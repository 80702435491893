import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'

import { NotionPageWithFooterSection } from 'components/notion/NotionPageWithFooterSection'
import { Layout } from 'layout/Layout'
import { useGetCompanyTaskQuery } from 'store/tasks/tasksApi'

import { ResourceItem } from './ResourceItem'
import { TaskStatusDropdown } from './TaskStatusDropdown'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  margin-bottom: 36px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const StyledText = styled(Text)`
  text-transform: uppercase;
  margin-bottom: 8px;
`

export const StrategyTask: FunctionComponent = () => {
  const { code = '' } = useParams<{ code: string }>()
  const { t } = useTranslation()

  const {
    data: companyTask,
    isLoading,
    isSuccess,
    isError,
  } = useGetCompanyTaskQuery({
    code,
  })

  return (
    <Layout maxContentWidth={1080} dark>
      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}
      {isSuccess && (
        <Container>
          <Header>
            <div>
              <StyledText
                element="p"
                type="body"
                size="xs"
                fontWeight={700}
                color={baseColors.coolGrey900}
              >
                Task
              </StyledText>
              <Text
                element="h1"
                type="heading"
                size="xl"
                fontWeight={500}
                color={baseColors.coolGrey900}
              >
                {companyTask.description}
              </Text>
            </div>

            <TaskStatusDropdown
              status={companyTask.status}
              task={companyTask}
            />
          </Header>

          <NotionPageWithFooterSection
            notionURL={companyTask?.notionURL}
            footerTitle="Resources"
            footer={
              companyTask.resources && companyTask.resources.length > 0
                ? companyTask.resources.map(resource => (
                    <ResourceItem key={resource.code} resource={resource} />
                  ))
                : undefined
            }
          />
        </Container>
      )}
    </Layout>
  )
}
