import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Clock: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM12.88 6.5V11.64L16.51 15.27C16.7018 15.4678 16.7018 15.7822 16.51 15.98L15.98 16.51C15.7822 16.7018 15.4678 16.7018 15.27 16.51L11.27 12.51C11.1784 12.4159 11.1249 12.2912 11.12 12.16V6.5C11.12 6.22386 11.3439 6 11.62 6H12.38C12.6561 6 12.88 6.22386 12.88 6.5Z"
      fill={outline}
    />
  </svg>
)
