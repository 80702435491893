import React, { FunctionComponent } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { space, Colors, Slack } from '@fairhq/common'
import { Layout } from 'layout/Layout'
import { State } from 'store/state'
import Button from 'ui-kit/Button'
import Icon from 'ui-kit/Icon'

import { useAppSelector } from '../../store/hooks'

import { useGetSlackInstallLinkQuery } from './campaignsApi'
import { CampaignsBlankSlateVisual } from './svgs/illustrations/CampaignsBlankSlateIllustration'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
`

const Content = styled.div`
  padding-right: ${space[10]}px;
  width: 100%;
`

const List = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 36px 1fr;
  row-gap: ${space[3]}px;
`

const ListItem = styled.li`
  margin-bottom: ${space[3]}px);
`

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledSlack = styled(Slack)`
  opacity: 1 !important;
  margin-right: ${space[3]}px;
`

const IllustrationContainer = styled.div`
  width: 100%;
  min-width: 300px;
`

export const CampaignsBlankSlate: FunctionComponent = () => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: slackbotInstallLink } = useGetSlackInstallLinkQuery({
    sessionId,
  })

  return (
    <Layout contentSx={{ paddingTop: 120 }}>
      <Container>
        <Content>
          <Heading sx={{ mb: 3 }}>{t('campaigns.blankslate.title')}</Heading>
          <Text as="div" sx={{ mb: 5 }}>
            {t('campaigns.blankslate.description')}
          </Text>
          <Text as="div">
            <List>
              <Trans
                i18nKey="campaigns.blankslate.bulletPoints"
                components={[
                  <Icon name="checkboxCheckedRounded" color={Colors.GREEN} />,
                  <ListItem />,
                ]}
              />
            </List>
          </Text>
          <Button
            variant="secondary"
            icon="arrowRight"
            iconPosition="right"
            iconSmall
            sx={{
              height: 72,
              p: '18px 40px 18px 30px',
              mt: 8,
              svg: { fill: 'inherit' },
            }}
            as="a"
            rel="noopener noreferrer"
            href={slackbotInstallLink}
          >
            <IconTextContainer>
              <StyledSlack />
              {t('campaigns.blankslate.button')}
            </IconTextContainer>
          </Button>
        </Content>
        <IllustrationContainer>
          <CampaignsBlankSlateVisual />
        </IllustrationContainer>
      </Container>
    </Layout>
  )
}
