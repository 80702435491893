import React, { FunctionComponent } from 'react'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'

import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import { getScoreColor } from 'utils/getScoreColor'

import { useFairExperience } from './hooks/useFairExperience'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const FairQuestion = styled(WhiteBoxWithShadow)`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;

  & p {
    margin: 0;
  }
`

const GapTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  background-color: ${baseColors.blue100};
  border-radius: 0 0 0 4px;
  & p {
    text-transform: uppercase;
  }
`

interface FairExperienceProps {
  latestSurveyWindowId: number
}

export const FairExperience: FunctionComponent<FairExperienceProps> = ({
  latestSurveyWindowId,
}) => {
  const { t } = useTranslation()

  const { data, isSuccess } = useFairExperience(latestSurveyWindowId)

  return (
    <Wrapper>
      <Container>
        <Text element="h2" type="body" size="xl" fontWeight={700}>
          {t('experience.fair.title')}
        </Text>
        <Text element="p" type="body" size="md" fontWeight={400}>
          {t('experience.fair.description')}
        </Text>
      </Container>
      <Container>
        {isSuccess &&
          data.length > 0 &&
          data.map(item => (
            <FairQuestion key={item.id}>
              <Text
                element="p"
                type="body"
                size="md"
                fontWeight={400}
                color={baseColors.grey500}
              >
                {item.surveyQuestion.title}
              </Text>
              <Text
                element="p"
                type="body"
                size="2xl"
                fontWeight={600}
                color={getScoreColor(item.companyScore * 10)}
              >
                {round(item.companyScore * 10, 1).toFixed(1)}
              </Text>
              <GapTag>
                <Text
                  element="p"
                  type="body"
                  size="2xs"
                  fontWeight={600}
                  color={baseColors.blue600}
                >
                  {t('experience.fair.gapTag')}
                </Text>
              </GapTag>
            </FairQuestion>
          ))}
        {isSuccess && data.length == 0 && (
          <Text
            element="p"
            type="body"
            size="md"
            fontWeight={400}
            color={baseColors.grey500}
          >
            {t('experience.fair.noMatches')}
          </Text>
        )}
      </Container>
    </Wrapper>
  )
}
