import React, { useState, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Heading } from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Navigate as Redirect } from 'react-router-dom'

import { useMount } from 'react-use'

import useSchema from 'hooks/use-yup-schema'
import { Layout } from 'layout/Layout'
import { getCustomer, updateCustomer } from 'store/customer/customerSlice'

import { State } from 'store/state'

import Button from 'ui-kit/Button'
import Field from 'ui-kit/Field'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { BackLink } from './BackLink'
import { SettingsSidebar } from './SettingsSidebar'

type FormData = {
  name: string
  email: string
  line1: string
  line2: string
  city: string
  country: string
  postal_code: string
}

export const EditBillingAddress = () => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState('')
  const dispatch = useAppDispatch()
  const [sending, setSending] = useState(false)
  const schema = useSchema([
    'name',
    'email',
    'line1',
    'line2',
    'city',
    'country',
    'postal_code',
  ])

  const { customer, subscription, loading } = useAppSelector(
    (state: State) => ({
      customer: state.customerReducer.customer,
      subscription: state.customerReducer.subscription,
      loading: state.customerReducer.loading,
    }),
    shallowEqual
  )

  const defaultValues = useMemo(
    () => ({
      name: customer?.name || '',
      email: customer?.email || '',
      line1: customer?.address_line1 || '',
      line2: customer?.address_line2 || '',
      city: customer?.address_city || '',
      country: t('countries.GBR'),
      postal_code: customer?.address_postal_code ?? '',
    }),
    [customer, t]
  )

  const { register, handleSubmit, reset, formState } = useForm<FormData>({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  })
  const { isDirty, errors } = formState

  useMount(() => {
    if (!customer && !loading) {
      dispatch(getCustomer())
    }
  })

  // update input values when data are fetched
  useEffect(() => {
    if (customer) {
      reset(defaultValues)
    }
  }, [customer, reset, defaultValues])

  const onSubmit = ({
    name,
    email,
    line1,
    line2,
    city,
    postal_code,
  }: FormData) => {
    if (customer && subscription) {
      dispatch(
        updateCustomer({
          customer: {
            name,
            email,
            address: {
              line1,
              line2,
              city,
              country: customer.address_country as string,
              postal_code,
            },
          },
        })
      )
      setSending(true)
    }
  }

  useEffect(() => {
    if (sending && !loading) {
      setRedirect('/settings/billing')
    }
  }, [sending, loading, setRedirect])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <BackLink
        to="/settings/billing"
        i18nKey="nav.settings.billing"
        setRedirect={setRedirect}
      />

      <Heading variant="subtitle" sx={{ mb: 8 }}>
        {t('settings.billing.changeBillingAddress')}
      </Heading>

      <Box sx={{ maxWidth: 480 }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid gap="4" sx={{ mb: 6 }}>
            <Field
              label={t('form.name.label')}
              placeholder={t('form.name.placeholder')}
              error={errors.name?.message}
              {...register('name')}
            />

            <Field
              label={t('form.email.label')}
              placeholder={t('form.email.placeholder')}
              type="email"
              error={errors.email?.message}
              {...register('email')}
            />

            <Box>
              <Field
                label={t('hello.payment.billing.address')}
                placeholder={t('form.line1.placeholder')}
                error={errors.line1?.message}
                {...register('line1')}
              />

              <Field
                placeholder={t('form.line2.placeholder')}
                error={errors.line2?.message}
                {...register('line2')}
                sx={{ mt: 2 }}
              />
            </Box>

            <Box>
              <Grid columns={24} gap={2}>
                <Field
                  label={t('form.city.label')}
                  placeholder={t('form.city.placeholder')}
                  error={errors.city?.message}
                  {...register('city')}
                  sx={{ gridColumn: 'span 16' }}
                />
                <Field
                  label={t('form.postal_code.label')}
                  placeholder={t('form.postal_code.placeholder')}
                  error={errors.postal_code?.message}
                  {...register('postal_code')}
                  sx={{ gridColumn: 'span 8' }}
                />
              </Grid>
            </Box>

            <Field
              label={t('form.country.label')}
              placeholder={t('form.country.placeholder')}
              error={errors.country?.message}
              {...register('country')}
              disabled
            />
          </Grid>

          <Button
            type="button"
            variant="secondary"
            onClick={() => setRedirect('/settings/billing')}
            sx={{ mr: 3 }}
            size="large"
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            size="large"
            loading={sending}
            disabled={!isDirty}
          >
            {t('saveChanges')}
          </Button>
        </form>
      </Box>
    </Layout>
  )
}
