import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ThemeUIStyleObject } from 'theme-ui'

import { makeTransition } from '@fairhq/common'

import { Tooltip } from '../components/Tooltip'
import { VisualizationItemValueProps, VisualizationTypeProps } from '../types'

export const RowVisualizationItemValue: FunctionComponent<
  VisualizationTypeProps<VisualizationItemValueProps>
> = ({ item, isScoreBy10, isGaps, maxGap }) => {
  const { t } = useTranslation()

  const getValueBasedLabel = () =>
    Number(item.value)
      ? (isScoreBy10 && round(item.value / 10, 1)) || `${round(item.value)}%`
      : item.value == 0
      ? '0'
      : t('na')

  const label = item.notEnoughData ? 'Not enough data' : getValueBasedLabel()

  const gapsWidth = maxGap ? round(item.value * 45) / maxGap : 0
  const isGapsNegative = isGaps && item.value < 0
  const isGapsAboveMaxWidth = isGaps && Math.abs(gapsWidth) > 30
  const gapMinWidth = Math.abs(gapsWidth) > 45 ? 45 : Math.abs(gapsWidth)
  const gapsStyle = {
    left: `calc(50% + ${
      gapsWidth < 0 ? `${Math.abs(gapsWidth) > 45 ? -45 : gapsWidth}%` : `0px`
    })`,
    borderRadius: gapsWidth < 0 ? '12px 0 0 12px ' : '0 12px 12px 0',
    maxWidth: `${gapMinWidth}%`,
  } as ThemeUIStyleObject
  const gapsLabelStyle = {
    [isGapsNegative ? 'left' : 'right']: isGapsAboveMaxWidth
      ? undefined
      : '-48px',
  } as ThemeUIStyleObject
  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          borderRadius: '0 12px 12px 0',
          bg: item.color,
          transition: makeTransition('opacity'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '42px',
          maxWidth: `${item.value > 100 ? 100 : item.value}%`,
          opacity: 0.8,
          position: 'relative',
          '&:hover': { opacity: 1, '.tooltip': { opacity: 1 } },
          ...(isGaps ? gapsStyle : {}),
        }}
      >
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{
            color:
              (!isGaps && round(item.value) > 84) || isGapsAboveMaxWidth
                ? 'white'
                : 'grey500',
            right: item.notEnoughData
              ? undefined
              : !isGaps && round(item.value) <= 84
              ? '-48px'
              : undefined,
            left: item.notEnoughData ? '28px' : undefined,
            position: 'absolute',
            whiteSpace: 'nowrap',
            ...(isGaps ? gapsLabelStyle : {}),
          }}
        >
          {label}
        </Text>
        <Tooltip tooltip={item.tooltip} isHorizontal />
      </Box>
    </Box>
  )
}
