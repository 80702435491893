import { combineReducers } from '@reduxjs/toolkit'

import { emptySplitApi } from 'emptySplitApi'

import { accountReducer } from './account/accountSlice'
import { apiHeadersReducer } from './apiHeaders/apiHeadersSlice'
import { areaReducer } from './area/areaSlice'
import { assessmentReducer } from './assessment/assessmentSlice'
import { auditReducer } from './audit/auditSlice'
import { authReducer } from './auth/authSlice'
import { companyReducer } from './company/companySlice'
import { customerReducer } from './customer/customerSlice'
import { documentReducer } from './document/documentSlice'
import { employeeReducer } from './employee/employeeSlice'
import { paymentReducer } from './payment/paymentSlice'
import { questionSessionReducer } from './questionSession/questionSessionSlice'
import { reportsReducer } from './reports/reportsSlice'
import { statusReducer } from './status/statusSlice'
import { surveyReducer } from './survey/surveySlice'

export const reducer = combineReducers({
  accountReducer,
  areaReducer,
  apiHeadersReducer,
  assessmentReducer,
  auditReducer,
  authReducer,
  companyReducer,
  customerReducer,
  documentReducer,
  employeeReducer,
  paymentReducer,
  questionSessionReducer,
  statusReducer,
  surveyReducer,
  reportsReducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
})
