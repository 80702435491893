import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Clipboard, space, ClipboardArrow } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { RoundButton } from 'styled'
import { ProgressBar } from 'ui-kit/ProgressBar'

import { getProgressColor } from 'utils/getProgressColor'

import { SurveyProgressBar } from './SurveyProgressBar'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const ProgressBarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space[2]}px;
`

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${space[6]}px !important;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledClipboardArrow = styled(ClipboardArrow)`
  margin-right: ${space[2]}px;
`

export const AuditInProgress: FunctionComponent = () => {
  const { latestSession } = useAppSelector((state: State) => ({
    latestSession: state.companyReducer.latestSession,
  }))

  const { t } = useTranslation()
  const { assessmentPercentage, surveyPercentage, audit } = useAuditBy(
    latestSession?.id,
    latestSession?.apiVersion
  )

  const assessmentProgressColor = getProgressColor(assessmentPercentage)
  const surveyProgressColor = getProgressColor(surveyPercentage)

  return (
    <>
      <LabelContainer>
        <Clipboard outline={baseColors.purple} />
        <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
          {t(`statusOverview.audit`)}
        </Text>
      </LabelContainer>
      <Text as="div" variant="title" sx={{ mb: 2, fontSize: 24 }}>
        {t(`statusOverview.continue.title`)}
      </Text>
      <Text
        as="div"
        sx={{ mt: 2, mb: 3, color: baseColors.grey400, fontSize: 16 }}
      >
        {t(`statusOverview.continue.description1`)}
      </Text>
      <ProgressBarTitleContainer>
        <Text variant="h2">{t(`statusOverview.continue.assessment`)}</Text>
        <Text
          variant="subtitle"
          sx={{ color: assessmentProgressColor }}
        >{`${assessmentPercentage}%`}</Text>
      </ProgressBarTitleContainer>
      <StyledProgressBar
        value={assessmentPercentage}
        color={assessmentProgressColor}
      />
      <ProgressBarTitleContainer>
        <Text variant="h2">{t(`statusOverview.continue.survey`)}</Text>
        <Text
          variant="subtitle"
          sx={{ color: surveyProgressColor }}
        >{`${surveyPercentage}%`}</Text>
      </ProgressBarTitleContainer>
      <SurveyProgressBar
        surveyPercentage={surveyPercentage}
        completedSurveys={audit?.survey.completedSurveys}
        totalSurveys={audit?.survey.surveys}
      />
      <Text as="div" sx={{ mb: 6, color: baseColors.grey400, fontSize: 16 }}>
        {t(`statusOverview.continue.description2`)}
      </Text>
      <ButtonContainer>
        <RoundButton componentClass={NavLink} to="/audit">
          <StyledClipboardArrow outline={baseColors.grey300} />
          {t(`statusOverview.continue.cta`)}
        </RoundButton>
      </ButtonContainer>
    </>
  )
}
