import { DefaultTheme } from 'styled-components'

export interface Theme extends DefaultTheme {
  colors: Record<string, string>
  maxWidth: string
  maxWidth2: string
}

export const theme: Theme = {
  colors: {
    primary: '#5347d1',
    lightOrange: '#ffe5d6',
    yellow: '#F2A556',
    pink: '#F76E9C',
    red: '#F26956',
    black: '#000',
    blue: '#43C4EB',
    green: '#38E086',
    grey500: '#0A1B40',
    grey400: '#4C5766',
    grey300: '#98A3B3',
    grey200: '#C4CBD4',
    grey100: '#F2F7FA',
    white: '#fff',
  },
  maxWidth: '1360px',
  maxWidth2: '1180px',
}
