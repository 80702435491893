import queryString from 'query-string'

import { TagTypes, emptySplitApi } from 'emptySplitApi'

import {
  DemGroupCount,
  DemGroupScore,
  ENPSType,
  ExperienceCategory,
  ExperienceGains,
  ExperienceQuestion,
  JobLevelsCount,
  SurveyWindow,
} from './types'

const surveyWindowApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    isSurveyWindowDue: build.query<boolean, void>({
      query: () => ({
        url: `survey-window/due`,
      }),
      providesTags: [TagTypes.SURVEY_WINDOW_DUE],
    }),
    createSurveyWindow: build.mutation<SurveyWindow, void>({
      query: () => ({
        url: `survey-window`,
        method: 'POST',
      }),
      invalidatesTags: [
        TagTypes.SURVEY_WINDOW_DUE,
        TagTypes.CURRENT_SURVEY_WINDOW,
      ],
    }),
    getSurveyWindow: build.query<SurveyWindow, void>({
      query: () => ({
        url: `survey-window/current`,
      }),
      providesTags: [TagTypes.CURRENT_SURVEY_WINDOW],
    }),

    openSurveyWindow: build.mutation<SurveyWindow, { id: number }>({
      query: ({ id }) => ({
        url: `survey-window/${id}/open`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagTypes.CURRENT_SURVEY_WINDOW],
    }),
    closeSurveyWindow: build.mutation<SurveyWindow, { id: number }>({
      query: ({ id }) => ({
        url: `survey-window/${id}/close`,
        method: 'PATCH',
      }),
      invalidatesTags: [
        TagTypes.CURRENT_SURVEY_WINDOW,
        TagTypes.SURVEY_WINDOW_ARCHIVE,
      ],
    }),
    getSurveyWindowArchive: build.query<SurveyWindow[], void>({
      query: () => ({
        url: `survey-window/archive`,
      }),
      providesTags: [TagTypes.SURVEY_WINDOW_ARCHIVE],
    }),
    getSurveyWindowArchiveComparison: build.query<SurveyWindow[], void>({
      query: () => ({
        url: `survey-window/archive?${queryString.stringify({
          take: 2,
          order: 'DESC',
        })}`,
      }),
      providesTags: [TagTypes.SURVEY_WINDOW_ARCHIVE],
    }),
    getDemGroups: build.query<
      DemGroupCount[],
      { surveyWindowId?: number; code?: string; level?: string }
    >({
      query: ({ surveyWindowId, code, level }) => ({
        url: `survey-window/${surveyWindowId}/diversity?${queryString.stringify(
          { code, level }
        )}`,
      }),
    }),
    getJobLevels: build.query<JobLevelsCount[], { surveyWindowId: number }>({
      query: ({ surveyWindowId }) => ({
        url: `survey-window/${surveyWindowId}/levels`,
      }),
    }),
    getENPS: build.query<ENPSType, { surveyWindowId: number }>({
      query: ({ surveyWindowId }) => ({
        url: `survey-window/${surveyWindowId}/enps`,
      }),
    }),
    getExperienceQuestions: build.query<
      ExperienceQuestion[],
      { surveyWindowId?: number }
    >({
      query: ({ surveyWindowId }) => ({
        url: `survey-window/${surveyWindowId}/overall-scores`,
      }),
      transformResponse: (response: ExperienceQuestion[]) =>
        response.sort((a, b) => a.companyScore - b.companyScore),
    }),
    getDemGroupScoresByQuesiton: build.query<
      DemGroupScore[],
      { surveyWindowId: number; questionId: number }
    >({
      query: ({ surveyWindowId, questionId }) => ({
        url: `survey-window/${surveyWindowId}/dem-scores?${queryString.stringify(
          { questionId }
        )}`,
      }),
    }),
    getCategoriesScores: build.query<
      ExperienceCategory[],
      { surveyWindowId?: number }
    >({
      query: ({ surveyWindowId }) => ({
        url: `survey-window/${surveyWindowId}/categories-scores`,
      }),
    }),
    getBiggestGains: build.query<
      ExperienceGains[],
      { surveyWindowId?: number }
    >({
      query: ({ surveyWindowId }) => ({
        url: `survey-window/${surveyWindowId}/gains`,
      }),
      transformResponse: (response: ExperienceGains[]) =>
        response.sort((a, b) => b.score - a.score),
    }),
  }),
})

export const {
  useIsSurveyWindowDueQuery,
  useGetSurveyWindowQuery,
  useGetSurveyWindowArchiveQuery,
  useCreateSurveyWindowMutation,
  useOpenSurveyWindowMutation,
  useCloseSurveyWindowMutation,
  useGetDemGroupsQuery,
  useGetSurveyWindowArchiveComparisonQuery,
  useGetJobLevelsQuery,
  useGetENPSQuery,
  useGetExperienceQuestionsQuery,
  useGetDemGroupScoresByQuesitonQuery,
  useGetCategoriesScoresQuery,
  useGetBiggestGainsQuery,
} = surveyWindowApi
