import { FunctionComponent } from 'react'

import { FormControl } from 'rsuite'

import { FieldGroup } from './FieldGroup'

export const Field: FunctionComponent<any> = ({
  accepter,
  error,
  footer,
  label,
  message,
  name,
  children,
  style,
  className,
  ...restOfTheProps
}) => (
  <FieldGroup
    error={error}
    footer={footer}
    label={label}
    message={message}
    name={name}
    style={style}
    className={className}
  >
    <FormControl name={name} accepter={accepter} {...restOfTheProps}>
      {children}
    </FormControl>
  </FieldGroup>
)
