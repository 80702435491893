import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { ArrowSquarePurple } from 'components/ArrowSquarePurple'
import { ArrowWithBackground } from 'components/ArrowWithBackground'

import { SquareIconBackground } from 'components/SquareIconBackground'
import { Resource } from 'store/tasks/types'

import { CustomLink } from './CustomLink'

const ListItem = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  background-color: ${baseColors.white};
  padding: 16px 32px;
  border-radius: 16px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 4%), 0 2px 6px rgba(0, 0, 0, 4%);
`

const IconsWrap = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

interface ResourceItemProps {
  resource: Resource
}

export const ResourceItem: FunctionComponent<ResourceItemProps> = ({
  resource,
}) => (
  <ListItem>
    <CustomLink resource={resource}>
      <Text element="span" type="body" size="sm" fontWeight={600}>
        {resource.description}
      </Text>
    </CustomLink>
    <IconsWrap>
      <Text
        element="p"
        type="body"
        size="xs"
        fontWeight={500}
        color={baseColors.coolGrey600}
      >
        {resource.type}
      </Text>
      {resource.externalURL ? (
        <SquareIconBackground>
          <ArrowSquarePurple />
        </SquareIconBackground>
      ) : (
        <ArrowWithBackground />
      )}
    </IconsWrap>
  </ListItem>
)
