import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import Icon from 'ui-kit/Icon'
import ProgressBox from 'ui-kit/ProgressBox'

import { Dashbox, SpanProp } from './Dashbox'
import { DashboxHeading } from './DashboxHeading'

interface MinorityRepresentationProps {
  span: SpanProp
}

export const MinorityRepresentation: FunctionComponent<
  MinorityRepresentationProps
> = ({ span }) => {
  const { t } = useTranslation()
  const { missingEmployeesPercent, minorityPercent, pocPercent, womenPercent } =
    useAppSelector((state: State) => {
      const total = state.surveyReducer.status?.total ?? 0
      const minority = Math.round(
        total ? ((state.surveyReducer.status?.minority ?? 0) / total) * 100 : 0
      )
      const poc = Math.round(
        total ? ((state.surveyReducer.status?.poc ?? 0) / total) * 100 : 0
      )
      const woman = Math.round(
        total ? ((state.surveyReducer.status?.woman ?? 0) / total) * 100 : 0
      )
      return {
        missingEmployeesPercent: state.surveyReducer.status?.percent
          ? Math.round(100 - (state.surveyReducer.status?.percent ?? 0))
          : 0,
        minorityPercent: minority,
        pocPercent: poc,
        womenPercent: woman,
      }
    })

  const data = [
    { label: t('dashboard.minority.overall'), value: minorityPercent },
    { label: t('dashboard.minority.gender'), value: womenPercent },
    { label: t('dashboard.minority.ethnicity'), value: pocPercent },
  ]

  return (
    <Dashbox span={span}>
      <DashboxHeading heading={t('dashboard.minority.title')}>
        <Text as="div">
          {t('dashboard.minority.missing', {
            percent: missingEmployeesPercent,
          })}
        </Text>
      </DashboxHeading>
      <Flex sx={{ flexDirection: 'column', flex: 1 }}>
        {data.map(({ label, value }, i) => (
          <ProgressBox
            key={label}
            sx={{
              borderTop: th => `1px solid ${th.colors?.grey200}`,
              py: 3,
              px: 8,
              flex: 1,
              borderRadius: i === data.length - 1 ? '0 0 19px 19px' : '',
            }}
            progressValue={value}
          >
            <Flex sx={{ alignItems: 'center' }}>
              <Icon name="team" sx={{ mr: 2 }} />{' '}
              <Text
                as="div"
                variant="bodyLargeBold"
                sx={{ flex: 1, color: 'grey500' }}
              >
                {label}
              </Text>
              <Text as="div" variant="subtitle">
                {value}%
              </Text>
            </Flex>
          </ProgressBox>
        ))}
      </Flex>
    </Dashbox>
  )
}
