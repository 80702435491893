import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const ClipboardArrow: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 3C19.9926 3 21 3.89543 21 5V7H19V5H16V6.5C16 6.77614 15.7761 7 15.5 7H8.5C8.22386 7 8 6.77614 8 6.5V5H5V21H17H19H21C21 22.1046 19.9926 23 18.75 23H5.25C4.00736 23 3 22.1046 3 21V5C3 3.89543 4.00736 3 5.25 3H9.75C9.75 1.89543 10.7574 1 12 1C13.2426 1 14.25 1.89543 14.25 3H18.75ZM8.5 11C8.77614 11 9 10.7761 9 10.5V9.5C9 9.22386 8.77614 9 8.5 9H7.5C7.22386 9 7 9.22386 7 9.5V10.5C7 10.7761 7.22386 11 7.5 11H8.5ZM9 14.5C9 14.7761 8.77614 15 8.5 15H7.5C7.22386 15 7 14.7761 7 14.5V13.5C7 13.2239 7.22386 13 7.5 13H8.5C8.77614 13 9 13.2239 9 13.5V14.5ZM8.5 19C8.77614 19 9 18.7761 9 18.5V17.5C9 17.2239 8.77614 17 8.5 17H7.5C7.22386 17 7 17.2239 7 17.5V18.5C7 18.7761 7.22386 19 7.5 19H8.5Z"
      fill={outline}
    />
    <path
      d="M12 15.0513H20.1716L16.8787 18.5132L18.2929 20L24 14L18.2929 8L16.8787 9.48679L20.1716 12.9487H12V15.0513Z"
      fill={outline}
    />
  </svg>
)
