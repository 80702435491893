export const statusDictionary: Record<string, Record<string, string>> = {
  errorToIgnore: {},
  success: {},
}

interface Status {
  message: string
  name: string
  type: 'warning' | 'error' | 'success' | 'info'
}

export interface StatusState {
  count: number
  status: Status
}
