import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

import { CompanyInsightWithoutAreas } from 'features/insights/types'
import { Initiative } from 'features/strategy/Initiative'

import { useUpdateInitiativeMutation } from 'store/initiatives/initiativesApi'
import { CompanyInitiative } from 'store/initiatives/types'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'
import { Task as TaskType } from 'store/tasks/types'

import { AddOrRemoveButton } from './AddOrRemoveButton'

const Container = styled.article`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background-color: ${baseColors.white};
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
  position: relative;
`

interface InitiativeEditableProps {
  companyInitiative: CompanyInitiative
  companyInsights: CompanyInsightWithoutAreas[]
  companyTasks: TaskType[]
}

export const InitiativeEditable: FunctionComponent<InitiativeEditableProps> = ({
  companyInitiative,
  companyInsights,
  companyTasks: tasks,
}) => {
  const { data: strategy, isSuccess: isStrategySucess } = useGetStrategyQuery()
  const [updateInitiative] = useUpdateInitiativeMutation()

  const { recommendation } = companyInitiative

  return (
    <>
      {isStrategySucess && (
        <Container>
          <>
            <Initiative
              key={recommendation.id}
              recommendation={recommendation}
              companyInsights={companyInsights}
              companyTasks={tasks}
            />
            {companyInitiative.strategyId ? (
              <AddOrRemoveButton
                variant="remove"
                title="Remove initiative from strategy"
                onClick={() =>
                  updateInitiative({
                    id: companyInitiative.id,
                    strategyId: null,
                  })
                }
              />
            ) : (
              <AddOrRemoveButton
                variant="add"
                title="Add initiative to strategy"
                onClick={() =>
                  updateInitiative({
                    id: companyInitiative.id,
                    strategyId: strategy.id,
                  })
                }
              />
            )}
          </>
        </Container>
      )}
    </>
  )
}
