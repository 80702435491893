import React, { ReactNode } from 'react'

import WhiteBox from 'ui-kit/WhiteBox'

export const AddEmployeesWhiteBox: React.FunctionComponent<{
  children: ReactNode
}> = ({ children }) => (
  <WhiteBox
    sx={{
      pb: 14,
      '.rs-uploader': {
        maxHeight: '320px',
        '.rs-uploader-trigger-btn': {
          maxHeight: '320px',
          padding: 0,
          '.rs-container': {
            maxHeight: '320px',
            margin: 0,
            padding: '32px',

            h3: {
              marginTop: '24px',
              marginBottom: '10px',
              fontSize: '24px',
              lineHeight: 1.5,
            },
          },

          img: {
            height: '160px',
          },
        },
      },
    }}
  >
    {children}
  </WhiteBox>
)
