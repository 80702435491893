import React, { useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Animation } from 'rsuite'

import styled, { css } from 'styled-components'

import { Spinner, config } from '@fairhq/common'

import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { SignUpLayout } from 'layout/SignUpLayout'

import { SlideContainer } from 'styled'

import { BillingForm } from './BillingForm'
import { CompanyForm } from './CompanyForm'
import { DetailsForm } from './DetailsForm'
import { useCheckGoogleUserIsInvited } from './hooks/useCheckGoogleUserIsInvited'
import { useQuery } from './hooks/useQuery'
import { PaymentForm } from './PaymentForm'
import { SignUpSteps } from './SignUpSteps'

const SignUpStep = styled(Animation.Slide)`
  ${({ in: visible }) =>
    !visible &&
    css`
      display: none;
    `}
`

const stripePromise = loadStripe(config?.stripe || '')

const steps = (t: TFunction) => [
  {
    title: t('hello.personalInfo.title'),
    Component: React.memo(() => <DetailsForm />),
  },
  {
    title: t('hello.companyInfo.title'),
    Component: React.memo(() => <CompanyForm />),
  },
  {
    title: t('hello.payment.billing.title'),
    Component: React.memo(() => <BillingForm />),
  },
  {
    title: t('hello.payment.title'),
    Component: React.memo(() => (
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    )),
  },
]

const SignUpComponent = () => {
  const { t } = useTranslation()
  const { isAuthenticated, isLoading } = useAuth0()
  const { steps: total = 4 }: any = useQuery()
  const { step: activeStep = 1 } = useParams<{ step: string }>()
  const permissionsChecked = useCheckPermissions()
  const isInvitedGoogleUser = useCheckGoogleUserIsInvited()

  const { title: activeStepTitle, Component } = useMemo(
    () => steps(t)[+activeStep - 1],
    [activeStep, t]
  )

  if (
    !isAuthenticated ||
    isLoading ||
    !permissionsChecked ||
    isInvitedGoogleUser !== false
  ) {
    return <Spinner backdrop vertical />
  }

  return (
    <SignUpLayout>
      <SignUpSteps
        key={activeStep}
        activeStep={+activeStep}
        totalSteps={+total}
        title={activeStepTitle}
      >
        <SignUpStep in>
          {(props, ref) => (
            <SlideContainer {...props} ref={ref}>
              <Component />
            </SlideContainer>
          )}
        </SignUpStep>
      </SignUpSteps>
    </SignUpLayout>
  )
}

export const SignUp = React.memo(SignUpComponent)
