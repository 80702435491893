import React, { FunctionComponent } from 'react'

import { useCompany } from 'hooks/useCompany'
import { Routes } from 'routes/Routes'

const LoggedInApp: FunctionComponent = () => {
  useCompany()

  return <Routes />
}

export default React.memo(LoggedInApp)
