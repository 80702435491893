import {
  useContext,
  useEffect,
  useState,
  useCallback,
  FunctionComponent,
} from 'react'

import _ from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { Container, Rate, Tooltip, Whisper } from 'rsuite'
import styled from 'styled-components'

import { Confirm } from 'components/confirm'
import { DOCUMENT_MAX } from 'const'
import { removeFile } from 'store/document/documentSlice'
import { CompanyDocument, DocumentsLink } from 'store/document/types'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { Subtitle } from 'styled'
import { Message } from 'styled/message'
import { toDate } from 'utils/dates'

import { DeletedDocumentsContext, DocumentsContext } from '../DocumentUpload'

import { DocumentCard } from './DocumentCard'

const DocumentsListContainer = styled(Container)``

const Rejected = styled.span`
  color: ${({ theme }) => theme.colors.red};
`
const Accepted = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
`

export const DocumentsList: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const loading = useAppSelector(
    (state: State) => state.documentReducer.loading
  )
  const { deleting, setDeleting } = useContext(DeletedDocumentsContext)
  const { document, droppedFiles, setDroppedFiles, progress, hasError } =
    useContext(DocumentsContext)
  const [onDeleteFileOrDoc, setOnDeleteFileOrDoc] = useState<any>()

  const score = document?.score ? (document.score * 4) / DOCUMENT_MAX : 0
  const subtitle =
    document?.status === 'accepted' ? (
      <Accepted>{t('document.rated')}</Accepted>
    ) : (
      (document?.status === 'rejected' && (
        <Whisper
          placement="bottom"
          trigger="hover"
          speaker={<Tooltip>{t('document.notValid')}</Tooltip>}
        >
          <Rejected>{t('document.rejected')}</Rejected>
        </Whisper>
      )) ||
      (progress && progress !== 100 && t('document.uploading')) ||
      (document && t('document.uploaded')) ||
      t('document.toUpload')
    )
  const titleVisible =
    droppedFiles?.length > 0 || (document?.links || [])?.length > 0

  function onDelete(fileToRemove: any) {
    setDroppedFiles(
      droppedFiles?.filter(file => !_.isEqual(file, fileToRemove))
    )
  }

  const displayDocumentCard = ({
    links,
    id,
    status,
  }: Partial<CompanyDocument>) =>
    links?.map(link => (
      <DocumentCard
        key={(link as DocumentsLink)?.url}
        url={(link as DocumentsLink)?.url}
        name={(link as DocumentsLink)?.title || (link as DocumentsLink)?.url}
        isLink={(link as DocumentsLink)?.type === 'external_link'}
        date={toDate(i18n.language, (link as DocumentsLink)?.createdAt).format(
          'll'
        )}
        onDelete={() =>
          setOnDeleteFileOrDoc({
            documentId: id,
            id: (link as DocumentsLink)?.id,
            name: (link as DocumentsLink)?.title,
          })
        }
        rejected={status === 'rejected'}
      />
    ))

  const onConfirmDelete = useCallback(() => {
    setDeleting(true)
    const { documentId, id } = onDeleteFileOrDoc
    dispatch(removeFile({ documentId, id }))
  }, [dispatch, onDeleteFileOrDoc, setDeleting])

  useEffect(() => {
    if (deleting && !loading) {
      setDeleting(false)
      setOnDeleteFileOrDoc(undefined)
    }
  }, [deleting, dispatch, loading, setDeleting])

  return (
    <DocumentsListContainer>
      <Confirm
        loading={deleting}
        onCancel={() => setOnDeleteFileOrDoc(undefined)}
        onSubmit={onConfirmDelete}
        visible={!!onDeleteFileOrDoc}
        confirmLabel={t('delete')}
        danger
      >
        <h3>{t('document.deleteDocument')}</h3>
        <Message>
          <Trans
            i18nKey="confirm.areYouSureToDeleteDocument"
            components={[<br key="first" />, <strong key="second" />]}
            values={{
              name: onDeleteFileOrDoc?.name,
            }}
          />
        </Message>
      </Confirm>
      {titleVisible ? (
        <Subtitle>
          {subtitle}
          {document?.status === 'accepted' && (
            <Rate
              defaultValue={score}
              allowHalf
              color="violet"
              max={4}
              size="sm"
              readOnly
            />
          )}
        </Subtitle>
      ) : undefined}
      {displayDocumentCard(document ?? {})}
      {droppedFiles?.map(file => (
        <DocumentCard
          key={file.fileKey}
          name={file?.name ?? ''}
          progress={progress}
          onDelete={() => onDelete(file)}
          hasError={hasError}
        />
      ))}
    </DocumentsListContainer>
  )
}
