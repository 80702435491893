import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { count as countSurvey } from 'store/survey/surveySlice'
import { tCode } from 'utils/tCode'

import { useReportsParams } from '../hooks/useReportsParams'
import { DemGroupType, reportsKeyValue } from '../types'

import { DemGroupScoreRepresentation } from './DemGroupScoreRepresentation'
import { DiversityMakeUp } from './DiversityMakeUp'

export const DemographicGroupsTopLevelReports: FunctionComponent = () => {
  const { code, type } = useReportsParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { surveyStatus, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      surveyStatus: state.surveyReducer.status,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (!surveyStatus) {
      dispatch(countSurvey())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const {
    data: demGroupsReports,
    isLoading,
    isSuccess,
    isError,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })

  const diversityMakeupGraphData = demGroupsReports?.companyByDemGroup
    ?.filter(
      ({ dem_group_type, [reportsKeyValue[type]]: group }: any) =>
        (dem_group_type === DemGroupType.MINORITY ||
          dem_group_type === DemGroupType.ALL_MAJORITY) &&
        (!code || group === code)
    )
    .map(({ dem_group_code, dem_group_title, total }: any) => ({
      key: dem_group_code + Math.random(),
      label: tCode(t)(dem_group_code, '', dem_group_title),
      value: total,
    }))

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <DiversityMakeUp
        key="DiversityMakeUp"
        status={surveyStatus}
        graphData={diversityMakeupGraphData}
      />
      ,
      <DemGroupScoreRepresentation
        key="DemGroupScoreRepresentation_inclusion"
        area="inclusion"
      />
      <DemGroupScoreRepresentation
        key="DemGroupScoreRepresentation"
        area="equality"
      />
    </StateHandler>
  )
}
