import React, { useState } from 'react'

import { Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Navigate as Redirect } from 'react-router-dom'

import { FileType } from 'rsuite/es/Uploader'

import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { count } from 'store/employee/employeeSlice'

import Steps, { Step } from 'ui-kit/Steps'

import { useAppDispatch } from '../../store/hooks'

import { AddEmployeesWhiteBox } from './components/AddEmployeesWhiteBox'
import { Prepare } from './Prepare'
import { Review } from './Review'
import { Upload } from './Upload'

export const AddEmployeesWithCSV = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [droppedFiles, setDroppedFiles] = useState<FileType[]>([])
  const [employees, setEmployees] = useState([])
  const [step, setStep] = useState(0)
  const [redirect, setRedirect] = useState('')

  const steps: Step[] = [
    {
      title: t('employees.addEmployees.prepare'),
      component: <Prepare next={() => setStep(1)} />,
    },
    {
      title: t('employees.addEmployees.upload'),
      component: (
        <Upload
          next={() => setStep(2)}
          setEmployees={setEmployees}
          droppedFiles={droppedFiles}
          setDroppedFiles={setDroppedFiles}
        />
      ),
    },
    {
      title: t('employees.addEmployees.review'),
      component: (
        <Review
          next={() => {
            dispatch(count())
            setRedirect('/employees')
          }}
          employees={employees}
          clear={() => {
            setEmployees([])
            setStep(0)
            setDroppedFiles([])
          }}
        />
      ),
    },
  ]

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Layout dark>
      <PageTop>
        <Heading>{t('employees.add.viaCSV')}</Heading>
      </PageTop>
      <AddEmployeesWhiteBox>
        <Steps steps={steps} currentStep={step} sx={{ mb: 7 }} />

        {steps[step].component}
      </AddEmployeesWhiteBox>
    </Layout>
  )
}
