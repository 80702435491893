import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { config } from '@fairhq/common'
import { State } from 'store/state'

export enum TagTypes {
  CAMPAIGNS = 'Campaigns',
  ACCOUNT = 'Account',
  SCORABLE = 'Scorable',
  SESSIONS = 'Sessions',
  SCORES = 'Scores',
  EVENTS = 'Events',
  OLD_RECOMMENDATIONS = 'Old Recommendations',
  EMPLOYEES = 'Employees',
  ACCOUNTS = 'Accounts',
  STRATEGY = 'Strategy',
  STRATEGY_DUE = 'Strategy due',
  INSIGHTS = 'Insights',
  INITIATIVES = 'Initiatives',
  TASK = 'Task',
  CURRENT_SURVEY_WINDOW = 'Current survey window',
  SURVEY_WINDOW_DUE = 'Survey window due',
  SURVEY_WINDOW_ARCHIVE = 'Survey window archive',
}

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      // Note: these default headers are applied _after_ the headers
      // defined on a specific endpoint, so they will override anything
      // that's been defined on an endpoint
      const token = localStorage.getItem(config.tokenKey)
      const state = getState() as State

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      const accountId = state?.apiHeadersReducer?.accountId?.toString()
      headers.set('fairhq-account', accountId || '')

      const companyId = state?.apiHeadersReducer?.companyId?.toString()
      headers.set('fairhq-company', companyId || '')

      return headers
    },
  }),
  tagTypes: [
    TagTypes.CAMPAIGNS,
    TagTypes.ACCOUNT,
    TagTypes.SCORABLE,
    TagTypes.SESSIONS,
    TagTypes.SCORES,
    TagTypes.EVENTS,
    TagTypes.OLD_RECOMMENDATIONS,
    TagTypes.EMPLOYEES,
    TagTypes.ACCOUNTS,
    TagTypes.STRATEGY,
    TagTypes.STRATEGY_DUE,
    TagTypes.INSIGHTS,
    TagTypes.INITIATIVES,
    TagTypes.TASK,
    TagTypes.CURRENT_SURVEY_WINDOW,
    TagTypes.SURVEY_WINDOW_DUE,
    TagTypes.SURVEY_WINDOW_ARCHIVE,
  ],
  endpoints: () => ({}),
})
