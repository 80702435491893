import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Button,
  Chevron,
  ChevronDirection,
  Text,
  baseColors,
} from '@fairhq/common'
import { StickyBar } from 'components/StickyBar'

const InnerWrapper = styled.div<{ previousLabel?: string }>`
  width: 100%;
  display: flex;
  gap: 24px;
  max-width: 1200px;
  align-items: center;
  justify-content: ${({ previousLabel }) =>
    previousLabel ? 'space-between' : 'end'};
`

const StyledButton = styled(Button)`
  pointer-events: auto;
`

const LeftButton = styled(StyledButton)`
  align-self: flex-start;
`
interface ActionBarProps {
  onNextClick: () => void
  onPreviousClick?: () => void
  nextLabel: string
  previousLabel?: string
  descriptionLabel?: string
  isLast?: boolean
  isError?: boolean
  isLoading?: boolean
}

export const ActionBar: FunctionComponent<ActionBarProps> = ({
  onNextClick,
  onPreviousClick,
  nextLabel,
  previousLabel,
  descriptionLabel,
  isLast = false,
  isError = false,
  isLoading = false,
}) => {
  const { t } = useTranslation()
  return (
    <StickyBar>
      <InnerWrapper previousLabel={previousLabel}>
        {isError ? (
          <Text
            element="p"
            type="body"
            size="md"
            fontWeight={600}
            color={baseColors.red}
          >
            {t('error.TryAgain')}
          </Text>
        ) : (
          <>
            {previousLabel && (
              <LeftButton
                variant="secondary"
                iconLeft={<Chevron direction={ChevronDirection.LEFT} />}
                hasMinWidth
                loading={isLoading}
                onClick={onPreviousClick}
              >
                {t(previousLabel)}
              </LeftButton>
            )}

            {descriptionLabel && (
              <Text element="p" fontWeight={600} type="body" size="md">
                {t(descriptionLabel)}
              </Text>
            )}

            <StyledButton
              hasMinWidth
              loading={isLoading}
              onClick={onNextClick}
              iconRight={
                isLast ? false : <Chevron direction={ChevronDirection.RIGHT} />
              }
            >
              {t(nextLabel)}
            </StyledButton>
          </>
        )}
      </InnerWrapper>
    </StickyBar>
  )
}
