import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'

import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'

import { useGetSurveyWindowArchiveComparisonQuery } from 'store/surveyWindow/surveyWindowApi'

import { BiggestGains } from './BiggestGains'
import { CategoriesOverview } from './CategoriesOverview'
import { ENPS } from './ENPS'
import { ExperienceQuestions } from './ExperienceQuestions'
import { FairExperience } from './FairExperience'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const ReportsExperience = () => {
  const { t } = useTranslation()

  const { data, isFetching, isSuccess } =
    useGetSurveyWindowArchiveComparisonQuery()

  const latestSurveyWindow = data?.[0]
  const previousSurveyWindow = data?.[1]

  return (
    <Layout
      contentSx={{ display: 'flex', flexDirection: 'column', gap: '60px' }}
      sidebar={<ReportsModularSidebar />}
      dark
    >
      <Header>
        <Text
          element="h1"
          type="heading"
          size="2xl"
          fontWeight={400}
          color={baseColors.coolGrey900}
        >
          {t(`experience.title`)}
        </Text>
      </Header>
      {isFetching && <Spinner center />}
      {isSuccess && latestSurveyWindow && (
        <>
          <ENPS
            latestSurveyWindowId={latestSurveyWindow.id}
            previousSurveyWindowId={previousSurveyWindow?.id}
          />

          <CategoriesOverview
            latestSurveyWindowId={latestSurveyWindow.id}
            previousSurveyWindowId={previousSurveyWindow?.id}
          />

          <FairExperience latestSurveyWindowId={latestSurveyWindow.id} />

          {previousSurveyWindow && (
            <BiggestGains latestSurveyWindowId={latestSurveyWindow.id} />
          )}

          <ExperienceQuestions
            latestSurveyWindowId={latestSurveyWindow.id}
            previousSurveyWindowId={previousSurveyWindow?.id}
          />
        </>
      )}
    </Layout>
  )
}
