import React, { FunctionComponent, useEffect, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { StatusTagWithRoundIcon } from 'components/StatusTagWithRoundIcon'
import { PracticeCriteriaWithLevelType } from 'store/reports/types'

import { TickThin } from './TickThin'

const Container = styled.section`
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
`

const Heading = styled.div`
  background-color: ${baseColors.white};
  padding: 24px 40px;
  display: flex;
  gap: 40px;
`

const HeadingLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  min-width: 150px;
`

const PracticesContainer = styled.div<{ withoutPaddingRight: boolean }>`
  padding: ${({ withoutPaddingRight }) =>
    withoutPaddingRight ? `24px 24px 0 40px` : `24px 40px 0`};
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PracticeRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`

const PracticeCard = styled.div`
  border-radius: 16px;
  background-color: ${baseColors.white};
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const TickIcon = styled(TickThin)`
  flex-shrink: 0;
`

const AccordionControl = styled.button<{ withTotBorder: boolean }>`
  width: 100%;
  text-align: center;
  line-height: 30px;
  padding: 12px;
  color: ${baseColors.purple700};
  background-color: inherit;
  border-top: ${({ withTotBorder }) =>
    withTotBorder ? ` 1px solid ${baseColors.purple200}` : 'none'};
  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    border-radius: 20px;
    outline-offset: -2px;
  }
`

const BenchmarkHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 28px;
`

const PeerLabel = styled(Text)`
  text-transform: uppercase;
  min-width: 50px;
  text-align: center;
`

const PeerDescription = styled(Text)`
  font-style: italic;
  line-height: 18px;
`

const PeerComparison = styled.div`
  min-width: 50px;
  text-align: center;
`

interface LevelCardProps {
  practices: PracticeCriteriaWithLevelType[]
  levelNumber: number
  isShowAllOpenInitialSate: boolean
  areaCode: string
  levelKey: string
}

export const LevelCard: FunctionComponent<LevelCardProps> = ({
  practices,
  levelNumber,
  isShowAllOpenInitialSate,
  areaCode,
  levelKey,
}) => {
  const { t } = useTranslation()

  const [isShowAllOpen, setIsShowAllOpen] = useState(false)

  const completionPercentage =
    (practices.filter(practice => practice.checked).length / practices.length) *
    100

  const showPeerComparison = !!practices.find(
    practice => practice.peerComparison
  )

  useEffect(() => {
    setIsShowAllOpen(isShowAllOpenInitialSate)
  }, [isShowAllOpenInitialSate])

  return (
    <Container>
      <Heading>
        <HeadingLeft>
          <Text type="body" element="h3" size="lg" fontWeight={700}>
            <Trans i18nKey={`reports.level.${levelKey}`} />
          </Text>
          {completionPercentage >= 0 && (
            <StatusTagWithRoundIcon percentage={completionPercentage} />
          )}
        </HeadingLeft>
        <Text type="body" element="p" size="sm" fontWeight={400}>
          {levelNumber === 0 ? (
            ''
          ) : (
            <Trans i18nKey={`${areaCode}.${levelNumber}.description`} />
          )}
        </Text>
      </Heading>
      {practices.length > 0 && (
        <>
          {isShowAllOpen && (
            <PracticesContainer withoutPaddingRight={showPeerComparison}>
              {showPeerComparison && (
                <BenchmarkHeader>
                  <PeerDescription
                    type="body"
                    element="span"
                    size="2xs"
                    fontWeight={400}
                    color={baseColors.coolGrey600}
                  >
                    {t(`reports.peerComparison.description`)}
                  </PeerDescription>
                  <PeerLabel
                    type="body"
                    element="span"
                    size="xs"
                    fontWeight={700}
                  >
                    {t(`reports.peerComparison.label`)}
                  </PeerLabel>
                </BenchmarkHeader>
              )}
              {practices.map(practice => (
                <PracticeRow key={practice.id}>
                  <PracticeCard>
                    <TickIcon
                      fill={
                        practice.checked ? baseColors.purple600 : 'transparent'
                      }
                    />
                    <Text type="body" element="p" size="sm" fontWeight={400}>
                      {practice.title}
                    </Text>
                  </PracticeCard>
                  {showPeerComparison && (
                    <PeerComparison>
                      <PeerLabel
                        type="body"
                        element="span"
                        size="xs"
                        fontWeight={700}
                      >
                        {practice.peerComparison &&
                          `${Math.round(practice.peerComparison)}%`}
                      </PeerLabel>
                    </PeerComparison>
                  )}
                </PracticeRow>
              ))}
            </PracticesContainer>
          )}
          <AccordionControl
            withTotBorder={!isShowAllOpen}
            onClick={() => setIsShowAllOpen(!isShowAllOpen)}
          >
            <Text type="body" element="span" size="sm" fontWeight={500}>
              {isShowAllOpen
                ? t('reports.hideCriteria.label')
                : t('reports.showCriteria.label')}
            </Text>
          </AccordionControl>
        </>
      )}
    </Container>
  )
}
