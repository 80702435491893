import { FunctionComponent } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Container, SelectPicker } from 'rsuite'
import styled from 'styled-components'

import { Question } from 'store/questionTypes'
import { tCode } from 'utils/tCode'

const DropdownContainer = styled(Container)``

interface DropdownProps {
  question: Question
  selectedOption: number | undefined
  onChange: (value: any) => void
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  question,
  selectedOption,
  onChange,
}) => {
  const { t } = useTranslation()
  const tOption = tCode(t)
  const { code, title, options } = question ?? {}

  return (
    <DropdownContainer>
      <SelectPicker
        onChange={onChange}
        value={selectedOption}
        data={
          _.sortBy(options, ['priority'])?.map(option => ({
            value: option.id,
            label: tOption(option.code, 'title', option.title),
          })) ?? []
        }
        placeholder={tOption(code, 'placeholder', title)}
        searchable={false}
        maxHeight={150}
        size="lg"
      />
    </DropdownContainer>
  )
}
