import { useCallback, useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'
import { useMount } from 'react-use'
import { FileType } from 'rsuite/es/Uploader'

import { useArea } from 'hooks/useArea'
import { getAudit } from 'store/audit/auditSlice'
import {
  updateDocument,
  submitDocument,
  getAllDocumentType,
  getDocuments,
} from 'store/document/documentSlice'
import { State } from 'store/state'
import { isValidLink } from 'utils/isValidLink'
import { scrollTop } from 'utils/scrollTop'

import { DocumentStatus } from '../../store/document/types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { transformDocumentToFormData } from './transformDocumentToFormData'

export const useSubmitDocuments = () => {
  useArea()
  const dispatch = useAppDispatch()
  const { area, headers, documents, documentTypes, error, loading } =
    useAppSelector(
      (state: State) => ({
        area: state.areaReducer.area,
        headers: state.apiHeadersReducer,
        documents: state.documentReducer?.documents ?? [],
        documentTypes:
          state.documentReducer?.documentTypes?.filter(
            ({ area: areaType }) => state.areaReducer.area?.id === areaType?.id
          ) ?? [],
        error: state.documentReducer.error,
        loading: state.documentReducer.loading,
      }),
      shallowEqual
    )
  const [droppedFiles, setDroppedFiles] = useState<FileType[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [hasError, setHasError] = useState(false)
  const [skipping, setSkipping] = useState(false)
  const [redirect, setRedirect] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [link, setLink] = useState('')
  const type = documentTypes[currentIndex] ?? {}
  const documentByType = documents.find(
    ({ documentType }) => documentType?.id === type.id
  )
  const isProcessing = submitting || skipping
  const documentDefaultProps: any = {
    areaId: area?.id,
    sessionId: Number(headers.sessionId),
    companyId: Number(headers.companyId),
    accountId: Number(headers.accountId),
    documentTypeId: type.id,
  }
  if (link) {
    documentDefaultProps.links = [link]
  }
  // eslint-disable-next-line no-unsafe-optional-chaining
  const hasNext = currentIndex < documentTypes?.length - 1
  const redirectTo = `/audit/${area?.parent?.code}`

  const onNext = useCallback(() => {
    setHasError(false)
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (currentIndex < documentTypes?.length - 1) {
      setCurrentIndex(currentIndex + 1)
      scrollTop()
    }
    setDroppedFiles([])
  }, [currentIndex, documentTypes])

  const onUploadProgress = ({ loaded, total }: any) =>
    setProgress(Math.round((loaded * 100) / total))

  function onSubmit() {
    if (submitting) {
      return
    }
    const config = { onUploadProgress }
    if (skipping || (droppedFiles.length === 0 && !link)) {
      onSkipDocument()
      if (!hasNext) {
        setRedirect(redirectTo)
      }
      return
    }
    setSubmitting(true)
    setHasError(false)
    if (documentByType?.id) {
      const document: any = { status: 'pending' }
      if (link) {
        document.links = [link]
      }
      dispatch(
        updateDocument({
          area,
          id: documentByType.id,
          data: transformDocumentToFormData(document, droppedFiles),
          config,
        })
      )
    } else {
      dispatch(
        submitDocument({
          area,
          data: transformDocumentToFormData(
            { ...documentDefaultProps, status: 'pending' },
            droppedFiles
          ),
          config,
        })
      )
    }
  }

  function onSkipDocument() {
    if (submitting) {
      return
    }

    if (documentByType) {
      onNext()
    } else {
      setSkipping(true)
      dispatch(
        submitDocument({
          data: transformDocumentToFormData({
            ...documentDefaultProps,
            status: 'skipped',
          }),
        })
      )
    }
  }

  useMount(() => {
    if (!loading) {
      if (documentTypes.length === 0) {
        dispatch(getAllDocumentType())
      }

      if (documents.length === 0) {
        dispatch(getDocuments())
      }
    }
  })

  useEffect(() => {
    if (isProcessing && !loading) {
      setSubmitting(false)
      setSkipping(false)
      setProgress(0)
      setDroppedFiles([])
      setLink('')
      if (!error) {
        onNext()
        if (!hasNext) {
          dispatch(getAudit())
          setRedirect(redirectTo)
        }
      } else {
        setHasError(!!error)
      }
    }
  }, [
    area,
    dispatch,
    error,
    hasNext,
    isProcessing,
    loading,
    onNext,
    progress,
    redirectTo,
  ])

  return {
    onSubmit,
    onSkipDocument: documentByType ? undefined : onSkipDocument,
    type,
    currentIndex,
    skipping,
    submitting,
    setCurrentIndex,
    hasNext,
    disabled:
      (droppedFiles.length === 0 && !link && !documentByType) ||
      (!!link && !isValidLink(link)),
    redirect,
    droppedFiles,
    setDroppedFiles,
    document: documentByType?.status === 'skipped' ? undefined : documentByType,
    onLoadLink: (url: string) => setLink(url),
    progress,
    hasError,
    setHasError,
    link,
    skipped: documentByType?.status === DocumentStatus.SKIPPED,
  }
}
