import { FunctionComponent } from 'react'

import { Grid } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementsEnum } from 'store/company/types'
import { useAppSelector } from 'store/hooks'

import { State } from 'store/state'

import { Insights } from '../insights/Insights'

import { Areas } from './Areas'
import { AuditDILevel } from './AuditDILevel'
import { AuditEDIOverview } from './AuditEDIOverview'
import { MinorityRepresentation } from './MinorityRepresentation'

interface ReportsLandingWithGraphsProps {
  scoreData: {
    name: string
    scores: any[]
  }[]
}

export const ReportsLandingWithGraphs: FunctionComponent<
  ReportsLandingWithGraphsProps
> = ({ scoreData }) => {
  const { companyId } = useAppSelector(
    (state: State) => ({
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )

  const { t } = useTranslation()
  const { data: companyEntitlements } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  return (
    <Grid columns={6} gap={4}>
      <AuditDILevel span={3} />
      <AuditEDIOverview span={3} />

      {companyEntitlements?.entitlements.includes(
        EntitlementsEnum.REPORTS_INSIGHTS
      ) && <Insights span={6} />}

      {scoreData.map(({ name, scores }) =>
        scores?.length > 0 ? (
          <Areas
            key={name}
            scoreAreas={scores}
            span={3}
            title={t(`dashboard.categories.${name}`)}
          />
        ) : undefined
      )}

      <MinorityRepresentation span={3} />
    </Grid>
  )
}
