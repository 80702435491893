import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { customerApi } from './customerApi'
import { CustomerState, Customer, CustomerOptions } from './types'

const clear = createAction('customer/clear')

export const getSubscription = createAsyncThunk(
  'customer/getSubscription',
  async (_, { getState }) => {
    const response = await waitForResponse({
      callback: () => customerApi.getSubscription(getState),
    })
    return response
  }
)

export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (
    { customer, vat }: { customer: Customer; vat?: string },
    { getState }
  ) =>
    waitForResponse({
      callback: () => customerApi.createCustomer(getState, customer, vat),
    })
)
export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (options: CustomerOptions, { getState }) =>
    waitForResponse({
      callback: () => customerApi.updateCustomer(getState, options),
    })
)
export const getCustomer = createAsyncThunk(
  'customer/getCustomer',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => customerApi.getCustomer(getState),
    })
)
const cancelSubscription = createAsyncThunk(
  'customer/cancelSubscription',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => customerApi.cancelSubscription(getState),
    })
)

const initialState: Partial<CustomerState> = { loading: false }

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(cancelSubscription.fulfilled, state => {
        state.loading = false
      })
      .addCase(createCustomer.pending, state => {
        state.loading = true
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false
        state.customer = action.payload
      })
      .addCase(updateCustomer.pending, state => {
        state.loading = true
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false
        state.customer = action.payload
      })
      .addCase(getCustomer.pending, state => {
        state.loading = true
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false
        if (action?.payload) {
          state.customer = action.payload
        }
      })
      .addCase(getSubscription.pending, state => {
        state.loading = true
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) {
          const { status = '' } = action.payload
          state.subscription = action.payload
          state.isActiveSubscription =
            status === 'active' ||
            status === 'trialing' ||
            status === 'past_due'
        }
      })
      .addMatcher(isPending('customer'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('customer'), handleErrorState)
  },
})

export const { reducer: customerReducer } = customerSlice
