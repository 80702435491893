import { createSlice } from '@reduxjs/toolkit'

import { ApiHeadersState } from './types'

const initialState: Partial<ApiHeadersState> = {}

const apiHeadersSlice = createSlice({
  name: 'apiHeaders',
  initialState,
  reducers: {
    setSessionId(state, action) {
      state.sessionId = action.payload
    },
    setCompanyId(state, action) {
      state.companyId = action.payload
    },
    setAccountId(state, action) {
      state.accountId = action.payload
    },
    setApiVersion(state, action) {
      state.apiVersion = action.payload
    },
  },
})

export const { actions: apiHeadersActions, reducer: apiHeadersReducer } =
  apiHeadersSlice
