import { config } from '@fairhq/common'
import { http } from 'utils/http'

import { Assessment } from './types'

export const assessmentApi = {
  save(getState: () => unknown, assessment: Partial<Assessment>) {
    return http.post(
      getState,
      `${config.surveyUIEnabled ? 'surveys/' : ''}assessments`,
      assessment
    )
  },
  update(getState: () => unknown, id: string, assessment: Partial<Assessment>) {
    return http.patch(
      getState,
      `${config.surveyUIEnabled ? 'surveys/' : ''}assessments/${id}`,
      assessment
    )
  },
}
