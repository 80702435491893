const countries = [
  {
    label: 'United Kingdom',
    value: 'GBR',
    alt: ['Great Britain', 'England', 'UK'],
    children: [
      { label: 'Bath', value: 'Bath, GBR' },
      { label: 'Birmingham', value: 'Birmingham, GBR' },
      { label: 'Bradford', value: 'Bradford, GBR' },
      { label: 'Brighton and Hove', value: 'Brighton and Hove, GBR' },
      { label: 'Bristol', value: 'Bristol, GBR' },
      { label: 'Cambridge', value: 'Cambridge, GBR' },
      { label: 'Canterbury', value: 'Canterbury, GBR' },
      { label: 'Carlisle', value: 'Carlisle, GBR' },
      { label: 'Chester', value: 'Chester, GBR' },
      { label: 'Chichester', value: 'Chichester, GBR' },
      { label: 'Coventry', value: 'Coventry, GBR' },
      { label: 'Derby', value: 'Derby, GBR' },
      { label: 'Durham', value: 'Durham, GBR' },
      { label: 'Ely', value: 'Ely, GBR' },
      { label: 'Exeter', value: 'Exeter, GBR' },
      { label: 'Gloucester', value: 'Gloucester, GBR' },
      { label: 'Hereford', value: 'Hereford, GBR' },
      { label: 'Kingston upon Hull', value: 'Kingston upon Hull, GBR' },
      { label: 'Lancaster', value: 'Lancaster, GBR' },
      { label: 'Leeds', value: 'Leeds, GBR' },
      { label: 'Leicester', value: 'Leicester, GBR' },
      { label: 'Lichfield', value: 'Lichfield, GBR' },
      { label: 'Lincoln', value: 'Lincoln, GBR' },
      { label: 'Liverpool', value: 'Liverpool, GBR' },
      { label: 'London', value: 'London, GBR' },
      { label: 'Manchester', value: 'Manchester, GBR' },
      { label: 'Newcastle', value: 'Newcastle, GBR' },
      { label: 'Norwich', value: 'Norwich, GBR' },
      { label: 'Nottingham', value: 'Nottingham, GBR' },
      { label: 'Oxford', value: 'Oxford, GBR' },
      { label: 'Peterborough', value: 'Peterborough, GBR' },
      { label: 'Plymouth', value: 'Plymouth, GBR' },
      { label: 'Portsmouth', value: 'Portsmouth, GBR' },
      { label: 'Preston', value: 'Preston, GBR' },
      { label: 'Ripon', value: 'Ripon, GBR' },
      { label: 'Salford', value: 'Salford, GBR' },
      { label: 'Salisbury', value: 'Salisbury, GBR' },
      { label: 'Sheffield', value: 'Sheffield, GBR' },
      { label: 'Southampton', value: 'Southampton, GBR' },
      { label: 'St Albans', value: 'St Albans, GBR' },
      { label: 'Stoke-on-Trent', value: 'Stoke-on-Trent, GBR' },
      { label: 'Sunderland', value: 'Sunderland, GBR' },
      { label: 'Truro', value: 'Truro, GBR' },
      { label: 'Wakefield', value: 'Wakefield, GBR' },
      { label: 'Wells', value: 'Wells, GBR' },
      { label: 'Westminster', value: 'Westminster, GBR' },
      { label: 'Winchester', value: 'Winchester, GBR' },
      { label: 'Wolverhampton', value: 'Wolverhampton, GBR' },
      { label: 'Worcester', value: 'Worcester, GBR' },
      { label: 'York', value: 'York, GBR' },
      { label: 'Bangor', value: 'Bangor, GBR' },
      { label: 'Cardiff', value: 'Cardiff, GBR' },
      { label: 'Newport', value: 'Newport, GBR' },
      { label: 'St Davids', value: 'St Davids, GBR' },
      { label: 'Swansea', value: 'Swansea, GBR' },
      { label: 'Aberdeen', value: 'Aberdeen, GBR' },
      { label: 'Dundee', value: 'Dundee, GBR' },
      { label: 'Edinburgh', value: 'Edinburgh, GBR' },
      { label: 'Glasgow', value: 'Glasgow, GBR' },
      { label: 'Inverness', value: 'Inverness, GBR' },
      { label: 'Stirling', value: 'Stirling, GBR' },
      { label: 'Armagh', value: 'Armagh, GBR' },
      { label: 'Belfast', value: 'Belfast, GBR' },
      { label: 'Londonderry', value: 'Londonderry, GBR' },
      { label: 'Lisburn', value: 'Lisburn, GBR' },
      { label: 'Newry', value: 'Newry, GBR' },
    ],
  },
  {
    label: 'United States',
    value: 'USA',
    alt: ['United States of America', 'USA'],
    children: [
      { label: 'Alabama', value: 'Alabama, USA' },
      { label: 'Alaska', value: 'Alaska, USA' },
      { label: 'Arizona', value: 'Arizona, USA' },
      { label: 'Arkansas', value: 'Arkansas, USA' },
      { label: 'California', value: 'California, USA' },
      { label: 'Colorado', value: 'Colorado, USA' },
      { label: 'Connecticut', value: 'Connecticut, USA' },
      { label: 'Delaware', value: 'Delaware, USA' },
      { label: 'Florida', value: 'Florida, USA' },
      { label: 'Georgia', value: 'Georgia, USA' },
      { label: 'Hawaii', value: 'Hawaii, USA' },
      { label: 'Idaho', value: 'Idaho, USA' },
      { label: 'Illinois', value: 'Illinois, USA' },
      { label: 'Indiana', value: 'Indiana, USA' },
      { label: 'Iowa', value: 'Iowa, USA' },
      { label: 'Kansas', value: 'Kansas, USA' },
      { label: 'Kentucky', value: 'Kentucky, USA' },
      { label: 'Louisiana', value: 'Louisiana, USA' },
      { label: 'Maine', value: 'Maine, USA' },
      { label: 'Maryland', value: 'Maryland, USA' },
      { label: 'Massachusetts', value: 'Massachusetts, USA' },
      { label: 'Michigan', value: 'Michigan, USA' },
      { label: 'Minnesota', value: 'Minnesota, USA' },
      { label: 'Mississippi', value: 'Mississippi, USA' },
      { label: 'Missouri', value: 'Missouri, USA' },
      { label: 'Montana', value: 'Montana, USA' },
      { label: 'Nebraska', value: 'Nebraska, USA' },
      { label: 'Nevada', value: 'Nevada, USA' },
      { label: 'New Hampshire', value: 'New Hampshire, USA' },
      { label: 'New Jersey', value: 'New Jersey, USA' },
      { label: 'New Mexico', value: 'New Mexico, USA' },
      { label: 'New York', value: 'New York, USA' },
      { label: 'North Carolina', value: 'North Carolina, USA' },
      { label: 'North Dakota', value: 'North Dakota, USA' },
      { label: 'Ohio', value: 'Ohio, USA' },
      { label: 'Oklahoma', value: 'Oklahoma, USA' },
      { label: 'Oregon', value: 'Oregon, USA' },
      { label: 'Pennsylvania', value: 'Pennsylvania, USA' },
      { label: 'Rhode Island', value: 'Rhode Island, USA' },
      { label: 'South Carolina', value: 'South Carolina, USA' },
      { label: 'South Dakota', value: 'South Dakota, USA' },
      { label: 'Tennessee', value: 'Tennessee, USA' },
      { label: 'Texas', value: 'Texas, USA' },
      { label: 'Utah', value: 'Utah, USA' },
      { label: 'Vermont', value: 'Vermont, USA' },
      { label: 'Virginia', value: 'Virginia, USA' },
      { label: 'Washington', value: 'Washington, USA' },
      { label: 'West Virginia', value: 'West Virginia, USA' },
      { label: 'Wisconsin', value: 'Wisconsin, USA' },
      { label: 'Wyoming', value: 'Wyoming, USA' },
    ],
  },
  { label: 'Australia', value: 'AUS' },
  { label: 'Afghanistan', value: 'AFG' },
  { label: 'Albania', value: 'ALB' },
  { label: 'Algeria', value: 'DZA' },
  { label: 'American Samoa', value: 'ASM' },
  { label: 'Andorra', value: 'AND' },
  { label: 'Angola', value: 'AGO' },
  { label: 'Anguilla', value: 'AIA' },
  { label: 'Antarctica', value: 'ATA' },
  { label: 'Antigua and Barbuda', value: 'ATG' },
  { label: 'Argentina', value: 'ARG' },
  { label: 'Armenia', value: 'ARM' },
  { label: 'Aruba', value: 'ABW' },
  { label: 'Austria', value: 'AUT' },
  { label: 'Azerbaijan', value: 'AZE' },
  { label: 'Bahamas', value: 'BHS' },
  { label: 'Bahrain', value: 'BHR' },
  { label: 'Bangladesh', value: 'BGD' },
  { label: 'Barbados', value: 'BRB' },
  { label: 'Belarus', value: 'BLR' },
  { label: 'Belgium', value: 'BEL' },
  { label: 'Belize', value: 'BLZ' },
  { label: 'Benin', value: 'BEN' },
  { label: 'Bermuda', value: 'BMU' },
  { label: 'Bhutan', value: 'BTN' },
  { label: 'Bolivia', value: 'BOL' },
  { label: 'Bosnia and Herzegovina', value: 'BIH' },
  { label: 'Botswana', value: 'BWA' },
  { label: 'Brazil', value: 'BRA' },
  { label: 'Brunei', value: 'BRN' },
  { label: 'Bulgaria', value: 'BGR' },
  { label: 'Burkina Faso', value: 'BFA' },
  { label: 'Burundi', value: 'BDI' },
  { label: 'Cambodia', value: 'KHM' },
  { label: 'Cameroon', value: 'CMR' },
  { label: 'Canada', value: 'CAN' },
  { label: 'Cape Verde', value: 'CPV' },
  { label: 'Cayman Islands', value: 'CYM' },
  { label: 'Central African Republic', value: 'CAF' },
  { label: 'Chad', value: 'TCD' },
  { label: 'Chile', value: 'CHL' },
  { label: 'China', value: 'CHN' },
  { label: 'Colombia', value: 'COL' },
  { label: 'Comoros', value: 'COM' },
  {
    label: 'Congo (Democratic Republic)',
    value: 'COD',
    code2: 'CD',
    alt: ['DRC'],
  },
  { label: 'Congo (Republic)', value: 'COG' },
  { label: 'Costa Rica', value: 'CRI' },
  { label: 'Côte D’Ivoire', value: 'CIV' },
  { label: 'Croatia', value: 'HRV' },
  { label: 'Cuba', value: 'CUB' },
  { label: 'Cyprus', value: 'CYP' },
  { label: 'Czech Republic', value: 'CZE' },
  { label: 'Denmark', value: 'DNK' },
  { label: 'Djibouti', value: 'DJI' },
  { label: 'Dominica', value: 'DMA' },
  { label: 'Dominican Republic', value: 'DOM' },
  { label: 'Ecuador', value: 'ECU' },
  { label: 'Egypt', value: 'EGY' },
  { label: 'El Salvador', value: 'SLV' },
  { label: 'Equatorial Guinea', value: 'GNQ' },
  { label: 'Eritrea', value: 'ERI' },
  { label: 'Estonia', value: 'EST' },
  { label: 'Eswatini', value: 'SWZ' },
  { label: 'Ethiopia', value: 'ETH' },
  { label: 'Fiji', value: 'FJI' },
  { label: 'Finland', value: 'FIN' },
  { label: 'France', value: 'FRA' },
  { label: 'French Guiana', value: 'GUF' },
  { label: 'Gabon', value: 'GAB' },
  { label: 'Gambia', value: 'GMB' },
  { label: 'Georgia', value: 'GEO' },
  { label: 'Germany', value: 'DEU' },
  { label: 'Ghana', value: 'GHA' },
  { label: 'Greece', value: 'GRC' },
  { label: 'Greenland', value: 'GRL' },
  { label: 'Grenada', value: 'GRD' },
  { label: 'Guatemala', value: 'GTM' },
  { label: 'Guinea', value: 'GIN' },
  { label: 'Guinea-Bissau', value: 'GNB' },
  { label: 'Guyana', value: 'GUY' },
  { label: 'Haiti', value: 'HTI' },
  { label: 'Vatican', value: 'VAT' },
  { label: 'Honduras', value: 'HND' },
  { label: 'Hong Kong', value: 'HKG' },
  { label: 'Hungary', value: 'HUN' },
  { label: 'Iceland', value: 'ISL' },
  { label: 'India', value: 'IND' },
  { label: 'Indonesia', value: 'IDN' },
  { label: 'Iran', value: 'IRN' },
  { label: 'Iraq', value: 'IRQ' },
  { label: 'Ireland', value: 'IRL' },
  { label: 'Isle of Man', value: 'IMN' },
  { label: 'Israel', value: 'ISR' },
  { label: 'Italy', value: 'ITA' },
  { label: 'Jamaica', value: 'JAM' },
  { label: 'Japan', value: 'JPN' },
  { label: 'Jordan', value: 'JOR' },
  { label: 'Kazakhstan', value: 'KAZ' },
  { label: 'Kenya', value: 'KEN' },
  { label: 'Kiribati', value: 'KIR' },
  {
    label: 'North Korea',
    value: 'PRK',
    code2: 'KP',
    alt: ['Democratic People’s Republic of Korea', 'DPRK'],
  },
  { label: 'South Korea', value: 'KOR' },
  { label: 'Kuwait', value: 'KWT' },
  { label: 'Kyrgyzstan', value: 'KGZ' },
  {
    label: 'Laos',
    value: 'LAO',
    code2: 'LA',
    alt: ['Lao People’s Democratic Republic'],
  },
  { label: 'Latvia', value: 'LVA' },
  { label: 'Lebanon', value: 'LBN' },
  { label: 'Lesotho', value: 'LSO' },
  { label: 'Liberia', value: 'LBR' },
  { label: 'Libya', value: 'LBY' },
  { label: 'Liechtenstein', value: 'LIE' },
  { label: 'Lithuania', value: 'LTU' },
  { label: 'Luxembourg', value: 'LUX' },
  { label: 'Macao', value: 'MAC' },
  {
    label: 'Macedonia',
    value: 'MKD',
    code2: 'MK',
    alt: ['The Former Yugoslav Republic of Macedonia'],
  },
  { label: 'Madagascar', value: 'MDG' },
  { label: 'Malawi', value: 'MWI' },
  { label: 'Malaysia', value: 'MYS' },
  { label: 'Maldives', value: 'MDV' },
  { label: 'Mali', value: 'MLI' },
  { label: 'Malta', value: 'MLT' },
  { label: 'Marshall Islands', value: 'MHL' },
  { label: 'Martinique', value: 'MTQ' },
  { label: 'Mauritania', value: 'MRT' },
  { label: 'Mauritius', value: 'MUS' },
  { label: 'Mayotte', value: 'MYT' },
  { label: 'Mexico', value: 'MEX' },
  {
    label: 'Micronesia',
    value: 'FSM',
    code2: 'FM',
    alt: ['Federated States of Micronesia'],
  },
  { label: 'Moldova', value: 'MDA' },
  { label: 'Monaco', value: 'MCO' },
  { label: 'Mongolia', value: 'MNG' },
  { label: 'Montenegro', value: 'MNE' },
  { label: 'Morocco', value: 'MAR' },
  { label: 'Mozambique', value: 'MOZ' },
  { label: 'Myanmar', value: 'MMR' },
  { label: 'Namibia', value: 'NAM' },
  { label: 'Nauru', value: 'NRU' },
  { label: 'Nepal', value: 'NPL' },
  { label: 'Netherlands', value: 'NLD' },
  { label: 'New Zealand', value: 'NZL' },
  { label: 'Nicaragua', value: 'NIC' },
  { label: 'Niger', value: 'NER' },
  { label: 'Nigeria', value: 'NGA' },
  { label: 'Norway', value: 'NOR' },
  { label: 'Oman', value: 'OMN' },
  { label: 'Pakistan', value: 'PAK' },
  { label: 'Palau', value: 'PLW' },
  { label: 'Panama', value: 'PAN' },
  { label: 'Papua New Guinea', value: 'PNG' },
  { label: 'Paraguay', value: 'PRY' },
  { label: 'Peru', value: 'PER' },
  { label: 'Philippines', value: 'PHL' },
  { label: 'Poland', value: 'POL' },
  { label: 'Portugal', value: 'PRT' },
  { label: 'Puerto Rico', value: 'PRI' },
  { label: 'Qatar', value: 'QAT' },
  { label: 'Romania', value: 'ROU' },
  { label: 'Russia', value: 'RUS' },
  { label: 'Rwanda', value: 'RWA' },
  { label: 'St Helena', value: 'SHN' },
  {
    label: 'St Kitts & Nevis',
    value: 'KNA',
    code2: 'KN',
    alt: ['Saint Kitts and Nevis'],
  },
  { label: 'St Lucia', value: 'LCA' },
  {
    label: 'St Vincent & Grenadines',
    value: 'VCT',
    code2: 'VC',
    alt: ['Saint Vincent and the Grenadines'],
  },
  { label: 'Samoa', value: 'WSM' },
  { label: 'San Marino', value: 'SMR' },
  {
    label: 'São Tomé & Príncipe',
    value: 'STP',
    code2: 'ST',
    alt: ['Sao Tome and Principe'],
  },
  { label: 'Saudi Arabia', value: 'SAU' },
  { label: 'Senegal', value: 'SEN' },
  { label: 'Serbia', value: 'SRB' },
  { label: 'Seychelles', value: 'SYC' },
  { label: 'Sierra Leone', value: 'SLE' },
  { label: 'Singapore', value: 'SGP' },
  { label: 'Slovakia', value: 'SVK' },
  { label: 'Slovenia', value: 'SVN' },
  { label: 'Solomon Islands', value: 'SLB' },
  { label: 'Somalia', value: 'SOM' },
  { label: 'South Africa', value: 'ZAF' },
  { label: 'South Sudan', value: 'SSD' },
  { label: 'Spain', value: 'ESP' },
  { label: 'Sri Lanka', value: 'LKA' },
  { label: 'Sudan', value: 'SDN' },
  { label: 'Suriname', value: 'SUR' },
  { label: 'Sweden', value: 'SWE' },
  { label: 'Switzerland', value: 'CHE' },
  { label: 'Syria', value: 'SYR' },
  { label: 'Taiwan', value: 'TWN' },
  { label: 'Tajikistan', value: 'TJK' },
  { label: 'Tanzania', value: 'TZA' },
  { label: 'Thailand', value: 'THA' },
  { label: 'Timor-Leste', value: 'TLS' },
  { label: 'Togo', value: 'TGO' },
  { label: 'Tonga', value: 'TON' },
  { label: 'Trinidad and Tobago', value: 'TTO' },
  { label: 'Tunisia', value: 'TUN' },
  { label: 'Turkey', value: 'TUR' },
  { label: 'Turkmenistan', value: 'TKM' },
  { label: 'Tuvalu', value: 'TUV' },
  { label: 'Uganda', value: 'UGA' },
  { label: 'Ukraine', value: 'UKR' },
  { label: 'United Arab Emirates', value: 'ARE' },
  { label: 'Uruguay', value: 'URY' },
  { label: 'Uzbekistan', value: 'UZB' },
  { label: 'Vanuatu', value: 'VUT' },
  { label: 'Venezuela', value: 'VEN' },
  { label: 'Vietnam', value: 'VNM' },
  { label: 'Western Sahara', value: 'ESH' },
  { label: 'Yemen', value: 'YEM' },
  { label: 'Zambia', value: 'ZMB' },
  { label: 'Zimbabwe', value: 'ZWE' },
];

export default countries;
