import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  useGetReportByAreaQuery,
  useGetReportsQuery,
} from 'store/reports/reportsApiWithQuery'
import { ReportGroup } from 'store/reports/types'
import { State } from 'store/state'
import { count as countSurvey } from 'store/survey/surveySlice'
import { QueryStatus } from 'store/types'

// `getIsError` and `getIsLoading` are needed due to the limitations of hooks.
// Hooks (including RTK Query hooks) cannot be called conditionally. This means that
// we can't make API calls depending on the parameters of useDiversityRepresentation,
// so we have to make all API calls (though some of them might error silently)
// and then filter for which API calls we actually need in each case - and this includes
// filtering for which calls error and loading states we need

const getIsError = (
  type: string,
  isGenderError: boolean,
  isEthnicityError: boolean,
  isLocationError: boolean,
  isDepartmentError: boolean
): boolean => {
  if (!type && (isGenderError || isEthnicityError)) {
    return true
  }
  if (type === 'location' && isLocationError) {
    return true
  }
  if (type && isDepartmentError) {
    return true
  }
  return false
}

const getIsLoading = (
  type: string,
  isGenderLoading: boolean,
  isEthnicityLoading: boolean,
  isLocationLoading: boolean,
  isDepartmentLoading: boolean
): boolean => {
  if (!type && (isGenderLoading || isEthnicityLoading)) {
    return true
  }
  if (type === 'location' && isLocationLoading) {
    return true
  }
  if (type && isDepartmentLoading) {
    return true
  }
  return false
}

export const useDiversityRepresentation = (
  area?: string,
  group?: ReportGroup
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { code = '', type = '' } = useParams<{ type: string; code: string }>()

  const { companyBenchmark, surveyStatus, apiVersion, sessionId } =
    useAppSelector(
      (state: State) => ({
        companyBenchmark: state.companyReducer.company?.companyBenchmark,
        surveyStatus: state.surveyReducer.status,
        apiVersion: state.apiHeadersReducer.apiVersion,
        sessionId: state.apiHeadersReducer.sessionId,
      }),
      shallowEqual
    )

  useEffect(() => {
    if (!surveyStatus) {
      dispatch(countSurvey())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const {
    data: genderData,
    isLoading: isGenderLoading,
    isError: isGenderError,
  } = useGetReportByAreaQuery(
    {
      areaCode: area,
      group,
      projection: 'gender',
      apiVersion,
      sessionId,
    },
    { skip: area === undefined }
  )
  const gender = genderData?.representation.gender

  const {
    data: ethnicityData,
    isLoading: isEthnicityLoading,
    isError: isEthnicityError,
  } = useGetReportByAreaQuery(
    {
      areaCode: area,
      group,
      projection: 'ethnicity',
      apiVersion,
      sessionId,
    },
    { skip: area === undefined }
  )
  const ethnicity = ethnicityData?.representation.ethnicity

  const {
    data: locationData,
    isLoading: isLocationLoading,
    isError: isLocationError,
  } = useGetReportsQuery({
    type: 'location',
    apiVersion,
    sessionId,
  })
  const {
    data: departmentData,
    isLoading: isDepartmentLoading,
    isError: isDepartmentError,
  } = useGetReportsQuery({
    type: 'department',
    apiVersion,
    sessionId,
  })

  const representationByType =
    type === 'location'
      ? locationData?.companyByLocation?.find(
          ({ country }: any) => country === code
        )
      : departmentData?.companyByDepartment?.find(
          ({ department }: any) => department === code
        )

  const womenRepresentation =
    surveyStatus && type
      ? [
          {
            key: 'woman',
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (representationByType?.women * 100) /
                // eslint-disable-next-line no-unsafe-optional-chaining
                representationByType?.completed || 0
            ),
            label: t('reports.representation.woman.label'),
          },
          {
            key: 'woman_company',
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (surveyStatus?.woman * 100) / surveyStatus?.completed
            ),
            label: t('reports.representation.womanCompany.label'),
          },
        ]
      : [
          {
            key: 'woman',
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (gender?.[`${group}_gender`] * 100) / gender?.[`all_${group}`]
            ),
            label: t('reports.representation.woman.label'),
          },
        ]

  const pocRepresentation =
    surveyStatus && type
      ? [
          {
            key: 'poc',
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (representationByType?.poc * 100) /
                // eslint-disable-next-line no-unsafe-optional-chaining
                representationByType?.completed || 0
            ),
            label: t('reports.representation.poc.label'),
          },
          {
            key: 'poc_company',
            // eslint-disable-next-line no-unsafe-optional-chaining
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (surveyStatus?.poc * 100) / surveyStatus?.completed
            ),
            label: t('reports.representation.pocCompany.label'),
          },
        ]
      : [
          {
            key: 'poc',
            value: Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (ethnicity?.[`${group}_ethnicity`] * 100) /
                // eslint-disable-next-line no-unsafe-optional-chaining
                ethnicity?.[`all_${group}`]
            ),
            label: t('reports.representation.poc.label'),
          },
        ]

  const representationByGroup = [
    {
      key: 'gender',
      title: t('reports.representation.woman.title'),
      items: womenRepresentation,
      benchmark: {
        value: companyBenchmark?.women,
        label: t('reports.fairHQBenchmark.title'),
      },
    },
    {
      key: 'ethnicity',
      title: t('reports.representation.poc.title'),
      items: pocRepresentation,
      benchmark: {
        value: companyBenchmark?.people_of_color,
        label: t('reports.fairHQBenchmark.title'),
      },
    },
  ]

  const isError = getIsError(
    type,
    isGenderError,
    isEthnicityError,
    isLocationError,
    isDepartmentError
  )
  const isLoading = getIsLoading(
    type,
    isGenderLoading,
    isEthnicityLoading,
    isLocationLoading,
    isDepartmentLoading
  )

  let queryStatus = QueryStatus.SUCCESS
  if (isError) {
    queryStatus = QueryStatus.ERROR
  }
  if (isLoading) {
    queryStatus = QueryStatus.LOADING
  }

  return { representationByGroup, queryStatus }
}
