import { useCallback, useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { config } from '@fairhq/common'
import { emptySplitApi } from 'emptySplitApi'
import { clearAll } from 'store/clear'

import { useAppDispatch } from '../store/hooks'
import AuthContext from '../utils/auth'

export default function useSignOut() {
  const dispatch = useAppDispatch()
  const { logout } = useAuth0()
  const { setLoggedIn } = useContext(AuthContext)
  const signOut = useCallback(async () => {
    dispatch(clearAll())
    dispatch(emptySplitApi.util.resetApiState())
    localStorage.removeItem(config.tokenKey)
    localStorage.removeItem(config.employee)
    await logout({ returnTo: process.env.REACT_APP_PUBLIC_URL })
    setLoggedIn?.(false)
  }, [dispatch, logout, setLoggedIn])

  return signOut
}
