import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { Employee } from 'features/employees/types'
import { useSubmit } from 'hooks/useSubmit'
import { State } from 'store/state'

import { DataList, MessageBox } from '../../components'
import { useAppSelector } from '../../store/hooks'
import { Message } from '../../styled/message'

import { useSaveEmployeesMutation } from './emloyeesApi'
import { toEmployeeRow } from './utils'

interface ReviewProps {
  employees: Partial<Employee>[]
  next: () => void
  clear: () => void
}

export const Review: FunctionComponent<ReviewProps> = ({
  employees,
  next,
  clear,
}) => {
  const { t } = useTranslation()
  const { company, sessionId } = useAppSelector(
    (state: State) => ({
      company: state.companyReducer.company,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const [saveEmployees, { isSuccess, isLoading }] = useSaveEmployeesMutation()

  const { submitting, setSubmitting, onSubmit } = useSubmit(() => {
    saveEmployees({
      employees: employees.map(employee => ({
        ...employee,
        company: company?.id,
      })),
      sessionId,
    })
  })
  const headers = [
    { key: 'name', label: t('form.fullName.label'), size: 8 },
    { key: 'email', label: t('form.email.label'), size: 11 },
    { key: 'level', label: t('form.level.label'), size: 5 },
  ]

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
      if (isSuccess) {
        next()
      }
    }
  }, [isLoading, next, setSubmitting, submitting, isSuccess])

  return (
    <MessageBox
      btnLabel={t('employees.addEmployees.saveAndContinueToSurvey')}
      cancelBtnLabel={t('employees.addEmployees.startAgain')}
      onClick={() => onSubmit(true)}
      onCancel={clear}
      loading={submitting}
    >
      <div style={{ marginBottom: '72px' }}>
        <DataList
          headers={headers}
          rows={employees.map(toEmployeeRow(t, true))}
          preview
        />
      </div>
      <h3 style={{ marginTop: 0, marginBottom: '10px' }}>
        {t('employees.addEmployees.formattingLooksGood')}
      </h3>
      <Message style={{ marginBottom: '48px' }}>
        {t('employees.addEmployees.employeesImported', {
          count: employees.length,
        })}
      </Message>
    </MessageBox>
  )
}
