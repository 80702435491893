import React, { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IconButton, Text } from 'theme-ui'

import { baseColors, space } from '@fairhq/common'
import { Bin } from 'components/svgs/icons/Bin'
import { Edit } from 'components/svgs/icons/Edit'
import { Ellipsis } from 'components/svgs/icons/Ellipsis'
import { useDropdown } from 'hooks/useDropdown'

import {
  EditCampaignActions,
  ScheduledCampaignStatus,
  SlackChannel,
} from '../types'

import { CancelModal } from './modals/Campaign.CancelModal'
import { EditModal } from './modals/Campaign.EditModal'

const Container = styled.div`
  position: relative;
`

const DropdownMenu = styled.div`
  display: grid;
  grid-gap: ${space[1]}px;
  position: absolute;
  top: 100%;
  right: 0;
  white-space: nowrap;
  margin-top: ${space[1]}px;
  padding: ${space[2]}px;
  background-color: ${baseColors.white};
  border-radius: 6px;
  box-shadow: 0 3px 8px rgba(10, 27, 64, 15%);
  z-index: 1;
`

const DropdownOption = styled.div`
  border-radius: 6px;
  display: flex;
  text-align: left;
  cursor: pointer;
  align-items: center;
  color: ${baseColors.grey300};
  padding: ${space[2]}px ${space[3]}px;
  &:hover {
    background-color: ${baseColors.grey100};
    color: ${baseColors.purple};
  }
  svg {
    margin-right: 12px;
  }
`

export interface DropdownProps {
  campaignId: number
  scheduledCampaignId: number
  campaignCode: string
  startDate: string
  publishTime: string
  status: ScheduledCampaignStatus
  channel: SlackChannel
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  campaignId,
  scheduledCampaignId,
  campaignCode,
  startDate,
  publishTime,
  status,
  channel,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [actionType, setActionType] = useState<EditCampaignActions | null>(null)
  const {
    ref,
    opened: dropdownOpen,
    toggleDropdown,
    closeDropdown,
  } = useDropdown()

  const handleOptionClick = (actionType: EditCampaignActions) => {
    closeDropdown()
    setIsModalOpen(true)
    setActionType(actionType)
  }

  return (
    <Container ref={ref}>
      <IconButton
        sx={{
          width: '52px',
          height: '30px',
          minWidth: '52px',
          borderRadius: '100px',
          padding: '8px 18px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        variant="secondary"
        onClick={() => {
          toggleDropdown()
        }}
      >
        <Ellipsis />
      </IconButton>
      {dropdownOpen && (
        <DropdownMenu>
          {status === ScheduledCampaignStatus.PENDING && (
            <DropdownOption
              role="button"
              tabIndex={0}
              onClick={() => handleOptionClick(EditCampaignActions.EDIT_DATE)}
              onKeyDown={() => handleOptionClick(EditCampaignActions.EDIT_DATE)}
            >
              <Edit />
              <Text
                as="div"
                variant="label"
                sx={{
                  color: baseColors.grey500,
                  '&:hover': {
                    color: baseColors.purple,
                  },
                }}
              >
                {t('campaigns.drodpown.edit')}
              </Text>
            </DropdownOption>
          )}
          <DropdownOption
            role="button"
            tabIndex={0}
            onClick={() => handleOptionClick(EditCampaignActions.CANCEL)}
            onKeyDown={() => handleOptionClick(EditCampaignActions.CANCEL)}
          >
            <Bin />
            <Text
              as="div"
              variant="label"
              sx={{
                color: baseColors.grey500,
                '&:hover': {
                  color: baseColors.purple,
                },
              }}
            >
              {t('campaigns.dropdown.cancel')}
            </Text>
          </DropdownOption>
        </DropdownMenu>
      )}
      {isModalOpen && actionType === EditCampaignActions.CANCEL && (
        <CancelModal
          onClose={() => {
            setIsModalOpen(false)
            setActionType(null)
          }}
          campaignCode={campaignCode}
          scheduledCampaignId={scheduledCampaignId}
        />
      )}
      {isModalOpen && actionType === EditCampaignActions.EDIT_DATE && (
        <EditModal
          onClose={() => {
            setIsModalOpen(false)
            setActionType(null)
          }}
          scheduledCampaignId={scheduledCampaignId}
          initialStartDate={startDate}
          initialPublishTime={publishTime}
          campaignId={campaignId}
          initialChannel={channel}
        />
      )}
    </Container>
  )
}
