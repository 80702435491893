import { useState, FunctionComponent } from 'react'

import { motion } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'rsuite'
import styled, { css } from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Chevron, ChevronDirection, Clock } from '@fairhq/common'

import { ArrowWithBackground } from 'components/ArrowWithBackground'
import { Task as TaskType, TaskStatus } from 'store/tasks/types'

import { ScoreImpact } from './ScoreImpact'
import { StatusDropdown } from './StatusDropdown'

const Container = styled(motion.div)`
  border-radius: 10px;
  margin: 0 40px;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
`

// Needed because of a bug in framer motion, see
// https://github.com/framer/motion/issues/368
const PaddingContainer = styled.div`
  border-radius: inherit;
`

const HorizontalRule = styled.hr`
  border-color: ${baseColors.purple200};
  border-radius: 100px;
  margin-top: 24px;
  margin-bottom: 24px;
`

const StyledButton = styled(Button)`
  font-weight: bold;
  padding: 0 0 0 6px;
  margin-top: -1px;
  color: ${baseColors.purple800};
`

const StyledChevron = styled(Chevron)`
  margin-right: 12px;
`

/* stylelint-disable declaration-block-no-redundant-longhand-properties */
const HeadingContainer = styled.div<{
  backgroundColor: string
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 36px 36px 24px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextContainer = styled.div`
  background-color: ${baseColors.white};
  padding: 24px 36px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`

const ListContainer = styled.div`
  padding-top: 24px;
  border-radius: 4px;
`

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`

const ListItem = styled.li`
  &:not(:last-of-type) {
    padding-bottom: 8px;
  }
`

const TimeScoreContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const TimeContainer = styled.div<{ hasArrow: boolean }>`
  background-color: ${baseColors.purple200};
  color: ${baseColors.purple800};
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  position: relative;
  border-radius: ${({ hasArrow }) => (hasArrow ? '8px 0 0 8px' : '8px')};

  @media (max-width: 768px) {
    border-radius: 8px;
    margin-bottom: 8px;
  }

  ${({ hasArrow }) =>
    hasArrow &&
    css`
      @media (min-width: 768px) {
        &:after {
          content: '';
          position: absolute;
          height: 0;
          width: 0;
          left: 100%;
          top: 0;
          border: 15px solid transparent;
          border-left: 12px solid ${baseColors.purple200};
        }
      }
    `}
`

const StyledClock = styled(Clock)`
  margin-right: 8px;
`

const ScoreContainer = styled.div`
  background-color: ${baseColors.purple100};
  padding-left: 20px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 0 8px 8px 0;

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const getTaskColors = (
  status: string
): {
  headerBackgroundColor: string
} => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
    case TaskStatus.NOT_STARTED:
      return {
        headerBackgroundColor: baseColors.purple200,
      }
    case TaskStatus.COMPLETED:
    case TaskStatus.SKIPPED:
    default:
      return {
        headerBackgroundColor: baseColors.grey100,
      }
  }
}

interface TaskProps {
  task: TaskType
  disableAnimation?: boolean
  setSkippedTaskId: (id: number) => void
  setCompletedTaskCode: (code: string) => void
}

export const Task: FunctionComponent<TaskProps> = ({
  task: {
    code,
    status: initialStatus,
    id,
    oldRecommendation: { code: oldRecommendationCode },
    scoreImpact,
  },
  disableAnimation,
  setSkippedTaskId,
  setCompletedTaskCode,
}) => {
  const { t, i18n } = useTranslation()
  const [isReadMoreOpen, setIsReadMoreOpen] = useState<boolean>(false)
  const [currentStatus, setCurrentStatus] = useState(initialStatus)

  const { headerBackgroundColor } = getTaskColors(currentStatus)

  const hasBulletPoints = i18n.exists(`tasks.${code}.bulletPoints`)

  const { areaCode, current, projected } = scoreImpact || {}
  const showScoreChange = !!(
    areaCode &&
    current !== null &&
    current !== undefined &&
    projected !== null &&
    projected !== undefined
  )

  return (
    <Container
      initial={{ height: disableAnimation ? 'auto' : 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
    >
      <PaddingContainer>
        <HeadingContainer backgroundColor={headerBackgroundColor}>
          <Text variant="h3" sx={{ flexShrink: 100, pr: 3 }}>
            {t(`tasks.${code}.title`)}
          </Text>
          <StatusDropdown
            status={currentStatus}
            setStatus={setCurrentStatus}
            taskId={id}
            setSkippedTaskId={() => {
              setSkippedTaskId(id)
            }}
            setCompletedTask={() => {
              setCompletedTaskCode(code)
            }}
            onError={() => {
              setCurrentStatus(initialStatus)
            }}
          />
        </HeadingContainer>
        <TextContainer>
          {currentStatus !== TaskStatus.COMPLETED && (
            <TimeScoreContainer>
              <TimeContainer hasArrow={showScoreChange}>
                <StyledClock width="16" height="16" />
                <Text
                  variant="body"
                  sx={{ fontWeight: 600, lineHeight: '22px' }}
                >
                  {t(`tasks.${code}.timeEstimate`)}
                </Text>
              </TimeContainer>
              {showScoreChange && (
                <ScoreContainer>
                  <Text
                    variant="body"
                    sx={{
                      fontWeight: 600,
                      lineHeight: '22px',
                      color: baseColors.purple900,
                      mr: '8px',
                    }}
                  >
                    {t(`${areaCode}.title`)} score
                  </Text>
                  <ScoreImpact current={current} projected={projected} />
                </ScoreContainer>
              )}
            </TimeScoreContainer>
          )}
          <Text variant="bodyLarge">{t(`tasks.${code}.description`)}</Text>
          <HorizontalRule />
          <Footer>
            <div>
              {hasBulletPoints && (
                <StyledButton
                  appearance="link"
                  onClick={() => {
                    setIsReadMoreOpen(!isReadMoreOpen)
                  }}
                >
                  <StyledChevron
                    direction={
                      isReadMoreOpen
                        ? ChevronDirection.DOWN
                        : ChevronDirection.RIGHT
                    }
                  />
                  {t('tasks.readMore')}
                </StyledButton>
              )}
            </div>
            {oldRecommendationCode && (
              <StyledLink to={`/recommendations/${oldRecommendationCode}`}>
                <Text
                  sx={{
                    color: baseColors.purple800,
                    fontWeight: 600,
                    lineHeight: '24px',
                    fontSize: '16px',
                    mr: '8px',
                  }}
                >
                  {t('tasks.oldRecommendationLink')}
                </Text>
                <ArrowWithBackground />
              </StyledLink>
            )}
          </Footer>
          {hasBulletPoints && isReadMoreOpen && (
            <ListContainer>
              <List>
                <Text
                  variant="bodyLarge"
                  sx={{
                    lineHeight: '24px',
                    color: baseColors.grey500,
                    fontWeigth: '400',
                  }}
                >
                  <Trans
                    i18nKey={`tasks.${code}.bulletPoints`}
                    components={[<ListItem />]}
                  />
                </Text>
              </List>
            </ListContainer>
          )}
        </TextContainer>
      </PaddingContainer>
    </Container>
  )
}
