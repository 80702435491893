import React from 'react'

import { Box, Flex, Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Spinner } from '@fairhq/common'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementsEnum } from 'store/company/types'

import Button from '../../ui-kit/Button'

import { AddEmployeesWithCSV } from './AddEmployeesWithCSV'
import { AddEmployeesWhiteBox } from './components/AddEmployeesWhiteBox'

export const AddEmployees = () => {
  const { t } = useTranslation()

  const {
    data: companyEntitlements,
    isSuccess,
    isLoading,
  } = useGetEntitlementsQuery()

  if (
    isSuccess &&
    !companyEntitlements?.entitlements.includes(
      EntitlementsEnum.HRIS_INTEGRATION
    )
  ) {
    return <AddEmployeesWithCSV />
  }

  return (
    <Layout dark>
      <PageTop>
        <Heading>{t('employees.add.title')}</Heading>
      </PageTop>
      {isLoading && <Spinner center />}
      {isSuccess && (
        <AddEmployeesWhiteBox>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '300px',
            }}
          >
            <Text as="div" variant="bodyLargeBold" mb={6}>
              {t('employees.add.chooseHowToAddEmployees')}
            </Text>
            <Box mb={3}>
              <Button as={Link} to="/employees/add/integration">
                {t('employees.add.viaIntegrations')}
              </Button>
            </Box>
            <Box>
              <Button variant="secondary" as={Link} to="/employees/add/csv">
                {t('employees.add.viaCSV')}
              </Button>
            </Box>
          </Flex>
        </AddEmployeesWhiteBox>
      )}
    </Layout>
  )
}
