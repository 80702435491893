import { http } from 'utils/http'

import { Customer, CustomerOptions } from './types'

export const customerApi = {
  getSubscription(getState: () => unknown) {
    return http.get(getState, 'customer/subscription')
  },
  createCustomer(getState: () => unknown, customer: Customer, vat?: string) {
    return http.post(getState, 'customer/create', { customer, vat })
  },
  updateCustomer(getState: () => unknown, { customer, vat }: CustomerOptions) {
    return http.patch(getState, `customer/update`, { customer, vat })
  },
  getCustomer(getState: () => unknown) {
    return http.get(getState, `customer`)
  },
  cancelSubscription(getState: () => unknown) {
    return http.post(getState, 'customer/subscription/cancel', {})
  },
}
