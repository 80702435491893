import React, { FunctionComponent } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EmptyCircle, Text, TickCircleFilled, baseColors } from '@fairhq/common'

import { CompanyInitiativeStatusEnum } from 'store/initiatives/types'
import { ArchivedStrategy as ArchivedStrategyType } from 'store/strategy/types'

const Container = styled.article`
  background-color: ${baseColors.coolGrey300};
  border-radius: 20px;
  padding: 36px 32px 42px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${baseColors.coolGrey800};
`

const InnerWrapper = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const LeftColumn = styled(Column)`
  width: 40%;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const RightColumn = styled(Column)`
  width: 60%;

  @media (max-width: 800px) {
    width: 100%;
  }
`
const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
`

const PlainListItem = styled(ListItem)`
  list-style-type: none;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const PlainList = styled(List)`
  padding: 0;
`

interface ArchivedStrategyProps {
  archivedStrategy: ArchivedStrategyType
}

export const ArchivedStrategy: FunctionComponent<ArchivedStrategyProps> = ({
  archivedStrategy,
}) => {
  const { t } = useTranslation()

  const dateFormat = 'dd-MM-yyyy'

  return (
    <Container>
      <>
        {archivedStrategy.startedAt && archivedStrategy.endedAt && (
          <Text element="h3" type="body" size="lg" fontWeight={600}>
            {`Strategy from ${format(
              new Date(archivedStrategy.startedAt),
              dateFormat
            )}
         to ${format(new Date(archivedStrategy.endedAt), dateFormat)}
         `}
          </Text>
        )}
      </>

      <InnerWrapper>
        {archivedStrategy.companyInsights.length > 0 && (
          <LeftColumn>
            <Text element="h4" type="body" size="md" fontWeight={600}>
              {t('strategy.archive.focusTitle')}
            </Text>
            <List>
              {archivedStrategy.companyInsights.map(companyInsight => (
                <li key={companyInsight.id}>
                  {companyInsight.insight.description}
                </li>
              ))}
            </List>
          </LeftColumn>
        )}

        {archivedStrategy.companyInitiatives.length > 0 && (
          <RightColumn>
            <Text
              element="h4"
              type="body"
              size="md"
              fontWeight={600}
              color={baseColors.coolGrey800}
            >
              {t('strategy.archive.approachTitle')}
            </Text>
            <PlainList>
              {archivedStrategy.companyInitiatives.map(companyInitiative => (
                <PlainListItem key={companyInitiative.id}>
                  {companyInitiative.status ===
                  CompanyInitiativeStatusEnum.COMPLETED ? (
                    <TickCircleFilled />
                  ) : (
                    <EmptyCircle />
                  )}
                  {companyInitiative.recommendation.description}
                </PlainListItem>
              ))}
            </PlainList>
          </RightColumn>
        )}
      </InnerWrapper>
    </Container>
  )
}
