import { useState, useEffect, useMemo, FunctionComponent } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Heading } from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import useSchema from 'hooks/use-yup-schema'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { Layout } from 'layout/Layout'
import { updateCompany } from 'store/company/companySlice'
import { State } from 'store/state'
import Button from 'ui-kit/Button'
import Field from 'ui-kit/Field'
import Input from 'ui-kit/Input'
import { Select } from 'ui-kit/Select'
import { locationOptions } from 'utils/locationOptions'
import sectors from 'utils/sectors'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { SettingsSidebar } from './SettingsSidebar'

type FormData = {
  name: string
  sector: string
  size: string
  location: string
}

export const CompanySettings: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [sending, setSending] = useState(false)
  const { isOwner } = useAuthRoles()

  const { company, loading } = useAppSelector(
    (state: State) => ({
      company: state.companyReducer.company,
      loading: state.companyReducer.loading,
    }),
    shallowEqual
  )

  const defaultValues = useMemo(
    () => ({
      name: company?.name || '',
      sector: company?.sector || '',
      size: company?.size || '',
      location: company?.location || '',
    }),
    [company]
  )

  const schema = useSchema(['name', 'sector', 'size', 'location'])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  })

  // update input values when data are fetched
  useEffect(() => {
    if (company) {
      reset(defaultValues)
    }
  }, [company, reset, defaultValues])

  const onSubmit = (data: FormData) => {
    if (shallowEqual(data, defaultValues)) {
      return
    }

    const { name, sector, size, location } = data

    if (company?.id) {
      dispatch(
        updateCompany({
          id: company.id,
          company: {
            name,
            sector,
            size,
            location,
          },
        })
      )

      setSending(true)
    }
  }

  useEffect(() => {
    if (sending && !loading) {
      setSending(false)
    }
  }, [sending, loading, setSending])

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <Heading variant="subtitle" sx={{ mb: 10 }}>
        {t('settings.companyInfo')}
      </Heading>

      {!!company && (
        <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={{ maxWidth: 480 }}>
          <Grid gap="4" sx={{ mb: 6 }}>
            <Field
              label={t('form.name.label')}
              placeholder={t('form.name.placeholder')}
              readOnly={!isOwner}
              error={errors.name?.message}
              {...register('name')}
            />

            <Field
              as={Select}
              label={t('form.sector.label')}
              readOnly={!isOwner}
              error={errors.sector?.message}
              {...register('sector')}
            >
              {sectors.map(sector => (
                <option key={sector.value} value={sector.value}>
                  {t(sector.label)}
                </option>
              ))}
            </Field>

            <Field
              as={Input}
              type="number"
              label={t('form.size.label')}
              readOnly={!isOwner}
              error={errors.size?.message}
              {...register('size')}
            />

            <Field
              as={Select}
              label={t('form.location.label')}
              readOnly={!isOwner}
              error={errors.location?.message}
              {...register('location')}
            >
              {locationOptions.map(location => (
                <option key={location.value} value={location.value}>
                  {t(location.label)}
                </option>
              ))}
            </Field>
          </Grid>
          {isOwner ? (
            <Button
              type="submit"
              size="large"
              loading={sending}
              disabled={!isOwner}
            >
              {t('saveChanges')}
            </Button>
          ) : undefined}
        </Box>
      )}
    </Layout>
  )
}
