import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

const ListItem = styled.li<{ greyedOut: boolean }>`
  background-color: ${({ greyedOut }) =>
    greyedOut ? baseColors.coolGrey300 : baseColors.white};
  border-radius: 20px;
  padding: 24px 32px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
  flex-grow: 1;
  flex-basis: 23.8%;
`

interface InsightProps {
  title: string
  greyedOut?: boolean
}

export const Insight: FunctionComponent<InsightProps> = ({
  title,
  greyedOut = false,
}) => (
  <ListItem greyedOut={greyedOut}>
    <Text
      element="p"
      type="body"
      size="md"
      fontWeight={600}
      color={greyedOut ? baseColors.coolGrey800 : baseColors.purple900}
    >
      {title}
    </Text>
  </ListItem>
)
