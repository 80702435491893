import { orderBy } from 'lodash'

import { Colors } from '@fairhq/common'
import { GraphItem } from 'components/visualization/types'
import { tCode } from 'utils/tCode'

import { REPORTS_BLUE } from './getDemGroupRepresentationByTab'

export const getRepresentationByTab = ({
  jobLevelType,
  jobLevel,
  group,
  location,
  department,
  groupType,
  t,
  sorted,
}: Record<
  | 'jobLevel'
  | 'group'
  | 'groupType'
  | 'location'
  | 'department'
  | 'totalSurveysCompleted'
  | string,
  any
>) => {
  if (groupType) {
    const getValues = ({ minority = 0, majority = 0, completed = 0 }: any) => [
      {
        value: Math.round((minority * 100) / completed || 0),
        color: Colors.PURPLE,
      },
      {
        value: Math.round((majority * 100) / completed || 0),
        color: REPORTS_BLUE,
      },
      {
        value:
          100 -
          Math.round((minority * 100) / completed || 0) -
          Math.round((majority * 100) / completed || 0),
        color: Colors.GREY100,
        hideLabel: true,
      },
    ]

    return {
      byJobLevel: jobLevelType.map(
        ({ key, label, minority, majority, total: completed }: any) => ({
          key,
          label,
          values: getValues({ completed, minority, majority }),
          max: completed,
        })
      ),
      byLocation:
        location?.map(({ country, completed, minority, majority }: any) => ({
          key: country,
          label: tCode(t)(`country.${country || 'na'}`, '', country),
          values: getValues({ completed, minority, majority }),
          max: completed,
        })) ?? [],
      byDepartment:
        department?.map(
          ({ department: key, completed, minority, majority }: any) => ({
            key,
            label: key || 'na',
            values: getValues({ completed, minority, majority }),
            max: completed,
          })
        ) ?? [],
    }
  }

  return {
    byJobLevel: orderBy(
      jobLevelType?.map(({ key, label }: any) => ({
        key,
        label,
        value: jobLevel?.[`all_${key}_${group}`],
        max: jobLevel?.[`all_${key}`],
        // eslint-disable-next-line no-unsafe-optional-chaining
        total: jobLevel?.[`all_${key}_${group}`] / jobLevel?.[`all_${key}`],
      })),
      sorted ? ['total'] : []
    ),
    byLocation: orderBy(
      location?.map(({ country, total, max }: any) => ({
        key: country,
        label: tCode(t)(`country.${country || 'na'}`, '', country),
        value: total,
        max,
        total: total / max,
      })) ?? [],
      ['total']
    ),
    byDepartment: orderBy(
      department?.map(({ department: key, total, max }: any) => ({
        key,
        label: key || 'na',
        value: total,
        max,
        total: total / max,
      })) ?? [],
      ['total']
    ),
  } as Record<string, GraphItem[]>
}
