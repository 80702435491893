import { chain, groupBy, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { colorGetter } from 'components/visualization/utils/colorGetter'
import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'
import { tCode } from 'utils/tCode'

import { DemGroupType } from '../types'
import { getScore } from '../utils/getScore'

import { useReportsParams } from './useReportsParams'

export const useGroupScoreBreakdown = (area: string) => {
  const { t } = useTranslation()
  const { code: rawCode, type } = useReportsParams()
  const code = customDecodeURIComponent(rawCode)

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: typeReportsData,
    isLoading: isTypeReportsLoading,
    isError: isTypeReportsError,
  } = useGetReportsQuery({ type, apiVersion, sessionId })
  const representation = typeReportsData?.experience

  const {
    data: demGroupsData,
    isLoading: isDemGroupsLoading,
    isError: isDemGroupsError,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })
  const general = demGroupsData?.experienceGeneral ?? []
  const minority = demGroupsData?.companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code && dem_group_type === DemGroupType.MINORITY
  )
  const majority = demGroupsData?.companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code && dem_group_type === DemGroupType.MAJORITY
  )

  const demGroups =
    ((type !== 'dem_groups' &&
      typeReportsData?.experienceByDemGroup?.filter(
        ({ _group }: any) => _group === code
      )) ||
      demGroupsData?.experience?.filter(
        ({ dem_group_group }: any) => !code || dem_group_group === code
      )) ??
    []

  const demGroupsDataExperience = code
    ? demGroupsData?.experience
    : demGroupsData?.experience?.filter(
        ({ dem_group_group }: { dem_group_group: string }) =>
          dem_group_group === code
      ) ?? []

  const {
    data: locationData,
    isLoading: isLocationLoading,
    isError: isLocationError,
  } = useGetReportsQuery({ type: 'location', apiVersion, sessionId })
  const {
    data: departmentData,
    isLoading: isDepartmentLoading,
    isError: isDepartmentError,
  } = useGetReportsQuery({
    type: 'department',
    apiVersion,
    sessionId,
  })
  const {
    data: jobLevelData,
    isLoading: isJobLevelLoading,
    isError: isJobLevelError,
  } = useGetReportsQuery({
    type: 'job_level',
    apiVersion,
    sessionId,
  })

  const minorities =
    (type === 'location' &&
      locationData?.companyByDemGroupAndLocation?.filter(
        ({ country, dem_group_type }: any) =>
          dem_group_type === DemGroupType.MINORITY && country === code
      )) ||
    (type === 'department' &&
      departmentData?.companyByDemGroupAndDepartment?.filter(
        ({ department, dem_group_type }: any) =>
          dem_group_type === DemGroupType.MINORITY && department === code
      )) ||
    (type === 'job_level' &&
      jobLevelData?.companyByDemGroupAndJobLevel?.filter(
        ({ level, dem_group_type }: any) =>
          dem_group_type === DemGroupType.MINORITY && level === code
      )) ||
    demGroupsData?.companyByDemGroup?.filter(
      ({ dem_group_type }: any) => dem_group_type === DemGroupType.MINORITY
    )

  const isError =
    isTypeReportsError ||
    isDemGroupsError ||
    isLocationError ||
    isDepartmentError ||
    isJobLevelError
  const isLoading =
    isTypeReportsLoading ||
    isDemGroupsLoading ||
    isLocationLoading ||
    isDepartmentLoading ||
    isJobLevelLoading
  let queryStatus = QueryStatus.SUCCESS
  if (isError) {
    queryStatus = QueryStatus.ERROR
  }
  if (isLoading) {
    queryStatus = QueryStatus.LOADING
  }

  let headers = [
    { key: 'companyScore', content: t('reports.overview.companyScore') },
  ]

  if (!code && type !== 'dem_groups') {
    const groups = chain(representation).groupBy('_group').value()
    headers = headers.concat(
      ...((Object.keys(groups) || [])?.map(group => ({
        key: group,
        content: t(`reports.label.${group}`, group),
      })) ?? [])
    )

    const generalByArea = chain(general).keyBy('parent_area_code').value()

    const items = Object.keys(generalByArea)
      .filter(parentAreaCode => parentAreaCode?.includes(area))
      .map(parentAreaCode => {
        const scores: Record<string, { value: number; color: any }> = {
          companyScore: {
            value: getScore(general, parentAreaCode) / 10,
            color: colorGetter()({
              value: +getScore(general, parentAreaCode),
            } as any),
          },
        }

        Object.keys(groups)?.forEach(group => {
          scores[group] = {
            value: getScore(groups[group], parentAreaCode) / 10,
            color: colorGetter()({
              value: +getScore(groups[group], parentAreaCode),
            } as any),
          }
        })

        return {
          icon: parentAreaCode,
          category: t(
            `${parentAreaCode}.experience`,
            generalByArea?.[parentAreaCode]?.parent_area_title
          ),
          scores,
        }
      })

    return { headers, items, queryStatus }
  }

  headers.push({
    key:
      code && type === 'dem_groups'
        ? majority?.dem_group_code
        : DemGroupType.MAJORITY,
    content:
      code && type === 'dem_groups'
        ? tCode(t)(majority?.dem_group_code, '', majority?.dem_group_title)
        : t('reports.overview.traditionalMajority'),
  })

  const {
    minority: minorityScores,
    majority: majorityScores,
    'all majority': traditionalMajorityScores,
  } = chain(demGroups).groupBy('dem_group_type').value()

  if (code && type === 'dem_groups' && minority) {
    headers.push({
      key: minority?.dem_group_code,
      content: tCode(t)(
        minority?.dem_group_code,
        '',
        minority?.dem_group_title
      ),
    })
  }

  if (code && type !== 'dem_groups' && minorities.length > 0) {
    headers = headers.concat(
      minorities.map(({ dem_group_code, dem_group_title }: any) => ({
        key: dem_group_code,
        content: tCode(t)(dem_group_code, '', dem_group_title),
      }))
    )
  }
  const narrowMinorities: {
    [key: string]: any
  } = groupBy(
    demGroupsDataExperience?.filter(
      (item: any) =>
        item?.dem_group_type === null && item._group?.includes(code)
    ),
    '_group'
  )
  const allNarrowGroups = Object.keys(narrowMinorities)
  const narrowMinoritiesHeaders = orderBy(
    allNarrowGroups?.map((key: string) => ({
      key,
      content: t(key),
    })),
    'content'
  )

  headers.push(...narrowMinoritiesHeaders)

  const generalByArea = chain(general).keyBy('parent_area_code').value()

  const items = Object.keys(generalByArea)
    .filter(parentAreaCode => parentAreaCode?.includes(area))
    .map(parentAreaCode => {
      const narrowGroupScores: { [key: string]: any } = {}
      for (const item of narrowMinoritiesHeaders) {
        narrowGroupScores[item.key] = {
          value: getScore(narrowMinorities[item.key], parentAreaCode) / 10,
          color: colorGetter()({
            value: +getScore(narrowMinorities[item.key], parentAreaCode),
          } as any),
        }
      }

      const subItems = {
        ...(majority
          ? {
              [majority.dem_group_code]: {
                value: getScore(majorityScores, parentAreaCode) / 10,
                color: colorGetter()({
                  value: +getScore(majorityScores, parentAreaCode),
                } as any),
              },
            }
          : {}),
        ...(minority
          ? {
              [minority.dem_group_code]: {
                value: getScore(minorityScores, parentAreaCode) / 10,
                color: colorGetter()({
                  value: +getScore(minorityScores, parentAreaCode),
                } as any),
              },
            }
          : {}),
        ...narrowGroupScores,
      }

      const generalItems = minorities?.map((min: any) => ({
        group: min?.dem_group_code,
        value:
          getScore(
            minorityScores?.filter(
              ({ dem_group_group }: any) =>
                dem_group_group === min?.dem_group_group
            ),
            parentAreaCode
          ) / 10,
        color: colorGetter()({
          value: +getScore(
            minorityScores?.filter(
              ({ dem_group_group }: any) =>
                dem_group_group === min?.dem_group_group
            ),
            parentAreaCode
          ),
        } as any),
      }))

      generalItems.unshift({
        group: DemGroupType.MAJORITY,
        value: getScore(traditionalMajorityScores, parentAreaCode) / 10,
        color: colorGetter()({
          value: +getScore(traditionalMajorityScores, parentAreaCode),
        } as any),
      })

      return {
        icon: parentAreaCode,
        category: t(
          `${parentAreaCode}.experience`,
          generalByArea?.[parentAreaCode]?.parent_area_title
        ),
        scores: {
          companyScore: {
            value: getScore(general, parentAreaCode) / 10,
            color: colorGetter()({
              value: +getScore(general, parentAreaCode),
            } as any),
          },
          ...(code && type === 'dem_groups'
            ? subItems
            : chain(generalItems).keyBy('group').value()),
        },
      }
    })

  return { items, headers, queryStatus }
}
