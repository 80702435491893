import React from 'react'

import { Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

function BetaBadge({ sx }: { sx?: ThemeUIStyleObject }) {
  return (
    <Text
      as="div"
      variant="caps"
      sx={{
        fontSize: 11,
        lineHeight: '18px',
        color: 'grey300',
        fontFamily: 'body',
        ...sx,
      }}
    >
      BETA
    </Text>
  )
}

export default BetaBadge
