import { shallowEqual } from 'react-redux'

import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { Session } from 'store/company/types'
import { useAppSelector } from 'store/hooks'
import {
  useGetLatestScoresQuery,
  useGetScoresForSessionQuery,
} from 'store/score/scoreApi'
import { AreasScoring, Scores } from 'store/score/types'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

const getSortedScoreAreas = (
  scores: AreasScoring | null
):
  | {
      name: string
      score: number
      max: number
    }[]
  | null => {
  if (!scores) {
    return null
  }
  const scoreAreas = []

  if (scores) {
    for (const key in scores) {
      if (Object.prototype.hasOwnProperty.call(scores, key)) {
        scoreAreas.push({
          name: key,
          score: scores[key].score ?? 0,
          max: scores[key].max ?? 0,
        })
      }
    }
  }

  const ordering: any = { equality: 0, diversity: 1, inclusion: 2 }
  const sortedScoreAreas = scoreAreas.sort(
    (a, b) => ordering[a.name] - ordering[b.name]
  )

  return sortedScoreAreas
}

const getRelevantScore = (
  latestScores: Scores | undefined,
  isLatestSessionScorable: boolean | undefined,
  previousSession: Session | undefined,
  previousSessionScores: Scores | undefined
) => {
  if (latestScores && isLatestSessionScorable) {
    return latestScores
  }
  if (previousSession && previousSession.scorable && previousSessionScores) {
    return previousSessionScores
  }
  return {
    areasScoring: null,
    dniLevel: null,
  }
}

export const useScoresOverview = () => {
  const { allSessions, latestSession } = useAppSelector(
    (state: State) => ({
      allSessions: state.companyReducer.company?.sessions,
      latestSession: state.companyReducer.latestSession,
    }),
    shallowEqual
  )

  const previousSession =
    allSessions && allSessions.length > 1 ? allSessions[1] : {}

  const {
    data: latestScores,
    isLoading: isGetLatestScoresLoading,
    isError: isGetLatestScoresError,
  } = useGetLatestScoresQuery({
    apiVersion: latestSession?.apiVersion,
    sessionId: latestSession?.id,
  })
  const {
    data: previousSessionScores,
    isLoading: isGetScoresForSessionLoading,
    isError: isGetScoresForSessionError,
  } = useGetScoresForSessionQuery({
    apiVersion: previousSession?.apiVersion,
    sessionId: previousSession?.id,
  })

  const {
    data: isLatestSessionScorable,
    isLoading: isGetIsScorableLoading,
    isError: isGetIsScorableError,
  } = useGetIsScorableQuery({
    apiVersion: latestSession?.apiVersion,
    sessionId: latestSession?.id,
  })

  if (
    isGetLatestScoresLoading ||
    isGetScoresForSessionLoading ||
    isGetIsScorableLoading
  ) {
    return {
      status: QueryStatus.LOADING,
      scoreAreas: null,
      dniLevel: null,
    }
  }

  if (
    isGetLatestScoresError ||
    isGetScoresForSessionError ||
    isGetIsScorableError
  ) {
    return {
      status: QueryStatus.ERROR,
      scoreAreas: null,
      dniLevel: null,
    }
  }

  const scores = getRelevantScore(
    latestScores,
    isLatestSessionScorable,
    previousSession,
    previousSessionScores
  )

  return {
    status: QueryStatus.SUCCESS,
    scoreAreas: getSortedScoreAreas(scores.areasScoring),
    dniLevel: scores.dniLevel,
  }
}
