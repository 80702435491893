import { FunctionComponent } from 'react'

import { Label } from '@theme-ui/components'
import enGB from 'date-fns/locale/en-GB'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

import { baseColors } from '../../primitives/colors'
import { Calendar } from '../icons/Calendar'

import { Input } from './Input'

// We can make this more dynamic if needed in the future
registerLocale('en-GB', enGB)

const Container = styled.div`
  * {
    font-family: inherit;
  }

  /* Input placeholder text */
  input {
    ::placeholder {
      color: ${baseColors.grey400};
    }
  }

  /* Entire popper */
  .react-datepicker {
    border-color: ${baseColors.grey200};
    border-radius: 20px;
  }

  /* Triangle position */
  .react-datepicker__triangle {
    transform: translate3d(150px, 0, 0) !important;
  }

  /* Inside of triangle */
  .react-datepicker-popper .react-datepicker__triangle::after {
    border-bottom-color: ${baseColors.white};
  }

  /* Triangle border */
  .react-datepicker-popper .react-datepicker__triangle::before {
    border-bottom-color: ${baseColors.grey200};
  }

  /* Previous and next arrows */
  .react-datepicker__navigation-icon::before {
    border-color: ${baseColors.grey500};
    border-width: 2px 2px 0 0;
    width: 7px;
    height: 7px;
  }

  /* Previous and next arrows placement */
  .react-datepicker__navigation {
    margin-top: 24px;
  }

  /* Popper header area */
  .react-datepicker__header {
    background-color: ${baseColors.white};
    border: none;
    border-radius: 20px;
    padding: 0;
    margin-top: 16px;
  }

  /* Current month header */
  .react-datepicker__current-month {
    color: ${baseColors.grey500};
    font-size: 16px;
    font-weight: normal;
  }

  /* Day names container */
  .react-datepicker__day-names {
    margin-top: 16px;
    margin-bottom: 0;
  }

  /* Individual day names */
  .react-datepicker__day-name {
    color: ${baseColors.grey400};
    font-weight: bold;
    font-size: 11px;
    width: 40px;
    height: 40px;
    margin: 0;
    line-height: 40px;
  }

  /* Days of the month container */
  .react-datepicker__month {
    margin-top: 0;
  }

  /* Days */
  .react-datepicker__day {
    font-size: 13px;
    color: ${baseColors.grey500};
    border-radius: 50%;
    border: 1px solid transparent;
    height: 40px;
    width: 40px;
    margin: 0;
    line-height: 38px;
    outline: none;

    &:hover {
      background-color: ${baseColors.white};
      color: ${baseColors.purple};
      border-color: ${baseColors.purple};
      font-weight: 600;
    }
  }

  /* Selected days */
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${baseColors.purple} !important;
    color: ${baseColors.white};
    font-weight: bold;

    &:hover {
      background-color: ${baseColors.white} !important;
      color: ${baseColors.purple};
      border-color: ${baseColors.purple};
    }
  }

  /* Disabled days */
  .react-datepicker__day--disabled {
    color: ${baseColors.grey300};

    &:hover {
      background-color: ${baseColors.white};
      border-color: transparent;
      color: ${baseColors.grey300};
      font-weight: normal;
    }
  }

  /* Today */
  .react-datepicker__day--today {
    background-color: ${baseColors.grey200};

    &:hover {
      background-color: ${baseColors.grey200};
      font-weight: bold;
    }

    &.react-datepicker__day--disabled {
      color: ${baseColors.grey400};
    }
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background-color: ${baseColors.white} !important;
    color: ${baseColors.grey500};
    font-weight: normal;
  }
`

const StyledDatePicker = styled(ReactDatePicker)`
  border: 1px solid ${baseColors.grey200};
  border-radius: 8px;
  padding: 14px;
  color: ${baseColors.grey500};
  width: 100%;
`

export interface DatePickerProps {
  value: Date | null
  onChange: (date: Date | null) => void
  id: string
  label: string
  minDate?: Date
  maxDate?: Date
  filterDate?: (date: Date) => boolean
  placeholder?: string
  className?: string
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  onChange,
  id,
  label,
  minDate,
  maxDate,
  filterDate,
  placeholder,
  className,
}) => (
  <Container className={className}>
    <Label
      htmlFor={id}
      sx={{
        mb: 1,
        fontWeight: 'bold',
        fontSize: 13,
        color: baseColors.grey500,
      }}
    >
      {label}
    </Label>
    <StyledDatePicker
      selected={value}
      onChange={onChange}
      dateFormat="dd/MM/yyyy"
      minDate={minDate}
      maxDate={maxDate}
      filterDate={filterDate}
      locale="en-GB" // Needed for weeks to start on Monday
      placeholderText={placeholder}
      customInput={<Input icon={<Calendar />} showArrow fieldId={id} />}
    />
  </Container>
)
