import { FunctionComponent } from 'react'

import { Input, InputGroup } from 'rsuite'

export const InputButton: FunctionComponent<any> = ({
  btnLabel,
  onClick,
  ...restOfProps
}) => (
  <InputGroup>
    <Input {...restOfProps} />
    <InputGroup.Button onClick={onClick}>{btnLabel}</InputGroup.Button>
  </InputGroup>
)
