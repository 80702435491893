import { FunctionComponent } from 'react'

import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as StepIcon } from './assets/step.svg'
import { ReactComponent as StepActiveIcon } from './assets/step_active.svg'
import { ReactComponent as StepDoneIcon } from './assets/step_done.svg'

const StepsIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  top: -123px;
  height: 12px;
  cursor: default;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
  }

  svg:not(:last-of-type),
  a {
    margin-right: 7px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }
`

export const StepsIcon: FunctionComponent<{
  activeStep: number
  totalSteps: number
}> = ({ activeStep, totalSteps }) => {
  const getIcons = () => {
    const icons = []
    for (let step = 1; step <= totalSteps; step++) {
      if (step === activeStep) {
        icons.push(<StepActiveIcon key={step} />)
      } else if (step < activeStep) {
        icons.push(
          <NavLink key={step} to={`/hello/${step}`}>
            <StepDoneIcon key={step} />
          </NavLink>
        )
      } else {
        icons.push(<StepIcon key={step} />)
      }
    }

    return icons
  }

  return <StepsIconContainer>{getIcons().map(icon => icon)}</StepsIconContainer>
}
