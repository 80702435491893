import { FunctionComponent } from 'react'

import { User } from '@auth0/auth0-react'
import { Box, Flex, Text, Link } from '@theme-ui/components'

import { makeTransition } from '@fairhq/common'
import Avatar from 'components/Avatar'
import { accountApi } from 'store/account/accountApi'
import { getUsers } from 'store/account/accountSlice'

import { store } from 'store/store'

import { useAppDispatch } from '../../store/hooks'

type UserRowProps = {
  user: User
  isOwner: boolean
  canActivate: boolean
  authRoles: string[]
}

export const UserRow: FunctionComponent<UserRowProps> = ({
  user,
  isOwner,
  canActivate,
  authRoles,
}) => {
  const dispatch = useAppDispatch()

  const isDeactivated = user.blocked

  const toggleActivated = () => {
    accountApi.activation(store.getState, user?.user_id).then(() => {
      dispatch(getUsers())
    })
  }

  return (
    <Flex
      key={user.id}
      sx={{
        alignItems: 'center',
        py: '10px',
        px: 4,
        borderRadius: 10,
        transition: makeTransition('background'),
        '&:hover': {
          bg: 'grey100',
          '.UserRow__action': {
            opacity: 1,
            transition: makeTransition('opacity'),
          },
        },
      }}
    >
      <Avatar
        user={user}
        // @ts-ignore
        sx={{ mr: 4 }}
        grayscale={isDeactivated}
        picture={user?.picture}
      />
      <Box sx={{ flex: '1 1 auto' }}>
        <Text as="div" variant="bodyBold" color="grey500">
          {[user.details?.firstName, user.details?.lastName].join(' ')}
          {authRoles.length > 0 && (
            <Text
              as="div"
              variant="caps"
              sx={{ display: 'inline', ml: 2, color: 'grey300' }}
            >
              {authRoles.join(', ')}
            </Text>
          )}
        </Text>
        <Text as="div">{user.email}</Text>
      </Box>

      <Box>
        {canActivate && !isOwner && (
          <Link
            role="button"
            variant="text.capsLink"
            onClick={toggleActivated}
            className="UserRow__action"
            sx={{
              opacity: 0,
              transition: makeTransition('opacity'),
            }}
          >
            {isDeactivated ? 'Activate' : 'Deactivate'}
          </Link>
        )}
      </Box>
    </Flex>
  )
}
