import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import Sidebar from 'layout/Sidebar'

export const EmptySidebar: FunctionComponent = () => (
  <Sidebar sx={{ py: 6 }}>
    <Box sx={{ position: 'sticky', bottom: 0 }} />
  </Sidebar>
)
