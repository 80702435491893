import { FunctionComponent, PropsWithChildren } from 'react'

import { Box } from 'theme-ui'

import { VisualizationComponentProps } from '../types'

import { convertIntoLegend } from '../utils/convertIntoLegend'

import { Grid } from './Grid'
import { Legend } from './Legend'

export const Container: FunctionComponent<
  VisualizationComponentProps & PropsWithChildren
> = ({ items, benchmark, isLegendVisible, legend, children, isVertical }) => (
  <Box>
    <Grid
      benchmarkValue={benchmark?.value ?? undefined}
      isVertical={isVertical}
      sx={{ mb: isVertical && isLegendVisible ? '48px' : undefined }}
    >
      {children}
    </Grid>
    {isLegendVisible ? (
      <Legend legend={legend || convertIntoLegend(items)} />
    ) : undefined}
  </Box>
)
