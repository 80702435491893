import { createSlice, createAction } from '@reduxjs/toolkit'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isRejected } from 'store/helpers/isRejected'

import { StatusState, statusDictionary } from './types'

const clear = createAction('status/clear')

const initialState: Partial<StatusState> = { count: 0 }

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.count = (state.count ?? 0) + 1
      state.status = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addMatcher(isFulfilled(''), (state, action) => {
        if (statusDictionary.success[action.type]) {
          state.count = (state.count ?? 0) + 1
          state.status = {
            type: 'success',
            name: action.type,
            message: statusDictionary.success[action.type],
          }
        }
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected(''), (state, action) => {
        if (statusDictionary.errorToIgnore[action.type]) {
          return
        }

        const stateWithError: any = {}
        handleErrorState(stateWithError, action)
        state.count = (state.count ?? 0) + 1
        state.status = {
          name: stateWithError.error.name,
          message: stateWithError.error.message,
          type: 'error',
        }
      })
  },
})

export const { actions: statusActions, reducer: statusReducer } = statusSlice
