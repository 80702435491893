import React, { FunctionComponent, useEffect } from 'react'

import {
  Route,
  Routes as Switch,
  Navigate as Redirect,
  useMatch as useRouteMatch,
} from 'react-router-dom'
import { useLocation } from 'react-use'

import { config } from '@fairhq/common'
import { Admin } from 'features/admin/Admin'
import { AssessmentQuestions } from 'features/assessmentQuestions/AssessmentQuestions'
import { Audit } from 'features/audit/Audit'
import { Campaigns } from 'features/campaigns/Campaigns'
import { Changelog } from 'features/changelog/Changelog'
import { Dashboard } from 'features/dashboard/Dashboard'
import { DocumentUpload } from 'features/documentUpload/DocumentUpload'
import { AddEmployees } from 'features/employees/AddEmployees'
import { Employees } from 'features/employees/Employees'
import { Maintenance } from 'features/maintenance/Maintenance'
import { NotFound } from 'features/notFound/NotFound'
import { Reports } from 'features/reports/Reports'
import { ReportsAreas } from 'features/reportsAreas/ReportsAreas'
import { GroupsReports } from 'features/reportsAreasCore/components/GroupsReports'
import { ReportCategories } from 'features/reportsCategories/ReportCategories'
import { ReportsDiversity } from 'features/reportsDiversity/ReportsDiversity'
import { ReportsEquality } from 'features/reportsEquality/ReportsEquality'
import { ReportsExperience } from 'features/reportsExperience/ReportsExperience'
import { Resources } from 'features/resources/Resources'
import { AssessmentChanges } from 'features/settings/AssessmentChanges'
import { BillingSettings } from 'features/settings/BillingSettings'
import { CompanySettings } from 'features/settings/CompanySettings'
import { EditBillingAddress } from 'features/settings/EditBillingAddress'
import { EditPersonalDetails } from 'features/settings/EditPersonalDetails'
import { TeamSettings } from 'features/settings/TeamSettings'
import { ActiveSubscriptionGuard } from 'features/signUp/ActiveSubscriptionGuard'
import { NoActiveSubscription } from 'features/signUp/NoActiveSubscription'
import { SignUp } from 'features/signUp/SignUp'
import { VerifyEmail } from 'features/signUp/VerifyEmail'
import { OldRecommendation } from 'features/strategy/oldStrategy/OldRecommendation'
import { Strategy } from 'features/strategy/Strategy'
import { StrategyRecommendation } from 'features/strategyRecommendation/StrategyRecommendation'
import { StrategyTask } from 'features/strategyTask/StrategyTask'
import { Surveys } from 'features/surveys/Surveys'
import { TaskOldRecommendation } from 'features/taskRecommendations/TaskOldRecommendation'
import { useAlert } from 'hooks/useAlert'
import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { EntitlementsGuard } from 'routes/EntitlementsGuard'
import { EntitlementScopesEnum, EntitlementsEnum } from 'store/company/types'
import { UIComponentsExamples } from 'ui-kit/examples/UIComponentsExamples'

import AreaDetail from '../features/assessments/AreaDetail'
import { CompanyBreakdown } from '../features/companyBreakdown/CompanyBreakdown'
import { AddEmployeesWithCSV } from '../features/employees/AddEmployeesWithCSV'
import { AddEmployeesWithIntegration } from '../features/employees/AddEmployeesWithIntegration'
import { AccountSettings } from '../features/settings/AccountSettings'

import { Settings } from '../features/settings/Settings'

import { Login } from './Login'
import { PrivateRoute } from './PrivateRoute'
import { useCanOverrideMaintenance } from './useCanOverrideMaintenance'

const getShouldRedirect = (
  isUnderMaintenance: boolean,
  canOverrideMaintenance: boolean,
  isSecretLogin: boolean
): boolean => {
  if (isUnderMaintenance) {
    if (canOverrideMaintenance) {
      return false
    }
    return !isSecretLogin
  }
  return false
}

const RoutesComponent: FunctionComponent = () => {
  const { permissions } = useAuthPermissions()

  useAlert()
  const location = useLocation()

  useEffect(() => {
    window?.gtag?.('config', config.analytics, { page_path: location.pathname })
  }, [location.pathname])

  const isUnderMaintenance = config.maintenanceMode === 'true'
  const canOverrideMaintenance = useCanOverrideMaintenance()
  const isSecretLogin = !!useRouteMatch('/bypass-maintenance')
  const maintenanceRedirect = getShouldRedirect(
    isUnderMaintenance,
    canOverrideMaintenance,
    isSecretLogin
  )

  return (
    <Switch>
      <Route key="/maintenance" path="/maintenance" element={<Maintenance />} />
      {maintenanceRedirect && (
        <Route path="/" element={<Redirect to="/maintenance" />} />
      )}
      <Route
        path="/bypass-maintenance"
        element={
          <PrivateRoute key="/bypass-maintenance">
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <PrivateRoute key="/admin">
            <Admin />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute key="/home">
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute key="/reports" path="/reports">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<Reports />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/equality"
        element={
          <PrivateRoute key="/reports/equality">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportsEquality />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/diversity"
        element={
          <PrivateRoute key="/reports/diversity">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportsDiversity />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/experience"
        element={
          <PrivateRoute key="/reports/experience">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportsExperience />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/experience/:category"
        element={
          <PrivateRoute key="/reports/experience/:category">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportCategories />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/equality/:code?"
        element={
          <PrivateRoute key="/reports/equality/:code">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportsAreas />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/diversity/:code?"
        element={
          <PrivateRoute key="/reports/diversity/:code">
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.REPORTS}
              component={<ReportsDiversity />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/inclusion/:code?"
        element={
          <PrivateRoute key="/reports/inclusion/:code">
            <EntitlementsGuard
              entitlement={EntitlementsEnum.REPORTS}
              component={<ReportsAreas />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/group/:type/:code?"
        element={
          <PrivateRoute key="/reports/group/:type/:code?">
            <EntitlementsGuard
              entitlement={EntitlementsEnum.REPORTS}
              component={<GroupsReports />}
            />
          </PrivateRoute>
        }
      />

      <Route path="/dashboard" element={<Redirect to="/home" />} />

      <Route
        path="/hello/:step"
        element={
          <PrivateRoute>
            <SignUp />
          </PrivateRoute>
        }
      />
      <Route
        path="/account-setup"
        element={
          <PrivateRoute>
            <ActiveSubscriptionGuard />
          </PrivateRoute>
        }
      />
      <Route path="/setup-error" element={<NoActiveSubscription />} />

      <Route
        path="/verifyEmail"
        element={
          <PrivateRoute>
            <VerifyEmail />
          </PrivateRoute>
        }
      />
      <Route
        path="/documents/:code?/upload"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT}
              component={<DocumentUpload />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/ui"
        element={
          <PrivateRoute>
            <UIComponentsExamples />
          </PrivateRoute>
        }
      />

      <Route
        path="/strategy"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlementScope={EntitlementScopesEnum.STRATEGY}
              component={<Strategy />}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="/strategy/recommendation/:code"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.STRATEGY_INSIGHTS}
              component={<StrategyRecommendation />}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="/strategy/:recCode"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.STRATEGY}
              component={<OldRecommendation />}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="/strategy/task/:code"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.STRATEGY_INSIGHTS}
              component={<StrategyTask />}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="/resources/:code"
        element={
          <PrivateRoute>
            <Resources />
          </PrivateRoute>
        }
      />

      <Route
        path="/session/:startDate?"
        element={
          <PrivateRoute>
            <Changelog />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/account"
        element={
          <PrivateRoute key="/settings/account">
            <AccountSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/account/personal"
        element={
          <PrivateRoute key="/settings/account/personal">
            <EditPersonalDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/company"
        element={
          <PrivateRoute key="/settings/company">
            <CompanySettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/team"
        element={
          <PrivateRoute key="/settings/team">
            <TeamSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/billing"
        element={
          <PrivateRoute key="/settings/billing">
            <BillingSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/billing/address"
        element={
          <PrivateRoute key="/settings/billing/address">
            <EditBillingAddress />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/assessment"
        element={
          <PrivateRoute key="/settings/assessment">
            <EntitlementsGuard
              entitlement={EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT}
              component={<AssessmentChanges />}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute key="/settings">
            <Settings />
          </PrivateRoute>
        }
      />
      {permissions?.includes(PermissionsEnum.READ_AUDIT) && (
        <>
          <Route
            path="/audit/breakdown"
            element={
              <PrivateRoute key="/audit/breakdown" path="/audit/breakdown">
                <CompanyBreakdown />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit"
            element={
              <PrivateRoute key="/audit">
                <Audit />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit/:code"
            element={
              <PrivateRoute key="/audit/:code">
                <EntitlementsGuard
                  entitlement={EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT}
                  component={<AreaDetail />}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/assessments"
            element={
              <PrivateRoute>
                <Redirect to="/audit" />
              </PrivateRoute>
            }
          />
          <Route
            path="/assessments/:code"
            element={
              <PrivateRoute>
                <Redirect to="/audit/:code" />
              </PrivateRoute>
            }
          />
          <Route
            path="/assessment/:code/questions"
            element={
              <PrivateRoute>
                <EntitlementsGuard
                  entitlement={EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT}
                  component={<AssessmentQuestions />}
                />
              </PrivateRoute>
            }
          />

          {permissions?.includes(PermissionsEnum.READ_EMPLOYEE) && (
            <>
              <Route
                path="/employees"
                element={
                  <PrivateRoute>
                    <EntitlementsGuard
                      entitlement={EntitlementsEnum.EMPLOYEES}
                      component={<Employees />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/employees/add"
                element={
                  <PrivateRoute>
                    <EntitlementsGuard
                      entitlement={EntitlementsEnum.EMPLOYEES}
                      component={<AddEmployees />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/employees/add/csv"
                element={
                  <PrivateRoute>
                    <EntitlementsGuard
                      entitlement={EntitlementsEnum.EMPLOYEES}
                      component={<AddEmployeesWithCSV />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/employees/add/integration"
                element={
                  <PrivateRoute>
                    <EntitlementsGuard
                      entitlement={EntitlementsEnum.HRIS_INTEGRATION}
                      component={<AddEmployeesWithIntegration />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/survey"
                element={
                  <PrivateRoute>
                    <EntitlementsGuard
                      entitlementScope={EntitlementScopesEnum.SURVEY}
                      component={<Surveys />}
                    />
                  </PrivateRoute>
                }
              />
            </>
          )}
        </>
      )}

      <Route
        path="/campaigns"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.CAMPAIGNS}
              component={<Campaigns />}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="/recommendations/:code"
        element={
          <PrivateRoute>
            <EntitlementsGuard
              entitlement={EntitlementsEnum.CRITICAL_FOUNDATIONS}
              component={<TaskOldRecommendation />}
            />
          </PrivateRoute>
        }
      />

      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Redirect to="/home" />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  )
}

export const Routes = React.memo(RoutesComponent)
