import React, { FunctionComponent } from 'react'

import { Box, Link } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { SxProp } from 'theme-ui'

import Icon from 'ui-kit/Icon'

type BackLinkProps = {
  to: string
  setRedirect: (to: string) => void
  i18nKey: string
  sx?: SxProp
}

export const BackLink: FunctionComponent<BackLinkProps> = ({
  to = '/settings',
  setRedirect,
  i18nKey = 'nav.settings',
  sx,
}) => {
  const { t } = useTranslation()

  return (
    <Link
      href={to}
      onClick={e => {
        e.preventDefault()
        setRedirect(to)
      }}
      sx={{
        mb: 4,
        variant: 'text.caps',
        display: 'inline-block',
        color: 'grey400',
        ...sx,
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon name="arrowLeft" small sx={{ mr: 2, mt: '-1px' }} />
        {t(i18nKey)}
      </Box>
    </Link>
  )
}
