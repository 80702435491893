import React, { FunctionComponent } from 'react'

import { capitalize } from 'lodash'
import styled from 'styled-components'

import { Text } from '@fairhq/common'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import { useGetCategoriesScoresQuery } from 'store/surveyWindow/surveyWindowApi'

import { GapAndScore } from './GapAndScore'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const CategoryBlock = styled(WhiteBoxWithShadow)`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;

  & p {
    margin: 0;
  }
`

interface CategoriesOverviewProps {
  latestSurveyWindowId: number
  previousSurveyWindowId?: number
}

export const CategoriesOverview: FunctionComponent<CategoriesOverviewProps> = ({
  latestSurveyWindowId,
  previousSurveyWindowId,
}) => {
  const { data: latestCategoriesScores, isSuccess: isLatestCategoriesSuccess } =
    useGetCategoriesScoresQuery({
      surveyWindowId: latestSurveyWindowId,
    })

  const {
    data: previousCategoriesScores,
    isSuccess: isPreviousCategoriesSucess,
  } = useGetCategoriesScoresQuery(
    {
      surveyWindowId: previousSurveyWindowId,
    },
    { skip: !previousSurveyWindowId }
  )

  if (isLatestCategoriesSuccess && latestCategoriesScores) {
    return (
      <Wrapper>
        {latestCategoriesScores.map(categoryItem => {
          const previous = previousCategoriesScores?.find(
            item => item.category === categoryItem.category
          )
          return (
            <CategoryBlock key={categoryItem.category}>
              <Text element="p" type="body" size="lg" fontWeight={700}>
                {capitalize(categoryItem.category)}
              </Text>
              <GapAndScore
                score={categoryItem.score}
                gap={categoryItem.minorityMajorityGap}
                showDiffColumn={
                  isPreviousCategoriesSucess &&
                  previousCategoriesScores.length > 0
                }
                previousScore={previous?.score}
                previousGap={previous?.minorityMajorityGap}
              />
            </CategoryBlock>
          )
        })}
      </Wrapper>
    )
  }
  return null
}
