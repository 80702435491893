import { ReportType } from 'store/reports/types'

export enum DemGroupType {
  ALL_MAJORITY = 'all majority',
  MAJORITY = 'majority',
  MINORITY = 'minority',
}

export const reportsKeyValue = {
  dem_groups: 'dem_group_code',
  job_level: 'level',
  department: 'department',
  location: 'country',
} as Record<ReportType, string>

export interface Document {
  title: string
  score: number | null
  url: string
}
