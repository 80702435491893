import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

type Props = {
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

const Sidebar: FunctionComponent<Props> = ({ sx, children }) => (
  <Box
    sx={{
      width: '264px',
      bg: 'white',
      height: '100%',
      overflowY: 'auto',
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default Sidebar
