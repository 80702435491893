import { useState, FunctionComponent, useEffect } from 'react'

import { AnimatePresence } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Heading, Link, Text } from 'theme-ui'

import { baseColors, space, Spinner, Sparkles } from '@fairhq/common'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { useGetTasksQuery } from 'store/tasks/tasksApi'
import { TaskStatus } from 'store/tasks/types'
import { RoundButton } from 'styled'

import { SkipTaskModal } from './SkipTaskModal'
import { Task } from './Task'
import { TaskCompletedModal } from './TaskCompletedModal'
import { TasksIntro } from './TasksIntro'

const Container = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
`

const SpinnerContainer = styled.div`
  margin: ${space[10]}px 0;
  display: flex;
  justify-content: center;
  div {
    height: ${space[6]}px;
  }
`
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 8px;
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const ErrorContainer = styled.div`
  padding: 0 ${space[8]}px ${space[6]}px;
`

const ToggleMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space[4]}px;
  cursor: pointer;
  border-top: 1px solid ${baseColors.grey200};
  margin-top: 40px;
`

const AllDoneContainer = styled.div`
  background-color: ${baseColors.purple100};
  border-radius: 20px;
  padding: 40px;
  margin: 0 40px 20px;
`

export const Tasks: FunctionComponent = () => {
  const { t } = useTranslation()
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )
  const [skippedTaskId, setSkippedTaskId] = useState<number | null>(null)
  const [showAllTasks, setShowAllTasks] = useState<boolean>(false)
  const [completedTaskCode, setCompletedTaskCode] = useState<string | null>(
    null
  )

  const {
    data: tasks,
    isSuccess,
    isLoading,
    isError,
    refetch: refetchTasks,
  } = useGetTasksQuery({
    sessionId,
  })
  const activeTasks = tasks?.filter(
    ({ status }) =>
      status === TaskStatus.IN_PROGRESS || status === TaskStatus.NOT_STARTED
  )
  const inactiveTasks = tasks?.filter(
    ({ status }) =>
      status === TaskStatus.COMPLETED || status === TaskStatus.SKIPPED
  )

  const completedTask = tasks?.find(({ code }) => code === completedTaskCode)
  const showCompletedTaskModal = !!completedTask?.linkedQuestionCode

  useEffect(() => {
    if (completedTaskCode && !showCompletedTaskModal) {
      refetchTasks()
    }
  }, [completedTaskCode, showCompletedTaskModal, refetchTasks])

  const handleSkippedTaskModalClose = () => {
    setSkippedTaskId(null)
    refetchTasks()
  }

  const handleCompletedTaskModalClose = () => {
    setCompletedTaskCode(null)
    refetchTasks()
  }

  return (
    <Container>
      <TasksIntro />
      <HeadingContainer>
        <Heading variant="h2" sx={{ fontSize: 21 }}>
          {t('tasks.title')}
        </Heading>
        <LabelContainer>
          <Sparkles outline={baseColors.purple} />
          <Link
            href="https://bit.ly/fhqactions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              variant="labelBold"
              sx={{ color: baseColors.purple, fontSize: 14, ml: 1 }}
            >
              {t('tasks.experiment')}
            </Text>
          </Link>
        </LabelContainer>
      </HeadingContainer>
      <Text
        sx={{ p: '0 40px 48px', fontSize: 16, lineHeight: '30px' }}
        variant="body"
        as="p"
      >
        {t('tasks.description')}
      </Text>
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {isError && (
        <ErrorContainer>
          <Text
            variant="bodyLargeBold"
            sx={{ color: baseColors.red, mb: 4, display: 'block' }}
          >
            {t('tasks.loadingError')}
          </Text>
          <RoundButton
            onClick={() => {
              window.location.reload()
            }}
          >
            {t('tasks.tryAgain')}
          </RoundButton>
        </ErrorContainer>
      )}
      {isSuccess && (
        <>
          {activeTasks && activeTasks.length > 0 ? (
            <AnimatePresence>
              {activeTasks.map(task => (
                <Task
                  key={task.id}
                  task={task}
                  setSkippedTaskId={setSkippedTaskId}
                  setCompletedTaskCode={setCompletedTaskCode}
                />
              ))}
            </AnimatePresence>
          ) : (
            <AllDoneContainer>
              <Text variant="bodyLarge">
                <Trans i18nKey="tasks.allDone" components={[<br />]} />
              </Text>
            </AllDoneContainer>
          )}
          {showAllTasks &&
            inactiveTasks?.map(task => (
              <Task
                key={task.id}
                task={task}
                disableAnimation
                setSkippedTaskId={setSkippedTaskId}
                setCompletedTaskCode={setCompletedTaskCode}
              />
            ))}
          {inactiveTasks && inactiveTasks.length > 0 && (
            <ToggleMoreContainer onClick={() => setShowAllTasks(!showAllTasks)}>
              <Text
                variant="bodyLargeBold"
                sx={{ color: baseColors.purple700 }}
              >
                {showAllTasks
                  ? t('tasks.hidePrevious')
                  : t('tasks.showPrevious')}
              </Text>
              {!showAllTasks && (
                <Text
                  variant="bodyBold"
                  sx={{
                    color: baseColors.purple700,
                    px: '9px',
                    ml: '8px',
                    mt: '2px',
                    bg: baseColors.purple200,
                    borderRadius: '8px',
                  }}
                >
                  {inactiveTasks.length}
                </Text>
              )}
            </ToggleMoreContainer>
          )}
        </>
      )}
      {skippedTaskId && (
        <SkipTaskModal
          taskId={skippedTaskId}
          onClose={handleSkippedTaskModalClose}
        />
      )}
      <Modal
        // Note:it is important to use the modals' `isOpen` prop rather than rendering
        // the modal conditionally because otherwise the exit animation won't work
        // See docs: https://reactcommunity.org/react-modal/styles/transitions/
        isOpen={showCompletedTaskModal}
        onRequestClose={handleCompletedTaskModalClose}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        closeTimeoutMS={200}
      >
        {showCompletedTaskModal && (
          <TaskCompletedModal
            task={completedTask}
            onClose={handleCompletedTaskModalClose}
          />
        )}
      </Modal>
    </Container>
  )
}
