import { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'

import { baseColors, Assessment, Survey } from '@fairhq/common'

export const ReportsStatusIcon: FunctionComponent<{
  type: 'company' | 'survey' | string
}> = ({ type }) => {
  const Icon = type === 'survey' ? Survey : Assessment
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '28px',
        minWidth: '28px',
        padding: '4px',
        background: baseColors.purple200,
        borderRadius: '8px',
        marginRight: '16px',
      }}
    >
      <Icon width="16px" outline={baseColors.purple} />
    </Flex>
  )
}
