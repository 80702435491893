import { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import { ArrowSquarePurple } from 'components/ArrowSquarePurple'

const LinkWrapper = styled.span`
  display: inline-flex;
  gap: 4px;
  position: relative;
  align-items: center;
`

const StyledLink = styled(Link)`
  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 24px;
  }
`

const StyledAnchor = styled.a`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:focus-visible::before {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 24px;
  }
`
interface NotionEmbedLinksProps {
  children: React.ReactNode
  className?: string
  href?: string
}

export const NotionEmbedLinks: FunctionComponent<NotionEmbedLinksProps> = ({
  children,
  ...rest
}) => {
  const { href } = rest
  const isInternalLink = href?.includes('beta.fairhq.co')

  if (href && isInternalLink) {
    const routeToURL = new URL(href)
    return <StyledLink to={routeToURL.pathname}>{children}</StyledLink>
  }

  return (
    <LinkWrapper>
      <StyledAnchor {...rest}>{children}</StyledAnchor>
      <ArrowSquarePurple />
    </LinkWrapper>
  )
}
