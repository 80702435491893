import { useAppSelector } from 'store/hooks'
import { useGetPracticesQuery } from 'store/reports/reportsApiWithQuery'
import { PracticeCriteriaWithLevelType } from 'store/reports/types'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

const groupByLevels = (practices: PracticeCriteriaWithLevelType[]) =>
  practices.reduce(
    (
      levels: Record<string, PracticeCriteriaWithLevelType[]>,
      practiceCriteria: PracticeCriteriaWithLevelType
    ) => {
      levels[practiceCriteria.level] = [
        ...(levels[practiceCriteria.level] || []),
        practiceCriteria,
      ]
      return levels
    },
    {}
  )

export const usePracticeCriteria = (code?: string) => {
  const { sessionId } = useAppSelector((state: State) => ({
    sessionId: state.apiHeadersReducer.sessionId,
  }))

  const {
    data: practices,
    isError,
    isSuccess,
    isFetching,
  } = useGetPracticesQuery(
    {
      sessionId,
      areaCode: code,
    },
    { skip: !code }
  )

  if (isSuccess && !isFetching) {
    if (practices.length === 0) {
      return {
        queryStatus: QueryStatus.SUCCESS,
        practicesGroupedByLevel: undefined,
        firstIncompleteLevel: undefined,
      }
    }

    const practicesWithLevels = practices.filter(
      practice => !!practice.level
    ) as PracticeCriteriaWithLevelType[]

    return {
      queryStatus: QueryStatus.SUCCESS,
      practicesGroupedByLevel:
        practicesWithLevels.length > 0
          ? groupByLevels(practicesWithLevels)
          : undefined,
      firstIncompleteLevel: practices.find(practice => !practice.checked)
        ?.level,
    }
  }

  return {
    queryStatus: isError ? QueryStatus.ERROR : QueryStatus.LOADING,
    practicesGroupedByLevel: undefined,
    firstIncompleteLevel: undefined,
  }
}
