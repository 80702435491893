import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { StateHandler } from 'components/StateHandler'
import { State } from 'store/state'

import {
  useCheckIsNewStrategyDueQuery,
  useGetArchivedStrategiesQuery,
  useGetStrategyQuery,
} from 'store/strategy/strategyApi'

import { useAppSelector } from '../../store/hooks'

import { ArchivedStrategies } from './ArchivedStrategies'
import { Initiatives } from './Initiatives'
import { Insights } from './Insights'
import { StrategyDueBar } from './StrategyDueBar'
import { StrategyIntro } from './StrategyIntro'

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`
export const CommittedStrategy: FunctionComponent = () => {
  const { t } = useTranslation()

  const { companyId } = useAppSelector((state: State) => ({
    companyId: state.apiHeadersReducer.companyId,
  }))

  const {
    data: strategy,
    isLoading,
    isSuccess,
    isError,
  } = useGetStrategyQuery(undefined, {
    skip: !companyId,
  })

  const { data: isNewStrategyDue, isLoading: isStratetyDueLoading } =
    useCheckIsNewStrategyDueQuery()

  const {
    data: archivedStrategies,
    isLoading: isLoadingArchive,
    isSuccess: isArchiveSuccess,
  } = useGetArchivedStrategiesQuery()

  const greyedOut = isNewStrategyDue ?? false
  const archiveId = 'archive'
  return (
    <StateHandler
      isLoading={isLoading || isStratetyDueLoading || isLoadingArchive}
      isSuccess={isSuccess}
      isError={isError}
      errorMessage={t('error.somethingWrong.fallback')}
    >
      <Container>
        <Header>
          <Text
            element="h1"
            type="heading"
            size="2xl"
            fontWeight={500}
            color={baseColors.coolGrey900}
          >
            {t('strategy.title')}
          </Text>
          {isArchiveSuccess && archivedStrategies.length > 0 && (
            <Link
              onClick={() =>
                document
                  .getElementById(archiveId)
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
              to={{ pathname: '/strategy' }}
            >
              {t('strategy.archive.button')}
            </Link>
          )}
        </Header>

        {isNewStrategyDue && <StrategyDueBar />}
        <StrategyIntro greyedOut={greyedOut} />
        {strategy &&
          strategy?.companyInsights &&
          strategy?.companyInsights.length > 0 && (
            <Insights
              companyInsights={strategy.companyInsights}
              greyedOut={greyedOut}
            />
          )}

        {strategy &&
          strategy?.companyInitiatives &&
          strategy?.companyInitiatives?.length > 0 && (
            <Initiatives
              companyInitiatives={strategy.companyInitiatives}
              companyInsights={strategy.companyInsights}
              companyTasks={strategy.companyTasks}
              greyedOut={greyedOut}
            />
          )}
        {isArchiveSuccess && archivedStrategies?.length > 0 && (
          <ArchivedStrategies archiveId={archiveId} />
        )}
      </Container>
    </StateHandler>
  )
}
