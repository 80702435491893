import { FunctionComponent } from 'react'

interface IconProps {
  className?: string
  fill?: string
  width?: string
  height?: string
}

export const TickThin: FunctionComponent<IconProps> = ({
  className,
  fill = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2631 17.3858L19.4267 8.22228C19.7268 7.90734 19.7268 7.41215 19.4267 7.09722L18.5625 6.23305C18.2455 5.92232 17.7381 5.92232 17.4211 6.23305L9.69247 13.9617L7.24669 11.5322C7.09875 11.3746 6.8922 11.2852 6.67601 11.2852C6.45982 11.2852 6.25327 11.3746 6.10533 11.5322L5.24115 12.3964C5.08681 12.5495 5 12.7579 5 12.9752C5 13.1926 5.08681 13.401 5.24115 13.5541L9.12179 17.3858C9.26967 17.5435 9.47626 17.6328 9.69247 17.6328C9.90867 17.6328 10.1153 17.5435 10.2631 17.3858Z"
      fill={fill}
    />
  </svg>
)
