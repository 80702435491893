import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Text } from '@fairhq/common'
import { Visualization } from 'components/visualization/Visualization'

import { useGetDemGroupScoresByQuesitonQuery } from 'store/surveyWindow/surveyWindowApi'

const Container = styled.div`
  padding: 48px 0 0;
`

const StyledTitle = styled(Text)`
  text-transform: uppercase;
`

interface DemGroupsByQuestionProps {
  questionId: number
  surveyWindowId: number
}

export const DemGroupsByQuestion: FunctionComponent<
  DemGroupsByQuestionProps
> = ({ questionId, surveyWindowId }) => {
  const { t } = useTranslation()

  const { data, isSuccess } = useGetDemGroupScoresByQuesitonQuery({
    surveyWindowId,
    questionId,
  })

  if (isSuccess && data && data.length > 0) {
    return (
      <Container>
        <StyledTitle element="p" type="body" size="xs" fontWeight={700}>
          {t(`experience.questions.groupsTitle`)}
        </StyledTitle>

        <Visualization
          type="Row"
          items={
            data.map(item => ({
              key: item.id,
              label: t(item.demGroup.code),
              value: item.score * 100,
            })) as any
          }
          isScoreBy10
        />
      </Container>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
