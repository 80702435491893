import React, { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

interface PageTopProps {
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

export const PageTop: FunctionComponent<PageTopProps> = ({ sx, children }) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 6,
      ...sx,
    }}
  >
    {children}
  </Flex>
)
