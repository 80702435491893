import { ReportProjection } from 'store/reports/types'

export const getProjection = (pathname: string): ReportProjection[] => {
  const defaultProjection: ReportProjection[] = ['general']
  if (pathname?.includes('/equality/equality')) {
    return defaultProjection.concat(['documents', 'fairness', 'experience'])
  }

  if (
    pathname?.includes('/diversity.women') ||
    pathname?.includes('/diversity.peopleOfColor')
  ) {
    return defaultProjection.concat(['jobLevel', 'department', 'location'])
  }

  if (
    pathname?.includes('/diversity.leadership') ||
    pathname?.includes('/diversity.manager')
  ) {
    return defaultProjection.concat(['ethnicity', 'gender'])
  }

  if (pathname?.includes('/inclusion')) {
    return defaultProjection.concat(['experience'])
  }

  return defaultProjection
}
