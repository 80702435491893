import React, { FunctionComponent, ReactNode } from 'react'

import { Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

export const Label: FunctionComponent<{
  label: ReactNode
  sx?: ThemeUIStyleObject
}> = ({ label, sx }) => (
  <Text
    as="div"
    variant="label"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: 'grey500',
      hyphens: 'auto',
      ...sx,
    }}
  >
    {label}
  </Text>
)
