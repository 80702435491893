import { FunctionComponent } from 'react'

import { Button } from 'rsuite'

import { ListContainer, ListCell, ListRow, ActionsToolbar } from './styled/list'

import { ActionMenu, Header, Row } from './index'

interface ListProps {
  rows: Row[]
  headers: Header[]
  preview?: boolean
}

const Actions: FunctionComponent<{ actions: ActionMenu[] }> = ({ actions }) => (
  <ActionsToolbar>
    {actions
      .filter(({ hidden }) => !hidden)
      .map(({ icon, label, onClick }, index) => (
        <Button
          type="button"
          appearance="link"
          onClick={onClick}
          title={label}
          key={`action-menu-${index}`}
        >
          {icon}
        </Button>
      ))}
  </ActionsToolbar>
)
const List: FunctionComponent<ListProps> = ({ headers, rows, preview }) => (
  <ListContainer>
    <ListRow header="true">
      {headers.map(({ key, label, size }) => (
        <ListCell key={key} xs={size}>
          {label}
        </ListCell>
      ))}
      {preview ? undefined : <ListCell xs={3} />}
    </ListRow>
    {rows.map(({ data, actions, faded }, index) => (
      <ListRow key={`row-${index}`} faded={faded ? 1 : 0}>
        {headers.map(({ key = '', size }) => (
          <ListCell key={`row-${index}-${data[key]}`} xs={size}>
            {data[key] || '——'}
          </ListCell>
        ))}
        {preview ? undefined : (
          <ListCell xs={3}>
            {!actions || actions.length === 0 ? undefined : (
              <Actions actions={actions} />
            )}
          </ListCell>
        )}
      </ListRow>
    ))}
  </ListContainer>
)

export default List
