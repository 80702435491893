import { FunctionComponent } from 'react'

import { useLocation } from 'react-router'
import { Flex, Text } from 'theme-ui'

import { makeTransition, space } from '@fairhq/common'
import { useAreaProgress } from 'features/areas/hooks/useAreaProgress'
import Icon from 'ui-kit/Icon'
import RouterLink from 'ui-kit/RouterLink'

export const SidebarMenuItem: FunctionComponent<{
  text: string
  url: string
  code?: string
  completion?: string
  hasNoProgression?: boolean
}> = ({ text, url, code, completion, hasNoProgression }) => {
  const { pathname } = useLocation()
  const isActive = pathname.startsWith(url)
  const { completed, total } = useAreaProgress({ area: { code } })

  const percent =
    completed && total ? `${Math.round((completed * 100) / total)}%` : ''
  const progress = hasNoProgression ? '' : completion || percent || 0

  return (
    <RouterLink to={url}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 48,
          pl: `${space[8] - 3}px`,
          pr: 4,
          py: 1,
          color: isActive ? 'purple' : 'grey500',
          borderLeft: '3px solid',
          bg: isActive ? 'grey100' : 'transparent',
          borderColor: isActive ? 'purple' : 'transparent',
          transition: [
            makeTransition('background'),
            makeTransition('border'),
            makeTransition('color'),
          ].join(),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Text as="div" variant="label">
          {text}
        </Text>
        {progress ? (
          progress === '100%' ? (
            <Icon name="checkboxCheckedRounded" sx={{ fill: 'green' }} />
          ) : (
            <Text as="div" sx={{ color: 'grey400' }}>
              {progress}
            </Text>
          )
        ) : null}
      </Flex>
    </RouterLink>
  )
}
