import React from 'react'

import { Grid } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import SurveyCard from './SurveyCard'

const Surveys = () => {
  const { t } = useTranslation()
  const { areas } = useAppSelector(
    (state: State) => ({
      areas: state.areaReducer.areas?.filter(({ sources }) =>
        sources?.includes('company')
      ),
    }),
    shallowEqual
  )
  const tArea = tCode(t)

  if (!areas || areas?.length === 0) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <Grid gap={1}>
      {areas
        ?.filter(({ sources }) => sources?.includes('company'))
        ?.map(({ code, title }) => (
          <SurveyCard
            key={code + Math.random()}
            label={tArea(code, 'title', title)}
            code={code}
          />
        ))}
    </Grid>
  )
}

export default Surveys
