import React, { FunctionComponent } from 'react'

interface CampaignsBlankSlateVisualProps {
  className?: string
}

export const CampaignsBlankSlateVisual: FunctionComponent<
  CampaignsBlankSlateVisualProps
> = ({ className }) => (
  <svg
    viewBox="0 0 600 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="30" width="540" height="420" rx="24" fill="#F2F7FA" />
    <rect x="195" y="120" width="190" height="48" rx="12" fill="white" />
    <circle opacity="0.5" cx="219" cy="144" r="12" fill="#F2A556" />
    <rect
      opacity="0.3"
      x="249"
      y="139"
      width="112"
      height="10"
      rx="5"
      fill="#98A3B3"
    />
    <rect x="207" y="204" width="190" height="48" rx="12" fill="white" />
    <circle cx="231" cy="228" r="12" fill="#F2F7FA" />
    <rect
      opacity="0.3"
      x="261"
      y="223"
      width="112"
      height="10"
      rx="5"
      fill="#98A3B3"
    />
    <g filter="url(#filter0_d_8309_43172)">
      <rect x="183" y="162" width="190" height="48" rx="12" fill="#5347D1" />
    </g>
    <circle opacity="0.7" cx="207" cy="186" r="12" fill="white" />
    <rect
      opacity="0.3"
      x="237"
      y="181"
      width="112"
      height="10"
      rx="5"
      fill="#F2F7FA"
    />
    <path
      d="M464.709 270.703V271.593C464.708 273.068 464.121 274.482 463.078 275.525C462.035 276.568 460.621 277.155 459.146 277.156H419.093C417.618 277.156 416.203 276.569 415.16 275.526C414.117 274.483 413.53 273.068 413.53 271.593V270.703C413.527 269.401 413.984 268.14 414.821 267.143C415.099 266.812 415.414 266.513 415.76 266.253C415.76 266.253 437.78 265.754 437.78 265.14H459.146C460.621 265.142 462.035 265.729 463.077 266.772C464.12 267.815 464.707 269.228 464.709 270.703Z"
      fill="#CCCCCC"
    />
    <path d="M435.905 276.935H441.69V314.318H435.905V276.935Z" fill="#CCCCCC" />
    <path
      d="M419.439 314.115C419.439 314.741 428.206 314.357 439.021 314.357C449.835 314.357 458.602 314.741 458.602 314.115C458.602 313.49 449.835 308.533 439.021 308.533C428.206 308.533 419.439 313.49 419.439 314.115Z"
      fill="#CCCCCC"
    />
    <path
      d="M427.393 269.949C426.975 269.388 426.686 268.743 426.544 268.059C426.403 267.374 426.413 266.667 426.575 265.987C426.737 265.307 427.045 264.671 427.479 264.123C427.913 263.575 428.462 263.129 429.087 262.816L441.349 221.034L450.687 225.607L435.697 265.24C436.153 266.324 436.195 267.539 435.815 268.653C435.436 269.766 434.66 270.702 433.637 271.283C432.613 271.863 431.412 272.048 430.261 271.802C429.111 271.556 428.09 270.897 427.393 269.949L427.393 269.949Z"
      fill="#A0616A"
    />
    <path
      d="M438.571 228.011L449.021 230.84C449.319 230.921 449.63 230.936 449.934 230.886C450.238 230.835 450.527 230.72 450.782 230.547C451.037 230.374 451.252 230.149 451.413 229.886C451.573 229.623 451.674 229.328 451.71 229.022L452.974 218.302C453.377 216.779 453.159 215.157 452.369 213.794C451.578 212.43 450.279 211.436 448.757 211.028C447.234 210.621 445.613 210.834 444.247 211.621C442.881 212.407 441.883 213.703 441.471 215.225L437.166 225.085C437.043 225.367 436.982 225.673 436.988 225.981C436.993 226.289 437.065 226.592 437.198 226.87C437.332 227.148 437.523 227.393 437.76 227.59C437.997 227.787 438.273 227.931 438.571 228.011H438.571Z"
      fill="#6C63FF"
    />
    <path
      d="M424.661 309.734L419.205 309.733L416.609 295.583H424.662L424.661 309.734Z"
      fill="#A0616A"
    />
    <path
      d="M426.053 307.952L415.308 307.952H415.307C414.408 307.952 413.518 308.129 412.687 308.473C411.856 308.817 411.101 309.321 410.465 309.957C409.83 310.593 409.325 311.348 408.981 312.179C408.637 313.009 408.46 313.9 408.46 314.799V315.022L426.052 315.022L426.053 307.952Z"
      fill="#2F2E41"
    />
    <path
      d="M418.328 302.145C418.392 302.145 418.456 302.142 418.52 302.136L426.07 301.586C426.376 301.558 426.671 301.46 426.932 301.3C427.194 301.139 427.415 300.921 427.578 300.662C427.742 300.403 427.844 300.109 427.877 299.804C427.909 299.499 427.871 299.191 427.766 298.903L417.66 276.2C417.588 275.997 417.559 275.782 417.574 275.568C417.589 275.353 417.648 275.144 417.748 274.954C417.847 274.761 417.986 274.592 418.156 274.457C418.326 274.322 418.522 274.225 418.732 274.171C423.484 272.929 435.675 269.621 446.229 265.752C450.751 264.094 453.598 261.698 454.693 258.63C456.137 254.586 454.001 250.898 453.91 250.743L453.838 250.622L443.907 251.525L433.141 252.44L409.548 265.092C407.571 266.161 406.07 267.935 405.342 270.062C404.615 272.189 404.716 274.511 405.624 276.567L416.501 300.958C416.658 301.312 416.915 301.612 417.239 301.822C417.563 302.033 417.942 302.145 418.328 302.145Z"
      fill="#2F2E41"
    />
    <path
      d="M442.18 206.608C436.144 206.608 431.25 201.714 431.25 195.678C431.25 189.641 436.144 184.747 442.18 184.747C448.217 184.747 453.111 189.641 453.111 195.678C453.111 201.714 448.217 206.608 442.18 206.608Z"
      fill="#A0616A"
    />
    <path
      d="M427.776 309.289L422.32 309.288L419.724 295.138H427.777L427.776 309.289Z"
      fill="#A0616A"
    />
    <path
      d="M429.169 307.507L418.424 307.506H418.423C417.524 307.506 416.634 307.683 415.803 308.028C414.972 308.372 414.217 308.876 413.581 309.512C412.945 310.148 412.441 310.903 412.097 311.733C411.753 312.564 411.576 313.455 411.576 314.354V314.576L429.168 314.577L429.169 307.507Z"
      fill="#2F2E41"
    />
    <path
      d="M421.444 303.481C421.507 303.481 421.571 303.478 421.635 303.472L429.186 302.922C429.491 302.893 429.786 302.795 430.047 302.635C430.309 302.475 430.53 302.257 430.694 301.997C430.858 301.738 430.96 301.445 430.992 301.14C431.025 300.835 430.987 300.526 430.881 300.238L420.776 277.535C420.704 277.333 420.674 277.117 420.689 276.903C420.704 276.689 420.763 276.48 420.863 276.289C420.963 276.097 421.102 275.927 421.271 275.793C421.441 275.658 421.637 275.56 421.847 275.506C426.599 274.264 438.791 270.957 449.345 267.087C453.866 265.429 456.714 263.033 457.809 259.966C459.252 255.922 457.117 252.234 457.025 252.079L456.954 251.958L447.023 252.86L436.257 253.776L412.664 266.427C410.687 267.496 409.185 269.271 408.458 271.397C407.731 273.524 407.831 275.846 408.739 277.902L419.616 302.294C419.774 302.647 420.03 302.947 420.354 303.158C420.679 303.368 421.057 303.481 421.444 303.481Z"
      fill="#2F2E41"
    />
    <path
      d="M438.809 212.806L450.38 208.8C450.38 208.8 457.644 214.381 454.155 228.226C452.333 235.37 452.864 242.911 455.668 249.73C456.006 250.574 456.395 251.396 456.833 252.191C456.833 252.191 443.927 267.768 431.911 256.197L436.584 233.722C436.584 233.722 429.241 218.814 438.809 212.806Z"
      fill="#2F2E41"
    />
    <path
      d="M440.823 206.979C442.379 204.651 443.607 201.422 441.894 199.206C440.203 197.019 436.869 197.342 434.279 196.374C430.673 195.026 428.581 190.789 429.233 186.994C429.886 183.2 432.932 180.054 436.56 178.767C440.189 177.48 444.281 177.899 447.835 179.381C452.209 181.205 455.961 184.735 457.535 189.206C459.108 193.676 458.267 199.045 455.003 202.481C451.503 206.165 446.011 207.147 440.93 207.087"
      fill="#2F2E41"
    />
    <path
      d="M450.991 187.116C452.951 188.712 455.942 188.003 458.046 186.602C460.151 185.202 461.858 183.207 464.135 182.109C468.149 180.173 473.002 181.46 476.908 183.605C480.815 185.749 484.278 188.711 488.404 190.394C492.531 192.077 497.796 192.16 500.921 188.983C501.69 191.21 501.753 193.621 501.101 195.886C500.449 198.151 499.114 200.159 497.279 201.638C495.443 203.116 493.197 203.992 490.845 204.146C488.493 204.3 486.151 203.725 484.138 202.499C479.617 199.745 477.12 193.971 472.049 192.45C469.242 191.609 466.229 192.287 463.393 193.021C460.557 193.754 457.6 194.547 454.737 193.923C451.875 193.298 449.198 190.726 449.562 187.819L450.991 187.116Z"
      fill="#2F2E41"
    />
    <path
      d="M415.409 290.11C416.566 282.593 416.566 274.942 415.409 267.425C415.336 266.977 415.106 266.57 414.761 266.276C414.415 265.983 413.977 265.821 413.523 265.82H395.779C395.491 265.819 395.207 265.884 394.948 266.009C394.69 266.134 394.463 266.317 394.285 266.544C394.104 266.773 393.977 267.041 393.915 267.326C393.853 267.612 393.857 267.908 393.926 268.192C395.586 275.145 395.586 282.39 393.926 289.343C393.857 289.627 393.853 289.923 393.915 290.209C393.977 290.494 394.104 290.762 394.285 290.991C394.463 291.218 394.69 291.4 394.948 291.526C395.207 291.651 395.491 291.716 395.779 291.715H413.523C413.977 291.714 414.415 291.552 414.761 291.259C415.106 290.965 415.336 290.558 415.409 290.11Z"
      fill="#6C63FF"
    />
    <path
      d="M401.239 276.801H410.551C410.905 276.801 411.244 276.66 411.494 276.41C411.745 276.159 411.885 275.82 411.886 275.466V274.944C411.885 274.591 411.745 274.251 411.494 274.001C411.244 273.751 410.905 273.61 410.551 273.609H401.239C400.885 273.61 400.546 273.751 400.295 274.001C400.045 274.251 399.904 274.591 399.904 274.944V275.466C399.904 275.82 400.045 276.159 400.295 276.41C400.546 276.66 400.885 276.801 401.239 276.801Z"
      fill="white"
    />
    <path
      d="M401.239 282.295H410.551C410.905 282.295 411.244 282.154 411.494 281.904C411.745 281.653 411.885 281.314 411.886 280.96V280.438C411.885 280.084 411.745 279.745 411.494 279.494C411.244 279.244 410.905 279.103 410.551 279.103H401.239C400.885 279.103 400.546 279.244 400.295 279.494C400.045 279.745 399.904 280.084 399.904 280.438V280.96C399.904 281.314 400.045 281.653 400.295 281.904C400.546 282.154 400.885 282.295 401.239 282.295Z"
      fill="white"
    />
    <path
      d="M410.742 267.946C410.478 267.299 410.359 266.602 410.393 265.904C410.427 265.206 410.614 264.524 410.94 263.906C411.266 263.288 411.724 262.749 412.282 262.327C412.839 261.905 413.482 261.61 414.165 261.463L436.477 224.069L444.376 230.83L419.96 265.461C420.13 266.626 419.867 267.812 419.221 268.796C418.575 269.779 417.591 270.492 416.455 270.798C415.319 271.104 414.11 270.983 413.057 270.457C412.004 269.932 411.18 269.038 410.742 267.946L410.742 267.946Z"
      fill="#A0616A"
    />
    <path
      d="M432.044 230.132L441.456 235.482C441.724 235.634 442.022 235.727 442.328 235.754C442.635 235.781 442.944 235.741 443.235 235.638C443.525 235.535 443.789 235.37 444.01 235.155C444.231 234.94 444.403 234.681 444.514 234.393L448.416 224.329C449.187 222.954 449.381 221.33 448.956 219.812C448.532 218.295 447.522 217.007 446.15 216.233C444.777 215.458 443.154 215.259 441.635 215.679C440.116 216.1 438.826 217.105 438.047 218.475L431.415 226.947C431.225 227.19 431.09 227.47 431.018 227.77C430.947 228.07 430.94 228.381 431 228.683C431.06 228.986 431.184 229.272 431.364 229.521C431.544 229.771 431.776 229.98 432.044 230.132Z"
      fill="#2F2E41"
    />
    <path
      d="M331.893 243.266H512.578C513.345 243.266 514.081 243.571 514.623 244.113C515.166 244.656 515.471 245.392 515.471 246.159C515.471 246.926 515.166 247.662 514.623 248.204C514.081 248.747 513.345 249.052 512.578 249.052H507.46V312.024C507.46 312.792 507.155 313.527 506.613 314.07C506.07 314.612 505.334 314.917 504.567 314.917C503.8 314.917 503.064 314.612 502.522 314.07C501.979 313.527 501.675 312.792 501.675 312.024V254.61L469.244 313.39C468.86 314.053 468.228 314.536 467.488 314.733C466.747 314.931 465.959 314.827 465.295 314.444C464.632 314.06 464.147 313.43 463.948 312.69C463.749 311.95 463.852 311.161 464.234 310.497L498.135 249.052H343.241V249.089L377.121 310.497C377.505 311.161 377.609 311.951 377.41 312.692C377.212 313.433 376.727 314.065 376.063 314.448C375.398 314.832 374.609 314.936 373.868 314.737C373.127 314.539 372.495 314.054 372.111 313.39L343.241 261.063V312.024C343.241 312.792 342.936 313.527 342.394 314.07C341.851 314.612 341.116 314.917 340.348 314.917C339.581 314.917 338.845 314.612 338.303 314.07C337.76 313.527 337.456 312.792 337.456 312.024V250.577L336.614 249.052H331.893C331.125 249.052 330.39 248.747 329.847 248.204C329.305 247.662 329 246.926 329 246.159C329 245.392 329.305 244.656 329.847 244.113C330.39 243.571 331.125 243.266 331.893 243.266Z"
      fill="#98A3B3"
    />
    <path
      d="M406.659 241.486H441.817C442.677 241.486 443.375 242.184 443.375 243.044C443.375 243.904 442.677 244.602 441.817 244.602H406.659C405.799 244.602 405.102 243.904 405.102 243.044C405.102 242.184 405.799 241.486 406.659 241.486Z"
      fill="#C4CBD4"
    />
    <path
      d="M406.659 241.486H441.817C442.677 241.486 443.375 242.184 443.375 243.044C443.375 243.904 442.677 244.602 441.817 244.602H406.659C405.799 244.602 405.102 243.904 405.102 243.044C405.102 242.184 405.799 241.486 406.659 241.486Z"
      fill="#CFD6E2"
    />
    <path
      d="M383.072 244.602H422.68C423.447 244.601 424.183 244.296 424.725 243.753C425.267 243.211 425.572 242.476 425.573 241.709V219.902C425.572 219.135 425.267 218.4 424.725 217.857C424.183 217.315 423.447 217.01 422.68 217.009H383.072C382.305 217.01 381.57 217.315 381.028 217.857C380.485 218.4 380.18 219.135 380.179 219.902V241.709C380.18 242.476 380.485 243.211 381.028 243.753C381.57 244.296 382.305 244.601 383.072 244.602Z"
      fill="#C4CBD4"
    />
    <path
      d="M383.072 244.602H422.68C423.447 244.601 424.183 244.296 424.725 243.753C425.267 243.211 425.572 242.476 425.573 241.709V219.902C425.572 219.135 425.267 218.4 424.725 217.857C424.183 217.315 423.447 217.01 422.68 217.009H383.072C382.305 217.01 381.57 217.315 381.028 217.857C380.485 218.4 380.18 219.135 380.179 219.902V241.709C380.18 242.476 380.485 243.211 381.028 243.753C381.57 244.296 382.305 244.601 383.072 244.602Z"
      fill="#CFD6E2"
    />
    <path
      d="M402.876 233.476C401.401 233.476 400.206 232.281 400.206 230.806C400.206 229.331 401.401 228.136 402.876 228.136C404.351 228.136 405.546 229.331 405.546 230.806C405.546 232.281 404.351 233.476 402.876 233.476Z"
      fill="white"
    />
    <path
      d="M149.801 221.571C150.024 220.958 150.115 220.305 150.068 219.655C150.022 219.005 149.838 218.372 149.53 217.797C149.221 217.223 148.795 216.72 148.279 216.322C147.762 215.924 147.168 215.639 146.534 215.487C146.333 215.44 146.129 215.407 145.923 215.388L135.702 197.47L138.283 193.036L131.858 188.08L126.942 194.907C126.356 195.728 126.05 196.715 126.07 197.724C126.09 198.732 126.434 199.707 127.052 200.504L141.085 218.47C141.034 218.613 140.99 218.758 140.954 218.905C140.802 219.539 140.786 220.198 140.906 220.839C141.026 221.48 141.281 222.088 141.652 222.624C142.024 223.159 142.505 223.611 143.063 223.948C143.621 224.285 144.244 224.5 144.891 224.579C145.079 224.603 145.268 224.615 145.458 224.615C146.406 224.612 147.33 224.318 148.107 223.774C148.883 223.23 149.474 222.461 149.801 221.571Z"
      fill="#FFB8B8"
    />
    <path
      d="M169.744 309.744L174.9 309.744L177.353 289.854L169.743 289.855L169.744 309.744Z"
      fill="#FFB8B8"
    />
    <path
      d="M178.583 308.271H168.639V314.532H184.845C184.845 313.71 184.683 312.895 184.368 312.136C184.054 311.376 183.592 310.686 183.011 310.104C182.429 309.523 181.739 309.062 180.98 308.747C180.22 308.432 179.406 308.271 178.583 308.271Z"
      fill="#2F2E41"
    />
    <path
      d="M127.263 309.744L132.419 309.744L134.872 289.854L127.262 289.855L127.263 309.744Z"
      fill="#FFB8B8"
    />
    <path
      d="M136.102 308.271H126.158V314.532H142.364C142.364 313.71 142.202 312.895 141.887 312.136C141.573 311.376 141.111 310.686 140.53 310.104C139.949 309.523 139.258 309.062 138.499 308.747C137.739 308.432 136.925 308.271 136.102 308.271Z"
      fill="#2F2E41"
    />
    <path
      d="M130.428 189.048L142.005 193.447L144.831 185.596C145.206 184.554 145.201 183.412 144.816 182.373C144.431 181.334 143.691 180.464 142.727 179.918C141.752 179.366 140.611 179.18 139.511 179.395C138.411 179.609 137.424 180.211 136.728 181.089L130.428 189.048Z"
      fill="#6C63FF"
    />
    <path
      d="M169.299 293.635L179.008 291.42C179.524 291.302 179.977 290.992 180.274 290.554C180.571 290.115 180.692 289.581 180.611 289.057C176.715 264.364 172.967 249.903 163.308 231.538C163.173 231.196 161.664 224.246 160.68 219.642C160.691 219.134 160.518 218.64 160.192 218.251C159.866 217.863 159.409 217.606 158.908 217.528L140.803 214.742C140.53 214.647 140.242 214.604 139.953 214.614L139.772 214.649L139.774 214.827C139.776 215.011 139.96 215.039 140.028 215.049L140.269 215.086C139.995 215.214 139.742 215.386 139.523 215.594C131.59 225.135 127.465 256.409 122.226 290.96C122.197 291.24 122.231 291.523 122.326 291.789C122.421 292.054 122.574 292.295 122.774 292.494C122.964 292.701 123.194 292.867 123.449 292.983C123.705 293.099 123.982 293.161 124.262 293.167L135.83 293.449C136.338 293.46 136.833 293.287 137.222 292.96C137.612 292.634 137.87 292.177 137.947 291.675L145.739 246.65C145.792 246.298 145.957 245.972 146.208 245.721C146.46 245.469 146.785 245.304 147.136 245.25C147.488 245.195 147.848 245.255 148.163 245.419C148.479 245.583 148.734 245.844 148.891 246.163C155.176 258.964 161.038 270.035 166.811 292.121C166.926 292.569 167.186 292.966 167.551 293.25C167.916 293.534 168.365 293.688 168.827 293.688C168.986 293.688 169.144 293.67 169.299 293.635Z"
      fill="#2F2E41"
    />
    <path
      d="M159.874 220.156C160.118 219.984 160.322 219.761 160.473 219.504C160.624 219.247 160.719 218.96 160.75 218.663C162.233 204.821 161.2 193.989 157.593 185.547C156.958 184.091 156.001 182.798 154.794 181.765C153.587 180.732 152.162 179.986 150.625 179.583L141.267 179.111L141.219 179.134C139.107 180.169 137.385 181.856 136.307 183.946C135.23 186.036 134.855 188.418 135.238 190.738C138.248 198.672 140.768 206.933 138.846 213.596C138.766 213.875 138.742 214.167 138.777 214.455C138.812 214.743 138.905 215.02 139.05 215.272C139.19 215.514 139.379 215.726 139.604 215.893C139.83 216.06 140.087 216.179 140.36 216.243L158.185 220.483C158.473 220.552 158.773 220.558 159.065 220.502C159.356 220.445 159.632 220.327 159.874 220.156Z"
      fill="#6C63FF"
    />
    <path
      d="M163.902 200.292L181.851 186.132C181.994 186.182 182.14 186.225 182.287 186.26C182.921 186.409 183.578 186.422 184.217 186.299C184.856 186.177 185.462 185.921 185.996 185.549C186.533 185.177 186.985 184.694 187.32 184.133C187.656 183.572 187.868 182.946 187.942 182.296C188.062 181.258 187.828 180.209 187.278 179.321C186.727 178.432 185.893 177.756 184.91 177.401C184.296 177.181 183.643 177.093 182.993 177.143C182.343 177.193 181.711 177.38 181.138 177.691C180.565 178.002 180.064 178.431 179.669 178.949C179.273 179.467 178.991 180.063 178.842 180.698C178.796 180.899 178.764 181.103 178.746 181.308L160.878 191.616L156.431 189.056L151.506 195.505L158.409 200.426C159.218 200.997 160.189 201.293 161.178 201.268C162.167 201.244 163.123 200.902 163.902 200.292H163.902Z"
      fill="#FFB8B8"
    />
    <path
      d="M149.315 197.513L159.557 190.549L154.744 183.733C154.105 182.828 153.166 182.177 152.094 181.897C151.022 181.617 149.886 181.725 148.885 182.202C147.873 182.684 147.067 183.512 146.613 184.537C146.158 185.561 146.085 186.715 146.406 187.788L149.315 197.513Z"
      fill="#6C63FF"
    />
    <path
      d="M144.39 175.616C138.685 175.616 134.06 170.99 134.06 165.285C134.06 159.58 138.685 154.955 144.39 154.955C150.095 154.955 154.721 159.58 154.721 165.285C154.721 170.99 150.095 175.616 144.39 175.616Z"
      fill="#FFB8B8"
    />
    <path
      d="M148.178 163.746C150.833 165.564 154.319 167.432 157.031 165.497C158.811 164.226 159.372 161.873 158.65 159.808C157.352 156.095 153.784 154.46 150.373 153.207C145.938 151.578 141.109 150.282 136.53 151.448C131.952 152.615 127.905 157.048 128.732 161.7C129.397 165.441 132.925 168.553 132.424 172.32C131.92 176.11 127.657 178.106 123.894 178.783C120.13 179.46 115.919 179.678 113.214 182.381C109.764 185.83 110.626 191.993 113.872 195.634C117.119 199.275 122.018 200.926 126.765 202.048C133.055 203.534 139.858 204.315 145.832 201.848C151.806 199.381 156.455 192.74 154.724 186.513C153.993 183.883 152.271 181.66 150.607 179.496C148.943 177.331 147.258 175.068 146.623 172.413C146.094 170.2 146.486 167.631 147.953 165.972C148.22 165.681 148.388 165.314 148.433 164.922C148.478 164.53 148.397 164.134 148.203 163.79L148.178 163.746Z"
      fill="#2F2E41"
    />
    <defs>
      <filter
        id="filter0_d_8309_43172"
        x="159"
        y="150"
        width="238"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0392157 0 0 0 0 0.105882 0 0 0 0 0.25098 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8309_43172"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8309_43172"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
