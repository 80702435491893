import React, { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Arrow, Text, TickCircleFilled, baseColors } from '@fairhq/common'
import { Task, TaskStatus } from 'store/tasks/types'

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
`

const StyledLink = styled(Link)<{ $greyedOut: boolean }>`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ $greyedOut }) =>
    $greyedOut ? baseColors.coolGrey700 : baseColors.purple800};
  &:hover span {
    color: ${baseColors.purple};
  }
  &:focus-visible::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 8px;
  }
`

const StyledArrow = styled(Arrow)`
  flex-shrink: 0;
  color: ${baseColors.purple600};
`

const StyledCircle = styled(TickCircleFilled)`
  flex-shrink: 0;
  color: ${baseColors.coolGrey500};
`

const StyledText = styled(Text)<{ skipped: boolean }>`
  ${({ skipped }) =>
    skipped &&
    css`
      text-decoration: line-through;
      color: ${baseColors.coolGrey600};
    `}
`

interface InitiativeTaskProps {
  greyedOut: boolean
  task: Task
}
export const InitiativeTask: FunctionComponent<InitiativeTaskProps> = ({
  greyedOut,
  task,
}) => (
  <ListItem>
    <StyledLink $greyedOut={greyedOut} to={`/strategy/task/${task.code}`}>
      <StyledText
        skipped={task.status === TaskStatus.SKIPPED}
        element="span"
        type="body"
        size="sm"
        fontWeight={600}
      >
        {task.description}
      </StyledText>
      {task.status === TaskStatus.COMPLETED ? (
        <StyledCircle aria-hidden="true" width="20" height="20" />
      ) : (
        <StyledArrow aria-hidden="true" width="20" height="20" />
      )}
    </StyledLink>
  </ListItem>
)
