import React, { useEffect } from 'react'

import { Text } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'

import { baseColors, Spinner } from '@fairhq/common'

import { useCreateEmployeesQuery } from './integrationsApi'

export const SyncEmployees: React.FunctionComponent<{
  accountToken: string
  next: () => void
}> = ({ accountToken, next }) => {
  const { t } = useTranslation()
  const { isError, isSuccess } = useCreateEmployeesQuery({
    accountToken,
  })

  useEffect(() => {
    if (isSuccess) {
      next()
    }
  }, [isSuccess, next])

  if (isError) {
    return (
      <Text variant="label" sx={{ color: baseColors.red, fontWeight: 'bold' }}>
        {t('employees.add.integrations.error')}
      </Text>
    )
  }
  return (
    <Spinner vertical content={t('employees.add.integrations.syncEmployees')} />
  )
}
