import React, { FunctionComponent } from 'react'

import { Box, Flex } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'
import { Visualization } from 'components/visualization/Visualization'

import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import { useOpenable } from 'hooks/useOpenable'
import { ExperienceQuestion } from 'store/surveyWindow/types'
import Icon from 'ui-kit/Icon'

import { DemGroupsByQuestion } from './DemGroupsByQuestion'
import { GapAndScore } from './GapAndScore'

const Accordion = styled(WhiteBoxWithShadow)`
  flex-direction: column;
`

interface QuestionAccordionProps {
  experienceQuestion: ExperienceQuestion
  blockTitle: string
  companyScore: number
  gap: number
  showDiffColumn: boolean
  previousScore?: number
  previousGap?: number
  majorityScore?: number
  minorityScore?: number
}

export const QuestionAccordion: FunctionComponent<QuestionAccordionProps> = ({
  experienceQuestion,
  blockTitle,
  companyScore,
  gap,
  showDiffColumn,
  previousScore,
  previousGap,
}) => {
  const { t } = useTranslation()
  const { opened, toggle } = useOpenable()

  return (
    <Accordion>
      <Flex
        role="button"
        onClick={toggle}
        sx={{
          width: '100%',
          p: '24px 40px',
          alignItems: 'center',
          justifyContent: 'space-between',
          userSelect: 'none',
          cursor: 'pointer',
          gap: '60px',
        }}
      >
        <Flex sx={{ flexGrow: 1, alignItems: 'center' }}>
          <Text
            element="p"
            type="body"
            size="md"
            fontWeight={400}
            color={baseColors.grey500}
          >
            {blockTitle}
          </Text>

          <Icon
            name={opened ? 'arrowDown' : 'arrowRight'}
            sx={{ ml: 2, flexShrink: 0 }}
            small
          />
        </Flex>
        <GapAndScore
          score={companyScore}
          gap={gap}
          previousScore={previousScore}
          previousGap={previousGap}
          showDiffColumn={showDiffColumn}
        />
      </Flex>

      {opened && (
        <Box
          sx={{ borderTop: '1px solid', borderColor: 'grey200', py: 4, px: 8 }}
        >
          <Visualization
            type="Row"
            isScoreBy10
            items={[
              // nded to multiply the score here by 100 because Visualization component expects data that way

              {
                label: t('reports.overview.traditionalMajority'),
                values: [
                  {
                    notEnoughData: !experienceQuestion.majorityScore,
                    label: experienceQuestion.majorityScore * 100,
                    value: experienceQuestion.majorityScore * 100,
                  },
                ],
              },
              {
                label: t('reports.overview.traditionalMinority'),
                values: [
                  {
                    notEnoughData: !experienceQuestion.minorityScore,
                    label: experienceQuestion.minorityScore * 100,
                    value: experienceQuestion.minorityScore * 100,
                  },
                ],
              },
            ]}
          />

          <DemGroupsByQuestion
            questionId={experienceQuestion.surveyQuestion.id}
            surveyWindowId={experienceQuestion.surveyWindowId}
          />
        </Box>
      )}
    </Accordion>
  )
}
