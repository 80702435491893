import { JobLevelEnum } from 'store/surveyWindow/types'

export const findJobLevel = (
  levels: { level: string; code: JobLevelEnum }[],
  level?: string
) => {
  if (!level) {
    return undefined
  }

  return level === JobLevelEnum.MANAGER_LEADERSHIP
    ? { level, code: level }
    : levels.find(({ code }) => code === level)
}
