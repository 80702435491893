import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Lock: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.09677 9.17949V6.8718C7.09677 4.18132 9.29217 2 12 2C14.7078 2 16.9032 4.18132 16.9032 6.8718V9.17949H17.1613C18.7295 9.17949 20 10.4418 20 12V19.1795C20 20.7377 18.7295 22 17.1613 22H6.83871C5.27049 22 4 20.7377 4 19.1795V12C4 10.4418 5.27049 9.17949 6.83871 9.17949H7.09677ZM8.64516 6.8718C8.64516 5.03099 10.1473 3.53846 12 3.53846C13.8527 3.53846 15.3548 5.03099 15.3548 6.8718V9.17949H8.64516V6.8718ZM6.83871 10.7179C6.12564 10.7179 5.54839 11.2915 5.54839 12V19.1795C5.54839 19.888 6.12564 20.4615 6.83871 20.4615H17.1613C17.8744 20.4615 18.4516 19.888 18.4516 19.1795V12C18.4516 11.2915 17.8744 10.7179 17.1613 10.7179H6.83871Z"
      fill={outline}
    />
  </svg>
)
