import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { chain } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { Documents } from './Documents'
import { ScoreHeader } from './ScoreHeader'

interface ReportsDocumentsProps {
  code: string
}

export const ReportsDocuments: FunctionComponent<ReportsDocumentsProps> = ({
  code,
}) => {
  const { t } = useTranslation()
  const tDocument = tCode(t)

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: generalReports,
    isLoading: isGeneralReportsLoading,
    isError: isGeneralReportsError,
    isSuccess: isGeneralReportsSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'general',
    apiVersion,
    sessionId,
  })
  const documentAreaScore = chain(generalReports?.processAndDocuments)
    .keyBy('_type')
    .value()?.document

  const {
    data: documentsReports,
    isLoading: isDocumentsReportsLoading,
    isError: isDocumentsReportsError,
    isSuccess: isDocumentsReportsSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'documents',
    apiVersion,
    sessionId,
  })
  const documents = documentsReports?.documents

  const scoreDocument =
    // eslint-disable-next-line no-unsafe-optional-chaining
    ((documentAreaScore?.score ?? 0) * 10) / documentAreaScore?.area_max

  if (documents?.list?.length === 0) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <StateHandler
      isError={isGeneralReportsError || isDocumentsReportsError}
      isSuccess={isGeneralReportsSuccess && isDocumentsReportsSuccess}
      isLoading={isGeneralReportsLoading || isDocumentsReportsLoading}
      errorMessage={t('error.TryAgain')}
    >
      <ScoreHeader
        title={t('reports.documents.title')}
        score={scoreDocument}
        icon="documents"
      />
      <Box mb={9}>
        <Documents
          data={
            documents?.list
              ?.filter(({ documentType }: any) => !documentType?.deprecated)
              .map(({ score, area, documentType }: any) => ({
                title: tDocument(
                  documentType?.code,
                  'title',
                  documentType?.title
                ),
                score: score * 100,
                url: `/documents/${area?.code}/upload`,
              })) ?? []
          }
        />
      </Box>
    </StateHandler>
  )
}
