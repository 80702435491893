import { FunctionComponent } from 'react'

import { shallowEqual } from 'react-redux'

import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { NewSurvey } from './NewSurvey'
import { SurveyCard } from './SurveyCard'
import { SurveyIntro } from './SurveyIntro'

export const SurveyHeader: FunctionComponent = () => {
  const { totalEmployees, countInvited } = useAppSelector(
    (state: State) => ({
      totalEmployees: state.employeeReducer.count ?? 0,
      countInvited: state.employeeReducer.countInvited ?? 0,
    }),
    shallowEqual
  )
  const { data: sessions } = useGetSessionsQuery()
  const latestSession = sessions?.[0]

  return (
    <>
      {totalEmployees === 0 ? <SurveyIntro sx={{ mb: 10 }} /> : undefined}

      {totalEmployees > 0 && countInvited === 0 ? <NewSurvey /> : undefined}

      {totalEmployees > 0 && countInvited > 0 && latestSession ? (
        <SurveyCard session={latestSession} sx={{ mb: 4 }} defaultOpened />
      ) : undefined}
    </>
  )
}
