import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'

import { CommitStrategyPrompt } from './CommitStrategyPrompt'
import { Initiatives } from './Initiatives'
import { PromoteStrategyIntro } from './PromoteStrategyInto'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const PromoteStrategy: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    data: strategy,
    isError,
    isSuccess,
    isLoading,
  } = useGetStrategyQuery()

  if (isSuccess && strategy?.draftStep) {
    return <CommitStrategyPrompt />
  }

  return (
    <Container>
      <PromoteStrategyIntro />
      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}

      {isSuccess && strategy && <Initiatives strategy={strategy} />}
    </Container>
  )
}
