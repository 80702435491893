import React, { FunctionComponent } from 'react'

import { AliasesCSSProperties } from '@styled-system/css'
import { Box, Flex } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { Navigation, NAVIGATION_HEIGHT } from 'features/navigation/Navigation'

type LayoutProps = {
  dark?: boolean
  sidebar?: React.ReactNode
  maxContentWidth?: number
  px?: AliasesCSSProperties['px']
  hideNavigation?: boolean
  contentSx?: ThemeUIStyleObject
  children?: React.ReactNode
}

export const Layout: FunctionComponent<LayoutProps> = ({
  dark,
  sidebar,
  maxContentWidth = 1200,
  px = 10,
  hideNavigation,
  contentSx,
  children,
}) => {
  const contentHeight = `calc(100vh - ${
    hideNavigation ? 0 : NAVIGATION_HEIGHT
  }px)`
  return (
    <>
      {!hideNavigation ? <Navigation /> : null}
      <Flex
        sx={{
          height: contentHeight,
        }}
      >
        {sidebar && (
          <Box sx={{ flex: '0 0 auto' }} className="box-sidebar">
            {sidebar}
          </Box>
        )}
        <Box
          sx={{
            bg: dark ? 'grey100' : 'white',
            minHeight: contentHeight,
            overflowY: 'auto',
            flex: '1 1 auto',
          }}
          className="Layout-scroller"
        >
          <Box
            sx={{
              // @ts-ignore
              maxWidth: th => maxContentWidth + th.space[20],
              px,
              pt: 8,
              pb: 20,
              m: 'auto',
              ...contentSx,
            }}
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </>
  )
}
