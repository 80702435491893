import { FunctionComponent, ReactElement, ReactNode } from 'react'

import styled, { css } from 'styled-components'

import { baseColors } from '../primitives/colors'
import { space } from '../primitives/space'

import { Spinner } from './Spinner'

import { Text } from './Text'

const buttonStyles = {
  primary: {
    hasBorders: false,
    default: {
      background: baseColors.purple700,
      color: baseColors.white,
      borderColor: undefined,
    },
    hover: {
      color: baseColors.white,
      background: baseColors.purple800,
      borderColor: undefined,
    },
    disabled: {
      background: baseColors.coolGrey300,
      color: baseColors.coolGrey600,
      borderColor: undefined,
    },
    focus: {
      color: baseColors.white,
      outlineColor: baseColors.purple700,
    },
  },
  secondary: {
    hasBorders: true,
    default: {
      background: baseColors.white,
      color: baseColors.purple600,
      borderColor: baseColors.purple500,
    },
    hover: {
      color: baseColors.purple600,
      background: baseColors.purple200,
      borderColor: baseColors.purple600,
    },
    disabled: {
      background: baseColors.coolGrey300,
      color: baseColors.coolGrey600,
      borderColor: baseColors.coolGrey400,
    },
    focus: {
      color: baseColors.purple600,
      outlineColor: baseColors.purple700,
    },
  },
  tertiary: {
    hasBorders: false,
    default: {
      background: baseColors.white,
      color: baseColors.purple800,
      borderColor: undefined,
    },
    hover: {
      color: baseColors.purple800,
      background: baseColors.coolGrey200,
      borderColor: undefined,
    },
    disabled: {
      background: baseColors.coolGrey300,
      color: baseColors.coolGrey600,
      borderColor: undefined,
    },
    focus: {
      color: baseColors.purple800,
      outlineColor: baseColors.purple700,
    },
  },
}

const BORDER_WIDTH = 3

const StyledButton = styled.button<{
  variant: Variant
  hasMinWidth: boolean
  fullWidth: boolean
  hasIcons: boolean
}>`
  height: fit-content;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: ${({ hasIcons }) => (hasIcons ? 'space-between' : 'center')};
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  ${({ hasMinWidth }) =>
    hasMinWidth &&
    css`
      min-width: 168px;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ variant }) => {
    const styles = buttonStyles[variant]
    const { hasBorders } = styles

    return css`
      background-color: ${styles.default.background};
      color: ${styles.default.color};
      padding: ${space[2] - (hasBorders ? BORDER_WIDTH : 0)}px ${space[4]}px;
      ${hasBorders &&
      css`
        border: ${BORDER_WIDTH}px solid ${styles.default.borderColor};
      `}

      &:hover {
        color: ${styles.hover.color};
        background-color: ${styles.hover.background};
        ${hasBorders &&
        css`
          border: ${BORDER_WIDTH}px solid ${styles.hover.borderColor};
        `}
      }
      &:focus-visible {
        color: ${styles.focus.color};
        outline: 2px solid ${styles.focus.outlineColor};
        outline-offset: 2px;
      }
      &:disabled {
        background-color: ${styles.disabled.background};
        color: ${styles.disabled.color};
        box-shadow: none;
        ${hasBorders &&
        css`
          border: ${BORDER_WIDTH}px solid ${styles.disabled.borderColor};
        `}
      }
    `
  }}
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const LeftIconContainer = styled(IconContainer)`
  margin-right: 16px;
`

const RightIconContainer = styled(IconContainer)`
  margin-left: 16px;
`

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`

export type Variant = 'primary' | 'secondary' | 'tertiary'

export interface ButtonProps {
  children: string | ReactElement
  onClick?: () => void
  variant?: Variant
  className?: string
  iconOnly?: ReactNode
  iconLeft?: ReactNode
  iconRight?: ReactNode
  disabled?: boolean
  hasMinWidth?: boolean
  fullWidth?: boolean
  loading?: boolean
  ariaLabel?: string
  [key: string]: any
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  onClick,
  variant = 'primary',
  className,
  iconOnly,
  iconLeft,
  iconRight,
  disabled,
  hasMinWidth = false,
  fullWidth = false,
  loading,
  ariaLabel,
  ...restOfProperties
}) => (
  <StyledButton
    className={className}
    variant={variant}
    onClick={onClick}
    hasMinWidth={hasMinWidth}
    fullWidth={fullWidth}
    disabled={disabled}
    hasIcons={!!iconLeft || !!iconRight}
    aria-label={typeof children === 'string' ? children : ariaLabel}
    {...restOfProperties}
  >
    {iconOnly &&
      (loading ? (
        <SpinnerContainer>
          <Spinner size="sm" color={buttonStyles[variant].default.color} />
        </SpinnerContainer>
      ) : (
        iconOnly
      ))}
    {!iconOnly && (
      <>
        {iconLeft && <LeftIconContainer>{iconLeft}</LeftIconContainer>}
        {loading ? (
          <SpinnerContainer>
            <Spinner size="sm" color={buttonStyles[variant].default.color} />
          </SpinnerContainer>
        ) : (
          <Text element="span" type="body" size="sm" fontWeight={600}>
            {children}
          </Text>
        )}
        {iconRight && <RightIconContainer>{iconRight}</RightIconContainer>}
      </>
    )}
  </StyledButton>
)
