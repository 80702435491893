import { FunctionComponent, useState, useEffect } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import Modal from 'react-modal'

import { Container, SelectPicker } from 'rsuite'
import styled from 'styled-components'

import { Button, Text } from 'theme-ui'

import { baseColors } from '@fairhq/common'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import {
  useGetTasksQuery,
  useGetTasksFollowUpQuestionsQuery,
  usePostTaskAnswerMutation,
} from 'store/tasks/tasksApi'
import WhiteBox from 'ui-kit/WhiteBox'

export const ChoiceContainer = styled(Container)`
  .rs-radio,
  .rs-checkbox {
    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 12px;
    }

    label {
      text-align: left;
      white-space: initial;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface SkipTaskModalProps {
  taskId: number
  onClose: () => void
}

export const SkipTaskModal: FunctionComponent<SkipTaskModalProps> = ({
  taskId,
  onClose,
}) => {
  const { t } = useTranslation()
  const [otherInput, setOtherInput] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: questionsData, isSuccess: isQuestionsSuccess } =
    useGetTasksFollowUpQuestionsQuery({ sessionId })
  const [
    postTaskAnswer,
    { isError: isAnswerError, isSuccess: isAnswerSuccess },
  ] = usePostTaskAnswerMutation()

  const { data: tasks } = useGetTasksQuery({
    sessionId,
  })
  const taskCode = tasks?.find(({ id }) => id === taskId)?.code

  useEffect(() => {
    if (isAnswerSuccess) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnswerSuccess])

  if (!isQuestionsSuccess) {
    return null
  }
  const firstQuestion = questionsData[0]
  const { options } = firstQuestion

  const isOtherVisible = options?.find(
    ({ id, isOther }) => id === selectedOption && isOther
  )

  return (
    <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
      <WhiteBox
        sx={{
          width: '100%',
          padding: '48px',
        }}
      >
        <Text as="div" variant="subtitle" sx={{ textAlign: 'center', mb: 2 }}>
          {t(`tasksQuestions.${firstQuestion.code}.modalTitle`)}
        </Text>
        <Text as="div" variant="h3" sx={{ mb: 1 }}>
          {t(`tasks.${taskCode}.title`)}
        </Text>
        <Text as="div" sx={{ mb: 5 }}>
          {firstQuestion.description}
        </Text>
        <Text as="div" variant="label" sx={{ mb: 1, fontWeight: 'bold' }}>
          {firstQuestion.title}
        </Text>

        <ChoiceContainer>
          <SelectPicker
            onChange={value => setSelectedOption(value)}
            value={selectedOption}
            data={
              _.sortBy(options, ['priority'])?.map(option => ({
                value: option.id,
                label: option.title,
              })) ?? []
            }
            placeholder={t(`tasksQuestions.PickReason`)}
            searchable={false}
            maxHeight={300}
            size="lg"
          />

          {isOtherVisible ? (
            <textarea
              style={{ marginTop: '12px' }}
              onChange={e => setOtherInput(e.target.value)}
              rows={3}
              value={otherInput}
            />
          ) : undefined}
        </ChoiceContainer>

        <ButtonsContainer>
          <Button
            sx={{ mr: 2 }}
            disabled={!selectedOption || (isOtherVisible && !otherInput)}
            onClick={() => {
              if (selectedOption) {
                postTaskAnswer({
                  questionId: firstQuestion.id,
                  taskId,
                  optionId: selectedOption,
                  otherInput,
                  sessionId,
                })
              }
            }}
          >
            {t(`tasksQuestions.${firstQuestion.code}.modalConfirm`)}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            {t(`tasksQuestions.${firstQuestion.code}.modalCancel`)}
          </Button>
        </ButtonsContainer>

        {isAnswerError && (
          <ErrorContainer>
            <Text
              as="div"
              variant="label"
              sx={{ color: baseColors.red, fontWeight: 'bold', mt: 4 }}
            >
              {t('error.TryAgain')}
            </Text>
          </ErrorContainer>
        )}
      </WhiteBox>
    </Modal>
  )
}
