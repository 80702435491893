import { reverse, sortBy } from 'lodash'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'

export const useScoreArea = (
  code?: string
): { name: string; scores: any[] }[] => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: latestScores } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })

  return code
    ? [
        {
          name: 'top',
          scores: reverse(
            sortBy(
              (latestScores?.areasScoring?.[code]?.areas ?? []).map(
                (score: any) => ({
                  ...score,
                  percent: score.score / score.max,
                })
              ),
              ['percent']
            )
          ),
        },
      ]
    : [
        { name: 'top', scores: latestScores?.excelAreas ?? [] },
        { name: 'bottom', scores: latestScores?.improvementAreas ?? [] },
      ]
}
