import React, { FunctionComponent, useEffect } from 'react'

import { Text, Box } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import { DeactivateIllustration } from 'components/svgs/illustrations/DeactivateIllustration'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import Button from 'ui-kit/Button'
import WhiteBox from 'ui-kit/WhiteBox'

import { useCancelScheduledCampaignMutation } from '../../campaignsApi'

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface CancelModalProps {
  onClose: () => void
  campaignCode: string
  scheduledCampaignId: number
}

export const CancelModal: FunctionComponent<CancelModalProps> = ({
  onClose,
  campaignCode,
  scheduledCampaignId,
}) => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const [cancelScheduledCampaign, { isLoading, isError, isSuccess }] =
    useCancelScheduledCampaignMutation()

  useEffect(() => {
    if (isSuccess && !isLoading) {
      onClose()
    }
  }, [isSuccess, isLoading, onClose])

  return (
    <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
      <WhiteBox
        sx={{
          width: '100%',
          padding: '48px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text
          as="div"
          variant="subtitle"
          sx={{
            color: baseColors.grey400,
            lineHeight: '42px',
            fontWeight: 700,
            mb: 4,
            textAlign: 'center',
          }}
        >
          {t('campaigns.cancel.title')}
        </Text>
        <DeactivateIllustration />
        <Text as="div" sx={{ mt: 2, mb: 5, fontSize: 16 }}>
          {t(`campaigns.${campaignCode}.title`)}
        </Text>
        <Box>
          <Button
            loading={isLoading}
            onClick={() => {
              cancelScheduledCampaign({
                scheduledCampaignId,
                sessionId,
              })
            }}
            sx={{ bg: 'red', mr: '18px' }}
            size="large"
          >
            {t('campaigns.cancel.confirm')}
          </Button>
          <Button variant="secondary" onClick={onClose} size="large">
            {t('campaigns.cancel.cancel')}
          </Button>
        </Box>
        {isError && (
          <ErrorContainer>
            <Text
              as="div"
              variant="label"
              sx={{
                color: baseColors.red,
                fontWeight: 'bold',
                mt: 4,
                width: '100%',
              }}
            >
              {t('campaigns.cancel.error')}
            </Text>
          </ErrorContainer>
        )}
      </WhiteBox>
    </Modal>
  )
}
