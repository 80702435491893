import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const ArrowCaret: FunctionComponent<IconProps> = ({
  className,
  fill = 'currentColor',
  width = '12',
  height = '12',
}) => (
  <svg
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M6.33816 7.84623L10 4H2L5.66184 7.84623C5.7495 7.94434 5.8719 8 6 8C6.1281 8 6.2505 7.94434 6.33816 7.84623Z"
      fill={fill}
    />
  </svg>
)
