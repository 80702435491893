import { ApiVersion } from 'store/apiHeaders/types'

export interface Company {
  id: number
  name: string
  sector: string
  size: string
  location: string
  logo: any
  survey_info: any
  accountId: number
  scorable: boolean
  integrated: boolean
  audit_info: any
  sessions: any[]
  createdAt: string
  [key: string]: any
}

export interface Session {
  id: number
  api_version: string
  start_date: string
  end_date: string
  scorable: boolean | null
  reportLink?: string
  company_id: number
  created_at: string
  updated_at: string
  audit_type: string
}

export interface SessionCamelCase {
  id: number
  apiVersion: ApiVersion
  startDate: string
  endDate: string
  scorable: boolean | null
  reportLink?: string
  companyId: number
  createdAt: string
  updatedAt: string
  auditType: string
}

export interface CompanyState {
  error: any
  invitationSent: boolean
  loading: boolean
  company: Partial<Company>
  sessionIdCurrentlyViewing: number
  latestSession: SessionCamelCase
  version: string
}

export interface Entitlement {
  scopes: EntitlementScopesEnum[]
  entitlements: EntitlementsEnum[]
  enabledEntitlements: {
    [key: string]: {
      id: number
      title: EntitlementsEnum
      scope: EntitlementScopesEnum
    }[]
  }
}

export enum EntitlementScopesEnum {
  AUDIT = 'audit',
  REPORTS = 'reports',
  STRATEGY = 'strategy',
  CAMPAIGNS = 'campaigns',
  EMPLOYEES = 'employees',
  TASKS = 'tasks',
  EVENTS = 'events',
  SURVEY = 'survey',
}

export enum EntitlementsEnum {
  DNI_CALENDAR = 'dni_calendar_dashboard',
  REPORTS = 'reports',
  REPORTS_INSIGHTS = 'reports_insights',
  CAMPAIGNS = 'campaigns',
  EMPLOYEE_SURVEY_AUDIT = 'employee_survey_audit',
  ADVANCED_ASSESSMENT_AUDIT = 'advanced_assessment_audit',
  CRITICAL_FOUNDATIONS = 'critical_foundations_dashboard',
  EMPLOYEES = 'employees',
  STRATEGY = 'strategy',
  STRATEGY_INSIGHTS = 'strategy_insights',
  HRIS_INTEGRATION = 'hris_integration',
  DIVERSITYVC_CERTIFICATION_REPORTS = 'diversityvc_certification_reports',
  PRIVEQ_CERTIFICATION_REPORTS = `priveq_certification_reports`,
  SURVEYS = 'surveys',
  DEM_REPORT = 'DEM_report',
  IS = 'IS',
}

export enum CompanySector {
  PRIVATE_EQUITY = 'private_equity',
  VENTURE_CPAITAL = 'venture_capital',
  TECHNOLOGY = 'technology',
}
