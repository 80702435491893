import React, { FunctionComponent } from 'react'

interface InwardsArrowsProps {
  className?: string
  stroke?: string
}

export const InwardsArrows: FunctionComponent<InwardsArrowsProps> = ({
  className,
  stroke = 'currentColor',
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}
    className={className}
    fill="none"
  >
    <path
      d="M14 8.00016H10M10 8.00016L11.3333 6.66683M10 8.00016L11.3333 9.3335"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66602 7.99984L6.66602 7.99984M6.66602 7.99984L5.33268 9.33317M6.66602 7.99984L5.33268 6.6665"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
