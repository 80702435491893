import React, { FunctionComponent } from 'react'

import { DifferenceTag } from 'components/DifferenceTag'

import { useGetENPSQuery } from 'store/surveyWindow/surveyWindowApi'

interface ENPSComparisonProps {
  previousSurveyWindowId: number
  latestSurveyWindowScore: number
}

export const ENPSComparison: FunctionComponent<ENPSComparisonProps> = ({
  previousSurveyWindowId,
  latestSurveyWindowScore,
}) => {
  const { data, isSuccess } = useGetENPSQuery({
    surveyWindowId: previousSurveyWindowId,
  })

  if (isSuccess && data) {
    return <DifferenceTag value={latestSurveyWindowScore - data.score} />
  }

  return null
}
