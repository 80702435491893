import { FunctionComponent } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { SelectPicker } from 'rsuite'
import styled from 'styled-components'
import { Label, Text } from 'theme-ui'

import { baseColors, Hash, Lock, space } from '@fairhq/common'

import { SlackChannel } from '../types'

const ChannelOptionContainer = styled.div`
  display: flex;
  align-items: center;
`

const SlackInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space[10]}px;
  margin-bottom: ${space[4]}px;
`
const StyledLockIcon = styled(Lock)`
  height: 1em;
  width: 1em;
`

const StyledHashIcon = styled(Hash)`
  height: 1em;
  width: 1em;
`

interface ChannelPickerProps {
  channel?: SlackChannel | undefined
  onChannelChange?: (channel: SlackChannel) => void
  slackChannels: SlackChannel[]
}

export const ChannelPicker: FunctionComponent<ChannelPickerProps> = ({
  channel,
  onChannelChange,
  slackChannels,
}) => {
  const { t } = useTranslation()

  return (
    <SlackInputContainer>
      <Label
        htmlFor="slack-channel"
        sx={{
          mb: 1,
          fontWeight: 'bold',
          fontSize: 13,
          color: baseColors.grey500,
        }}
      >
        {t('campaigns.schedule.channel')}
      </Label>
      <SelectPicker
        className="slack-channel"
        id="slack-channel"
        value={channel}
        onChange={onChannelChange}
        placeholder={t('campaigns.schedule.channelPlaceholder')}
        maxHeight={220}
        style={{ zIndex: 0 }}
        data={
          _.sortBy(slackChannels, ['name'])?.map(slackChannel => ({
            label: (
              <ChannelOptionContainer>
                {slackChannel.isPrivate ? (
                  <StyledLockIcon />
                ) : (
                  <StyledHashIcon />
                )}
                <Text
                  variant="label"
                  sx={{
                    fontSize: 13,
                    color: baseColors.grey400,
                    pb: 1,
                    pt: 1,
                  }}
                >
                  {slackChannel.name}
                </Text>
              </ChannelOptionContainer>
            ),
            value: slackChannel,
          })) ?? []
        }
      />
      <Text
        as="div"
        variant="label"
        sx={{
          mb: 1,
          mt: 1,
          fontSize: 13,
          color: baseColors.grey400,
        }}
      >
        {t('campaigns.schedule.channelDescription')}
      </Text>
    </SlackInputContainer>
  )
}
