import React, { useState } from 'react'

import _ from 'lodash'
import { Navigate as Redirect } from 'react-router-dom'
import { Container } from 'rsuite'
import { FileType } from 'rsuite/es/Uploader'

import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { AreaHeader } from 'features/areas/AreaHeader'
import { useArea } from 'hooks/useArea'
import { AreaSource } from 'store/area/types'
import { CompanyDocument, CompanyDocumentType } from 'store/document/types'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'
import { AreaFooter } from '../areas/AreaFooter'

import { DocumentView } from './components/DocumentView'
import { useSubmitDocuments } from './useSubmitDocuments'

const DocumentContainer = styled(Container)`
  height: 100%;
`

interface DocumentsContextProps {
  droppedFiles: FileType[]
  setDroppedFiles: (files: FileType[]) => void
  document?: Partial<CompanyDocument>
  onLoadLink: (url: string) => void
  progress: number
  hasError: boolean
  skipped: boolean
  setHasError: (value: boolean) => void
  link: string
  currentIndex: number
  type: CompanyDocumentType
}

export const DocumentsContext = React.createContext<DocumentsContextProps>(
  {} as DocumentsContextProps
)
export const DeletedDocumentsContext = React.createContext<any>({} as any)

export const DocumentUpload = () => {
  useArea()
  const area = useAppSelector((state: State) => state.areaReducer.area)
  const {
    currentIndex,
    skipping,
    submitting,
    onSkipDocument,
    onSubmit,
    setCurrentIndex,
    hasNext,
    disabled,
    redirect,
    type,
    droppedFiles,
    setDroppedFiles,
    document,
    onLoadLink,
    progress,
    hasError,
    setHasError,
    link,
    skipped,
  } = useSubmitDocuments()
  const [deleting, setDeleting] = useState(false)

  // eslint-disable-next-line no-unsafe-optional-chaining
  if (!area || !_.isEqual(+type?.area?.id, +area?.id)) {
    return <Spinner backdrop vertical />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <DocumentsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        currentIndex,
        type,
        droppedFiles,
        setDroppedFiles,
        document,
        onLoadLink,
        progress,
        hasError,
        setHasError,
        link,
        skipped,
      }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <DeletedDocumentsContext.Provider value={{ deleting, setDeleting }}>
        <DocumentContainer>
          <AreaHeader area={area} group={AreaSource.DOCUMENTS} />
          <DocumentView />
          <AreaFooter
            disabledNext={disabled}
            disabledPrevious={currentIndex === 0}
            loadingNext={submitting}
            loadingSkipDocument={skipping}
            onNext={onSubmit}
            onPrevious={() => setCurrentIndex(currentIndex - 1)}
            onSkipDocument={onSkipDocument}
            hasNext={hasNext}
          />
        </DocumentContainer>
      </DeletedDocumentsContext.Provider>
    </DocumentsContext.Provider>
  )
}
