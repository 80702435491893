import { useEffect } from 'react'

import { useAsync } from 'react-use'

import { config } from '@fairhq/common'

const useCacheBuster = () => {
  const { value: meta } = useAsync(async () => {
    const response = await fetch('/meta.json')
    const result = await response.json()
    return result
  }, [])
  const isSameVersion = meta && meta?.version !== config.version
  const isProd = process.env.NODE_ENV === 'production'

  useEffect(() => {
    if (isSameVersion && isProd && caches) {
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name)
        }
        window.location.reload()
      })
    }
  }, [isProd, isSameVersion])
}

export default useCacheBuster
