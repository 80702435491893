import { shallowEqual } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { getIntercomCompanyId } from 'utils/getIntercomCompanyId'

export const useUpdateIntercomAuditCompletion = (
  completionPercentage?: number
) => {
  const { update: updateIntercom } = useIntercom()

  const { intercomUserHash, companyId, auditStatus } = useAppSelector(
    (state: State) => ({
      intercomUserHash: state.authReducer.intercom,
      companyId: state.apiHeadersReducer.companyId,
      auditStatus: state.auditReducer.status,
    }),
    shallowEqual
  )

  const inStateCompletion =
    auditStatus?.company && auditStatus.documents
      ? auditStatus.documents?.total > 0
        ? (auditStatus.company.percent + auditStatus.documents.percent) / 2
        : auditStatus.company?.percent
      : undefined

  const percentage = inStateCompletion || completionPercentage

  if (companyId && percentage) {
    updateIntercom({
      userHash: intercomUserHash,
      company: {
        companyId: getIntercomCompanyId(companyId),
        customAttributes: {
          audit_completion_rate: Math.round(percentage),
        },
      },
    })
  }
}
