import React, { useState, useEffect, FunctionComponent } from 'react'

import { User } from '@auth0/auth0-react'
import { Box, Flex, Grid, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { usePrevious } from 'react-use'

import { makeTransition } from '@fairhq/common'

import Avatar from 'components/Avatar'
import { Roles, useAuthRoles } from 'hooks/useAuthRoles'
import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import {
  removeUser,
  getUsers,
  sendEmailVerification,
} from 'store/account/accountSlice'
import { State } from 'store/state'

import Icon from 'ui-kit/Icon'
import { Separator } from 'ui-kit/Separator'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { AddUser } from './AddUser'
import { UserRow } from './UserRow'

type UsersProps = {
  showAddMember: boolean
  setShowAddMember: React.Dispatch<React.SetStateAction<boolean>>
}

export const Users: FunctionComponent<UsersProps> = ({
  showAddMember,
  setShowAddMember,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [resendingId, setResending] = useState('')
  const { isOwner: isCurrentUserOwner } = useAuthRoles()

  const { loading, users, apiVersion } = useAppSelector(
    (state: State) => ({
      users: state.accountReducer.users ?? [],
      loading: state.accountReducer.loading,
      apiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )
  const { data: account } = useGetAccountQuery({ apiVersion })
  const previousAccount = usePrevious(account)

  useEffect(() => {
    if (
      (users.length === 0 || previousAccount?.id !== account?.id) &&
      !loading
    ) {
      dispatch(getUsers())
    }
  }, [account, dispatch, loading, previousAccount, users])

  const resend = (userId: string) => {
    setResending(userId)
    dispatch(sendEmailVerification(userId))
  }

  const deleteToken = (userId: string) => {
    setResending(userId)
    dispatch(removeUser(userId))
  }

  useEffect(() => {
    if (resendingId && !loading) {
      setResending('')
    }
  }, [dispatch, resendingId, loading])

  if (!account && loading) {
    return <div>{t('loading')}</div>
  }

  const activeUsers = users
    .filter(user => !user.blocked && user?.email_verified)
    .sort(user => (user?.app_metadata?.roles?.includes(Roles.OWNER) ? -1 : 1))

  const deactivatedUsers = users.filter(user => user.blocked)

  const pendingUsers = users.filter(user => !user?.email_verified)

  const renderRow = (user: User) => (
    <UserRow
      key={user?.email}
      user={user}
      isOwner={user?.app_metadata?.roles?.includes(Roles.OWNER)}
      canActivate={isCurrentUserOwner}
      authRoles={user.app_metadata.roles}
    />
  )

  return (
    <>
      <Grid gap={2} sx={{ mb: 2 }}>
        {activeUsers.map(renderRow)}

        {pendingUsers?.map(user => (
          <Flex
            key={user?.user_id}
            sx={{
              alignItems: 'center',
              py: '10px',
              px: 4,
              borderRadius: 10,
              transition: makeTransition('background'),
              '&:hover': {
                bg: 'grey100',
                '& .Users__actions': { visibility: 'visible' },
              },
            }}
          >
            <Avatar
              // @ts-ignore
              sx={{ mr: 4 }}
              picture={user?.picture}
              user={user}
            />
            <Box sx={{ flex: '1 1 auto' }}>
              <Text as="div" variant="bodyBold" color="grey500">
                Pending invitation
              </Text>
              <Text as="div">{user?.email}</Text>
            </Box>
            <Flex
              sx={{ alignItems: 'center', visibility: 'hidden' }}
              className="Users__actions"
            >
              {isCurrentUserOwner && (
                <>
                  <Box
                    onClick={() => resend(user?.user_id as string)}
                    sx={{
                      cursor: 'pointer',
                      pointerEvents:
                        resendingId === user?.user_id ? 'none' : 'auto',
                      opacity: resendingId === user?.user_id ? 0.5 : 1,
                    }}
                    title="Resend"
                  >
                    <Icon name="resend" sx={{ display: 'block' }} />
                  </Box>
                  <Box
                    onClick={() => deleteToken(user?.user_id)}
                    sx={{ cursor: 'pointer', ml: 2 }}
                    title="Delete invitation"
                  >
                    <Icon name="closeSmall" small sx={{ display: 'block' }} />
                  </Box>
                </>
              )}
            </Flex>
          </Flex>
        ))}
      </Grid>

      {showAddMember && <AddUser onClose={() => setShowAddMember(false)} />}

      {deactivatedUsers.length > 0 && (
        <>
          <Separator />
          <Text as="div" variant="caps" sx={{ mb: 3 }}>
            Deactivated users
          </Text>

          <Grid gap={2} sx={{ mb: 2 }}>
            {deactivatedUsers.map(renderRow)}
          </Grid>
        </>
      )}
    </>
  )
}
