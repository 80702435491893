import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'

import { baseColors, Spinner } from '@fairhq/common'

interface StateHandlerProps {
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  children?: React.ReactNode
  loadingComponent?: React.ReactElement
}

export const StateHandler: FunctionComponent<StateHandlerProps> = ({
  children,
  errorMessage,
  loadingComponent,
  isError,
  isLoading,
  isSuccess,
}) => {
  const { t } = useTranslation()

  if (isError) {
    return (
      <Flex sx={{ justifyContent: 'flex-end' }}>
        <Text
          as="div"
          variant="label"
          sx={{
            color: theme => theme?.colors?.red,
            fontWeight: 'bold',
            mt: 4,
            width: '100%',
          }}
        >
          {errorMessage ?? t('error.TryAgain')}
        </Text>
      </Flex>
    )
  }

  if (isLoading && !isSuccess) {
    return (
      loadingComponent || (
        <Flex
          sx={{
            backgroundColor: baseColors.white,
            borderRadius: '20px',
            padding: theme => `${theme?.space?.[10]}px`,
            marginBottom: theme => `${theme?.space?.[4]}px`,
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Flex>
      )
    )
  }

  return <>{children}</>
}
