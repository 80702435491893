import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ThemeUIStyleObject } from 'theme-ui'

import { makeTransition } from '@fairhq/common'

import { Tooltip } from '../components/Tooltip'
import { VisualizationItemValueProps, VisualizationTypeProps } from '../types'

export const BarVisualizationItemValue: FunctionComponent<
  VisualizationTypeProps<VisualizationItemValueProps>
> = ({ item, isScoreBy10, isGaps, maxGap }) => {
  const { t } = useTranslation()
  const label = item.value
    ? (isScoreBy10 && round(item.value / 10, 1)) || `${round(item.value, 0)}%`
    : t('na')
  const gapsHeight = maxGap ? round(item.value * 45) / maxGap : 0
  const isGapsNegative = isGaps && item.value < 0
  const isGapsAboveMaxHeight = isGaps && Math.abs(gapsHeight) > 30
  const gapMinHeight = Math.abs(gapsHeight) > 45 ? 45 : Math.abs(gapsHeight)
  const gapsStyle = {
    bottom: `calc(50% + ${
      gapsHeight < 0
        ? `${Math.abs(gapsHeight) > 45 ? -45 : gapsHeight}%`
        : `-1px`
    })`,
    borderRadius: gapsHeight < 0 ? '0 0 12px 12px' : '12px 12px 0 0',
    minHeight: `${gapMinHeight}%`,
  } as ThemeUIStyleObject
  const gapsLabelStyle = {
    [isGapsNegative ? 'bottom' : 'top']: isGapsAboveMaxHeight ? '8px' : '-34px',
  } as ThemeUIStyleObject
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        flex: 1,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          flex: 1,
          borderRadius: '12px 12px 0 0',
          bg: item.color,
          transition: makeTransition('opacity'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          minHeight: `${item.value > 100 ? 100 : item.value}%`,
          opacity: 0.8,
          position: 'relative',
          '&:hover': { opacity: 1, '.tooltip': { opacity: 1 } },
          ...(isGaps ? gapsStyle : {}),
        }}
      >
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{
            color:
              (!isGaps && round(item.value) > 84) || isGapsAboveMaxHeight
                ? 'white'
                : 'grey500',
            top: isGaps ? undefined : round(item.value) > 84 ? '8px' : '-34px',
            position: 'absolute',
            ...(isGaps ? gapsLabelStyle : {}),
          }}
        >
          {label}
        </Text>
        <Tooltip tooltip={item.tooltip} />
      </Box>
    </Box>
  )
}
