import React, { FunctionComponent } from 'react'

import dayjs from 'dayjs'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import { useGetSurveyWindowArchiveQuery } from 'store/surveyWindow/surveyWindowApi'

import { SquaredProgressBar } from './SquaredProgressBar'

const StyledWhiteBox = styled(WhiteBoxWithShadow)`
  flex-direction: column;
  padding-bottom: 60px;
`

const Row = styled.div`
  display: grid;
  width: 100%;
  padding: 20px 40px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
`

const ArchiveItem = styled(Row)`
  border-bottom: solid 1px ${baseColors.coolGrey300};
`

const Completion = styled.div`
  display: flex;
  gap: 24px;
`

const Progress = styled(SquaredProgressBar)`
  min-width: 160px;
`

const StyledText = styled(Text)`
  white-space: nowrap;
`

export const SurveyWindowsArchive: FunctionComponent = () => {
  const { data, isSuccess } = useGetSurveyWindowArchiveQuery()

  const dateFormat = 'DD MMM YYYY'

  if (isSuccess && data?.length > 0) {
    return (
      <StyledWhiteBox>
        <Row>
          {['Survey', 'Responses collected', 'Responses'].map(header => (
            <StyledText
              key={header}
              element="h4"
              type="body"
              size="xs"
              fontWeight={700}
            >
              {header}
            </StyledText>
          ))}
        </Row>
        {data.map(surveyWindow => (
          <ArchiveItem key={surveyWindow.id}>
            <StyledText element="p" type="body" size="md" fontWeight={600}>
              {surveyWindow.name}
            </StyledText>
            <StyledText element="span" type="body" size="md" fontWeight={400}>
              {`${dayjs(surveyWindow.openedAt).format(dateFormat)} - ${dayjs(
                surveyWindow.closedAt
              ).format(dateFormat)}`}
            </StyledText>
            <Completion>
              <StyledText element="h3" type="body" size="md" fontWeight={400}>
                {`${surveyWindow.totalCompleted} / ${surveyWindow.totalInvited}`}
              </StyledText>
              <Progress
                value={surveyWindow.totalCompleted}
                max={surveyWindow.totalInvited}
              />
            </Completion>
          </ArchiveItem>
        ))}
      </StyledWhiteBox>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
