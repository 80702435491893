import { useState } from 'react'

import { Box, Grid, Input } from '@theme-ui/components'

import Button from '../Button'
import Field from '../Field'
import Icon, { icons, IconName } from '../Icon'

export const UIComponentsExamples = () => {
  const [value, setValue] = useState('david')
  return (
    <Box sx={{ p: 4 }}>
      <Box mb={4}>
        <Field
          label="Label"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Placeholder"
          error="asd"
          icon="search"
        />
        <Input placeholder="asd" />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button size="large">Go to the company assessment</Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button>Add member</Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button icon="addMember" iconPosition="left">
          Add member
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button size="small">Small button</Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button icon="arrowRight" iconSmall size="large">
          Next
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button icon="arrowRight" iconSmall disabled size="large">
          Next
        </Button>
      </Box>

      <Box sx={{ mb: 6 }}>
        <Button variant="secondary" size="large">
          Cancel
        </Button>
      </Box>

      <Grid
        columns="repeat(6, 24px 1fr)"
        gap={2}
        mb={10}
        sx={{ whiteSpace: 'nowrap', fontSize: 12 }}
      >
        {Object.keys(icons).map(iconName => (
          <>
            <Icon name={iconName as IconName} /> {iconName}
          </>
        ))}
      </Grid>
    </Box>
  )
}
