import { FunctionComponent } from 'react'

import { Navigate as Redirect, useParams } from 'react-router-dom'

import { AccountSettings } from './AccountSettings'

type SettingsSections = 'account'

const sectionMap: Record<SettingsSections, FunctionComponent> = {
  account: AccountSettings,
}

export const Settings: FunctionComponent = () => {
  const { section } = useParams<{ section?: SettingsSections }>()

  const SettingsSectionComponent =
    section && sectionMap[section] ? sectionMap[section] : undefined

  if (SettingsSectionComponent) {
    return <SettingsSectionComponent />
  }

  return <Redirect to="/settings/account" />
}
