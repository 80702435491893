export enum ApiVersionOptions {
  V1 = 'v1',
  V2 = 'v2',
}

export type ApiVersion = ApiVersionOptions.V1 | ApiVersionOptions.V2 | undefined
export type SessionId = number | undefined

export interface ApiHeadersState {
  accountId: number
  apiVersion: ApiVersion
  companyId: number
  sessionId: SessionId
}
