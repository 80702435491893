import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { shallowEqual } from 'react-redux'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { getAPIVersion } from 'store/company/companySlice'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

import { Roles } from './useAuthRoles'

export const useGetApiHeaderData = () => {
  const { user } = useAuth0()
  const dispatch = useAppDispatch()
  const { companyId, accountId, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      companyId: state.apiHeadersReducer.companyId,
      accountId: state.apiHeadersReducer.accountId,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const isAdmin =
    user?.['https://fairhq.co/app_metadata']?.roles?.includes(Roles.ADMIN) ||
    user?.['https://fairhq.co/app_metadata']?.roles?.includes(Roles.DVC_ADMIN)

  const companyIdFromUser =
    user?.['https://fairhq.co/user_metadata']?.companies?.[0]

  useEffect(() => {
    if (!isAdmin && companyIdFromUser) {
      dispatch(apiHeadersActions.setCompanyId(companyIdFromUser))
    }
  }, [dispatch, isAdmin, companyIdFromUser])

  useGetAccountQuery({
    apiVersion,
  })

  const { refetch: refetchSession } = useGetSessionsQuery()

  useEffect(() => {
    if (companyId) {
      dispatch(getAPIVersion())
    }
  }, [dispatch, companyId])

  useEffect(() => {
    refetchSession()
  }, [refetchSession, accountId, companyId])

  return isAdmin || !!(companyId && accountId && apiVersion && sessionId)
}
