import React, { FunctionComponent } from 'react'

import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Row, Divider, Col } from 'rsuite'

import styled from 'styled-components'

import { Field } from './Field'
import { FieldsContainer } from './styled'

export const FieldsRow = styled(Row)`
  margin-bottom: 24px;
`

export const displayField =
  (t: TFunction) =>
  ({ accepter, name, props, hidden, hiddenLabel }: Partial<FieldOption>) =>
    !hidden ? (
      <Field
        key={name}
        accepter={accepter}
        name={name}
        label={hiddenLabel ? undefined : t(`form.${name}.label`)}
        placeholder={t(`form.${name}.placeholder`)}
        {...props}
      />
    ) : undefined

export interface FieldOption {
  name: string
  accepter: React.ComponentType<any>
  props: any
  hidden: boolean
  hiddenLabel: boolean
}

export interface AdvancedFieldOption {
  title: string
  group: Array<Partial<FieldOption>>[]
  divider?: boolean
}

export const AdvancedFields: FunctionComponent<{
  data: Array<AdvancedFieldOption>
}> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <FieldsContainer>
      {data.map(({ title, divider, group }) => (
        <Row key={title} className="fhq-row">
          <h4>{title}</h4>
          {group.map((rows, index) => (
            <FieldsRow key={`field-${index}`} gutter={20}>
              {rows.map((row, indexRow) => (
                <Col
                  key={`field-${index}-row-${indexRow}`}
                  xs={24}
                  md={rows.length === 1 ? 12 : 24 / rows.length}
                >
                  {displayField(t)(row)}
                </Col>
              ))}
            </FieldsRow>
          ))}
          {divider ? <Divider /> : undefined}
        </Row>
      ))}
    </FieldsContainer>
  )
}
