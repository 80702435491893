import React, { FunctionComponent, useEffect, useState } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { space } from '@fairhq/common'

import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { useGetAvailableCampaignsQuery } from './campaignsApi'
import { Campaign } from './compound/Campaign'

const Container = styled.div`
  margin-bottom: ${space[10]}px;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: ${space[5]}px;
`

export const AvailableCampaigns: FunctionComponent = () => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [openModalId, setOpenModalId] = useState<number | null>(null)
  const { data: availableCampaigns } = useGetAvailableCampaignsQuery({
    sessionId,
  })
  const { t } = useTranslation()

  const { search } = useLocation()
  const campaignSelected = new URLSearchParams(search).get('selected')

  useEffect(() => {
    if (
      availableCampaigns &&
      availableCampaigns?.length > 0 &&
      campaignSelected
    ) {
      const element = document.getElementById(campaignSelected)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [campaignSelected, availableCampaigns])

  return (availableCampaigns?.length ?? 0) > 0 ? (
    <Container>
      <HeadingContainer>
        <Heading sx={{ mr: 4 }}>{t('campaigns.available.title')}</Heading>
        <Text as="div" variant="label">
          {t('campaigns.available.description')}
        </Text>
      </HeadingContainer>
      {availableCampaigns?.map(
        ({
          id,
          code: campaignCode,
          theme: { code: themeCode, image, color },
          frequency,
          totalNudges,
        }) => (
          <Campaign key={id} id={`campaign-${id}`}>
            <Campaign.LeftColumn>
              <Campaign.Visual image={image} />
              <Campaign.Content>
                <Campaign.Theme themeCode={themeCode} color={color} />
                <Campaign.Title campaignCode={campaignCode} />
                <Campaign.Description campaignCode={campaignCode} />
                <Campaign.Footer>
                  <Campaign.GenericTiming
                    totalNudges={totalNudges}
                    frequency={frequency}
                  />
                </Campaign.Footer>
              </Campaign.Content>
            </Campaign.LeftColumn>
            <Campaign.ScheduleButton
              onClick={() => {
                setIsModalOpen(true)
                setOpenModalId(id)
              }}
            />
          </Campaign>
        )
      )}
      {isModalOpen && openModalId && (
        <Campaign.ScheduleModal
          onClose={() => {
            setIsModalOpen(false)
          }}
          id={openModalId}
        />
      )}
    </Container>
  ) : null
}
