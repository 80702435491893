import { Stripe } from '../customer/types'

export interface Price {
  id: string
  currency: string
  nickname: string
  unit_amount: number
  billing_scheme: 'per_unit' | 'tiered'
  tiers: PriceTiers[]
  lookup_key: string
}

interface PriceTiers {
  flat_amount: number | null
  flat_amount_decimal: number | null
  unit_amount: number
  unit_amount_decimal: number
  up_to: number
}

export interface PaymentInfo {
  customerId: string
  paymentMethodId: string
  price: string
  subscriptionId: string
  coupon: string
  quantity?: number
}
export interface RetryInvoiceInfo {
  customerId: string
  paymentMethodId: string
  invoiceId: string
}

interface Invoice {
  id: string
  created: number
  amount_paid: number
  invoice_pdf: string
}

export interface PaymentState {
  invoice: any
  isIncomplete: boolean
  loading: boolean
  loadingPrices: boolean
  loadingTax: boolean
  error: any
  coupon: Coupon
  paymentIntent: any
  subscription: Stripe
  tax: any
  invoices: Invoice[]
}

export interface Coupon {
  id: string
  name: string
  percent_off: number
  amount_off: number | null
  duration_in_months: number | null
  valid: boolean
}

export enum PriceLookupKeys {
  PRIVEQSTANDARD = 'priveqstandard',
  STANDARD_YEARLY = 'standard_yearly',
}
