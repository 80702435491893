import {
  useGetCertificatationQuery,
  useGetLevelsCompletionQuery,
} from 'store/reports/reportsApiWithQuery'
import { LevelCompletion } from 'store/reports/types'
import { QueryStatus } from 'store/types'
import { getLevelNumber } from 'utils/getLevelNumber'

export const useLevelAndCertification = () => {
  const {
    data: certification,
    isSuccess: isCertificationSucess,
    isError: isCertificationError,
  } = useGetCertificatationQuery()
  const {
    data: levelsCompletion,
    isSuccess: isLevelsCompletionSucess,
    isError: isLevelsCompletionError,
  } = useGetLevelsCompletionQuery()

  if (isCertificationSucess && isLevelsCompletionSucess) {
    let levelToUse = null
    let highestCompletedLevel = null

    for (const levelCompletion of levelsCompletion) {
      // Initializes it since the default is level 1
      if (levelToUse === null) {
        levelToUse = levelCompletion
      }

      // only overwrite level to be used if previous level is completed (100%)
      if (+(levelToUse.completionPercentage ?? 0) === 100) {
        levelToUse = levelCompletion

        if (+(levelCompletion.completionPercentage ?? 0) === 100) {
          highestCompletedLevel = levelCompletion
        }
      } else {
        break
      }
    }

    const isLevelCertified =
      highestCompletedLevel &&
      certification &&
      highestCompletedLevel.level === certification.level

    const isReadyForCertification =
      (highestCompletedLevel && !isLevelCertified) ?? undefined
    const labelsBase = isReadyForCertification
      ? `reports.getDVCCertified`
      : `reports.DVClevel.wip`

    return {
      queryStatus: QueryStatus.SUCCESS,
      certification,
      isReadyForCertification,
      labelsBase,
      levelInfo:
        isReadyForCertification && highestCompletedLevel
          ? {
              ...highestCompletedLevel,
              levelNumber: getLevelNumber(highestCompletedLevel.level),
            }
          : ({
              ...levelToUse,
              levelNumber: getLevelNumber(levelToUse?.level ?? `DVC0`),
            } as LevelCompletion & { levelNumber: number }),
    }
  }
  if (isCertificationError || isLevelsCompletionError) {
    return {
      queryStatus: QueryStatus.ERROR,
      certification: undefined,
      labelsBase: '',
      isReadyForCertification: undefined,
      levelInfo: undefined,
    }
  }
  return {
    queryStatus: QueryStatus.LOADING,
    certification: undefined,
    labelsBase: '',
    isReadyForCertification: undefined,
    levelInfo: undefined,
  }
}
