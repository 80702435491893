import { User } from '@auth0/auth0-spa-js'

import { ApiVersionOptions } from 'store/apiHeaders/types'

import { http } from 'utils/http'

import { Account, UserMetadata } from './types'

export const accountApi = {
  getAccounts(getState: () => unknown) {
    return http.get(getState, 'account/accounts', {
      'fairhq-version': ApiVersionOptions.V2,
    })
  },
  createAccount(getState: () => unknown, account: Partial<Account>) {
    return http.post(getState, 'account', account)
  },
  removeUser(getState: () => unknown, userId: string) {
    return http.delete(getState, `account/users/${userId}`)
  },
  updateUserMetadata(
    getState: () => unknown,
    user: Partial<User>,
    metadata: Partial<UserMetadata>
  ) {
    return http.post(getState, `account/users/me`, { user, metadata })
  },
  updateOnboarding(getState: () => unknown, user: Partial<User>) {
    return http.post(getState, `account/users/onboarding`, { user })
  },
  checkRole(getState: () => unknown) {
    return http.post(getState, `account/users/check`, {})
  },
  sendEmailVerification(getState: () => unknown, userId: string) {
    return http.post(getState, `account/users/${userId}/send`, {})
  },
  requestChangePasswordEmail(getState: () => unknown, userId: string) {
    return http.post(getState, `account/users/${userId}/password`, {})
  },
  activation(getState: () => unknown, userId: string) {
    return http.post(getState, `account/users/${userId}/activation`, {})
  },
  addUser(getState: () => unknown, user: User, authRole: string) {
    return http.post(getState, `account/users`, { user, authRole })
  },
  getUsers(getState: () => unknown) {
    return http.get(getState, `account/users`)
  },
  getIntercomHash(getState: () => unknown) {
    return http.getWithAxios(getState, `account/users/intercom`)
  },
}
