import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Slack: FunctionComponent<IconProps> = ({
  className,
  width = '36',
  height = '36',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.59 22.758a3.778 3.778 0 0 1-3.781 3.784 3.778 3.778 0 0 1-3.78-3.784 3.778 3.778 0 0 1 3.78-3.783h3.78v3.783Zm1.89 0a3.778 3.778 0 0 1 3.78-3.783c2.09 0 3.78 1.69 3.78 3.783v9.459A3.778 3.778 0 0 1 13.26 36a3.778 3.778 0 0 1-3.78-3.783v-9.459Z"
      fill="#E01E5A"
    />
    <path
      d="M13.26 7.567a3.778 3.778 0 0 1-3.78-3.784A3.778 3.778 0 0 1 13.26 0c2.09 0 3.78 1.691 3.78 3.783v3.784h-3.78Zm0 1.92c2.09 0 3.78 1.691 3.78 3.784a3.778 3.778 0 0 1-3.78 3.783H3.78A3.778 3.778 0 0 1 0 13.271a3.778 3.778 0 0 1 3.78-3.784h9.48Z"
      fill="#36C5F0"
    />
    <path
      d="M28.41 13.27a3.778 3.778 0 0 1 3.781-3.783c2.09 0 3.78 1.691 3.78 3.784a3.778 3.778 0 0 1-3.78 3.783h-3.78v-3.783Zm-1.89 0a3.778 3.778 0 0 1-3.78 3.784 3.778 3.778 0 0 1-3.78-3.783V3.783A3.778 3.778 0 0 1 22.74 0c2.09 0 3.78 1.691 3.78 3.783v9.488Z"
      fill="#2EB67D"
    />
    <path
      d="M22.74 28.433c2.09 0 3.78 1.691 3.78 3.784A3.778 3.778 0 0 1 22.74 36a3.778 3.778 0 0 1-3.78-3.783v-3.784h3.78Zm0-1.892a3.778 3.778 0 0 1-3.78-3.783 3.778 3.778 0 0 1 3.78-3.783h9.48c2.09 0 3.78 1.69 3.78 3.783a3.778 3.778 0 0 1-3.78 3.784h-9.48Z"
      fill="#ECB22E"
    />
  </svg>
)
