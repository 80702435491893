import { FunctionComponent } from 'react'

import { Bar } from './bar/Bar'
import { Comparison } from './comparison/Comparison'
import { Row } from './row/Row'
import { RowStack } from './row-stack/RowStack'
import { Stack } from './stack/Stack'
import { VisualizationComponents, VisualizationProps } from './types'
import { convertIntoVisualizationItem } from './utils/convertIntoVisualizationItem'

const Visualization: FunctionComponent<VisualizationProps> &
  VisualizationComponents = ({ type, ...componentProps }) => {
  const Component = Visualization[type]
  const {
    items,
    benchmark,
    isLegendVisible,
    isScoreBy10,
    legend,
    colorRules,
    isGaps,
    isMinMajSorted,
  } = componentProps

  return (
    <Component
      items={convertIntoVisualizationItem(
        items,
        type,
        benchmark,
        colorRules,
        isMinMajSorted
      )}
      benchmark={benchmark}
      isLegendVisible={isLegendVisible}
      isScoreBy10={isScoreBy10}
      legend={legend}
      isGaps={isGaps}
    />
  )
}

Visualization.Bar = Bar
Visualization.Comparison = Comparison
Visualization.Row = Row
Visualization.Stack = Stack
Visualization.RowStack = RowStack

export { Visualization }
