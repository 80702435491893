import { useEffect } from 'react'

import { shallowEqual } from 'react-redux'

import { useIntercom } from 'react-use-intercom'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import {
  useGetAuditAreasQuery,
  useGetIsScorableQuery,
} from 'store/audit/auditApiWithQuery'
import { getIntercomHash } from 'store/auth/authSlice'
import {
  useGetEntitlementsQuery,
  useGetSessionsQuery,
} from 'store/company/companyApiWithQuery'
import { getAPIVersion } from 'store/company/companySlice'
import { getSubscription } from 'store/customer/customerSlice'
import { Stripe } from 'store/customer/types'
import { useGetReportAreasQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { convertToIntercomSubscription } from 'utils/convertToIntercomSubscription'

import { getIntercomCompanyId } from 'utils/getIntercomCompanyId'

import { useAppDispatch, useAppSelector } from '../store/hooks'

import { identifyIntercomUser } from './identifyIntercomUser'
import { useAuthRoles } from './useAuthRoles'

export const useCompany = () => {
  const dispatch = useAppDispatch()
  const { isAdmin } = useAuthRoles()

  const {
    audit,
    user,
    jwt,
    intercomUserHash,
    version,
    userEmailVerified,
    company,
    companyId,
    apiVersion,
    sessionId,
    isActiveSubscription,
  } = useAppSelector(
    (state: State) => ({
      audit: state.auditReducer.status,
      company: state.companyReducer.company,
      user: state.authReducer.user,
      jwt: state.authReducer.jwt,
      intercomUserHash: state.authReducer.intercom,
      version: state.apiHeadersReducer.apiVersion,
      userEmailVerified: state.authReducer.user?.email_verified,
      companyId: state.apiHeadersReducer.companyId,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer?.sessionId,
      isActiveSubscription: state.customerReducer?.isActiveSubscription,
    }),
    shallowEqual
  )

  // The /account-setup route is a 'waiting' step for the backend to sync
  // with stripe. At this point, the backend will be returning errors,
  // so we don't want to be doing much fetching on this route.
  // The /setup-error route is what we show the user if the backend
  // took too long to sync, so we again don't want to be making calls
  // as the customer will be seeing errors
  const notReady =
    window.location.pathname.includes('/account-setup') ||
    window.location.pathname.includes('/setup-error')
  const {
    data: account,
    isLoading: isAccountLoading,
    refetch: refetchAccount,
  } = useGetAccountQuery({ apiVersion })
  const { data: sessions, refetch: refetchSessions } = useGetSessionsQuery()
  const latestSession = sessions?.[0]
  const { refetch: refetchIsScorable } = useGetIsScorableQuery({
    apiVersion,
    sessionId,
  })
  const { refetch: refetchReportAreas } = useGetReportAreasQuery({
    apiVersion,
    sessionId,
  })

  const { refetch: refetchAuditAreas } = useGetAuditAreasQuery({
    sessionId,
    query: {},
  })

  const { update: updateIntercom, shutdown: shutdownIntercom } = useIntercom()

  const shouldGetAccount =
    user?.user_metadata?.account &&
    !isAccountLoading &&
    version &&
    !account &&
    jwt &&
    userEmailVerified

  useGetEntitlementsQuery(undefined, { skip: !jwt || !companyId })

  useEffect(() => {
    if (
      user?.user_metadata?.onboarding?.hidden &&
      !audit &&
      account &&
      userEmailVerified &&
      isActiveSubscription &&
      !notReady
    ) {
      refetchAuditAreas()

      refetchReportAreas()
    }
  }, [
    audit,
    account,
    isActiveSubscription,
    user,
    userEmailVerified,
    notReady,
    refetchReportAreas,
    refetchAuditAreas,
  ])

  useEffect(() => {
    if (latestSession && !notReady) {
      refetchIsScorable()
    }
  }, [refetchIsScorable, latestSession, notReady])

  useEffect(() => {
    if (account && jwt && userEmailVerified) {
      dispatch(getIntercomHash())
        // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
        // and thinks we're returning nothing. Instructions on how to change that really
        // aren't clear
        // @ts-ignore
        .unwrap()
        .then((userHash: string) => {
          identifyIntercomUser({
            user,
            userHash,
            companyId,
            companyName: company?.name,
            companySector: company?.sector,
            updateIntercom,
            shutdownIntercom,
            isAdmin,
          })
        })
      refetchSessions()
    }
  }, [
    account,
    dispatch,
    jwt,
    userEmailVerified,
    updateIntercom,
    shutdownIntercom,
    user,
    companyId,
    company?.name,
    company?.sector,
    isAdmin,
    refetchSessions,
  ])

  useEffect(() => {
    if (shouldGetAccount) {
      refetchAccount()
    }
  }, [shouldGetAccount, refetchAccount])

  useEffect(() => {
    if (!version && jwt && user && userEmailVerified) {
      dispatch(getAPIVersion())
    }
  }, [dispatch, jwt, user, version, userEmailVerified])

  useEffect(() => {
    if (company?.id && !notReady) {
      dispatch(getSubscription())
        // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
        // and thinks we're returning nothing. Instructions on how to change that really
        // aren't clear
        // @ts-ignore
        .unwrap()
        .then((response: Stripe) => {
          updateIntercom(convertToIntercomSubscription(response))
        })
      identifyIntercomUser({
        user,
        userHash: intercomUserHash,
        companyId: company.id,
        companyName: company.name,
        companySector: company?.sector,
        companyCreatedAt: company.createdAt,
        updateIntercom,
        shutdownIntercom,
        isAdmin,
        auditType: latestSession?.audit_type,
      })
      updateIntercom({
        customAttributes: {
          company_id: getIntercomCompanyId(company?.id),
          company_name: company.name,
          industry: company?.sector,
          audit_type: latestSession?.audit_type,
        },
      })
    }
  }, [
    company,
    dispatch,
    updateIntercom,
    notReady,
    intercomUserHash,
    isAdmin,
    user,
    shutdownIntercom,
    latestSession,
  ])
}
