import { emptySplitApi } from 'emptySplitApi'

import { Price } from './types'

const paymentApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getPrices: build.query<Price[], void>({
      query: () => ({
        url: `payment/prices`,
      }),
    }),
  }),
})

export const { useGetPricesQuery } = paymentApi
