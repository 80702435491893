import { useParams } from 'react-router-dom'

import { ReportType } from 'store/reports/types'

interface ReportsParams extends Record<string, string> {
  type: ReportType
  code: string
}

export const useReportsParams = () => {
  const { code = '', type } = useParams<ReportsParams>()
  return { code, type: type as ReportType }
}
