import { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { Session } from 'store/company/types'

import { SurveyCard } from './SurveyCard'

export const SurveyList: FunctionComponent = () => {
  const { t } = useTranslation()

  const { data: allSessions } = useGetSessionsQuery()
  const sessions = allSessions?.slice(1)
  if (sessions?.length === 0) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <>
      <Text
        as="div"
        variant="bodyLargeBold"
        sx={{ color: 'grey300', mt: 6, mb: 4 }}
      >
        {t('survey.previouslyCompleted')}
      </Text>
      {sessions?.map((session: Session) => (
        <SurveyCard key={session.id} session={session} sx={{ mb: 4 }} />
      ))}
    </>
  )
}
