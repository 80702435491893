import { FunctionComponent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Navigate as Redirect } from 'react-router-dom'

import { ReactComponent as StrategyBlankslate } from 'assets/illustrations/strategy-blankslate.svg'
import { StateHandler } from 'components/StateHandler'
import { useAppSelector } from 'store/hooks'
import { useGetOldRecommendationsQuery } from 'store/oldRecommendations/oldRecommendationApi'
import { State } from 'store/state'
import Blankslate from 'ui-kit/Blankslate'

import { OldRecommendations } from './OldRecommendations'

export const OldStrategy: FunctionComponent = () => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState<string>('')
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const {
    data: oldRecommendations,
    isLoading,
    isSuccess,
    isError,
    refetch: refetchOldRecommendations,
  } = useGetOldRecommendationsQuery({ sessionId })

  useEffect(() => {
    refetchOldRecommendations()
  }, [refetchOldRecommendations])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <StateHandler
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      errorMessage={t('error.somethingWrong.fallback')}
    >
      {oldRecommendations && oldRecommendations.length > 0 ? (
        <OldRecommendations oldRecommendations={oldRecommendations} />
      ) : (
        <Blankslate
          titleKey="oldRecommendations.blankslate.title"
          textKey="oldRecommendations.blankslate.text"
          illustComp={StrategyBlankslate}
          buttonText="oldRecommendations.goToAudit"
          buttonOnClick={() => setRedirect('/audit')}
        />
      )}
    </StateHandler>
  )
}
