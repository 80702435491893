import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Target, Text, baseColors } from '@fairhq/common'

import { PlainCard } from './PlainCard'

const Container = styled(PlainCard)`
  display: flex;
  padding: 32px 40px;
  gap: 24px;
`

const ImageContainer = styled.div`
  align-self: center;
`

const Image = styled.img`
  width: 150px;
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`
export const CommitStrategyPrompt: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container>
      <ImageContainer>
        <Image
          src="https://files.fairhq.co/ilustrations/workflow-design-gameplan-team-teamwork-search-find-idea-thought.svg"
          alt="Abstract illustration about team work"
        />
      </ImageContainer>
      <div>
        <Text element="p" fontWeight={400} type="body" size="md">
          {t('promoteStrategy.draft.description')}
        </Text>
        <StyledButton
          onClick={() => navigate('/strategy')}
          iconLeft={<Target outline={baseColors.purple300} />}
        >
          {t('promoteStrategy.draft.button')}
        </StyledButton>
      </div>
    </Container>
  )
}
