import { FunctionComponent, useEffect, useState } from 'react'

import { usePrevious } from 'react-use'

import { ProgressBar, ProgressContainer } from '../../styled'

interface ProgressProps {
  progress: number
  hasError?: boolean
  done?: boolean
  mode?: 'local' | 'sub' | 'title' | 'header'
}

const Progress: FunctionComponent<ProgressProps> = ({
  hasError,
  mode,
  done,
  progress,
}) => {
  const [percent, setPercent] = useState(0)
  const previousPercent = usePrevious(progress)

  useEffect(() => {
    if (progress && previousPercent !== progress) {
      setPercent(progress)
    }
  }, [previousPercent, progress])

  return (
    <ProgressContainer>
      <ProgressBar
        done={done}
        progress={percent}
        hasError={hasError}
        mode={mode}
      />
    </ProgressContainer>
  )
}

export default Progress
