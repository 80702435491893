import { Price } from 'store/payment/types'

export function toAmount({
  price,
  discount,
  language,
  size,
  tax = 0,
  coupon,
}: {
  price?: Price
  discount?: number
  language: string
  tax?: number
  size?: number
  coupon?: any
}) {
  if (price?.billing_scheme === 'tiered' && size) {
    const { tiers } = price

    let totalAmount = 0
    let previousSizeRemainder = size
    let previousTierUpTo = 0

    for (const tier of tiers) {
      const tierRange = (tier.up_to ?? Infinity) - previousTierUpTo
      const currentSizeRemainder = previousSizeRemainder - tierRange

      if (currentSizeRemainder > 0) {
        totalAmount +=
          (tier.unit_amount === 0 && tier.flat_amount
            ? tier.flat_amount
            : (tier.up_to - previousTierUpTo) * tier.unit_amount) / 100
      } else {
        totalAmount +=
          (tier.unit_amount === 0 && tier.flat_amount
            ? tier.flat_amount
            : previousSizeRemainder * tier.unit_amount) / 100

        break
      }
      previousSizeRemainder = currentSizeRemainder
      previousTierUpTo = tier.up_to
    }

    let priceWithCoupon = coupon
      ? (coupon?.percent_off &&
          ((100 - coupon.percent_off) / 100) * (totalAmount ?? 0)) ||
        (coupon?.amount_off && (totalAmount ?? 0) - coupon.amount_off / 100)
      : 0
    if (priceWithCoupon < 0) {
      priceWithCoupon = 0
    }
    return toCurrency({
      number: ((coupon ? priceWithCoupon : totalAmount) ?? 0) * (1 + tax),
      language,
      currency: price.currency,
    })
  }
  return price
    ? toCurrency({
        number:
          (discount !== undefined ? discount : price.unit_amount / 100) *
          (1 + tax),
        language,
        currency: price.currency,
      })
    : ''
}

export function toSymbol(currency = 'GBP') {
  const symbols: Record<string, string> = { GBP: '£', EUR: '€', USD: '$' }
  return symbols[currency] || currency
}

export function toCurrency({
  number,
  language,
  currency = 'GBP',
}: {
  number: number
  currency?: string
  language: string
}) {
  return new Intl.NumberFormat([language, 'en'], {
    style: 'currency',
    currency,
  }).format(number)
}
