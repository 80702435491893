import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'

import { HappyFace } from './assets/HappyFace'
import { SadFace } from './assets/SadFace'
import { useExperienceBar } from './hooks/useExperienceBar'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const LabelAndBarWrapper = styled(Wrapper)`
  gap: 12px;
  min-width: 234px;
`

const BarWrapper = styled(Wrapper)`
  gap: 2px;
`

const Bar = styled.div<{ backgroundImage: string }>`
  width: 100px;
  height: 12px;
  border-radius: 8px;
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-repeat: no-repeat;
`

interface ExperienceBarProps {
  total: number
  detractors: number
  passives: number
  promoters: number
}

export const ExperienceBar: FunctionComponent<ExperienceBarProps> = ({
  total,
  detractors,
  passives,
  promoters,
}) => {
  const { sadSize, happySize, backgroundImage, colors } = useExperienceBar({
    total,
    detractors,
    passives,
    promoters,
  })

  return (
    <LabelAndBarWrapper>
      <Text
        element="p"
        type="body"
        size="xs"
        fontWeight={600}
        color={baseColors.grey500}
      >
        EXPERIENCE
      </Text>
      <BarWrapper>
        <SadFace stroke={colors.red} width={sadSize} height={sadSize} />
        <Bar backgroundImage={backgroundImage} />
        <HappyFace stroke={colors.green} width={happySize} height={happySize} />
      </BarWrapper>
    </LabelAndBarWrapper>
  )
}
