import { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Checkbox, CheckboxItem } from './Checkbox'

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const ListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

export interface CheckboxesProps {
  items: CheckboxItem[]
  className?: string
}

export const Checkboxes: FunctionComponent<CheckboxesProps> = ({
  items,
  className,
}) => (
  <List className={className}>
    {items.map(item => (
      <ListItem key={item.id}>
        <Checkbox item={item} />
      </ListItem>
    ))}
  </List>
)
