import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { useJobLevels } from 'hooks/useJobLevels'
import { Layout } from 'layout/Layout'
import { useGetSurveyWindowArchiveComparisonQuery } from 'store/surveyWindow/surveyWindowApi'

import { findJobLevel } from 'utils/findJobLevel'

import { CompanyMakeUp } from './CompanyMakeup'
import { DiversityOverview } from './DiversityOverview'
import { KeyIndustryDemographics } from './KeyIndustryDemographics'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const DiversityPage: FunctionComponent = () => {
  const { code: level } = useParams<{ code: string }>()

  const { permissions } = useAuthPermissions()
  const { t } = useTranslation()
  const levels = useJobLevels()
  const currentLevel = findJobLevel(levels, level)

  const { data, isSuccess } = useGetSurveyWindowArchiveComparisonQuery()

  const latestSurveyWindow = data?.[0]
  const previousSurveyWindow = data?.[1]
  return (
    <Layout
      contentSx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
      sidebar={<ReportsModularSidebar />}
      dark
    >
      <Header>
        <Text
          element="h1"
          type="heading"
          size="2xl"
          fontWeight={400}
          color={baseColors.coolGrey900}
        >
          {!level && t(`diversity.title`)}
          {level &&
            currentLevel &&
            t(`jobLevel.${currentLevel?.level.toLowerCase()}`)}
        </Text>

        {isSuccess && latestSurveyWindow && (
          <Text element="p" type="body" size="xs" fontWeight={400}>
            <Trans
              i18nKey="diversity.overview.headerInfo"
              components={
                permissions.includes(PermissionsEnum.READ_AUDIT)
                  ? [<Link to="/survey" />]
                  : []
              }
            />
          </Text>
        )}
      </Header>

      {isSuccess && latestSurveyWindow && (
        <>
          <DiversityOverview
            latestSurveyWindow={latestSurveyWindow}
            level={level}
          />

          <KeyIndustryDemographics
            latestSurveyWindow={latestSurveyWindow}
            previousSurveyWindow={previousSurveyWindow}
            level={level}
          />

          <CompanyMakeUp
            latestSurveyWindow={latestSurveyWindow}
            level={level}
          />
        </>
      )}
    </Layout>
  )
}
