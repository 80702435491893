import { CompanyInsightWithoutAreas } from 'features/insights/types'
import { Recommendation } from 'store/strategy/types'
import { Task } from 'store/tasks/types'

export const findInsightsAndTasks = ({
  companyInsights,
  companyTasks,
  recommendation,
  insightsCode,
}: {
  companyInsights: CompanyInsightWithoutAreas[]
  companyTasks: Task[]
  recommendation: Recommendation
  insightsCode: string[]
}) => {
  const relevantCompanyInsights = findInsights({
    companyInsights,
    insightsCode,
  })

  const tasks = findTasks({ companyTasks, recommendation })

  return { relevantCompanyInsights, tasks }
}

export const findInsights = ({
  companyInsights,
  insightsCode,
}: {
  companyInsights: CompanyInsightWithoutAreas[]
  insightsCode: string[]
}) => {
  const relevantCompanyInsights = companyInsights.filter(({ insight }) =>
    insightsCode.includes(insight.code)
  )

  return relevantCompanyInsights
}

export const findTasks = ({
  companyTasks,
  recommendation,
}: {
  companyTasks: Task[]
  recommendation: Recommendation
}) => {
  const tasks = companyTasks.filter(companyTask =>
    recommendation.linkedTasksCode.includes(companyTask.code)
  )

  return tasks
}
