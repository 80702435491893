import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { orderBy } from 'lodash'

import { waitForResponse } from '@fairhq/common'

import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isRejected } from 'store/helpers/isRejected'
import { OldCompanyRecommendation } from 'store/oldRecommendations/types'

import { companyApi } from './companyApi'
import { CompanyState, Company } from './types'

const clear = createAction('company/clear')

export const getAPIVersion = createAsyncThunk(
  'company/getAPIVersion',
  async (_, { dispatch, getState }) => {
    const data = await waitForResponse({
      callback: () => companyApi.getAPIVersion(getState),
    })
    const apiVersion = data.version
    dispatch(apiHeadersActions.setApiVersion(apiVersion || ''))
    return data
  }
)

export const updateOldRecommendation = createAsyncThunk(
  'company/updateOldRecommendation',
  async (
    {
      id,
      oldRecommendation,
    }: {
      id: number
      oldRecommendation: Partial<OldCompanyRecommendation>
    },
    { getState }
  ) => {
    const result = await waitForResponse({
      callback: () =>
        companyApi.updateOldRecommendation(getState, id, oldRecommendation),
    })
    return result
  }
)

export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async (
    { id, company }: { id: number; company: Partial<Company> },
    { getState }
  ) =>
    waitForResponse({
      callback: () => companyApi.updateCompany(getState, id, company),
    })
)

const initialState: Partial<CompanyState> = { loading: false }

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany(state, action) {
      const orderedSessions = orderBy(
        action.payload?.sessions,
        ['startDate'],
        ['desc']
      )
      const latestSession = orderedSessions[0]
      state.company = {
        ...(action.payload ?? {}),
        sessions: orderedSessions,
      }
      state.latestSession = latestSession
    },
    setSessionCurrentlyViewing(state, action) {
      state.sessionIdCurrentlyViewing = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)

      .addCase(getAPIVersion.fulfilled, (state, action) => {
        state.loading = false
        state.version = action.payload.version
      })
      .addCase(updateCompany.pending, state => {
        state.error = undefined
        state.loading = true
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false
        state.company = action.payload
      })
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isRejected('company'), handleErrorState)
      .addMatcher(isFulfilled('company'), state => {
        state.error = undefined
        state.loading = false
      })
  },
})

export const { actions: companyActions, reducer: companyReducer } = companySlice
