import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import i18next from 'i18next'

import { baseColors } from '@fairhq/common'
import { toDate } from 'utils/dates'

export interface DateProps {
  startDate: string
}

export const Date: FunctionComponent<DateProps> = ({ startDate }) => (
  <Text
    as="div"
    variant="subtitle"
    sx={{ fontSize: 18, mb: 2, color: baseColors.grey300 }}
  >
    {toDate(i18next.language, startDate)?.format('DD/MM/YYYY')}
  </Text>
)
