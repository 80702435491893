import { FunctionComponent } from 'react'

import { Box, Flex } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { Button as RSuiteButton } from 'rsuite'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Calendar, Rocket, Slack } from '@fairhq/common'
import { useGetSlackInstallStatusQuery } from 'features/campaigns/campaignsApi'
import { ScheduledCampaign } from 'features/campaigns/types'

import { State } from 'store/state'
import Button from 'ui-kit/Button'

import { useAppSelector } from '../../store/hooks'

import { PreviewScheduledCampaigns } from './PreviewScheduledCampaigns'
import { ScheduledCampaignIcon } from './ScheduledCampaignIcon'

const StyledButton = styled(RSuiteButton)`
  font-weight: bold;
  padding: 0;
  margin-top: -1px;
  color: ${baseColors.purple700};
`

interface SlackbotInfoProps {
  scheduledCampaigns?: ScheduledCampaign[]
}

export const SlackbotInfo: FunctionComponent<SlackbotInfoProps> = ({
  scheduledCampaigns,
}) => {
  const { t } = useTranslation()
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )
  const { data: withInstall } = useGetSlackInstallStatusQuery({ sessionId })
  const { showNewMessages } = useIntercom()
  const hasScheduledCampaigns =
    scheduledCampaigns && scheduledCampaigns?.length > 0
  const title = t(
    withInstall
      ? (hasScheduledCampaigns &&
          `promoteCampaigns.withScheduledCampaign.heading`) ||
          `promoteCampaigns.withSlackbot.heading`
      : `promoteCampaigns.installSlackbot.heading`
  )
  const buttonIcon = hasScheduledCampaigns
    ? undefined
    : (withInstall && <Calendar outline={baseColors.purple400} />) || (
        <Rocket outline={baseColors.purple400} />
      )
  const buttonLabel = t(
    withInstall
      ? (hasScheduledCampaigns &&
          `promoteCampaigns.withScheduledCampaign.manage`) ||
          `promoteCampaigns.withSlackbot.scheduleNow`
      : 'promoteCampaigns.installSlackbot.getStarted'
  )

  return (
    <div>
      <Text
        as="div"
        variant="h2"
        sx={{ mb: '16px', fontSize: '22px', lineHeight: '28px' }}
      >
        {title}
      </Text>
      {withInstall && hasScheduledCampaigns ? (
        <PreviewScheduledCampaigns scheduledCampaigns={scheduledCampaigns} />
      ) : (
        <Box
          sx={
            withInstall
              ? {
                  background: baseColors.grey100,
                  padding: '16px',
                  borderRadius: '10px',
                }
              : undefined
          }
        >
          <Flex sx={{ alignItems: 'flex-start' }} mb={2}>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '24px',
                minWidth: '24px',
                padding: '4px',
                marginRight: '12px',
              }}
            >
              <Slack width="16" height="16" />
            </Flex>
            <Text as="div" sx={{ fontWeight: 700, fontSize: '16px' }}>
              {t(
                withInstall
                  ? `promoteCampaigns.withSlackbot.installed`
                  : 'promoteCampaigns.installSlackbot.install'
              )}
            </Text>
          </Flex>
          <Flex sx={{ alignItems: 'flex-start' }}>
            <ScheduledCampaignIcon />
            <Text as="div" sx={{ fontSize: '14px' }}>
              {t(
                withInstall
                  ? `promoteCampaigns.withSlackbot.maintain`
                  : 'promoteCampaigns.installSlackbot.maintain'
              )}
            </Text>
          </Flex>
        </Box>
      )}
      <Box sx={{ textAlign: 'center' }} mt={4}>
        <Button
          as={Link}
          variant={hasScheduledCampaigns ? 'secondary' : undefined}
          to="/campaigns"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            svg: { opacity: 1, fill: 'none' },
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              span: { ml: 2 },
            }}
          >
            {buttonIcon}
            <span>{buttonLabel}</span>
          </Flex>
        </Button>
        {withInstall || hasScheduledCampaigns ? undefined : (
          <Text
            as="div"
            sx={{ marginTop: '16px', fontSize: '14px', a: { fontWeight: 700 } }}
          >
            <Trans
              i18nKey="promoteCampaigns.installSlackbot.requestIntegration"
              components={[
                <StyledButton
                  onClick={() =>
                    showNewMessages(
                      t(
                        'promoteCampaigns.installSlackbot.requestIntegrationMessage'
                      )
                    )
                  }
                  appearance="link"
                />,
              ]}
            />
          </Text>
        )}
      </Box>
    </div>
  )
}
