import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Radio, RadioGroup, Schema, SelectPicker } from 'rsuite'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { Label } from 'components/questions/Label'
import { useSchema } from 'hooks'
import { useSubmit } from 'hooks/useSubmit'
import { createAccount } from 'store/account/accountSlice'
import { authActions } from 'store/auth/authSlice'
import { companyActions } from 'store/company/companySlice'
import { CompanySector } from 'store/company/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { locationOptions } from 'utils/locationOptions'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  .rs-radio {
    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }
`
const Image = styled.img`
  width: 120px;
`

const buildCompanyFormValues = ({
  name,
  sector,
  size,
  location,
}: any = {}) => ({
  name: name || '',
  sector: sector || 'technology',
  size: size || '',
  location: location || 'London, GBR',
})

export const useCompanyForm = (next: () => void) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const {
    error,
    accountId,
    company: currentCompany,
  } = useAppSelector(
    (state: State) => ({
      error: state.companyReducer.error,
      accountId: state.apiHeadersReducer.accountId,
      company: state.companyReducer.company,
    }),
    shallowEqual
  )
  const [form, setForm] = useState(buildCompanyFormValues())
  const [errorMessage, setErrorMessage] = useState()
  const { onSubmit, submitting, setSubmitting } = useSubmit(() => {
    dispatch(companyActions.setCompany(form))
    if (!accountId) {
      dispatch(
        createAccount({
          name: form.name,
        })
      )
    }
    dispatch(companyActions.setCompany(form))
    getAccessTokenSilently().then(token => {
      if (token) {
        dispatch(authActions.setJWT(token))
      }
      next()
    })
  })

  const sectorsAuditTypes = [
    { value: CompanySector.TECHNOLOGY, label: 'company.sector.fairhq' },
    {
      value: CompanySector.PRIVATE_EQUITY,
      label: 'company.sector.PrivateEquity',
    },
    {
      value: CompanySector.VENTURE_CPAITAL,
      label: 'company.sector.VentureCapital',
    },
  ]
  const { name, sector: sectorSchema, size, location } = useSchema()
  const model = Schema.Model({ name, sector: sectorSchema, size, location })
  const fields = [
    { name: 'name' },
    {
      name: 'sector',
      accepter: RadioGroup,
      props: {
        children: (
          <StyledWrapper>
            <Text
              element="p"
              type="body"
              size="xs"
              fontWeight={400}
              color={baseColors.grey400}
            >
              {t('hello.companyInfo.sectorDescription')}
            </Text>
            <div>
              {sectorsAuditTypes.map(({ value, label }) => (
                <Radio key={value} value={value} className="sign-up">
                  <Label label={t(label)} checked={value === form.sector} />
                  {value === 'venture_capital' && (
                    <Image
                      src="https://files.fairhq.co/dvc-partner.svg"
                      alt="Abstract image representing campaign theme"
                    />
                  )}
                </Radio>
              ))}
            </div>
          </StyledWrapper>
        ),
      },
    },
    {
      name: 'size',
      props: {
        type: 'number',
        style: { width: '50%' },
      },
    },
    {
      name: 'location',
      accepter: SelectPicker,
      props: {
        cleanable: false,
        data: locationOptions.map(location => ({
          ...location,
          label: t(location.label),
        })),
      },
    },
  ]

  useEffect(() => {
    if (error && submitting) {
      setSubmitting(false)
      setErrorMessage(t(`error.${error.name}`, { defaultValue: error.message }))
    } else {
      setErrorMessage(undefined)
    }
  }, [error, setSubmitting, submitting, t])

  useEffect(() => {
    if (currentCompany) {
      setForm(buildCompanyFormValues(currentCompany))
    }
  }, [currentCompany])

  return {
    errorMessage,
    fields,
    form,
    model,
    setForm,
    onSubmit,
    submitting,
  }
}
