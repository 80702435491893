import React, { FunctionComponent, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

import { BaseConfirmModal } from 'components/BaseConfirmModal'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import {
  useGetSurveyWindowQuery,
  useOpenSurveyWindowMutation,
} from 'store/surveyWindow/surveyWindowApi'

import { SurveyWindow } from 'store/surveyWindow/types'

import { PromptCard } from './PromptCard'

interface SurveyWindowDraftProps {
  currentSurveyWindow: SurveyWindow
}

export const SurveyWindowDraft: FunctionComponent<SurveyWindowDraftProps> = ({
  currentSurveyWindow,
}) => {
  const { employeesCount } = useAppSelector(
    (state: State) => ({
      employeesCount: state.employeeReducer.count,
    }),
    shallowEqual
  )

  const [showWarning, setShowWarning] = useState<boolean>(false)
  const { t } = useTranslation()
  const { isFetching: isFetchingCurrent } = useGetSurveyWindowQuery()

  const [openSurveyWindow, { isLoading: isLoadingOpen, isError: isErrorOpen }] =
    useOpenSurveyWindowMutation()

  const onClick = () => {
    if (!employeesCount) {
      setShowWarning(true)
    } else {
      openSurveyWindow({ id: currentSurveyWindow.id })
    }
  }

  const onCloseWarning = () => {
    setShowWarning(false)
  }

  return (
    <>
      <PromptCard
        title="surveys.draft.title"
        description="surveys.draft.description"
        onClick={onClick}
        buttonText="surveys.draft.button"
        isLoading={isFetchingCurrent || isLoadingOpen}
        error={isErrorOpen}
        guidance={
          <Trans
            i18nKey="surveys.draft.guidance"
            components={[
              <Link to="/employees" />,
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={t('surveys.draft.guidanceLinkAnnounce')}
              >
                |
              </a>,
            ]}
          />
        }
      />
      {showWarning && (
        <BaseConfirmModal
          onClose={onCloseWarning}
          title={t('surveys.draft.modalTitle')}
          body={t('surveys.draft.modalBody')}
          confirmCustomText={t('surveys.draft.modalButton')}
          withSingleButton
        />
      )}
    </>
  )
}
