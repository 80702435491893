import React, { useState, FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'

import { PRACTICE_MAX_SHOW } from 'const'

import Icon from 'ui-kit/Icon'

import { Practice, PracticeType } from './Practice'

interface PracticesProps {
  data: PracticeType[]
  title?: string
}

export const Practices: FunctionComponent<PracticesProps> = ({
  data,
  title,
}) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const orderedData = orderBy(data, ['checked', 'id'], 'desc')

  const missing = orderedData.filter(practice => !practice.checked).length

  return (
    <Box>
      {(title || missing) && (
        <Flex
          sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 3 }}
        >
          {title && (
            <Text as="div" variant="bodyBold" color="grey500">
              {title}
            </Text>
          )}
          {missing ? (
            <Text
              as="div"
              variant="label"
              sx={{ textAlign: 'right', color: 'grey400' }}
            >{`${missing} missing`}</Text>
          ) : null}
        </Flex>
      )}

      {orderedData.slice(0, PRACTICE_MAX_SHOW).map((practice, index) => (
        <Practice practice={practice} key={index} />
      ))}
      {showMore &&
        orderedData
          .slice(PRACTICE_MAX_SHOW)
          .map((practice, index) => (
            <Practice practice={practice} key={index} />
          ))}

      {orderedData.length > PRACTICE_MAX_SHOW && (
        <Box sx={{ mt: 3 }}>
          <Flex
            as="button"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              padding: 0,
              bg: 'transparent',
            }}
            onClick={() => setShowMore(!showMore)}
            role="button"
          >
            <Text as="div" variant="labelBold" sx={{ color: 'grey400' }}>
              {showMore ? t('showLess') : t('showMore')}
            </Text>
            <Icon
              name={showMore ? 'arrowUp' : 'arrowDown'}
              small
              sx={{ ml: 1 }}
            />
          </Flex>
        </Box>
      )}
    </Box>
  )
}
