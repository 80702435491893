import React, { FunctionComponent, useState, useEffect } from 'react'

import { getDay, nextMonday, addDays } from 'date-fns'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import { SlackChannel } from 'features/campaigns/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { scrollTop } from 'utils/scrollTop'

import { useScheduleCampaignMutation } from '../../campaignsApi'
import { useAvailableCampaign } from '../../hooks/useAvailableCampaign'

import { DateModal } from './Campaign.DateModal'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

const getNextWeekday = () => {
  const today = new Date()
  return getDay(today) === 5 || getDay(today) === 6
    ? nextMonday(today)
    : addDays(today, 1)
}

// This is 11am local time
const getDefaultStartTime = (): Date =>
  new Date(dayjs().hour(11).minute(0).second(0).format())

export interface ScheduleModalProps {
  onClose: () => void
  id: number
}

export const ScheduleModal: FunctionComponent<ScheduleModalProps> = ({
  onClose,
  id,
}) => {
  const [startDate, setStartDate] = useState<Date>(getNextWeekday())
  const [publishTime, setPublishTime] = useState<Date>(getDefaultStartTime())
  const [channel, setChannel] = useState<SlackChannel>()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { totalNudges, frequency, code } = useAvailableCampaign(id) || {}

  const [scheduleCampaign, { isLoading, isError, isSuccess }] =
    useScheduleCampaignMutation()

  useEffect(() => {
    if (isSuccess && !isLoading) {
      onClose()
      scrollTop()
    }
  }, [isSuccess, isLoading, onClose])

  const formattedStartDate: string = dayjs
    .utc(startDate)
    .startOf('day')
    .toISOString()

  const formattedStartTime: string = dayjs.utc(publishTime).format('kk:mm')

  return (
    <DateModal
      modalTitleKey="campaigns.schedule.modalTitle"
      titleKey={`campaigns.${code}.title`}
      descriptionKey={`campaigns.${code}.description`}
      totalNudges={totalNudges}
      frequency={frequency}
      cancelKey="campaigns.schedule.cancel"
      confirmKey="campaigns.schedule.confirm"
      errorKey="campaigns.schedule.error"
      onConfirm={() => {
        if (id && channel) {
          scheduleCampaign({
            campaignId: id,
            startDate: formattedStartDate,
            publishTime: formattedStartTime,
            sessionId,
            channel,
          })
        }
      }}
      isLoading={isLoading}
      isError={isError}
      onClose={onClose}
      startDate={startDate}
      onStartDateChange={(date: Date | null) =>
        setStartDate(date ?? new Date())
      }
      publishTime={publishTime}
      onPublishTimeChange={(date: Date | null) =>
        setPublishTime(date ?? new Date())
      }
      channel={channel}
      onChannelChange={(channel: SlackChannel) => setChannel(channel)}
    />
  )
}
