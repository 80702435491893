import { chain } from 'lodash'

import { Colors } from '@fairhq/common'

import { GraphItem } from 'components/visualization/types'

// Temporarily hardcoded as the colours are still being worked on
export const REPORTS_BLUE = '#ccedfa' as Colors

// Only add the benchmark if there's something to compare against
const getArrayWithMaybeComparison = (array: any, comparison: any) => {
  if (array.length > 0) {
    return array.concat(comparison)
  }

  return array
}

export const getDemGroupRepresentationByTab = ({
  jobLevelType,
  jobLevel,
  location,
  department,
  demGroups,
  benchmark,
  groups,
  t,
}: Record<
  'jobLevel' | 'group' | 'location' | 'department' | 'demGroups' | string,
  any
>) => {
  const levels = chain(jobLevel).groupBy('level').value()
  const locations = chain(location).groupBy('country').value()
  const departments = chain(department).groupBy('department').value()

  const getItem = ({
    key,
    label,
    minority,
    majority,
    total: max,
  }: Record<string, any>) => {
    const minorityTotal =
      Math.round(((minority?.completed ?? 0) * 100) / max) || 0
    const majorityTotal =
      Math.round(((majority?.completed ?? 0) * 100) / max) || 0

    return {
      key,
      label,
      values: [
        { value: minorityTotal, color: Colors.PURPLE },
        { value: majorityTotal, color: REPORTS_BLUE },
        {
          value: 100 - minorityTotal - majorityTotal,
          color: Colors.GREY100,
          hideLabel: true,
        },
      ],
      max,
    }
  }

  const comparison = benchmark
    ? [
        {
          key: benchmark?.benchmarkOptionCode,
          label: t(`reports.${benchmark?.benchmarkOptionCode}`),
          values: [
            {
              // eslint-disable-next-line no-unsafe-optional-chaining
              value: Math.round(+benchmark?.benchmarkOptionValue * 100 || 0),
              color: Colors.GREY300,
            },
          ],
          max: 100,
        },
      ]
    : []

  return {
    byJobLevel: getArrayWithMaybeComparison(
      jobLevelType.map(({ key, label, type, total }: any) => {
        let { minority, majority } = chain(levels[type])
          .keyBy('dem_group_type')
          .value()

        if (key === 'employees') {
          const demGroupsByType = chain(demGroups)
            .keyBy('dem_group_type')
            .value()
          minority = demGroupsByType?.minority
          majority = demGroupsByType?.majority
        }

        return getItem({ key, label, minority, majority, total })
      }),
      comparison
    ),
    byLocation: getArrayWithMaybeComparison(
      Object.keys(locations).map(country => {
        const { minority, majority } = chain(locations[country])
          .keyBy('dem_group_type')
          .value()

        return getItem({
          key: country,
          label: t(`country.${country || 'na'}`),
          minority,
          majority,
          total: chain(groups?.location).keyBy('country').value()?.[country]
            ?.completed,
        })
      }),
      comparison
    ),
    byDepartment: getArrayWithMaybeComparison(
      Object.keys(departments)?.map(depName => {
        const { minority, majority } = chain(departments[depName])
          .keyBy('dem_group_type')
          .value()

        return getItem({
          key: depName,
          label: t(depName || 'na'),
          minority,
          majority,
          total: chain(groups?.department).keyBy('department').value()?.[
            depName
          ]?.completed,
        })
      }),
      comparison
    ),
  } as Record<string, GraphItem[]>
}
