import { FunctionComponent } from 'react'

import { Flex, FlexProps } from '@theme-ui/components'

import { formatSpan } from 'utils/formatSpan'

export type SpanProp = number | number[]

type DashboxProps = { span?: SpanProp } & FlexProps

export const Dashbox: FunctionComponent<DashboxProps> = ({
  span,
  sx,
  children,
  ...rest
}) => (
  <Flex
    sx={{
      position: 'relative',
      bg: 'white',
      borderRadius: 20,
      flexDirection: 'column',
      gridColumn: span ? formatSpan(span) : '',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Flex>
)
