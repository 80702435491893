import React from 'react'

import { ReportsAreasCore } from 'features/reportsAreasCore/ReportsAreasCore'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'

import { DiversityPage } from './DiversityPage'

export const ReportsDiversity = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  if (
    companyEntitlements?.entitlements.includes(EntitlementsEnum.REPORTS) ||
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.REPORTS_INSIGHTS
    )
  ) {
    return <ReportsAreasCore />
  }

  if (companyEntitlements?.entitlements.includes(EntitlementsEnum.DEM_REPORT)) {
    return <DiversityPage />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
