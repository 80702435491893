import { FunctionComponent } from 'react'

import { SurveyWindow, SurveyWindowStatus } from 'store/surveyWindow/types'
import { MIN_SURVEY_COMPLETION_DECIMAL } from 'utils/minSurveyCompletion'

import { SurveyWindowClose } from './SurveyWindowClose'
import { SurveyWindowClosed } from './SurveyWindowClosed'
import { SurveyWindowDraft } from './SurveyWindowDraft'
import { SurveyWindowOpen } from './SurveyWindowOpen'

interface CurrentSurveyWindowProps {
  currentSurveyWindow: SurveyWindow
}

export const CurrentSurveyWindow: FunctionComponent<
  CurrentSurveyWindowProps
> = ({ currentSurveyWindow }) => {
  if (currentSurveyWindow.status === SurveyWindowStatus.DRAFT) {
    return <SurveyWindowDraft currentSurveyWindow={currentSurveyWindow} />
  }

  if (currentSurveyWindow.status === SurveyWindowStatus.OPEN) {
    if (
      currentSurveyWindow.totalCompleted / currentSurveyWindow.totalInvited >=
      MIN_SURVEY_COMPLETION_DECIMAL
    ) {
      // has reached threshold
      return <SurveyWindowClose currentSurveyWindow={currentSurveyWindow} />
    }
    return <SurveyWindowOpen currentSurveyWindow={currentSurveyWindow} />
  }

  if (currentSurveyWindow.status === SurveyWindowStatus.CLOSED) {
    return <SurveyWindowClosed currentSurveyWindow={currentSurveyWindow} />
  }

  return null
}
