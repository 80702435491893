import React, { FunctionComponent, useState } from 'react'

import { Flex, Heading, Text } from '@theme-ui/components'

import { orderBy } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { Colors } from '@fairhq/common'

import { Tabs, Tab } from 'components/Tabs'

import {
  VisualizationItemProps,
  VisualizationItemValueProps,
} from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import { AssessmentSidebar } from 'features/assessments/AssessmentSidebar'
import { Layout } from 'layout/Layout'

import { PageTop } from 'layout/PageTop'

import { useAppSelector } from 'store/hooks'

import { useGetCompanyBreakdownQuery } from 'store/reports/reportsApiWithQuery'

import { State } from 'store/state'

import BorderBox from 'ui-kit/BorderBox'

import { REPORTS_BLUE } from '../reportsAreasCore/utils/representation/getDemGroupRepresentationByTab'

import { PayGapBreakdown } from './PayGapBreakdown'

export const CompanyBreakdown: FunctionComponent<{}> = () => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { data: breakdown } = useGetCompanyBreakdownQuery({
    apiVersion,
    sessionId,
  })
  const [activeTab, setActiveTab] = useState('byJobLevel')
  const getTooltip = (value: number, max: number) => (
    <Trans
      key={Math.random()}
      i18nKey="dashboard.diversity.makeup.tooltip"
      components={[<strong />]}
      values={{ completed: max, count: value }}
    />
  )
  const transformToGraphItem = (
    _type: 'level' | 'department' | 'country' | string,
    _group: 'ethnicity' | 'gender'
  ) => {
    const items = orderBy(
      breakdown?.filter(({ type }) => type === _type) ?? [],
      ['group']
    )
    return items.map(
      ({
        group,
        poc = 0,
        total = 0,
        unknownEthnicity = 0,
        white = 0,
        male = 0,
        female = 0,
        otherGender = 0,
        unknownGender = 0,
      }) => {
        const values: VisualizationItemValueProps[] = []
        if (_group === 'ethnicity') {
          values.push({
            key: 'poc',
            value: (poc * 100) / total,
            color: Colors.PURPLE,
            label: t('company.breakdown.poc'),
            tooltip: getTooltip(poc, total),
          })
          values.push({
            key: 'white',
            value: (white * 100) / total,
            color: REPORTS_BLUE,
            label: t('company.breakdown.white'),
            tooltip: getTooltip(white, total),
          })
          values.push({
            key: 'unknownEthnicity',
            value: (unknownEthnicity * 100) / total,
            color: Colors.GREY200,
            label: t('company.breakdown.unknownEthnicity'),
            tooltip: getTooltip(unknownEthnicity, total),
          })
        } else {
          values.push({
            key: 'otherGender',
            value: (otherGender * 100) / total,
            color: Colors.PURPLE,
            label: t('company.breakdown.otherGender'),
            tooltip: getTooltip(otherGender, total),
          })
          values.push({
            key: 'female',
            value: (female * 100) / total,
            color: Colors.PURPLE,
            label: t('company.breakdown.female'),
            tooltip: getTooltip(female, total),
          })
          values.push({
            key: 'male',
            value: (male * 100) / total,
            color: REPORTS_BLUE,
            label: t('company.breakdown.male'),
            tooltip: getTooltip(male, total),
          })
          values.push({
            key: 'unknownGender',
            value: (unknownGender * 100) / total,
            color: Colors.GREY200,
            label: t('company.breakdown.unknownGender'),
            tooltip: getTooltip(unknownGender, total),
          })
        }
        return { label: group, values } as VisualizationItemProps
      }
    )
  }
  const items: Record<string, VisualizationItemProps[]> = {
    byJobLevelAndGender: transformToGraphItem('level', 'gender'),
    byDepartmentAndGender: transformToGraphItem('department', 'gender'),
    byLocationAndGender: transformToGraphItem('country', 'gender'),
    byJobLevelAndEthnicity: transformToGraphItem('level', 'ethnicity'),
    byDepartmentAndEthnicity: transformToGraphItem('department', 'ethnicity'),
    byLocationAndEthnicity: transformToGraphItem('country', 'ethnicity'),
  }
  const tabs: Tab[] = [
    {
      key: 'byJobLevel',
      label: t('reports.byJobLevel'),
      length: breakdown?.filter(({ type }) => type === 'level').length ?? 0,
    },
    {
      key: 'byDepartment',
      label: t('reports.byDepartment'),
      length:
        breakdown?.filter(({ type }) => type === 'department').length ?? 0,
    },
    {
      key: 'byLocation',
      label: t('reports.byLocation'),
      length: breakdown?.filter(({ type }) => type === 'country').length ?? 0,
    },
  ]
  const genderItems = items[`${activeTab}AndGender`] ?? []
  const ethnicityItems = items[`${activeTab}AndEthnicity`] ?? []

  return (
    <Layout sidebar={<AssessmentSidebar />} dark>
      <PageTop>
        <div>
          <Heading>{t('breakdown.title')}</Heading>
          <Text as="div" variant="label" sx={{ mt: 4 }}>
            {t('breakdown.description')}
          </Text>
        </div>
      </PageTop>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 4,
        }}
      >
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </Flex>
      <BorderBox mb={9} sx={{ py: 5 }}>
        <Text as="div" variant="caps" mb={4}>
          {t('breakdown.byGender')}
        </Text>
        <Visualization
          type={genderItems?.length > 8 ? 'Row' : 'Bar'}
          items={genderItems}
          isLegendVisible
          isMinMajSorted
        />
      </BorderBox>

      <BorderBox mb={9} sx={{ py: 5 }}>
        <Text as="div" variant="caps" mb={4}>
          {t('breakdown.byEthnicity')}
        </Text>
        <Visualization
          type={ethnicityItems?.length > 8 ? 'Row' : 'Bar'}
          items={ethnicityItems}
          isLegendVisible
          isMinMajSorted
        />
      </BorderBox>

      <PayGapBreakdown activeTab={activeTab} />
    </Layout>
  )
}
