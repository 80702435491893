import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as FullStory from '@fullstory/browser'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'

import { config } from '@fairhq/common'
import App from 'App'
import { ErrorBoundary } from 'ErrorBoundary'
import reportWebVitals from 'reportWebVitals'
import { store } from 'store/store'
import 'styles.css'
import 'i18n'

FullStory.init({ orgId: 'ZSFFJ', devMode: config.env !== 'production' })

if (config.env !== 'local' && process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '692fa9316670031880e33a5ff850932b',
    plugins: [new BugsnagPluginReact()],
    appVersion: config.version,
    releaseStage: config.env,
    sendCode: true,
    onError: event => {
      if (window.FS && window.FS.getCurrentSessionURL) {
        event.addMetadata('fullstory', {
          urlAtTime: window.FS.getCurrentSessionURL(true),
        })
      }
    },
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        redirectUri={window.location.origin}
        audience={config.auth0.audience}
      >
        <IntercomProvider
          appId={config.intercomAppId}
          autoBoot
          autoBootProps={{
            customAttributes: {
              subdomain: 'dashboard',
              version: config.version,
              env: config.env,
            },
          }}
        >
          <App />
        </IntercomProvider>
      </Auth0Provider>
    </Provider>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
