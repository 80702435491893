import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { getAudit } from 'store/audit/auditSlice'
import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { documentApi } from './documentApi'
import { DocumentState, CompanyDocument, DocumentOptions } from './types'

const clear = createAction('document/clear')

export const getAllDocumentType = createAsyncThunk(
  'document-type/getAllDocumentType',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => documentApi.getAllDocumentType(getState),
    })
)

export const getDocuments = createAsyncThunk(
  'document/getDocuments',
  async (_, { getState }) =>
    waitForResponse({ callback: () => documentApi.getDocuments(getState) })
)

export const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (id: string, { getState }) =>
    waitForResponse({
      callback: () => documentApi.deleteDocument(getState, id),
    })
)

export const submitDocument = createAsyncThunk(
  'document/submitDocument',
  async ({ data, config, area }: DocumentOptions, { dispatch, getState }) => {
    await documentApi.submitDocument(getState, data, config)
    dispatch(getDocuments())
    if (area?.parent?.code) {
      dispatch(getStatus(area?.parent?.code))
    }
    if (area?.code) {
      dispatch(getStatus(area.code))
    }
  }
)

export const updateDocument = createAsyncThunk(
  'document/updateDocument',
  async (
    { id, data, config, area }: DocumentOptions,
    { dispatch, getState }
  ) => {
    await documentApi.updateDocument(getState, id!, data, config)
    dispatch(getDocuments())
    if (area?.parent?.code) {
      dispatch(getStatus(area?.parent?.code))
    }
    if (area?.code) {
      dispatch(getStatus(area.code))
    }
  }
)

export const removeFile = createAsyncThunk(
  'document/removeFile',
  async (
    { id, documentId }: { documentId: number; id: number },
    { dispatch, getState }
  ) => {
    const result = await waitForResponse({
      callback: () => documentApi.removeUpload(getState, documentId, id),
    })
    dispatch(getDocuments())
    return result
  }
)

export const reviewDocuments = createAsyncThunk(
  'document/reviewDocuments',
  async (
    data: { sessionId: number; areaId?: number },
    { dispatch, getState }
  ) => {
    const result = await waitForResponse({
      callback: () => documentApi.reviewDocuments(getState, data),
    })
    dispatch(getAudit())
    return result
  }
)

export const getStatus = createAsyncThunk(
  'document/getStatus',
  async (code: string, { getState }) => {
    const response = await waitForResponse({
      callback: () => documentApi.getStatus(getState, code),
    })
    return { code, status: response }
  }
)

const initialState: Partial<DocumentState> = { loading: false, statuses: {} }

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getAllDocumentType.pending, state => {
        state.error = undefined
        state.loading = true
        state.documentTypes = undefined
      })
      .addCase(getAllDocumentType.fulfilled, (state, action) => {
        state.loading = false
        state.documentTypes = action.payload
      })
      .addCase(getDocuments.pending, state => {
        state.error = undefined
        state.loading = true
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false
        state.documents = action.payload
      })
      .addCase(getStatus.pending, (state, action) => {
        state.error = undefined
        if (state.statuses) {
          state.statuses[action.meta.arg] = {
            ...state.statuses[action.meta.arg],
            loading: true,
          }
        }
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.loading = false
        const { code, status } = action.payload
        if (state.statuses) {
          state.statuses[code] = status
        }
      })
      .addMatcher(isPending('document'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isFulfilled<CompanyDocument>('document/'), state => {
        state.error = undefined
        state.loading = false
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('document'), handleErrorState)
  },
})

export const { actions: documentActions, reducer: documentReducer } =
  documentSlice
