import React from 'react'

import { useAppSelector } from 'store/hooks'

import { State } from 'store/state'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'

import { EditStrategyStep1 } from './EditStrategyStep1'
import { EditStrategyStep2 } from './EditStrategyStep2'
import { EditStrategyStep3 } from './EditStrategyStep3'

export const EditStrategy = () => {
  const { companyId } = useAppSelector((state: State) => ({
    companyId: state.apiHeadersReducer.companyId,
  }))

  const { data: strategy } = useGetStrategyQuery(undefined, {
    skip: !companyId,
  })

  if (strategy?.draftStep === 1) {
    return <EditStrategyStep1 strategy={strategy} />
  }

  if (strategy?.draftStep === 2) {
    return <EditStrategyStep2 strategy={strategy} />
  }

  if (strategy?.draftStep === 3) {
    return <EditStrategyStep3 strategy={strategy} />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
