import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Survey: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M12.6667 2.5H3.33333C3.11232 2.5 2.90036 2.5878 2.74408 2.74408C2.5878 2.90036 2.5 3.11232 2.5 3.33333V12.6667C2.5 12.8877 2.5878 13.0996 2.74408 13.2559C2.90036 13.4122 3.11232 13.5 3.33333 13.5H6C6.27614 13.5 6.5 13.7239 6.5 14C6.5 14.2761 6.27614 14.5 6 14.5H3.33333C2.8471 14.5 2.38079 14.3068 2.03697 13.963C1.69315 13.6192 1.5 13.1529 1.5 12.6667V3.33333C1.5 2.8471 1.69315 2.38079 2.03697 2.03697C2.38079 1.69315 2.8471 1.5 3.33333 1.5H12.6667C13.1529 1.5 13.6192 1.69315 13.963 2.03697C14.3068 2.38079 14.5 2.8471 14.5 3.33333V5.33333C14.5 5.60948 14.2761 5.83333 14 5.83333C13.7239 5.83333 13.5 5.60948 13.5 5.33333V3.33333C13.5 3.11232 13.4122 2.90036 13.2559 2.74408C13.0996 2.5878 12.8877 2.5 12.6667 2.5Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.60601 11.4353C4.88731 11.7166 5.26884 11.8747 5.66667 11.8747C6.06449 11.8747 6.44602 11.7166 6.72733 11.4353C7.00863 11.154 7.16667 10.7725 7.16667 10.3747C7.16667 9.97685 7.00863 9.59532 6.72733 9.31401C6.44602 9.03271 6.06449 8.87467 5.66667 8.87467C5.26884 8.87467 4.88731 9.03271 4.60601 9.31401C4.3247 9.59532 4.16667 9.97685 4.16667 10.3747C4.16667 10.7725 4.3247 11.154 4.60601 11.4353ZM5.31311 10.0211C5.40688 9.92735 5.53406 9.87467 5.66667 9.87467C5.79928 9.87467 5.92645 9.92735 6.02022 10.0211C6.11399 10.1149 6.16667 10.2421 6.16667 10.3747C6.16667 10.5073 6.11399 10.6345 6.02022 10.7282C5.92645 10.822 5.79927 10.8747 5.66667 10.8747C5.53406 10.8747 5.40688 10.822 5.31311 10.7282C5.21934 10.6345 5.16667 10.5073 5.16667 10.3747C5.16667 10.2421 5.21935 10.1149 5.31311 10.0211Z"
      fill={outline}
    />
    <path
      d="M4.16667 4.70801C4.16667 4.43187 4.39052 4.20801 4.66667 4.20801H10C10.2761 4.20801 10.5 4.43187 10.5 4.70801C10.5 4.98415 10.2761 5.20801 10 5.20801H4.66667C4.39052 5.20801 4.16667 4.98415 4.16667 4.70801Z"
      fill={outline}
    />
    <path
      d="M4.66667 6.54134C4.39052 6.54134 4.16667 6.7652 4.16667 7.04134C4.16667 7.31748 4.39052 7.54134 4.66667 7.54134H8C8.27614 7.54134 8.5 7.31748 8.5 7.04134C8.5 6.7652 8.27614 6.54134 8 6.54134H4.66667Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3946 11.5L10.396 11.5L12.6054 11.5C13.1075 11.5014 13.5885 11.7015 13.9435 12.0565C14.2985 12.4115 14.4986 12.8925 14.5 13.3946L14.5 13.396L14.5 13.6667C14.5 13.8877 14.4122 14.0996 14.2559 14.2559C14.0996 14.4122 13.8877 14.5 13.6667 14.5H9.33333C9.11232 14.5 8.90036 14.4122 8.74408 14.2559C8.5878 14.0996 8.5 13.8877 8.5 13.6667V13.3946C8.50143 12.8925 8.7015 12.4115 9.05649 12.0565C9.41148 11.7015 9.89254 11.5014 10.3946 11.5ZM10.3968 12.5C10.1592 12.5008 9.9316 12.5956 9.7636 12.7636C9.59559 12.9316 9.50084 13.1592 9.5 13.3968V13.5H13.5V13.3968C13.4992 13.1592 13.4044 12.9316 13.2364 12.7636C13.0684 12.5956 12.8408 12.5008 12.6032 12.5H10.3968Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8622 7.20279C11.507 7.13123 11.1386 7.16649 10.8035 7.30413C10.4684 7.44176 10.1815 7.6756 9.97921 7.97613C9.77688 8.27665 9.66813 8.63038 9.66668 8.99267C9.66572 9.23404 9.71242 9.47323 9.80412 9.69651C9.89583 9.91978 10.0307 10.1228 10.2011 10.2938C10.3714 10.4648 10.5738 10.6005 10.7967 10.6931C11.0196 10.7857 11.2586 10.8333 11.5 10.8333C11.8623 10.8333 12.2165 10.726 12.5178 10.5249C12.8191 10.3237 13.0541 10.0378 13.1931 9.70327C13.3321 9.3687 13.3688 9.00045 13.2986 8.64502C13.2285 8.28959 13.0546 7.96291 12.7989 7.70623C12.5433 7.44954 12.2173 7.27436 11.8622 7.20279ZM11.1834 8.22915C11.3357 8.16659 11.5032 8.15056 11.6646 8.18309C11.826 8.21562 11.9742 8.29525 12.0904 8.41192C12.2066 8.5286 12.2857 8.67709 12.3176 8.83865C12.3494 9.00021 12.3328 9.16759 12.2696 9.31967C12.2064 9.47175 12.0996 9.6017 11.9626 9.69312C11.8257 9.78454 11.6647 9.83333 11.5 9.83333C11.3903 9.83334 11.2817 9.81167 11.1803 9.76958C11.079 9.7275 10.987 9.66582 10.9096 9.58808C10.8321 9.51034 10.7708 9.41808 10.7291 9.31659C10.6875 9.2151 10.6662 9.10638 10.6667 8.99667C10.6673 8.83199 10.7168 8.67121 10.8087 8.5346C10.9007 8.398 11.0311 8.29171 11.1834 8.22915Z"
      fill={outline}
    />
  </svg>
)
