import { darken } from '@theme-ui/color'

import { makeTransition } from './makeTransition'

export const linkStyles = {
  textDecoration: 'none',
  color: 'purple',
  fontWeight: 'bold',
  transition: makeTransition('color'),
  cursor: 'pointer',
  '&:hover,&:focus': {
    textDecoration: 'none',
    color: darken('purple', 0.1),
  },
}
