import React, { FunctionComponent } from 'react'

import { Grid } from '@theme-ui/components'

import { SubNavItem } from 'features/navigation/SubNavItem'
import useSignOut from 'hooks/use-sign-out'
import { useAuthRoles } from 'hooks/useAuthRoles'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementScopesEnum } from 'store/company/types'
import RouterLink from 'ui-kit/RouterLink'

export const AccountMenu: FunctionComponent = () => {
  const signOut = useSignOut()
  const { isOwner, isAdmin } = useAuthRoles()
  const { data, isSuccess } = useGetEntitlementsQuery()

  return (
    <Grid
      gap={3}
      sx={{
        variant: 'text.body',
        position: 'absolute',
        top: '100%',
        right: 0,
        whiteSpace: 'nowrap',
        width: 150,
        mt: 2,
        mr: -1,
        px: 2,
        py: 2,
        bg: 'white',
        borderRadius: 12,
        boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
      }}
    >
      <RouterLink to="/settings/account">
        <SubNavItem label="nav.settings.account" icon="customer" />
      </RouterLink>

      <RouterLink to="/settings/team">
        <SubNavItem label="nav.settings.team" icon="team" />
      </RouterLink>

      <RouterLink to="/settings/company">
        <SubNavItem label="nav.settings.company" icon="company" />
      </RouterLink>
      {(isOwner || isAdmin) && (
        <>
          <RouterLink to="/settings/billing">
            <SubNavItem label="nav.settings.billing" icon="paymentMethod" />
          </RouterLink>
          {isSuccess && data.scopes.includes(EntitlementScopesEnum.AUDIT) && (
            <RouterLink to="/settings/assessment">
              <SubNavItem label="nav.settings.assessment" icon="assessment" />
            </RouterLink>
          )}
        </>
      )}

      <SubNavItem label="nav.signOut" icon="logOut" onClick={signOut} />
    </Grid>
  )
}
