import React, { useEffect, FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { Tabs, Tab } from 'components/Tabs'

import { ColorRule, GraphItem, Legend } from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import BorderBox from 'ui-kit/BorderBox'

interface RepresentationProps {
  tabs: Tab[]
  dataByTab: Record<string, GraphItem[]>
  title: string
  benchmark: number
  colorGetter: ColorRule[]
  legend?: Legend[]
}

export const Representation: FunctionComponent<RepresentationProps> = ({
  tabs,
  legend,
  dataByTab,
  benchmark,
  title,
  colorGetter,
}) => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = React.useState('byJobLevel')

  useEffect(() => {
    if (tabs?.length > 0) {
      setActiveTab(tabs[0]?.key)
    }
  }, [tabs])

  return (
    <BorderBox mb={9} sx={{ py: 5 }}>
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
      >
        <Text as="div" variant="bodyLargeBold">
          {title}
        </Text>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </Flex>

      <Visualization
        type={dataByTab[activeTab]?.length > 8 ? 'RowStack' : 'Stack'}
        items={dataByTab[activeTab] as any}
        benchmark={
          benchmark
            ? {
                value: benchmark,
                label: t('reports.fairHQBenchmark.title'),
              }
            : undefined
        }
        isLegendVisible={!!legend}
        colorRules={colorGetter}
        legend={legend}
      />
    </BorderBox>
  )
}
