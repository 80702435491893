import { FunctionComponent, useEffect, useState } from 'react'

import { Box, Flex, Text, Link as ThemeLink } from '@theme-ui/components'
import { useTranslation, Trans } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { ThemeUIStyleObject } from 'theme-ui'

import illustration from 'assets/illustrations/employee-survey2.svg'
import { State } from 'store/state'
import { sendSurvey } from 'store/survey/surveySlice'
import Button from 'ui-kit/Button'
import Icon from 'ui-kit/Icon'
import WhiteBox from 'ui-kit/WhiteBox'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

interface NewSurveyProps {
  sx?: ThemeUIStyleObject
}

export const NewSurvey: FunctionComponent<NewSurveyProps> = ({ sx }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [sending, setSending] = useState('')
  const { loading } = useAppSelector(
    (state: State) => ({
      loading: state.surveyReducer.loading,
    }),
    shallowEqual
  )

  const checkboxes = [
    {
      title: t('survey.surveyReadyToSend.setTheDate.title'),
      content: t('survey.surveyReadyToSend.setTheDate.description'),
    },
    {
      title: t('survey.surveyReadyToSend.reviewAndAddEmployees.title'),
      content: (
        <Trans
          i18nKey="survey.surveyReadyToSend.reviewAndAddEmployees.description"
          components={[<Link to="/employees">Employee list</Link>]}
        >
          You might have grown since the last time you sent out the survey.
          Review your <Link to="/employees">Employee list</Link>.
        </Trans>
      ),
    },
    {
      title: t('survey.surveyReadyToSend.planTheLaunch.title'),
      content: (
        <Trans
          i18nKey="survey.surveyReadyToSend.planTheLaunch.description"
          components={[
            <ThemeLink
              href="https://fairhq.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              Launch pack
            </ThemeLink>,
          ]}
        >
          Plan how to launch the survey to employees to ensure high completion
          rates. See tips in our{' '}
          <ThemeLink href="https://fairhq.co">Launch pack</ThemeLink>.
        </Trans>
      ),
    },
  ]

  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)

  const getters = [check1, check2, check3]
  const setters = [setCheck1, setCheck2, setCheck3]

  function sendToNew() {
    setSending('all')
    dispatch(sendSurvey([]))
  }

  useEffect(() => {
    if (sending && !loading) {
      setSending('')
    }
  }, [loading, sending])

  return (
    <WhiteBox sx={{ pr: 10, ...sx }}>
      <Text as="div" variant="subtitle" sx={{ mb: 5 }}>
        {t('survey.surveyReadyToSend.title')}
      </Text>
      <Text as="div" variant="labelBold" sx={{ mb: 4, color: 'grey400' }}>
        {t('survey.surveyReadyToSend.reviewChecklist')}
      </Text>

      <Flex sx={{ mb: 6, alignItems: 'center' }}>
        <Box sx={{ flexBasis: '550px' }}>
          {checkboxes.map(({ content, title }, i) => (
            <Box key={title} sx={{ pl: 6, '& + &': { mt: 3 } }}>
              <Flex
                sx={{ alignItems: 'center', ml: -6, cursor: 'pointer' }}
                onClick={() => setters[i](!getters[i])}
              >
                <Icon
                  name="addMember"
                  sx={{ fill: getters[i] ? 'green' : 'icon', mr: 2 }}
                />
                <Text
                  as="div"
                  variant="bodyLargeBold"
                  sx={{
                    color: getters[i] ? 'green' : 'grey500',
                    flex: '1 1 auto',
                  }}
                >
                  {title}
                </Text>
              </Flex>
              <Text as="div" sx={{ color: 'grey500' }}>
                {content}
              </Text>
            </Box>
          ))}
        </Box>
        <Flex
          sx={{
            flex: '1 1 auto',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: 270,
              height: 270,
              backgroundImage: `url(${illustration})`,
              backgroundSize: '100% 100%',
            }}
          />
        </Flex>
      </Flex>

      <Button
        icon="send"
        variant="primary"
        size="large"
        sx={{ mr: 6 }}
        loading={sending === 'all'}
        onClick={sendToNew}
        disabled={loading}
      >
        {t('employees.label.sendToAll')}
      </Button>
    </WhiteBox>
  )
}
