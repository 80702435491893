import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

interface ChartProps {
  score: number
  sx?: ThemeUIStyleObject
}

export const Chart: FunctionComponent<ChartProps> = ({ score, sx }) => {
  const size = 48
  const strokeWidth = 3
  const radius = size / 2 - strokeWidth / 2
  const circumference = radius * 2 * Math.PI
  const circToShow = 1 - Math.min(1, score / 10)

  if (score === 0 || score === 10) {
    return null
  }

  return (
    <Box sx={{ transform: 'rotate(-90deg)', ...sx }}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={circumference * circToShow}
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </Box>
  )
}
