import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { LinkButton, RoundButton } from 'styled'

import { ReactComponent as NextIcon } from './assets/next.svg'
import { ReactComponent as PreviousIcon } from './assets/previous.svg'

import {
  AreaFooterContainer,
  NextSection,
  PreviousSection,
} from './styled/area-footer'

interface AreaFooterProps {
  disabledNext?: boolean
  disabledPrevious?: boolean
  loadingNext?: boolean
  loadingSkipDocument?: boolean
  onNext?: () => void
  onPrevious?: () => void
  onSkip?: () => void
  onSkipDocument?: () => void
  hasNext?: boolean
  skipped?: boolean
}

export const AreaFooter: FunctionComponent<AreaFooterProps> = ({
  disabledNext,
  disabledPrevious,
  loadingNext,
  loadingSkipDocument,
  onNext,
  onPrevious,
  onSkip,
  onSkipDocument,
  hasNext,
  skipped,
}) => {
  const { t } = useTranslation()
  return (
    <AreaFooterContainer>
      <PreviousSection>
        {onPrevious ? (
          <LinkButton onClick={onPrevious} disabled={disabledPrevious}>
            <PreviousIcon />
            <span>{t('areas.label.previous')}</span>
          </LinkButton>
        ) : undefined}
        {onSkip ? (
          <LinkButton onClick={onSkip}>
            {skipped
              ? t('areas.label.skipAgain')
              : t('areas.label.skipQuestion')}
          </LinkButton>
        ) : undefined}
      </PreviousSection>
      <NextSection>
        {onSkipDocument ? (
          <RoundButton
            appearance="ghost"
            onClick={onSkipDocument}
            disabled={loadingSkipDocument}
            loading={loadingSkipDocument}
          >
            {t('areas.label.skipDocument')}
          </RoundButton>
        ) : undefined}
        {onNext ? (
          <RoundButton
            onClick={onNext}
            disabled={disabledNext || loadingNext}
            loading={loadingNext}
          >
            <span>
              {hasNext ? t('areas.label.next') : t('areas.label.finish')}
            </span>
            {hasNext ? (
              <NextIcon
                style={{
                  marginLeft: '60px',
                  marginRight: '-30px',
                  opacity: 0.5,
                }}
              />
            ) : undefined}
          </RoundButton>
        ) : undefined}
      </NextSection>
    </AreaFooterContainer>
  )
}
