import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { CompanyInsightWithoutAreas } from 'features/insights/types'

import { Insight } from './Insight'

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const List = styled.ul`
  width: 100%;
  display: flex;
  gap: 16px;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`
interface InsightsProps {
  companyInsights: CompanyInsightWithoutAreas[]
  greyedOut?: boolean
}

export const Insights: FunctionComponent<InsightsProps> = ({
  companyInsights,
  greyedOut = false,
}) => {
  const { t } = useTranslation()

  return (
    <SectionWrapper>
      <Text
        element="h2"
        type="body"
        size="lg"
        fontWeight={700}
        color={baseColors.black}
      >
        {t('strategy.focusAreas.title')}
      </Text>
      <List>
        {companyInsights.map(({ id, insight }) => (
          <Insight key={id} title={insight.description} greyedOut={greyedOut} />
        ))}
      </List>
    </SectionWrapper>
  )
}
