import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'ui-kit/Button'

export interface ScheduleButtonProps {
  onClick: () => void
}

export const ScheduleButton: FunctionComponent<ScheduleButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Button
      icon="date"
      iconPosition="left"
      sx={{ mr: 2, alignSelf: 'center' }}
      onClick={onClick}
    >
      {t('campaigns.schedule.button')}
    </Button>
  )
}
