import React, { FunctionComponent, useEffect } from 'react'

import styled from 'styled-components'

import { StrategyDueBar } from 'features/strategy/StrategyDueBar'
import { useGetInitiativesQuery } from 'store/initiatives/initiativesApi'
import {
  useCheckIsNewStrategyDueQuery,
  useUpdateStrategyMutation,
} from 'store/strategy/strategyApi'

import { Strategy } from 'store/strategy/types'
import { findInsightsAndTasks } from 'utils/findInsightsAndTasks'
import { scrollTop } from 'utils/scrollTop'

import { ActionBar } from './ActionBar'
import { EditStrategyIntro } from './EditStrategyIntro'
import { InitiativeEditable } from './InitiativeEditable'
import { Instructions } from './Instructions'

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface EditStrategyStep2Props {
  strategy: Strategy
}
export const EditStrategyStep2: FunctionComponent<EditStrategyStep2Props> = ({
  strategy,
}) => {
  const { data, isSuccess } = useGetInitiativesQuery({
    sessionId: strategy?.sessionId,
  })
  const { data: isNewStrategyDue } = useCheckIsNewStrategyDueQuery()

  const [
    updateStrategy,
    {
      isLoading: isUpdateStrategyLoading,
      isSuccess: isUpdateStrategySuccess,
      isError: isUpdateStrategyError,
    },
  ] = useUpdateStrategyMutation()

  useEffect(() => {
    if (isUpdateStrategySuccess) {
      scrollTop()
    }
  })

  return (
    <>
      {isSuccess && (
        <>
          <EditStrategyIntro companyInsights={strategy.companyInsights} />
          <Instructions
            title="strategy.draft.stepTwoTitle"
            description="strategy.draft.stepTwoDescription"
            fullWidth
          />

          <PlanWrapper>
            {data.companyInitiatives.map(companyInitiative => {
              const { relevantCompanyInsights, tasks } = findInsightsAndTasks({
                companyTasks: data.companyTasks,
                insightsCode: companyInitiative.insightsCode,
                recommendation: companyInitiative.recommendation,
                companyInsights: strategy.companyInsights,
              })
              return (
                <InitiativeEditable
                  key={companyInitiative.id}
                  companyInitiative={companyInitiative}
                  companyInsights={relevantCompanyInsights}
                  companyTasks={tasks}
                />
              )
            })}
          </PlanWrapper>

          {isNewStrategyDue ? (
            <StrategyDueBar />
          ) : (
            <ActionBar
              isLoading={isUpdateStrategyLoading}
              isError={isUpdateStrategyError}
              previousLabel="strategy.draft.stepTwoPreviousLabel"
              nextLabel="strategy.draft.stepTwoNextLabel"
              onPreviousClick={() =>
                updateStrategy({
                  id: strategy.id,
                  draftStep: 1,
                })
              }
              onNextClick={() =>
                updateStrategy({
                  id: strategy.id,
                  draftStep: 3,
                })
              }
            />
          )}
        </>
      )}
    </>
  )
}
