import { FunctionComponent } from 'react'

import { Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'
import { NotionPageWithFooterSection } from 'components/notion/NotionPageWithFooterSection'
import { TaskWithDropdown } from 'components/TaskWithDropdown/TaskWithDropdown'
import { Layout } from 'layout/Layout'

import { useGetStrategyQuery } from 'store/strategy/strategyApi'
import { TaskStatus } from 'store/tasks/types'

import { useGetRecommendationQuery } from './recommendationApi'

const ListItem = styled.li<{ $completed: boolean }>`
  display: flex;
  padding: 8px 8px 8px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: ${({ $completed }) =>
    $completed ? baseColors.coolGrey100 : baseColors.white};
  gap: 24px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 4%), 0 2px 6px rgba(0, 0, 0, 4%);
`

export const StrategyRecommendation: FunctionComponent = () => {
  const { code = '' } = useParams<{ code: string }>()
  const { t } = useTranslation()

  const { data } = useGetStrategyQuery()

  const {
    data: recommendationWithTasks,
    isLoading,
    isSuccess,
    isError,
  } = useGetRecommendationQuery({ code })

  const linkedTasks = data?.companyTasks.filter(task =>
    recommendationWithTasks?.linkedTasksCode.includes(task.code)
  )

  return (
    <Layout maxContentWidth={1080} dark>
      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}
      <Heading sx={{ mb: 6 }}>{recommendationWithTasks?.description}</Heading>
      {isSuccess && (
        <NotionPageWithFooterSection
          notionURL={recommendationWithTasks?.notionURL}
          footerTitle="Tasks"
          footer={
            linkedTasks && linkedTasks.length > 0
              ? linkedTasks.map(task => (
                  <ListItem
                    $completed={task.status === TaskStatus.COMPLETED}
                    key={task.code}
                  >
                    <TaskWithDropdown
                      task={task}
                      parentId={recommendationWithTasks.id}
                    />
                  </ListItem>
                ))
              : undefined
          }
        />
      )}
    </Layout>
  )
}
