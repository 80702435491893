import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { keys, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { Tabs } from 'components/Tabs'
import { COLOR_RULES_GAPS } from 'components/visualization/constants'
import { GraphItem } from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import BorderBox from 'ui-kit/BorderBox'
import { tCode } from 'utils/tCode'

import { useAreaDetail } from '../hooks/useAreaDetail'
import { getTabs } from '../utils/getTabs'

interface DiversityExperienceProps {
  code: string
}

export const DiversityExperience: FunctionComponent<
  DiversityExperienceProps
> = ({ code }) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: experienceReports,
    isLoading,
    isError,
    isSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'experience',
    apiVersion,
    sessionId,
  })

  const experience = experienceReports?.experience.experience
  const experienceGaps = experienceReports?.experience.experienceGaps

  const { max, queryStatus } = useAreaDetail(code)

  const subAreas = experience?.reduce(
    (
      accumulator: any,
      {
        _group,
        _type,
        score_calculation,
        score_majority_calculation,
        score_minority_calculation,
        dem_group_title,
      }: Record<string, any>
    ) => {
      if (!accumulator[_type]) {
        accumulator[_type] = {}
        if (_type === 'general') {
          accumulator[_type].score = 0
          accumulator[_type].majority = 0
          accumulator[_type].minority = 0
        }
      }
      if (_group) {
        if (!accumulator[_type][_group]) {
          accumulator[_type][_group] = {}
          accumulator[_type][_group].score = 0
          accumulator[_type][_group].title = tCode(t)(
            _type,
            '',
            dem_group_title || _group
          )
          accumulator[_type][_group].code = _group
        }
        accumulator[_type][_group].score += score_calculation
      } else if (_type === 'general') {
        accumulator[_type].score += score_calculation
        accumulator[_type].minority += score_minority_calculation
        accumulator[_type].majority += score_majority_calculation
      }

      return accumulator
    },
    {}
  )

  const subAreasGaps = experienceGaps?.reduce(
    (
      accumulator: any,
      {
        minority_code,
        minority_score,
        majority_score,
        minority_title,
      }: Record<string, any>
    ) => {
      if (!accumulator[minority_code]) {
        accumulator[minority_code] = {}
        accumulator[minority_code].minority = 0
        accumulator[minority_code].majority = 0
        accumulator[minority_code].count = 0
        accumulator[minority_code].code = minority_code
        accumulator[minority_code].title = tCode(t)(
          minority_code,
          '',
          minority_title
        )
      }
      accumulator[minority_code].minority += minority_score
      accumulator[minority_code].majority += majority_score
      accumulator[minority_code].count += 1

      return accumulator
    },
    {}
  )

  const getScoresByType = (data: any, gaps = false) => ({
    key: (data?.code ?? '') + Math.random(),
    value: Math.round(
      gaps && data
        ? ((data.minority / max - data.majority / max) * 100) /
            (data.majority / max)
        : data
        ? (data.score * 100) / max
        : 0
    ),
    label: data?.title,
  })

  const overview = [
    {
      key: 'traditionalMajority',
      value: Math.round(((subAreas?.general?.majority ?? 0) * 100) / max),
      label: t('reports.overview.traditionalMajority'),
    },
    {
      key: 'traditionalMinority',
      value: Math.round(((subAreas?.general?.minority ?? 0) * 100) / max),
      label: t('reports.overview.traditionalMinority'),
    },
  ]

  const gapBetweenMajorityAndMinority =
    subAreas?.general?.majority && max
      ? Math.round(
          (((subAreas?.general?.minority ?? 0) / max -
            subAreas.general.majority / max) *
            100) /
            (subAreas.general.majority / max)
        )
      : 0
  const items: Record<string, GraphItem[]> = {
    overview,
    byEmployeeGroups: keys(subAreas?.demGroups)
      ?.map(key => getScoresByType(subAreas?.demGroups?.[key]))
      .concat(overview),
    byJobLevel: keys(subAreas?.jobLevel)?.map(key =>
      getScoresByType(subAreas?.jobLevel?.[key])
    ),
    byDepartment: keys(subAreas?.department)?.map(key =>
      getScoresByType(subAreas?.department?.[key])
    ),
    byLocation: keys(subAreas?.location)?.map(key =>
      getScoresByType(subAreas?.location?.[key])
    ),
    byGaps: keys(subAreasGaps)
      ?.map(key => getScoresByType(subAreasGaps?.[key], true))
      .concat({
        key: 'traditionalMinority',
        value: gapBetweenMajorityAndMinority,
        label: t('reports.overview.traditionalMinority'),
      }),
  }

  const [activeTab, setActiveTab] = React.useState('overview')
  return (
    <StateHandler
      isError={queryStatus === QueryStatus.ERROR || isError}
      isSuccess={queryStatus === QueryStatus.SUCCESS && isSuccess}
      isLoading={queryStatus === QueryStatus.LOADING || isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <BorderBox mb={9} sx={{ py: 5 }}>
        <Flex
          sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
        >
          <Text as="div" variant="bodyLargeBold">
            {t(`reports.${code}.experience.title`)}
          </Text>
          <Tabs
            tabs={getTabs(t, items)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Flex>
        <Visualization
          type={
            items?.[activeTab].length < 4 || items?.[activeTab].length > 10
              ? 'Row'
              : 'Bar'
          }
          items={sortBy(items?.[activeTab] || [], ['value']) as any}
          isScoreBy10={activeTab !== 'byGaps'}
          isGaps={activeTab === 'byGaps'}
          colorRules={activeTab === 'byGaps' ? COLOR_RULES_GAPS : undefined}
        />
      </BorderBox>
    </StateHandler>
  )
}
