import { Recommendation } from 'store/strategy/types'

export interface CompanyInitiative {
  id: number
  insightsCode: string[]
  priority: number
  status: CompanyInitiativeStatusEnum
  recommendationId: number
  strategyId: number
  recommendation: Recommendation
}

export enum CompanyInitiativeStatusEnum {
  COMPLETED = 'completed',
  NOT_COMPLETED = 'notCompleted',
}
