import React from 'react'

import { ReportsAreasCore } from 'features/reportsAreasCore/ReportsAreasCore'
import { ReportsAreasDVC } from 'features/reportsAreasDVC/ReportsAreasDVC'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'

export const ReportsAreas = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  if (
    companyEntitlements?.entitlements.includes(EntitlementsEnum.REPORTS) ||
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.REPORTS_INSIGHTS
    )
  ) {
    return <ReportsAreasCore />
  }

  if (
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.DIVERSITYVC_CERTIFICATION_REPORTS
    ) ||
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.PRIVEQ_CERTIFICATION_REPORTS
    )
  ) {
    return <ReportsAreasDVC />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
