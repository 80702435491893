import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'

import { makeTransition } from '@fairhq/common'
import { useAreaProgress } from 'features/areas/hooks/useAreaProgress'
import { AreaSource } from 'store/area/types'

import Icon from 'ui-kit/Icon'
import RouterLink from 'ui-kit/RouterLink'

const SurveyCard: FunctionComponent<{
  label: string
  code: string
}> = ({ label, code }) => {
  const { completed, total } = useAreaProgress({
    area: { code },
    group: AreaSource.COMPANY,
  })

  return (
    <RouterLink to={`/assessment/${code}/questions`}>
      <Flex
        sx={{
          bg: 'white',
          borderRadius: 12,
          height: 66,
          px: 7,
          py: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'grey500',
          transition: makeTransition('color'),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Text as="div" variant="bodyBold" sx={{ mr: 4, flex: '1 1 auto' }}>
          {label}
        </Text>
        <Text
          as="div"
          variant="bodyBold"
          sx={{ color: 'grey300', flex: '0 0 auto', textAlign: 'right' }}
        >
          <Text sx={{ color: 'grey400' }}>{completed}</Text>/{total}
          {completed >= total && (
            <Icon
              name="checkboxCheckedRounded"
              sx={{ ml: 1, mt: '-1px', fill: 'green' }}
            />
          )}
        </Text>
      </Flex>
    </RouterLink>
  )
}

export default SurveyCard
