import { FunctionComponent } from 'react'

import { round } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, space, ClipboardArrow } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { RoundButton } from 'styled'

import { SurveyProgressBar } from './SurveyProgressBar'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledClipboardArrow = styled(ClipboardArrow)`
  margin-right: ${space[2]}px;
`

export const SurveyInProgress: FunctionComponent = () => {
  const { t } = useTranslation()
  const { latestSession } = useAppSelector((state: State) => ({
    latestSession: state.companyReducer.latestSession,
  }))

  const { audit } = useAuditBy(latestSession?.id, latestSession?.apiVersion)
  const surveyPercentage = round(audit?.survey?.percent ?? 0, 0)
  return (
    <>
      <LabelContainer>
        <ClipboardArrow outline={baseColors.purple} />
        <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
          {t(`statusOverview.employeeSurvey`)}
        </Text>
      </LabelContainer>
      <Text variant="title" sx={{ mb: 2, fontSize: 24, display: 'block' }}>
        <Trans
          i18nKey="statusOverview.percentageCompleted"
          values={{
            percentageCompleted: surveyPercentage,
          }}
        />
      </Text>
      <SurveyProgressBar
        surveyPercentage={surveyPercentage}
        completedSurveys={audit?.survey.completedSurveys}
        totalSurveys={audit?.survey.surveys}
      />
      <Text
        sx={{
          mb: 4,
          color: baseColors.grey400,
          fontSize: 16,
          display: 'block',
        }}
      >
        {t(`statusOverview.surveyInProgress.description`)}
      </Text>
      <ButtonContainer>
        <RoundButton componentClass={NavLink} to="/survey" appearance="ghost">
          <StyledClipboardArrow outline={baseColors.grey300} />
          {t(`statusOverview.surveyInProgress.cta`)}
        </RoundButton>
      </ButtonContainer>
    </>
  )
}
