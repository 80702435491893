import React, { FunctionComponent } from 'react'

export const DeactivateIllustration: FunctionComponent = () => (
  <svg
    width="215"
    height="120"
    viewBox="0 0 215 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M45.1223 119.543H12.3666C12.3666 119.543 11.7026 109.805 15.631 109.694C19.5593 109.584 19.1167 114.01 24.0412 107.924C28.9657 101.837 34.9412 102.169 35.7158 105.766C36.4905 109.363 34.222 112.24 38.3717 111.354C42.5214 110.469 48.4973 112.793 45.1223 119.543Z"
        fill="#E6E6E6"
      />
      <path
        d="M28.1907 119.545L28.0801 119.541C28.338 113.13 29.6806 109.011 30.7618 106.683C31.9355 104.157 33.0666 103.134 33.078 103.124L33.1516 103.207C33.1404 103.217 32.0226 104.229 30.8583 106.739C29.7831 109.055 28.4477 113.158 28.1907 119.545Z"
        fill="white"
      />
      <path
        d="M40.0457 119.559L39.9395 119.528C40.7632 116.81 42.2482 114.34 44.2613 112.337L44.3356 112.419C42.3374 114.408 40.8635 116.861 40.0457 119.559Z"
        fill="white"
      />
      <path
        d="M17.5248 119.551L17.4151 119.536C17.7723 117.132 17.3944 114.676 16.3311 112.491C15.8952 111.575 15.3182 110.732 14.6211 109.995L14.6977 109.915C15.4043 110.661 15.9892 111.514 16.4313 112.442C17.5041 114.647 17.8853 117.125 17.5248 119.551Z"
        fill="white"
      />
      <path
        d="M49.5674 119.691H8.83369L8.82437 119.553C8.80107 119.21 8.28132 111.113 10.9048 108.224C11.1651 107.919 11.4864 107.672 11.848 107.498C12.2097 107.324 12.6036 107.229 13.0046 107.216C14.7146 107.168 15.7893 107.808 16.6561 108.322C18.29 109.292 19.3691 109.932 23.2935 105.082C27.5445 99.8276 32.1314 98.697 34.7682 99.3255C36.4719 99.7318 37.6461 100.88 37.9897 102.475C38.3159 103.986 38.2115 105.406 38.1281 106.546C38.0389 107.767 37.9685 108.731 38.4699 109.168C38.8892 109.532 39.7235 109.566 41.098 109.272C43.8937 108.676 47.7955 109.186 49.7536 111.716C50.8071 113.078 51.6371 115.552 49.6084 119.609L49.5674 119.691ZM9.11162 119.395H49.3841C50.8958 116.328 50.9431 113.737 49.5194 111.898C47.723 109.576 43.9542 108.966 41.1598 109.562C39.6662 109.881 38.7765 109.828 38.2753 109.391C37.6624 108.857 37.7376 107.828 37.8327 106.526C37.9147 105.404 38.0165 104.008 37.7001 102.538C37.3814 101.059 36.2876 99.993 34.6993 99.6144C32.1466 99.0054 27.6868 100.122 23.5234 105.269C19.4385 110.318 18.2077 109.588 16.5049 108.577C15.6273 108.056 14.6312 107.466 13.0127 107.512C12.6517 107.524 12.2972 107.611 11.9719 107.768C11.6466 107.924 11.3577 108.148 11.1238 108.423C8.7666 111.019 9.05408 118.3 9.11186 119.395H9.11162Z"
        fill="#2F2E41"
      />
      <path
        d="M182.664 119.856H142.543L142.535 119.631L140.619 64.8408H184.587L182.664 119.856ZM142.993 119.39H182.214L184.105 65.3067H141.101L142.993 119.39Z"
        fill="#3F3D56"
      />
      <path
        d="M152.112 74.9924H149.061V112.756H152.112V74.9924Z"
        fill="#3F3D56"
      />
      <path
        d="M164.128 74.9924H161.076V112.756H164.128V74.9924Z"
        fill="#3F3D56"
      />
      <path
        d="M176.143 74.9924H173.092V112.756H176.143V74.9924Z"
        fill="#3F3D56"
      />
      <path
        d="M210.082 80.9298L209.885 80.8054L160.049 49.3117L164.372 42.4713L164.569 42.5957L214.404 74.0893L210.082 80.9298ZM160.692 49.1668L209.936 80.2869L213.761 74.2344L164.517 43.114L160.692 49.1668Z"
        fill="#3F3D56"
      />
      <path
        d="M198.048 46.7734C196.6 45.8567 194.918 45.3747 193.204 45.3848C191.49 45.3948 189.814 45.8965 188.377 46.8302C186.939 47.7639 185.799 49.0905 185.093 50.6525C184.386 52.2145 184.143 53.9464 184.391 55.6426L186.768 57.1443C186.171 55.7577 186.033 54.2163 186.375 52.7458C186.717 51.2754 187.521 49.9531 188.669 48.9726C189.817 47.992 191.249 47.4048 192.755 47.2969C194.261 47.189 195.762 47.566 197.038 48.3728C198.314 49.1795 199.298 50.3736 199.847 51.7803C200.395 53.1869 200.479 54.7322 200.086 56.1898C199.692 57.6475 198.843 58.9409 197.661 59.8807C196.479 60.8205 195.028 61.3573 193.519 61.4126L195.896 62.9143C197.534 62.4112 198.994 61.4485 200.103 60.1405C201.211 58.8324 201.92 57.2339 202.147 55.5346C202.374 53.8354 202.109 52.1067 201.382 50.5538C200.656 49.0008 199.5 47.6888 198.05 46.7734H198.048Z"
        fill="#3F3D56"
      />
      <path d="M211.77 119.534H0V120H211.77V119.534Z" fill="#3F3D56" />
      <path
        d="M92.4982 116.355H89.1052L87.4902 103.268H92.4991L92.4982 116.355Z"
        fill="#FEB8B8"
      />
      <path
        d="M82.5605 119.505H93.2239V115.385H86.6233C85.5458 115.385 84.5124 115.813 83.7505 116.575C82.9886 117.337 82.5605 118.37 82.5605 119.448V119.505Z"
        fill="#2F2E41"
      />
      <path
        d="M91.3047 96.0758L93.6451 98.5325L104.234 90.6751L100.78 87.0493L91.3047 96.0758Z"
        fill="#FEB8B8"
      />
      <path
        d="M96.0567 99.658L91.5023 94.8792L88.5195 97.7219L95.8772 105.442L95.9185 105.403C96.3048 105.035 96.6148 104.594 96.8308 104.106C97.0468 103.618 97.1646 103.092 97.1774 102.559C97.1902 102.026 97.0978 101.495 96.9055 100.997C96.7133 100.499 96.4248 100.044 96.0567 99.658V99.658Z"
        fill="#2F2E41"
      />
      <path
        d="M67.6889 60.222C67.4742 59.8766 67.3378 59.4882 67.2893 59.0844C67.2409 58.6805 67.2816 58.2709 67.4085 57.8845C67.5354 57.498 67.7455 57.1441 68.024 56.8476C68.3025 56.5511 68.6426 56.3192 69.0203 56.1684L82.3413 22.2183L87.5338 25.3273L72.736 57.8965C72.9474 58.5481 72.9125 59.2547 72.6378 59.8822C72.3631 60.5098 71.8677 61.0147 71.2455 61.3014C70.6234 61.5881 69.9176 61.6366 69.2621 61.4376C68.6066 61.2387 68.0468 60.8062 67.6889 60.222Z"
        fill="#FEB8B8"
      />
      <path
        d="M118.251 62.6555C117.845 62.6264 117.45 62.5088 117.095 62.3107C116.739 62.1127 116.432 61.8391 116.193 61.5092C115.955 61.1794 115.792 60.8013 115.716 60.4016C115.639 60.0019 115.652 59.5903 115.752 59.1959L96.0996 28.4743L101.666 26.098L119.333 57.2049C119.985 57.4158 120.538 57.8589 120.885 58.4502C121.232 59.0414 121.35 59.7396 121.217 60.4121C121.083 61.0847 120.707 61.6848 120.161 62.0985C119.614 62.5122 118.934 62.7108 118.251 62.6564V62.6555Z"
        fill="#FEB8B8"
      />
      <path
        d="M82.4727 56.8226L85.9511 108.522L94.264 107.788L95.9754 69.646L100.621 86.0271L110.645 86.7605L106.677 54.3776L82.4727 56.8226Z"
        fill="#2F2E41"
      />
      <path
        d="M102.087 83.5823L100.619 86.0273L90.3516 93.1175L97.6864 97.0296C97.6864 97.0296 111.867 89.2057 110.645 86.7607L102.087 83.5823Z"
        fill="#2F2E41"
      />
      <path
        d="M75.1934 35.9056L78.0871 27.7516C78.7309 25.9373 79.7281 24.2687 81.021 22.8424C82.314 21.416 83.8769 20.2603 85.6195 19.442C89.5362 17.9991 93.796 17.7659 97.8467 18.7727L98.8998 19.0341C101.708 19.7147 104.339 20.9854 106.618 22.7616C108.401 24.1673 110.005 25.9472 110.148 27.8618C110.149 27.8659 110.15 27.8698 110.151 27.8737C110.645 30.0384 110.89 41.2851 110.89 41.2851H106.533L107.222 56.4438L107.166 56.334C107.166 56.334 82.0392 61.0892 82.0392 58.6442V42.996L81.5241 37.3302L75.1934 35.9056Z"
        fill="#CCCCCC"
      />
      <path
        d="M98.6469 16.6531C102.493 16.6531 105.61 13.5355 105.61 9.68981C105.61 5.8441 102.493 2.72655 98.6469 2.72655C94.8012 2.72655 91.6836 5.8441 91.6836 9.68981C91.6836 13.5355 94.8012 16.6531 98.6469 16.6531Z"
        fill="#FEB8B8"
      />
      <path
        d="M99.6909 6.56711L105.098 6.78377C105.778 6.78167 106.521 6.75767 107.039 6.31783C107.819 5.65409 107.689 4.40164 107.271 3.46604C106.106 0.860958 103.507 -0.07232 100.654 0.00432729C97.8011 0.0809746 94.8113 1.04803 93.2749 3.45229C91.7385 5.85654 91.3233 8.89262 91.902 11.6864C92.571 10.2658 93.601 9.04553 94.889 8.1474C96.1771 7.24927 97.6781 6.70469 99.2424 6.56804L99.6909 6.56711Z"
        fill="#2F2E41"
      />
      <path
        d="M131.798 74.6775L112.228 60.9618C112.046 60.8339 111.922 60.6391 111.884 60.4201C111.845 60.2012 111.895 59.9759 112.022 59.7935L127.382 37.8773C127.51 37.6955 127.705 37.5718 127.924 37.5333C128.143 37.4948 128.368 37.5446 128.551 37.6719L148.121 51.3875C148.302 51.5155 148.426 51.7103 148.465 51.9292C148.503 52.1482 148.453 52.3735 148.326 52.5558L132.966 74.4715C132.839 74.6535 132.644 74.7774 132.425 74.816C132.206 74.8546 131.98 74.8048 131.798 74.6775Z"
        fill="#6C63FF"
      />
      <path
        d="M136.199 59.7951L124.716 51.747C124.583 51.6539 124.47 51.5356 124.383 51.3988C124.295 51.262 124.236 51.1094 124.208 50.9497C124.18 50.79 124.184 50.6263 124.219 50.468C124.254 50.3096 124.32 50.1598 124.413 50.027C124.506 49.8942 124.624 49.781 124.761 49.694C124.898 49.6069 125.051 49.5476 125.21 49.5195C125.37 49.4914 125.534 49.4951 125.692 49.5303C125.85 49.5654 126 49.6314 126.133 49.7245L137.616 57.7726C137.885 57.9606 138.067 58.2474 138.124 58.57C138.181 58.8925 138.107 59.2244 137.919 59.4926C137.731 59.7608 137.444 59.9434 137.122 60.0001C136.799 60.0568 136.467 59.9831 136.199 59.7951Z"
        fill="white"
      />
      <path
        d="M133.563 63.5554L122.08 55.5073C121.947 55.4143 121.833 55.296 121.746 55.1591C121.659 55.0223 121.6 54.8696 121.571 54.7099C121.543 54.5501 121.547 54.3863 121.582 54.2279C121.617 54.0695 121.683 53.9196 121.776 53.7867C121.869 53.6538 121.988 53.5406 122.125 53.4535C122.262 53.3664 122.414 53.3071 122.574 53.2791C122.734 53.2511 122.898 53.2548 123.056 53.2901C123.215 53.3254 123.364 53.3915 123.497 53.4848L134.981 61.5329C135.248 61.721 135.431 62.0077 135.487 62.3301C135.544 62.6525 135.47 62.9842 135.282 63.2523C135.094 63.5203 134.808 63.7028 134.485 63.7596C134.163 63.8165 133.831 63.743 133.563 63.5554Z"
        fill="white"
      />
      <path
        d="M130.898 67.3562L119.415 59.3081C119.282 59.215 119.169 59.0967 119.082 58.9599C118.995 58.8231 118.935 58.6705 118.907 58.5108C118.879 58.351 118.883 58.1873 118.918 58.029C118.953 57.8707 119.019 57.7209 119.112 57.5881C119.205 57.4553 119.324 57.3421 119.461 57.2551C119.597 57.168 119.75 57.1087 119.91 57.0806C120.069 57.0525 120.233 57.0562 120.391 57.0913C120.55 57.1265 120.7 57.1925 120.832 57.2856L132.316 65.3337C132.584 65.5216 132.766 65.8085 132.823 66.131C132.88 66.4536 132.806 66.7855 132.618 67.0537C132.43 67.3219 132.143 67.5044 131.821 67.5611C131.498 67.6179 131.166 67.5442 130.898 67.3562V67.3562Z"
        fill="white"
      />
      <path
        d="M136.166 52.3564L131.718 49.2391C131.585 49.1461 131.472 49.0278 131.385 48.891C131.298 48.7541 131.238 48.6015 131.21 48.4417C131.182 48.2819 131.186 48.1181 131.221 47.9597C131.256 47.8013 131.322 47.6514 131.415 47.5185C131.508 47.3856 131.627 47.2724 131.763 47.1853C131.9 47.0982 132.053 47.039 132.213 47.0109C132.373 46.9829 132.537 46.9866 132.695 47.0219C132.853 47.0572 133.003 47.1233 133.136 47.2166L137.584 50.3339C137.852 50.5218 138.034 50.8086 138.091 51.1312C138.148 51.4538 138.074 51.7857 137.886 52.0539C137.698 52.3221 137.411 52.5046 137.089 52.5613C136.766 52.6181 136.434 52.5443 136.166 52.3564Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="214.404" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
