import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RadioButtons } from '@fairhq/common'
import { Question } from 'store/questionTypes'

const StyledTextArea = styled.textarea`
  margin-top: 12px;
  width: 100%;
`

interface SingleChoiceProps {
  question: Question
  selectedOption: number | undefined
  setSelectedOption: (value: any) => void
  other: string | undefined
  setOther: (value: string) => void
}

export const SingleChoice: FunctionComponent<SingleChoiceProps> = ({
  question,
  selectedOption,
  setSelectedOption,
  other,
  setOther,
}) => {
  const { t } = useTranslation()
  const { options, title } = question ?? {}
  const sortedOptions = [...options].sort((a, b) =>
    a.priority > b.priority ? 1 : -1
  )

  const isOtherVisible = sortedOptions?.find(
    ({ id, isOther }) => id === selectedOption && isOther
  )

  return (
    <div>
      <RadioButtons
        items={sortedOptions.map(({ title, id }) => ({
          id: id.toString(),
          value: id.toString(),
          label: title,
        }))}
        value={selectedOption?.toString()}
        ariaLabel={title}
        onValueChange={value => {
          setSelectedOption(value)
        }}
      />
      {isOtherVisible && (
        <StyledTextArea
          onChange={e => setOther(e.target.value)}
          placeholder={t('other.writeItHere')}
          rows={5}
          value={other}
        />
      )}
    </div>
  )
}
