import { FunctionComponent, ReactNode } from 'react'

import { Root, Item, Indicator } from '@radix-ui/react-radio-group'

import styled from 'styled-components'

import { baseColors } from '../../primitives/colors'
import { Tick } from '../icons/Tick'
import { Text } from '../Text'

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: ${({ isChecked }) =>
    isChecked ? baseColors.purple200 : baseColors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 06%);
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    &:hover {
      background-color: ${({ isChecked }) =>
        isChecked ? baseColors.purple200 : baseColors.purple100};
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

const RadioButton = styled(Item)`
  background-color: ${baseColors.white};
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid ${baseColors.purple600};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;

  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
  }

  &:hover {
    background-color: ${baseColors.purple100};
  }
`

const CheckedIndicator = styled(Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${baseColors.purple600};
  border-radius: 100%;
  width: 20px;
  height: 20px;
`

const Label = styled(Text)`
  flex-grow: 1;
  cursor: pointer;
  padding: 16px 24px 16px 18px;
`

interface RadioButtonItem {
  id: string
  value: string
  label: ReactNode
}

export interface RadioButtonsProps {
  ariaLabel: string
  items: RadioButtonItem[]
  value?: string
  onValueChange: (value: string) => void
  className?: string
}

export const RadioButtons: FunctionComponent<RadioButtonsProps> = ({
  ariaLabel,
  items,
  value,
  onValueChange,
  className,
}) => (
  <Root
    aria-label={ariaLabel}
    value={value}
    onValueChange={onValueChange}
    loop
    className={className}
  >
    <ItemsContainer>
      {items.map(({ id, value: itemValue, label }) => (
        <ItemContainer key={id} isChecked={itemValue === value}>
          <RadioButton value={itemValue} id={id}>
            <CheckedIndicator>
              <Tick fill={baseColors.white} width="13" height="13" />
            </CheckedIndicator>
          </RadioButton>
          <Label
            element="label"
            type="body"
            size="sm"
            fontWeight={500}
            color={baseColors.grey500}
            htmlFor={id}
          >
            {label}
          </Label>
        </ItemContainer>
      ))}
    </ItemsContainer>
  </Root>
)
