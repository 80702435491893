import { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { SignUpLayout } from 'layout/SignUpLayout'

import { RoundButton } from '../../styled'
import { Message } from '../../styled/message'

const NotFoundContainer = styled.div`
  max-width: 480px;
  width: 100%;

  h2 {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 1.4;
    white-space: pre;
  }
`

export const NotFound: FunctionComponent<{ invalidToken?: boolean }> = ({
  invalidToken,
}) => {
  const { t } = useTranslation()

  if (invalidToken) {
    return (
      <SignUpLayout
        sources="
    https://files.fairhq.co/images/small/small_Group_877_05b09948ea.png  300w,
    https://files.fairhq.co/images/medium/medium_Group_877_05b09948ea.png  600w,
    https://files.fairhq.co/images/large/large_Group_877_05b09948ea.png  1200w,
    https://files.fairhq.co/images/large/Group_877_05b09948ea.png 2000w"
        sourcesWebp="https://files.fairhq.co/images/small/small_Group_877_05b09948ea.webp  300w,
    https://files.fairhq.co/images/medium/medium_Group_877_05b09948ea.webp  600w,
    https://files.fairhq.co/images/large/large_Group_877_05b09948ea.webp  1200w,
    https://files.fairhq.co/images/large/Group_877_05b09948ea.webp 2000w"
        picture="https://files.fairhq.co/images/origin/Group_877_05b09948ea.png"
        invalidToken
      >
        <NotFoundContainer>
          <h2>
            <Trans
              i18nKey="error.invalidToken.oopsWrongToken"
              components={[<br />]}
            />
          </h2>
          <Message>{t('error.invalidToken.sorryButWrongToken')}</Message>
          <Message>{t('error.invalidToken.orContactHR')}</Message>
        </NotFoundContainer>
      </SignUpLayout>
    )
  }

  return (
    <SignUpLayout
      sources="
    https://files.fairhq.co/images/small/small_Not_found4x_7efb4420ac.png  300w,
    https://files.fairhq.co/images/medium/medium_Not_found4x_7efb4420ac.png  600w,
    https://files.fairhq.co/images/large/large_Not_found4x_7efb4420ac.png  1200w,
    https://files.fairhq.co/images/large/Not_found4x_7efb4420ac.png 2000w"
      sourcesWebp="https://files.fairhq.co/images/small/small_Not_found4x_7efb4420ac.webp  300w,
    https://files.fairhq.co/images/medium/medium_Not_found4x_7efb4420ac.webp  600w,
    https://files.fairhq.co/images/large/large_Not_found4x_7efb4420ac.webp  1200w,
    https://files.fairhq.co/images/large/Not_found4x_7efb4420ac.webp 2000w"
      picture="https://files.fairhq.co/images/origin/Not_found4x_7efb4420ac.png"
      notFound
    >
      <NotFoundContainer>
        <h2>
          <Trans i18nKey="error.notFound.oops" components={[<br />]} />
        </h2>
        <Message>{t('error.notFound.sorryGoBackHome')}</Message>
        <RoundButton appearance="ghost" componentClass={NavLink} to="/">
          {t('error.notFound.goBackHome')}
        </RoundButton>
      </NotFoundContainer>
    </SignUpLayout>
  )
}
