import i18next, { TFunction } from 'i18next'
import { capitalize, omit, orderBy } from 'lodash'
import styled from 'styled-components'

import { Employee } from 'features/employees/types'

import { toDate } from 'utils/dates'

export const supportedHeaders = [
  'firstName',
  'lastName',
  'email',
  'level',
  'role',
  'department',
  'employmentType',
  'setup',
  'workingHours',
  'salaryYear',
  'salaryAmount',
  'salaryBonus',
  'currency',
  'city',
  'country',
  'state',
  'ethnicity',
  'gender',
]

const Tag = styled.strong`
  margin-left: 7px;
  text-transform: uppercase;
  font-size: 13px;
`

const New = styled(Tag)`
  color: ${({ theme }) => theme.colors.primary};
`

const Inactive = styled(Tag)`
  color: ${({ theme }) => theme.colors.grey300};
`

export const toEmployeeRow =
  (t: TFunction, preview = false) =>
  ({
    firstName,
    lastName,
    email,
    level,
    invited,
    activated,
  }: Partial<Employee>) => {
    const inactiveText = t('inactive')
    const newText = t('new')
    return {
      data: {
        email,
        level: capitalize(level),
        survey: invited
          ? toDate(i18next.language, invited)?.format('ll')
          : undefined,
        name: (
          <span>
            {`${firstName} ${lastName}`.trim()}{' '}
            {!activated && !preview ? (
              <Inactive>{inactiveText}</Inactive>
            ) : invited || preview ? undefined : (
              <New>{newText}</New>
            )}
          </span>
        ),
      },
    }
  }

export const parseCSVToEmployees = (
  employee: Partial<Employee & any>
): Employee =>
  ({
    ...omit(employee, [
      'employmentType',
      'level',
      'salaryAmount',
      'salaryBonus',
      'salaryYear',
      'currency',
      'workingHours',
      'department',
      'role',
    ]),
    employeesSalaries: employee.salaryAmount
      ? [
          {
            year: employee.salaryYear ? +employee.salaryYear : null,
            currency: employee.currency,
            amount: employee.salaryAmount ? +employee.salaryAmount : null,
            bonus: employee.salaryBonus ? +employee.salaryBonus : null,
          },
        ]
      : [],
    level: capitalize(employee.level.trim()),
    type: employee.employmentType.trim(),
    workingHours: employee.workingHours ? +employee.workingHours : null,
    department: employee.department.trim(),
    role: employee.department.trim(),
  } as Employee)

export const LIMIT = 50

export const sortEmployees = (employees: Employee[]) =>
  orderBy(
    employees.map(employee => ({
      ...employee,
      isInvited: !!employee.invited,
    })),
    [
      'activated',
      employee => employee.lastName.toLowerCase(),
      employee => employee.firstName.toLowerCase(),
    ],
    ['desc', 'asc', 'asc']
  )

export const removeDupes = ({
  allEmployees,
  employees = [],
}: {
  allEmployees: Employee[]
  employees?: Employee[]
}) => {
  if (allEmployees.length === 0) {
    return employees
  }

  const filtered = allEmployees.filter(
    e => !employees?.find(em => em.id === e.id)
  )
  return [...filtered, ...(employees ?? [])]
}
