import React, { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'

import { Link } from 'react-router-dom'

import { baseColors, Button } from '@fairhq/common'

import { StatusTagWithRoundIcon } from 'components/StatusTagWithRoundIcon'

import { ReportsStatusIcon } from './ReportsStatusIcon'
import { StatusType } from './useReportsStatus'

export const ReportsStatusCard: FunctionComponent<{
  status: StatusType | undefined
  type: string
}> = ({ status, type }) => {
  if (!status) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow:
          '0px 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Flex
        sx={{
          padding: '24px 32px',
          borderBottom: `1px solid ${baseColors.purple200}`,
        }}
      >
        <ReportsStatusIcon type={type} />
        <Text
          as="h3"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '26px',
          }}
        >
          {status.title}
        </Text>
      </Flex>

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'start',
          gap: '24px;',
          padding: '24px 32px',
          a: { display: 'inline-flex' },
        }}
      >
        <StatusTagWithRoundIcon percentage={status.percent} />

        <Text as="div" sx={{ fontSize: '16px', lineHeight: '24px' }}>
          {status.description}
        </Text>
        <Button
          variant={status.button?.type as any}
          as={status.button.external ? 'a' : Link}
          to={!status.button.external ? status.button.link : undefined}
          href={status.button.external ? status.button.link : undefined}
          target={status.button.external ? '_blank' : undefined}
        >
          {status.button?.label}
        </Button>
      </Flex>
    </Box>
  )
}
