import { FunctionComponent } from 'react'

import { Spinner } from '@fairhq/common'
import { useGetNotionPageQuery } from 'store/notion/notionApi'

import { getPageIdFromNotionUrl } from 'utils/getPageIdFromNotionUrl'

import { NotionEmbed } from './NotionEmbed'

interface NotionPageProps {
  notionURL: string
}

export const NotionPage: FunctionComponent<NotionPageProps> = ({
  notionURL,
}) => {
  const pageId = getPageIdFromNotionUrl(notionURL)

  const {
    data: page,
    isLoading,
    isSuccess,
  } = useGetNotionPageQuery({
    pageId,
  })

  return (
    <>
      {isLoading && <Spinner center />}

      {isSuccess && page && <NotionEmbed page={page} />}
    </>
  )
}
