import React from 'react'

import { Box, Flex, Label, Text, InputProps } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { IconName } from './Icon'
import Input from './Input'

type FieldProps = {
  label?: string
  labelSX?: ThemeUIStyleObject
  controlSX?: ThemeUIStyleObject
  error?: string
  hint?: string
  icon?: IconName
  inline?: boolean
  textValue?: boolean
} & Omit<InputProps, 'ref'>

function Field(
  {
    as: Control = Input,
    label,
    labelSX,
    controlSX,
    name,
    value,
    placeholder,
    error,
    hint,
    icon,
    inline,
    textValue,
    sx,
    ...props
  }: FieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <Box sx={sx}>
      <Flex
        sx={{
          flexDirection: inline ? 'row' : 'column',
          alignItems: inline ? 'center' : '',
        }}
      >
        {!!label && (
          <Label
            htmlFor={name}
            sx={{
              mb: inline ? 0 : 1,
              mr: inline ? 2 : 0,
              width: inline ? 'auto' : '100%',
              fontWeight: inline ? '400' : '',
              fontSize: inline ? '14px' : '',
              lineHeight: inline ? '24px' : '',
              flex: t => (inline ? `0 0 ${t.space?.[23]}px` : ''),
              ...labelSX,
            }}
          >
            {label}
          </Label>
        )}
        {textValue ? (
          <Text
            as="div"
            variant="bodyLargeBold"
            sx={{ color: 'grey500', ...controlSX }}
            ref={ref}
            {...props}
          >
            {value}
          </Text>
        ) : (
          <Control
            ref={ref}
            name={name}
            id={name}
            value={value}
            placeholder={placeholder}
            error={error}
            icon={icon}
            sx={controlSX}
            {...props}
          />
        )}
      </Flex>
      {error && (
        <Text as="div" variant="label" sx={{ mt: 1, color: 'red' }}>
          {error}
        </Text>
      )}
      {hint && (
        <Text as="div" variant="label" sx={{ mt: 2 }}>
          {hint}
        </Text>
      )}
    </Box>
  )
}

export default React.forwardRef<HTMLInputElement, FieldProps>(Field)
