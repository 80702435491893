import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Colors } from '@fairhq/common'

import { StateHandler } from 'components/StateHandler'
import { Tab } from 'components/Tabs'
import { COLOR_RULES_POC } from 'components/visualization/constants'
import { QueryStatus } from 'store/types'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'

import { useReports } from '../hooks/useReports'

import { getTabs } from '../utils/getTabs'
import { getDataByTab } from '../utils/representation/getDataByTab'
import { REPORTS_BLUE } from '../utils/representation/getDemGroupRepresentationByTab'

import { Representation } from './Representation'

export const DemGroupRepresentation: FunctionComponent = () => {
  const { t } = useTranslation()
  const { code: rawCode = '', type = '' } = useParams<{
    type: string
    code: string
  }>()
  const code = customDecodeURIComponent(rawCode)

  const {
    benchmark,
    companyBenchmark,
    jobLevel,
    minority,
    majority,
    location,
    department,
    demGroups,
    surveysInfo,
    total,
    queryStatus,
  } = useReports()
  const legend = [
    {
      color: Colors.PURPLE,
      label:
        type === 'dem_groups'
          ? t(minority?.dem_group_code ?? `${code}.minority`)
          : t('reports.overview.traditionalMinority'),
    },
    {
      color: REPORTS_BLUE,
      label:
        type === 'dem_groups'
          ? t(majority?.dem_group_code ?? `${code}.majority`)
          : t('reports.overview.traditionalMajority'),
    },
    {
      color: Colors.GREY100,
      label: t('reports.representation.notAnswered'),
    },
    {
      color: Colors.GREY300,
      label: t('reports.representation.comparison'),
    },
  ]
  const dataByTab = getDataByTab(t, {
    jobLevel,
    location,
    department,
    surveysInfo,
    demGroups,
    type,
    total,
    benchmark,
  })
  const tabs: Tab[] = getTabs(t, dataByTab)?.filter(({ key }) =>
    type === 'dem_groups'
      ? key === 'byJobLevel' || key === 'byLocation' || key === 'byDepartment'
      : (type === 'job_level' && key === 'byJobLevel') ||
        (type === 'location' && key === 'byLocation') ||
        (type === 'department' && key === 'byDepartment')
  )

  return (
    <StateHandler
      isError={queryStatus === QueryStatus.ERROR}
      isSuccess={queryStatus === QueryStatus.SUCCESS}
      isLoading={queryStatus === QueryStatus.LOADING}
      errorMessage={t('error.TryAgain')}
    >
      <Representation
        title={t(`reports.${code || type}.representation.title`)}
        tabs={tabs}
        dataByTab={dataByTab}
        benchmark={
          code === 'dem_gender'
            ? companyBenchmark?.women ?? 0
            : (code === 'dem_ethnicity' && companyBenchmark?.people_of_color) ||
              undefined
        }
        colorGetter={COLOR_RULES_POC}
        legend={legend}
      />
    </StateHandler>
  )
}
