import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const TickCircleFilled: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '20',
  height = '20',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.73001 13.3501L14.35 7.73011C14.5341 7.53696 14.5341 7.23326 14.35 7.04011L13.82 6.51012C13.6256 6.31954 13.3144 6.31954 13.12 6.51012L8.38001 11.2501L6.88001 9.76011C6.78928 9.66341 6.6626 9.60861 6.53001 9.60861C6.39742 9.60861 6.27074 9.66341 6.18001 9.76011L5.65001 10.2901C5.55535 10.384 5.50211 10.5118 5.50211 10.6451C5.50211 10.7784 5.55535 10.9062 5.65001 11.0001L8.03001 13.3501C8.12071 13.4468 8.24741 13.5016 8.38001 13.5016C8.51261 13.5016 8.63931 13.4468 8.73001 13.3501Z"
      fill={outline}
    />
  </svg>
)
