import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import styled, { css } from 'styled-components'

import { Arrow, Button, Spinner, Text, baseColors } from '@fairhq/common'
import { ArrowDirection } from '@fairhq/common/lib/components/atoms/icons/Arrow'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'

import { useGetAuditAreasQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { useLevelAndCertification } from './hooks/useLevelAndCertification'
import { SubAreaCard } from './SubAreaCard'

const TitleAndCertification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }
`

const Container = styled.main`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const LevelBox = styled(WhiteBoxWithShadow)<{ withIllustration: boolean }>`
  flex-direction: column;
  gap: 4px;
  align-self: flex-start;
  padding: 24px;
  ${({ withIllustration }) =>
    withIllustration &&
    css`
      background-color: #ebe9fb;
      background: #ebe9fb no-repeat
        url(https://files.fairhq.co/ilustrations/achievements-workflow-team-teamwork-working-together-assist-assistance-target-goal-purple400.svg);
      background-position: right -7.35px;
    `}
`

const GrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ImageWrapper = styled.div`
  display: flex;
  padding: 0 40px;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const StyledSubHeader = styled(Text)`
  text-transform: uppercase;
`

const Image = styled.img`
  display: flex;
  width: 280px;
  height: 280px;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 24px;
`

const HeaderBlock = styled.div<{ withSpaceTop: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ withSpaceTop }) =>
    withSpaceTop &&
    css`
      padding-top: 120px;
      @media (max-width: 900px) {
        padding-top: 0;
      }
    `}
`

const NoMarginText = styled(Text)`
  margin: 0;
`
export const ReportsDVC = () => {
  const { sessionId } = useAppSelector(
    (state: State) => ({
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { t } = useTranslation()

  const {
    queryStatus,
    certification,
    levelInfo,
    labelsBase,
    isReadyForCertification,
  } = useLevelAndCertification()

  const { data: areas } = useGetAuditAreasQuery({
    sessionId,
    query: {},
  })

  const relevantAreasCode = new Set(
    levelInfo?.localAreas?.map(item => item.parentAreaCode)
  )
  const relevantSubAreas = areas?.filter(area =>
    relevantAreasCode.has(area.code)
  )

  return (
    <Layout
      contentSx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
      sidebar={<ReportsModularSidebar />}
      dark
    >
      <TitleAndCertification>
        <HeaderBlock withSpaceTop={!!certification}>
          <Text fontWeight={500} size="2xl" element="h1" type="heading">
            {t(`equality.title`)}
          </Text>
          <Text fontWeight={400} size="sm" element="p" type="body">
            {t(`reports.DVC.equality.description`)}
          </Text>
        </HeaderBlock>
        {certification && (
          <ImageWrapper>
            <Image
              src={`https://files.fairhq.co/certificates/${certification.certificationYear}/${certification.level}.svg`}
              alt={`The Diversity VC Standard certification stamp, level ${levelInfo?.levelNumber}, ${certification?.certificationYear}`}
            />
          </ImageWrapper>
        )}
      </TitleAndCertification>
      {queryStatus === QueryStatus.LOADING && <Spinner center />}

      {queryStatus === QueryStatus.SUCCESS && levelInfo && (
        <Container>
          <LevelBox withIllustration={isReadyForCertification ?? false}>
            <StyledSubHeader fontWeight={700} size="xs" element="p" type="body">
              {t(`${labelsBase}.secondaryTitle`)}
            </StyledSubHeader>

            <Text fontWeight={500} size="2xl" element="h2" type="heading">
              <Trans i18nKey={`reports.level.${levelInfo.level}`} />
            </Text>
            {!isReadyForCertification &&
              levelInfo &&
              +(levelInfo.completionPercentage ?? 0) > 0 && (
                <Text
                  fontWeight={500}
                  size="lg"
                  element="p"
                  type="heading"
                  color={baseColors.purple600}
                >
                  <Trans
                    i18nKey="reports.DVClevel.wip.subTitle"
                    values={{
                      completionPercentage: levelInfo?.completionPercentage,
                    }}
                  />
                </Text>
              )}

            <NoMarginText fontWeight={400} size="sm" element="p" type="body">
              <Trans
                i18nKey={`${labelsBase}.description`}
                values={{
                  level: levelInfo?.levelNumber,
                }}
              />
            </NoMarginText>
            {isReadyForCertification && (
              <ButtonContainer>
                <Button
                  as="a"
                  href={t(`reports.getDVCCertified.link`)}
                  iconRight={<Arrow direction={ArrowDirection.RIGHT} />}
                >
                  {t(`reports.getDVCCertified.button`)}
                </Button>
              </ButtonContainer>
            )}
          </LevelBox>
          {relevantSubAreas && areas && relevantSubAreas.length > 0 && (
            <GrowColumn>
              {relevantSubAreas.map(subArea => (
                <SubAreaCard
                  key={subArea.id}
                  subArea={subArea}
                  levelInfo={levelInfo}
                  firstChildArea={areas.find(
                    area => area.parentAreaCode === subArea.code
                  )}
                />
              ))}
            </GrowColumn>
          )}
        </Container>
      )}
    </Layout>
  )
}
