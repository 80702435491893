export interface SurveyWindow {
  id: number
  companyId: number
  openedAt: Date | null
  closedAt: Date | null
  totalInvited: number
  totalCompleted: number
  status: SurveyWindowStatus
  name: string
}

export enum SurveyWindowStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  CLOSED = 'closed',
  ARCHIVE = 'archive',
}

export interface DemGroupCount {
  id: number
  surveyWindowId: number
  demGroupCode: string
  count: number
  total: number
  rate: string
  percentile: string
  createdAt: Date
  updatedAt: Date
}

export interface JobLevelsCount {
  level: JobLevelEnum
  completed: number
  invited: number
}

export enum JobLevelEnum {
  EMPLOYEE = 'employee',
  LEADERSHIP = 'leadership',
  MANAGER = 'manager',
  MANAGER_LEADERSHIP = `manager_leadership`,
}

export interface ENPSType {
  id: number
  surveyWindowId: number
  score: number
  promotersPercentage: number
  detractorsPercentage: number
  passivesPercentage: number
  percentile: number
  total: number
}

export interface DemGroupScore {
  id: number
  surveyWindowId: number
  surveyQuestionId: number
  demGroup: {
    id: number
    code: string
  }
  score: number
}

export interface ExperienceQuestion {
  id: number
  surveyWindowId: number
  surveyQuestion: SurveyQuestion
  companyScore: number
  majorityScore: number
  minorityScore: number
  minorityMajorityGap: number
}

interface SurveyQuestion {
  id: number
  airtableId: string
  code: string
  type: string
  title: string
  description: string
  areaCode: string
  priority: number
  linkedOptionsCode: string[]
  linkedQuestionsCode: string[]
  isRequired: boolean
  partCode: string
}

enum ExperienceCategoryEnum {
  RETENTION = 'retention',
  PRODUCTIVITY = 'productivity',
  INCLUSION = 'inclusion',
}
export interface ExperienceCategory {
  category: ExperienceCategoryEnum
  score: number
  minorityMajorityGap: number
}

export interface ExperienceGains {
  category: string
  subCategory: string
  gap: number
  previousGap: number
  gapDiff: number
  gapDiffPercentage: number
  score: number
  previousScore: number
  scoreDiff: number
  scoreDiffPercent: number
}
