import { LevelCompletion } from 'store/reports/types'

export const useSubAreacompletion = (
  levelInfo: LevelCompletion,
  subAreaCode: string
) => {
  if (!levelInfo.localAreas) {
    return { subAreaCompletion: 0 }
  }
  const childAreas = levelInfo.localAreas.filter(
    localArea => localArea.parentAreaCode === subAreaCode
  )

  const sumOfCompletions = childAreas.reduce(
    (a, b) => a + +b.completionPercentage,
    0
  )
  return { subAreaCompletion: sumOfCompletions / childAreas.length }
}
