import { darken, alpha } from '@theme-ui/color'
import { Theme } from 'theme-ui'

import { baseColors } from '../components/primitives/colors'
import { space } from '../components/primitives/space'

import { linkStyles } from './linkStyles'
import { makeTransition } from './makeTransition'

const breakpoints = ['768', '1180', '1360']

export const themeUI: Theme = {
  space,
  breakpoints,

  colors: {
    ...baseColors,
    primary: baseColors.purple,
    background: baseColors.white,
    text: baseColors.grey400,
    icon: baseColors.grey300,
    border: baseColors.grey200_2,
  },

  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Druk Wide Web',sans-serif",
  },
  fontWeights: {
    heading: 500,
  },

  text: {
    default: {
      fontSize: 14,
      lineHeight: '24px',
      'p + p': {
        mt: 4,
      },
    },
    heading: {
      fontSize: 24,
      lineHeight: '36px',
      color: 'grey500',
      fontFamily: 'heading',
      fontWeight: 'heading',
    },
    title: {
      fontSize: 30,
      lineHeight: '42px',
      color: 'grey500',
      fontFamily: 'heading',
      fontWeight: 'heading',
    },
    subtitle: {
      fontSize: 18,
      lineHeight: '30px',
      color: 'grey500',
      fontWeight: 'heading',
      fontFamily: 'heading',
    },
    caps: {
      textTransform: 'uppercase',
      fontSize: 13,
      lineHeight: '24px',
      fontWeight: 700,
    },
    capsLarge: {
      variant: 'text.caps',
      fontSize: 14,
    },
    bodyLarge: {
      fontSize: 16,
      lineHeight: '30px',
    },
    bodyLargeBold: {
      variant: 'text.bodyLarge',
      fontWeight: 700,
    },
    body: {
      variant: 'text.default',
    },
    bodyBold: {
      variant: 'text.default',
      fontWeight: 700,
    },
    link: {
      ...linkStyles,
    },
    capsLink: {
      variant: 'text.caps',
      ...linkStyles,
    },
    h2: {
      fontSize: 21,
      lineHeight: '36px',
      fontFamily: 'body',
      fontWeight: 700,
      color: 'grey500',
    },
    h3: {
      fontSize: 18,
      lineHeight: '30px',
      fontFamily: 'body',
      fontWeight: 700,
      color: 'grey500',
    },
    label: {
      fontSize: 13,
      lineHeight: '18px',
      fontWeight: 400,
    },
    labelBold: {
      fontSize: 13,
      lineHeight: '18px',
      fontWeight: 700,
      color: 'grey500',
    },
  },

  buttons: {
    primary: {
      position: 'relative',
      height: space[9],
      px: 6,
      minWidth: space[26],
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 700,
      color: 'white',
      bg: 'primary',
      border: 0,
      borderRadius: '9999px', // make round corners
      transition: makeTransition('opacity'),
      whiteSpace: 'nowrap',

      svg: {
        fill: 'white',
        opacity: 0.5,
      },

      '&:hover': {
        opacity: 0.9,
        color: 'white',
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      border: '1px solid',
      borderColor: 'grey200',
      bg: 'transparent',
      color: 'grey400',
      transition: makeTransition('background', 0.3),

      '&:hover': {
        bg: 'grey100',
        color: 'grey400',
      },
    },
    link: {
      variant: 'text.caps',
      height: space[9],
      bg: 'transparent',
      px: 0,
      py: 0,
      color: 'text',
    },
  },

  forms: {
    common: {
      fontSize: 14,
      lineHeight: '24px',
      color: 'grey500',
      border: '1px solid',
      borderColor: 'grey200',
      borderRadius: 8,
      height: space[9],
      display: 'inline-block',
      width: '100%',
      maxWidth: '100%',
      py: 0,
      px: `${space[3] - 1}px`,
      bg: 'white',
      transition: makeTransition('border-color'),

      '&:hover': {
        borderColor: 'grey300',
      },

      '&:focus': {
        borderColor: 'blue',
        outline: 0,
      },

      '&::placeholder': {
        color: 'grey300',
      },
      '&:disabled': {
        bg: 'grey100',
        borderColor: 'grey200',
        '&:hover': {
          borderColor: 'grey200',
        },
      },
    },
    input: {
      variant: 'forms.common',
    },
    select: {
      variant: 'forms.common',
      '& + svg': {
        ml: -6,
        fill: 'grey300',
      },
    },
    label: {
      fontSize: 13,
      lineHeight: '18px',
      fontWeight: 700,
      color: 'grey500',
    },
  },

  links: {
    default: {
      textDecoration: 'none',
      color: 'purple',
      fontWeight: 'bold',
      transition: makeTransition('color'),
      '&:hover,&:focus': {
        textDecoration: 'none',
        color: darken('purple', 0.1),
      },
    },
    dark: {
      variant: 'links.default',
      color: 'grey400',
      '&:hover,&:focus': {
        color: darken('grey400', 0.1),
      },
    },
  },

  styles: {
    a: {
      variant: 'links.default',
    },
    root: {
      bg: 'grey100',
      height: '100%',
      html: { height: '100%' },
      '.notion-viewport': { display: 'none', '& + .notion-callout': { mt: 0 } },
      '.notion': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
      },
      '.notion-callout': {
        position: 'relative',
        borderRadius: 18,
        border: 0,
        bg: 'grey100',
        pt: 5,
        pb: 5,
        pr: 8,
        pl: 4,
        margin: '24px 0',
        overflow: 'hidden',
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          width: 32,
          height: 32,
          bg: 'grey200',
          borderRadius: '0 0 0 12px',
          opacity: 0.5,
        },
        '&:after': {
          content: "''",
          position: 'absolute',
          top: '-23px',
          right: '-23px',
          width: 46,
          height: 46,
          bg: 'white',
          transform: 'rotate(45deg)',
        },
      },
      '.notion-callout + .notion-callout': {
        mt: -2,
      },
      '.notion-h': {
        fontFamily: "'Poppins', sans-serif",
      },
      '.notion-blank': {
        display: 'none',
      },
      '.notion-bookmark': {
        borderRadius: 18,
        fontWeight: 400,

        '&>div:first-of-type': {
          py: 3,
          px: 4,
          color: 'grey500',
        },
      },
      '.notion-callout + .notion-row': {
        pt: 0,
        mt: -2,
      },
      '.notion-bookmark-title': {
        fontWeight: 700,
      },
      '.notion-bookmark-link img': {
        display: 'none',
      },
      '.notion-bookmark-link > div': {
        color: 'grey300',
      },
      '.notion-to-do + .notion-to-do': {
        mt: 1,
      },
      '.notion-to-do-item .notion-property-checkbox': {
        alignSelf: 'flex-start',
        mr: 2,
        mt: '4px',
      },
      '.notion-to-do-children': {
        pl: '28px',
      },
      '.notion-link': {
        fontWeight: 400,
        color: 'purple',
        opacity: 1,

        borderColor: alpha('purple', 0.7),
        '&:hover': {
          color: darken('purple', 0.1),
          borderColor: darken('purple', 0.1),
        },
      },
      '.notion-property-checkbox-unchecked,.notion-property-checkbox-checked': {
        borderRadius: 2,
        borderColor: 'border',
      },
    },
  },
}
