import { Area } from '../area/types'

export interface DocumentOptions {
  data: FormData
  area?: Partial<Area>
  config?: any
  id?: number
}

export interface CompanyDocumentType {
  id: number
  code: string
  title: string
  description: string
  airtableId: string
  max: number
  deprecated: boolean
  area: Area
}

export interface DocumentsLink {
  id: number
  type: string
  url: string
  title: string
  createdAt: string
}

export enum DocumentStatus {
  SKIPPED = 'skipped',
  UPLOADED = 'uploaded',
  REVIEWED = 'reviewed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending',
  SUBMITTED = 'submitted',
}

export interface CompanyDocument {
  id: number
  score: number
  title: string
  status: DocumentStatus | string
  documentType: CompanyDocumentType
  companyId: number
  accountId: number
  areaId: number
  sessionId: number
  links: DocumentsLink[] | string[]
  area: Area
}

export interface CompanyDocumentStatus {
  reviewed: number
  submitted: number
  missing: number
  completed: number
  percent: number
  skipPercent: number
  total: number
  rejected: number
  loading: boolean
}

export interface DocumentState {
  error: any
  loading: boolean
  documentTypes: CompanyDocumentType[]
  documents: CompanyDocument[]
  statuses: Record<string, Partial<CompanyDocumentStatus>>
}
