import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

const StyledText = styled(Text)`
  display: block;
  width: 100%;
  text-align: center;
`

interface BellCurveProps {
  value: number
}

export const BellCurve: FunctionComponent<BellCurveProps> = ({ value }) => {
  enum CaptionEnum {
    LOW = 'Low',
    TYPICAL = 'Typical',
    LEADING = 'Leading',
  }

  const caption =
    value <= 0.15
      ? CaptionEnum.LOW
      : value >= 0.85
      ? CaptionEnum.LEADING
      : CaptionEnum.TYPICAL

  return (
    <>
      <svg
        width="122"
        height="46"
        viewBox="0 0 122 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37 14.2013V46H0C15.5 42.9128 30.5 22.2282 37 14.2013Z"
          fill={
            caption === CaptionEnum.LOW
              ? baseColors.red
              : baseColors.coolGrey300
          }
        />
        <path
          d="M38.5 12.6577V46H83.5V12.6577C81.5 8.95302 74 0 61 0C48 -2.71421e-07 40.5 8.95302 38.5 12.6577Z"
          fill={
            caption === CaptionEnum.TYPICAL
              ? baseColors.yellow
              : baseColors.coolGrey300
          }
        />
        <path
          d="M85 14.2013V46H122C106.5 42.9128 91.5 22.2282 85 14.2013Z"
          fill={
            caption === CaptionEnum.LEADING
              ? baseColors.fairGreen400
              : baseColors.coolGrey300
          }
        />
      </svg>
      <StyledText element="span" type="body" size="sm" fontWeight={500}>
        {caption}
      </StyledText>
    </>
  )
}
