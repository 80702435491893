import { flattenDeep } from 'lodash'

import { Assessment } from '../../assessment/types'

export const getSkippedAndZeroValueQuestions = (
  assessments: Assessment[] = []
) =>
  flattenDeep(
    assessments
      ?.filter(({ value, skipped }) => (!skipped && +value === 0) || !!skipped)
      .map(({ question }) => question?.code)
  )
