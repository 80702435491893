import React, { FunctionComponent } from 'react'

import { Grid, Flex, Text } from '@theme-ui/components'
import { Link } from 'react-router-dom'

import { makeTransition } from '@fairhq/common'

import Icon from 'ui-kit/Icon'

import { Document } from '../types'

import { Stars } from './Stars'

type DocumentsProps = {
  data: Document[]
}

export const Documents: FunctionComponent<DocumentsProps> = ({ data }) => (
  <Grid gap={2}>
    {data.map(({ title, score, url }) => (
      <Link key={title} to={url} className="Documents__link">
        <Flex
          sx={{
            bg: 'white',
            borderRadius: 20,
            height: 78,
            pl: 6,
            pr: 5,
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'grey500',
            transition: makeTransition('color'),
            '.Documents__link:hover &,.Documents__link:focus &': {
              color: 'purple',
            },
          }}
        >
          <Icon name="documents" sx={{ mr: 2, flex: '0 0 auto' }} />

          <Flex sx={{ flex: '1 1 auto', alignItems: 'center' }}>
            <Text as="div" variant="bodyBold">
              {title}
            </Text>
            <Icon name="arrowRight" sx={{ ml: 1 }} small />
          </Flex>

          <Flex sx={{ flex: '0 0 auto', alignItems: 'center' }}>
            <Text as="div" variant="labelBold" sx={{ color: 'grey400' }}>
              {score ? `${Math.round(score)}%` : 'Unavailable'}
            </Text>

            <Stars score={score} />
          </Flex>
        </Flex>
      </Link>
    ))}
  </Grid>
)
