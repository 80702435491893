import React, { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { round } from 'lodash'
import { ThemeUIStyleObject } from 'theme-ui'

import { IconName } from 'ui-kit/Icon'

import { ScoreChart } from './ScoreChart'

interface ScoreHeaderProps {
  title: string
  description?: string
  score?: number
  icon?: IconName
  sx?: ThemeUIStyleObject
}

export const ScoreHeader: FunctionComponent<ScoreHeaderProps> = ({
  description,
  title,
  score,
  icon,
  sx,
}) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 4,
      ...sx,
    }}
  >
    <Box>
      <Text as="div" variant="bodyLargeBold" sx={{ color: 'grey500' }}>
        {title}
      </Text>
      {description ? (
        <Text
          as="div"
          variant="label"
          sx={{
            marginTop: '6px',
            maxWidth: '760px',
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          {description}
        </Text>
      ) : undefined}
    </Box>
    {score !== undefined && <ScoreChart score={round(score, 1)} icon={icon} />}
  </Flex>
)
