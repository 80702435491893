import React, { FunctionComponent, useState } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Arrow, baseColors } from '@fairhq/common'

import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'
import { TaskStatus, Task as TaskType } from 'store/tasks/types'

import { CompletedTaskModal } from './CompletedTaskModal'
import { SkippedTaskModal } from './SkippedTaskModal'
import { StatusDropdown } from './StatusDropdown'

const StyledArrow = styled(Arrow)`
  flex-shrink: 0;
  color: ${baseColors.purple600};
`
const StyledLink = styled(Link)<{ $completed: boolean }>`
  color: ${({ $completed }) =>
    $completed ? baseColors.coolGrey700 : baseColors.purple800};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  &:focus-visible::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 8px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
`

interface TaskWithDropdownProps {
  task: TaskType
  parentId: number
}

export const TaskWithDropdown: FunctionComponent<TaskWithDropdownProps> = ({
  task,
  parentId,
}) => {
  const { permissions } = useAuthPermissions()

  const [skipTask, setSkipTask] = useState<TaskType | null>(null)
  const [completeTask, setCompleteTask] = useState<TaskType | null>(null)
  const [currentStatus, setCurrentstatus] = useState<TaskStatus>(task.status)

  const { refetch: refetchStrategy } = useGetStrategyQuery()

  const handleSkippedTaskModalClose = () => {
    setSkipTask(null)
    refetchStrategy()
  }

  const handleCompletedTaskModalClose = () => {
    setCompleteTask(null)
    refetchStrategy()
  }
  return (
    <>
      <LinkWrapper>
        <StyledLink
          $completed={task.status === TaskStatus.COMPLETED}
          to={`/strategy/task/${task.code}`}
        >
          {task.description}
        </StyledLink>
        <StyledArrow aria-hidden="true" width="16" height="16" />
      </LinkWrapper>
      <StatusDropdown
        status={currentStatus}
        parentId={parentId}
        task={task}
        setSkipTask={() => {
          setSkipTask(task)
        }}
        setCompleteTask={() => {
          setCompleteTask(task)
        }}
        setCurrentstatus={setCurrentstatus}
      />

      {skipTask && (
        <SkippedTaskModal
          task={skipTask}
          onClose={handleSkippedTaskModalClose}
        />
      )}

      {completeTask && permissions.includes(PermissionsEnum.READ_AUDIT) && (
        <CompletedTaskModal
          task={completeTask}
          onClose={handleCompletedTaskModalClose}
        />
      )}
    </>
  )
}
