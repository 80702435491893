import { AreaStatus } from 'store/audit/types'

export function getAssessmentProgress({
  status: areaStatus,
}: {
  status?: Partial<AreaStatus>
}) {
  const {
    questionsAnswered = 0,
    questionsNotAnswered = 0,
    loading,
  } = areaStatus ?? {}
  const completed = questionsAnswered
  const total = questionsAnswered + questionsNotAnswered || 0
  const skipped = completed > 0 ? questionsNotAnswered ?? 0 : 0

  return {
    loading,
    disabled: completed === total,
    total,
    skipped,
    completed,
    status: completed === total ? 'completed' : 'pending',
    reviewed: 0,
    rejected: 0,
    submitted: 0,
  }
}
