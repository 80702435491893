import { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'
import { FileType } from 'rsuite/es/Uploader'

import { FileUploader } from 'components'

import uploadIcon from './assets/upload.svg'

import { CSVPreview } from './CSVPreview'

interface UploadProps {
  next: () => void
  setEmployees: (employees: any) => void
  droppedFiles: FileType[]
  setDroppedFiles: (files: FileType[]) => void
}

export const Upload: FunctionComponent<UploadProps> = ({
  next,
  setEmployees,
  droppedFiles,
  setDroppedFiles,
}) => {
  const { t } = useTranslation()

  return droppedFiles.length > 0 ? (
    <CSVPreview
      files={droppedFiles}
      next={next}
      setEmployees={setEmployees}
      clear={() => setDroppedFiles([])}
    />
  ) : (
    <Box sx={{ maxWidth: 800, mx: 'auto', textAlign: 'center' }}>
      <Box sx={{ mb: 8 }}>
        <FileUploader
          action=""
          accept="text/csv"
          onChange={files => setDroppedFiles(files)}
          description={t('employees.addEmployees.orDragDropHere')}
          icon={uploadIcon}
          title={t('employees.addEmployees.selectCSV')}
          multiple={false}
          draggable
        />
      </Box>
      <Text as="div" variant="bodyLarge">
        <Trans
          i18nKey="survey.addEmployees.havingIssuesWithCSVNotion"
          components={[
            <a
              href={t('survey.addEmployees.howToDownloadCSVNotion.url')}
              target="_blank"
              rel="noopener noreferrer"
            >
              xxx
            </a>,
          ]}
        />
      </Text>
    </Box>
  )
}
