import React from 'react'

import { Grid } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useParams } from 'react-router-dom'

import { DocumentStatus } from 'store/document/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import DocumentCard from './DocumentCard'

export const Documents = () => {
  const { t } = useTranslation()
  const { code = '' } = useParams<{ code: string }>()
  const { documentTypes, documents } = useAppSelector(
    (state: State) => ({
      documentTypes:
        state.documentReducer?.documentTypes?.filter(({ area: areaType }) =>
          areaType?.code?.includes(code)
        ) ?? [],

      documents:
        state.documentReducer?.documents?.filter(({ area: areaType }) =>
          areaType?.code?.includes(code)
        ) ?? [],
    }),
    shallowEqual
  )

  const tDocument = tCode(t)

  const getStatus = (id: number) =>
    documents?.find(({ documentType }) => documentType.id === id)
      ?.status as DocumentStatus

  return (
    <Grid gap={1}>
      {documentTypes?.map(({ code: docCode, title, area, id }) => (
        <DocumentCard
          key={docCode + Math.random()}
          label={tDocument(docCode, 'title', title)}
          areaCode={area?.code}
          status={getStatus(id)}
        />
      ))}
    </Grid>
  )

  // eslint-disable-next-line react/jsx-no-useless-fragment
}
