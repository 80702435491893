import { FunctionComponent } from 'react'

import { Box, Flex, Grid, Text } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'

import { Data, ScoreMatrixRow } from './ScoreMatrixRow'

export interface ScoreMatrixHeader {
  key: string
  content: string
}

export interface ScoreMatrixProps {
  data: Data[]
  headers: ScoreMatrixHeader[]
}

export const ScoreMatrix: FunctionComponent<ScoreMatrixProps> = ({
  data,
  headers,
}) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ overflowX: 'auto' }}>
      <Flex sx={{ height: 48, alignItems: 'center', width: 'fit-content' }}>
        <Text
          as="div"
          variant="labelBold"
          sx={{
            flex: '0 0 240px',
            mr: 8,
            color: 'grey400',
            position: 'sticky',
            left: 0,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {t('categories.title')}
        </Text>
        <Grid
          columns={headers.length}
          gap={8}
          sx={{
            flex: '1 1 auto',
          }}
        >
          {headers.map(header => (
            <Text
              as="div"
              key={header.key}
              variant="labelBold"
              sx={{ textAlign: 'center', color: 'grey400', minWidth: '140px' }}
            >
              {header.content}
            </Text>
          ))}
        </Grid>
      </Flex>
      {data.map(row => (
        <ScoreMatrixRow key={row.category} data={row} />
      ))}
    </Box>
  )
}
