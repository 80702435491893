import { emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions } from 'store/apiHeaders/types'
import { Recommendation } from 'store/strategy/types'

const recommendationApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getRecommendation: build.query<Recommendation, { code: string }>({
      query: ({ code }) => ({
        url: `recommendations/${code}`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
    }),
  }),
})

export const { useGetRecommendationQuery } = recommendationApi
