import { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import Button from 'ui-kit/Button'

export type Tab = {
  key: string
  label: string
  length?: number
}

interface TabsProps {
  tabs: Tab[]
  activeTab: string
  setActiveTab: (tabKey: string) => void
  sx?: ThemeUIStyleObject
  tabSx?: ThemeUIStyleObject
}

export const Tabs: FunctionComponent<TabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  sx,
  tabSx,
}) => (
  <Flex sx={sx}>
    {tabs
      ?.filter(({ length }) => !length || length > 0)
      .map(({ key, label }) => (
        <Button
          variant="secondary"
          key={key}
          disabled={key === activeTab}
          onClick={() => setActiveTab(key)}
          sx={{
            minWidth: 0,
            '&:disabled': {
              borderColor: 'grey100',
              bg: 'grey100',
              color: 'purple',
              opacity: '100%',
            },
            '& + &': {
              ml: 2,
            },
            ...tabSx,
          }}
          square
          size="small"
        >
          {label}
        </Button>
      ))}
  </Flex>
)
