import React, { FunctionComponent, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Flex } from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { Text, baseColors } from '@fairhq/common'
import Avatar from 'components/Avatar'
import useSchema from 'hooks/use-yup-schema'
import { useGetAuthRolesQuery } from 'store/account/accountApiWithQuery'
import { addUser } from 'store/account/accountSlice'
import { State } from 'store/state'
import { Message } from 'styled/message'
import Button from 'ui-kit/Button'
import Field from 'ui-kit/Field'
import Icon from 'ui-kit/Icon'

import { Select } from 'ui-kit/Select'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

type AddUserProps = {
  onClose: () => void
}

type FormData = {
  email: string
  authRole: string
}

export const AddUser: FunctionComponent<AddUserProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const [sending, setSending] = useState(false)
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(
    (state: State) => ({
      loading: state.accountReducer.loading,
    }),
    shallowEqual
  )
  const schema = useSchema(['email', 'authRole'])

  const { data: authRoles, isSuccess, isError } = useGetAuthRolesQuery()

  const { watch, register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })
  const { isDirty, errors } = formState

  const authRoleFieldValue = watch('authRole')
  const defaultRole = authRoles?.find(item => item.name === 'Viewer')?.id
  const authRoleSelected = authRoles?.find(
    role => role.id === (authRoleFieldValue || defaultRole)
  )

  const onSubmit = ({ email, authRole }: FormData) => {
    if (loading) {
      return
    }

    setSending(true)
    dispatch(
      addUser({
        user: { email, user_metadata: { invited: true } },
        authRole,
      })
    )
  }

  useEffect(() => {
    if (sending && !loading) {
      setSending(false)
      onClose()
    }
  }, [loading, sending, onClose])

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        justifyContent: 'space-between',
        py: 3,
        px: 4,
        bg: 'grey100',
        borderRadius: 10,
      }}
    >
      <Avatar
        // @ts-ignore
        sx={{ mr: 4 }}
      />
      {isError && (
        <Message type="error">{t(`setup.error.description`)}</Message>
      )}
      {isSuccess && (
        <Flex
          sx={{
            flex: '1 1 auto',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Flex
            sx={{
              gap: 3,
              width: '100%',
            }}
          >
            <Field
              {...register('email')}
              type="email"
              placeholder="Email"
              sx={{
                flexGrow: 2,
                display: 'inline-block',
              }}
              error={errors.email?.message}
              autoFocus
            />
            <Field
              as={Select}
              placeholder="role"
              error={errors.authRole?.message}
              {...register('authRole')}
              sx={{ minWidth: 150 }}
              defaultValue={defaultRole}
            >
              {authRoles?.map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Field>
          </Flex>
          {authRoleFieldValue && (
            <Text
              element="p"
              type="body"
              size="2xs"
              fontWeight={400}
              color={baseColors.coolGrey700}
            >
              {`${authRoleSelected?.name}: ${authRoleSelected?.description}`}
            </Text>
          )}
          <Button disabled={!isDirty} loading={sending} type="submit">
            Invite
          </Button>
        </Flex>
      )}

      <Box
        onClick={() => {
          onClose?.()
        }}
        sx={{ cursor: 'pointer', ml: 5 }}
      >
        <Icon name="closeSmall" small sx={{ display: 'block' }} />
      </Box>
    </Flex>
  )
}
