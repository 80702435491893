import React, { FunctionComponent } from 'react'

import { SurveyWindow } from 'store/surveyWindow/types'

import { PromptCard } from './PromptCard'

interface SurveyWindowClosedProps {
  currentSurveyWindow: SurveyWindow
}

export const SurveyWindowClosed: FunctionComponent<SurveyWindowClosedProps> = ({
  currentSurveyWindow,
}) => (
  <PromptCard
    title="surveys.closed.title"
    progress={{
      max: currentSurveyWindow.totalInvited,
      value: currentSurveyWindow.totalCompleted,
    }}
    description="surveys.closed.description"
  />
)
