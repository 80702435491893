import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'rsuite'

import { LinkButton } from '../../styled'

import { ReactComponent as SearchIcon } from './assets/search.svg'
import { DataListHeaderContainer, Count } from './styled'

import { ActionMenu } from './index'

interface DataListHeaderProps {
  action?: ActionMenu
  onSearch?: (searchTerm: string) => void
  title?: string
  total?: number
}

const DataListHeader: FunctionComponent<DataListHeaderProps> = ({
  action,
  onSearch,
  title,
  total,
}) => {
  const { t } = useTranslation()
  return (
    <DataListHeaderContainer>
      <h3>
        {title} <Count>({total})</Count>
      </h3>
      <InputGroup inside>
        <InputGroup.Addon>
          <SearchIcon />
        </InputGroup.Addon>
        <Input
          type="search"
          placeholder={t('form.search.placeholder')}
          onChange={onSearch}
        />
      </InputGroup>
      <LinkButton onClick={action?.onClick}>
        {action?.icon}
        {action?.label}
      </LinkButton>
    </DataListHeaderContainer>
  )
}

export default DataListHeader
