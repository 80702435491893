import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'

import { Session } from 'store/company/types'
import { State } from 'store/state'
import { getCompletion } from 'store/survey/surveySlice'

import { GraphItem } from '../components/visualization/types'
import { useAppDispatch, useAppSelector } from '../store/hooks'

export interface SurveyCompletion {
  created: string
  total: number
  completed: number
  max: number
  surveyFinished: boolean
  byJobLevel: GraphItem[]
  byDepartment: GraphItem[]
  byLocation: GraphItem[]
  isLatest: boolean
  hasChangelog: boolean
  iscompletionLoading: boolean
}

export const useCompletion = (
  session?: Session,
  withCompletionLoading = false
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { completion, completionLoaded, loading } = useAppSelector(
    (state: State) => ({
      loading: state.surveyReducer.loading,
      completionLoaded: state.surveyReducer.completionLoaded,
      completion:
        (session && state.surveyReducer.completion?.[session?.id]) ??
        ({} as any),
    }),
    shallowEqual
  )
  const { data: sessions, isLoading } = useGetSessionsQuery()
  const isLatest = session?.id === sessions?.[0]?.id
  const allSessions = sessions?.filter(
    ({ scorable, id }) => scorable && id !== session?.id
  )
  const isFirst = sessions?.[sessions.length - 1]?.id === session?.id

  useEffect(() => {
    if (withCompletionLoading && !completionLoaded && !loading) {
      dispatch(getCompletion())
    }
  }, [dispatch, completionLoaded, loading, withCompletionLoading])

  const rowToItem = ({ _group, completed, total }: any) => ({
    label: t(_group),
    value: Math.round((completed * 100) / total),
  })

  return {
    isLatest,
    hasChangelog:
      session?.scorable && !isFirst && (allSessions || []).length >= 1,
    created: session?.start_date,
    total: Math.round(completion?.status?.percent ?? 0),
    completed: completion?.status?.completed ?? 0,
    max: completion?.status?.total,
    surveyFinished: session?.scorable ?? false,
    byJobLevel: completion?.completion
      ?.filter(
        ({ _type, _group, completed }: any) =>
          _type === 'jobLevel' && _group && completed >= 5
      )
      .map(rowToItem),
    byDepartment: completion?.completion
      ?.filter(
        ({ _type, _group, completed }: any) =>
          _type === 'department' && _group && completed >= 5
      )
      .map(rowToItem),
    byLocation:
      completion?.completion
        ?.filter(
          ({ _type, _group, completed }: any) =>
            _type === 'location' && _group && completed >= 5
        )
        .map(rowToItem) ?? [],
    iscompletionLoading: isLoading,
  } as SurveyCompletion
}
