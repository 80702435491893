import { useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'

import { Area, AreaSource } from 'store/area/types'
import { getStatus as getAuditStatus } from 'store/audit/auditSlice'
import { getStatus as getDocumentStatus } from 'store/document/documentSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { getAssessmentProgress, getDocumentProgress } from '../utils'

export const useAreaProgress = ({
  area,
  group,
}: {
  group?: AreaSource
  area?: Partial<Area>
}): Record<string, any> => {
  const dispatch = useAppDispatch()
  const [documentMounted, setDocumentMounted] = useState(false)
  const { status, documentStatus } = useAppSelector(
    (state: State) => ({
      status: state.auditReducer.areas?.[area?.code ?? ''],
      documentStatus: state.documentReducer.statuses?.[area?.code ?? ''] ?? {},
    }),
    shallowEqual
  )

  useEffect(() => {
    if (
      area?.code &&
      (group === AreaSource.COMPANY || !group) &&
      !status?.loading &&
      status?.questions === undefined
    ) {
      dispatch(getAuditStatus(area.code))
    }
  }, [area, dispatch, group, status])

  useEffect(() => {
    if (
      area?.code &&
      (group === AreaSource.DOCUMENTS || !group) &&
      !documentStatus?.loading &&
      documentStatus?.total === undefined &&
      !documentMounted
    ) {
      setDocumentMounted(true)
      dispatch(getDocumentStatus(area.code))
    }
  }, [area, dispatch, group, documentStatus, documentMounted])

  if (group === 'documents' && area) {
    return getDocumentProgress({ status: documentStatus })
  }

  if (group === 'company' && area) {
    return getAssessmentProgress({ status })
  }

  if (!group && area) {
    const document = getDocumentProgress({ status: documentStatus })
    const assessment = getAssessmentProgress({ status })
    const completed = (document?.completed ?? 0) + (assessment?.completed ?? 0)
    const total = (document?.total ?? 0) + (assessment?.total ?? 0)

    return { completed, total }
  }
  return {
    disabled: false,
    total: 0,
    skipped: 0,
    completed: 0,
    reviewed: 0,
    rejected: 0,
    submitted: 0,
  }
}
