import { emptySplitApi, TagTypes } from 'emptySplitApi'
import { ApiVersionOptions } from 'store/apiHeaders/types'

import { Strategy, ArchivedStrategy, UpdateStrategy } from './types'

const strategyApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getStrategy: build.query<Strategy, void>({
      query: () => ({
        url: `strategy`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      providesTags: [TagTypes.STRATEGY],
    }),
    checkIsNewStrategyDue: build.query<boolean, void>({
      query: () => ({
        url: 'strategy/due',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      providesTags: [TagTypes.STRATEGY_DUE],
    }),
    generateNewStrategy: build.mutation<Strategy, void>({
      query: () => ({
        url: 'strategy',
        method: 'POST',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      invalidatesTags: [TagTypes.STRATEGY, TagTypes.STRATEGY_DUE],
    }),
    updateStrategy: build.mutation<Strategy, UpdateStrategy>({
      query: ({ draftStep, committedAt, id, regenerateInitiatives }) => ({
        url: `strategy/${id}`,
        method: 'PATCH',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
        body: { draftStep, committedAt, regenerateInitiatives },
      }),
      invalidatesTags: [TagTypes.STRATEGY, TagTypes.INITIATIVES],
    }),
    getArchivedStrategies: build.query<ArchivedStrategy[], void>({
      query: () => ({
        url: `strategy?archived=true`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      providesTags: [TagTypes.STRATEGY],
    }),
  }),
})

export const {
  useGetStrategyQuery,
  useCheckIsNewStrategyDueQuery,
  useGenerateNewStrategyMutation,
  useGetArchivedStrategiesQuery,
  useUpdateStrategyMutation,
} = strategyApi
