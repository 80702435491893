import { FunctionComponent } from 'react'

import { Spinner } from '@fairhq/common'
import { EditStrategy } from 'features/editStrategy/EditStrategy'
import { State } from 'store/state'

import { useGetStrategyQuery } from 'store/strategy/strategyApi'

import { useAppSelector } from '../../store/hooks'

import { CommittedStrategy } from './CommittedStrategy'
import { HoldingPage } from './HoldingPage'

export const AutomatedStrategy: FunctionComponent = () => {
  const { companyId } = useAppSelector((state: State) => ({
    companyId: state.apiHeadersReducer.companyId,
  }))

  const {
    data: strategy,
    isLoading,
    isSuccess,
  } = useGetStrategyQuery(undefined, {
    skip: !companyId,
  })

  if (isLoading) {
    return <Spinner />
  }

  if (isSuccess && strategy) {
    if (strategy.committedAt) {
      return <CommittedStrategy />
    }
    if (strategy.draftStep) {
      return <EditStrategy />
    }
  }

  return <HoldingPage />
}
