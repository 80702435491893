import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Calendar: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M7.5 10a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2h-9Z" fill={outline} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 2a1 1 0 0 1 1 1v.5h7V3a1 1 0 1 1 2 0v.5h.5a4 4 0 0 1 4 4v8.257a4 4 0 0 1-1.172 2.829l-2.242 2.242A4 4 0 0 1 15.757 22H6a4 4 0 0 1-4-4V7.5a4 4 0 0 1 4-4h.5V3a1 1 0 0 1 1-1Zm11.914 4.086A2 2 0 0 1 20 7.5v7h-2.5a3 3 0 0 0-3 3V20H6a2 2 0 0 1-2-2V7.5a2 2 0 0 1 2-2h.5V6a1 1 0 0 0 2 0v-.5h7V6a1 1 0 1 0 2 0v-.5h.5a2 2 0 0 1 1.414.586ZM16.5 17.5v2.357a2 2 0 0 0 .672-.443l2.242-2.242a2 2 0 0 0 .443-.672H17.5a1 1 0 0 0-1 1Z"
      fill={outline}
    />
  </svg>
)
