import React, { useState, useEffect, FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useLocation, Link } from 'react-router-dom'

import styled from 'styled-components'

import { makeTransition } from '@fairhq/common'

import Icon from 'ui-kit/Icon'

import { DisabledMenuIcon } from './DisabledMenuIcon'
import { ReportsSidebarMenuGroup } from './hooks/useReportsSidebarGenAndSub'

import { SubMenuItem } from './SubMenuItem'

const MenuLink = styled(Link)<{ $enabled: boolean }>`
  cursor: ${({ $enabled }) => ($enabled ? 'pointer' : 'default')};
`

export const MenuGroup: FunctionComponent<ReportsSidebarMenuGroup> = ({
  baseUrl,
  text,
  url,
  items,
  enabled,
}) => {
  const location = useLocation()
  const [opened, setOpened] = useState(
    url ? location.pathname.startsWith(baseUrl || url) : false
  )

  const hasChildren = items !== undefined && items.length > 0

  useEffect(() => {
    if (url && location.pathname.startsWith(baseUrl || url) && !opened) {
      setOpened(true)
    }
  }, [location, url, opened, baseUrl])

  const active = url ? location.pathname.startsWith(url) : false
  return (
    <>
      <MenuLink to={enabled ? url : '#'} $enabled={!!enabled}>
        <Flex
          sx={{
            pointerEvents: enabled ? undefined : 'none',
            position: 'relative',
            pl: 7,
            height: 54,
            alignItems: 'center',
            color: active ? 'purple' : 'grey500',
            transition: makeTransition('color'),
            bg: active ? 'grey100' : 'white',
            '&:hover': { color: 'purple' },
            '&:before': {
              content: "''",
              position: 'absolute',
              top: 0,
              left: 0,
              width: 3,
              height: '100%',
              bg: 'purple',
              display: active ? 'block' : 'none',
            },
          }}
        >
          <Flex>
            {enabled ? undefined : <DisabledMenuIcon />}
            <Text as="div" sx={{ fontWeight: 700 }}>
              {text}
            </Text>
          </Flex>
          {hasChildren && enabled && (
            <Flex
              sx={{
                ml: 'auto',
                width: 54,
                height: 54,
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
              }}
              onClick={e => {
                e.preventDefault()
                setOpened(!opened)
              }}
            >
              <Icon name={opened ? 'arrowUp' : 'arrowDown'} small />
            </Flex>
          )}
        </Flex>
      </MenuLink>

      {opened && hasChildren && (
        <Box sx={{ my: 1 }}>
          {items &&
            items.map(itemProps => (
              <SubMenuItem key={itemProps.text} {...itemProps} />
            ))}
        </Box>
      )}
    </>
  )
}
