import { TFunction } from 'i18next'

export const tCode =
  (t: TFunction) =>
  // eslint-disable-next-line default-param-last
  (code = '', suffix = '', defaultValue?: string, restOfOptions?: any) =>
    t(suffix ? `${code}.${suffix}` : code, {
      ...restOfOptions,
      defaultValue,
    })
