import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import {
  iconMap,
  textMap,
  OldRecommendationTypeStatus,
} from 'store/oldRecommendations/types'
import Icon from 'ui-kit/Icon'

interface OldRecommendationAreaProps {
  data: OldRecommendationTypeStatus | string
  sx?: ThemeUIStyleObject
}

export const OldRecommendationArea: FunctionComponent<
  OldRecommendationAreaProps
> = ({ data, sx }) => {
  const { t } = useTranslation()

  return (
    <Flex sx={{ alignItems: 'center', flex: '0 0 auto', ...sx }}>
      <Icon name={iconMap[data]} sx={{ mr: 1, flex: '0 0 auto' }} />
      <Text as="div" sx={{ color: 'grey400', fontWeight: 400 }}>
        {t(textMap[data])}
      </Text>
    </Flex>
  )
}
