import { shallowEqual } from 'react-redux'

import { useLocation } from 'react-router-dom'

import { useAuthRoles } from 'hooks/useAuthRoles'

import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export const useRedirectToBilling = () => {
  const { isAdmin } = useAuthRoles()
  const { isActiveSubscription, hidden } = useAppSelector(
    (state: State) => ({
      hidden: state.authReducer.user?.user_metadata?.onboarding?.hidden,
      isActiveSubscription: state.customerReducer?.isActiveSubscription,
    }),
    shallowEqual
  )
  const { pathname } = useLocation()
  const billingLink = '/settings/billing'

  if (
    !isAdmin &&
    isActiveSubscription === false &&
    !pathname.includes(billingLink) &&
    hidden
  ) {
    return billingLink
  }

  return ''
}
