import { chain, reverse } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import { tCode } from 'utils/tCode'

import { useScoreArea } from '../../reportsCore/hooks/useScoreArea'

const getQuality = (percentage: number) => {
  if (percentage > 75) {
    return 'great'
  }
  if (percentage > 50) {
    return 'okay'
  }
  return 'low'
}

const gentleJoin = (items: string[]) => {
  if (items.length === 0) {
    return ''
  }
  if (items.length === 1) {
    return items[0]
  }
  if (items.length === 2) {
    return items.join(
      items.some(item => item.includes(' and ')) ? ', and ' : ' and '
    )
  }

  return [
    items.slice(0, items.length - 1).join(', '),
    items[items.length - 1],
  ].join(items.some(item => item.includes(' and ')) ? ', and ' : ' and ')
}

export const useAreaDetail = (area: string) => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { companyName, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      companyName: state.companyReducer.company?.name,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const [scoreData] = useScoreArea(area)
  const {
    data: latestScores,
    isLoading: isLatestScoresLoading,
    isError: isLatestScoresError,
  } = useGetLatestScoresQuery({ apiVersion, sessionId })

  const {
    data: areaReport,
    isLoading: isAreaReportLoading,
    isError: isAreaReportError,
  } = useGetReportByAreaQuery({
    areaCode: area,
    projection: 'general',
    apiVersion,
    sessionId,
  })

  const { Blurb, Headline } =
    (areaReport && chain(areaReport?.copies).keyBy('type').value()) || {}
  const { highestScoringArea, lowestScoringArea } = areaReport ?? ({} as any)

  const worstSubAreas = reverse(scoreData?.scores || [])?.map(
    ({ area_code, area_title }: any) =>
      `<strong>${tArea(area_code, 'title', area_title)}</strong>`
  )

  const values = {
    one: gentleJoin(worstSubAreas.slice(0, 1)),
    two: gentleJoin(worstSubAreas.slice(0, 2)),
    three: gentleJoin(worstSubAreas.slice(0, 3)),
    companyName,
    highestScoringArea: tArea(
      highestScoringArea?.code,
      'title',
      highestScoringArea?.title
    ),
    lowestScoringArea: tArea(
      lowestScoringArea?.code,
      'title',
      lowestScoringArea?.title
    ),
  }

  const scoreArea = latestScores?.areasScoring?.[area]
  const value = Math.round(areaReport?.scoring?.score ?? 0)
  const max = areaReport?.scoring?.max ?? 0
  const percentage = (areaReport?.scoring?.score10 ?? 0) * 10
  const quality = getQuality(percentage)
  const header = tCode(t)(Headline?.code, '', Headline?.description)
  const blurb = (
    <Trans i18nKey={Blurb?.code} values={values}>
      {tCode(t)(Blurb?.code, '', Blurb?.description, values)}
    </Trans>
  )

  const isError = isLatestScoresError || isAreaReportError
  const isLoading = isLatestScoresLoading || isAreaReportLoading
  let queryStatus = QueryStatus.SUCCESS
  if (isError) {
    queryStatus = QueryStatus.ERROR
  }
  if (isLoading) {
    queryStatus = QueryStatus.LOADING
  }

  return {
    scoreArea,
    value,
    max,
    percentage,
    quality,
    header,
    blurb,
    queryStatus,
  }
}
