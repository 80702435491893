import { FunctionComponent } from 'react'

import { Button, Container } from 'rsuite'
import styled from 'styled-components'

import { Progress } from 'components'
import { ReactComponent as RejectedIcon } from 'components/card/assets/card-rejected.svg'
import { Ellipsis, ProgressBar } from 'styled'
import { Message } from 'styled/message'

import { ReactComponent as CloseIcon } from '../assets/close.svg'
import { ReactComponent as FileIcon } from '../assets/document.svg'
import { ReactComponent as LinkIcon } from '../assets/link.svg'
import { ReactComponent as SuccessIcon } from '../assets/success.svg'

const DocumentCardOverlay = styled.div<{ progress?: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ progress }) => `${progress || 0}%`};
  background: ${({ theme, progress }) =>
    progress === 100 ? 'inherit' : theme.colors.grey100};
  z-index: 8;
`

const DocumentCardContainerBody = styled(Container)`
  flex-flow: row;
  padding: 0 30px;
  height: 120px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  align-items: center;
  border-radius: 10px;

  ${Ellipsis} {
    flex: 1;
    margin-left: 12px;
    margin-right: 24px;
  }

  p {
    margin-bottom: 0;
  }

  .rs-btn {
    margin-left: 24px;
  }
`

const DocumentCardContainer = styled(Container)<{ progress?: number }>`
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  ${ProgressBar} {
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

interface DocumentCardProps {
  name: string
  url?: string
  isLink?: boolean
  date?: string
  onDelete?: () => void
  progress?: number
  hasError?: boolean
  rejected?: boolean
}

export const DocumentCard: FunctionComponent<DocumentCardProps> = ({
  isLink,
  date,
  name,
  onDelete,
  progress,
  hasError = false,
  rejected,
  url,
}) => (
  <DocumentCardContainer>
    <DocumentCardOverlay progress={progress} />
    {progress !== undefined ? (
      <Progress progress={progress} hasError={hasError} />
    ) : undefined}
    <DocumentCardContainerBody>
      {isLink ? <LinkIcon /> : <FileIcon />}
      <Ellipsis as="a" title={name} href={url} target="_blank">
        <strong>{name}</strong>
      </Ellipsis>
      {date ? <Message type="indication">{date}</Message> : undefined}
      {progress === 100 && !hasError ? <SuccessIcon /> : undefined}
      {rejected ? <RejectedIcon style={{ marginLeft: '10px' }} /> : undefined}
      <Button type="button" appearance="link" onClick={onDelete}>
        <CloseIcon />
      </Button>
    </DocumentCardContainerBody>
  </DocumentCardContainer>
)
