import React, { FunctionComponent } from 'react'

interface SendProps {
  className?: string
  fill?: string
}

export const Send: FunctionComponent<SendProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5759 3.14769L20.8356 3.40757C20.9794 3.53877 21.0339 3.74163 20.9754 3.92735L14.9437 20.6701C14.8722 20.868 14.6845 20.9999 14.4743 21H14.3145C14.1184 21 13.9398 20.8872 13.8552 20.7101L10.4698 13.5232L3.28969 10.1347C3.11276 10.05 3.00013 9.87117 3.00009 9.67488V9.51495C2.9824 9.29005 3.11743 9.08117 3.32963 9.00516L20.0566 3.00775C20.1128 2.99742 20.1703 2.99742 20.2264 3.00775C20.3567 3.00696 20.4821 3.05718 20.5759 3.14769ZM12.9864 14.123L14.2147 16.7818L18.1293 5.85652L7.21429 9.77483L9.87064 11.0043C10.6098 11.388 11.5091 11.2725 12.1275 10.7144L13.8152 9.1551C14.0081 8.97105 14.3114 8.97105 14.5043 9.1551L14.8837 9.53494C15.0676 9.728 15.0676 10.0316 14.8837 10.2246L13.3259 11.9139C12.7736 12.5123 12.6393 13.3861 12.9864 14.123Z"
      fill={fill}
    />
  </svg>
)
