import { useState, useEffect, FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDebounce } from 'react-use'

import styled from 'styled-components'

import { baseColors, Button, Text } from '@fairhq/common'
import { Close } from 'components/svgs/icons/Close'
import { RoundTick } from 'components/svgs/icons/RoundTick'
import { Employee } from 'features/employees/types'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementScopesEnum } from 'store/company/types'
import { count as countEmployees } from 'store/employee/employeeSlice'
import { State } from 'store/state'
import { count as countSurvey, sendSurvey } from 'store/survey/surveySlice'

import Input from 'ui-kit/Input'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { AssessmentSidebar } from '../assessments/AssessmentSidebar'

import { AddEmployees } from './AddEmployees'
import { EmployeesList } from './EmployeesList'
import { ConfirmSendAllModal } from './modals/ConfirmSendAllModal'
import { ConfirmSendSelectedModal } from './modals/ConfirmSendSelectedModal'
import { SurveyButtons } from './SurveyButtons'

const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 24px;
  gap: 16px;
  flex-wrap: wrap;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 8px;
`

const SuccessContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
`

const CloseButton = styled.button`
  background-color: inherit;
  display: flex;
  align-items: center;
  padding: 0;
`

export const Employees: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [showConfirmSendSelected, setShowConfirmSendSelected] = useState(false)
  const [showConfirmSendAll, setShowConfirmSendAll] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([])
  const [debouncedSearch, setDebouncedSearch] = useState(searchTerm)
  const [showSentSuccessMessage, setShowSentSuccessMessage] = useState(false)
  const { totalEmployees, isSurveyError, isSurveyLoading } = useAppSelector(
    (state: State) => ({
      totalEmployees: state.employeeReducer.count,
      isSurveyError: state.surveyReducer.error,
      isSurveyLoading: state.surveyReducer.loading,
    }),
    shallowEqual
  )

  const { data: companyEntitlements } = useGetEntitlementsQuery()

  useDebounce(
    () => {
      setDebouncedSearch(searchTerm)
    },
    300,
    [searchTerm]
  )

  useEffect(() => {
    dispatch(countEmployees())
    dispatch(countSurvey())
  }, [dispatch])

  const onCloseSendSelectedConfirm = () => {
    setShowConfirmSendSelected(false)
  }

  const onCloseSendAllConfirm = () => {
    setShowConfirmSendAll(false)
  }

  if (totalEmployees === 0) {
    return <AddEmployees />
  }

  const hasSurveyEntitlement = companyEntitlements?.scopes.includes(
    EntitlementScopesEnum.SURVEY
  )

  return (
    <Layout dark maxContentWidth={1080} sidebar={<AssessmentSidebar />}>
      <PageTop>
        <TitleContainer>
          <Text element="h1" type="heading" size="xl" fontWeight={500}>
            {t('employeesList.title')}{' '}
          </Text>
          <Text
            element="h1"
            type="body"
            size="md"
            fontWeight={500}
            color={baseColors.grey400}
          >
            ({totalEmployees})
          </Text>
        </TitleContainer>
        <Link to="/employees/add">
          <Button>{t('employees.onboarding.addEmployees')}</Button>
        </Link>
      </PageTop>
      <SubHeaderContainer>
        <Input
          placeholder={t('form.search.placeholder')}
          icon="search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          sx={{
            borderColor: baseColors.coolGrey400,
            minWidth: '300px',
          }}
          boxSx={{ flex: '0.5', minWidth: '300px' }}
        />
        {hasSurveyEntitlement && (
          <SurveyButtons
            selectedEmployees={selectedEmployees}
            setShowConfirmSendAll={setShowConfirmSendAll}
            setShowConfirmSendSelected={setShowConfirmSendSelected}
          />
        )}
      </SubHeaderContainer>
      {showSentSuccessMessage ? (
        <SuccessContainer>
          <RoundTick fill={baseColors.green} />
          <Text
            color={baseColors.grey500}
            element="p"
            size="sm"
            fontWeight={700}
            type="body"
          >
            {t('employees.surveysSent.success')}
          </Text>
          <CloseButton
            type="button"
            onClick={() => setShowSentSuccessMessage(false)}
          >
            <Close fill={baseColors.grey400} width="10px" height="10px" />
          </CloseButton>
        </SuccessContainer>
      ) : null}
      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
        <EmployeesList
          searchTerm={debouncedSearch}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          showSurveyColumn={hasSurveyEntitlement}
        />
      </Box>
      {showConfirmSendSelected && (
        <ConfirmSendSelectedModal
          selectedEmployees={selectedEmployees}
          onClose={onCloseSendSelectedConfirm}
          onSubmit={() => {
            dispatch(sendSurvey(selectedEmployees.map(item => item.id)))
            onCloseSendSelectedConfirm()
            if (!isSurveyError && !isSurveyLoading) {
              setShowSentSuccessMessage(true)
            }
          }}
        />
      )}
      {showConfirmSendAll && (
        <ConfirmSendAllModal
          onClose={onCloseSendAllConfirm}
          onSubmit={() => {
            dispatch(sendSurvey([]))
            onCloseSendAllConfirm()
            if (!isSurveyError && !isSurveyLoading) {
              setShowSentSuccessMessage(true)
            }
          }}
        />
      )}
    </Layout>
  )
}
