import { emptySplitApi } from 'emptySplitApi'

import { AssessmentChanges } from './types'

const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getAssessmentChanges: build.query<AssessmentChanges[], void>({
      query: () => ({
        url: 'assessments/changes',
      }),
    }),
  }),
})

export const { useGetAssessmentChangesQuery } = settingsApi
