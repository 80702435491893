import { FunctionComponent, useEffect } from 'react'

import { shallowEqual } from 'react-redux'

import { useAreas } from 'hooks/useAreas'
import { AreaSource } from 'store/area/types'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'

import { ReportsStatus } from '../reportsStatus/ReportsStatus'

import { useScoreArea } from './hooks/useScoreArea'

import { ReportsLandingWithGraphs } from './ReportsLandingWithGraphs'

export const ReportsCore: FunctionComponent = () => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  const { refetch: refetchLatestScores } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })

  useAreas({ group: AreaSource.DOCUMENTS, local: true })
  const scoreData = useScoreArea()

  useEffect(() => {
    if (isScorable) {
      refetchLatestScores()
    }
  }, [refetchLatestScores, isScorable])

  return isScorable ? (
    <ReportsLandingWithGraphs scoreData={scoreData} />
  ) : (
    <ReportsStatus />
  )
}
