import { FunctionComponent } from 'react'

import { alpha } from '@theme-ui/color'
import { Flex, Text } from '@theme-ui/components'
import { TooltipProps } from 'recharts'

import { baseColors } from '@fairhq/common'

import { Delta } from './Delta'
import { DataKeys } from './LineChart'

export const LineChartTooltip: FunctionComponent<
  TooltipProps<number, string>
> = ({ payload, active }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload.find(({ name }) => name === DataKeys.FROM) ?? {}
    const {
      value,
      payload: { delta },
    } = dataPoint
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-end',
          width: 100,
          height: 50,
          textAlign: 'center',
          transform: 'translate(-50px, -58px)',
          bg: alpha('white', 0.2),
        }}
      >
        <Text
          as="div"
          variant="subtitle"
          sx={{ color: baseColors.grey500, mb: '6px' }}
        >
          {value}
        </Text>
        {delta ? (
          <Delta
            delta={delta}
            sx={{
              mt: '-10px',
              mb: '6px',
            }}
          />
        ) : null}
      </Flex>
    )
  }

  return null
}
