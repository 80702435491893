import React, { FunctionComponent, useEffect, useState } from 'react'

import { Flex, Text } from '@theme-ui/components'

import { orderBy, uniq } from 'lodash'
import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { Colors } from '@fairhq/common'

import { Tabs, Tab } from 'components/Tabs'
import {
  VisualizationItemProps,
  VisualizationItemValueProps,
} from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import { useAppSelector } from 'store/hooks'

import { useGetCompanyPayGapBreakdownQuery } from 'store/reports/reportsApiWithQuery'

import { State } from 'store/state'

import BorderBox from 'ui-kit/BorderBox'

export const PayGapBreakdown: FunctionComponent<{
  activeTab: string
}> = ({ activeTab }) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { data: breakdown } = useGetCompanyPayGapBreakdownQuery({
    apiVersion,
    sessionId,
  })
  const currencies = uniq(breakdown?.map(({ currency }) => currency) ?? [])
  const [activeCurrency, setActiveCurrency] = useState('')
  const transformToGraphItem = (
    _type: 'level' | 'department' | 'country' | string,
    _group: 'ethnicity' | 'gender',
    _calculation: 'mean' | 'median'
  ) => {
    const items = orderBy(
      breakdown?.filter(
        ({ type, currency, employmentType }) =>
          type === _type &&
          currency === activeCurrency &&
          employmentType?.toLowerCase() === 'full-time'
      ) ?? [],
      ['group']
    )

    return items.map(
      ({
        group,
        pocMedian = 0,
        pocMean = 0,
        unknownEthnicityMedian = 0,
        unknownEthnicityMean = 0,
        whiteMedian = 0,
        whiteMean = 0,
        maleMedian = 0,
        maleMean = 0,
        femaleMedian = 0,
        femaleMean = 0,
        otherGenderMedian = 0,
        otherGenderMean = 0,
        unknownGenderMedian = 0,
        unknownGenderMean = 0,
      }) => {
        const values: Partial<VisualizationItemValueProps>[] = []
        if (_group === 'ethnicity' && _calculation === 'median') {
          values.push({
            key: 'poc',
            value: ((pocMedian - whiteMedian) * 100) / whiteMedian,
            label: t('company.breakdown.poc.median'),
            color: Colors.PURPLE,
          })
          values.push({
            key: 'unknownEthnicity',
            value: ((unknownEthnicityMedian - whiteMedian) * 100) / whiteMedian,
            label: t('company.breakdown.unknownEthnicity.median'),
            color: Colors.GREY200,
          })
        } else if (_group === 'gender' && _calculation === 'median') {
          values.push({
            key: 'otherGender',
            value: ((otherGenderMedian - maleMedian) * 100) / maleMedian,
            label: t('company.breakdown.otherGender.median'),
            color: Colors.PURPLE,
            tooltip: <b>{t('company.breakdown.otherGender.median')}</b>,
          })
          values.push({
            key: 'female',
            value: ((femaleMedian - maleMedian) * 100) / maleMedian,
            label: t('company.breakdown.female.median'),
            color: Colors.PURPLE,
            tooltip: <b>{t('company.breakdown.female.median')}</b>,
          })
          values.push({
            key: 'unknownGender',
            value: ((unknownGenderMedian - maleMedian) * 100) / maleMedian,
            label: t('company.breakdown.unknownGender.median'),
            color: Colors.GREY200,
          })
        } else if (_group === 'ethnicity' && _calculation === 'mean') {
          values.push({
            key: 'poc',
            value: ((pocMean - whiteMean) * 100) / whiteMean,
            label: t('company.breakdown.poc.mean'),
            color: Colors.PURPLE,
          })
          values.push({
            key: 'unknownEthnicity',
            value: ((unknownEthnicityMean - whiteMean) * 100) / whiteMean,
            label: t('company.breakdown.unknownEthnicity.mean'),
            color: Colors.GREY200,
          })
        } else if (_group === 'gender' && _calculation === 'mean') {
          values.push({
            key: 'otherGender',
            value: ((otherGenderMean - maleMean) * 100) / maleMean,
            label: t('company.breakdown.otherGender.mean'),
            color: Colors.PURPLE,
            tooltip: <b>{t('company.breakdown.otherGender.mean')}</b>,
          })
          values.push({
            key: 'female',
            value: ((femaleMean - maleMean) * 100) / maleMean,
            label: t('company.breakdown.female.mean'),
            color: Colors.PURPLE,
            tooltip: <b>{t('company.breakdown.female.mean')}</b>,
          })
          values.push({
            key: 'unknownGender',
            value: ((unknownGenderMean - maleMean) * 100) / maleMean,
            label: t('company.breakdown.unknownGender.mean'),
            color: Colors.GREY200,
          })
        }
        return { label: group, values } as any
      }
    )
  }
  const items: Record<string, VisualizationItemProps[]> = {
    byJobLevelAndGenderAndMean: transformToGraphItem('level', 'gender', 'mean'),
    byJobLevelAndGenderAndMedian: transformToGraphItem(
      'level',
      'gender',
      'median'
    ),
    byDepartmentAndGenderAndMean: transformToGraphItem(
      'department',
      'gender',
      'mean'
    ),
    byDepartmentAndGenderAndMedian: transformToGraphItem(
      'department',
      'gender',
      'median'
    ),
    byLocationAndGenderAndMean: transformToGraphItem(
      'country',
      'gender',
      'mean'
    ),
    byLocationAndGenderAndMedian: transformToGraphItem(
      'country',
      'gender',
      'median'
    ),
    byJobLevelAndEthnicityAndMean: transformToGraphItem(
      'level',
      'ethnicity',
      'mean'
    ),
    byJobLevelAndEthnicityAndMedian: transformToGraphItem(
      'level',
      'ethnicity',
      'median'
    ),
    byDepartmentAndEthnicityAndMean: transformToGraphItem(
      'department',
      'ethnicity',
      'mean'
    ),
    byDepartmentAndEthnicityAndMedian: transformToGraphItem(
      'department',
      'ethnicity',
      'median'
    ),
    byLocationAndEthnicityAndMean: transformToGraphItem(
      'country',
      'ethnicity',
      'mean'
    ),
    byLocationAndEthnicityAndMedian: transformToGraphItem(
      'country',
      'ethnicity',
      'median'
    ),
  }
  const tabs: Tab[] = currencies?.map(currency => ({
    key: `${currency}`,
    label: t(currency),
  }))

  useEffect(() => {
    if (currencies?.length > 0 && !activeCurrency) {
      setActiveCurrency(currencies?.[0] ?? '')
    }
  }, [activeCurrency, currencies])

  return (
    <BorderBox mb={9} sx={{ py: 5 }}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 4,
        }}
      >
        <Tabs
          tabs={tabs}
          activeTab={activeCurrency}
          setActiveTab={setActiveCurrency}
        />
      </Flex>
      <Text as="div" variant="caps" mb={4}>
        {t('breakdown.byGenderAndFullTimeAndMean')}
      </Text>
      <Visualization
        type={
          (items?.[`${activeTab}AndGenderAndMean`] ?? [])?.length > 8
            ? 'Row'
            : 'Bar'
        }
        items={items?.[`${activeTab}AndGenderAndMean`] ?? []}
        isLegendVisible
        isGaps
        isMinMajSorted
      />
      <Text as="div" variant="caps" my={4}>
        {t('breakdown.byGenderAndFullTimeAndMedian')}
      </Text>
      <Visualization
        type={
          (items?.[`${activeTab}AndGenderAndMedian`] ?? [])?.length > 8
            ? 'Row'
            : 'Bar'
        }
        items={items?.[`${activeTab}AndGenderAndMedian`] ?? []}
        isLegendVisible
        isGaps
        isMinMajSorted
      />
      <Text as="div" variant="caps" my={4}>
        {t('breakdown.byEthnicityAndFullTimeAndMean')}
      </Text>
      <Visualization
        type={
          (items?.[`${activeTab}AndEthnicityAndMean`] ?? [])?.length > 8
            ? 'Row'
            : 'Bar'
        }
        items={items?.[`${activeTab}AndEthnicityAndMean`] ?? []}
        isLegendVisible
        isGaps
        isMinMajSorted
      />
      <Text as="div" variant="caps" my={4}>
        {t('breakdown.byEthnicityAndFullTimeAndMedian')}
      </Text>
      <Visualization
        type={
          (items?.[`${activeTab}AndEthnicityAndMedian`] ?? [])?.length > 8
            ? 'Row'
            : 'Bar'
        }
        items={items?.[`${activeTab}AndEthnicityAndMedian`] ?? []}
        isLegendVisible
        isGaps
        isMinMajSorted
      />
    </BorderBox>
  )
}
