import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Navigate as Redirect } from 'react-router-dom'
import { useMount } from 'react-use'
import { Checkbox, Row, Schema, Col } from 'rsuite'

import { Field } from 'components/form/Field'
import { Form } from 'components/form/Form'
import { ReactComponent as CheckboxCheckedIcon } from 'components/questions/assets/checkbox-checked.svg'
import { ReactComponent as CheckboxEmptyIcon } from 'components/questions/assets/checkbox-empty.svg'
import { useSchema } from 'hooks'
import { useErrorMessage } from 'hooks/useErrorMessage'
import { useSubmit } from 'hooks/useSubmit'
import { authActions } from 'store/auth/authSlice'
import {
  createCustomer,
  updateCustomer,
  getCustomer,
} from 'store/customer/customerSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { Message } from 'styled/message'
import { sanitizeNull } from 'utils/sanitizeNull'

const BillingFormComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { tax, address, company, error, loading, user, customerId } =
    useAppSelector(
      (state: State) => ({
        company: state.companyReducer.company,
        error: state.customerReducer.error,
        loading: state.customerReducer.loading,
        tax: state.customerReducer.customer?.taxId,
        customerId: state.customerReducer.customer?.id,
        address: state.customerReducer.customer,
        user: state.authReducer.user,
      }),
      shallowEqual
    )
  const { getAccessTokenSilently } = useAuth0()
  const [disabledEmail, setDisabledEmail] = useState(true)
  const [form, setForm] = useState({
    email: user?.email || '',
    city: '',
    country: t('countries.GBR'),
    line1: '',
    line2: '',
    postal_code: '',
    vat: '',
  })
  const [redirect, setRedirect] = useState('')
  const { errorMessage, setError } = useErrorMessage()
  const { submitting, setSubmitting, onSubmit } = useSubmit(() => {
    const { email, city, line1, line2, postal_code, vat } = form
    const customer = {
      email,
      name: company?.name || '',
      address: { city, line1, line2, postal_code, country: 'UK' },
    }
    if (!customerId) {
      dispatch(createCustomer({ customer, vat }))
    } else {
      dispatch(updateCustomer({ customer, vat }))
    }
  })
  const schema = useSchema()
  const model: any = Schema.Model(
    _.pick(schema, [
      'email',
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'vat',
    ])
  )
  model.city = model.city?.isRequired(t('form.required'))
  model.country = model.country?.isRequired(t('form.required'))

  useMount(() => {
    dispatch(getCustomer())
  })

  useEffect(() => {
    if (disabledEmail && !form.email) {
      setForm(sanitizeNull({ ...form, email: user?.email || '' }))
    }
  }, [disabledEmail, form, user])

  useEffect(() => {
    if (submitting && !loading) {
      setSubmitting(false)
      if (error) {
        setError(error)
      } else {
        getAccessTokenSilently().then(token => {
          if (token) {
            dispatch(authActions.setJWT(token))
          }
          setRedirect(`/hello/4`)
        })
      }
    }
  }, [
    dispatch,
    error,
    getAccessTokenSilently,
    loading,
    setError,
    setSubmitting,
    submitting,
  ])

  useEffect(() => {
    if (address) {
      setForm(
        sanitizeNull({
          city: address.address_city,
          country: address.address_country,
          line1: address.address_line1,
          line2: address.address_line2,
          postal_code: address.address_postal_code,
          email: user?.email || '',
          vat: tax?.value || '',
        })
      )
    }
  }, [address, t, tax?.value, user])

  if (!company) {
    return <Redirect to="/hello/2" />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Form
      loading={submitting}
      disabled={submitting}
      errorMessage={errorMessage}
      model={model}
      formValue={form}
      onChange={(formValue: typeof form) => setForm(formValue)}
      onSubmit={onSubmit}
      submitBtnLabel={t('hello.payment.billing.save')}
      checkTrigger="none"
    >
      <Message>{t('hello.payment.billing.pleaseFillYourBillingInfo')}</Message>
      <Field
        name="email"
        label={t('hello.payment.billing.emailFor')}
        placeholder={t(`form.email.placeholder`)}
        disabled={disabledEmail}
        className="form-email"
      />
      <Checkbox
        checked={disabledEmail}
        onChange={(value, checked) => setDisabledEmail(checked)}
      >
        {disabledEmail ? <CheckboxCheckedIcon /> : <CheckboxEmptyIcon />}{' '}
        <span>{t('hello.payment.billing.useDefaultEmail')}</span>
      </Checkbox>
      <Field
        name="line1"
        label={t('hello.payment.billing.address')}
        placeholder={t(`form.line1.placeholder`)}
        className="form-line1"
      />
      <Field
        name="line2"
        placeholder={t(`form.line2.placeholder`)}
        className="form-line2"
      />
      <Row gutter={10}>
        {[
          { name: 'city', xs: 16 },
          { name: 'postal_code', xs: 8 },
        ].map(({ name, xs }) => (
          <Col key={name} xs={xs}>
            <Field
              name={name}
              placeholder={t(`form.${name}.placeholder`)}
              label={t(`form.${name}.label`)}
            />
          </Col>
        ))}
      </Row>
      <Field
        name="country"
        label={t(`form.country.label`)}
        placeholder={t(`form.country.placeholder`)}
        disabled
        className="form-country"
      />
      <Field
        name="vat"
        placeholder={t(`form.vat.placeholder`)}
        label={t(`form.vat.label`)}
      />
    </Form>
  )
}

export const BillingForm = React.memo(BillingFormComponent)
