import { FunctionComponent, useEffect } from 'react'

import { Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { useGetAuditAreasQuery } from 'store/audit/auditApiWithQuery'
import { count as countEmployees } from 'store/employee/employeeSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useGetDiversityMakeupReportsQuery } from 'store/reports/reportsApiWithQuery'
import { DiversityMakeup } from 'store/reports/types'
import { State } from 'store/state'
import { count as countSurvey } from 'store/survey/surveySlice'
import { tCode } from 'utils/tCode'

import { ReportsSidebarGeneralSubAndGroups } from '../reports/ReportsSidebarGeneralSubAndGroups'

import { DiversityExperience } from './components/DiversityExperience'
import { DiversityMakeUp } from './components/DiversityMakeUp'
import { DiversityRepresentation } from './components/DiversityRepresentation'
import { DiversityRepresentationGroup } from './components/DiversityRepresentationGroup'
import { InclusionQuestions } from './components/InclusionQuestions'
import { ReportsDocuments } from './components/ReportsDocuments'
import { ReportsExperience } from './components/ReportsExperience'
import { ReportsFairness } from './components/ReportsFairness'
import { ReportsOverview } from './components/ReportsOverview'
import { ReportsPracticeCriteria } from './components/ReportsPracticeCriteria'
import { useScoreBreakdown } from './hooks/useScoreBreakdown'

import { getGroup } from './utils/getGroup'
import { getProjection } from './utils/getProjection'

const getAreaIfGeneral = (pathname: string) => {
  if (pathname?.endsWith('/equality')) {
    return 'equality'
  }
  if (pathname?.endsWith('/diversity')) {
    return 'diversity'
  }
  if (pathname?.endsWith('/inclusion')) {
    return 'inclusion'
  }

  return undefined
}

export const ReportsAreasCore: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { permissions } = useAuthPermissions()

  const { surveyStatus, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      surveyStatus: state.surveyReducer.status,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { pathname } = useLocation()
  const { code } = useParams<{ code: string }>()
  const generalArea = getAreaIfGeneral(pathname)
  const areaCode = code || generalArea || ''
  const { data: areas } = useGetAuditAreasQuery({ sessionId, query: {} })
  const area = areas?.find(({ code }) => code === areaCode)
  const { graphItems } = useScoreBreakdown(generalArea)

  const group = getGroup(pathname)
  const projection = getProjection(pathname)

  const { data: diversityMakeupData } = useGetDiversityMakeupReportsQuery({
    apiVersion,
    sessionId,
    breakdown: true,
  })

  useEffect(() => {
    dispatch(countSurvey())
    if (permissions?.includes(PermissionsEnum.READ_EMPLOYEE)) {
      dispatch(countEmployees())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const diversityMakeupGraphData = diversityMakeupData?.map(
    ({ demGroupCode, demGroupTitle, total }: DiversityMakeup) => ({
      key: demGroupCode + Math.random(),
      label: tCode(t)(demGroupCode, '', demGroupTitle),
      value: total,
    })
  )

  return (
    <Layout sidebar={<ReportsSidebarGeneralSubAndGroups />} dark>
      <PageTop>
        <Heading>{tArea(areaCode, 'title', area?.title)}</Heading>
      </PageTop>
      <ReportsOverview code={areaCode} graphItems={graphItems} />
      {areaCode === 'diversity' && (
        <DiversityMakeUp
          status={surveyStatus}
          graphData={diversityMakeupGraphData}
        />
      )}
      {areaCode.includes('equality.') && (
        <ReportsPracticeCriteria code={areaCode} />
      )}
      {projection.includes('documents') && <ReportsDocuments code={areaCode} />}
      {projection.includes('fairness') && <ReportsFairness code={areaCode} />}
      {projection.includes('experience') &&
        areaCode?.startsWith('inclusion') && (
          <DiversityExperience code={areaCode} />
        )}
      {projection.includes('experience') &&
        (areaCode?.includes('equality.') ||
          areaCode?.includes('inclusion.')) && (
          <ReportsExperience code={areaCode} />
        )}
      {projection.includes('experience') && areaCode === 'inclusion' && (
        <InclusionQuestions code={areaCode} />
      )}
      {(group?.includes('poc') || group?.includes('woman')) && (
        <DiversityRepresentation code={areaCode} group={group} />
      )}
      {(group?.includes('leadership') || group?.includes('manager')) && (
        <DiversityRepresentationGroup area={areaCode} group={group} />
      )}
    </Layout>
  )
}
