import React, { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { ArrowWithBackground } from 'components/ArrowWithBackground'
import { TaskWithDropdown } from 'components/TaskWithDropdown/TaskWithDropdown'
import { Strategy } from 'store/strategy/types'
import { TaskStatus } from 'store/tasks/types'

import { useInitiatives } from './hooks/useInitiatives'
import { PlainCard } from './PlainCard'
import { StrategyFinished } from './StrategyFinished'

const InitiativeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 24px 32px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  &:focus-visible {
    outline: 2px solid ${baseColors.purple};
    outline-offset: 2px;
    border-radius: 8px;
  }
`

const CapsText = styled(Text)`
  text-transform: uppercase;
  color: ${baseColors.purple800};
  white-space: nowrap;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 32px 40px;
  gap: 8px;
  background-color: ${baseColors.white};
  margin: 0;
  &:last-child {
    border-radius: 0 0 20px 20px;
  }
`
const ListItem = styled.li<{ $completed: boolean }>`
  display: flex;
  padding: 8px 8px 8px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: ${({ $completed }) =>
    $completed ? baseColors.coolGrey100 : baseColors.purple100};
  gap: 24px;
`

interface InitiativesProps {
  strategy: Strategy
}

export const Initiatives: FunctionComponent<InitiativesProps> = ({
  strategy,
}) => {
  const { initiativesToShow, uncompletedInitiatives } = useInitiatives(strategy)

  if (uncompletedInitiatives.length === 0) {
    return <StrategyFinished />
  }

  return (
    <>
      {initiativesToShow?.map(companyInitiative => (
        <PlainCard key={companyInitiative.id}>
          <InitiativeHeader>
            <Text element="p" fontWeight={600} type="body" size="md">
              {companyInitiative.recommendation.description}
            </Text>
            <StyledLink
              to={`/strategy/recommendation/${companyInitiative.recommendation.code}`}
            >
              <CapsText element="p" fontWeight={700} type="body" size="xs">
                Full guide
              </CapsText>
              <ArrowWithBackground />
            </StyledLink>
          </InitiativeHeader>
          {companyInitiative.tasks.length > 0 && (
            <List>
              {companyInitiative.tasks.map(task => (
                <ListItem
                  $completed={task.status === TaskStatus.COMPLETED}
                  key={`${companyInitiative.id}_${task.id}`}
                >
                  <TaskWithDropdown
                    task={task}
                    parentId={companyInitiative.id}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </PlainCard>
      ))}
    </>
  )
}
