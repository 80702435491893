export function checkTokenStatus(decodedToken: any) {
  if (!decodedToken) {
    return { status: 'invalid' }
  }

  try {
    const { exp, ...restOfToken } = decodedToken
    if (exp > Math.floor(Date.now() / 1000)) {
      return { status: 'valid', ...restOfToken }
    }

    return { status: 'expired', accountId: !!restOfToken.accountId }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  return { status: 'invalid' }
}
