import { FunctionComponent } from 'react'

interface DefaultLinkProps {
  children: React.ReactNode
}

export const DefaultLink: FunctionComponent<DefaultLinkProps> = ({
  children,
  ...rest
}) => (
  <a target="_blank" rel="noopener noreferrer" {...rest}>
    {children}
  </a>
)
