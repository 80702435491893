import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import { OldRecommendationIntensity } from 'store/oldRecommendations/types'

import { DifficultyCircle } from './DifficultyCircle'

const textMap: Record<string, string> = {
  [OldRecommendationIntensity.LOW]: 'oldRecommendation.difficulty.easy',
  [OldRecommendationIntensity.MEDIUM]: 'oldRecommendation.difficulty.medium',
  [OldRecommendationIntensity.INTENSIVE]: 'oldRecommendation.difficulty.hard',
}

const bulletMap: Record<string, boolean[]> = {
  [OldRecommendationIntensity.LOW]: [true, false, false],
  [OldRecommendationIntensity.MEDIUM]: [true, true, false],
  [OldRecommendationIntensity.INTENSIVE]: [true, true, true],
}

interface OldRecommendationDifficultyProps {
  data: OldRecommendationIntensity | string
  sx?: ThemeUIStyleObject
}

export const OldRecommendationDifficulty: FunctionComponent<
  OldRecommendationDifficultyProps
> = ({ data, sx }) => {
  const { t } = useTranslation()

  const bullets = bulletMap?.[data] ?? []

  return (
    <Flex sx={{ alignItems: 'center', ...sx }}>
      <Flex sx={{ flex: '0 0 auto', mr: 1 }}>
        {bullets?.map((active, index) => (
          <DifficultyCircle
            key={index}
            active={active}
            sx={{ ml: index === 0 ? null : '2px' }}
          />
        ))}
      </Flex>
      <Text as="div" sx={{ color: 'grey400', fontWeight: 400 }}>
        {t(textMap[data])}
      </Text>
    </Flex>
  )
}
