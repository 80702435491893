import jwtDecode from 'jwt-decode'
import { shallowEqual } from 'react-redux'

import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export enum PermissionsEnum {
  READ_EMPLOYEE = 'read:employee',
  CREATE_SESSION = 'create:session',
  READ_AUDIT = `read:audit`,
}

export const useAuthPermissions = () => {
  const { token } = useAppSelector(
    (state: State) => ({
      token: state.authReducer.jwt,
    }),
    shallowEqual
  )

  if (token) {
    const decodedToken: { permissions: PermissionsEnum[] } = jwtDecode(token)
    return { permissions: decodedToken.permissions }
  }

  return { permissions: [] }
}
