import { FunctionComponent } from 'react'

import { Trans } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Arrow, Button, Graph, Text, baseColors } from '@fairhq/common'
import { ArrowDirection } from '@fairhq/common/lib/components/atoms/icons/Arrow'
import purpleBackgroundHeader from 'components/svgs/purpleBackgroundHeader.svg'
import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementScopesEnum } from 'store/company/types'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { useIsCompanyAssessmentComplete } from './hooks/useIsCompanyAssessmentComplete'

const Container = styled.section`
  background-color: ${baseColors.purple800};
  border-radius: 20px;
  padding: 32px;
  color: ${baseColors.white};
  background-image: url('${purpleBackgroundHeader}');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-height: 220px;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`
const StyledButton = styled(Button)`
  margin-top: 4px;
`

export const DashboardIntro: FunctionComponent = () => {
  const navigate = useNavigate()
  const { permissions } = useAuthPermissions()
  const { user, companyName } = useAppSelector(
    (state: State) => ({
      user: state.authReducer.user,
      companyName: state.companyReducer.company?.name,
    }),
    shallowEqual
  )

  const { data: companyEntitlements } = useGetEntitlementsQuery()
  const { isCompanyAssessmentCompleted } = useIsCompanyAssessmentComplete()

  const getDynamicBody = (
    entitlementScopes: EntitlementScopesEnum[],
    isCompanyAssessmentComplete: boolean
  ) => {
    if (isCompanyAssessmentComplete) {
      return (
        <>
          <Text element="h1" fontWeight={500} type="heading" size="xl">
            <Trans i18nKey="dashboard.heading.titleFocus" />
          </Text>
          <Trans i18nKey="dashboard.heading.descriptionFocus" />
          {entitlementScopes.includes(EntitlementScopesEnum.REPORTS) && (
            <StyledButton
              variant="tertiary"
              onClick={() => navigate('/reports')}
              iconLeft={<Graph />}
            >
              <Trans i18nKey="dashboard.heading.buttonTitleReports" />
            </StyledButton>
          )}
        </>
      )
    }
    return (
      <>
        <Text element="h1" fontWeight={500} type="heading" size="xl">
          <Trans i18nKey="dashboard.heading.titleWelcome" />
        </Text>

        <Trans
          i18nKey="dashboard.heading.descriptionWelcome"
          values={{
            companyName: companyName || 'your company',
          }}
        />
        {entitlementScopes.includes(EntitlementScopesEnum.AUDIT) &&
          permissions.includes(PermissionsEnum.READ_AUDIT) && (
            <StyledButton
              variant="tertiary"
              onClick={() => navigate('/audit')}
              iconRight={<Arrow direction={ArrowDirection.RIGHT} />}
            >
              <Trans i18nKey="dashboard.heading.buttonTitleWelcome" />
            </StyledButton>
          )}
      </>
    )
  }

  return (
    <Container>
      <StyledText element="p" fontWeight={700} type="body" size="xs">
        <Trans
          i18nKey="dashboard.welcome"
          values={{
            userGivenName: user?.given_name,
          }}
        />
      </StyledText>
      {companyEntitlements?.scopes &&
        companyEntitlements?.scopes.length > 0 &&
        isCompanyAssessmentCompleted !== undefined &&
        getDynamicBody(
          companyEntitlements?.scopes,
          isCompanyAssessmentCompleted
        )}
    </Container>
  )
}
