import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import purpleBackgroundHeader from 'components/svgs/purpleBackgroundHeader.svg'

/* stylelint-disable declaration-block-no-redundant-longhand-properties */
const Container = styled.div`
  background-color: ${baseColors.purple800};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 48px 40px;
  color: ${baseColors.white};
  background-image: url(${purpleBackgroundHeader});
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

export const TasksIntro: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <StyledText element="p" fontWeight={700} type="body" size="xs">
        {t('criticalFoundations.label')}{' '}
      </StyledText>
      <Text element="h1" fontWeight={500} type="heading" size="xl">
        {t('criticalFoundations.title')}
      </Text>
      <Text element="p" fontWeight={400} type="body" size="sm">
        {t('criticalFoundations.description')}
      </Text>
    </Container>
  )
}
