import { FunctionComponent, useEffect } from 'react'

import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import {
  useGetSurveyWindowQuery,
  useIsSurveyWindowDueQuery,
} from 'store/surveyWindow/surveyWindowApi'

import { CurrentSurveyWindow } from './CurrentSurveyWindow'
import { SurveyWindowDue } from './SurveyWindowDue'

const StyledWhiteBox = styled(WhiteBoxWithShadow)`
  padding: 32px;
  gap: 16px;
  flex-basis: 50%;
  min-height: 180px;
`

export const SurveyWindowPrompt: FunctionComponent = () => {
  const { data: currentSurveyWindow, isFetching: isFetchingCurrent } =
    useGetSurveyWindowQuery()
  const { data: isSurveyWindowDue, isFetching: isFetchingDue } =
    useIsSurveyWindowDueQuery()

  useEffect(() => {}, [currentSurveyWindow, isSurveyWindowDue])

  if (isSurveyWindowDue) {
    return (
      <StyledWhiteBox>{!isFetchingDue && <SurveyWindowDue />}</StyledWhiteBox>
    )
  }

  if (currentSurveyWindow) {
    return (
      <StyledWhiteBox>
        {isFetchingDue || isFetchingCurrent ? (
          <Spinner center />
        ) : (
          <CurrentSurveyWindow currentSurveyWindow={currentSurveyWindow} />
        )}
      </StyledWhiteBox>
    )
  }

  return null
}
