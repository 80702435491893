import { FunctionComponent } from 'react'

import { DemGroupRepresentation } from './DemGroupRepresentation'
import { DemGroupScoreBreakdown } from './DemGroupScoreBreakdown'
import { DemGroupScoreRepresentation } from './DemGroupScoreRepresentation'

export const GenericGroupReports: FunctionComponent = () => (
  <>
    <DemGroupRepresentation key="DemGroupRepresentation" />
    <DemGroupScoreRepresentation
      key="DemGroupScoreRepresentation_inclusion"
      area="inclusion"
    />
    <DemGroupScoreBreakdown
      key="DemGroupScoreBreakdown_inclusion"
      area="inclusion"
    />
    <DemGroupScoreRepresentation key="DemGroupScoreBreakdown" area="equality" />
    <DemGroupScoreBreakdown key="DemGroupScoreBreak" area="equality" />
  </>
)
