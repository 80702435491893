import React, { FunctionComponent } from 'react'

import { Text, TextProps } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { markedNumberFormat } from 'utils/numbers'

type DeltaValue = number | null | undefined

interface DeltaProps {
  delta: DeltaValue
  percents?: boolean
  variant?: TextProps['variant']
  sx?: ThemeUIStyleObject
}

export const Delta: FunctionComponent<DeltaProps> = ({
  delta,
  percents = false,
  variant,
  sx,
}) => {
  if (delta === undefined) {
    return null
  }

  return (
    <Text
      as="div"
      variant={variant || 'labelBold'}
      sx={{
        color: delta === null ? 'grey300' : delta > 0 ? 'green' : 'red',
        ...sx,
      }}
    >
      {markedNumberFormat(delta)}
      {percents ? '%' : ''}
    </Text>
  )
}
