import { FunctionComponent, useState } from 'react'

import { Box, Flex, Text, Link } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import { DATE_FORMAT_SESSION_URL, DATE_FORMAT_SURVEY } from 'const'
import { useCompletion } from 'hooks/useCompletion'
import { Session } from 'store/company/types'
import { toDate } from 'utils/dates'

import { getProgressColor } from 'utils/getProgressColor'

import Button from '../../ui-kit/Button'
import Icon from '../../ui-kit/Icon'
import RouterLink from '../../ui-kit/RouterLink'
import WhiteBox from '../../ui-kit/WhiteBox'

import { SurveyCompletion } from './SurveyCompletion'

type SurveyCardProps = {
  session?: Session
  defaultOpened?: boolean
  sx?: ThemeUIStyleObject
}

export const SurveyCard: FunctionComponent<SurveyCardProps> = ({
  session,
  defaultOpened,
  sx,
}) => {
  const { t, i18n } = useTranslation()
  const [opened, setOpened] = useState(!!defaultOpened)

  const completion = useCompletion(session)

  const getContent = () => {
    const title =
      completion.total === 0
        ? t('survey.sent')
        : t('dashboard.percentCompleted', { percent: completion.total })
    const resendSurveyLink = (
      <Text as="div">
        <Trans
          i18nKey="employees.havingProblemsFindingSurvey"
          components={[
            <br />,
            <RouterLink variant="capsLink" to="/employees">
              Resend to selected employees
            </RouterLink>,
          ]}
        />
      </Text>
    )
    const changelogStartDate = session?.start_date
      ? toDate(i18n.language, session?.start_date).format(
          DATE_FORMAT_SESSION_URL
        )
      : ''

    const content = (completion.total === 0 && [
      <Text
        as="div"
        variant="bodyLargeBold"
        sx={{ mb: 3, mt: 5, color: 'grey500' }}
      >
        {t('employees.wasSentToAll')}
      </Text>,
      <Text as="div" sx={{ color: 'grey500', mb: 4 }}>
        {t('employees.sitBackAndRelax')}
      </Text>,
      <Text as="div" variant="label" sx={{ mb: 4 }}>
        {t('employees.getAtLeast75')}
      </Text>,
      resendSurveyLink,
    ]) ||
      (completion.total < 25 && [
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{ mb: 3, mt: 5, color: 'grey500' }}
        >
          {t('employees.allReceived')}
        </Text>,
        <Text as="div" sx={{ color: 'grey500', mb: 4 }}>
          {t('employees.haveYouLaunch')}
        </Text>,
        <Text as="div" variant="label" sx={{ mb: 4 }}>
          {t('employees.getAtLeast75')}
        </Text>,
        resendSurveyLink,
      ]) ||
      (completion.total < 75 && [
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{ mb: 3, mt: 5, color: 'grey500' }}
        >
          {t('employees.niceWork')}
        </Text>,
        <Text as="div" sx={{ color: 'grey500', mb: 4 }}>
          <Trans
            i18nKey="employees.youHaveReached"
            values={{ completion: completion.total }}
            components={[
              <Link
                href="https://ask.fairhq.co/en/articles/5742307-private-beta-launch-pack"
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </Text>,
        ...(completion.isLatest ? [resendSurveyLink] : []),
        ...((completion.hasChangelog && [
          <Button
            as={RouterLink}
            to={`/session/${changelogStartDate}`}
            size="large"
            sx={{ alignItems: 'center', display: 'inline-flex' }}
          >
            {t('survey.viewResults')}
          </Button>,
        ]) ||
          []),
      ]) ||
      (completion.surveyFinished && [
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{ mb: 3, mt: 5, color: 'grey500' }}
        >
          {t('employees.finalResult', { completion: completion.total })}
        </Text>,
        <Text as="div" sx={{ color: 'grey500', mb: 8 }}>
          {t('employees.survey.thanksForGoing')}
        </Text>,
        ...((completion.hasChangelog && [
          <Button
            as={RouterLink}
            to={`/session/${changelogStartDate}`}
            size="large"
            sx={{ alignItems: 'center', display: 'inline-flex' }}
          >
            {t('survey.viewResults')}
          </Button>,
        ]) ||
          []),
      ]) || [
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{ mb: 3, mt: 5, color: 'grey500' }}
        >
          {t('survey.greatCompletion')}
        </Text>,
        <Text as="div" sx={{ color: 'grey500', mb: 4 }}>
          {t('employees.youHaveReached75', { completion: completion.total })}
        </Text>,
      ]

    return { title, content }
  }

  const content = getContent()

  if (!session) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <WhiteBox sx={{ position: 'relative', overflow: 'hidden', ...sx }}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flex: '0 1 468px', mr: 10 }}>
          <Text as="div" variant="bodyBold" sx={{ textTransform: 'uppercase' }}>
            {t('survey.created', {
              date: toDate(i18n.language, session.start_date).format(
                DATE_FORMAT_SURVEY
              ),
            })}
          </Text>
          <Text
            as="div"
            variant="heading"
            onClick={() => setOpened(!opened)}
            sx={{
              userSelect: 'none',
              cursor: 'pointer',
              color: getProgressColor(completion.total),
              '&:after': {
                content: "''",
                position: 'absolute',
                top: -9000,
                left: -9000,
                right: -9000,
                bottom: -9000,
                display: opened ? 'none' : 'block',
              },
            }}
          >
            {completion.surveyFinished && !opened
              ? t('survey.completed')
              : content.title}
            <Icon
              name={opened ? 'arrowDown' : 'arrowRight'}
              sx={{ ml: 2 }}
              small
            />
          </Text>

          {opened && content.content}
        </Box>

        <SurveyCompletion data={completion} opened={opened} />
      </Flex>
    </WhiteBox>
  )
}
