import { FunctionComponent } from 'react'

import {
  Link as ThemeLink,
  LinkProps as ThemeLinkProps,
} from '@theme-ui/components'
import { Link, LinkProps } from 'react-router-dom'

const RouterLink: FunctionComponent<
  Omit<ThemeLinkProps, 'href'> & LinkProps
> = ({ sx, ...rest }) => <ThemeLink as={Link} sx={sx} {...rest} />

export default RouterLink
