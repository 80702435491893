import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { Square } from 'components/Square'
import { useGetDemGroupsQuery } from 'store/surveyWindow/surveyWindowApi'

import { ApprovalStamp } from './assets/ApprovalStamp'

const TradVsMinority = styled.div`
  display: flex;
  padding-left: 20px;
  gap: 60px;
`

const ChartContainer = styled.div`
  min-width: 190px;
  min-height: 190px;
  max-height: 240px;
  max-width: 240px;
`

const Captions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Caption = styled.div`
  display: flex;
  gap: 8px;
`

const CaptionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
`

const Approval = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
`

const CaptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

interface TradVsMinoritiesProps {
  surveyWindowId: number
  completed: number
  level?: string
}

export const TradVsMinorities: FunctionComponent<TradVsMinoritiesProps> = ({
  surveyWindowId,
  completed,
  level,
}) => {
  const { t } = useTranslation()

  const { data: minoritiesData, isSuccess: isMinoritiesSuccess } =
    useGetDemGroupsQuery({
      surveyWindowId,
      code: 'dem_minorities',
      level,
    })

  const { data: tradMajData, isSuccess: isTradMajSuccess } =
    useGetDemGroupsQuery({
      surveyWindowId,
      code: 'dem_traditional_majority',
      level,
    })

  const COLORS = [
    baseColors.purple500,
    baseColors.purple800,
    baseColors.grey200,
  ]

  const tradMaj = tradMajData?.[0] ?? {
    demGroupCode: 'dem_traditional_majority',
    count: 0,
    rate: 0,
  }

  const minorities = minoritiesData?.[0] ?? {
    demGroupCode: 'dem_minorities',
    count: 0,
    rate: 0,
  }

  if (isTradMajSuccess && isMinoritiesSuccess) {
    const unknown = {
      demGroupCode: 'dem_unknown',
      count: completed - (tradMaj.count + minorities.count),
    }

    const allGroups = [minorities, tradMaj, unknown].map(item => ({
      name: item.demGroupCode,
      value: item.count,
    }))

    return (
      <>
        <Text element="p" type="body" size="md" fontWeight={400}>
          {t('diversity.overview.description')}
        </Text>
        <TradVsMinority>
          <ChartContainer>
            <ResponsiveContainer height="100%" width={190}>
              <PieChart>
                <Pie
                  data={allGroups}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  dataKey="value"
                >
                  {allGroups.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
          <Captions>
            {[minorities, tradMaj].map((item, index) => {
              const percentage = Math.round(+item.rate * 100)

              return (
                <Caption key={item.demGroupCode}>
                  <Square backgroundColor={COLORS[index]} />
                  <CaptionBody>
                    <CaptionHeader>
                      <Text element="p" type="body" size="md" fontWeight={600}>
                        <Trans
                          i18nKey={`diversity.latest.${item.demGroupCode}.title`}
                          values={{
                            value: percentage,
                          }}
                        />
                      </Text>
                      {item.demGroupCode.includes('minorities') && (
                        <Approval>
                          <ApprovalStamp />
                          <Text
                            element="span"
                            type="body"
                            size="sm"
                            fontWeight={400}
                            color={baseColors.purple700}
                          >
                            <Trans
                              i18nKey="diversity.overview.minoritiesBenchmark"
                              values={{
                                value: 64,
                              }}
                            />
                          </Text>
                        </Approval>
                      )}
                    </CaptionHeader>
                    <Text element="p" type="body" size="sm" fontWeight={400}>
                      <Trans
                        i18nKey={`diversity.latest.${item.demGroupCode}.description`}
                        values={{
                          value: percentage,
                        }}
                      />
                    </Text>
                  </CaptionBody>
                </Caption>
              )
            })}
            {unknown.count > 0 && (
              <Caption>
                <Square
                  backgroundColor={baseColors.grey200}
                  borderColor={baseColors.grey400}
                />
                <CaptionBody>
                  <Text element="p" type="body" size="md" fontWeight={400}>
                    {t(`diversity.latest.${unknown.demGroupCode}.title`)}
                  </Text>
                </CaptionBody>
              </Caption>
            )}
          </Captions>
        </TradVsMinority>
      </>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
