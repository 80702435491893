import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { baseColors, space, Text } from '@fairhq/common'
import {
  OldCompanyRecommendation,
  OldCompanyRecommendationStatus,
} from 'store/oldRecommendations/types'
import { tCode } from 'utils/tCode'

import { OldRecommendationGroup } from './OldRecommendationGroup'

const StyledText = styled(Text)`
  margin-bottom: ${space[6]}px;
`

interface OldRecommendationsProps {
  oldRecommendations: OldCompanyRecommendation[]
}

export const OldRecommendations: FunctionComponent<OldRecommendationsProps> = ({
  oldRecommendations,
}) => {
  const { t } = useTranslation()
  const tArea = tCode(t)

  const notCompletedOldRecommendations = oldRecommendations.filter(
    ({ status }) => status !== OldCompanyRecommendationStatus.IMPLEMENTED
  )

  // Getting all areas from not-completed old recommendations and de-duplicating them
  // ending up with an array of areas
  const areas = notCompletedOldRecommendations
    .map(({ area }) => area)
    .filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id)
    )

  const completedOldRecommendations = oldRecommendations.filter(
    ({ status }) => status === OldCompanyRecommendationStatus.IMPLEMENTED
  )

  return (
    <>
      <StyledText
        element="h1"
        type="heading"
        size="xl"
        fontWeight={500}
        color={baseColors.grey500}
      >
        {t('oldRecommendations.title')}
      </StyledText>
      {areas.map(({ code, title, score }, index) => {
        const [topLevelArea, subArea] = (code || '').split('.')
        return (
          <OldRecommendationGroup
            key={code}
            icon={`${topLevelArea}.${subArea}`}
            title={tArea(code, 'title', title)}
            oldRecommendations={notCompletedOldRecommendations.filter(
              oldRecommendation => oldRecommendation.area.code === code
            )}
            score={(score?.percent ?? 0) / 10}
            defaultOpened={index === 0}
          />
        )
      })}
      {completedOldRecommendations.length > 0 && (
        <OldRecommendationGroup
          title={t(`strategy.completedOldRecommendations.title`)}
          oldRecommendations={completedOldRecommendations}
          score={0}
          defaultOpened={false}
        />
      )}
    </>
  )
}
