import { FunctionComponent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useUnmount } from 'react-use'

import { Spinner } from '@fairhq/common'

import { Questions } from 'components/questions/Questions'
import { useArea } from 'hooks/useArea'
import { useQuestions } from 'hooks/useQuestions'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { State } from 'store/state'

import { Layout } from '../../layout/Layout'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { AreaHeader } from '../areas/AreaHeader'

import { useAssessment } from './useAssessment'
import { Welcome } from './Welcome'

export const AssessmentQuestions: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { code } = useParams<{ code: string }>()
  const { area, question, hasNext, headers, loading, saving } = useAppSelector(
    (state: State) => ({
      area: state.areaReducer.area,
      assessment: state.questionSessionReducer.assessment,
      currentIndex: state.questionSessionReducer.currentIndex,
      question: state.questionSessionReducer.question,
      hasNext: state.questionSessionReducer.hasNext,
      headers: state.apiHeadersReducer,
      loading: state.areaReducer.assessmentsLoading,
      saving: state.assessmentReducer.saving || false,
    }),
    shallowEqual
  )
  const [started] = useState(true)
  const [completed, setCompleted] = useState(false)

  useArea()

  useQuestions()

  useUnmount(() => {
    dispatch(questionSessionActions.clear())
  })

  const { onSubmitAssessment } = useAssessment({ area })

  const isProcessing =
    (code && code !== area?.code) ||
    (started && !question) ||
    (loading && !started)

  useEffect(() => {
    if (!hasNext) {
      setCompleted(true)
    }
  }, [hasNext])

  function onSubmit({
    optionOrOptionsSelected,
    other = '',
    value = '',
    skip = false,
  }: {
    optionOrOptionsSelected?: number | number[]
    value?: string
    other?: string
    skip?: boolean
  }) {
    onSubmitAssessment({
      optionOrOptionsSelected,
      skip,
      getAssessmentToSave: (options, totalScore) =>
        ({
          areaId: area?.id,
          questionId: question?.id,
          auditQuestionId: question?.auditQuestionId,
          score: skip ? 0 : totalScore,
          assessmentOptions: skip
            ? []
            : options?.map(option => ({
                optionId: +option.id,
                score: option.score,
                analysis: option.analysis,
                auditOptionId: option.auditOptionId,
              })),
          sessionId: Number(headers.sessionId),
          companyId: Number(headers.companyId),
          accountId: Number(headers.accountId),
          value,
          other,
          skipped: skip,
        } as any),
    })
  }

  if (isProcessing) {
    return <Spinner backdrop vertical content={t('loading')} />
  }

  return (
    <Layout contentSx={{ p: 0, maxWidth: 'none' }} hideNavigation>
      <Questions
        header={<AreaHeader area={area} />}
        welcome={<Welcome area={area} completed={completed} />}
        loadingNext={saving}
        onSubmit={onSubmit}
        onPrevious={() => dispatch(questionSessionActions.previous())}
        onSkip={() => onSubmit({ skip: true })}
        started={started && !completed}
      />
    </Layout>
  )
}
