import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { StateHandler } from 'components/StateHandler'
import { Layout } from 'layout/Layout'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { AutomatedStrategy } from './AutomatedStrategy'
import { OldStrategy } from './oldStrategy/OldStrategy'

export const Strategy: FunctionComponent = () => {
  const { t } = useTranslation()
  const { companyId } = useAppSelector((state: State) => ({
    companyId: state.apiHeadersReducer.companyId,
  }))

  const {
    data: companyEntitlements,
    isLoading,
    isSuccess,
    isError,
  } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  return (
    <Layout dark={!isLoading}>
      <StateHandler
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage={t('error.somethingWrong.fallback')}
      >
        {companyEntitlements?.entitlements.includes(
          EntitlementsEnum.STRATEGY_INSIGHTS
        ) ? (
          <AutomatedStrategy />
        ) : (
          <OldStrategy />
        )}
      </StateHandler>
    </Layout>
  )
}
