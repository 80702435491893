import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import fetch from 'node-fetch'

import { config, sanitize } from '@fairhq/common'

import { getDefaultHeaders } from './getDefaultHeaders'

const { baseUrl } = config

const getHeadersWithJSON = (getState: () => unknown) => {
  const headers = {
    ...getDefaultHeaders(getState),
    'Content-Type': 'application/json',
  }

  return headers
}

export const handleError = async (response: any) => {
  if (response.status >= 400 && response.status < 600) {
    const { message } = await response.json()
    let error = { name: response.statusText, message: response.statusText }
    if (_.isArray(message)) {
      const firstMessage = message?.[0]?.messages?.[0]
      error = {
        name: firstMessage?.id || error.name,
        message: firstMessage?.message || error.name,
      } as Error
    }
    throw error
  }
  return response
}

export const http = {
  get(getState: () => unknown, url: string, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'GET',
      headers: { ...getHeadersWithJSON(getState), ...headers },
    }).then(handleError)
  },
  getWithAxios(
    getState: () => unknown,
    url: string,
    options: AxiosRequestConfig = {}
  ) {
    return axios.get(`${baseUrl}${url}`, {
      ...options,
      headers: { ...getDefaultHeaders(getState), ...options.headers },
    })
  },
  post(getState: () => unknown, url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'POST',
      headers: { ...getHeadersWithJSON(getState), ...headers } as any,
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  postWithAxios(
    getState: () => unknown,
    url: string,
    data: any,
    options: AxiosRequestConfig = {}
  ) {
    return axios.post(`${baseUrl}${url}`, data, {
      ...options,
      headers: { ...getDefaultHeaders(getState), ...options.headers } as any,
    })
  },
  put(getState: () => unknown, url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'PUT',
      headers: { ...getHeadersWithJSON(getState), ...headers },
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  putWithAxios(
    getState: () => unknown,
    url: string,
    data: any,
    options: AxiosRequestConfig = {}
  ) {
    return axios.put(`${baseUrl}${url}`, data, {
      ...options,
      headers: { ...getDefaultHeaders(getState), ...options.headers } as any,
    })
  },
  patch(getState: () => unknown, url: string, data: any, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'PATCH',
      headers: { ...getHeadersWithJSON(getState), ...headers },
      body: JSON.stringify(sanitize(data)),
    }).then(handleError)
  },
  delete(getState: () => unknown, url: string, headers = {}) {
    return fetch(`${baseUrl}${url}`, {
      method: 'DELETE',
      headers: { ...getHeadersWithJSON(getState), ...headers },
    }).then(handleError)
  },
}
