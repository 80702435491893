import { FunctionComponent } from 'react'

import { Container, Input } from 'rsuite'

export interface NumberInputProps {
  onChange: (value: string) => void
  value: string | undefined
  placeholder?: string
}

export const NumberInput: FunctionComponent<NumberInputProps> = ({
  onChange,
  value,
  placeholder,
}) => (
  <Container>
    <Input
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type="number"
      size="lg"
    />
  </Container>
)
