import React, { FunctionComponent } from 'react'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'

import { GraphItem } from 'components/visualization/types'
import { useAuditBy } from 'hooks/useAuditBy'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'

import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { DemGroupType } from '../types'

import { AnswerBreakdown } from './AnswerBreakdown'
import { ScoreHeader } from './ScoreHeader'

interface ReportsExperienceProps {
  code: string
}

export const ReportsExperience: FunctionComponent<ReportsExperienceProps> = ({
  code,
}) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })
  const { isSurveyCompleted } = useAuditBy(sessionId, apiVersion)
  const {
    data: experienceData,
    isLoading,
    isSuccess,
    isError,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'experience',
    apiVersion,
    sessionId,
  })
  const { experience, questions, questionsGaps } =
    experienceData?.experience ?? {}

  const getQuestionsByType = (
    type: string,
    questionId: number,
    overview?: DemGroupType
  ) =>
    questions
      ?.filter(
        ({ _type, question_id }: any) =>
          _type === type && questionId === question_id
      )
      ?.filter(({ _type, _group }: any) =>
        _type === 'general' ? true : _group
      )
      .map(
        ({
          _group,
          dem_group_title,
          question_max,
          question_score,
          question_minority_score,
          question_majority_score,
        }: any) => ({
          key: _group + Math.random(),
          value:
            ((overview
              ? (overview === DemGroupType.MINORITY &&
                  question_minority_score) ||
                question_majority_score
              : question_score) *
              100) /
            question_max,
          label: tCode(t)(
            `reports.${type}.${_group}`,
            '',
            dem_group_title || _group
          ),
        })
      )

  const getQuestionsExperience = (
    questionId: number
  ): Record<string, GraphItem[]> => {
    const [traditionalMajorityScore] = getQuestionsByType(
      'general',
      questionId,
      DemGroupType.MAJORITY
    )
    const [traditionalMinorityScore] = getQuestionsByType(
      'general',
      questionId,
      DemGroupType.MINORITY
    )
    const gapBetweenMajorityAndMinority = traditionalMajorityScore?.value
      ? (((traditionalMinorityScore?.value ?? 0) -
          traditionalMajorityScore.value) *
          100) /
        traditionalMajorityScore.value
      : 0
    const overview = [
      {
        key: 'traditionalMajority',
        value: traditionalMajorityScore?.value,
        label: t('reports.overview.traditionalMajority'),
      },
      {
        key: 'traditionalMinority',
        value: traditionalMinorityScore?.value,
        label: t('reports.overview.traditionalMinority'),
      },
    ]

    return {
      overview,
      byEmployeeGroups: getQuestionsByType('demGroups', questionId).concat(
        overview
      ),
      byJobLevel: getQuestionsByType('jobLevel', questionId),
      byDepartment: getQuestionsByType('department', questionId),
      byLocation: getQuestionsByType('location', questionId),
      byGaps: questionsGaps
        ?.filter((question: any) => question.question_id === questionId)
        .map(
          ({
            majority_score,
            minority_code,
            minority_score,
            minority_title,
            question_max,
          }: any) => ({
            key: minority_code,
            value:
              ((minority_score / question_max - majority_score / question_max) *
                100) /
              (majority_score / question_max),
            label: tCode(t)(minority_score, '', minority_title),
          })
        )
        .concat({
          key: 'traditionalMinority',
          value: gapBetweenMajorityAndMinority,
          label: t('reports.overview.traditionalMinority'),
        }),
    }
  }

  const generalExperienceForEquality = experience?.find(
    ({ _type }: any) => _type === 'general'
  )
  const generalScoreEquality =
    // eslint-disable-next-line no-unsafe-optional-chaining
    (generalExperienceForEquality?.score_calculation * 10) /
      // eslint-disable-next-line no-unsafe-optional-chaining
      generalExperienceForEquality?.area_max ?? 0

  const isInclusionArea = code?.includes('inclusion.')

  if (questions?.length === 0 || (!isScorable && !isSurveyCompleted)) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <ScoreHeader
        title={
          isInclusionArea
            ? t('reports.questions.title')
            : t('reports.experience.title')
        }
        score={isInclusionArea ? undefined : generalScoreEquality}
        icon={isInclusionArea ? undefined : 'customer'}
      />
      {questions
        ?.filter(({ _type }: any) => _type === 'general')
        .map(
          ({
            question_code,
            question_id,
            question_max,
            question_score,
            question_title,
          }: any) => (
            <AnswerBreakdown
              key={question_code}
              answer={question_title}
              score={round((question_score * 10) / question_max, 1)}
              items={getQuestionsExperience(question_id)}
            />
          )
        )}
    </StateHandler>
  )
}
