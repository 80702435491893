import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useGetAvailableCampaignsQuery } from '../campaignsApi'
import { Campaign } from '../types'

export const useAvailableCampaign = (id: number): Campaign | undefined => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { availableCampaign } = useGetAvailableCampaignsQuery(
    { sessionId },
    {
      selectFromResult: ({ data }) => ({
        availableCampaign: data?.find(
          (campaign: Campaign) => campaign.id === id
        ),
      }),
    }
  )
  return availableCampaign
}
