import { FunctionComponent } from 'react'

import { Grid } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'

import { useAppSelector } from 'store/hooks'

import { State } from 'store/state'

import { formatSpan } from 'utils/formatSpan'

import { useGetInsightsQuery } from './insightsApi'

const InsightCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  background-color: ${baseColors.white};
  border-radius: 16px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 4%), 0 2px 6px rgba(0, 0, 0, 4%);
`

const InsightsHeader = styled.div`
  border-radius: 20px 20px 0 0;
  background-color: ${baseColors.white};
  padding: 24px 32px;
`

const InsightsCard = styled(Grid)`
  border-radius: 0 0 20px 20px;
  background-color: ${baseColors.grey100};
  padding: 32px;
`

const InsightText = styled(Text)`
  margin: 0;
  color: ${baseColors.purple800};
`

const InsightAreasText = styled(Text)`
  margin: 0;
  color: ${baseColors.coolGrey700};
`

interface InsightsProps {
  span: number
}

export const Insights: FunctionComponent<InsightsProps> = ({ span }) => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { t } = useTranslation()
  const { data: companyInsights, isSuccess } = useGetInsightsQuery({
    sessionId,
  })

  //
  if (isSuccess && companyInsights?.length > 0) {
    return (
      <Grid
        sx={{
          gridGap: 0,
          gridColumn: formatSpan(span),
          boxShadow: `0 0 2px 1px rgba(0, 0, 0, 4%),
        0 2px 6px rgba(0, 0, 0, 4%)`,
          borderRadius: '20px',
        }}
      >
        <InsightsHeader>
          <Text element="p" type="body" size="lg" fontWeight={700}>
            Insights
          </Text>
        </InsightsHeader>
        <InsightsCard>
          {companyInsights.map(companyInsight => (
            <InsightCard key={companyInsight.id}>
              <InsightText element="p" type="body" size="md" fontWeight={600}>
                {companyInsight.insight.description}
              </InsightText>
              <InsightAreasText
                element="p"
                type="body"
                size="sm"
                fontWeight={600}
              >
                {companyInsight.insight.areas
                  .map(area => t(area.title))
                  .join(', ')}
              </InsightAreasText>
            </InsightCard>
          ))}
        </InsightsCard>
      </Grid>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
