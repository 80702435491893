import React, { FunctionComponent } from 'react'

import { ActionBar } from 'features/editStrategy/ActionBar'

import { useGenerateNewStrategyMutation } from 'store/strategy/strategyApi'

export const StrategyDueBar: FunctionComponent = () => {
  const [generateNewstrategy, { isLoading, isError }] =
    useGenerateNewStrategyMutation()
  return (
    <ActionBar
      nextLabel="strategy.due.button"
      descriptionLabel="strategy.due.barDescription"
      onNextClick={() => generateNewstrategy()}
      isLoading={isLoading}
      isError={isError}
      isLast
    />
  )
}
