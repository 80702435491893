import { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Clipboard, space, ClipboardArrow } from '@fairhq/common'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { RoundButton } from 'styled'
import { ProgressBar } from 'ui-kit/ProgressBar'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledClipboardArrow = styled(ClipboardArrow)`
  margin-right: ${space[2]}px;
`

export const StartAudit: FunctionComponent = () => {
  const { t } = useTranslation()
  const { companyName } = useAppSelector(
    (state: State) => ({
      companyName: state.companyReducer.company?.name,
    }),
    shallowEqual
  )
  return (
    <>
      <LabelContainer>
        <Clipboard outline={baseColors.purple} />
        <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
          {t(`statusOverview.audit`)}
        </Text>
      </LabelContainer>
      <Text variant="title" sx={{ mb: 2, fontSize: 24, display: 'block' }}>
        <Trans
          i18nKey="statusOverview.percentageCompleted"
          values={{
            percentageCompleted: 0,
          }}
        />
      </Text>
      <ProgressBar value={0} />
      <Text
        sx={{
          mt: 2,
          mb: 4,
          color: baseColors.grey400,
          fontSize: 16,
          display: 'block',
        }}
      >
        <Trans
          i18nKey="statusOverview.start.description"
          values={{
            companyName,
          }}
        />
      </Text>
      <ButtonContainer>
        <RoundButton componentClass={NavLink} to="/audit">
          <StyledClipboardArrow outline={baseColors.grey300} />
          {t(`statusOverview.start.cta`)}
        </RoundButton>
      </ButtonContainer>
    </>
  )
}
