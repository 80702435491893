import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import Sidebar from 'layout/Sidebar'

import { ReportsSidebarMenuGroup } from './hooks/useReportsSidebarGenAndSub'

import { MenuGroup } from './MenuGroup'

interface MenuGroupsProps {
  items: ReportsSidebarMenuGroup[]
}

export const MenuGroups: FunctionComponent<MenuGroupsProps> = ({ items }) => (
  <Sidebar sx={{ py: 6 }}>
    <Box sx={{ position: 'sticky', bottom: 0 }}>
      <Box sx={{ mb: 5 }}>
        {items?.map(groupProps => (
          <MenuGroup key={groupProps.text} {...groupProps} />
        ))}
      </Box>
    </Box>
  </Sidebar>
)
