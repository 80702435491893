import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { useUpdateInsightMutation } from 'features/insights/insightsApi'
import { CompanyInsight } from 'features/insights/types'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'

import { AddOrRemoveButton } from './AddOrRemoveButton'

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  background-color: ${baseColors.white};
  border-radius: 20px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
  position: relative;
`

const StyledText = styled(Text)`
  padding: 24px;
`

interface InsightEditableProps {
  companyInsight: CompanyInsight
}

export const InsightEditable: FunctionComponent<InsightEditableProps> = ({
  companyInsight,
}) => {
  const { data: strategy, isSuccess: isStrategySucess } = useGetStrategyQuery()

  const [updateInsight] = useUpdateInsightMutation()

  return (
    <>
      {isStrategySucess && (
        <ListItem>
          <StyledText
            element="p"
            type="body"
            size="md"
            fontWeight={500}
            color={baseColors.purple900}
          >
            {companyInsight.insight.description}
          </StyledText>
          {companyInsight.strategyId ? (
            <AddOrRemoveButton
              variant="remove"
              title="Remove insight from strategy"
              onClick={() =>
                updateInsight({
                  id: companyInsight.id,
                  strategyId: null,
                })
              }
            />
          ) : (
            <AddOrRemoveButton
              variant="add"
              title="Add insight to strategy"
              onClick={() =>
                updateInsight({
                  id: companyInsight.id,
                  strategyId: strategy.id,
                })
              }
            />
          )}
        </ListItem>
      )}
    </>
  )
}
