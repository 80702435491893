import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

export const LeftColumn: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => <Container>{children}</Container>
