import React, { useContext } from 'react'

import { Flex } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Animation, Container } from 'rsuite'

import styled from 'styled-components'

import { baseColors, Spinner } from '@fairhq/common'
import { Subtitle } from 'styled'
import { tCode } from 'utils/tCode'

import { DocumentsContext } from '../DocumentUpload'

const DocumentTypeCardContainer = styled(Container)`
  position: sticky;
  top: 180px;
  max-width: 480px;
  word-break: break-word;

  ${Subtitle} {
    margin-bottom: 18px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .markdown {
    color: ${({ theme }) => theme.colors.grey400};
    margin-bottom: 36px;
  }
`

export const DocumentTypeCard = () => {
  const { t } = useTranslation()
  const tDocument = tCode(t)
  const { currentIndex: index, type, skipped } = useContext(DocumentsContext)

  if (!type) {
    return <Spinner backdrop vertical content={t('loading')} />
  }

  return (
    <Animation.Slide in placement="bottom">
      {(props, ref) => (
        <DocumentTypeCardContainer ref={ref} {...props} key={type?.code}>
          {skipped ? (
            <Flex
              as="p"
              sx={{
                color: baseColors.purple800,
                backgroundColor: baseColors.purple200,
                padding: '4px 16px',
                borderRadius: '30px',
                display: 'inline-flex',
                marginBottom: '18px',
                strong: { marginRight: '5px' },
              }}
            >
              <Trans i18nKey="document.skipped" components={[<strong />]} />
            </Flex>
          ) : undefined}
          <Subtitle>{t('document.number', { number: index + 1 })}</Subtitle>
          <h2>{tDocument(type?.code, 'title', type?.title)}</h2>

          <ReactMarkdown className="markdown">
            {tDocument(type?.code, 'description', type?.description)}
          </ReactMarkdown>
        </DocumentTypeCardContainer>
      )}
    </Animation.Slide>
  )
}
