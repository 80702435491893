import React, { FunctionComponent } from 'react'

interface EllipsisProps {
  className?: string
  stroke?: string
  fill?: string
}

export const Ellipsis: FunctionComponent<EllipsisProps> = ({
  className,
  stroke = 'currentColor',
  fill = 'currentColor',
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="4" cy="8" r="1" fill={fill} stroke={stroke} />
    <circle cx="8" cy="8" r="1" fill={fill} stroke={stroke} />
    <circle cx="12" cy="8" r="1" fill={fill} stroke={stroke} />
  </svg>
)
