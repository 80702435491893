import React, { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { useOpenable } from 'hooks/useOpenable'
import Icon from 'ui-kit/Icon'

import { Score } from './Score'

interface BreakdownProps {
  title: React.ReactNode
  score?: number
  max?: number
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

export const Breakdown: FunctionComponent<BreakdownProps> = ({
  title,
  score,
  max,
  sx,
  children,
}) => {
  const { opened, toggle } = useOpenable()

  return (
    <Box
      sx={{
        bg: 'white',
        borderRadius: 20,
        '& + &': {
          mt: 2,
        },
        ...sx,
      }}
    >
      <Flex
        role="button"
        onClick={toggle}
        sx={{
          px: 8,
          height: 84,
          alignItems: 'center',
          justifyContent: 'space-between',
          userSelect: 'none',
          cursor: 'pointer',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Text as="div" sx={{ color: 'grey500' }}>
            {title}
          </Text>
          <Icon
            name={opened ? 'arrowDown' : 'arrowRight'}
            sx={{ ml: 2 }}
            small
          />
        </Flex>
        {score ? <Score score={score} max={max} /> : undefined}
      </Flex>

      {opened ? children : undefined}
    </Box>
  )
}
