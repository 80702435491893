import { Container } from 'rsuite'
import styled, { css } from 'styled-components'

import { LinkButton } from 'styled'

import { ListCell, ListRow } from './list'

export const Count = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: normal;
`

export const DataListHeaderContainer = styled(Container)`
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.grey100};
  border-radius: 20px 20px 0 0;
  min-height: 110px;
  padding: 0 48px 0 30px;
  ${LinkButton} svg {
    margin-right: 12px;
  }

  h3 {
    font-size: 18px !important;
    word-break: break-word;
  }

  .rs-input-group {
    align-items: center;
    max-width: 360px;
    height: 54px;

    > .rs-input-group-addon {
      top: 3px;
    }

    > input.rs-input {
      height: 54px;
      padding-left: 42px !important;
    }
  }
`

export const DataListFooterContainer = styled(Container)<{ hidden: boolean }>`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};

  p {
    margin-bottom: 0;
    height: 64px;
    line-height: 64px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey200};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey400};
  }

  .rs-loader-wrapper {
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
`

export const DataListContainer = styled(Container)<{ preview: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.grey200_1};
  border-radius: 20px;

  ${DataListHeaderContainer} {
    display: ${({ preview }) => (preview ? 'none' : 'flex')};
  }

  ${({ preview, theme }) =>
    preview
      ? css`
          ${ListRow}:first-of-type {
            border-radius: 20px 20px 0 0;
            background-color: ${theme.colors.grey100};
          }

          ${ListCell} {
            padding-left: 40px;
          }
        `
      : undefined}
`
