import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useDocumentProgress } from './useDocumentProgress'

export const useTitle = () => {
  const { t } = useTranslation()
  const { audit } = useAppSelector(
    (state: State) => ({
      audit: state.auditReducer.status,
    }),
    shallowEqual
  )

  const { percent: documentsProgress, total: documentsTotal } =
    useDocumentProgress()

  const companyAssessmentProgress = (audit?.company?.progress ?? 0) * 100

  const percent =
    documentsTotal > 0
      ? Math.round((companyAssessmentProgress + documentsProgress) / 2)
      : Math.round(companyAssessmentProgress) || 0

  const completed = percent === 100

  return {
    title: t('areas.assessment'),
    status: {
      title: `${percent}%`,
      type: completed ? 'completed' : (percent > 0 && 'reviewing') || 'pending',
    },
    done: completed,
    percent,
  }
}
