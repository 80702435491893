import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { space } from '@fairhq/common'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    padding-top: ${space[1]}px;
  }
`

export const Footer: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => <Container>{children}</Container>
