import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

export interface ThemeProps {
  themeCode: string
  color: string
}

export const Theme: FunctionComponent<ThemeProps> = ({ themeCode, color }) => {
  const { t } = useTranslation()
  return (
    <Text as="div" variant="caps" color={color} sx={{ mb: 2 }}>
      {t(`themes.${themeCode}.title`)}
    </Text>
  )
}
