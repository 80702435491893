import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { SurveyWindowPrompt } from './SurveyWindowPrompt'
import { SurveyWindowsArchive } from './SurveyWindowsArchive'

const Container = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`
const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SurveyWindow = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <Text
          element="h1"
          type="heading"
          size="2xl"
          fontWeight={400}
          color={baseColors.coolGrey900}
        >
          {t('surveys.title')}
        </Text>
        <Text element="p" type="body" size="sm" fontWeight={500}>
          {t('surveys.description')}
        </Text>
      </Header>
      <SurveyWindowPrompt />
      <SurveyWindowsArchive />
    </Container>
  )
}
