import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { CompanyInsightWithoutAreas } from 'features/insights/types'
import { CompanyInitiative } from 'store/initiatives/types'
import { Task as TaskType } from 'store/tasks/types'

import { findInsightsAndTasks } from 'utils/findInsightsAndTasks'

import { Initiative } from './Initiative'

const Container = styled.article<{ greyedOut: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background-color: ${({ greyedOut }) =>
    greyedOut ? baseColors.coolGrey300 : baseColors.white};
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
`

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface InitiativesProps {
  companyInitiatives: CompanyInitiative[]
  companyInsights: CompanyInsightWithoutAreas[]
  companyTasks: TaskType[]
  greyedOut?: boolean
}

export const Initiatives: FunctionComponent<InitiativesProps> = ({
  companyInitiatives,
  companyInsights,
  companyTasks,
  greyedOut = false,
}) => {
  const { t } = useTranslation()

  return (
    <SectionWrapper>
      <Text
        element="h2"
        type="body"
        size="lg"
        fontWeight={700}
        color={baseColors.black}
      >
        {t('strategy.actionPlan.title')}
      </Text>

      <PlanWrapper>
        {companyInitiatives.map(companyInitiative => {
          const { relevantCompanyInsights, tasks } = findInsightsAndTasks({
            insightsCode: companyInitiative.insightsCode,
            recommendation: companyInitiative.recommendation,
            companyInsights,
            companyTasks,
          })
          return (
            <Container key={companyInitiative.id} greyedOut={greyedOut}>
              <Initiative
                recommendation={companyInitiative.recommendation}
                companyInsights={relevantCompanyInsights}
                greyedOut={greyedOut}
                companyTasks={tasks}
              />
            </Container>
          )
        })}
      </PlanWrapper>
    </SectionWrapper>
  )
}
