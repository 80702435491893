import { shallowEqual } from 'react-redux'

import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export enum Roles {
  ADMIN = 'Admin',
  DVC_ADMIN = 'DVCAdmin',
  OWNER = 'Owner',
}

export const useAuthRoles = () => {
  const { roles } = useAppSelector(
    (state: State) => ({
      roles: state.authReducer.user?.app_metadata?.roles ?? [],
    }),
    shallowEqual
  )

  return {
    isDVCAdmin: roles?.includes(Roles.DVC_ADMIN),
    isFHQAdmin: roles?.includes(Roles.ADMIN),
    isAdmin: roles?.includes(Roles.ADMIN) || roles?.includes(Roles.DVC_ADMIN),
    isOwner: roles?.includes(Roles.OWNER),
  }
}
