import { baseColors } from '@fairhq/common'

export const getScoreColor = (score: number) => {
  if (score >= 7.5) {
    return baseColors.green
  }

  if (score >= 5) {
    return baseColors.yellow
  }

  return baseColors.red
}
