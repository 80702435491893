import { FunctionComponent, useState } from 'react'

import _, { difference } from 'lodash'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import { FileType } from 'rsuite/es/Uploader'
import styled from 'styled-components'

import { MessageBox } from '../../components'

import { ReactComponent as ErrorIcon } from './assets/error.svg'
import { ReactComponent as FileIcon } from './assets/file.svg'
import { ReactComponent as SuccessIcon } from './assets/success.svg'

import { parseCSVToEmployees, supportedHeaders } from './utils'

const allowedFileTypes = ['text/csv', 'application/vnd.ms-excel', 'text/x-csv']

const FilePreview = styled.div<{ status: 'uploading' | 'error' | 'success' }>`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 172px;
  max-width: 650px;
  margin: auto auto 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 48px 0 80px;

  div {
    display: flex;
    margin-left: 36px;
    flex-grow: 1;
    align-items: baseline;
    flex-flow: column wrap;

    h3 {
      color: ${({ theme, status }) =>
        status === 'error'
          ? theme.colors.red
          : status === 'success'
          ? theme.colors.green
          : 'inherit'};
      margin: 0;
    }
  }
`
interface CSVPreviewProps {
  files: FileType[]
  clear: () => void
  next: () => void
  setEmployees: (employees: any[]) => void
}

export const CSVPreview: FunctionComponent<CSVPreviewProps> = ({
  clear,
  files,
  next,
  setEmployees,
}) => {
  const { t } = useTranslation()
  const [fileType] = files
  const [error, setError] = useState('')
  const [uploading, setUploading] = useState(true)
  const status = uploading ? 'uploading' : (error && 'error') || 'success'
  const authorizedPositions = ['manager', 'leadership', 'employee']

  useMount(() => {
    if (
      !fileType.blobFile ||
      !allowedFileTypes.includes(fileType.blobFile?.type)
    ) {
      setError('employees.addEmployees.error.fileTypeInvalid')
      setUploading(false)
    } else {
      Papa.parse<any>(fileType.blobFile, {
        header: true,
        skipEmptyLines: true,
        error(parseError) {
          setError(t(parseError?.name, { defaultValue: parseError.message }))
          setUploading(false)
        },
        complete(results) {
          const sanitizedResults = results.data.filter(({ email }) =>
            email.trim().toLowerCase()
          )
          const [firstRow] = sanitizedResults
          const rowsWithInvalidPosition = sanitizedResults.filter(
            ({ level }) =>
              !(level && authorizedPositions.includes(level?.toLowerCase()))
          )
          const hasUniqueEmails =
            _.uniq(sanitizedResults.map(({ email }) => email)).length ===
            sanitizedResults.length
          const hasMissingValuesAtIndex = sanitizedResults.findIndex(
            ({ firstName, lastName, email, level }) =>
              !firstName || !lastName || !email || !level
          )

          if (hasMissingValuesAtIndex !== -1) {
            setError(
              t('employees.addEmployees.error.missingValuesAtRow', {
                row: hasMissingValuesAtIndex + 2,
              })
            )
          } else if (
            difference(Object.keys(firstRow), supportedHeaders).length !== 0
          ) {
            setError(
              t('employees.addEmployees.error.columnsNotSupported', {
                expected: supportedHeaders.join(', '),
                result: Object.keys(firstRow).join(', '),
              })
            )
          } else if (rowsWithInvalidPosition.length > 0) {
            setError(
              t('employees.addEmployees.error.invalidPositions', {
                position: authorizedPositions.join(', '),
              })
            )
          } else if (!hasUniqueEmails) {
            setError(t('employees.addEmployees.error.emailsAreNotUnique'))
          } else {
            setEmployees(sanitizedResults.map(parseCSVToEmployees))
          }

          setUploading(false)
        },
      })
      setUploading(false)
    }
  })

  const btnByStatus: Record<typeof status, any> = {
    error: { label: t('employees.addEmployees.tryAgain'), onClick: clear },
    success: {
      label: t('employees.addEmployees.continueToReview'),
      onClick: next,
    },
    uploading: undefined,
  }

  return (
    <MessageBox
      btnLabel={btnByStatus[status]?.label}
      onClick={btnByStatus[status]?.onClick}
    >
      <FilePreview status={status}>
        <FileIcon />
        <div>
          <h3>{t(`upload.${status}`)}</h3>
          <span>{status === 'error' ? error : fileType.blobFile?.name}</span>
        </div>
        {status === 'error' ? <ErrorIcon /> : undefined}
        {status === 'success' ? <SuccessIcon /> : undefined}
      </FilePreview>
    </MessageBox>
  )
}
