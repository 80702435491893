import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import headerImageBlue from './svgs/strategyHeaderBlue.svg'
import headerImageGrey from './svgs/strategyHeaderGrey.svg'

const Container = styled.section<{
  greyedOut: boolean
  imageUrl: string
}>`
  background-color: ${({ greyedOut }) =>
    greyedOut ? baseColors.coolGrey700 : baseColors.purple800};
  border-radius: 20px;
  padding: 48px 40px;
  color: ${baseColors.white};
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

interface StrategyWithInsightsIntroProps {
  greyedOut?: boolean
}

export const StrategyIntro: FunctionComponent<
  StrategyWithInsightsIntroProps
> = ({ greyedOut = false }) => {
  const { t } = useTranslation()
  return (
    <Container
      greyedOut={greyedOut}
      imageUrl={greyedOut ? headerImageGrey : headerImageBlue}
    >
      <StyledText element="p" fontWeight={700} type="body" size="xs">
        {t('strategy.header.label')}
      </StyledText>
      <Text element="h1" fontWeight={500} type="heading" size="xl">
        {t('strategy.header.title')}
      </Text>
      <Text element="p" fontWeight={400} type="body" size="sm">
        {t('strategy.header.description')}
      </Text>
    </Container>
  )
}
