import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

export interface DescriptionProps {
  campaignCode: string
}

export const Description: FunctionComponent<DescriptionProps> = ({
  campaignCode,
}) => {
  const { t } = useTranslation()
  return (
    <Text as="div" sx={{ mb: 1, mt: 1 }}>
      {t(`campaigns.${campaignCode}.description`)}
    </Text>
  )
}
