import { useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'

import { checkRole } from 'store/account/accountSlice'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

export function useCheckPermissions() {
  const dispatch = useAppDispatch()
  const { user, userVerified, hasPermissions, checking, checked } =
    useAppSelector(
      (state: State) => ({
        userVerified: state.authReducer.user?.email_verified,
        user: state.authReducer.user,
        hasPermissions: state.authReducer.hasPermissions,
        checking: state.accountReducer.isCheckRolesLoading,
        checked: state.accountReducer.roleChecked,
      }),
      shallowEqual
    )
  const [hardRefresh, setHardRefresh] = useState(false)
  const [permissionsChecked, setPermissionsChecked] = useState(false)
  const [bootstrapped, setBootstrapped] = useState(false)
  const isUserWithoutPermissions =
    user && userVerified && hasPermissions === false
  const shouldCheckRole =
    isUserWithoutPermissions && !hardRefresh && !checking && !bootstrapped

  useEffect(() => {
    if (shouldCheckRole) {
      setBootstrapped(true)
      dispatch(checkRole())
    }
  }, [dispatch, shouldCheckRole])

  useEffect(() => {
    if (checked && bootstrapped) {
      setHardRefresh(true)
    }
  }, [checked, bootstrapped])

  useEffect(() => {
    if (hasPermissions) {
      setPermissionsChecked(true)
    }
  }, [hasPermissions])

  useEffect(() => {
    if (hardRefresh) {
      window.location.reload()
    }
  }, [hardRefresh])

  return permissionsChecked
}
