import { FunctionComponent } from 'react'

import i18next from 'i18next'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { useUser } from 'features/campaigns/hooks/useUser'

import { toDate } from 'utils/dates'

import { Answer, AssessmentChanges } from './types'

const ListItem = styled.li`
  font-size: 14px;
  &:not(:last-of-type) {
    padding-bottom: 8px;
  }
`
const StyledSpan = styled.span`
  font-weight: bold;
  font-size: inherit;
`

interface AssessmentChangeProps {
  change: AssessmentChanges
}
export const AssessmentChange: FunctionComponent<AssessmentChangeProps> = ({
  change,
}) => {
  const { nickname } = useUser(change.userId)

  const getValidResponse = (answer: Answer) => {
    const other = answer.other ? ` - ${answer.other}` : ''

    const displayAnswer = answer.skipped
      ? 'skipped'
      : answer.value
      ? answer.value
      : answer.options.map(option => option.title).join(', ')

    return other ? displayAnswer.concat(other) : displayAnswer
  }

  return (
    <ListItem>
      <Trans
        i18nKey="settings.assessments.item"
        values={{
          question: change.metadata.questionAnswered.title,
          from: getValidResponse(
            change.metadata.questionAnswered.previousAnswer
          ),
          to: getValidResponse(change.metadata.questionAnswered.newAnswer),
          user: nickname ? `by ${nickname}` : '',
          date: toDate(i18next.language, change.createdAt).format('LLL'),
        }}
        components={[<StyledSpan />]}
      />
    </ListItem>
  )
}
