import { FunctionComponent } from 'react'

import { Flex, Text, FlexProps } from '@theme-ui/components'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { baseColors, space } from '@fairhq/common'

import { Score } from './Score'

const StyledLink = styled(Link)`
  &:not(:last-of-type) {
    margin-bottom: ${space[2]}px;
  }

  display: inline-block;
  width: 100%;
  color: ${baseColors.grey500};
`

export type QuestionProps = {
  question: {
    content: string
    area: string
    score: number
    max?: number
  }
  parentAreaCode: string | null
} & FlexProps

export const Question: FunctionComponent<QuestionProps> = ({
  question,
  sx,
  parentAreaCode,
}) => (
  <StyledLink to={`/reports/inclusion/${parentAreaCode}`}>
    <Flex
      sx={{
        bg: 'white',
        borderRadius: 20,
        px: 6,
        py: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        '& + &': { mt: 2 },
        ...sx,
      }}
    >
      <Text as="div" sx={{ flex: '10 1 auto', fontWeight: 'normal' }}>
        {question.content}
      </Text>

      <Flex sx={{ alignItems: 'center' }}>
        <Text as="div" variant="caps" sx={{ whiteSpace: 'nowrap' }}>
          {question.area}
        </Text>

        <Score score={question.score} />
      </Flex>
    </Flex>
  </StyledLink>
)
