import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, Text } from '@fairhq/common'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { useGetSurveyWindowQuery } from 'store/surveyWindow/surveyWindowApi'

import { SurveyWindowStatus } from 'store/surveyWindow/types'

import { Employee } from './types'

const SendButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

interface SurveyButtonsProps {
  selectedEmployees: Employee[]
  setShowConfirmSendAll: React.Dispatch<React.SetStateAction<boolean>>
  setShowConfirmSendSelected: React.Dispatch<React.SetStateAction<boolean>>
}

export const SurveyButtons: FunctionComponent<SurveyButtonsProps> = ({
  setShowConfirmSendAll,
  setShowConfirmSendSelected,
  selectedEmployees,
}) => {
  const { t } = useTranslation()
  const { data: companyEntitlements } = useGetEntitlementsQuery()
  const { data: currentSurveyWindow } = useGetSurveyWindowQuery()

  if (
    (companyEntitlements?.entitlements.includes(EntitlementsEnum.SURVEYS) &&
      currentSurveyWindow?.status === SurveyWindowStatus.OPEN) ||
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT
    )
  ) {
    return (
      <SendButtonsContainer>
        <Text element="label" type="body" size="sm" fontWeight={600}>
          {t('employees.sendTo')}
        </Text>
        <Button variant="tertiary" onClick={() => setShowConfirmSendAll(true)}>
          <Trans
            i18nKey="employees.allIncomplete"
            values={{ total: selectedEmployees.length }}
          />
        </Button>
        <Button
          variant="tertiary"
          onClick={() => setShowConfirmSendSelected(true)}
        >
          <Trans
            i18nKey="employees.allSelected"
            values={{ total: selectedEmployees.length }}
          />
        </Button>
      </SendButtonsContainer>
    )
  }
  return null
}
