import React, { FunctionComponent, useState, useEffect } from 'react'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import { SlackChannel } from 'features/campaigns/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useEditCampaignMutation } from '../../campaignsApi'
import { useAvailableCampaign } from '../../hooks/useAvailableCampaign'

import { DateModal } from './Campaign.DateModal'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

// initialPublishTime comes in UTC
// We need to display it in the user's timezone
const formatInitialPublishTime = (initialPublishTime: string) => {
  const hourUtc = parseInt(initialPublishTime.substring(0, 2), 10)
  const dateUtc = dayjs().utc().hour(hourUtc).minute(0).second(0).format()
  return new Date(dateUtc)
}
interface EditModalProps {
  onClose: () => void
  initialStartDate: string
  initialPublishTime: string
  scheduledCampaignId: number
  campaignId: number
  initialChannel: SlackChannel
}

export const EditModal: FunctionComponent<EditModalProps> = ({
  onClose,
  initialStartDate,
  initialPublishTime,
  scheduledCampaignId,
  campaignId,
  initialChannel,
}) => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const [startDate, setStartDate] = useState<Date>(new Date(initialStartDate))
  const [publishTime, setPublishTime] = useState<Date>(
    new Date(formatInitialPublishTime(initialPublishTime))
  )
  const [channel, setChannel] = useState<SlackChannel>(initialChannel)

  const [editCampaign, { isLoading, isError, isSuccess }] =
    useEditCampaignMutation()

  useEffect(() => {
    if (isSuccess && !isLoading) {
      onClose()
    }
  }, [isSuccess, isLoading, onClose])

  const { totalNudges, frequency, code } =
    useAvailableCampaign(campaignId) || {}

  const formattedStartDate: string = dayjs
    .utc(startDate)
    .startOf('day')
    .toISOString()

  const formattedStartTime: string = dayjs.utc(publishTime).format('kk:mm')

  return (
    <DateModal
      modalTitleKey="campaigns.edit.modalTitle"
      titleKey={`campaigns.${code}.title`}
      descriptionKey={`campaigns.${code}.description`}
      totalNudges={totalNudges}
      frequency={frequency}
      cancelKey="campaigns.edit.cancel"
      confirmKey="campaigns.edit.confirm"
      errorKey="campaigns.edit.error"
      onConfirm={() => {
        editCampaign({
          scheduledCampaignId,
          startDate: formattedStartDate,
          publishTime: formattedStartTime,
          sessionId,
          channel,
        })
      }}
      isLoading={isLoading}
      isError={isError}
      onClose={onClose}
      startDate={startDate}
      onStartDateChange={(date: Date | null) =>
        setStartDate(date ?? new Date())
      }
      publishTime={publishTime}
      onPublishTimeChange={(date: Date | null) =>
        setPublishTime(date ?? new Date())
      }
      channel={channel}
      onChannelChange={(channel: SlackChannel) => setChannel(channel)}
    />
  )
}
