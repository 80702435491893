import React, { useState } from 'react'

import { Flex, Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { Navigate } from 'react-router-dom'

import { Spinner } from '@fairhq/common'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementsEnum } from 'store/company/types'

import Steps, { Step } from '../../ui-kit/Steps'

import { AddEmployeesWhiteBox } from './components/AddEmployeesWhiteBox'
import { useCreateLinkTokenQuery } from './integrationsApi'
import { LinkAccount } from './LinkAccount'
import { SyncEmployees } from './SyncEmployees'

export const AddEmployeesWithIntegration = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [accountToken, setAccountToken] = useState('')
  const [redirect, setRedirect] = useState('')
  const { data } = useCreateLinkTokenQuery()
  const { link_token: linkToken = '' } = data ?? {}

  const {
    data: companyEntitlements,
    isSuccess,
    isLoading,
  } = useGetEntitlementsQuery()

  const steps: Step[] = [
    {
      title: t('employees.add.linkYourAccount'),
      component: (
        <LinkAccount
          linkToken={linkToken}
          next={() => setStep(1)}
          setAccountToken={setAccountToken}
        />
      ),
    },
    {
      title: t('employees.add.syncYourEmployees'),
      component: (
        <SyncEmployees
          accountToken={accountToken}
          next={() => setRedirect('/employees')}
        />
      ),
    },
  ]

  if (
    isSuccess &&
    !companyEntitlements?.entitlements.includes(
      EntitlementsEnum.HRIS_INTEGRATION
    )
  ) {
    return <Navigate to="/employees/add/csv" />
  }

  if (redirect) {
    return <Navigate to={redirect} />
  }

  return (
    <Layout dark>
      <PageTop>
        <Heading>{t('employees.add.viaIntegrations')}</Heading>
      </PageTop>
      {isLoading && <Spinner center />}
      {isSuccess && (
        <AddEmployeesWhiteBox>
          <Steps steps={steps} currentStep={step} sx={{ mb: 7 }} />
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '300px',
            }}
          >
            {steps[step].component}
          </Flex>
        </AddEmployeesWhiteBox>
      )}
    </Layout>
  )
}
