import { FunctionComponent, useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'
import { Navigate as Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { space } from '@fairhq/common'
import { Events } from 'features/events/Events'
import { PromoteCampaigns } from 'features/promoteCampaigns/PromoteCampaigns'
import { PromoteStrategy } from 'features/promoteStrategy/PromoteStrategy'
import { Tasks } from 'features/tasks/Tasks'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { Layout } from 'layout/Layout'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { DashboardIntro } from './DashboardIntro'
import { ScoresOverview } from './ScoresOverview'
import { StatusOverview } from './statusOverview/StatusOverview'

const Grid = styled.div`
  display: grid;
  column-gap: ${space[4]}px;
  row-gap: ${space[4]}px;
  grid-template-columns: 2fr 1.4fr;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  display: grid;
  row-gap: ${space[4]}px;
`

export const Dashboard: FunctionComponent = () => {
  const { isAdmin } = useAuthRoles()

  const { companyId } = useAppSelector(
    (state: State) => ({
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )

  const [redirect, setRedirect] = useState('')
  const { data: companyEntitlements } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })
  const fallbackEntitlements = [
    EntitlementsEnum.STRATEGY_INSIGHTS,
    EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT,
    EntitlementsEnum.DNI_CALENDAR,
    EntitlementsEnum.CAMPAIGNS,
  ]

  const entitlements = companyEntitlements?.entitlements ?? fallbackEntitlements

  useEffect(() => {
    if (isAdmin && !companyId) {
      setRedirect('/admin')
    }
  }, [companyId, isAdmin])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Layout dark maxContentWidth={1320}>
      <Grid>
        <Column>
          <DashboardIntro />

          {entitlements.includes(EntitlementsEnum.STRATEGY_INSIGHTS) ? (
            <PromoteStrategy />
          ) : (
            entitlements.includes(EntitlementsEnum.CRITICAL_FOUNDATIONS) && (
              <Tasks />
            )
          )}
        </Column>
        <Column>
          {entitlements.includes(EntitlementsEnum.REPORTS) && (
            <ScoresOverview />
          )}

          {entitlements.includes(EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT) && (
            <StatusOverview />
          )}

          {entitlements.includes(EntitlementsEnum.DNI_CALENDAR) && <Events />}

          {entitlements.includes(EntitlementsEnum.CAMPAIGNS) && (
            <PromoteCampaigns />
          )}
        </Column>
      </Grid>
    </Layout>
  )
}
