import { shallowEqual } from 'react-redux'

import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { useAppSelector } from '../hooks'

import { useGetSessionsQuery } from './companyApiWithQuery'
import { Session } from './types'

export const useCurrentlyViewingOrLatestSession = (): {
  status: QueryStatus
  session: Session | null
} => {
  const { sessionIdCurrentlyViewing } = useAppSelector(
    (state: State) => ({
      sessionIdCurrentlyViewing: state.companyReducer.sessionIdCurrentlyViewing,
    }),
    shallowEqual
  )

  const { data: sessions, isLoading, isError } = useGetSessionsQuery()

  if (isLoading) {
    return {
      status: QueryStatus.LOADING,
      session: null,
    }
  }
  if (isError) {
    return {
      status: QueryStatus.ERROR,
      session: null,
    }
  }
  const session =
    sessions?.find(({ id }, index) =>
      sessionIdCurrentlyViewing ? id === sessionIdCurrentlyViewing : index === 0
    ) || null
  return { status: QueryStatus.SUCCESS, session }
}
