import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'

import { CompanyInsight } from './types'

const insightsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getInsights: build.query<CompanyInsight[], { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `insights`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.INSIGHTS],
    }),
    updateInsight: build.mutation<
      CompanyInsight,
      { strategyId: number | null; id: number }
    >({
      query: ({ id, strategyId }) => ({
        url: `insights/${id}`,
        method: 'PATCH',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
        body: { strategyId },
      }),
      invalidatesTags: [TagTypes.INSIGHTS],
    }),
  }),
})

export const { useGetInsightsQuery, useUpdateInsightMutation } = insightsApi
