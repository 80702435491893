import { FunctionComponent } from 'react'

import { NavLink } from 'react-router-dom'
import { Container } from 'rsuite'
import styled, { css } from 'styled-components'

import { FieldsContainer } from 'components/form/styled'
import { FormFooter } from 'styled'
import { defaultImage } from 'utils/defaultImage'

import { ReactComponent as LogoIcon } from './assets/logo.svg'

const Content = styled(Container)`
  margin: auto;
  padding: 80px 20px;
  max-width: 520px;
  width: 100%;

  @media (max-width: 991px) {
    padding: 80px 30px 30px;
  }

  .toc-checkbox {
    margin-bottom: 0;

    .rs-checkbox-wrapper {
      display: none;
    }

    .rs-checkbox-checker {
      padding: 0;
      min-height: auto;

      label {
        height: 24px;
        display: flex;

        .rs-container {
          color: ${({ theme }) => theme.colors.grey300};
          flex-flow: row;
          font-size: 14px;
          line-height: 1.71;
          align-items: center;
          height: 24px;

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-of-type) {
    margin-bottom: 30px;

    &.form-email,
    &.form-line1 {
      margin-bottom: 0;
    }

    &.form-country {
      margin-top: 30px;
    }

    &.form-email + .rs-checkbox {
      margin-bottom: 18px;

      .rs-checkbox-wrapper {
        display: none;
      }

      .rs-checkbox-checker {
        padding-left: 0;

        label {
          line-height: 1.87;
          display: flex;
          align-items: center;

          svg {
            margin-right: 7px;
          }
        }
      }
    }

    .rs-radio-group {
      display: flex;
      flex-flow: row;

      .rs-radio-wrapper {
        display: none;
      }

      .rs-radio {
        .rs-radio-checker {
          min-height: auto;
        }
      }
    }

    &.toc-hidden {
      margin-bottom: 0;
      min-height: auto;
      height: 0;
    }
  }

  .form-line2,
  .form-other {
    margin-top: 7px;
  }

  ${FormFooter} {
    margin-top: 48px;

    .rs-btn-toolbar {
      margin-top: 0;

      .rs-btn {
        margin: 0;
      }
    }
  }

  ${FieldsContainer} {
    padding-right: 20px;
  }
`

const ImageContainer = styled.div<{
  invalidToken?: boolean
  notFound?: boolean
}>`
  background-color: ${({ theme }) => theme.colors.lightOrange};
  flex: 1;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  ${({ notFound, invalidToken }) =>
    !notFound &&
    !invalidToken &&
    css`
      max-width: 600px;
    `}

  img {
    width: 100%;
    max-width: 600px;
  }

  @media (max-width: 991px) {
    display: none;
  }

  ${({ notFound }) =>
    notFound &&
    css`
      background-color: transparent;
      padding-right: 60px;

      img {
        width: auto;
        min-width: 600px;
      }
    `}
  ${({ invalidToken }) =>
    invalidToken &&
    css`
      z-index: -1;
      background-color: transparent;
      position: absolute;
      bottom: 0;
      right: 0;

      img {
        width: auto;
        min-width: 900px;
        max-width: initial;
      }
    `}
`

const SignLayoutContainer = styled(Container)`
  height: 100%;
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
`

const SignContainer = styled(Container)`
  position: relative;
  overflow: auto;
  max-width: 840px;
  flex: 1;
  -webkit-overflow-scrolling: touch;
`

interface SignUpLayoutProps {
  notFound?: boolean
  invalidToken?: boolean
  picture?: string
  sources?: string
  sourcesWebp?: string
  children?: React.ReactNode
}

export const SignUpLayout: FunctionComponent<SignUpLayoutProps> = ({
  children,
  invalidToken,
  notFound,
  picture,
  sources,
  sourcesWebp,
}) => (
  <SignLayoutContainer>
    <SignContainer>
      <Content>
        <NavLink to="/" style={{ marginBottom: '80px' }}>
          <LogoIcon />
        </NavLink>
        {children}
      </Content>
    </SignContainer>
    <ImageContainer notFound={notFound} invalidToken={invalidToken}>
      <picture>
        <source srcSet={sourcesWebp || defaultImage.webp} type="image/webp" />
        <source srcSet={sources || defaultImage.png} />
        <img src={picture || defaultImage.src} alt="Fair HQ" />
      </picture>
    </ImageContainer>
  </SignLayoutContainer>
)
