import React, { FunctionComponent } from 'react'

import { alpha } from '@theme-ui/color'
import { Flex, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { Colors } from '@fairhq/common'

import Icon, { IconName } from 'ui-kit/Icon'
import { decimalFormat } from 'utils/numbers'

import { Chart } from './Chart'

const getChartStyle = (score: ScoreChartProps['score']) => {
  if (!score || score === 0) {
    return {
      bg: Colors.RED,
      color: Colors.WHITE,
    }
  }

  if (score < 5) {
    return {
      bg: alpha(Colors.RED, 0.1),
      color: Colors.RED,
    }
  }

  if (score < 7.5) {
    return {
      bg: alpha(Colors.YELLOW, 0.1),
      color: Colors.YELLOW,
    }
  }

  if (score === 10) {
    return {
      bg: Colors.GREEN,
      color: Colors.WHITE,
    }
  }

  return {
    bg: alpha(Colors.GREEN, 0.1),
    color: Colors.GREEN,
  }
}

interface ScoreChartProps {
  score?: number
  icon?: IconName
  reversed?: boolean
  white?: boolean
  sx?: ThemeUIStyleObject
}

export const ScoreChart: FunctionComponent<ScoreChartProps> = ({
  score = 0,
  icon,
  reversed,
  white,
  sx,
}) => {
  const whiteBgStyle = white && {
    bg: 'white',
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: reversed ? 'row-reverse' : 'row',
        flex: '0 0 auto',
        ...sx,
      }}
    >
      <Text as="div" variant="subtitle" sx={{ flex: '1 1 auto' }}>
        {decimalFormat(score * 10) || 0}
      </Text>
      <Flex
        sx={{
          position: 'relative',
          width: 48,
          height: 48,
          ml: reversed ? 0 : 2,
          mr: reversed ? 2 : 0,
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          ...getChartStyle(score),
          ...whiteBgStyle,
        }}
      >
        <Chart score={score} sx={{ position: 'absolute', top: 0, left: 0 }} />
        {icon && <Icon name={icon} color="currentColor" />}
      </Flex>
    </Flex>
  )
}
