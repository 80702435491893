import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Flex, Text } from 'theme-ui'

import { baseColors, makeTransition, Chevron } from '@fairhq/common'
import { useDropdown } from 'hooks/useDropdown'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import {
  useEditTaskMutation,
  useGetTaskStatusOptionsQuery,
  useGetTasksQuery,
} from 'store/tasks/tasksApi'
import { TaskStatus } from 'store/tasks/types'
import { getTaskStatusColors } from 'utils/getTaskStatusColor'

const StyledChevron = styled(Chevron)`
  margin-left: 16px;
`

interface StatusDropdownProps {
  status: string
  setStatus: Dispatch<SetStateAction<TaskStatus>>
  taskId: number
  setSkippedTaskId: () => void
  setCompletedTask: () => void
  onError: () => void
}

export const StatusDropdown: FunctionComponent<StatusDropdownProps> = ({
  status,
  setStatus,
  taskId,
  setSkippedTaskId,
  setCompletedTask,
  onError,
}) => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )
  const { data: statusOptions } = useGetTaskStatusOptionsQuery()
  const [editTask, { isError, isSuccess }] = useEditTaskMutation()
  const { refetch: refetchTasks } = useGetTasksQuery({
    sessionId,
  })

  const {
    ref,
    opened: isDropdownOpen,
    toggleDropdown,
    closeDropdown,
  } = useDropdown()

  useEffect(() => {
    if (isError) {
      onError()
    }
  }, [isError, onError])

  useEffect(() => {
    if (isSuccess) {
      if (status === TaskStatus.SKIPPED) {
        setSkippedTaskId()
      } else if (status === TaskStatus.COMPLETED) {
        setCompletedTask()
      } else {
        refetchTasks()
      }
    }
    // Making this dependent on setSkippedTaskId and setCompletedTask
    // was making the skipped task and task completed modals appear twice,
    // probably as a result of these two function references changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, status, refetchTasks])

  return (
    <Flex
      ref={ref}
      sx={{
        position: 'relative',
        height: 48,
        px: '16px',
        alignItems: 'center',
        borderRadius: 12,
        userSelect: 'none',
        cursor: 'pointer',
        svg: { mr: 1 },
        ...getTaskStatusColors(status),
        flexShrink: 0,
      }}
      onClick={toggleDropdown}
    >
      <Text as="div" variant="caps" sx={{ width: '90px' }}>
        {t(`tasks.status.${status}`)}
      </Text>
      <StyledChevron />
      {isDropdownOpen && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1000,
            top: '100%',
            left: 0,
            width: '100%',
            mt: 1,
            boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
            bg: 'white',
            borderRadius: 8,
            overflow: 'hidden',
          }}
        >
          {statusOptions?.map(option => (
            <Flex
              key={option}
              sx={{
                px: 2,
                py: 1,
                transition: makeTransition('background'),
                '&:hover': { bg: 'grey100' },
                color: baseColors.grey500,
              }}
              onClick={() => {
                editTask({ id: taskId, status: option, sessionId })
                setStatus(option)
                closeDropdown()
              }}
            >
              <Text
                as="div"
                sx={{ fontWeight: option === status ? '700' : '' }}
              >
                {t(`tasks.status.${option}`)}
              </Text>
            </Flex>
          ))}
        </Box>
      )}
    </Flex>
  )
}
