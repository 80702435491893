import styled from 'styled-components'

export const NextSection = styled.div`
  .rs-btn:first-of-type {
    margin-right: 18px;
  }
`

export const PreviousSection = styled.div`
  display: flex;
  align-content: center;

  .rs-btn:first-of-type {
    margin-right: 36px;

    svg {
      margin-right: 12px;
    }
  }
`
export const AreaFooterContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-flow: row wrap;
  max-width: ${({ theme }) => theme.maxWidth2};
  width: 100%;
  height: 100px;
  min-height: 100px;
  margin: auto !important;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};

  .rs-btn {
    font-size: 14px;
  }
`
