import React, { FunctionComponent } from 'react'

interface SadFaceProps {
  className?: string
  stroke?: string
  width?: string
  height?: string
}

export const SadFace: FunctionComponent<SadFaceProps> = ({
  className,
  stroke = 'currentColor',
  width = '12px',
  height = '12px',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 16.9998C9.83563 16.372 10.8744 16 12 16C13.1256 16 14.1644 16.372 15 16.9998"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00195 8.01067C7.00195 8.01067 8.41139 7.88341 9.19783 8.50798M9.19783 8.50798L8.9347 9.34267C8.83091 9.67191 9.10226 10 9.47835 10C9.8736 10 10.1347 9.64338 9.93113 9.33476C9.75072 9.06118 9.50504 8.75196 9.19783 8.50798ZM14.002 8.01067C14.002 8.01067 15.4114 7.88341 16.1979 8.50798M16.1979 8.50798L15.9348 9.34267C15.831 9.67191 16.1023 10 16.4784 10C16.8737 10 17.1347 9.64338 16.9312 9.33476C16.7508 9.06118 16.5051 8.75196 16.1979 8.50798Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
