import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { JobLevelEnum } from 'store/surveyWindow/types'

export const useJobLevels = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  const defaulLevels = [
    { level: 'Employee', code: JobLevelEnum.EMPLOYEE },
    { level: 'Manager', code: JobLevelEnum.MANAGER },
    { level: 'Leadership', code: JobLevelEnum.LEADERSHIP },
  ]
  const levels = [
    { level: 'Operations', code: JobLevelEnum.EMPLOYEE },
    { level: 'Investment', code: JobLevelEnum.MANAGER },
    { level: 'Partners', code: JobLevelEnum.LEADERSHIP },
  ]
  if (
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.PRIVEQ_CERTIFICATION_REPORTS
    )
  ) {
    return levels
  }

  if (
    companyEntitlements?.entitlements.includes(
      EntitlementsEnum.DIVERSITYVC_CERTIFICATION_REPORTS
    )
  ) {
    return levels
  }
  return defaulLevels
}
