import { FunctionComponent, useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { ControlLabel, Input, UploaderProps, Container } from 'rsuite'

import styled, { css } from 'styled-components'

import { FileUploader } from 'components'
import { MessageBoxContainer } from 'components/message-box/styled'
import { LinkButton } from 'styled'
import { Message } from 'styled/message'
import { isValidLink } from 'utils/isValidLink'

import uploadIcon from '../assets/upload.svg'
import { DocumentsContext } from '../DocumentUpload'

const DocumentInputContainer = styled(Container)``

const DocumentUploaderContainer = styled(Container)<{ mini: boolean }>`
  margin-top: 36px;
  ${({ mini }) =>
    mini
      ? css`
          flex-flow: row;

          .rs-uploader {
            width: auto;
            margin: 0 20px 0 0;

            ${LinkButton} {
              background-color: transparent;
              display: flex;
              align-items: center;
              font-weight: bold;
              font-size: 13px;
              line-height: 1.38;
              text-transform: uppercase;
              color: ${({ theme }) => theme.colors.grey400};

              svg {
                margin-right: 6px;
              }
            }
          }

          ${DocumentInputContainer} {
            flex-flow: row;
            align-items: center;

            .rs-control-label {
              margin-right: 30px;
            }
          }
        `
      : css`
          ${DocumentInputContainer} {
            margin-top: 36px;
          }
          .rs-uploader {
            max-height: 300px;

            .rs-uploader-trigger-btn {
              max-height: 300px;
              margin: 0px;
              padding: 0;

              .rs-container {
                margin: 0;
                align-items: center;
              }
            }
          }
        `}

  .rs-uploader {
    ${MessageBoxContainer} {
      padding-top: 40px;

      p {
        font-size: 14px;
        line-height: 1.71;
      }
    }

    h3 {
      margin-top: 36px;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 1.67;
    }

    img {
      height: 120px;
    }
  }
`

export const DocumentUploader: FunctionComponent<
  Partial<UploaderProps>
> = restOfProps => {
  const { t } = useTranslation()

  const {
    document,
    droppedFiles,
    onLoadLink,
    setDroppedFiles,
    setHasError,
    link,
  } = useContext(DocumentsContext)
  const uploadVisible = droppedFiles.length === 0
  const draggable =
    droppedFiles.length === 0 && (document?.links || [])?.length === 0

  return (
    <DocumentUploaderContainer
      mini={!!(!draggable && uploadVisible)}
      style={{ marginTop: draggable ? '36px' : undefined }}
    >
      {!uploadVisible ? undefined : (
        <FileUploader
          action=""
          onChange={files => {
            setHasError(false)
            setDroppedFiles(files)
          }}
          description={t('document.upload.orDragDropHere')}
          icon={uploadIcon}
          title={t('document.upload.selectFileToUpload')}
          draggable={draggable}
          fileListVisible={false}
          multiple
          {...restOfProps}
        />
      )}
      <DocumentInputContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ControlLabel htmlFor="link">
            {uploadVisible && !draggable
              ? t('or')
              : t('document.upload.link.linkDocument')}
          </ControlLabel>
          {uploadVisible && !draggable ? undefined : (
            <Message type="indication" style={{ marginBottom: 0 }}>
              {t('document.upload.link')}
            </Message>
          )}
        </div>
        <Input
          className={
            link
              ? (!isValidLink(link) && 'has-error error') || 'success'
              : undefined
          }
          name="link"
          id="link"
          type="url"
          placeholder={t('document.upload.link.URLOfDoc')}
          value={link}
          onChange={url => onLoadLink(url)}
        />
      </DocumentInputContainer>
    </DocumentUploaderContainer>
  )
}
