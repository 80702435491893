import Bugsnag from '@bugsnag/js'
import { Stripe } from '@stripe/stripe-js'
import { TFunction } from 'i18next'

interface CompletePaymentProps {
  clearError: () => void
  next: () => void
  paymentIntent: any
  paymentMethodId: string
  setError: (error: any) => void
  setFulfilling: (fulfilling: boolean) => void
  setSubmitting: (submitting: boolean) => void
  stripe: Stripe | null
  t: TFunction
}

export const completePayment = async ({
  clearError,
  next,
  paymentIntent,
  paymentMethodId,
  setError,
  setFulfilling,
  setSubmitting,
  stripe,
  t,
}: CompletePaymentProps) => {
  setFulfilling(true)
  clearError()
  if (!stripe || !paymentIntent) {
    setError({
      error: {
        name: 'error.stripe.notAvailable',
        message: t('error.stripe.notAvailable'),
      },
    })
    setSubmitting(false)
    return
  }
  try {
    const result = await stripe.confirmCardPayment(
      paymentIntent?.client_secret || '',
      {
        payment_method: paymentMethodId,
      }
    )
    if (result.error) {
      setError(result.error)
    } else {
      next()
    }
  } catch (errorWhileCompletingPayment: any) {
    Bugsnag.notify(errorWhileCompletingPayment)
    setError(errorWhileCompletingPayment)
  }
}
