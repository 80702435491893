import { FunctionComponent } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Container, Radio, RadioGroup } from 'rsuite'
import styled, { css } from 'styled-components'

import { Label } from 'components/questions/Label'

import { Question } from 'store/questionTypes'
import { tCode } from 'utils/tCode'

const ScaleContainer = styled(Container)<{ scale?: boolean }>`
  .rs-radio-group {
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.grey200};

    .rs-radio {
      border: none;
      margin-right: 0;
      padding: 0;
      ${({ scale }) =>
        scale
          ? css`
              min-width: 30px;
              height: 80px;
            `
          : css`
              height: auto;
            `}

      &:first-of-type.rs-radio-checked {
        border-radius: 10px 0 0 10px;
      }

      &:last-of-type.rs-radio-checked {
        border-radius: 0 10px 10px 0;
      }

      &:not(:last-of-type) {
        border-radius: 0;
        border-right: 1px solid ${({ theme }) => theme.colors.grey200};
      }

      .rs-radio-checker {
        label {
          padding: 0;
        }

        .rs-container {
          flex-flow: column-reverse;
          align-items: center;

          svg {
            margin-bottom: 12px;
          }

          span {
            color: ${({ theme }) => theme.colors.black};
            margin-left: 0;
            font-weight: bold;
            margin-bottom: 6px;
            padding: 0 30px;
            ${({ scale }) =>
              !scale
                ? css`
                    white-space: pre-line;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    max-width: 100px;
                    padding: 0;
                  `
                : undefined}
          }
        }
      }
    }
  }
`
const ScaleFooter = styled(Container)`
  margin-top: 12px;
  flex-flow: row;
  justify-content: space-between;
  font-weight: bold;
`

interface ScaleProps {
  is10PointScale?: boolean
  question: Question
  selectedOption: number | undefined
  onChange: (value: any) => void
}

export const Scale: FunctionComponent<ScaleProps> = ({
  is10PointScale,
  question,
  selectedOption,
  onChange,
}) => {
  const { t } = useTranslation()
  const { options } = question ?? {}
  const tOption = tCode(t)
  const firstOption = _.head(options)
  const lastOption = _.last(options)
  const toRadioOption = ({ id, code, title }: any, index: number) => (
    <Radio key={code} value={id}>
      <Label
        label={is10PointScale ? `${index + 1}` : tOption(code, 'title', title)}
        checked={id === selectedOption}
      />
    </Radio>
  )

  return (
    <ScaleContainer scale={!!is10PointScale}>
      <RadioGroup
        onChange={onChange}
        value={selectedOption}
        appearance="picker"
      >
        {_.sortBy(options, ['priority'])?.map(toRadioOption)}
      </RadioGroup>
      {is10PointScale ? (
        <ScaleFooter>
          <span>{tOption(firstOption?.code, 'title', firstOption?.title)}</span>
          <span>{tOption(lastOption?.code, 'title', lastOption?.title)}</span>
        </ScaleFooter>
      ) : undefined}
    </ScaleContainer>
  )
}
