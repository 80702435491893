import { FunctionComponent } from 'react'

import { IconProps } from './types'

export enum ArrowDirection {
  DOWN = 'down',
  UP = 'up',
  LEFT = 'left',
  RIGHT = 'right',
}

const getDegreeRotation = (direction: ArrowDirection) => {
  switch (direction) {
    case ArrowDirection.LEFT:
      return 180
    case ArrowDirection.DOWN:
      return 90
    case ArrowDirection.UP:
      return 270
    case ArrowDirection.RIGHT:
    default:
      return 0
  }
}

interface ArrowProps extends IconProps {
  direction?: ArrowDirection
}

export const Arrow: FunctionComponent<ArrowProps> = ({
  className,
  outline = 'currentColor',
  width = '16',
  height = '16',
  direction = ArrowDirection.RIGHT,
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    className={className}
    style={{ transform: `rotate(${getDegreeRotation(direction)}deg)` }}
    height={height}
  >
    <path
      d="M2.986 7.986h9.994M8.988 3.992 13.013 8l-4.025 4.008"
      stroke={outline}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
