const env = process.env.REACT_APP_ENV || 'development'

// eslint-disable-next-line consistent-return
const getEnvironmentSpecificProperties = () => {
  if (env === 'production') {
    return {
      tokenKey: 'fairhq-prod-token',
      accountId: 'fairhq-prod-account',
      companyId: 'fairhq-prod-company',
      sessionId: 'fairhq-prod-session',
      employeeId: 'fairhq-prod-employee',
      APIversion: 'fairhq-prod-version',
      audit: 'fairhq-prod-audit',
      invitationKey: 'fairhq-prod-token-invitation',
      latestInvoiceId: 'fairhq-prod-invoice',
      latestInvoicePaymentIntentStatus: 'fairhq-prod-invoice-status',
    }
  }
  if (env === 'development') {
    return {
      tokenKey: 'fairhq-dev-token',
      accountId: 'fairhq-dev-account',
      companyId: 'fairhq-dev-company',
      sessionId: 'fairhq-dev-session',
      employeeId: 'fairhq-dev-employee',
      APIversion: 'fairhq-dev-version',
      audit: 'fairhq-dev-audit',
      invitationKey: 'fairhq-dev-token-invitation',
      latestInvoiceId: 'fairhq-dev-invoice',
      latestInvoicePaymentIntentStatus: 'fairhq-dev-invoice-status',
    }
  }
  if (env === 'local') {
    return {
      tokenKey: 'fairhq-local-token',
      accountId: 'fairhq-local-account',
      companyId: 'fairhq-local-company',
      sessionId: 'fairhq-local-session',
      employeeId: 'fairhq-local-employee',
      APIversion: 'fairhq-local-version',
      audit: 'fairhq-local-audit',
      invitationKey: 'fairhq-local-token-invitation',
      latestInvoiceId: 'fairhq-local-invoice',
      latestInvoicePaymentIntentStatus: 'fairhq-local-invoice-status',
    }
  }
}

export const config: any = {
  env,
  analytics: process.env.REACT_APP_ANALYTICS,
  stripe: process.env.REACT_APP_STRIPE,
  version: process.env.REACT_APP_VERSION,
  baseUrl: process.env.REACT_APP_API,
  graphql: `${process.env.REACT_APP_API}graphql`,
  bugsnag: process.env.REACT_APP_BUGSNAG,
  translationApiKey: 'NSwFlUi9S8_I-Rwz2XU8ug',
  auth0: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  },
  surveyUIEnabled: process.env.REACT_APP_SURVEY_UI,
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
  maintenanceMode: process.env.REACT_APP_MAINTENANCE_MODE,
  ...getEnvironmentSpecificProperties(),
}
