import { FunctionComponent } from 'react'

import { Flex, Heading } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { StateHandler } from 'components/StateHandler'
import { OldRecommendationArea } from 'features/strategy/oldStrategy/OldRecommendationArea'
import { OldRecommendationDetail } from 'features/strategy/oldStrategy/OldRecommendationDetail'
import { OldRecommendationDifficulty } from 'features/strategy/oldStrategy/OldRecommendationDifficulty'

import { Layout } from 'layout/Layout'
import { useGetOldRecommendationQuery } from 'store/oldRecommendations/oldRecommendationApi'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;
`

export const TaskOldRecommendation: FunctionComponent = () => {
  const { code = '' } = useParams<{ code: string }>()
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const {
    data: oldRecommendation,
    isLoading,
    isSuccess,
    isError,
  } = useGetOldRecommendationQuery({ code, sessionId })

  return (
    <Layout maxContentWidth={1080} dark>
      <StateHandler
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
        errorMessage={t('taskOldRecommendation.error.title')}
        loadingComponent={
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        }
      >
        <Heading sx={{ mb: 6 }}>{oldRecommendation?.title}</Heading>

        <Flex sx={{ mb: 6 }}>
          <OldRecommendationArea
            data={oldRecommendation?.type?.[0] as string}
            sx={{ mr: 6 }}
          />
          <OldRecommendationDifficulty
            data={oldRecommendation?.intensity as string}
          />
        </Flex>
        <OldRecommendationDetail
          isOldRecommendationsLoading={isLoading}
          notionLink={oldRecommendation?.notionLink as string}
        />
      </StateHandler>
    </Layout>
  )
}
