import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useTitle } from 'features/areas/hooks/useTitle'
import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { AreaType } from 'store/area/types'
import { useGetAuditAreasQuery } from 'store/audit/auditApiWithQuery'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import Sidebar from '../../layout/Sidebar'
import { useAppSelector } from '../../store/hooks'

import { AssessmentSidebarEmployees } from './AssessmentSidebarEmployees'
import { SidebarGroupHeader } from './components/SidebarGroupHeader'
import { SidebarMenuItem } from './components/SidebarMenuItem'

export const AssessmentSidebar: FunctionComponent = () => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { sessionId, companyId, companyIntegrated } = useAppSelector(
    (state: State) => ({
      sessionId: state.apiHeadersReducer.sessionId,
      companyId: state.apiHeadersReducer.companyId,
      companyIntegrated: state.companyReducer.company?.integrated,
    }),
    shallowEqual
  )

  const { data: companyEntitlements } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  const { permissions } = useAuthPermissions()

  const fallbackEntitlements = [
    EntitlementsEnum.EMPLOYEES,
    EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT,
    EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT,
  ]

  const entitlements = companyEntitlements?.entitlements ?? fallbackEntitlements

  const { data: areas } = useGetAuditAreasQuery({
    sessionId,
    query: { type: AreaType.SUB },
  })

  const { title, percent } = useTitle()

  return (
    <Sidebar
      sx={{ py: 6, display: 'flex', flexDirection: 'column', gap: '20px' }}
    >
      <div>
        {permissions?.includes(PermissionsEnum.READ_EMPLOYEE) && (
          <AssessmentSidebarEmployees />
        )}
        {companyIntegrated && (
          <SidebarMenuItem
            text={t('breakdown.title')}
            url="/audit/breakdown"
            hasNoProgression
          />
        )}
      </div>

      {entitlements.includes(EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT) && (
        <div>
          <SidebarGroupHeader
            text={title}
            url="/audit"
            completion={`${percent}%`}
          />
          {(areas ?? [])
            ?.filter(
              ({ sources }) =>
                sources?.includes('company') || sources?.includes('documents')
            )
            .filter(({ type }) => type === 'sub')
            .map(({ code, title: areaTitle }) => (
              <SidebarMenuItem
                key={code}
                text={tArea(code, 'title', areaTitle)}
                url={`/audit/${code}`}
                code={code}
              />
            ))}
        </div>
      )}
    </Sidebar>
  )
}
