import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Box } from 'theme-ui'

import { Text, baseColors } from '@fairhq/common'
import { BellCurve } from 'components/BellCurve'
import { Square } from 'components/Square'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import { useGetENPSQuery } from 'store/surveyWindow/surveyWindowApi'

import { ENPSComparison } from './ENPSComparison'

const Container = styled(WhiteBoxWithShadow)`
  flex-direction: column;
  padding: 40px;
  gap: 36px;
`

const CapsText = styled(Text)`
  text-transform: uppercase;
`

const Body = styled.div`
  display: flex;
  width: 100%;
  gap: 60px;
  align-items: flex-start;
`

const ScoreBox = styled.div`
  display: flex;
  gap: 12px;
`

const Captions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`

const Caption = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
`

const PeerComparisonCurve = styled.div`
  display: grid;
  align-items: center;
`

interface ENPSProps {
  latestSurveyWindowId: number
  previousSurveyWindowId?: number
}

export const ENPS: FunctionComponent<ENPSProps> = ({
  latestSurveyWindowId,
  previousSurveyWindowId,
}) => {
  const { t } = useTranslation()

  const { data, isSuccess } = useGetENPSQuery({
    surveyWindowId: latestSurveyWindowId,
  })

  if (isSuccess && data) {
    const colors = {
      green: baseColors.fairGreen400,
      yellow: baseColors.yellow,
      red: baseColors.red400,
    }
    return (
      <Container>
        <CapsText element="h2" type="body" size="sm" fontWeight={700}>
          {t('enps.title')}
        </CapsText>
        <Body>
          <ScoreBox>
            <Text element="h1" type="body" size="2xl" fontWeight={600}>
              {(data.score > 0 ? `+` : ``) + data.score}
            </Text>

            {previousSurveyWindowId && (
              <ENPSComparison
                latestSurveyWindowScore={data.score}
                previousSurveyWindowId={previousSurveyWindowId}
              />
            )}
          </ScoreBox>
          <div>
            <Box
              sx={{
                borderRadius: '8px',
                backgroundImage: `linear-gradient(90deg, ${colors.green} ${
                  data.promotersPercentage
                }%, ${colors.yellow} ${data.promotersPercentage}%, ${
                  colors.yellow
                } ${data.promotersPercentage + data.passivesPercentage}%, ${
                  colors.red
                } ${data.passivesPercentage}%)`,
                backgroundRepeat: 'no-repeat',
                height: 40,
                width: '100%',
                maxWidth: '400px',
                marginBottom: '6px',
              }}
            />
            <Captions>
              {[
                {
                  percentage: data.promotersPercentage,
                  title: 'Promoters',
                  color: colors.green,
                },
                {
                  percentage: data.passivesPercentage,
                  title: 'Passives',
                  color: colors.yellow,
                },
                {
                  percentage: data.detractorsPercentage,
                  title: 'Detractors',
                  color: colors.red,
                },
              ].map(item => (
                <Caption key={item.title}>
                  <Square
                    backgroundColor={item.color}
                    borderColor={item.color}
                  />
                  <Text element="span" type="body" size="sm" fontWeight={500}>
                    {`${item.title} ${item.percentage}%`}
                  </Text>
                </Caption>
              ))}
            </Captions>
          </div>
          <PeerComparisonCurve>
            <BellCurve value={+data.percentile} />
          </PeerComparisonCurve>
        </Body>
      </Container>
    )
  }

  return null
}
