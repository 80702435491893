import { FunctionComponent } from 'react'

import { alpha } from '@theme-ui/color'

import { Box, Text, Link } from '@theme-ui/components'
import { useTranslation, Trans } from 'react-i18next'

import { makeTransition } from '@fairhq/common'

import Button from '../../ui-kit/Button'

import { ReactComponent as CSVIcon } from './assets/csv.svg'

interface PrepareProps {
  next: () => void
}

export const Prepare: FunctionComponent<PrepareProps> = ({ next }) => {
  const { t } = useTranslation()

  return (
    <>
      <Link
        href="/examples/Fair HQ - CSV example.csv"
        target="_blank"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 200,
          maxWidth: 800,
          mx: 'auto',
          mb: 8,
          border: '1px solid',
          borderColor: 'border',
          borderRadius: 10,
          textDecoration: 'none',
          transition: `${makeTransition('border')}, ${makeTransition(
            'background'
          )}`,

          '&:hover': {
            borderColor: 'primary',
            bg: alpha('primary', 0.1),
          },
        }}
      >
        <CSVIcon />
        <Text as="div" variant="bodyLargeBold" sx={{ ml: 2, color: '' }}>
          {t('employees.addEmployees.downloadCSV')}
        </Text>
      </Link>

      <Box sx={{ maxWidth: 800, mx: 'auto', textAlign: 'center' }}>
        <Text as="div" variant="bodyLarge" sx={{ mb: 6 }}>
          <Trans
            i18nKey="survey.addEmployees.howToDownloadCSVNotion"
            components={[
              <a
                href={t('survey.addEmployees.howToDownloadCSVNotion.url')}
                target="_blank"
                rel="noopener noreferrer"
              >
                xxx
              </a>,
            ]}
          />
        </Text>

        <Text as="div" variant="bodyLargeBold" sx={{ mb: 1, color: 'grey500' }}>
          {t('survey.addEmployees.googleSheet.title')}
        </Text>
        <Text as="div" variant="bodyLarge" sx={{ mb: 8 }}>
          <Trans
            i18nKey="survey.addEmployees.googleSheet"
            components={[
              <a
                href={t('survey.addEmployees.googleSheet.url')}
                rel="noopener noreferrer"
                target="_blank"
              >
                Google Sheet
              </a>,
            ]}
          />
        </Text>

        <Button onClick={next} size="large">
          {t('employees.addEmployees.readyToUpload')}
        </Button>
      </Box>
    </>
  )
}
