import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Text } from '@fairhq/common'

import { useGetExperienceQuestionsQuery } from 'store/surveyWindow/surveyWindowApi'

import { QuestionAccordion } from './QuestionAccordion'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface ExperienceQuestionsProps {
  latestSurveyWindowId: number
  previousSurveyWindowId?: number
}

export const ExperienceQuestions: FunctionComponent<
  ExperienceQuestionsProps
> = ({ latestSurveyWindowId, previousSurveyWindowId }) => {
  const { t } = useTranslation()

  const { data: latestSurveyQuestions, isSuccess: isLatestSurveySuccess } =
    useGetExperienceQuestionsQuery({
      surveyWindowId: latestSurveyWindowId,
    })

  const { data: previousSurveyQuestions, isSuccess: isPreviousSurveySucess } =
    useGetExperienceQuestionsQuery(
      {
        surveyWindowId: previousSurveyWindowId,
      },
      { skip: !previousSurveyWindowId }
    )

  if (isLatestSurveySuccess && latestSurveyQuestions) {
    return (
      <Wrapper>
        <Container>
          <Text element="h2" type="body" size="xl" fontWeight={700}>
            {t('experience.questions.title')}
          </Text>
          <Text element="p" type="body" size="md" fontWeight={400}>
            {t('experience.questions.description')}
          </Text>
        </Container>

        <Container>
          {latestSurveyQuestions.map(experienceQuestion => {
            const previous = previousSurveyQuestions?.find(
              item =>
                item.surveyQuestion.id === experienceQuestion.surveyQuestion.id
            )
            return (
              <QuestionAccordion
                key={experienceQuestion.id}
                experienceQuestion={experienceQuestion}
                blockTitle={
                  experienceQuestion.surveyQuestion.type.includes('scale')
                    ? `"${experienceQuestion.surveyQuestion.title}"`
                    : experienceQuestion.surveyQuestion.title
                }
                companyScore={experienceQuestion.companyScore}
                gap={experienceQuestion.minorityMajorityGap}
                previousScore={previous?.companyScore}
                previousGap={previous?.minorityMajorityGap}
                showDiffColumn={
                  isPreviousSurveySucess && previousSurveyQuestions.length > 0
                }
              />
            )
          })}
        </Container>
      </Wrapper>
    )
  }
  return null
}
