import { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { Channel } from 'features/campaigns/compound/Campaign.Channel'
import { InProgressTiming } from 'features/campaigns/compound/Campaign.InProgressTiming'
import { SpecificTiming } from 'features/campaigns/compound/Campaign.SpecificTiming'
import {
  ScheduledCampaign,
  ScheduledCampaignStatus,
} from 'features/campaigns/types'

import { ScheduledCampaignIcon } from './ScheduledCampaignIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const PreviewScheduledCampaigns: FunctionComponent<{
  scheduledCampaigns: ScheduledCampaign[]
}> = ({ scheduledCampaigns }) => {
  const { t } = useTranslation()

  return (
    <div>
      {scheduledCampaigns?.map(
        ({
          scheduledCampaignId,
          nudgesToSend,
          nextNudgeOn,
          campaign,
          channel,
          startDate,
          status,
        }) => (
          <Flex
            key={scheduledCampaignId}
            sx={{
              alignItems: 'flex-start',
              '&:not(:last-of-type)': {
                marginBottom: '16px',
              },
            }}
          >
            <ScheduledCampaignIcon />
            <Container>
              <Text as="div" sx={{ fontSize: '16px', fontWeight: 700 }}>
                {t(`campaigns.${campaign?.code}.title`)}
              </Text>
              <Text as="div" sx={{ fontSize: '14px' }}>
                {status === ScheduledCampaignStatus.LIVE ? (
                  <InProgressTiming
                    nudgesToSend={nudgesToSend}
                    nextNudgeOn={nextNudgeOn}
                    withIcon={false}
                  />
                ) : (
                  <SpecificTiming
                    startDate={startDate}
                    frequency={campaign?.frequency}
                    withIcon={false}
                  />
                )}
              </Text>
              {channel && (
                <Text as="div" sx={{ fontSize: '14px' }}>
                  <Channel
                    channelName={channel.name}
                    isPrivate={channel.isPrivate}
                    iconSize="16"
                  />
                </Text>
              )}
            </Container>
          </Flex>
        )
      )}
    </div>
  )
}
