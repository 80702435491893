import { FunctionComponent } from 'react'

import { Box, BoxProps } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

const BorderBox: FunctionComponent<{ sx?: ThemeUIStyleObject } & BoxProps> = ({
  sx,
  children,
  ...rest
}) => (
  <Box
    sx={{
      borderRadius: 20,
      bg: 'white',
      p: 8,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export default BorderBox
