import queryString from 'query-string'

import { emptySplitApi } from 'emptySplitApi'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import { Area } from 'store/area/types'

import {
  Breakdown,
  CertificationType,
  DiversityMakeup,
  LevelCompletion,
  PayGapBreakdown,
  PracticeCriteriaType,
  Report,
  ReportGroup,
  ReportProjection,
  ReportType,
} from './types'

const reportsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getReportAreas: build.query<
      Partial<Area>[],
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `areas`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getCompanyBreakdown: build.query<
      Partial<Breakdown>[],
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `companies/breakdown`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getCompanyPayGapBreakdown: build.query<
      Partial<PayGapBreakdown>[],
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `companies/breakdown/paygap`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getReports: build.query<
      Partial<Report>,
      {
        type: ReportType
        apiVersion: ApiVersion
        sessionId: SessionId
      }
    >({
      query: ({ type, apiVersion, sessionId }) => ({
        url: `reports?${queryString.stringify({
          type,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getReportByArea: build.query<
      Partial<Report>,
      {
        areaCode?: string
        projection?: ReportProjection
        group?: ReportGroup
        apiVersion: ApiVersion
        sessionId: SessionId
      }
    >({
      query: ({ areaCode = '', projection, group, apiVersion, sessionId }) => ({
        url: `reports/${areaCode}?${queryString.stringify({
          projection,
          group,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getDiversityMakeupReports: build.query<
      DiversityMakeup[],
      {
        apiVersion: ApiVersion
        sessionId: SessionId
        breakdown?: boolean
      }
    >({
      query: ({ apiVersion, sessionId, breakdown }) => ({
        url: `reports/diversity/makeup?${queryString.stringify({
          breakdown,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getPractices: build.query<
      PracticeCriteriaType[],
      { areaCode?: string; sessionId: SessionId }
    >({
      query: ({ areaCode, sessionId }) => ({
        url: `reports/practices?${
          areaCode
            ? queryString.stringify({
                areaCode,
              })
            : ''
        }`,
        headers: { 'fairhq-session': sessionId ? sessionId.toString() : '' },
      }),
    }),
    getCertificatation: build.query<CertificationType, void>({
      query: () => ({
        url: `reports/certification`,
      }),
    }),
    getLevelsCompletion: build.query<LevelCompletion[], void>({
      query: () => ({
        url: `reports/completion/levels`,
      }),
    }),
  }),
})

export const {
  useGetReportAreasQuery,
  useGetReportsQuery,
  useGetDiversityMakeupReportsQuery,
  useGetReportByAreaQuery,
  useGetCompanyBreakdownQuery,
  useGetCompanyPayGapBreakdownQuery,
  useGetPracticesQuery,
  useGetCertificatationQuery,
  useGetLevelsCompletionQuery,
} = reportsApi
