import React, { FunctionComponent, useContext } from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Navigate as Redirect } from 'react-router-dom'

import { Spinner } from '@fairhq/common'
import AuthContext from 'utils/auth'

import { useRedirectToBilling } from './useRedirectToBilling'
import { useRedirectToOnboarding } from './useRedirectToOnboarding'

const PrivateRouteComponent: FunctionComponent<any> = ({ children }) => {
  const { loggedIn } = useContext(AuthContext)
  const redirectToOnboarding = useRedirectToOnboarding()
  const redirectToBilling = useRedirectToBilling()
  const redirect = redirectToOnboarding || redirectToBilling

  if (!loggedIn) {
    return <Spinner backdrop vertical />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return children
}

export const PrivateRoute = withAuthenticationRequired(PrivateRouteComponent)
