import { useAuthRoles } from 'hooks/useAuthRoles'
import {
  useGetAccountsQuery,
  useGetDVCAccountsQuery,
} from 'store/account/accountApiWithQuery'

export const useAccounts = (jwtToken?: string) => {
  const { isDVCAdmin, isFHQAdmin } = useAuthRoles()

  const {
    data: accounts,
    isSuccess,
    isError,
    isLoading,
  } = useGetAccountsQuery(undefined, { skip: !jwtToken && isDVCAdmin })

  const {
    data: DVCaccounts,
    isSuccess: DVCSuccess,
    isError: DVCError,
    isLoading: DVCLoading,
  } = useGetDVCAccountsQuery(undefined, { skip: !jwtToken && isFHQAdmin })

  if (isDVCAdmin) {
    return {
      accounts: DVCaccounts,
      isSuccess: DVCSuccess,
      isError: DVCError,
      isLoading: DVCLoading,
    }
  }

  if (isFHQAdmin) {
    return { accounts, isSuccess, isError, isLoading }
  }

  return {
    accounts: [],
    isSuccess: false,
    isError: false,
    isLoading: false,
  }
}
