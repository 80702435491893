import { FunctionComponent, useEffect, useRef } from 'react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useIntersection, usePrevious } from 'react-use'

import { Spinner } from '@fairhq/common'

import { Message } from 'styled/message'

import { DataListFooterContainer } from './styled'

import { Row } from './index'

interface DataListFooterProps {
  onLoadMore?: () => void
  loading?: boolean
  preview?: boolean
  data?: Row[]
  total?: number
}
const DataListFooter: FunctionComponent<DataListFooterProps> = ({
  data = [],
  onLoadMore,
  loading,
  preview,
  total = 0,
}) => {
  const bottomRef = useRef(null)
  const intersection = useIntersection(bottomRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  const { t } = useTranslation()
  const allDataVisible = data?.length === total
  const previousData = usePrevious(data)
  const dataHasChanged =
    !loading && data?.length !== 0 && _.isEqual(previousData, data)

  useEffect(() => {
    if (
      intersection?.isIntersecting &&
      !allDataVisible &&
      onLoadMore &&
      dataHasChanged
    ) {
      onLoadMore()
    }
  }, [allDataVisible, dataHasChanged, intersection, onLoadMore])

  return (
    <DataListFooterContainer hidden={!!(preview && allDataVisible)}>
      <div ref={bottomRef} />
      {preview ? (
        <Message>
          {t('data.previewTotal', { count: total - data.length })}
        </Message>
      ) : undefined}
      {loading ? <Spinner content={t('loading')} /> : undefined}
    </DataListFooterContainer>
  )
}

export default DataListFooter
