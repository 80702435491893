import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Text } from '@fairhq/common'
import { Layout } from 'layout/Layout'

export const NoAccess: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Layout
      dark
      contentSx={{
        paddingTop: 120,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Text element="h3" type="heading" size="md" fontWeight={500}>
        {t(`noAccess`)}
      </Text>
    </Layout>
  )
}
