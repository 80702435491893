import _ from 'lodash'

export const sanitize = (data: any) => {
  const sanitizedData = _.cloneDeep(data)
  Object.keys(sanitizedData).forEach(key => {
    const value = sanitizedData[key]
    if (value === '' || value === null || value === undefined) {
      delete sanitizedData[key]
    } else if (_.isObject(value)) {
      sanitizedData[key] = sanitize(value)
    } else if (_.isArray(value)) {
      sanitizedData[key] = value.map(obj => sanitize(obj))
    } else if (_.isString(value)) {
      sanitizedData[key] = _.trim(value)
    }
  })
  return sanitizedData
}
