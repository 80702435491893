import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'
import { NotionPage } from 'components/notion/NotionPage'
import { Layout } from 'layout/Layout'

import { useGetResourceQuery } from './resourcesApi'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

export const Resources: FunctionComponent = () => {
  const { code = '' } = useParams<{ code: string }>()
  const { t } = useTranslation()

  const {
    data: resource,
    isLoading,
    isSuccess,
    isError,
  } = useGetResourceQuery({
    code,
  })

  return (
    <Layout maxContentWidth={1080} dark>
      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}
      {isSuccess && resource && (
        <Container>
          <Header>
            <StyledText
              element="p"
              type="body"
              size="xs"
              fontWeight={700}
              color={baseColors.coolGrey900}
            >
              Resource
            </StyledText>
            <Text
              element="h1"
              type="heading"
              size="xl"
              fontWeight={500}
              color={baseColors.coolGrey900}
            >
              {resource?.description}
            </Text>
          </Header>
          {resource?.notionURL && <NotionPage notionURL={resource.notionURL} />}
        </Container>
      )}
    </Layout>
  )
}
