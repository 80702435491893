import { FunctionComponent } from 'react'

import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'

import { useAuditBy } from 'hooks/useAuditBy'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useCurrentlyViewingOrLatestSession } from 'store/company/useCurrentlyViewingOrLatestSession'
import { useAppSelector } from 'store/hooks'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'

import { State } from 'store/state'

import { QueryStatus } from 'store/types'

import { SpanProp } from './Dashbox'
import { DILevel } from './DILevel'
import { useRequestReports } from './hooks/useRequestReports'

interface AuditDILevelProps {
  span: SpanProp
}

export const AuditDILevel: FunctionComponent<AuditDILevelProps> = ({
  span,
}) => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })
  const { session } = useCurrentlyViewingOrLatestSession()
  const { data: latestScores } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })
  const { dniLevel } = latestScores || {}
  const { percentage, completed, auditQueryStatus } = useAuditBy()
  const { processing, requestReports } = useRequestReports()

  return (
    <StateHandler
      isSuccess={auditQueryStatus === QueryStatus.SUCCESS}
      isLoading={auditQueryStatus === QueryStatus.LOADING}
      isError={auditQueryStatus === QueryStatus.ERROR}
    >
      <DILevel
        completed={completed ?? false}
        dniLevel={dniLevel}
        percent={percentage}
        processing={processing}
        scorable={isScorable}
        requestReports={requestReports}
        sessionReportLink={session?.reportLink}
        span={span}
      />
    </StateHandler>
  )
}
