import { config } from '@fairhq/common'
import { State } from 'store/state'

export const getDefaultHeaders = (getState: () => unknown) => {
  const { apiHeadersReducer } = getState() as State
  const accountId = apiHeadersReducer?.accountId?.toString()
  const companyId = apiHeadersReducer?.companyId?.toString()
  const apiVersion = apiHeadersReducer?.apiVersion?.toString()
  const sessionId = apiHeadersReducer?.sessionId?.toString()
  const defaultHeaders: HeadersInit = {
    'fairhq-account': accountId || '',
    'fairhq-company': companyId || '',
    'fairhq-version': apiVersion || '',
    'fairhq-session': sessionId || '',
  }
  const token = localStorage.getItem(config.tokenKey)
  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`
  }

  return defaultHeaders
}
