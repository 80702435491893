import QueryString from 'qs'

import { emptySplitApi, TagTypes } from 'emptySplitApi'
import { Employee, EmployeePagination } from 'features/employees/types'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'

const employeesApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getEmployees: build.query<Employee[], EmployeePagination>({
      query: ({ limit, terms, lastName, firstName, employeeId }) => {
        const params = {
          limit,
          ...(terms ? { terms: encodeURIComponent(terms) } : {}),
          ...(lastName && firstName && employeeId
            ? { lastName, firstName, employeeId }
            : {}),
        }
        return {
          url: `employees/?${QueryString.stringify(params)}`,
        }
      },
      providesTags: [TagTypes.EMPLOYEES],
      keepUnusedDataFor: 0,
    }),
    saveEmployees: build.mutation<
      void,
      { employees: Partial<Employee>[]; sessionId: SessionId }
    >({
      query: ({ employees, sessionId }) => ({
        url: 'employees',
        method: 'POST',
        body: { employees },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
    updateEmployee: build.mutation<
      void,
      { employee: Partial<Employee>; sessionId: SessionId }
    >({
      query: ({ employee, sessionId }) => ({
        url: `employees/${employee.id}`,
        method: 'PATCH',
        body: employee,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      invalidatesTags: [TagTypes.EMPLOYEES],
    }),
  }),
})

export const {
  useGetEmployeesQuery,
  useSaveEmployeesMutation,
  useUpdateEmployeeMutation,
} = employeesApi
