import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Button, Spinner, Text, baseColors } from '@fairhq/common'

import {
  useCheckIsNewStrategyDueQuery,
  useGenerateNewStrategyMutation,
} from 'store/strategy/strategyApi'

const Container = styled.div`
  display: flex;
  gap: 80px;
  padding: 0 120px;
`

const InnerContainer = styled.main`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 38px 0;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledButton = styled(Button)`
  align-self: start;
`

export const HoldingPage: FunctionComponent = () => {
  const { t } = useTranslation()

  const {
    data: isNewStrategyDue,
    isLoading,
    isError,
    isSuccess,
  } = useCheckIsNewStrategyDueQuery()

  const [
    generateNewstrategy,
    { isLoading: isMutationLoading, isError: isMutationError },
  ] = useGenerateNewStrategyMutation()

  return (
    <Container>
      {isLoading && <Spinner center />}
      {isError && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={700}
          color={baseColors.red}
        >
          {t('error.somethingWrong.fallback')}
        </Text>
      )}
      {isSuccess && (
        <>
          <InnerContainer>
            <Section>
              <Text
                element="h1"
                type="heading"
                size="2xl"
                fontWeight={500}
                color={baseColors.coolGrey900}
              >
                {t('strategy.title')}
              </Text>
              <Text
                element="p"
                type="body"
                size="md"
                fontWeight={400}
                color={baseColors.coolGrey900}
              >
                {t('strategy.holdingPage.description')}
              </Text>
            </Section>

            <Section>
              {!isNewStrategyDue && (
                <Text
                  element="p"
                  type="body"
                  size="sm"
                  fontWeight={600}
                  color={baseColors.coolGrey700}
                >
                  {t('strategy.holdingPage.ctaDescription')}
                </Text>
              )}

              <StyledButton
                onClick={() => generateNewstrategy()}
                loading={isMutationLoading}
                disabled={!isNewStrategyDue}
                hasMinWidth
              >
                {t('strategy.holdingPage.ctaButton')}
              </StyledButton>

              {isMutationError && (
                <Text
                  element="p"
                  type="body"
                  size="md"
                  fontWeight={700}
                  color={baseColors.red}
                >
                  {t('strategy.generate.error')}
                </Text>
              )}
            </Section>
          </InnerContainer>
          <img
            src="https://files.fairhq.co/ilustrations/workflow-design-gameplan-team-teamwork-search-find-idea-thought.svg"
            alt="Illustration of three people generating ideas, doing research and planning a DEI strategy"
          />
        </>
      )}
    </Container>
  )
}
