import { TFunction } from 'i18next'

import { GraphItem } from 'components/visualization/types'

export const getTabs = (t: TFunction, items?: Record<string, GraphItem[]>) => [
  {
    key: 'overview',
    label: t('reports.overview'),
    length: items?.overview?.length ?? 0,
  },
  {
    key: 'byEmployeeGroups',
    label: t('reports.byEmployeeGroups'),
    length: items?.byEmployeeGroups?.length ?? 0,
  },
  {
    key: 'byGaps',
    label: t('reports.byGaps'),
    length: items?.byGaps?.length ?? 0,
  },
  {
    key: 'byJobLevel',
    label: t('reports.byJobLevel'),
    length: items?.byJobLevel?.length ?? 0,
  },
  {
    key: 'byDepartment',
    label: t('reports.byDepartment'),
    length: items?.byDepartment?.length ?? 0,
  },
  {
    key: 'byLocation',
    label: t('reports.byLocation'),
    length: items?.byLocation?.length ?? 0,
  },
]
