import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { getAudit } from '../audit/auditSlice'
import { count as employeeCount } from '../employee/employeeSlice'

import { surveyApi } from './surveyApi'
import { SurveyState, Survey } from './types'

export const clear = createAction('employees/clear')

export const count = createAsyncThunk('survey/count', async (_, { getState }) =>
  waitForResponse({
    callback: () => surveyApi.count(getState),
  })
)

export const getCompletion = createAsyncThunk(
  'survey/getCompletion',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => surveyApi.getCompletion(getState),
    })
)

export const sendSurvey = createAsyncThunk(
  'survey/sendSurvey',
  async (ids: number[], { dispatch, getState }) => {
    await waitForResponse({
      callback: () => surveyApi.sendSurvey(getState, ids),
      withJSON: false,
    })
    dispatch(employeeCount())
    dispatch(count())
    dispatch(getCompletion())
    dispatch(getAudit())
  }
)

export const updateSurvey = createAsyncThunk(
  'survey/update',
  async ({ survey }: { survey: Partial<Survey> }, { getState }) =>
    waitForResponse({ callback: () => surveyApi.update(getState, survey) })
)

export const getInvitation = createAsyncThunk(
  'survey/getInvitation',
  async (_, { getState }) =>
    waitForResponse({ callback: () => surveyApi.getInvitation(getState) })
)

const initialState: Partial<SurveyState> = { loading: false }

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(updateSurvey.fulfilled, (state, action) => {
        state.loading = false
        state.survey = action.payload
      })
      .addCase(count.pending, state => {
        state.loading = true
        state.status = {}
      })
      .addCase(count.fulfilled, (state, action) => {
        state.loading = false
        state.status = action.payload
      })
      .addCase(getCompletion.fulfilled, (state, action) => {
        state.loading = false
        state.completion = action.payload
        state.completionLoaded = true
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.loading = false
        state.invitation = action.payload
      })
      .addCase(sendSurvey.fulfilled, state => {
        state.loading = false
        state.error = undefined
      })
      .addCase(sendSurvey.pending, state => {
        state.loading = true
        state.error = undefined
      })
      .addMatcher(isFulfilled('survey'), state => {
        state.error = undefined
        state.loading = false
      })
      .addMatcher(isPending('survey'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('survey'), handleErrorState)
  },
})

export const { reducer: surveyReducer } = surveySlice
