import React, { FunctionComponent } from 'react'

import { NotionRenderer } from 'react-notion-x'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'

import { useGetNotionPageQuery } from 'store/notion/notionApi'

import { getPageIdFromNotionUrl } from 'utils/getPageIdFromNotionUrl'

import { NotionEmbedLinks } from './NotionEmebedLinks'

const Footer = styled.div`
  display: flex;
  gap: 60px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  background-color: ${baseColors.grey100};
  padding: 32px 40px;
  color: ${baseColors.purple800};
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  width: 100%;
`

const StyledNotionWrapper = styled.div`
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  width: 100%;
  background-color: ${baseColors.white};
  padding: 66px 36px;
  border-radius: 20px 20px 0 0;
`

interface NotionPageWithFooterSectionProps {
  notionURL?: string
  footer?: React.ReactNode
  footerTitle: string
}

export const NotionPageWithFooterSection: FunctionComponent<
  NotionPageWithFooterSectionProps
> = ({ notionURL, footer, footerTitle }) => {
  const pageId = notionURL ? getPageIdFromNotionUrl(notionURL) : ''

  const {
    data: notionPage,
    isSuccess: isNotionPageSuccess,
    isLoading: isNotionPageLoading,
  } = useGetNotionPageQuery(
    {
      pageId,
    },
    { skip: !pageId }
  )

  return (
    <>
      {isNotionPageLoading && <Spinner center />}

      {isNotionPageSuccess && notionPage && (
        <>
          <StyledNotionWrapper>
            <NotionRenderer
              recordMap={notionPage}
              mapPageUrl={(pageUrl: string) =>
                `https://notion.so/${pageUrl?.replace(/-/g, '')}`
              }
              components={{
                Link: NotionEmbedLinks,
                PageLink: NotionEmbedLinks,
              }}
            />
          </StyledNotionWrapper>
          {footer && (
            <Footer>
              <Text element="h2" type="heading" size="sm" fontWeight={500}>
                {footerTitle}
              </Text>
              <List>{footer}</List>
            </Footer>
          )}
        </>
      )}
    </>
  )
}
