import { FunctionComponent, ReactNode } from 'react'

import { Root, Indicator } from '@radix-ui/react-checkbox'
import styled from 'styled-components'

import { baseColors } from '../../primitives/colors'
import { Tick } from '../icons/Tick'
import { Text } from '../Text'

const Container = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  border-radius: 10px;
  background-color: ${({ isChecked }) =>
    isChecked ? baseColors.purple200 : baseColors.white};

  &:hover {
    background-color: ${({ isChecked }) =>
      isChecked ? baseColors.purple200 : baseColors.purple100};
  }
`

const StyledRoot = styled(Root)`
  background-color: ${({ checked }) =>
    checked ? baseColors.purple600 : 'transparent'};
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${baseColors.purple600};
  margin-left: 24px;

  &:hover {
    background-color: ${({ checked }) =>
      checked ? baseColors.purple600 : baseColors.purple100};
  }
  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
  }
`

const StyledIndicator = styled(Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled(Text)`
  flex-grow: 1;
  cursor: pointer;
  padding: 16px 24px 16px 18px;
`

export interface CheckboxItem {
  id: string
  isChecked: boolean
  onCheckedChange: (checked: boolean) => void
  label: ReactNode
}

export interface CheckboxProps {
  item: CheckboxItem
  className?: string
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  item: { id, isChecked, onCheckedChange, label },
  className,
}) => (
  <Container className={className} isChecked={isChecked}>
    <StyledRoot checked={isChecked} onCheckedChange={onCheckedChange} id={id}>
      <StyledIndicator>
        <Tick fill={baseColors.white} width="13" height="13" />
      </StyledIndicator>
    </StyledRoot>
    <Label
      element="label"
      type="body"
      size="sm"
      fontWeight={500}
      color={baseColors.grey500}
      htmlFor={id}
    >
      {label}
    </Label>
  </Container>
)
