import { useCallback, useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'
import { useInterval } from 'react-use'

import { config } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { useGenerateScoresMutation } from 'store/score/scoreApi'
import { State } from 'store/state'

export const useRequestReportsBy = ({
  sessionId,
  apiVersion,
}: {
  sessionId: SessionId
  apiVersion: ApiVersion
}) => {
  const { company } = useAppSelector(
    (state: State) => ({
      company: state.companyReducer.company,
    }),
    shallowEqual
  )

  const { data: isScorable } = useGetIsScorableQuery({
    apiVersion,
    sessionId,
  })
  const [processing, setProcessing] = useState(false)
  const { completed } = useAuditBy(sessionId, apiVersion)
  const [generateScores] = useGenerateScoresMutation()

  const refreshCompany = useCallback(() => {
    if (localStorage.getItem(config.audit) === 'generate' && isScorable) {
      setProcessing(true)
    } else {
      localStorage.removeItem(config.audit)
      setProcessing(false)
    }
  }, [isScorable])

  useEffect(() => {
    if (completed) {
      refreshCompany()
    }
  }, [completed, refreshCompany])

  useInterval(() => {
    if (processing && completed) {
      refreshCompany()
    }
  }, 30000)

  const requestReports = () => {
    setProcessing(true)
    localStorage.setItem(config.audit, 'generate')
    if (company?.id) {
      generateScores({ apiVersion, sessionId })
    }
  }

  return { requestReports, processing }
}
