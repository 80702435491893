import React, { FunctionComponent } from 'react'

import { Container, ControlLabel, FormGroup, HelpBlock } from 'rsuite'
import styled from 'styled-components'

const FormGroupContainer = styled(Container)`
  margin-top: 10px;
`

interface FieldGroupProps {
  error: unknown
  footer: React.ReactNode
  label: React.ReactNode
  message: React.ReactNode
  name: string
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

export const FieldGroup: FunctionComponent<FieldGroupProps> = ({
  className,
  error,
  footer,
  label,
  message,
  name,
  children,
  style,
}) => (
  <FormGroup
    controlId={name}
    className={`${className || ''} ${error ? 'has-error' : ''}`.trim()}
    style={style}
  >
    {label && <ControlLabel htmlFor={name}>{label}</ControlLabel>}
    {children}
    {message && <HelpBlock tooltip>{message}</HelpBlock>}
    {footer && <FormGroupContainer>{footer}</FormGroupContainer>}
  </FormGroup>
)
