import React, { FunctionComponent, useEffect } from 'react'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import { Initiative } from 'features/strategy/Initiative'
import { StrategyDueBar } from 'features/strategy/StrategyDueBar'
import {
  useCheckIsNewStrategyDueQuery,
  useUpdateStrategyMutation,
} from 'store/strategy/strategyApi'

import { Strategy } from 'store/strategy/types'
import { findInsightsAndTasks } from 'utils/findInsightsAndTasks'
import { scrollTop } from 'utils/scrollTop'

import { ActionBar } from './ActionBar'
import { EditStrategyIntro } from './EditStrategyIntro'

const Container = styled.article`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background-color: ${baseColors.white};
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
`

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface EditStrategyStep3Props {
  strategy: Strategy
}
export const EditStrategyStep3: FunctionComponent<EditStrategyStep3Props> = ({
  strategy,
}) => {
  const { data: isNewStrategyDue } = useCheckIsNewStrategyDueQuery()
  const [
    updateStrategy,
    {
      isLoading: isUpdateStrategyLoading,
      isSuccess: isUpdateStrategySuccess,
      isError: isUpdateStrategyError,
    },
  ] = useUpdateStrategyMutation()

  useEffect(() => {
    if (isUpdateStrategySuccess) {
      scrollTop()
    }
  })

  return (
    <>
      <EditStrategyIntro companyInsights={strategy.companyInsights} />
      <PlanWrapper>
        {strategy.companyInitiatives.map(companyInitiative => {
          const { relevantCompanyInsights, tasks } = findInsightsAndTasks({
            insightsCode: companyInitiative.insightsCode,
            recommendation: companyInitiative.recommendation,
            companyInsights: strategy.companyInsights,
            companyTasks: strategy.companyTasks,
          })
          return (
            <Container key={companyInitiative.id}>
              <Initiative
                recommendation={companyInitiative.recommendation}
                companyInsights={relevantCompanyInsights}
                companyTasks={tasks}
              />
            </Container>
          )
        })}
      </PlanWrapper>

      {isNewStrategyDue ? (
        <StrategyDueBar />
      ) : (
        <ActionBar
          isLast
          isLoading={isUpdateStrategyLoading}
          isError={isUpdateStrategyError}
          previousLabel="strategy.draft.stepThreePreviousLabel"
          nextLabel="strategy.draft.stepThreeNextLabel"
          onPreviousClick={() =>
            updateStrategy({
              id: strategy.id,
              draftStep: 2,
            })
          }
          onNextClick={() =>
            updateStrategy({
              id: strategy.id,
              draftStep: null,
              committedAt: new Date(),
            })
          }
        />
      )}
    </>
  )
}
