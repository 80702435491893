import { useRef } from 'react'

import { useIntersection } from 'react-use'

export const useIsIntersection = () => {
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  })

  return {
    isIntersection: intersection?.isIntersecting ?? false,
    intersectionRef,
  }
}
