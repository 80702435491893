import { FunctionComponent } from 'react'

import { IconProps } from './types'

export enum ChevronDirection {
  DOWN = 'down',
  UP = 'up',
  LEFT = 'left',
  RIGHT = 'right',
}

const getDegreeRotation = (direction: ChevronDirection) => {
  switch (direction) {
    case ChevronDirection.UP:
      return 180
    case ChevronDirection.LEFT:
      return 90
    case ChevronDirection.RIGHT:
      return 270
    case ChevronDirection.DOWN:
    default:
      return 0
  }
}

interface ChevronProps extends IconProps {
  direction?: ChevronDirection
}

export const Chevron: FunctionComponent<ChevronProps> = ({
  className,
  outline = 'currentColor',
  width = '12',
  height = '12',
  direction = ChevronDirection.DOWN,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ transform: `rotate(${getDegreeRotation(direction)}deg)` }}
  >
    <path
      d="M6.718 8.778a.746.746 0 0 1-.53.222h-.38a.766.766 0 0 1-.53-.222L.148 3.584a.508.508 0 0 1 0-.717l.71-.718a.488.488 0 0 1 .7 0l4.44 4.497 4.44-4.497a.497.497 0 0 1 .71 0l.7.718a.508.508 0 0 1 0 .717l-5.13 5.194Z"
      fill={outline}
    />
  </svg>
)
