import { FunctionComponent } from 'react'

import { Grid } from '@theme-ui/components'

import { Colors } from '@fairhq/common'

import Icon from 'ui-kit/Icon'

import { Document } from '../types'

import { Star } from './Star'

interface StarsProps {
  score: Document['score']
}

export const Stars: FunctionComponent<StarsProps> = ({ score }) => {
  if (!score) {
    return <Icon name="add" sx={{ ml: 2 }} />
  }
  const color =
    score >= 75 ? Colors.GREEN : score >= 50 ? Colors.YELLOW : Colors.RED

  return (
    <Grid columns={4} gap="2px" sx={{ color, mt: '-2px', ml: 2 }}>
      <Star filled={score > 0} />
      <Star filled={score > 25} />
      <Star filled={score > 50} />
      <Star filled={score > 75} />
    </Grid>
  )
}
