import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { makeTransition } from '@fairhq/common'
import { DocumentStatus } from 'store/document/types'

import Icon from 'ui-kit/Icon'
import RouterLink from 'ui-kit/RouterLink'

type Props = {
  label: string
  areaCode: string
  status?: DocumentStatus
}

const getStatusComp = (t: TFunction) => (status?: string) => {
  if (!status) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  const color = status === DocumentStatus.REJECTED ? 'red' : 'grey400'
  const iconProps: React.ComponentProps<typeof Icon> =
    status === DocumentStatus.REJECTED
      ? { name: 'unavailable', sx: { ml: 1, mt: '-1px' } }
      : (status === DocumentStatus.ACCEPTED && {
          name: 'checkboxCheckedRounded',
          sx: { ml: 1, mt: '-1px', fill: 'green' },
        }) || { name: 'fail', color: 'currentColor', sx: { ml: 1, mt: '-1px' } }

  if (
    status === DocumentStatus.ACCEPTED ||
    status === DocumentStatus.REJECTED ||
    status === DocumentStatus.PENDING
  ) {
    return (
      <Text
        as="div"
        variant="bodyBold"
        sx={{ color, flex: '0 0 auto', textAlign: 'right' }}
      >
        <>
          {t(`area.documents.status.${status}`)}
          <Icon {...iconProps} />
        </>
      </Text>
    )
  }

  return null
}

const DocumentCard: FunctionComponent<Props> = ({
  label,
  areaCode,
  status,
}) => {
  const { t } = useTranslation()
  const statusComp = getStatusComp(t)(status)

  return (
    <RouterLink to={`/documents/${areaCode}/upload`}>
      <Flex
        sx={{
          bg: 'white',
          borderRadius: 12,
          height: 66,
          px: 4,
          py: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'grey500',
          transition: makeTransition('color'),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Icon name="document" sx={{ mr: 2 }} />
        <Text as="div" variant="bodyBold" sx={{ mr: 4, flex: '1 1 auto' }}>
          {label}
        </Text>
        {statusComp ?? null}
      </Flex>
    </RouterLink>
  )
}

export default DocumentCard
