import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

const Container = styled.section`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 64px;
  background-color: ${baseColors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  display: flex;
  justify-content: center;
  pointer-events: none;
  color: ${baseColors.purple600};
  z-index: 1;
`

interface StickyBarProps {
  children: React.ReactNode
}

export const StickyBar: FunctionComponent<StickyBarProps> = ({ children }) => (
  <Container tabIndex={0}>{children}</Container>
)
