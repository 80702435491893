import React, { FunctionComponent } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { space } from '@fairhq/common'

import { Campaign } from './compound/Campaign'
import { useScheduledCampaigns } from './hooks/useScheduledCampaigns'
import { ScheduledCampaignStatus } from './types'

const Container = styled.div`
  margin-bottom: ${space[10]}px;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: ${space[5]}px;
`

export const ScheduledCampaigns: FunctionComponent = () => {
  const { t } = useTranslation()
  const scheduledCampaigns = useScheduledCampaigns()
  return scheduledCampaigns.length > 0 ? (
    <Container>
      <HeadingContainer>
        <Heading sx={{ mr: 4 }}>{t('campaigns.active.title')} </Heading>
        <Text as="div" variant="label">
          {t('campaigns.active.description')}
        </Text>
      </HeadingContainer>
      {scheduledCampaigns.map(
        ({
          scheduledCampaignId,
          campaign: {
            id: campaignId,
            code: campaignCode,
            theme: { code: themeCode, image, color },
            frequency,
            totalNudges,
          },
          owner,
          startDate,
          publishTime,
          nudgesSent,
          status,
          channel,
          nudgesToSend,
          nextNudgeOn,
        }) => (
          <Campaign key={scheduledCampaignId}>
            <Campaign.LeftColumn>
              <Campaign.Visual image={image} />
              <Campaign.Content>
                <Campaign.Theme themeCode={themeCode} color={color} />
                <Campaign.Title campaignCode={campaignCode} />
                <Campaign.Description campaignCode={campaignCode} />
                <Campaign.Footer>
                  <Campaign.User owner={owner} />
                  {status === ScheduledCampaignStatus.PENDING ? (
                    <Campaign.GenericTiming
                      totalNudges={totalNudges}
                      frequency={frequency}
                    />
                  ) : (
                    <Campaign.InProgressTiming
                      frequency={frequency}
                      nudgesToSend={nudgesToSend}
                      nextNudgeOn={nextNudgeOn}
                    />
                  )}
                  {channel && (
                    <Campaign.Channel
                      channelName={channel.name}
                      isPrivate={channel.isPrivate}
                    />
                  )}
                </Campaign.Footer>
              </Campaign.Content>
            </Campaign.LeftColumn>
            <Campaign.RightColumn>
              <Campaign.Progress
                sent={nudgesSent}
                total={totalNudges}
                status={status}
              >
                {status === ScheduledCampaignStatus.PENDING ? (
                  <Campaign.Date startDate={startDate} />
                ) : (
                  <Campaign.Sent
                    sent={nudgesSent}
                    total={totalNudges}
                    status={status}
                  />
                )}
              </Campaign.Progress>
              <Campaign.Dropdown
                campaignId={campaignId}
                scheduledCampaignId={scheduledCampaignId}
                campaignCode={campaignCode}
                startDate={startDate}
                publishTime={publishTime}
                status={status}
                channel={channel}
              />
            </Campaign.RightColumn>
          </Campaign>
        )
      )}
    </Container>
  ) : null
}
