import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useMount, usePrevious } from 'react-use'
import { Alert as RSAlert } from 'rsuite'
import styled from 'styled-components'

import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

import { ReactComponent as ErrorIcon } from './error.svg'
import { ReactComponent as SuccessIcon } from './success.svg'

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-width: 24px !important;
    margin-right: 20px;
  }
`

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

// We're filtering out these errors, as they are not _real_ errors,
// they're just a thing that RTK Query uses to track component subscriptions
// See https://stackoverflow.com/questions/69789058/what-does-this-error-mean-in-redux-toolkit
const isRTKQueryConditionError = (
  status: { message: string; name: string; type: string } | undefined
): boolean => {
  const { message, type } = status || {}
  return type === 'error' && (message || '').includes('executeQuery')
}

export const useAlert = () => {
  const { t } = useTranslation()
  const { count, status } = useAppSelector((state: State) => ({
    count: state.statusReducer.count,
    status: state.statusReducer.status,
  }))
  const previousCount = usePrevious(count)
  const { pathname } = useLocation()

  useMount(() => {
    RSAlert.config({ duration: 5000 })
  })

  useEffect(() => {
    if (
      count !== previousCount &&
      status &&
      !pathname.startsWith('/sign') &&
      !pathname.startsWith('/survey') &&
      !pathname.startsWith('/hello')
    ) {
      const statusMessage = t(`${status?.type}.${status?.name}`, {
        defaultValue: status?.message,
      })

      if (
        status.type === 'error' &&
        !isRTKQueryConditionError(status) &&
        status.message !== 'company/getAPIVersion/rejected'
      ) {
        RSAlert.error(
          <Container>
            <ErrorIcon />
            <Ellipsis>{statusMessage}</Ellipsis>
          </Container>
        )
      } else if (status.type === 'success') {
        RSAlert.success(
          <Container>
            <SuccessIcon />
            <Ellipsis>{statusMessage}</Ellipsis>
          </Container>
        )
      }
    }
  }, [count, pathname, previousCount, status, t])
}
