import { useEffect, useState, FunctionComponent } from 'react'

import { CardElement } from '@stripe/react-stripe-js'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
import { ControlLabel, FormGroup } from 'rsuite'
import styled, { css } from 'styled-components'

import { Field } from 'components/form/Field'
import { Form } from 'components/form/Form'
import { InputButton } from 'components/form/InputButton'
import { DATE_FORMAT, CARD_ELEMENT_OPTIONS } from 'const'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getCoupon } from 'store/payment/paymentSlice'
import { Price, PriceLookupKeys } from 'store/payment/types'
import { State } from 'store/state'
import { Message } from 'styled/message'
import { toAmount, toCurrency } from 'utils/currency'
import { toDate } from 'utils/dates'

import { useCardForm } from './hooks/useCardForm'

const CardFormGroup = styled(FormGroup)`
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 10px;
  padding: 15px 18px;
`

const DiscountField = styled(Field)<{ errorMessage?: string }>`
  ${({ errorMessage, theme }) =>
    !errorMessage &&
    css`
      color: ${theme.colors.green};
    `}
`

interface CardFormProps {
  endOnboarding: () => void
  hasCustomer: boolean
  invoicingEnabled: boolean
  size: number
  price?: Price
  amount: string
}

export const CardForm: FunctionComponent<CardFormProps> = ({
  endOnboarding,
  price,
  hasCustomer,
  size,
  invoicingEnabled,
  amount,
}) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const { coupon, loading, tax } = useAppSelector((state: State) => ({
    coupon: state.paymentReducer.coupon,
    loading: state.paymentReducer.loading,
    tax: state.paymentReducer.tax,
  }))
  const [invalidCoupon, setInvalidCoupon] = useState(false)
  const [checking, setChecking] = useState(false)
  const [unverifiedCoupon, setUnverifiedCoupon] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const { errorMessage, onSubmit, submitting } = useCardForm({
    endOnboarding,
    price,
    hasCustomer,
    setFeedbackMessage,
    size: price?.billing_scheme === 'tiered' ? +size : undefined,
    invoicingEnabled,
  })
  const discountLabel = coupon
    ? (coupon?.percent_off && `${coupon.percent_off}%`) ||
      toCurrency({
        number: (coupon?.amount_off ?? 0) / 100,
        language: i18n.language,
      })
    : ''
  const amountStringWithVAT = toAmount({
    price,
    coupon,
    discount: coupon
      ? ((price?.unit_amount || 0) / 100) * ((100 - coupon.percent_off) / 100)
      : undefined,
    language: i18n.language,
    tax: (tax?.percentage || 0) / 100,
    size,
  })

  useEffect(() => {
    if (checking && !loading) {
      setChecking(false)
      if (!coupon) {
        setInvalidCoupon(true)
      } else {
        setInvalidCoupon(false)
      }
    }
  }, [checking, loading, coupon])

  if (price?.lookup_key === PriceLookupKeys.PRIVEQSTANDARD) {
    return (
      <>
        <Message>{t('hello.payment.byInvoicePrivEq')}</Message>
        <Form
          loading={submitting}
          disabled={submitting}
          errorMessage={errorMessage}
          onSubmit={() => onSubmit(invalidCoupon ? undefined : coupon)}
          submitBtnLabel={hasCustomer && 'Get started'}
        >
          {feedbackMessage && !errorMessage && (
            <Message type="indication">{t(feedbackMessage)}</Message>
          )}
        </Form>
      </>
    )
  }
  if (price?.lookup_key === PriceLookupKeys.STANDARD_YEARLY) {
    return (
      <>
        <Message>
          <Trans
            i18nKey="hello.payment.pricingMessage"
            components={[<a href="mailto:hello@fairhq.co">pricing page</a>]}
            values={{
              amount,
              tax: `${tax?.percentage || 0}%`,
            }}
          />
        </Message>
        {invoicingEnabled ? (
          <Message>{t('hello.payment.byInvoice')}</Message>
        ) : undefined}
        <Form
          loading={submitting}
          disabled={submitting}
          errorMessage={errorMessage}
          onSubmit={() => onSubmit(invalidCoupon ? undefined : coupon)}
          submitBtnLabel={
            hasCustomer
              ? t('hello.payment.card.pay', { amount: amountStringWithVAT })
              : undefined
          }
        >
          {invoicingEnabled || coupon?.percent_off === 100 ? undefined : (
            <>
              <ControlLabel>{t('hello.payment.cardNumber')}</ControlLabel>
              <CardFormGroup>
                <CardElement
                  options={{ ...CARD_ELEMENT_OPTIONS, disabled: !hasCustomer }}
                />
              </CardFormGroup>
            </>
          )}
          <DiscountField
            accepter={InputButton}
            name="discount"
            label={t('form.discount.label')}
            btnLabel={t('form.discount.apply')}
            placeholder={t('form.discount.placeholder')}
            className={invalidCoupon || !coupon ? undefined : 'success'}
            onChange={(value: string) => {
              setUnverifiedCoupon(value)
              setInvalidCoupon(false)
            }}
            onClick={() => {
              setChecking(true)
              dispatch(getCoupon(unverifiedCoupon))
            }}
            errorMessage={invalidCoupon && t('form.coupon.invalid')}
          />

          {feedbackMessage && !errorMessage && (
            <Message type="indication">{t(feedbackMessage)}</Message>
          )}
          {!invalidCoupon && coupon && (
            <Message type="success">
              {coupon.duration_in_months
                ? t('hello.payment.youAppliedACouponOfUntil', {
                    percent: discountLabel,
                    until: toDate(
                      i18n.language,
                      dayjs().add(coupon.duration_in_months, 'month').format()
                    ).format(DATE_FORMAT),
                  })
                : t('hello.payment.youAppliedACouponOf', {
                    percent: discountLabel,
                  })}
            </Message>
          )}
        </Form>
      </>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
