import React, { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

interface DashboxHeadingProps {
  heading: string | React.ReactNode
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

export const DashboxHeading: FunctionComponent<DashboxHeadingProps> = ({
  heading,
  children,
  sx,
}) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 84,
      pt: 1,
      px: 8,
      ...sx,
    }}
  >
    <Box>
      {typeof heading === 'string' ? (
        <Text as="div" variant="caps">
          {heading}
        </Text>
      ) : (
        heading
      )}
    </Box>
    <Box sx={{ ml: 2, flex: '0 0 auto' }}>{children}</Box>
  </Flex>
)
