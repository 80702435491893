import React, { FunctionComponent } from 'react'

interface EditProps {
  className?: string
  stroke?: string
}

export const Edit: FunctionComponent<EditProps> = ({
  className,
  stroke = 'currentColor',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4 20H8.17525L19.1353 9.03997C19.689 8.4863 20 7.73536 20 6.95235C20 6.16934 19.689 5.4184 19.1353 4.86472C18.5816 4.31105 17.8307 4 17.0477 4C16.2646 4 15.5137 4.31105 14.96 4.86472L4 15.8248V20Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 6L18 10"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
