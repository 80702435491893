import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useGetScheduledCampaignsQuery } from '../campaignsApi'
import { ScheduledCampaignStatus, ScheduledCampaign } from '../types'

export const useScheduledCampaigns = (): ScheduledCampaign[] => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { scheduledCampaigns } = useGetScheduledCampaignsQuery(
    { sessionId },
    {
      selectFromResult: ({ data }) => ({
        scheduledCampaigns: data?.filter(
          campaign =>
            campaign.status === ScheduledCampaignStatus.LIVE ||
            campaign.status === ScheduledCampaignStatus.PENDING
        ),
      }),
    }
  )
  return scheduledCampaigns || []
}
