import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeTransition } from '@fairhq/common'

import { VisualizationItemValueProps } from '../types'

export const ComparisonVisualizationItemValue: FunctionComponent<{
  item: VisualizationItemValueProps
  isScoreBy10?: boolean
}> = ({ item, isScoreBy10 }) => {
  const { t } = useTranslation()
  const label = item.value
    ? (isScoreBy10 && round(item.value / 10, 1)) || `${item.value}%`
    : t('na')
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        flex: 1,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          flex: 1,
          borderRadius: '12px 12px 0 0',
          bg: item.color,
          transition: makeTransition('opacity'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          minHeight: `${item.value > 100 ? 100 : item.value}%`,
          opacity: 0.8,
          position: 'relative',
        }}
      >
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{
            color: item.value >= 100 ? 'white' : 'grey500',
            top: item.value >= 100 ? '8px' : '-34px',
            position: 'absolute',
          }}
        >
          {label}
        </Text>
      </Box>
    </Box>
  )
}
