import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAuditBy } from 'hooks/useAuditBy'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementScopesEnum, EntitlementsEnum } from 'store/company/types'
import { count as countEmployees } from 'store/employee/employeeSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { SidebarGroupHeader } from './components/SidebarGroupHeader'
import { SidebarMenuItem } from './components/SidebarMenuItem'

export const AssessmentSidebarEmployees = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { totalEmployees, companyId } = useAppSelector(
    (state: State) => ({
      companyId: state.apiHeadersReducer.companyId,
      totalEmployees: state.employeeReducer.count,
    }),
    shallowEqual
  )
  const { data: companyEntitlements } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  useEffect(() => {
    if (totalEmployees === undefined) {
      dispatch(countEmployees())
    }
  }, [totalEmployees, dispatch])

  const { surveyPercentage } = useAuditBy()

  const fallbackEntitlements = [
    EntitlementsEnum.EMPLOYEES,
    EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT,
    EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT,
  ]

  const entitlements = companyEntitlements?.entitlements ?? fallbackEntitlements

  return (
    <>
      {companyEntitlements?.scopes.includes(EntitlementScopesEnum.SURVEY) && (
        <SidebarGroupHeader
          text={t('employees.title')}
          url="/survey"
          completion={`${Math.round(surveyPercentage ?? 0)}%`}
        />
      )}
      {entitlements.includes(EntitlementsEnum.EMPLOYEES) && (
        <SidebarMenuItem
          text={t('employees.total', { count: totalEmployees ?? 0 })}
          url="/employees"
          completion={`${totalEmployees ?? 0}`}
        />
      )}
    </>
  )
}
