import { FileType } from 'rsuite/es/Uploader'

import { sanitize } from '@fairhq/common'
import { CompanyDocument } from 'store/document/types'

export const transformDocumentToFormData = (
  document: Partial<CompanyDocument>,
  droppedFiles?: FileType[]
): FormData => {
  const formData = new FormData()
  const sanitizedDocument = sanitize(document)
  if (droppedFiles && droppedFiles?.length > 0) {
    droppedFiles.forEach(({ blobFile }) => {
      formData.append('files', blobFile as File)
    })
  }

  Object.keys(sanitizedDocument).forEach(key => {
    formData.append(key, sanitizedDocument[key])
  })

  return formData
}
