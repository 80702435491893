export const getLevelNumber = (level: string) => {
  if (level.includes('3')) {
    return 3
  }
  if (level.includes('2')) {
    return 2
  }

  if (level.includes('1')) {
    return 1
  }
  return 0
}
