import React, { ReactNode } from 'react'

import { ButtonToolbar, Form as RSForm, Icon } from 'rsuite'
import { Schema } from 'schema-typed'

import {
  FormContainer,
  FormFooter,
  LinkButton,
  RoundButton,
} from '../../styled'
import { Message } from '../../styled/message'

interface FormProps {
  checkTrigger?: 'change' | 'blur'
  disabled?: boolean
  errorMessage?: string
  footer?: ReactNode
  formValue?: any
  loading?: boolean
  model?: Schema
  onChange?: (formValue: any) => void
  onSubmit?: (isValid: boolean) => void
  submitBtnLabel?: string
  onAction?: () => void
  actionBtnLabel?: string
  submitBtnAppearance?: 'link' | 'primary'
  successMessage?: string
  title?: string
  withoutContainer?: boolean
  block?: boolean
}

export const Form = React.forwardRef<FormProps, any>(
  (
    {
      actionBtnLabel,
      onAction,
      checkTrigger,
      children,
      disabled,
      errorMessage,
      footer,
      formValue,
      loading,
      model,
      onSubmit,
      onChange,
      submitBtnLabel,
      submitBtnAppearance,
      successMessage,
      title,
      withoutContainer,
      block,
    },
    ref
  ) => {
    const Container = !withoutContainer ? FormContainer : React.Fragment
    return (
      <RSForm
        ref={ref}
        checkTrigger={checkTrigger || 'blur'}
        formValue={formValue}
        model={model}
        onChange={onChange}
        onSubmit={onSubmit}
      >
        <Container>
          {title && <h2>{title}</h2>}
          {children}
          <FormFooter>
            {errorMessage && (
              <Message type="error">
                <Icon icon="exclamation-circle" size="lg" /> {errorMessage}
              </Message>
            )}
            {successMessage && (
              <Message type="success">
                <Icon icon="check-circle" size="lg" /> {successMessage}
              </Message>
            )}
            {submitBtnLabel && (
              <ButtonToolbar>
                {actionBtnLabel ? (
                  <LinkButton onClick={onAction}>{actionBtnLabel}</LinkButton>
                ) : undefined}
                <RoundButton
                  disabled={disabled}
                  appearance={submitBtnAppearance || 'primary'}
                  type="submit"
                  loading={loading}
                  block={block}
                  data-testid="submit"
                >
                  {submitBtnLabel}
                </RoundButton>
              </ButtonToolbar>
            )}
            {footer}
          </FormFooter>
        </Container>
      </RSForm>
    )
  }
)
