import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Hash: FunctionComponent<IconProps> = ({
  outline = 'currentColor',
  className,
  width = '24',
  height = '24',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5677 4.14975C10.6503 3.60368 10.2747 3.09402 9.72858 3.01139C9.18251 2.92876 8.67285 3.30446 8.59022 3.85053L8.10487 7.05811H5.26315C4.71087 7.05811 4.26315 7.50582 4.26315 8.05811C4.26315 8.61039 4.71087 9.05811 5.26315 9.05811H7.80223L6.91192 14.9419H4C3.44772 14.9419 3 15.3896 3 15.9419C3 16.4942 3.44772 16.9419 4 16.9419H6.60929L6.16917 19.8505C6.08654 20.3966 6.46223 20.9063 7.0083 20.9889C7.55437 21.0715 8.06403 20.6958 8.14666 20.1498L8.63206 16.9419H13.8724L13.4323 19.8505C13.3497 20.3966 13.7254 20.9063 14.2715 20.9889C14.8175 21.0715 15.3272 20.6958 15.4098 20.1498L15.8952 16.9419H18.7368C19.2891 16.9419 19.7369 16.4942 19.7369 15.9419C19.7369 15.3896 19.2891 14.9419 18.7368 14.9419H16.1978L17.0882 9.05811H20C20.5523 9.05811 21 8.61039 21 8.05811C21 7.50582 20.5523 7.05811 20 7.05811H17.3908L17.8309 4.14975C17.9135 3.60368 17.5378 3.09402 16.9917 3.01139C16.4457 2.92876 15.936 3.30446 15.8534 3.85053L15.368 7.05811H10.1276L10.5677 4.14975ZM14.1751 14.9419L15.0654 9.05811H9.825L8.93469 14.9419H14.1751Z"
      fill={outline}
    />
  </svg>
)
