import { ChangeEventHandler, FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { SxProp } from 'theme-ui'

import { DATE_FORMAT_SESSION, DATE_FORMAT_SESSION_URL } from 'const'
import { Session } from 'store/company/types'
import { Select } from 'ui-kit/Select'
import { toDate } from 'utils/dates'

interface SessionDropdownProps {
  sessions: Session[]
  onChange?: ChangeEventHandler<{ value: string | number }>
  sx?: SxProp
  value?: string
  hidden?: boolean
}

export const SessionDropdown: FunctionComponent<SessionDropdownProps> = ({
  hidden,
  onChange,
  sessions,
  sx = {},
  value,
}) => {
  const { i18n } = useTranslation()

  if (hidden) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <Select
      onChange={onChange}
      sx={{ minWidth: 240, height: 42, ...sx }}
      value={value}
    >
      {sessions?.map(({ start_date: date }: any) => (
        <option
          key={date}
          value={toDate(i18n.language, date).format(DATE_FORMAT_SESSION_URL)}
        >
          {toDate(i18n.language, date).format(DATE_FORMAT_SESSION)}
        </option>
      ))}
    </Select>
  )
}
