import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { config, waitForResponse } from '@fairhq/common'

import { getAudit, getStatus } from 'store/audit/auditSlice'
import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { questionSessionActions } from 'store/questionSession/questionSessionSlice'

import { Area } from '../area/types'

import { assessmentApi } from './assessmentApi'
import { AssessmentState, Assessment } from './types'

const clear = createAction('assessment/clear')

const getStatuses = createAsyncThunk(
  'assessment/getStatuses',
  async (area: Partial<Area>, { dispatch }) => {
    const code = area.type === 'local' ? area.code?.split('.') : undefined
    dispatch(getStatus(area.code || ''))
    if (code?.length === 3) {
      dispatch(getStatus((area.code || '').replace(`.${code[2]}`, '')))
    }
    dispatch(getAudit())
  }
)

export const save = createAsyncThunk(
  'assessment/save',
  async (assessment: Partial<Assessment>, { dispatch, getState }) => {
    const result = await waitForResponse({
      callback: () => assessmentApi.save(getState, assessment),
    })
    dispatch(questionSessionActions.addAssessment(result))
    dispatch(questionSessionActions.next())
    if (!config.surveyUIEnabled) {
      dispatch(getStatuses(result.area))
    }
    return result
  }
)
export const update = createAsyncThunk(
  'assessment/update',
  async (
    {
      id,
      assessment,
    }: {
      id: string
      assessment: Partial<Assessment>
    },
    { dispatch, getState }
  ) => {
    const result = await waitForResponse({
      callback: () => assessmentApi.update(getState, id, assessment),
    })
    dispatch(questionSessionActions.addAssessment(result))
    dispatch(questionSessionActions.next())
    if (!config.surveyUIEnabled) {
      dispatch(getStatuses(result.area))
    }
    return result
  }
)

const initialState: Partial<AssessmentState> = { loading: false }

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(update.pending, state => {
        state.saving = true
        state.assessment = undefined
      })
      .addCase(update.fulfilled, (state, action) => {
        state.saving = false
        state.assessment = action.payload
      })
      .addCase(save.pending, state => {
        state.saving = true
        state.assessment = undefined
      })
      .addCase(save.fulfilled, (state, action) => {
        state.saving = false
        state.assessment = action.payload
      })
      .addMatcher(isPending('assessment'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isFulfilled('assessment'), state => {
        state.loading = false
      })
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isRejected('assessment'), handleErrorState)
  },
})

export const { reducer: assessmentReducer } = assessmentSlice
