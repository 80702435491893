import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { space, baseColors } from '@fairhq/common'

import { Channel, ChannelProps } from './Campaign.Channel'
import { Content } from './Campaign.Content'
import { Date, DateProps } from './Campaign.Date'
import { Description, DescriptionProps } from './Campaign.Description'
import { Dropdown, DropdownProps } from './Campaign.Dropdown'
import { Footer } from './Campaign.Footer'
import { GenericTiming, GenericTimingProps } from './Campaign.GenericTiming'
import {
  InProgressTiming,
  InProgressTimingProps,
} from './Campaign.InProgressTiming'
import { LeftColumn } from './Campaign.LeftColumn'
import { Progress, ProgressProps } from './Campaign.Progress'
import { RightColumn } from './Campaign.RightColumn'
import { ScheduleButton, ScheduleButtonProps } from './Campaign.ScheduleButton'
import { Sent, SentProps } from './Campaign.Sent'
import { SpecificTiming, SpecificTimingProps } from './Campaign.SpecificTiming'
import { Theme, ThemeProps } from './Campaign.Theme'
import { Title, TitleProps } from './Campaign.Title'
import { User, UserProps } from './Campaign.User'
import { Visual, VisualProps } from './Campaign.Visual'
import {
  ScheduleModal,
  ScheduleModalProps,
} from './modals/Campaign.ScheduleModal'

const Card = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
  padding: ${space[6]}px ${space[8]}px;
  display: grid;
  grid-template-columns: 1fr 170px;
  column-gap: ${space[5]}px;
  margin-bottom: ${space[2]}px;
`

interface CampaignComposition {
  Visual: FunctionComponent<VisualProps>
  Content: FunctionComponent<{ children?: React.ReactNode }>
  LeftColumn: FunctionComponent<{ children?: React.ReactNode }>
  Theme: FunctionComponent<ThemeProps>
  Title: FunctionComponent<TitleProps>
  Description: FunctionComponent<DescriptionProps>
  Footer: FunctionComponent<{ children?: React.ReactNode }>
  User: FunctionComponent<UserProps>
  SpecificTiming: FunctionComponent<SpecificTimingProps>
  GenericTiming: FunctionComponent<GenericTimingProps>
  Progress: FunctionComponent<ProgressProps>
  Sent: FunctionComponent<SentProps>
  Date: FunctionComponent<DateProps>
  ScheduleButton: FunctionComponent<ScheduleButtonProps>
  ScheduleModal: FunctionComponent<ScheduleModalProps>
  RightColumn: FunctionComponent<{ children?: React.ReactNode }>
  Dropdown: FunctionComponent<DropdownProps>
  Channel: FunctionComponent<ChannelProps>
  InProgressTiming: FunctionComponent<InProgressTimingProps>
}

interface CampaignProps {
  children?: React.ReactNode
  id?: string
}

const Campaign: FunctionComponent<CampaignProps> & CampaignComposition = ({
  children,
  id,
}) => <Card id={id}>{children}</Card>

Campaign.Visual = Visual
Campaign.Content = Content
Campaign.LeftColumn = LeftColumn
Campaign.Theme = Theme
Campaign.Title = Title
Campaign.Description = Description
Campaign.Footer = Footer
Campaign.User = User
Campaign.SpecificTiming = SpecificTiming
Campaign.GenericTiming = GenericTiming
Campaign.InProgressTiming = InProgressTiming
Campaign.Progress = Progress
Campaign.Sent = Sent
Campaign.Date = Date
Campaign.ScheduleButton = ScheduleButton
Campaign.ScheduleModal = ScheduleModal
Campaign.RightColumn = RightColumn
Campaign.Dropdown = Dropdown
Campaign.Channel = Channel

export { Campaign }
