import { FunctionComponent, useState } from 'react'

import { Box, Flex, Text, Grid } from '@theme-ui/components'

import { ScoreChart } from 'features/reportsAreasCore/components/ScoreChart'

import { OldCompanyRecommendation } from 'store/oldRecommendations/types'
import Icon, { IconName } from 'ui-kit/Icon'

import { OldRecommendationCard } from './OldRecommendationCard'

interface OldRecommendationGroupProps {
  title: string
  oldRecommendations: Partial<OldCompanyRecommendation>[]
  score: number
  icon?: string
  defaultOpened: boolean
}

export const OldRecommendationGroup: FunctionComponent<
  OldRecommendationGroupProps
> = ({ title, oldRecommendations, score, icon, defaultOpened }) => {
  const [opened, setOpened] = useState<boolean>(defaultOpened)
  return (
    <Box
      sx={{
        borderRadius: 20,
        bg: 'white',
        px: 8,
        py: 6,
        '& + &': { mt: 4 },
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          mx: -8,
          my: -6,
          px: 8,
          py: 6,
          userSelect: 'none',
        }}
        onClick={() => setOpened(!opened)}
      >
        <Box>
          <Box sx={{ alignItems: 'center' }}>
            <Text as="div" variant="h2">
              {title}
              <Icon
                name={opened ? 'arrowDown' : 'arrowRight'}
                sx={{ ml: 2 }}
                small
              />
            </Text>
          </Box>
        </Box>
        {icon && <ScoreChart score={score} icon={icon as IconName} />}
      </Flex>
      {opened && (
        <Grid columns={2} sx={{ columnGap: 4, rowGap: 3, mt: 8 }}>
          {oldRecommendations.map(({ oldRecommendation, status }) => (
            <OldRecommendationCard
              key={oldRecommendation?.id}
              oldRecommendation={oldRecommendation}
              status={status}
              dark
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}
