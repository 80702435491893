export enum Colors {
  PURPLE = 'purple',
  PURPLE100 = 'purple100',
  PURPLE200 = 'purple200',
  PURPLE300 = 'purple300',
  PURPLE400 = 'purple400',
  PURPLE500 = 'purple500',
  PURPLE600 = 'purple600',
  PURPLE700 = 'purple700',
  PURPLE800 = 'purple800',
  PURPLE900 = 'purple900',
  YELLOW = 'yellow',
  BLUE = 'blue',
  BLUE000 = 'blue000', // equivalente to secondaryBlue in new design system
  BLUE100 = 'blue100', // equivalente to secondaryBlue in new design system
  BLUE200 = 'blue200', // equivalente to secondaryBlue in new design system
  BLUE300 = 'blue300', // equivalente to secondaryBlue in new design system
  BLUE400 = 'blue400', // equivalente to secondaryBlue in new design system
  BLUE500 = 'blue500', // equivalente to secondaryBlue in new design system
  BLUE600 = 'blue600', // equivalente to secondaryBlue in new design system
  BLUE700 = 'blue700', // equivalente to secondaryBlue in new design system
  BLUE800 = 'blue800', // equivalente to secondaryBlue in new design system
  RED = 'red',
  RED000 = 'red000', // equivalente to scoreRedScoredRed in new design system
  RED100 = 'red100', // equivalente to scoreRedScoredRed in new design system
  RED200 = 'red200', // equivalente to scoreRedScoredRed in new design system
  RED300 = 'red300', // equivalente to scoreRedScoredRed in new design system
  RED400 = 'red400', // equivalente to scoreRedScoredRed in new design system
  RED500 = 'red500', // equivalente to scoreRedScoredRed in new design system
  RED600 = 'red600', // equivalente to scoreRedScoredRed in new design system
  RED700 = 'red700', // equivalente to scoreRedScoredRed in new design system
  RED800 = 'red800', // equivalente to scoreRedScoredRed in new design system
  GREEN = 'green',
  PINK = 'pink',
  GREY500 = 'grey500',
  GREY400 = 'grey400',
  GREY300 = 'grey300',
  GREY200 = 'grey200',
  GREY200_2 = 'grey200_2',
  GREY100 = 'grey100',
  WHITE = 'white',
  BLACK = 'black',
  MODAL = 'modal',
  COOL_GREY_100 = 'coolGrey100',
  COOL_GREY_200 = 'coolGrey200',
  COOL_GREY_300 = 'coolGrey300',
  COOL_GREY_400 = 'coolGrey400',
  COOL_GREY_500 = 'coolGrey500',
  COOL_GREY_600 = 'coolGrey600',
  COOL_GREY_700 = 'coolGrey700',
  COOL_GREY_800 = 'coolGrey800',
  COOL_GREY_900 = 'coolGrey900',
  FAIR_GREEN_100 = 'fairGreen100',
  FAIR_GREEN_200 = 'fairGreen200',
  FAIR_GREEN_400 = 'fairGreen400',
  FAIR_GREEN_600 = 'fairGreen600',
}

export const baseColors: Record<Colors, string> = {
  [Colors.PURPLE]: '#5347D1',
  [Colors.PURPLE100]: '#F7F6FE',
  [Colors.PURPLE200]: '#EBE9FB',
  [Colors.PURPLE300]: '#D4D1F5',
  [Colors.PURPLE400]: '#BCB8EA',
  [Colors.PURPLE500]: '#8881DA',
  [Colors.PURPLE600]: '#6057C7',
  [Colors.PURPLE700]: '#483EB1',
  [Colors.PURPLE800]: '#2E2877',
  [Colors.PURPLE900]: '#1E1A4D',

  [Colors.YELLOW]: '#f2a556',
  [Colors.BLUE]: '#43c4eb',
  [Colors.BLUE000]: '#E9F9FF',
  [Colors.BLUE100]: '#C7EFFC',
  [Colors.BLUE200]: '#8DDAF3',
  [Colors.BLUE300]: '#44BEE1',
  [Colors.BLUE400]: '#119DC0',
  [Colors.BLUE500]: '#0A6681',
  [Colors.BLUE600]: '#094D63',
  [Colors.BLUE700]: '#083446',
  [Colors.BLUE800]: '#041E2A',

  [Colors.RED]: '#f26956',
  [Colors.RED000]: '#FFF4F4',
  [Colors.RED100]: '#FDE2E1',
  [Colors.RED200]: '#FAC1BE',
  [Colors.RED300]: '#F69790',
  [Colors.RED400]: '#F46052',
  [Colors.RED500]: '#B3291B',
  [Colors.RED600]: '#8D160A',
  [Colors.RED700]: '#640D02',
  [Colors.RED800]: '#3E0400',

  [Colors.GREEN]: '#38e086',
  [Colors.PINK]: '#ff6584',

  [Colors.GREY500]: '#0a1b40',
  [Colors.GREY400]: '#4c5766',
  [Colors.GREY300]: '#98a3b3',
  [Colors.GREY200]: '#c4cbd4',
  [Colors.GREY200_2]: '#CFD6E2',
  [Colors.GREY100]: '#f2f7fa',
  [Colors.WHITE]: '#fff',
  [Colors.BLACK]: '#000',

  [Colors.MODAL]: '#FFE5D6',

  [Colors.COOL_GREY_100]: '#F8F9FC',
  [Colors.COOL_GREY_200]: '#F4F6FA',
  [Colors.COOL_GREY_300]: '#E0E4EB',
  [Colors.COOL_GREY_400]: '#B6BCC9',
  [Colors.COOL_GREY_500]: '#838995',
  [Colors.COOL_GREY_600]: '#5E636E',
  [Colors.COOL_GREY_700]: '#454A54',
  [Colors.COOL_GREY_800]: '#29303D',
  [Colors.COOL_GREY_900]: '#151C28',

  [Colors.FAIR_GREEN_100]: '#C3F3D3',
  [Colors.FAIR_GREEN_200]: '#92E0A7',
  [Colors.FAIR_GREEN_400]: '#3FA366',
  [Colors.FAIR_GREEN_600]: '#10512E',
}
