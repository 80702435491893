import { uniqBy } from 'lodash'

import { Legend, VisualizationItemProps } from '../types'

export function convertIntoLegend(items: VisualizationItemProps[]): Legend[] {
  const legend: Legend[] = []

  items.forEach(item => {
    legend.push(...item.values.map(({ color, label }) => ({ color, label })))
  })

  return uniqBy(legend, 'label')
}
