import React, { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { ArrowWithBackground } from 'components/ArrowWithBackground'
import { CompanyInsightWithoutAreas } from 'features/insights/types'

import { Recommendation } from 'store/strategy/types'
import { Task as TaskType } from 'store/tasks/types'

import { InitiativeTask } from './InitiativeTask'

const RecAndTasksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 32px;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const InsightsContainer = styled.div<{ greyedOut: boolean }>`
  border-top: 1px solid
    ${({ greyedOut }) =>
      greyedOut ? baseColors.coolGrey100 : baseColors.purple200};
  padding-top: 12px;
`

const LeftBox = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-self: stretch;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const LeftBoxTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const StyledSpan = styled.span`
  font-weight: normal;
`

const List = styled.ul`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: start;
  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 8px;
  }
`
interface InitiativeEditableProps {
  companyInsights: CompanyInsightWithoutAreas[]
  recommendation: Recommendation
  greyedOut?: boolean
  companyTasks: TaskType[]
}

export const Initiative: FunctionComponent<InitiativeEditableProps> = ({
  companyInsights,
  recommendation,
  greyedOut = false,
  companyTasks,
}) => (
  <RecAndTasksContainer>
    <LeftBox>
      <LeftBoxTop>
        <Text
          element="h3"
          type="body"
          size="lg"
          fontWeight={600}
          color={greyedOut ? baseColors.coolGrey800 : baseColors.purple900}
        >
          {recommendation.description}
        </Text>
        {recommendation.notionURL && (
          <StyledLink
            aria-label={`Open the guide: ${recommendation.description}`}
            to={`/strategy/recommendation/${recommendation.code}`}
          >
            <Text
              element="p"
              type="body"
              size="sm"
              fontWeight={600}
              color={greyedOut ? baseColors.coolGrey900 : baseColors.purple800}
            >
              Open the guide
            </Text>
            <ArrowWithBackground />
          </StyledLink>
        )}
      </LeftBoxTop>
      {companyInsights.length > 0 && (
        <InsightsContainer greyedOut={greyedOut}>
          <Text
            element="p"
            type="body"
            size="sm"
            fontWeight={600}
            color={baseColors.black}
          >
            {`Insights: `}
            <StyledSpan>
              {companyInsights
                .map(({ insight }) => insight.description)
                .join(', ')}
            </StyledSpan>
          </Text>
        </InsightsContainer>
      )}
    </LeftBox>

    <List>
      {companyTasks.length > 0 &&
        companyTasks.map(companyTask => (
          <InitiativeTask
            key={companyTask.id}
            task={companyTask}
            greyedOut={greyedOut}
          />
        ))}
    </List>
  </RecAndTasksContainer>
)
