import { useTranslation } from 'react-i18next'

import { GraphItem } from 'components/visualization/types'
import { useScoreArea } from 'features/reportsCore/hooks/useScoreArea'
import { tCode } from 'utils/tCode'

export const useScoreBreakdown = (area?: string) => {
  const { t } = useTranslation()
  const tArea = tCode(t)

  const [scoreData] = useScoreArea(area)
  const { scores } = scoreData

  const graphItems: GraphItem[] | undefined = area
    ? scores.map(({ area_code, area_title, score, max }) => ({
        key: area_code,
        label: tArea(area_code, 'title', area_title),
        max: 100,
        value: score ? Math.round((score / max) * 100) : 0,
      }))
    : []

  return { graphItems }
}
