import { User } from '@auth0/auth0-react'

import { IntercomProps } from 'react-use-intercom'

import { getIntercomCompanyId } from 'utils/getIntercomCompanyId'

export const identifyIntercomUser = ({
  user,
  userHash,
  companyId,
  companyName,
  companySector,
  companyCreatedAt,
  updateIntercom,
  shutdownIntercom,
  isAdmin,
  auditType,
}: {
  user: User | undefined
  userHash: string
  companyId: number | undefined
  companyName: string | undefined
  companySector: string | undefined
  companyCreatedAt?: string | undefined
  updateIntercom: (user: Partial<IntercomProps> | undefined) => void
  shutdownIntercom?: () => void
  isAdmin?: boolean
  auditType?: string
}) => {
  if (isAdmin && shutdownIntercom) {
    shutdownIntercom()
  } else {
    const intercomUser: Partial<IntercomProps> = {
      email: user?.email || '',
      userHash,
    }

    if (companyId && companyName) {
      intercomUser.company = {
        companyId: getIntercomCompanyId(companyId),
        createdAt: companyCreatedAt,
        name: companyName,
        industry: companySector,
        customAttributes: {
          audit_type: auditType,
        },
      }
    }

    updateIntercom(intercomUser)
  }
}
