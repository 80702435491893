import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

interface TagProps {
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

const Tag: FunctionComponent<TagProps> = ({ children, sx }) => (
  <Text
    as="div"
    variant="caps"
    sx={{
      bg: 'red',
      display: 'inline-block',
      color: 'white',
      whiteSpace: 'nowrap',
      my: '-3px',
      px: 2,
      py: '3px',
      borderRadius: 100,
      flex: '0 0 auto',
      ...sx,
    }}
  >
    {children}
  </Text>
)

export default Tag
