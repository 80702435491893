import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(calendar)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

export const toDate = (language: string, date?: string | number) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).locale(language)
  }
  return dayjs.utc(date?.substring(0, 23)).locale(language)
}

export const diffWithNow = (language: string, date?: string | number) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).locale(language).diff(dayjs(), 'day')
  }

  return dayjs.utc(date?.substring(0, 23)).locale(language).diff(dayjs(), 'day')
}
