import { emptySplitApi } from 'emptySplitApi'

import { Resource } from './types'

const resourcesApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getResource: build.query<Resource, { code: string }>({
      query: ({ code }) => ({
        url: `resources/${code}`,
      }),
    }),
  }),
})

export const { useGetResourceQuery } = resourcesApi
