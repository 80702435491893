import { FunctionComponent } from 'react'

import { alpha } from '@theme-ui/color'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { makeTransition } from '@fairhq/common'
import { ScoreChart } from 'features/reportsAreasCore/components/ScoreChart'
import Sidebar from 'layout/Sidebar'
import { useAppSelector } from 'store/hooks'
import { useGetOldRecommendationsQuery } from 'store/oldRecommendations/oldRecommendationApi'
import { iconMap } from 'store/oldRecommendations/types'
import { State } from 'store/state'
import Icon, { IconName } from 'ui-kit/Icon'
import { tCode } from 'utils/tCode'

import { OldStrategyMenuItem } from './OldStrategyMenuItem'

export const OldStrategySidebar: FunctionComponent = () => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { recCode } = useParams<{ recCode: string }>()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: oldRecommendations } = useGetOldRecommendationsQuery({
    sessionId,
  })
  const oldRecommendation = (oldRecommendations ?? [])?.find(
    oldCompanyRecommendation =>
      oldCompanyRecommendation?.oldRecommendation?.code === recCode
  )
  const copy = (oldRecommendations ?? [])
    ?.find(
      oldCompanyRecommendation =>
        oldCompanyRecommendation?.oldRecommendation?.code === recCode
    )
    ?.copies?.find(({ type }) => type.toLowerCase() === 'old recommendation')

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView()
    }
  }

  const menu = [
    { text: t('oldRecommendations.why'), icon: 'info', id: 'why' },
    {
      text: t('oldRecommendations.howToGetStarted'),
      icon: 'improvements',
      id: 'how',
    },
    {
      text: t('oldRecommendations.resources'),
      icon: 'documents',
      id: 'resources',
    },
  ]

  return (
    <Sidebar sx={{ py: 6 }}>
      <Link to="/strategy">
        <Flex
          sx={{
            mb: 5,
            px: 7,
            height: 54,
            alignItems: 'center',
            color: 'text',
            transition: makeTransition('color'),
            '&:hover': { color: 'grey500' },
          }}
        >
          <Icon name="arrowLeft" sx={{ mr: 1 }} small />
          <Text as="div" variant="caps">
            {t('oldRecommendations.title')}
          </Text>
        </Flex>
      </Link>

      <Box sx={{ mb: 5 }}>
        {menu.map(({ icon, id, text }) => (
          <OldStrategyMenuItem
            key={id}
            text={text}
            icon={icon as IconName}
            url={`#${id}`}
            onClick={() => {
              scrollToSection(id)
            }}
          />
        ))}
      </Box>

      {copy ? (
        <Box sx={{ bg: alpha('red', 0.1), borderRadius: 20, p: 5, mx: 4 }}>
          <Text as="div" variant="caps" sx={{ mb: 3 }}>
            {t('oldRecommendations.area', {
              area: tArea(
                oldRecommendation?.area?.code,
                'title',
                oldRecommendation?.area?.title
              ),
            })}
          </Text>

          <Text as="div" sx={{ mb: 5, color: 'grey500' }}>
            {t(copy.code, { defaultValue: copy.description })}
          </Text>

          <ScoreChart
            score={(oldRecommendation?.area?.score?.percent ?? 0) / 10}
            icon={
              iconMap[oldRecommendation?.oldRecommendation?.type?.[0] as string]
            }
            reversed
            white
          />
        </Box>
      ) : undefined}
    </Sidebar>
  )
}
