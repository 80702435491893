import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

export const WhiteBoxWithShadow = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
  display: flex;
  overflow: hidden;
`
