import { Button, Container } from 'rsuite'
import styled, { css } from 'styled-components'

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const FormFooter = styled.div``

export const FormContainer = styled(Container)`
  h2 {
    color: ${({ theme }) => theme.colors.grey500};
    margin-bottom: 18px;
    font-size: 30px;
    line-height: 1.4;
  }
`

export const Title = styled.h1`
  position: relative;
  font-size: 16px;
`

export const Section = styled(Container)<{
  group?: 'company' | 'documents' | 'employee'
}>`
  max-width: ${({ theme }) => theme.maxWidth};
  padding: 48px 80px;
  margin: auto;

  h2 {
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 48px;
  }

  h3 {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
  }
`

export const RoundButton = styled(Button)`
  height: 66px;
  border-radius: 100px;
  font-weight: bold;
  padding: 21px 48px;
  line-height: 1.71;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  ${({ appearance, theme }) =>
    appearance === 'ghost'
      ? css`
          border-color: ${theme.colors.grey200};
          color: ${theme.colors.grey400};
        `
      : undefined};
`

RoundButton.defaultProps = {
  appearance: 'primary',
  type: 'button',
}

export const LinkButton = styled(Button)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.38;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey400};
`

LinkButton.defaultProps = {
  appearance: 'link',
  type: 'button',
}

export const SlideContainer = styled.div`
  width: 100%;
`

export const ProgressBar = styled.div<{
  progress: number
  hasError?: boolean
  orange?: boolean
  done?: boolean
  mode?: 'local' | 'sub' | 'title' | 'header'
}>`
  background: ${({ theme, progress, hasError, orange, mode, done }) =>
    orange && (mode === 'header' || mode === 'title')
      ? theme.colors.yellow
      : (hasError && theme.colors.red) ||
        (((!orange && progress === 100) || done) && theme.colors.green) ||
        (orange && 'transparent') ||
        ((mode === 'header' || mode === 'title') &&
          progress > 0 &&
          theme.colors.primary) ||
        theme.colors.grey200};
  height: 6px;
  width: ${({ progress }) => progress}%;
  transition: width 0.6s;
`

export const ProgressContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.grey100};
`

export const Subtitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.71;
  color: ${({ theme }) => theme.colors.grey300};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 30px;
`
