import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, space, ClipboardArrow } from '@fairhq/common'
import { RoundButton } from 'styled'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledClipboardArrow = styled(ClipboardArrow)`
  margin-right: ${space[2]}px;
`

export const ReadyForNewSurvey: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <>
      <LabelContainer>
        <ClipboardArrow outline={baseColors.purple} />
        <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
          {t(`statusOverview.employeeSurvey`)}
        </Text>
      </LabelContainer>
      <Text variant="title" sx={{ mb: 2, fontSize: 24, display: 'block' }}>
        {t(`statusOverview.newSurvey.title`)}
      </Text>
      <Text
        sx={{
          mt: 2,
          mb: 4,
          color: baseColors.grey400,
          fontSize: 16,
          display: 'block',
        }}
      >
        {t(`statusOverview.newSurvey.description`)}
      </Text>
      <ButtonContainer>
        <RoundButton componentClass={NavLink} to="/survey">
          <StyledClipboardArrow outline={baseColors.grey300} />
          {t(`statusOverview.newSurvey.cta`)}
        </RoundButton>
      </ButtonContainer>
    </>
  )
}
