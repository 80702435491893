import { http } from 'utils/http'

export const employeeApi = {
  count(getState: () => unknown) {
    return Promise.all([
      http.get(getState, `employees/count`),
      http.get(getState, `employees/count?type=invited`),
      http.get(getState, `employees/count?type=level`),
    ])
  },
}
