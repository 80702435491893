import { chain, groupBy, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import { tCode } from 'utils/tCode'

import { DemGroupType } from '../types'

import { useReportsParams } from './useReportsParams'

const roundScore = (data: any) =>
  Math.round(
    (data
      ?.map(({ score_calculation }: any) => score_calculation)
      .reduce((acc: number, score: number) => acc + score, 0) ?? 0) * 100
  ) /
  (data
    ?.map(({ area_max }: any) => area_max)
    .reduce((acc: number, score: number) => acc + score, 0) ?? 1)

export const useGroupRepresentation = (area: string) => {
  const { t } = useTranslation()
  const { code, type } = useReportsParams()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: demGroupsData,
    isLoading: isDemGroupsLoading,
    isError: isDemGroupsError,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })
  const { experience, experienceGeneral, companyByDemGroup } =
    demGroupsData || {}
  const general = experienceGeneral ?? []

  const demGroups = !code
    ? experience
    : experience?.filter(
        ({ dem_group_group }: { dem_group_group: string }) =>
          dem_group_group === code
      )

  const minority = companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code && dem_group_type === DemGroupType.MINORITY
  )

  const majority = companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code &&
      (dem_group_type === DemGroupType.MAJORITY ||
        dem_group_type === DemGroupType.ALL_MAJORITY)
  )

  const minorities = companyByDemGroup?.filter(
    ({ dem_group_type }: any) => dem_group_type === DemGroupType.MINORITY
  )

  const {
    data: typeReportsData,
    isLoading: isTypeReportsLoading,
    isError: isTypeReportsError,
  } = useGetReportsQuery({ type, apiVersion, sessionId })

  const isLoading = isDemGroupsLoading || isTypeReportsLoading
  const isError = isDemGroupsError || isTypeReportsError
  let queryStatus = QueryStatus.SUCCESS
  if (isError) {
    queryStatus = QueryStatus.ERROR
  }
  if (isLoading) {
    queryStatus = QueryStatus.LOADING
  }

  const representation = typeReportsData?.experience

  const { minority: minorityScores, majority: majorityScores } = chain(
    demGroups
  )
    .groupBy('dem_group_type')
    .value()

  const companyScore = {
    key: 'companyScore',
    value: roundScore(general),
    label: t('reports.overview.companyScore'),
  }

  if (type === 'dem_groups') {
    const majorityScore = {
      key: 'majorityScores',
      value: roundScore(
        majorityScores?.filter(({ area_code }: any) =>
          area_code?.includes(area)
        )
      ),
      label: code
        ? tCode(t)(majority?.dem_group_code, '', majority?.dem_group_title)
        : t('reports.overview.traditionalMajority'),
    }

    if (code) {
      const minorityScore = {
        key: 'minorityScores',
        value: roundScore(
          minorityScores?.filter(({ area_code }: any) =>
            area_code?.includes(area)
          )
        ),
        label: tCode(t)(
          minority?.dem_group_code,
          '',
          minority?.dem_group_title
        ),
      }

      const narrowMinorities = groupBy(
        demGroups?.filter(
          (item: any) =>
            item.dem_group_type === null && item._group?.includes(code)
        ),
        '_group'
      )

      const narrowMinoritiesScores = Object.keys(narrowMinorities)?.map(
        key => ({
          key,
          value: roundScore(narrowMinorities[key]),
          label: t(key),
        })
      )
      const umbrellaGroups = [companyScore, majorityScore, minorityScore]

      return {
        items: [...umbrellaGroups, ...orderBy(narrowMinoritiesScores, 'label')],
        queryStatus,
        ...(narrowMinoritiesScores.length > 0
          ? { withSpaceFromIndex: umbrellaGroups.length - 1 }
          : {}),
      }
    }

    if (minorities?.length > 0) {
      const minoritiesScore = minorities?.map((min: any) => ({
        key: min.dem_group_code,
        value: roundScore(
          minorityScores?.filter(
            ({ area_code, dem_group_group }: any) =>
              area_code?.includes(area) &&
              dem_group_group === min?.dem_group_group
          )
        ),
        label: tCode(t)(min?.dem_group_code, '', min?.dem_group_title),
      }))
      return {
        items: [
          companyScore,
          ...orderBy({ majorityScore, ...minoritiesScore }, 'value'),
        ],
        queryStatus,
      }
    }
  }

  const groups = chain(representation).groupBy('_group').value()

  return {
    items: [
      companyScore,
      ...orderBy(
        Object.keys(groups)?.map(group => ({
          key: group,
          value: roundScore(
            groups[group]?.filter(({ area_code }: any) =>
              area_code?.includes(area)
            )
          ),
          label: t(`reports.label.${group.toLowerCase()}`, group),
        })),
        ['value']
      ),
    ],
    queryStatus,
  }
}
