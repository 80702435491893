import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

const InstructionsContainer = styled.section<{ fullWidth: boolean }>`
  background-color: ${baseColors.blue100};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '35%')};
  margin-bottom: 24px;
`
interface InstructionsProps {
  title: string
  description: string
  fullWidth?: boolean
}

export const Instructions: FunctionComponent<InstructionsProps> = ({
  title,
  description,
  fullWidth = false,
}) => {
  const { t } = useTranslation()

  return (
    <InstructionsContainer fullWidth={fullWidth}>
      <Text element="h3" type="body" size="xl" fontWeight={700}>
        {t(title)}
      </Text>
      <Text element="p" type="body" size="sm" fontWeight={400}>
        <Trans i18nKey={description} />
      </Text>
    </InstructionsContainer>
  )
}
