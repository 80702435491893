import { filter, includes, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'

export function useRepresentation(code: string) {
  const { t } = useTranslation()
  const dividedCode = code.split('.')
  const areaCodeKeyword =
    dividedCode?.[1] === 'payAndBonus' ? 'pay_bonus' : dividedCode?.[1]

  const { companyBenchmark, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      companyBenchmark: state.companyReducer.company?.companyBenchmark,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { data: fairnessData } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'fairness',
    apiVersion,
    sessionId,
  })
  const fairness = fairnessData?.fairness?.[0] ?? {}

  const [poc, white, man, woman] = sortBy(
    filter(
      Object.keys(fairness),
      e => includes(e, areaCodeKeyword) && !includes(e, '_survey_')
    )
  )
  const surveyData =
    areaCodeKeyword === 'hiring'
      ? sortBy(
          filter(
            Object.keys(fairness),
            e => includes(e, areaCodeKeyword) && includes(e, '_survey_')
          )
        )
      : undefined
  const [pocSurvey, whiteSurvey, manSurvey, womanSurvey] = surveyData ?? []

  const gapEthnicity =
    // eslint-disable-next-line no-unsafe-optional-chaining
    Math.abs(Math.round((fairness?.[poc] - fairness?.[white]) * 100)) || 0
  const gapGender =
    // eslint-disable-next-line no-unsafe-optional-chaining
    Math.abs(Math.round((fairness?.[woman] - fairness?.[man]) * 100)) || 0

  const menRepresention = {
    key: 'men',
    // eslint-disable-next-line no-unsafe-optional-chaining
    value: Math.round(fairness?.[man] * 100) || 0,
    label: t('reports.fairness.men'),
    color: 'blue',
  }
  const womenRepresention = {
    key: 'women',
    // eslint-disable-next-line no-unsafe-optional-chaining
    value: Math.round(fairness?.[woman] * 100) || 0,
    label: t('reports.fairness.women'),
    color: 'purple',
  }
  const whiteRepresentation = {
    key: 'white',
    // eslint-disable-next-line no-unsafe-optional-chaining
    value: Math.round(fairness?.[white] * 100) || 0,
    label: t('reports.fairness.white'),
    color: 'blue',
  }
  const pocRepresentation = {
    key: 'poc',
    // eslint-disable-next-line no-unsafe-optional-chaining
    value: Math.round(fairness?.[poc] * 100) || 0,
    label: t('reports.fairness.poc'),
    color: 'purple',
  }
  const getRepresentation = () => {
    const gender: any = {
      key: 'gender',
      gap: gapGender,
      title: t(`reports.fairness.${code}.gender.title`),
      items: [menRepresention, womenRepresention],
    }
    const ethnicity: any = {
      key: 'ethnicity',
      gap: gapEthnicity,
      title: t(`reports.fairness.${code}.ethnicity.title`),
      items: [whiteRepresentation, pocRepresentation],
    }

    if (areaCodeKeyword === 'hiring') {
      gender.benchmark = {
        value: companyBenchmark?.women_hiring,
        label: t('reports.fairHQBenchmark.title'),
      }
      ethnicity.benchmark = {
        value: companyBenchmark?.people_of_color_hiring,
        label: t('reports.fairHQBenchmark.title'),
      }
    }
    return [gender, ethnicity]
  }

  const getHiringRepresentation = () => {
    const gender = {
      key: 'gender',
      gap: gapGender,
      title: t(`reports.fairness.${code}.gender.title`),
      items: [
        {
          label: t(`reports.fairness.${code}.audit.title`),
          values: [menRepresention, womenRepresention],
        },
        ...(areaCodeKeyword === 'hiring'
          ? [
              {
                label: t(`reports.fairness.${code}.survey.title`),
                values: [
                  {
                    key: 'menSurvey',
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    value: Math.round(fairness?.[manSurvey] * 100) || 0,
                    label: t('reports.fairness.men'),
                    color: 'blue',
                  },
                  {
                    key: 'womenSurvey',
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    value: Math.round(fairness?.[womanSurvey] * 100) || 0,
                    label: t('reports.fairness.women'),
                    color: 'purple',
                  },
                ],
              },
            ]
          : []),
      ],
    } as any
    const ethnicity = {
      key: 'ethnicity',
      gap: gapEthnicity,
      title: t(`reports.fairness.${code}.ethnicity.title`),
      items: [
        {
          label: t(`reports.fairness.${code}.audit.title`),
          values: [whiteRepresentation, pocRepresentation],
        },
        ...(areaCodeKeyword === 'hiring'
          ? [
              {
                label: t(`reports.fairness.${code}.survey.title`),
                values: [
                  {
                    key: 'whiteSurvey',
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    value: Math.round(fairness?.[whiteSurvey] * 100) || 0,
                    label: t('reports.fairness.white'),
                    color: 'blue',
                  },
                  {
                    key: 'pocSurvey',
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    value: Math.round(fairness?.[pocSurvey] * 100) || 0,
                    label: t('reports.fairness.poc'),
                    color: 'purple',
                  },
                ],
              },
            ]
          : []),
      ],
    } as any

    if (areaCodeKeyword === 'hiring') {
      gender.benchmark = {
        value: companyBenchmark?.women_hiring,
        label: t('reports.fairHQBenchmark.title'),
      }
      ethnicity.benchmark = {
        value: companyBenchmark?.people_of_color_hiring,
        label: t('reports.fairHQBenchmark.title'),
      }
    }
    return [gender, ethnicity]
  }

  return areaCodeKeyword === 'hiring'
    ? getHiringRepresentation
    : getRepresentation
}
