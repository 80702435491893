import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  FieldOption,
  AdvancedFieldOption,
  AdvancedFields,
  displayField,
} from './AdvancedFields'

import { FieldsContainer } from './styled'

interface FieldsProps {
  data: Array<Partial<FieldOption>> | Array<AdvancedFieldOption>
  advanced?: boolean
}

export const Fields: FunctionComponent<FieldsProps> = ({ data, advanced }) => {
  const { t } = useTranslation()

  if (advanced) {
    return <AdvancedFields data={data as Array<AdvancedFieldOption>} />
  }

  return (
    <FieldsContainer>
      {(data as Array<Partial<FieldOption>>).map(displayField(t))}
    </FieldsContainer>
  )
}
