import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Checkboxes } from '@fairhq/common'
import { Question } from 'store/questionTypes'

const StyledTextArea = styled.textarea`
  margin-top: 12px;
  width: 100%;
`

interface MultipleChoiceProps {
  question: Question
  selectedOptions: number[]
  setSelectedOptions: (newValue: any) => void
  other: string | undefined
  setOther: (value: string) => void
}

export const MultipleChoice: FunctionComponent<MultipleChoiceProps> = ({
  question,
  selectedOptions,
  setSelectedOptions,
  other,
  setOther,
}) => {
  const { t } = useTranslation()
  const { options } = question ?? {}
  const sortedOptions = [...options].sort((a, b) =>
    a.priority > b.priority ? 1 : -1
  )

  const isOtherVisible = sortedOptions?.find(
    ({ id, isOther }) => (selectedOptions || [])?.includes?.(id) && isOther
  )

  const onChangeMultiple = (id: number, isChecked: boolean) => {
    let values: number[] = []
    if (isChecked) {
      values = [...selectedOptions, id]
    } else {
      values = selectedOptions.filter(optionId => optionId !== id)
    }

    const none = sortedOptions?.find(({ isNone }) => isNone)
    const otherOption = sortedOptions?.find(({ isOther }) => isOther)
    const noneSelected = none ? values.find(id => id === none.id) : false
    const otherSelected = otherOption
      ? values.find(id => id === otherOption.id)
      : false
    if (noneSelected) {
      const newValues = []
      newValues.push(noneSelected)
      if (otherSelected) {
        newValues.push(otherSelected)
      }
      setSelectedOptions(newValues)
    } else {
      setSelectedOptions(values)
    }
  }

  return (
    <div>
      <Checkboxes
        items={sortedOptions.map(({ id, title }) => ({
          id: id.toString(),
          label: title,
          isChecked: (selectedOptions || [])?.includes?.(id),
          onCheckedChange: isChecked => {
            onChangeMultiple(id, isChecked)
          },
        }))}
      />
      {isOtherVisible && (
        <StyledTextArea
          onChange={e => setOther(e.target.value)}
          placeholder={t('other.writeItHere')}
          rows={5}
          value={other}
        />
      )}
    </div>
  )
}
