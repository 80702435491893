import React, { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'

import { Label } from '../components/Label'
import { DEFAULT_LABEL_WIDTH } from '../constants'
import { VisualizationItemProps, VisualizationTypeProps } from '../types'

import { RowStackVisualizationItemValue } from './RowStackVisualizationItemValue'

export const RowStackVisualizationItem: FunctionComponent<
  VisualizationTypeProps<VisualizationItemProps>
> = ({ item, isScoreBy10, isGaps, maxGap }) => (
  <Flex
    sx={{
      alignItems: 'center',
      padding: '6px 0',
      transition: 'all 0.15s ease-out',
      zIndex: 3,
      borderRadius: '12px',
      bg: 'transparent',
      '&:hover': item.isTransparent
        ? undefined
        : {
            bg: 'white',
            boxShadow: '0px 18px 30px -6px rgba(10, 27, 64, 0.1)',
          },
    }}
  >
    <Label
      label={item.label}
      sx={{
        flexGrow: 1,
        minWidth: `${DEFAULT_LABEL_WIDTH}px`,
        maxWidth: `${DEFAULT_LABEL_WIDTH}px`,
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: '18px',
      }}
    />
    <Flex
      sx={{
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      {item.values.map(itemValue => (
        <RowStackVisualizationItemValue
          key={itemValue.key}
          item={itemValue}
          isScoreBy10={isScoreBy10}
          isGaps={isGaps}
          maxGap={maxGap}
        />
      ))}
    </Flex>
  </Flex>
)
