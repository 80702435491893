import { useAuthRoles } from 'hooks/useAuthRoles'

import { Account } from 'store/account/types'
import { Company } from 'store/company/types'

import { AdminList } from './AdminList'

export type AccountAndCompany = Account & { company: Partial<Company> }

export const Admin = () => {
  const { isAdmin } = useAuthRoles()

  if (!isAdmin) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return <AdminList />
}
