import React, { FunctionComponent } from 'react'

interface OutwardsArrowsProps {
  className?: string
  stroke?: string
}

export const OutwardsArrows: FunctionComponent<OutwardsArrowsProps> = ({
  className,
  stroke = 'currentColor',
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}
    className={className}
    fill="none"
  >
    <path
      d="M6.09961 8.33169H2.09961M2.09961 8.33169L3.43294 6.99669M2.09961 8.33169L3.43294 9.66669"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.33333H14M14 8.33333L12.6667 9.66667M14 8.33333L12.6667 7"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
