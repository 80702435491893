import React, { FunctionComponent } from 'react'

import { Avatar } from '@theme-ui/components'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { space, Text } from '@fairhq/common'

import { useUser } from '../hooks/useUser'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${space[4]}px;
`

export interface UserProps {
  owner: string
}

export const User: FunctionComponent<UserProps> = ({ owner }) => {
  const { picture, nickname } = useUser(owner)
  return picture && nickname ? (
    <Container>
      <Avatar
        sx={{ mr: 1, width: 30, height: 30, minWidth: 30 }}
        src={picture}
        alt={nickname}
      />
      <Text element="label" type="body" size="xs" fontWeight={400}>
        <Trans
          i18nKey="campaigns.active.scheduledBy"
          values={{
            nickname,
          }}
        />
      </Text>
    </Container>
  ) : null
}
