import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { Colors } from '@fairhq/common'

import Icon from './Icon'

export type Step = {
  title: string
  component: React.ReactNode
}

type Props = {
  steps: Step[]
  currentStep: number
  sx?: ThemeUIStyleObject
}

const Steps: FunctionComponent<Props> = ({ steps, currentStep, sx }) => (
  <Flex sx={{ justifyContent: 'center', ...sx }}>
    {steps.map((step, i) => {
      const isCurrent = i === currentStep
      const isComplete = i < currentStep
      return (
        <Flex key={step.title} sx={{ alignItems: 'center', mx: 4 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: 48,
              height: 48,
              mr: 2,
              bg: isCurrent ? 'purple' : isComplete ? 'border' : 'transparent',
              border: '1px solid',
              borderColor: isCurrent ? 'purple' : 'border',
              borderRadius: '50%',
            }}
          >
            {isComplete ? (
              <Icon name="checkmark" color={Colors.WHITE} />
            ) : (
              <Text
                as="div"
                variant="bodyBold"
                sx={{ color: isCurrent ? 'white' : 'grey300' }}
              >
                {i + 1}
              </Text>
            )}
          </Flex>
          <Text
            as="div"
            variant="bodyBold"
            sx={{ color: isCurrent ? 'grey400' : 'grey300' }}
          >
            {step.title}
          </Text>
        </Flex>
      )
    })}
  </Flex>
)

export default Steps
