import queryString from 'query-string'

import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'

import { AreasQueryParams } from 'store/area/types'

import { AuditArea, Company, Documents, Survey } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to auditApi

export const auditApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getIsScorable: build.query<
      boolean,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `/audit/scorable`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.SCORABLE],
    }),
    getAuditDocuments: build.query<
      Documents,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `audit/documents`,
        headers: {
          'fairhq-version': apiVersion,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAuditSurvey: build.query<
      Survey,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `audit/survey`,
        headers: {
          'fairhq-version': apiVersion,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAuditCompany: build.query<
      Company,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `audit/company`,
        headers: {
          'fairhq-version': apiVersion,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAuditAreas: build.query<
      AuditArea[],
      { sessionId: SessionId; query: AreasQueryParams }
    >({
      query: ({ sessionId, query }) => ({
        url: `areas?${queryString.stringify(query as any)}`,
        headers: {
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const {
  useGetIsScorableQuery,
  useGetAuditDocumentsQuery,
  useGetAuditSurveyQuery,
  useGetAuditCompanyQuery,
  useGetAuditAreasQuery,
} = auditApi
