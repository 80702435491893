export const rolesOptions = [
  { value: 'ceo', label: 'roles.ceo' },
  { value: 'coo', label: 'roles.coo' },
  { value: 'board_member', label: 'roles.boardMember' },
  { value: 'chief_of_staff', label: 'roles.chiefOfStaff' },
  { value: 'hr_professional', label: 'roles.hrProfessional' },
  { value: 'talent_professional', label: 'roles.talentProfessional' },
  { value: 'di_champion', label: 'roles.DIChampion' },
  { value: 'di_professional_in_house', label: 'roles.DIProfessionalInHouse' },
  { value: 'external_di_consultant', label: 'roles.externalDIConsultant' },
  { value: 'other', label: 'roles.other' },
]
