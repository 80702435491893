import { FunctionComponent, useEffect, useState } from 'react'

import { Box, Flex, Link, Text } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'

import { config } from '@fairhq/common'
import { Confirm } from 'components/confirm'
import {
  useCreateSessionMutation,
  useGetSessionsQuery,
} from 'store/company/companyApiWithQuery'
import { count as countEmployees } from 'store/employee/employeeSlice'
import { useAppDispatch } from 'store/hooks'
import Button from 'ui-kit/Button'
import WhiteBox from 'ui-kit/WhiteBox'
import { diffWithNow } from 'utils/dates'

import { useCompletion } from '../../hooks/useCompletion'

export const ReAudit: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const { data: sessions } = useGetSessionsQuery()
  const [createSession, { isLoading, isSuccess }] = useCreateSessionMutation()

  const latestSession = sessions?.[0]
  const [reAuditVisible, setReAuditVisible] = useState(false)
  const { total, iscompletionLoading } = useCompletion(latestSession, true)
  const daysBeforeNewSession = diffWithNow(
    i18n.language,
    latestSession?.end_date
  )

  useEffect(() => {
    if (isSuccess) {
      setReAuditVisible(false)
      dispatch(countEmployees())
    }
  }, [isSuccess, dispatch])

  if (!isLoading && !iscompletionLoading && total === 0) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <WhiteBox sx={{ position: 'relative', overflow: 'hidden' }}>
      <Confirm
        onCancel={() => {
          setReAuditVisible(false)
        }}
        visible={reAuditVisible}
        onSubmit={() => {
          createSession()
        }}
        loading={isLoading}
        title={<h3>{t('employees.doYouWantToReAudit.title')}</h3>}
      >
        <Text as="div" mb="24px">
          {t('employees.doYouWantToReAudit.description')}
        </Text>
      </Confirm>

      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '500px' }}>
          <Text
            as="div"
            variant="heading"
            sx={{ color: 'grey300', fontSize: '18px' }}
            mb="12px"
          >
            {t('survey.nextSurveyAroundTheCorner.title')}
          </Text>
          <Text as="div">
            <Trans
              i18nKey="survey.nextSurveyAroundTheCorner.description"
              components={[
                <Link
                  href="https://ask.fairhq.co/en/articles/5742307-private-beta-launch-pack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  xxx
                </Link>,
              ]}
            />
          </Text>
        </Box>

        {daysBeforeNewSession <= 0 || config.env === 'development' ? (
          <Button
            variant="secondary"
            size="large"
            onClick={() => setReAuditVisible(true)}
          >
            {t('survey.reAudit')}
          </Button>
        ) : (
          <Text as="div" variant="bodyBold" sx={{ color: 'grey400' }}>
            {t('survey.daysUntilNextSurvey', {
              count: daysBeforeNewSession,
            })}
          </Text>
        )}
      </Flex>
    </WhiteBox>
  )
}
