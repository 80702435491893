import { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { useAppSelector } from 'store/hooks'
import {
  useGetReportAreasQuery,
  useGetReportByAreaQuery,
} from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { Question } from './Question'

interface InclusionQuestionsProps {
  code: string
}

export const InclusionQuestions: FunctionComponent<InclusionQuestionsProps> = ({
  code,
}) => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const {
    data: areas,
    isLoading: isAreasLoading,
    isError: isAreasError,
    isSuccess: isAreasSuccess,
  } = useGetReportAreasQuery({ apiVersion, sessionId })
  const {
    data: experienceReports,
    isLoading: isExperienceLoading,
    isError: isExperienceError,
    isSuccess: isExperienceSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'experience',
    apiVersion,
    sessionId,
  })

  const questions = experienceReports?.experience.questions ?? []

  const questionsWithPercent = sortBy(
    questions
      ?.filter((question: any) => question._type === 'general')
      .map((question: any) => ({
        ...question,
        score10: (question.question_score * 10) / question.question_max,
      })),
    ['score10']
  )

  const lowQuestions = questionsWithPercent?.slice(0, 2)
  const topQuestions = questionsWithPercent?.reverse()?.slice(0, 2)

  const representation = [
    {
      key: 'insiqhts.questions.topScoring',
      title: t('insiqhts.questions.topScoring'),
      questions: topQuestions,
    },
  ]

  if (questionsWithPercent?.length > 2) {
    representation.push({
      key: 'insiqhts.questions.lowScoring',
      title: t('insiqhts.questions.lowScoring'),
      questions: lowQuestions,
    })
  }

  return (
    <StateHandler
      isError={isAreasError || isExperienceError}
      isSuccess={isAreasSuccess && isExperienceSuccess}
      isLoading={isAreasLoading || isExperienceLoading}
      errorMessage={t('error.TryAgain')}
    >
      {representation
        ?.filter(({ questions: list }) => list?.length > 0)
        .map(({ title, questions: list }) => (
          <Box sx={{ mb: 8 }} key={title + Math.random()}>
            <Text as="div" variant="bodyLargeBold" sx={{ mb: 4 }}>
              {title}
            </Text>
            {list?.map(
              ({ area_code, question_code, score10, question_title }) => {
                const area = areas?.find(({ code }) => code === area_code)
                return (
                  <Question
                    key={question_code + Math.random()}
                    question={{
                      content: question_title,
                      area: tArea(area_code, 'title', area?.title),
                      score: score10,
                    }}
                    parentAreaCode={area?.parent?.code}
                  />
                )
              }
            )}
          </Box>
        ))}
    </StateHandler>
  )
}
