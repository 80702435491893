import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'

import { Colors } from '@fairhq/common'

import Icon from 'ui-kit/Icon'

export interface PracticeType {
  key: string
  content: string
  checked?: boolean
}

interface PracticeProps {
  practice: PracticeType
}

export const Practice: FunctionComponent<PracticeProps> = ({
  practice: { checked, content },
}) => (
  <Flex sx={{ alignItems: 'center', mt: 2 }}>
    <Icon
      name={checked ? 'checkboxChecked' : 'checkboxUnavailable'}
      sx={{ mr: 2, flex: '0 0 auto' }}
      color={checked ? Colors.GREEN : undefined}
    />
    <Text as="div" variant="label" sx={{ color: 'grey500' }}>
      {content}
    </Text>
  </Flex>
)
