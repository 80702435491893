import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Megaphone: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M18 8C18.7956 8 19.5587 8.31607 20.1213 8.87868C20.6839 9.44129 21 10.2044 21 11C21 11.7956 20.6839 12.5587 20.1213 13.1213C19.5587 13.6839 18.7956 14 18 14"
      stroke={outline}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8V19C10 19.2652 9.89464 19.5196 9.70711 19.7071C9.51957 19.8946 9.26522 20 9 20H7.5C7.23478 20 6.98043 19.8946 6.79289 19.7071C6.60536 19.5196 6.5 19.2652 6.5 19V14"
      stroke={outline}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7.99995L16.524 4.22995C16.6555 4.12046 16.8154 4.0507 16.9851 4.02885C17.1548 4.00701 17.3271 4.03398 17.482 4.1066C17.6369 4.17922 17.7679 4.29449 17.8597 4.4389C17.9514 4.5833 18.0001 4.75087 18 4.92195V17.0779C18.0001 17.249 17.9514 17.4166 17.8597 17.561C17.7679 17.7054 17.6369 17.8207 17.482 17.8933C17.3271 17.9659 17.1548 17.9929 16.9851 17.971C16.8154 17.9492 16.6555 17.8794 16.524 17.7699L12 13.9999H4C3.73478 13.9999 3.48043 13.8946 3.29289 13.7071C3.10536 13.5195 3 13.2652 3 12.9999V8.99995C3 8.73473 3.10536 8.48038 3.29289 8.29284C3.48043 8.1053 3.73478 7.99995 4 7.99995H12"
      stroke={outline}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
