import { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { StateHandler } from 'components/StateHandler'
import { Visualization } from 'components/visualization/Visualization'
import { QueryStatus } from 'store/types'

import { useGroupRepresentation } from '../hooks/useGroupRepresentation'

interface DemGroupScoreRepresentationProps {
  area: string
}

export const DemGroupScoreRepresentation: FunctionComponent<
  DemGroupScoreRepresentationProps
> = ({ area }) => {
  const { t } = useTranslation()
  const { items, queryStatus } = useGroupRepresentation(area)

  return (
    <StateHandler
      isError={queryStatus === QueryStatus.ERROR}
      isSuccess={queryStatus === QueryStatus.SUCCESS}
      isLoading={queryStatus === QueryStatus.LOADING}
      errorMessage={t('error.TryAgain')}
    >
      <Box sx={{ borderRadius: 20, bg: 'white', px: 8, py: 5, mb: 5 }}>
        <Text as="div" variant="bodyLargeBold" sx={{ mb: 5 }}>
          {t(`reports.${area}.experience.title`)}
        </Text>

        <Visualization
          type={items?.length > 8 ? 'Row' : 'Bar'}
          items={items as any}
          isScoreBy10
        />
      </Box>
    </StateHandler>
  )
}
