import { max } from 'lodash'

import { VisualizationItemProps } from '../types'

export const getMaximumGap = (items: VisualizationItemProps[]) =>
  items.reduce((maximumGap, { values }) => {
    const currentMax = max(values.map(({ value }) => Math.abs(value)))
    if (currentMax && currentMax > maximumGap) {
      return currentMax
    }
    return maximumGap
  }, 0)
