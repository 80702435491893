import { useEffect } from 'react'

import { shallowEqual } from 'react-redux'

import { useParams } from 'react-router-dom'

import { useSaveAnswer } from 'hooks/useSaveAnswer'
import { getAssessmentsByArea } from 'store/area/areaSlice'
import { Area } from 'store/area/types'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

export const useAssessment = ({ area }: { area?: Area }) => {
  const dispatch = useAppDispatch()
  const { code } = useParams<{ code: string }>()
  const { areaAssessments, loading } = useAppSelector(
    (state: State) => ({
      loading: state.areaReducer.loading,
      areaAssessments: state.areaReducer.assessments?.[area?.id || ''] ?? {
        loaded: false,
        assessments: [],
      },
    }),
    shallowEqual
  )

  const { onSubmitAssessment } = useSaveAnswer()

  useEffect(() => {
    if (
      area?.id &&
      area?.type === 'local' &&
      !loading &&
      !areaAssessments?.loaded
    ) {
      dispatch(getAssessmentsByArea(area.id))
    }
  }, [area, areaAssessments, code, dispatch, loading])

  return { onSubmitAssessment }
}
