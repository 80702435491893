import { CompanyDocumentStatus } from 'store/document/types'

export function getDocumentProgress({
  status,
}: {
  status: Partial<CompanyDocumentStatus>
}) {
  const total = 0
  const completed = 0
  const skipped = 0
  const reviewed = 0
  const rejected = 0
  const submitted = 0
  const totalScore = 0

  return {
    disabled: false,
    total,
    skipped,
    completed,
    status: completed === total ? 'completed' : 'pending',
    reviewed,
    rejected,
    submitted,
    score: totalScore,
    ...status,
  }
}
