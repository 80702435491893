import { useState } from 'react'

import { useTranslation } from 'react-i18next'

export const useErrorMessage = () => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')

  const setError = ({
    error,
    message,
  }: {
    error?: { name?: string; type?: string; message?: string }
    message?: string
  }) => {
    setErrorMessage(
      t(`error.${error?.type || error?.name}`, {
        defaultValue: t(error?.message || message || ''),
      })
    )
  }

  const clearError = () => {
    setErrorMessage('')
  }

  return { clearError, errorMessage, setError }
}
