import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Target, Text, baseColors } from '@fairhq/common'

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${baseColors.purple700};
  position: relative;
`

const StyledLink = styled(Link)`
  border-bottom: 2px solid transparent;
  color: ${baseColors.purple700};
  &:hover:not(:focus-visible) {
    border-bottom: 2px solid ${baseColors.purple700};
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:focus-visible::before {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 24px;
  }
`

export const PromoteStrategyIntro: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Text element="h1" fontWeight={500} type="heading" size="xl">
        {t('promoteStrategy.header.title')}
      </Text>
      <LinkWrapper>
        <Target aria-hidden="true" />
        <StyledLink to="/strategy">
          <Text element="span" fontWeight={700} type="body" size="sm">
            {t('promoteStrategy.header.CTA')}
          </Text>
        </StyledLink>
      </LinkWrapper>
    </Container>
  )
}
