import { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import { Resource } from 'store/tasks/types'

const StyledLink = styled(Link)`
  flex-grow: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:focus-visible::before {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 24px;
  }
`

const StyledAnchor = styled.a`
  flex-grow: 1;
`

interface CustomLinkProps {
  resource: Resource
  children: React.ReactNode
}

export const CustomLink: FunctionComponent<CustomLinkProps> = ({
  resource,
  children,
}) => {
  if (resource.notionURL) {
    return (
      <StyledLink to={`/resources/${resource.code}`}>{children}</StyledLink>
    )
  }

  if (resource.campaign && resource.campaign?.id) {
    return (
      <StyledLink
        to={{
          pathname: '/campaigns',
          search: `selected=campaign-${resource.campaign?.id}`,
        }}
      >
        {children}
      </StyledLink>
    )
  }
  return <StyledAnchor href={resource.externalURL}>{children}</StyledAnchor>
}
