import { useState, useRef } from 'react'

import { useOnOutsideClick } from './useOnOutsideClick'

export const useDropdown = (
  onCloseCallback?: () => void,
  onOutsideCallback?: () => void,
  defaultOpened = false
) => {
  const ref = useRef(null)
  const [opened, setOpened] = useState(defaultOpened)
  useOnOutsideClick<HTMLDivElement>(ref, onOutsideClick)

  function openDropdown() {
    setOpened(true)
  }
  function closeDropdown() {
    setOpened(false)
    onCloseCallback && onCloseCallback()
  }
  function toggleDropdown() {
    setOpened(!opened)
  }

  function onOutsideClick() {
    closeDropdown()
    onOutsideCallback && onOutsideCallback()
  }

  return { ref, opened, openDropdown, closeDropdown, toggleDropdown }
}
