import React, { FunctionComponent } from 'react'

import { useLocation } from 'react-router'
import { Flex, Text } from 'theme-ui'

import { makeTransition, space } from '@fairhq/common'

import RouterLink from 'ui-kit/RouterLink'

export const SidebarGroupHeader: FunctionComponent<{
  text: string
  url: string
  completion?: string
}> = ({ text, url, completion }) => {
  const { pathname } = useLocation()
  const isActive = pathname.endsWith(url)

  return (
    <RouterLink to={url}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 54,
          pl: `${space[7] - 3}px`,
          pr: 4,
          py: 2,
          color: isActive ? 'purple' : 'grey500',
          borderLeft: '3px solid',
          bg: isActive ? 'grey100' : 'transparent',
          borderColor: isActive ? 'purple' : 'transparent',
          transition: [
            makeTransition('background'),
            makeTransition('border'),
            makeTransition('color'),
          ].join(),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Text as="div" variant="bodyBold">
          {text}
        </Text>
        {completion ? (
          <Text
            as="div"
            sx={{ color: completion === '0%' ? 'grey300' : 'grey400' }}
          >
            {completion}
          </Text>
        ) : null}
      </Flex>
    </RouterLink>
  )
}
