import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { BellCurve } from 'components/BellCurve'
import { DifferenceTag } from 'components/DifferenceTag'
import { DemGroupCount, SurveyWindow } from 'store/surveyWindow/types'

import { ApprovalStamp } from './assets/ApprovalStamp'
import { ComparisonSquareBar } from './ComparisonSquareBar'

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% auto;
  column-gap: 20px;
`

const BarsWrapper = styled(FlexWrapper)`
  gap: 4px;
  flex-direction: column;
`

const BarWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 250px 80px auto;
`

const PeerComparisonCurve = styled.div`
  display: grid;
  justify-items: end;
  align-items: center;
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

const Approval = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
`

interface KeyIndustryDemographicsProps {
  demCode: string
  latestSurveyWindow: SurveyWindow
  previousSurveyWindow?: SurveyWindow
  latestDem?: DemGroupCount
  previousDem?: DemGroupCount
  benchmark: number
}

export const KeyDemographic: FunctionComponent<
  KeyIndustryDemographicsProps
> = ({
  demCode,
  latestSurveyWindow,
  previousSurveyWindow,
  latestDem,
  previousDem,
  benchmark,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Text element="p" type="body" size="lg" fontWeight={700}>
        {t(`${demCode}.parentGroup`)}
      </Text>
      <FlexWrapper>
        <BarsWrapper>
          <Text element="p" type="body" size="sm" fontWeight={400}>
            {t(`${demCode}.helper`)}
          </Text>
          {previousSurveyWindow && (
            <BarWrapper>
              <NoWrapText element="p" type="body" size="sm" fontWeight={400}>
                {previousSurveyWindow.name}
              </NoWrapText>
              <ComparisonSquareBar
                percentage={previousDem ? +previousDem.rate * 100 : 0}
                color={baseColors.grey300}
              />
            </BarWrapper>
          )}

          <BarWrapper>
            <NoWrapText element="p" type="body" size="sm" fontWeight={400}>
              {latestSurveyWindow.name}
            </NoWrapText>
            <ComparisonSquareBar
              percentage={latestDem ? +latestDem.rate * 100 : 0}
            />
            {latestDem && previousDem && (
              <DifferenceTag
                value={+latestDem.rate - +previousDem.rate}
                displayValue={`${Math.round(
                  (+latestDem.rate - +previousDem.rate) * 100
                )}%`}
              />
            )}

            <Approval>
              <ApprovalStamp />
              <Text
                element="span"
                type="body"
                size="sm"
                fontWeight={400}
                color={baseColors.purple700}
              >
                {`${benchmark}%`}
              </Text>
            </Approval>
          </BarWrapper>
        </BarsWrapper>

        {latestDem?.percentile && (
          <PeerComparisonCurve>
            <BellCurve value={+latestDem.percentile} />
          </PeerComparisonCurve>
        )}
      </FlexWrapper>
    </Container>
  )
}
