import { FunctionComponent } from 'react'

import { shallowEqual } from 'react-redux'

import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'

import { SpanProp } from './Dashbox'
import { EDIOverview } from './EDIOverview'

interface AuditEDIOverviewProps {
  span: SpanProp
}

export const AuditEDIOverview: FunctionComponent<AuditEDIOverviewProps> = ({
  span,
}) => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: latestScores } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  const scoreAreas = ['equality', 'diversity', 'inclusion'].map(name => ({
    name,
    ...latestScores?.areasScoring?.[name],
  }))

  return (
    <EDIOverview span={span} scoreAreas={scoreAreas} isScorable={isScorable} />
  )
}
