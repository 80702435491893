import { ReportGroup } from 'store/reports/types'

export const getGroup = (pathname: string): ReportGroup | undefined => {
  if (pathname?.includes('/diversity.women')) {
    return 'woman'
  }
  if (pathname?.includes('/diversity.peopleOfColor')) {
    return 'poc'
  }
  if (pathname?.includes('/diversity.leadership')) {
    return 'leadership'
  }
  if (pathname?.includes('/diversity.manager')) {
    return 'manager'
  }
  return undefined
}
