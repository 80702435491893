import { useEffect, useState } from 'react'

import _, { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Schema, SelectPicker } from 'rsuite'

import { AdvancedFieldOption } from 'components/form/AdvancedFields'
import { InputAddon } from 'components/form/InputAddon'
import { Employee } from 'features/employees/types'
import { useSchema } from 'hooks'
import { useJobLevels } from 'hooks/useJobLevels'
import { useSubmit } from 'hooks/useSubmit'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import countries from 'utils/countries'
import { toSymbol } from 'utils/currency'
import { tCode } from 'utils/tCode'

import { useUpdateEmployeeMutation } from '../emloyeesApi'

export const useEmployeeEditForm = (employee: Partial<Employee>) => {
  const { t } = useTranslation()
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const levels = useJobLevels()

  const [salary] = employee?.employeesSalaries ?? []
  const [form, setForm] = useState<any>(employee ?? {})
  const [updateEmployee, { isSuccess, isLoading }] = useUpdateEmployeeMutation()

  const { onSubmit, submitting, setSubmitting } = useSubmit(() => {
    updateEmployee({
      employee: {
        ..._.omit(form, ['employment_type', 'salary', 'bonus', 'department']),
        salary: [
          {
            year: new Date().getFullYear(),
            currency: 'GBP',
            amount: form.salary,
            bonus: form.bonus,
          },
        ],
        type: form.employment_type,
        department: form.department.trim(),
      },
      sessionId,
    })
  })
  const model = Schema.Model(
    _.pick(useSchema(), [
      'email',
      'firstName',
      'lastName',
      'level',
      'city',
      'country',
      'role',
      'department',
      'employment_type',
      'setup',
      'workingHours',
      'salary',
      'bonus',
    ])
  )

  const fields: AdvancedFieldOption[] = [
    {
      title: t('employees.employee.personalInfo'),
      group: [
        [{ name: 'firstName' }, { name: 'lastName' }],
        [
          { name: 'email' },
          {
            name: 'level',
            accepter: SelectPicker,
            props: {
              data: levels.map(({ level, code }) => ({
                value: capitalize(code),
                label: t(`jobLevel.${level.toLowerCase()}`),
              })),
              searchable: false,
              cleanable: false,
            },
          },
        ],
      ],
    },
    {
      title: t('employees.employee.location'),
      group: [
        [
          { name: 'city' },
          {
            name: 'country',
            accepter: SelectPicker,
            props: {
              data: countries,
            },
          },
        ],
      ],
    },
    {
      title: t('employees.employee.workingSetup'),
      group: [
        [{ name: 'role' }, { name: 'department' }],
        [
          {
            name: 'employment_type',
            accepter: SelectPicker,
            props: {
              data: [
                'full-time',
                'part-time',
                'trainee',
                'contract',
                'commission',
                'other',
              ].map(type => ({
                value: type,
                label: tCode(t)(`employee.type.${type}`, '', type),
              })),
              searchable: false,
            },
          },
          {
            name: 'setup',
            accepter: SelectPicker,
            props: {
              data: ['remote', 'office', 'flexible', 'on-site'].map(setup => ({
                value: setup,
                label: tCode(t)(`employee.setup.${setup}`, '', setup),
              })),
              searchable: false,
            },
          },
        ],
        [{ name: 'workingHours', props: { type: 'number' } }],
      ],
    },
    {
      title: t('employees.employee.salary'),
      group: [
        [
          {
            name: 'salary',
            accepter: InputAddon,
            props: {
              type: 'number',
              addon: toSymbol(salary?.currency),
            },
          },
          {
            name: 'bonus',
            accepter: InputAddon,
            props: { type: 'number', addon: toSymbol(salary?.currency) },
          },
        ],
      ],
    },
  ]

  useEffect(() => {
    setForm({
      ...(employee ?? {}),
      employment_type: employee.type,
      salary: salary?.amount || '0',
      bonus: salary?.bonus || '0',
      workingHours: employee.workingHours || '0',
    })
  }, [employee, salary, setForm])

  return {
    model,
    fields,
    form,
    setForm,
    onSubmit,
    submitting,
    setSubmitting,
    isSuccess,
    isLoading,
  }
}
