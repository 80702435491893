import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { StateHandler } from 'components/StateHandler'

import { useGetArchivedStrategiesQuery } from 'store/strategy/strategyApi'

import { ArchivedStrategy } from './ArchivedStrategy'

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface ArchivedStrategiesProps {
  archiveId: string
}

export const ArchivedStrategies: FunctionComponent<ArchivedStrategiesProps> = ({
  archiveId,
}) => {
  const { t } = useTranslation()

  const {
    data: archivedStrategies,
    isLoading: isLoadingArchive,
    isSuccess: isArchiveSuccess,
    isError: isArchiveError,
  } = useGetArchivedStrategiesQuery()

  return (
    <StateHandler
      isLoading={isLoadingArchive}
      isSuccess={isArchiveSuccess}
      isError={isArchiveError}
      errorMessage={t('error.somethingWrong.fallback')}
    >
      <SectionWrapper id={archiveId}>
        <Text
          element="h2"
          type="body"
          size="lg"
          fontWeight={700}
          color={baseColors.black}
        >
          {t('strategy.archive.title')}
        </Text>
        <PlanWrapper>
          {archivedStrategies &&
            archivedStrategies?.length > 0 &&
            archivedStrategies.map(archivedStrategy => (
              <ArchivedStrategy
                key={archivedStrategy.sessionId}
                archivedStrategy={archivedStrategy}
              />
            ))}
        </PlanWrapper>
      </SectionWrapper>
    </StateHandler>
  )
}
