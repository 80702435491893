import React, { FunctionComponent } from 'react'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { space, baseColors, Calendar, Text } from '@fairhq/common'
import { Send } from 'components/svgs/icons/Send'

const TimingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

const StyledCalendar = styled(Calendar)`
  margin-right: ${space[1]}px;
  flex-shrink: 0;
`

const StyledSend = styled(Send)`
  margin-right: ${space[1]}px;
  flex-shrink: 0;
`

const StyledText = styled(Text)`
  margin-right: 24px;
`
export interface GenericTimingProps {
  totalNudges: number
  frequency: number
}

export const GenericTiming: FunctionComponent<GenericTimingProps> = ({
  totalNudges,
  frequency,
}) => (
  <TimingContainer>
    <StyledSend fill={baseColors.grey300} />
    <StyledText element="label" type="body" size="xs" fontWeight={600}>
      <Trans
        i18nKey="campaigns.totalNudges"
        values={{
          totalNudges,
        }}
      />
    </StyledText>
    <StyledCalendar outline={baseColors.grey300} />
    <Text element="label" type="body" size="xs" fontWeight={600}>
      <Trans
        i18nKey="campaigns.nudgeFrequency"
        values={{
          frequency,
        }}
      />
    </Text>
  </TimingContainer>
)
