import { TFunction } from 'i18next'

import { Colors } from '@fairhq/common'

import { Legend } from '../types'

export const getLegend = (t: TFunction, key?: string): Legend[] => {
  const suffix = key ? `.${key}` : ''
  return [
    {
      color: 'transparent',
      label: `${t('benchmark.title')}`,
    },
    {
      color: Colors.RED,
      label: `${t(`reports.legend.low${suffix}`)}`,
    },
    {
      color: Colors.YELLOW,
      label: `${t(`reports.legend.good${suffix}`)}`,
    },
    {
      color: Colors.GREEN,
      label: `${t(`reports.legend.great${suffix}`)}`,
    },
    {
      color: Colors.GREY300,
      label: `${t(`reports.legend.comparison${suffix}`)}`,
    },
  ]
}
