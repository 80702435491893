import React, { FunctionComponent, ReactNode } from 'react'

import { ButtonToolbar } from 'rsuite'

import { LinkButton, RoundButton } from '../../styled'
import { Message } from '../../styled/message'

import { ReactComponent as SuccessIcon } from './assets/success.svg'
import { MessageBoxContainer } from './styled'

interface MessageBoxProps {
  description?: ReactNode
  btnLabel?: string
  cancelBtnLabel?: string
  icon?: string
  note?: string
  onCancel?: () => void
  onClick?: () => void
  title?: string
  loading?: boolean
  success?: boolean
  disabled?: boolean
  before?: boolean
  children?: React.ReactNode
}

const MessageBox: FunctionComponent<MessageBoxProps> = ({
  btnLabel,
  cancelBtnLabel,
  children,
  description,
  icon,
  note,
  onClick,
  onCancel,
  title,
  loading,
  success,
  disabled,
  before,
}) => (
  <MessageBoxContainer>
    {icon ? (
      <div style={{ textAlign: 'center' }}>
        <img src={icon} alt={title} height="200" />
        {success ? (
          <SuccessIcon
            style={{
              marginLeft: '-50px',
              marginTop: '20px',
              position: 'absolute',
            }}
          />
        ) : undefined}
      </div>
    ) : undefined}
    {title ? <h3>{title}</h3> : undefined}
    {before && description ? <Message>{description}</Message> : undefined}
    {children}
    {!before && description ? <Message>{description}</Message> : undefined}
    <ButtonToolbar
      style={{ justifyContent: cancelBtnLabel ? 'space-between' : 'center' }}
    >
      {cancelBtnLabel ? (
        <LinkButton onClick={onCancel} disabled={!onCancel}>
          {cancelBtnLabel}
        </LinkButton>
      ) : undefined}
      {btnLabel ? (
        <RoundButton
          onClick={onClick}
          loading={loading}
          disabled={!onClick || loading || disabled}
        >
          {btnLabel}
        </RoundButton>
      ) : undefined}
    </ButtonToolbar>
    {note ? <Message type="indication">{note}</Message> : undefined}
  </MessageBoxContainer>
)

export default MessageBox
