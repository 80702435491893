import { FunctionComponent } from 'react'

interface StarProps {
  filled: boolean
}

export const Star: FunctionComponent<StarProps> = ({ filled }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(1.000000, 1.000000)">
      {filled ? (
        <path
          d="M6.02164296,0.575066125 C6.38157296,-0.191688875 7.47215296,-0.191688875 7.83208296,0.575067125 L9.17237296,3.43024113 C9.31394296,3.73181113 9.59639296,3.94309113 9.92568296,3.99370113 L13.004563,4.46691113 C13.808863,4.59052113 14.136663,5.57171113 13.568163,6.15391113 L11.279563,8.49783113 C11.057763,8.72502113 10.957063,9.04405113 11.008163,9.35742113 L11.539263,12.6138911 C11.673663,13.4377911 10.798963,14.0539911 10.068463,13.6499911 L7.41078296,12.1802911 C7.10965296,12.0137911 6.74406296,12.0137911 6.44294296,12.1802911 L3.78531296,13.6499911 C3.05474296,14.0539911 2.18004296,13.4377911 2.31443296,12.6138911 L2.84558296,9.35742113 C2.89669296,9.04405113 2.79595296,8.72502113 2.57413296,8.49783113 L0.285558961,6.15391113 C-0.282893039,5.57171113 0.0449009609,4.59052113 0.849148961,4.46691113 L3.92804296,3.99370113 C4.25733296,3.94309113 4.53978296,3.73181113 4.68135296,3.43024113 L6.02164296,0.575066125 Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ) : (
        <path
          d="M6.47376095,0.787531625 C6.65376095,0.404156625 7.19896095,0.404155625 7.37896095,0.787531625 L8.71926095,3.64270163 C8.93156095,4.09507163 9.35526095,4.41198163 9.84926095,4.48789163 L12.9281609,4.96111163 C13.3302609,5.02291163 13.4941609,5.51351163 13.2099609,5.80461163 L10.9213609,8.14853163 C10.5886609,8.48931163 10.4374609,8.96785163 10.5141609,9.43791163 L11.0453609,12.6943916 C11.1124609,13.1062916 10.6751609,13.4143916 10.3098609,13.2123916 L7.65226095,11.7427916 C7.20056095,11.4929916 6.65216095,11.4929916 6.20046095,11.7427916 L3.54286095,13.2123916 C3.17756095,13.4143916 2.74026095,13.1062916 2.80736095,12.6943916 L3.33856095,9.43791163 C3.41526095,8.96785163 3.26406095,8.48931163 2.93136095,8.14853163 L0.642760947,5.80461163 C0.358560947,5.51351163 0.522460947,5.02291163 0.924560947,4.96111163 L4.00346095,4.48789163 C4.49746095,4.41198163 4.92116095,4.09507163 5.13346095,3.64270163 L6.47376095,0.787531625 Z"
          stroke="#CFD6E2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      )}
    </g>
  </svg>
)
