import { FunctionComponent } from 'react'

import { Trans } from 'react-i18next'

import {
  useCreateSurveyWindowMutation,
  useGetSurveyWindowQuery,
} from 'store/surveyWindow/surveyWindowApi'

import { PromptCard } from './PromptCard'

export const SurveyWindowDue: FunctionComponent = () => {
  const { isFetching: isFetchingCurrent } = useGetSurveyWindowQuery()

  const [
    createSurveyWindow,
    { isLoading: isLoadingCreate, isError: isErrorCreate },
  ] = useCreateSurveyWindowMutation()
  const onClick = () => {
    createSurveyWindow()
  }

  return (
    <PromptCard
      title="surveys.due.title"
      description="surveys.due.description"
      buttonText="surveys.due.button"
      onClick={onClick}
      isLoading={isFetchingCurrent || isLoadingCreate}
      guidance={<Trans i18nKey="surveys.due.guidance" />}
      error={isErrorCreate}
    />
  )
}
