import { ButtonToolbar, Col, Row, Container } from 'rsuite'
import styled, { css } from 'styled-components'

export const ActionsToolbar = styled(ButtonToolbar)`
  display: flex;
  flex-flow: row;
  position: absolute;
  top: -10px;
  left: 0;

  .rs-btn {
    padding: 0;
    height: auto;

    &:not(:last-of-type) {
      margin-right: 18px;
    }
  }
`
export const ListContainer = styled(Container)``
export const ListCell = styled(Col)`
  padding-left: 30px;
  font-size: 14px;

  &:first-of-type {
    font-weight: bold;
  }
`
export const ListRowFooter = styled(Row)`
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey200};
  z-index: 10;
  border-radius: 0 0 20px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};

  p {
    padding: 30px 16px;
    font-style: italic;
    margin-bottom: 0;
  }
`
export const ListRow = styled(Row)<{ header: boolean; faded: boolean }>`
  height: 64px;
  display: flex;
  align-items: center;
  margin: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  }

  ${ListCell}:first-of-type {
    color: ${({ theme }) => theme.colors.grey500};
  }

  ${({ faded }) =>
    faded &&
    css`
      color: ${({ theme }) => theme.colors.grey300};

      ${ListCell}:first-of-type {
        color: ${({ theme }) => theme.colors.grey400};
      }
    `}

  ${({ header }) =>
    header &&
    css`
      height: 50px;
      font-weight: bold;
      background-color: ${({ theme }) => theme.colors.white};
      z-index: 10;
    `}
`
