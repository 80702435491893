import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { sortBy } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

import { Colors } from '@fairhq/common'
import { Visualization } from 'components/visualization/Visualization'
import BorderBox from 'ui-kit/BorderBox'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'

import { useReportsParams } from '../hooks/useReportsParams'

interface DiversityMakeUpProps {
  status: Record<string, number> | undefined
  graphData:
    | {
        key: string
        label: string
        value: number
      }[]
    | undefined
}

export const DiversityMakeUp: FunctionComponent<DiversityMakeUpProps> = ({
  status,
  graphData,
}) => {
  const { t } = useTranslation()
  const { code: rawCode, type } = useReportsParams()
  const code = customDecodeURIComponent(rawCode)

  if (!graphData || graphData.length === 0) {
    return null
  }

  return (
    <BorderBox mb={9} sx={{ py: 5 }}>
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
      >
        <Text as="div" variant="bodyLargeBold">
          {type
            ? t(`dashboard.makeUp`, { code: t(`${code}.title`, code) })
            : t('dashboard.diversity.companyMakeUp')}
        </Text>
        <Text as="div">
          <Trans
            i18nKey="dashboard.diversity.surveyCompleted"
            components={[<strong />]}
            values={{
              total: status?.total ?? 0,
              completed: status?.completed ?? 0,
            }}
          />
        </Text>
      </Flex>
      <Visualization
        type={graphData.length < 4 || graphData.length > 10 ? 'Row' : 'Bar'}
        items={
          sortBy(
            graphData?.map((item: any) => ({
              ...item,
              color: Colors.PURPLE,
              value:
                status?.completed && item?.value
                  ? Math.round((item.value * 100) / status.completed)
                  : 0,
              tooltip: (
                <Trans
                  i18nKey="dashboard.diversity.makeup.tooltip"
                  components={[<strong />]}
                  values={{ completed: status?.completed, count: item?.value }}
                />
              ),
            })),
            ['value']
          ) as any
        }
      />
    </BorderBox>
  )
}
