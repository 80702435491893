import React, { useEffect, FunctionComponent } from 'react'

import { Flex, Grid, Text, Heading, Box } from '@theme-ui/components'
import { round, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { makeTransition, Colors } from '@fairhq/common'

import { ReactComponent as Negative } from 'assets/icons/48/negative.svg'
import { ReactComponent as Positive } from 'assets/icons/48/positive.svg'
import { StateHandler } from 'components/StateHandler'
import { Legend } from 'components/visualization/components/Legend'
import { DATE_FORMAT_SESSION, DATE_FORMAT_SESSION_URL } from 'const'

import { AssessmentSidebar } from 'features/assessments/AssessmentSidebar'
import { DILevel } from 'features/reportsCore/DILevel'
import { EDIOverview } from 'features/reportsCore/EDIOverview'
import { useAuditBy } from 'hooks/useAuditBy'
import { useCompletion } from 'hooks/useCompletion'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { getSessionAudit } from 'store/audit/auditSlice'
import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { Session } from 'store/company/types'
import { getChangelog } from 'store/reports/reportsSlice'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import BorderBox from 'ui-kit/BorderBox'
import Icon from 'ui-kit/Icon'
import { toDate } from 'utils/dates'
import { getProgressColor } from 'utils/getProgressColor'
import { tCode } from 'utils/tCode'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { Delta } from './Delta'
import { GapList, GapListItem } from './GapList'
import { LineChart } from './LineChart'
import { SessionDropdown } from './SessionDropdown'

export const Changelog: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const tArea = tCode(t)
  const { startDate } = useParams<{ startDate: string }>()
  const [compareTo, setCompareTo] = React.useState<Session>()

  const { allChangelog } = useAppSelector(
    (state: State) => ({
      allChangelog: state.reportsReducer.changelog,
    }),
    shallowEqual
  )

  const { data: allSessions } = useGetSessionsQuery()
  const session = allSessions?.find(
    ({ start_date }) =>
      toDate(i18n.language, start_date).format(DATE_FORMAT_SESSION_URL) ===
      startDate
  )
  const changelogKey =
    session && compareTo ? `${session?.id}_${compareTo?.id}` : ''
  const changelog = allChangelog?.[changelogKey]

  const sessions = allSessions
    ?.filter(
      ({ start_date }) =>
        toDate(i18n.language, start_date).format(DATE_FORMAT_SESSION_URL) !==
        startDate
    )
    .filter(({ scorable }) => scorable)

  const completion = useCompletion(session, true)
  const { percentage, completed, auditQueryStatus } = useAuditBy()

  useEffect(() => {
    if (!changelog && compareTo && session) {
      const log = {
        compareTo: compareTo.id,
        compareToVersion: compareTo.api_version,
        compareFrom: session.id,
        compareFromVersion: session.api_version,
      }
      dispatch(getChangelog(log))
      dispatch(getSessionAudit(log))
    }
  }, [changelog, compareTo, dispatch, session])

  useEffect(() => {
    if (sessions && sessions.length > 0 && !compareTo) {
      setCompareTo(sessions[0])
    }
  }, [compareTo, i18n, sessions])

  const toGapListItem = ({
    area_title,
    area_code = '',
    difference = 0,
    score,
    max,
  }: any): GapListItem => ({
    key: area_code + Math.random(),
    label: tArea(area_code, 'title', area_title),
    icon: area_code,
    value: round(+((score * 10) / +max), 1),
    delta: round(difference, 1) || round(difference, 2),
  })

  return (
    <StateHandler
      isSuccess={auditQueryStatus === QueryStatus.SUCCESS}
      isLoading={auditQueryStatus === QueryStatus.LOADING}
      isError={auditQueryStatus === QueryStatus.ERROR}
    >
      <Layout sidebar={<AssessmentSidebar />} dark>
        <PageTop>
          <Flex sx={{ alignItems: 'baseline' }}>
            <Link to="/survey">
              <Flex
                sx={{
                  alignItems: 'center',
                  color: 'text',
                  transition: makeTransition('color'),
                  '&:hover': { color: 'grey500' },
                  borderRight: theme => `1px solid ${theme.colors?.grey200}`,
                }}
                mr="18px"
                pr="18px"
              >
                <Icon name="arrowLeft" sx={{ mr: 1 }} small />
                <Text as="div" variant="caps">
                  {t('survey.navTitle')}
                </Text>
              </Flex>
            </Link>
            <Heading>
              {(session?.start_date &&
                toDate(i18n.language, session?.start_date).format(
                  DATE_FORMAT_SESSION
                )) ||
                t('changelog.title')}
            </Heading>
          </Flex>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Text as="div" variant="bodyLargeBold" mr="24px">
              {t('changelog.compareTo')}
            </Text>
            <SessionDropdown
              sessions={sessions || []}
              value={
                compareTo?.start_date &&
                toDate(i18n.language, compareTo?.start_date).format(
                  DATE_FORMAT_SESSION_URL
                )
              }
              onChange={e => {
                const newSession = sessions?.find(
                  ({ start_date }) =>
                    toDate(i18n.language, start_date).format(
                      DATE_FORMAT_SESSION_URL
                    ) === e?.target?.value
                )
                setCompareTo(newSession)
              }}
            />
          </Flex>
        </PageTop>

        <BorderBox mb={9}>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Text as="div" variant="h3" mb="18px">
                {t('changelog.surveyResult')}
              </Text>
              <Text as="div">{t('changelog.surveysResultWhatHasChanged')}</Text>
            </Box>
            <Text
              as="div"
              variant="heading"
              sx={{
                color: getProgressColor(completion?.total),
              }}
            >
              <Text as="div">
                {t('changelog.completion', { percent: completion?.total })}
              </Text>
            </Text>
          </Flex>
        </BorderBox>
        <Grid columns={6} gap={4}>
          <DILevel
            span={3}
            completed={completed ?? false}
            percent={percentage}
            processing={false}
            dniLevel={(changelog?.dniLevel ?? {}) as any}
            isChangelog
          />
          <EDIOverview
            span={3}
            scoreAreas={
              changelog?.general?.map(
                ({ area_code, score, difference, max }) => ({
                  name: area_code || '',
                  score,
                  max,
                  difference: round(+difference, 1),
                })
              ) ?? []
            }
            isScorable
            isChangelog
          />
        </Grid>

        <Flex
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          mb={9}
          mt={9}
        >
          <Text as="div" variant="bodyLargeBold">
            {t('changelog.whatHasChanged')}
          </Text>
          <Text as="div">{t('changelog.areasThatHaveChanged')}</Text>
        </Flex>

        <Grid columns={2} gap={4} mb={9}>
          {(changelog?.positive ?? [])?.filter(
            ({ area_code, difference }: any) =>
              !!round(+difference, 2) && area_code !== 'equality.dnIstrategy'
          )?.length > 0 ? (
            <GapList
              title={[
                <Positive key="changelog-positive-icon" />,
                <Text
                  as="div"
                  variant="bodyLargeBold"
                  ml="12px"
                  key="changelog-positive"
                >
                  {t('changelog.positiveChanges')}
                </Text>,
              ]}
              items={sortBy(
                changelog?.positive
                  ?.filter(
                    ({ area_code, difference }: any) =>
                      !!round(+difference, 2) &&
                      area_code !== 'equality.dnIstrategy'
                  )
                  .map(toGapListItem) ?? [],
                ['delta']
              ).reverse()}
            />
          ) : undefined}
          {(changelog?.negative ?? [])?.filter(
            ({ area_code, difference }: any) =>
              !!round(+difference, 2) && area_code !== 'equality.dnIstrategy'
          )?.length > 0 ? (
            <GapList
              title={[
                <Negative key="changelog-negative-icon" />,
                <Text
                  as="div"
                  variant="bodyLargeBold"
                  ml="12px"
                  key="changelog-negative"
                >
                  {t('changelog.negativeChanges')}
                </Text>,
              ]}
              items={sortBy(
                changelog?.negative
                  ?.filter(
                    ({ area_code, difference }: any) =>
                      !!round(+difference, 2) &&
                      area_code !== 'equality.dnIstrategy'
                  )
                  .map(toGapListItem) ?? [],
                ['delta']
              )}
            />
          ) : undefined}
        </Grid>

        {changelog?.general?.map(area => (
          <BorderBox mb={9} sx={{ py: 5 }}>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 5,
              }}
            >
              <Text as="div" variant="bodyLargeBold">
                {t(`${area.area_code}.changelog`)}
              </Text>
              <Flex sx={{ alignItems: 'center' }}>
                <Delta
                  variant="bodyLargeBold"
                  delta={round(+area.difference, 1) || 0}
                />
                <Text
                  as="div"
                  variant="subtitle"
                  sx={{ width: 60, textAlign: 'right', color: 'grey500' }}
                >
                  {round((area.score * 10) / area.max, 1)}
                </Text>
              </Flex>
            </Flex>

            <LineChart
              items={
                area.areas
                  ?.filter(
                    ({ area_code }: any) => area_code !== 'equality.dnIstrategy'
                  )
                  .map(
                    ({
                      area_code,
                      area_title,
                      difference,
                      max,
                      score,
                      scoreCompare,
                      maxCompare,
                    }) => ({
                      key: area_code || '',
                      label: tArea(area_code, 'changelog', area_title),
                      from: round((score * 10) / max, 1),
                      to: maxCompare
                        ? round(((scoreCompare ?? 0) * 10) / maxCompare, 1)
                        : 0,
                      delta: round(difference, 1),
                    })
                  ) ?? []
              }
            />
            <Legend
              legend={[
                {
                  color: Colors.PURPLE,
                  label: session?.start_date
                    ? toDate(i18n.language, session?.start_date).format(
                        DATE_FORMAT_SESSION
                      )
                    : '',
                },
                {
                  color: Colors.GREY200,
                  label: compareTo?.start_date
                    ? toDate(i18n.language, compareTo?.start_date).format(
                        DATE_FORMAT_SESSION
                      )
                    : '',
                },
              ]}
              sx={{ mt: 4 }}
            />
          </BorderBox>
        ))}
      </Layout>
    </StateHandler>
  )
}
