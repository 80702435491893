import styled from 'styled-components'

export const Square = styled.div<{
  backgroundColor: string
  borderColor?: string
}>`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex-shrink: 0;
  line-height: 1rem;
  margin: 4px 0;
  border: 1px solid
    ${({ borderColor, backgroundColor }) => borderColor ?? backgroundColor};
`
