import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import { markedNumberFormat } from 'utils/numbers'

import { ReactComponent as DonutSvg } from '../assets/donut.svg'

interface DonutChartProps {
  value: number
  max: number
  color?: string
  valueLabel?: string | number
  maxLabel?: string
  sx?: ThemeUIStyleObject
  diff?: number
  showDetail?: boolean
  diameter?: number
}

export const DonutChart: FunctionComponent<DonutChartProps> = ({
  value = 0,
  max = 100,
  diff = 0,
  color = 'primary',
  valueLabel,
  maxLabel,
  sx,
  showDetail = true,
  diameter = 210,
}) => {
  const { t } = useTranslation()
  const circumference = 90 * 2 * Math.PI // r * 2 * π, r = 90 from SVG

  const circToShow = 1 - Math.min(1, value / max)
  const donutThickness = (diameter - 30) / 3
  const smallCircleSize = diameter - donutThickness
  const smallCirclePosition = donutThickness / 2

  return (
    <Box
      sx={{
        position: 'relative',
        width: diameter,
        height: diameter,
        bg: 'grey100',
        borderRadius: '50%',
        ...sx,
      }}
    >
      <Box
        as={DonutSvg}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          stroke: color,
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: `${circumference * circToShow}`,
          transform: `rotate(-90deg)`,
        }}
      />
      <Flex
        sx={{
          position: 'absolute',
          top: `${smallCirclePosition}px`,
          left: `${smallCirclePosition}px`,
          bg: 'white',
          borderRadius: '50%',
          width: smallCircleSize,
          height: smallCircleSize,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 8px 12px rgba(10, 27, 64, 0.1)',
        }}
      >
        <Text
          as="div"
          variant="title"
          sx={{ color: value === 0 ? 'grey300' : '' }}
        >
          {valueLabel || value}
        </Text>
        {showDetail && (
          <Text as="div" variant="bodyLargeBold" sx={{ color: 'grey300' }}>
            {maxLabel || t(`of`, { value: Math.round(max) })}
          </Text>
        )}

        {diff ? (
          <Text
            as="div"
            variant="bodyLargeBold"
            sx={{ color: diff < 0 ? 'red' : 'green' }}
          >
            {markedNumberFormat(diff)}
          </Text>
        ) : undefined}
      </Flex>
    </Box>
  )
}
