import { FunctionComponent } from 'react'

import { Label } from '@theme-ui/components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

import { baseColors } from '../../primitives/colors'
import { Clock } from '../icons/Clock'

import { Input } from './Input'

const Container = styled.div`
  * {
    font-family: inherit;
  }

  /* Input placeholder text */
  input {
    ::placeholder {
      color: ${baseColors.grey400};
    }
  }

  /* Entire popper */
  .react-datepicker {
    border-color: ${baseColors.grey200};
    color: ${baseColors.grey500};
    border-radius: 20px;
    padding: 16px 0;
  }

  /* Date picker */
  .react-datepicker-popper {
    width: 120px;

    div {
      width: 100% !important;
    }
  }

  /* Triangle position */
  .react-datepicker__triangle {
    transform: translate3d(65px, 0, 0) !important;
  }

  /* Inside of triangle */
  .react-datepicker-popper .react-datepicker__triangle::after {
    border-bottom-color: ${baseColors.white};
  }

  /* Triangle border */
  .react-datepicker-popper .react-datepicker__triangle::before {
    border-bottom-color: ${baseColors.grey200};
  }

  /* Popper header area */
  .react-datepicker__header {
    background-color: ${baseColors.white};
    border-bottom: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 0;
    padding-bottom: 8px;
  }

  /* Time header */
  .react-datepicker-time__header {
    color: ${baseColors.grey500};
    font-weight: normal;
    font-size: 16px;
  }

  /* Entire popper - time */
  .react-datepicker__time {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .react-datepicker__time-container {
    /* Individual time */
    .react-datepicker__time-list-item {
      font-size: 14px;
      text-align: left;
      padding: 3px 16px !important;
      height: 32px;
      margin-top: 8px;
    }

    .react-datepicker__time-box ul.react-datepicker__time-list {
      /* Time hover */
      li.react-datepicker__time-list-item:hover {
        background-color: ${baseColors.grey100};
        color: ${baseColors.purple};
        font-weight: bold;
      }

      /* Selected time */
      li.react-datepicker__time-list-item--selected {
        background-color: ${baseColors.purple};
        color: ${baseColors.white};
        font-weight: bold;
      }

      /* Disabled time */
      li.react-datepicker__time-list-item--disabled {
        color: ${baseColors.grey300};

        &:hover {
          background-color: ${baseColors.white};
          color: ${baseColors.grey300};
          font-weight: normal;
        }
      }
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid ${baseColors.grey200};
  border-radius: 8px;
  padding: 14px;
  color: ${baseColors.grey500};
  width: 100%;
`

export interface TimePickerProps {
  value: Date | null
  onChange: (date: Date | null) => void
  id: string
  label: string
  timeIntervalsMinutes?: number
  placeholder?: string
  filterTime?: (date: Date) => boolean
  className?: string
}

export const TimePicker: FunctionComponent<TimePickerProps> = ({
  value,
  onChange,
  id,
  label,
  filterTime,
  placeholder,
  className,
  timeIntervalsMinutes = 15,
}) => (
  <Container className={className}>
    <Label
      htmlFor={id}
      sx={{
        mb: 1,
        fontWeight: 'bold',
        fontSize: 13,
        color: baseColors.grey500,
      }}
    >
      {label}
    </Label>
    <StyledDatePicker
      selected={value}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeIntervalsMinutes}
      timeCaption="Time"
      dateFormat="h:mm aa"
      filterTime={filterTime}
      placeholderText={placeholder}
      customInput={<Input icon={<Clock />} showArrow fieldId={id} />}
    />
  </Container>
)
