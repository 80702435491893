import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { ApiVersionOptions } from 'store/apiHeaders/types'
import { State } from 'store/state'

import { companyActions } from './companySlice'
import { Company, Entitlement, Session } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to companyApi

const companyApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getSessions: build.query<Session[], void>({
      query: () => ({
        url: `/companies/sessions`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      providesTags: [TagTypes.SESSIONS],
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        const { data: sessions } = await queryFulfilled

        const { apiHeadersReducer } = getState() as State
        const sessionId = apiHeadersReducer?.sessionId
        if (!sessionId) {
          dispatch(apiHeadersActions.setSessionId(sessions?.[0]?.id))
        }
      },
    }),
    createSession: build.mutation<Session[], void>({
      query: () => ({
        url: `/sessions`,
        method: 'POST',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
        invalidatesTags: [TagTypes.SESSIONS],
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const { data: sessions } = await queryFulfilled
        dispatch(apiHeadersActions.setSessionId(sessions?.[0]?.id))
        dispatch(apiHeadersActions.setApiVersion(sessions?.[0]?.api_version))
      },
    }),
    getEntitlements: build.query<Entitlement, void>({
      query: () => ({
        url: '/companies/entitlements',
      }),
    }),
    createCompany: build.mutation<Company, { company: Partial<Company> }>({
      query: ({ company }) => ({
        url: `/companies`,
        method: 'POST',
        body: { ...company },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const { data: company } = await queryFulfilled
        dispatch(apiHeadersActions.setCompanyId(company?.id))
        dispatch(apiHeadersActions.setSessionId(company.sessions?.[0]?.id))
        dispatch(companyActions.setCompany(company))
      },
    }),
  }),
})

export const {
  useGetSessionsQuery,
  useCreateSessionMutation,
  useGetEntitlementsQuery,
  useCreateCompanyMutation,
} = companyApi
