import { FunctionComponent } from 'react'

import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, space } from '@fairhq/common'
import { ProgressBar } from 'ui-kit/ProgressBar'

import { getProgressColor } from 'utils/getProgressColor'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space[3]}px;
`
interface SurveyProgressBarProps {
  surveyPercentage: number
  completedSurveys?: string
  totalSurveys?: string
}
export const SurveyProgressBar: FunctionComponent<SurveyProgressBarProps> = ({
  surveyPercentage,
  completedSurveys,
  totalSurveys,
}) => {
  const surveyProgressColor = getProgressColor(surveyPercentage)

  return (
    <Container>
      <ProgressBar
        value={surveyPercentage}
        color={surveyProgressColor}
        lineAtPercentage={75}
      />
      <Text sx={{ alignSelf: 'flex-end', mt: 2, color: baseColors.grey400 }}>
        {completedSurveys} of {totalSurveys} employees
      </Text>
    </Container>
  )
}
