import { Strategy } from 'store/strategy/types'
import { TaskStatus } from 'store/tasks/types'
import { findTasks } from 'utils/findInsightsAndTasks'

export const useInitiatives = (strategy: Strategy) => {
  const uncompletedInitiatives = []
  for (const companyInitiative of strategy.companyInitiatives) {
    const tasks = findTasks({
      recommendation: companyInitiative.recommendation,
      companyTasks: strategy.companyTasks,
    })
    const isToBeCompleted = tasks.find(
      task =>
        task.status === TaskStatus.IN_PROGRESS ||
        task.status === TaskStatus.NOT_STARTED
    )
    if (isToBeCompleted) {
      uncompletedInitiatives.push({ ...companyInitiative, tasks })
    }
    // eslint-disable-next-line no-continue
    continue
  }

  const initiativesToShow = uncompletedInitiatives
    .slice(0, 2)
    .map(initiative => ({
      ...initiative,
      tasks: initiative.tasks
        .filter(
          task =>
            task.status === TaskStatus.IN_PROGRESS ||
            task.status === TaskStatus.NOT_STARTED
        )
        .slice(0, 3),
    }))

  return {
    initiativesToShow,
    uncompletedInitiatives,
  }
}
