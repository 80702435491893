import { TFunction } from 'i18next'
import { chain } from 'lodash'

import { getDemGroupRepresentationByTab } from './getDemGroupRepresentationByTab'
import { getRepresentationByTab } from './getRepresentationByTab'

export const getDataByTab = (
  t: TFunction,
  {
    jobLevel,
    group,
    location,
    department,
    demGroups,
    surveysInfo,
    total,
    type,
    benchmark,
    sorted,
  }: Record<'jobLevel' | 'location' | 'department' | string, any>
) => {
  const levels = chain(total?.jobLevel).keyBy('level').value()

  const jobLevelType = [
    {
      key: 'employees',
      label: t('reports.byJobLevel.allEmployees'),
      total: surveysInfo?.completed ?? 0,
      max: surveysInfo?.total ?? 0,
      minority: surveysInfo?.minority ?? 0,
      majority: surveysInfo?.majority ?? 0,
    },
    {
      key: 'ic',
      type: 'Employee',
      label: t(
        'reports.byJobLevel.individualContributors',
        'Individual contributors'
      ),
      total: levels?.Employee?.completed ?? 0,
      max: levels?.Employee?.total ?? 0,
      minority: levels?.Employee?.minority ?? 0,
      majority: levels?.Employee?.majority ?? 0,
    },
    {
      key: 'manager',
      type: 'Manager',
      label: t('reports.byJobLevel.manager'),
      total: levels?.Manager?.completed ?? 0,
      max: levels?.Manager?.total ?? 0,
      minority: levels?.Manager?.minority ?? 0,
      majority: levels?.Manager?.majority ?? 0,
    },
    {
      key: 'leadership',
      type: 'Leadership',
      label: t('reports.byJobLevel.leadership'),
      total: levels?.Leadership?.completed ?? 0,
      max: levels?.Leadership?.total ?? 0,
      minority: levels?.Leadership?.minority ?? 0,
      majority: levels?.Leadership?.majority ?? 0,
    },
  ]

  if (!type || type !== 'dem_groups') {
    return getRepresentationByTab({
      jobLevelType,
      jobLevel,
      group,
      location,
      department,
      groupType: type,
      t,
      sorted,
    })
  }

  return getDemGroupRepresentationByTab({
    jobLevelType,
    jobLevel,
    location,
    department,
    demGroups,
    groups: total,
    benchmark,
    t,
  })
}
