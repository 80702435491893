import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { makeTransition } from '@fairhq/common'

import {
  OldRecommendation,
  OldCompanyRecommendationStatus,
  oldRecommendationStatusColor,
  oldRecommendationStatusLabel,
  oldRecommendationStatusIcon,
} from 'store/oldRecommendations/types'
import Icon from 'ui-kit/Icon'

import { OldRecommendationArea } from './OldRecommendationArea'
import { OldRecommendationDifficulty } from './OldRecommendationDifficulty'

interface OldRecommendationCardProps {
  oldRecommendation?: Partial<OldRecommendation>
  status?: OldCompanyRecommendationStatus | string
  dark?: boolean
}

export const OldRecommendationCard: FunctionComponent<
  OldRecommendationCardProps
> = ({ oldRecommendation, status = '', dark }) => {
  const { t } = useTranslation()

  return (
    <Link
      to={`/strategy/${oldRecommendation?.code}`}
      style={{ alignSelf: 'stretch' }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          bg: dark ? 'grey100' : 'white',
          p: 5,
          borderRadius: 18,
          overflow: 'hidden',
          '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 6,
            bg: oldRecommendationStatusColor[status],
            opacity: 0.7,
          },
          '&:hover': {
            '.Old__Recommendation__title': {
              color: 'purple',
            },
          },
        }}
      >
        <Flex sx={{ alignItems: 'center', mb: 5 }}>
          <Icon
            name={oldRecommendationStatusIcon[status]}
            color={oldRecommendationStatusColor[status]}
            sx={{ mr: 1 }}
          />
          <Text
            as="div"
            variant="caps"
            sx={{ color: oldRecommendationStatusColor[status] }}
          >
            {oldRecommendationStatusLabel(t)[status]}
          </Text>
        </Flex>
        <Text
          as="div"
          variant="h3"
          sx={{
            flex: '1 1 auto',
            mb: 4,
            fontWeight: 400,
            transition: makeTransition('color'),
            minHeight: 60,
          }}
          className="Old__Recommendation__title"
        >
          {oldRecommendation?.title}
        </Text>
        <Flex>
          <OldRecommendationArea
            data={oldRecommendation?.type?.[0] as string}
            sx={{ mr: 4 }}
          />
          <OldRecommendationDifficulty
            data={oldRecommendation?.intensity as string}
          />
        </Flex>
      </Box>
    </Link>
  )
}
