import { Container } from 'rsuite'
import styled from 'styled-components'

import { DocumentsList } from './DocumentsList'
import { DocumentTypeCard } from './DocumentTypeCard'

import { DocumentUploader } from './DocumentUploader'

const DocumentViewUploadContainer = styled(Container)`
  max-width: 480px;
`

const DocumentViewContainer = styled(Container)`
  flex-flow: row;
  max-width: ${({ theme }) => theme.maxWidth2};
  width: 100%;
  margin: 100px auto auto !important;
  justify-content: space-between;
  align-items: baseline;
`

export const DocumentView = () => (
  <DocumentViewContainer>
    <DocumentTypeCard />
    <DocumentViewUploadContainer>
      <DocumentsList />
      <DocumentUploader
        accept="image/jpeg,image/gif,image/png,application/pdf"
        autoUpload={false}
      />
    </DocumentViewUploadContainer>
  </DocumentViewContainer>
)
