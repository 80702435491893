import React, { FunctionComponent } from 'react'

import { Box, Flex, Grid, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'

import { State } from 'store/state'

import { ReactComponent as ReportsIcon } from './assets/reports.svg'
import { ReportsStatusCard } from './ReportsStatusCard'
import { useReportsStatus } from './useReportsStatus'

export const ReportsStatus: FunctionComponent = () => {
  const { t } = useTranslation()
  const { companyName } = useAppSelector(
    (state: State) => ({
      companyName: state.companyReducer.company?.name,
    }),
    shallowEqual
  )
  const status = useReportsStatus()

  return (
    <Box>
      <Flex sx={{ alignItems: 'center' }}>
        <Text
          as="div"
          variant="label"
          sx={{
            flex: 1,
            marginRight: '87px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {t('reports.landing.description', { companyName })}
        </Text>
        <ReportsIcon />
      </Flex>
      <Text
        as="div"
        variant="h2"
        sx={{ mt: '16px', mb: '24px', fontSize: '22px', lineHeight: '28px' }}
      >
        {t('reports.landing.unlock')}
      </Text>
      <Grid columns={2} gap={5}>
        {Object.keys(status).map(type => (
          <ReportsStatusCard key={type} type={type} status={status[type]} />
        ))}
      </Grid>
    </Box>
  )
}
