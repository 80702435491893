import { FunctionComponent } from 'react'

import { Grid, Flex, Text } from '@theme-ui/components'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import Icon from 'ui-kit/Icon'
import ProgressBox from 'ui-kit/ProgressBox'

import { formatSpan } from 'utils/formatSpan'
import { markedNumberFormat } from 'utils/numbers'

import { SpanProp } from './Dashbox'

interface EDIOverviewProps {
  span: SpanProp
  isScorable?: boolean
  isChangelog?: boolean
  scoreAreas?: {
    name?: string
    score?: number
    max?: number
    difference?: number
  }[]
}

export const EDIOverview: FunctionComponent<EDIOverviewProps> = ({
  isScorable,
  isChangelog,
  span,
  scoreAreas,
}) => {
  const { t } = useTranslation()

  return (
    <Grid gap={4} sx={{ gridColumn: formatSpan(span) }}>
      {scoreAreas?.map(({ name, score, max, difference }) => {
        const scoreBy10 = ((score ?? 0) * 10) / (max ?? 0) ?? 0
        const color =
          scoreBy10 >= 7.5 ? 'green' : (scoreBy10 >= 5 && 'yellow') || 'red'
        return (
          <ProgressBox
            key={name}
            progressValue={isScorable ? Math.round(score ?? 0) : 0}
            progressMax={max}
            progressColor={color}
            sx={{ borderRadius: 20 }}
            to={
              isScorable && score && !isChangelog
                ? `/reports/${name}`
                : undefined
            }
            animated={isScorable && !!score}
          >
            <Flex
              sx={{
                px: 6,
                py: 1,
                minHeight: '82px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Flex sx={{ alignItems: 'center' }}>
                <Text as="div" variant="h3">
                  {t(`${name}.title`)}
                </Text>
                <Icon name="arrowRight" small sx={{ ml: 2 }} />
              </Flex>
              <Flex sx={{ alignItems: 'center' }}>
                {difference ? (
                  <Text
                    as="div"
                    variant="bodyLargeBold"
                    mr="24px"
                    sx={{ color: difference < 0 ? 'red' : 'green' }}
                  >
                    {markedNumberFormat(difference)}
                  </Text>
                ) : undefined}
                <Text as="div" variant="title" sx={{ color }}>
                  {isScorable && max
                    ? round(((score ?? 0) * 10) / max ?? 0, 1)
                    : 0}
                </Text>
              </Flex>
            </Flex>
          </ProgressBox>
        )
      })}
    </Grid>
  )
}
