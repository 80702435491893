import { FunctionComponent } from 'react'

import { Flex, Grid, Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAreaProgress } from 'features/areas/hooks/useAreaProgress'
import { useAreas } from 'hooks/useAreas'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { Layout } from '../../layout/Layout'
import { PageTop } from '../../layout/PageTop'

import { useAppSelector } from '../../store/hooks'

import { AssessmentSidebar } from './AssessmentSidebar'
import { Documents } from './components/Documents'
import Surveys from './components/Surveys'

const AreaDetail: FunctionComponent = () => {
  const { t } = useTranslation()
  const { code = '' } = useParams<{ code: string }>()
  const { area, companyAreas, documentTypes } = useAppSelector(
    (state: State) => ({
      area: state.areaReducer.area,
      companyAreas:
        state.areaReducer.areas?.filter(({ sources }) =>
          sources?.includes('company')
        ) ?? [],
      documentTypes:
        state.documentReducer?.documentTypes?.filter(({ area: areaType }) =>
          areaType?.code?.includes(code)
        ) ?? [],
    }),
    shallowEqual
  )
  const tArea = tCode(t)
  const { completed, total } = useAreaProgress({ area: { code } })

  useAreas({})

  return (
    <Layout dark sidebar={<AssessmentSidebar />}>
      <PageTop>
        <Flex sx={{ alignItems: 'baseline' }}>
          <Heading>{tArea(area?.code, 'title', area?.title)}</Heading>
        </Flex>
        <Text as="div" variant="subtitle" sx={{ color: 'grey400' }}>
          {Math.round((completed * 100) / total)}%
        </Text>
      </PageTop>

      <Grid
        columns={documentTypes.length === 0 ? 1 : 2}
        gap={4}
        sx={{ alignItems: 'flex-start' }}
      >
        {companyAreas.length > 0 && <Surveys />}
        {documentTypes.length > 0 && <Documents />}
      </Grid>
    </Layout>
  )
}

export default AreaDetail
