import { useState } from 'react'

export const useSubmit = (callback?: () => void) => {
  const [submitting, setSubmitting] = useState(false)

  function onSubmit(isValid: boolean) {
    if (!isValid || submitting) {
      return
    }
    setSubmitting(true)
    if (callback) {
      callback()
    }
  }

  return { onSubmit, submitting, setSubmitting }
}
