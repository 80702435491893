import Bugsnag, { NotifiableError } from '@bugsnag/js'

export async function waitForResponse({
  callback,
  withJSON = true,
}: {
  callback: () => Promise<any>
  withJSON?: boolean
}) {
  try {
    const response = await callback()
    if (!response || response.status === 204 || response.status === 404) {
      return undefined
    }
    if (withJSON) {
      return (await response?.json()) ?? (await undefined)
    }
    return response
  } catch (error) {
    const typedError = error as NotifiableError
    Bugsnag.notify(typedError)
    throw error
  }
}
