import React from 'react'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { Text } from '@fairhq/common'

import { PlainCard } from './PlainCard'

const Container = styled(PlainCard)`
  display: flex;
  padding: 32px 40px;
  gap: 24px;
`

const ImageContainer = styled.div`
  align-self: center;
`

const Image = styled.img`
  width: 150px;
`

export const StrategyFinished = () => (
  <Container>
    <ImageContainer>
      <Image
        src="https://files.fairhq.co/ilustrations/workflow-design-gameplan-team-teamwork-search-find-idea-thought.svg"
        alt="Abstract illustration about team work"
      />
    </ImageContainer>
    <div>
      <Text element="p" fontWeight={400} type="body" size="md">
        <Trans i18nKey="promoteStrategy.finished" />
      </Text>
    </div>
  </Container>
)
