import { FunctionComponent, PropsWithChildren } from 'react'

import { Flex } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { baseColors } from '@fairhq/common'

import { DEFAULT_BAR_HEIGHT, DEFAULT_LABEL_WIDTH } from '../constants'

export const Grid: FunctionComponent<
  {
    benchmarkValue?: number
    isVertical?: boolean
    sx?: ThemeUIStyleObject
  } & PropsWithChildren
> = ({ benchmarkValue, isVertical, children, sx }) => (
  <Flex
    sx={{
      justifyContent: isVertical ? undefined : 'space-around',
      flexDirection: isVertical ? 'column' : 'row',
      zIndex: 2,
      minHeight: isVertical ? undefined : '250px',
      width: '100%',
      position: 'relative',
      ...sx,
    }}
  >
    <Flex
      mb={6}
      sx={{
        zIndex: 1,
        width: isVertical ? `calc(100% - ${DEFAULT_LABEL_WIDTH}px)` : '100%',
        position: 'absolute',
        height: '100%',
        maxHeight: isVertical ? undefined : `${DEFAULT_BAR_HEIGHT}px`,
        // overflow: 'hidden',
        marginLeft: isVertical ? `${DEFAULT_LABEL_WIDTH}px` : undefined,
        border: '1px solid',
        borderColor: baseColors.grey200_2,
        borderBottomWidth: !isVertical ? '1px' : '0px',
        borderTopWidth: !isVertical ? '1px' : '0px',
        borderLeftWidth: isVertical ? '1px' : '0px',
        borderRightWidth: isVertical ? '1px' : '0px',
        '&:before': {
          display: 'block',
          width: !isVertical ? '100%' : (benchmarkValue && 2) || 1,
          height: isVertical ? '100%' : (benchmarkValue && 2) || 1,
          marginTop:
            (!isVertical &&
              (benchmarkValue
                ? `${(1 - benchmarkValue) * DEFAULT_BAR_HEIGHT}px`
                : '100px')) ||
            undefined,
          marginLeft:
            (isVertical &&
              (benchmarkValue ? `${benchmarkValue * 100}%` : '50%')) ||
            undefined,
          bg: benchmarkValue ? 'transparent' : 'border',
          backgroundImage: benchmarkValue
            ? `linear-gradient(to ${isVertical ? 'top' : 'left'}, ${
                baseColors.grey200
              } 25%, transparent 25%, transparent 75%, ${
                baseColors.grey200
              } 75%)`
            : '',
          backgroundSize: isVertical ? '2px 24px' : '24px 2px',
          content: '""',
          position: 'absolute',
        },
      }}
    />
    {children}
  </Flex>
)
