import { FunctionComponent, useEffect } from 'react'

import { Heading, Link, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { PageTop } from 'layout/PageTop'
import { count as countEmployees } from 'store/employee/employeeSlice'
import { count as countSurvey } from 'store/survey/surveySlice'
import RouterLink from 'ui-kit/RouterLink'

import { useAppDispatch } from '../../store/hooks'

import { ReAudit } from './ReAudit'
import { SurveyHeader } from './SurveyHeader'
import { SurveyList } from './SurveyList'

export const Survey: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(countEmployees())
    dispatch(countSurvey())
  }, [dispatch])

  return (
    <>
      <PageTop>
        <Heading>{t('survey.employees.title')}</Heading>
        <Text as="div">
          <Link
            variant="dark"
            href="https://fairhq.notion.site/D-I-survey-overview-questions-examples-da825bcd7b0f44b485568c043f7a45b1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('survey.aboutSurvey')}
          </Link>
          <RouterLink to="/employees" sx={{ ml: 4 }}>
            {t('survey.manageEmployees')}
          </RouterLink>
        </Text>
      </PageTop>
      <SurveyHeader />
      <ReAudit />
      <SurveyList />
    </>
  )
}
