import React, { FunctionComponent, useState } from 'react'

import { useParams } from 'react-router'

import { CompletedTaskModal } from 'components/TaskWithDropdown/CompletedTaskModal'
import { SkippedTaskModal } from 'components/TaskWithDropdown/SkippedTaskModal'
import { StatusDropdown } from 'components/TaskWithDropdown/StatusDropdown'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'
import { useIsTaskLinkedToStrategyQuery } from 'store/tasks/tasksApi'
import { Task as TaskType, TaskStatus } from 'store/tasks/types'

interface TaskStatusDropdownProps {
  status: TaskStatus
  task: TaskType
}

export const TaskStatusDropdown: FunctionComponent<TaskStatusDropdownProps> = ({
  status,
  task,
}) => {
  const { code = '' } = useParams<{ code: string }>()

  const [skipTask, setSkipTask] = useState<TaskType | null>(null)
  const [completeTask, setCompleteTask] = useState<TaskType | null>(null)
  const [currentStatus, setCurrentstatus] = useState<TaskStatus>(task.status)

  const { refetch: refetchStrategy } = useGetStrategyQuery()

  const { data: isTaskLinkedToStrategy } = useIsTaskLinkedToStrategyQuery({
    code,
  })

  const handleSkippedTaskModalClose = () => {
    setSkipTask(null)
    refetchStrategy()
  }

  const handleCompletedTaskModalClose = () => {
    setCompleteTask(null)
    refetchStrategy()
  }

  if (status === TaskStatus.NOT_STARTED && !isTaskLinkedToStrategy) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  return (
    <>
      <StatusDropdown
        status={currentStatus}
        task={task}
        setSkipTask={() => {
          setSkipTask(task)
        }}
        setCompleteTask={() => {
          setCompleteTask(task)
        }}
        setCurrentstatus={setCurrentstatus}
      />
      {skipTask && (
        <SkippedTaskModal
          task={skipTask}
          onClose={handleSkippedTaskModalClose}
        />
      )}

      {completeTask && (
        <CompletedTaskModal
          task={completeTask}
          onClose={handleCompletedTaskModalClose}
        />
      )}
    </>
  )
}
