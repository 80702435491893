import { useEffect, FunctionComponent } from 'react'

import { Grid, Flex, Heading, Text } from '@theme-ui/components'

import { chain, sampleSize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { OldRecommendationArea } from 'features/strategy/oldStrategy/OldRecommendationArea'
import { OldRecommendationCard } from 'features/strategy/oldStrategy/OldRecommendationCard'
import { OldRecommendationDifficulty } from 'features/strategy/oldStrategy/OldRecommendationDifficulty'

import { Layout } from 'layout/Layout'
import { useAppSelector } from 'store/hooks'
import { useGetOldRecommendationsQuery } from 'store/oldRecommendations/oldRecommendationApi'

import { OldRecommendation as OldRecommendationType } from 'store/oldRecommendations/types'
import { State } from 'store/state'

import { OldRecommendationDetail } from './OldRecommendationDetail'
import { OldStrategySidebar } from './OldStrategySidebar'
import { StatusPicker } from './StatusPicker'

import 'react-notion-x/src/styles.css'

export const OldRecommendation: FunctionComponent = () => {
  const { t } = useTranslation()
  const { recCode } = useParams<{ recCode: string }>()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: oldRecommendations, isLoading } = useGetOldRecommendationsQuery(
    {
      sessionId,
    }
  )

  const oldRecommendation = chain(oldRecommendations)
    .map('oldRecommendation')
    .keyBy('code')
    .value()?.[recCode as string] as Partial<OldRecommendationType>

  const oldCompanyRecommendation = (oldRecommendations ?? [])?.find(
    companyRec => companyRec?.oldRecommendation?.code === recCode
  )
  const otherOldRecommendations = oldRecommendations?.filter(
    ({ oldRecommendation: rec }) => rec?.code !== recCode
  )

  useEffect(() => {
    const scroller = document.querySelector('.Layout-scroller')
    if (scroller) {
      scroller.scrollTo({ top: 0 })
    }
  }, [recCode])

  if (!oldRecommendation && !isLoading) {
    return (
      <Layout dark>
        <Heading>{t('oldRecommendations.404')}</Heading>
      </Layout>
    )
  }

  return (
    <Layout sidebar={<OldStrategySidebar />} maxContentWidth={1080} px={8} dark>
      <Heading sx={{ mb: 6 }}>{oldRecommendation?.title}</Heading>

      <Flex sx={{ mb: 6 }}>
        <OldRecommendationArea
          data={oldRecommendation?.type?.[0] as string}
          sx={{ mr: 6 }}
        />
        <OldRecommendationDifficulty
          data={oldRecommendation?.intensity as string}
        />
        <StatusPicker
          data={oldCompanyRecommendation?.status as string}
          oldRecommendationId={oldCompanyRecommendation?.id as number}
          sx={{ ml: 'auto', my: -2 }}
        />
      </Flex>

      <OldRecommendationDetail
        isOldRecommendationsLoading={isLoading}
        notionLink={oldRecommendation?.notionLink as string}
      />

      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 4 }}
      >
        <Text as="div" variant="h3">
          {t('oldRecommendations.otherRecommendationsForYou')}
        </Text>
        <Link to="/strategy">
          <Text as="div" variant="label" sx={{ fontWeight: 700 }}>
            {t('viewAll')}
          </Text>
        </Link>
      </Flex>

      <Grid columns={2} sx={{ columnGap: 4, rowGap: 3 }}>
        {sampleSize(otherOldRecommendations, 2).map(
          ({ oldRecommendation, status }) => (
            <OldRecommendationCard
              key={oldRecommendation?.id}
              oldRecommendation={oldRecommendation}
              status={status}
            />
          )
        )}
      </Grid>
    </Layout>
  )
}
