import { FunctionComponent, useLayoutEffect, useRef } from 'react'

import { Text, Box } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { NotionRenderer } from 'react-notion-x'

import { useAppSelector } from 'store/hooks'
import { useGetOldRecommendationNotionPageQuery } from 'store/oldRecommendations/oldRecommendationApi'
import { State } from 'store/state'

import { getPageIdFromNotionUrl } from 'utils/getPageIdFromNotionUrl'

import { DefaultLink } from './DefaultLink'

interface OldRecommendationDetailProps {
  isOldRecommendationsLoading: boolean
  notionLink: string
}

export const OldRecommendationDetail: FunctionComponent<
  OldRecommendationDetailProps
> = ({ isOldRecommendationsLoading, notionLink }) => {
  const notionRef = useRef<any>()
  const { t } = useTranslation()

  const pageId = getPageIdFromNotionUrl(notionLink as string)

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: page } = useGetOldRecommendationNotionPageQuery({
    pageId,
    sessionId,
  })

  useLayoutEffect(() => {
    if (notionRef.current && page) {
      const titlesID = ['why', 'how', 'resources']
      const notionTitles = notionRef.current.querySelectorAll('.notion-h-title')
      if (notionTitles?.length > 0) {
        notionTitles.forEach((title: any, index: number) => {
          title?.setAttribute('id', titlesID[index])
        })
      }
    }
  }, [page])

  return (
    <Box
      sx={{ bg: 'white', py: 11, px: 6, mb: 9, borderRadius: 20 }}
      css={`
        img {
          max-width: 100%;
        }
      `}
    >
      <Text
        as="div"
        sx={{
          maxWidth: '660px',
          mx: 'auto',
          color: 'grey500',
        }}
        ref={notionRef}
      >
        {page && !isOldRecommendationsLoading ? (
          <NotionRenderer
            recordMap={page}
            mapPageUrl={(pageUrl: string) =>
              `https://notion.so/${pageUrl?.replace(/-/g, '')}`
            }
            components={{ PageLink: DefaultLink }}
          />
        ) : (
          t('loading')
        )}
      </Text>
    </Box>
  )
}
