import { FunctionComponent, useState } from 'react'

import { format } from 'date-fns'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text } from 'theme-ui'

import { baseColors, Calendar, space, Spinner } from '@fairhq/common'

import { State } from 'store/state'
import { Message } from 'styled/message'

import { useAppSelector } from '../../store/hooks'

import { Event } from './Event'
import { useGetEventsQuery } from './eventsApi'

const SpinnerContainer = styled.div`
  margin: ${space[10]}px 0;
  display: flex;
  justify-content: center;
  div {
    height: ${space[6]}px;
  }
`

const OuterContainer = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
`

const EventsBodyContainer = styled.div`
  padding: ${space[10]}px;
`
const MonthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
`
const EventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const MonthHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Divider = styled.i`
  height: 1px;
  background-color: ${baseColors.grey200};
  flex-grow: 1;
`

const ToggleMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid ${baseColors.grey200};
  padding: 20px 0;
`

export const Events: FunctionComponent = () => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const {
    data: events,
    isSuccess,
    isLoading,
    isError,
  } = useGetEventsQuery({ sessionId })
  const [showAllEvents, setShowAllEvents] = useState<boolean>(false)

  const EVENTS_DISPLAYED_MAX = 4
  const maxEvents = events?.slice(0, EVENTS_DISPLAYED_MAX)

  const eventsGroupedByMonth = groupBy(
    showAllEvents ? events : maxEvents,
    event => event.startDate.substring(0, 7)
  )

  const months = Object.keys(eventsGroupedByMonth)

  return (
    <OuterContainer>
      <EventsBodyContainer>
        <LabelContainer>
          <Calendar outline={baseColors.purple} />
          <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
            {t(`events.title`)}
          </Text>
        </LabelContainer>
        <Text as="div" variant="title" sx={{ mb: 2, fontSize: 24 }}>
          {t(`events.heading`)}
        </Text>
        <Text
          as="div"
          sx={{ mt: 2, mb: 3, color: baseColors.grey400, fontSize: 16 }}
        >
          {t(`events.description`)}
        </Text>
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {isError && (
          <div>
            <Message type="error">{t('events.loadingError')}</Message>
          </div>
        )}
        {isSuccess && (
          <EventsListContainer>
            {months.map((month, index) => (
              <MonthContainer key={index}>
                <MonthHeadingContainer>
                  <Text sx={{ color: baseColors.grey400, fontSize: 14 }}>
                    {format(new Date(month), 'MMMM y')}
                  </Text>
                  <Divider />
                </MonthHeadingContainer>
                {eventsGroupedByMonth[month].map(event => (
                  <Event key={event.code} event={event} />
                ))}
              </MonthContainer>
            ))}
          </EventsListContainer>
        )}
      </EventsBodyContainer>
      {events && events.length > EVENTS_DISPLAYED_MAX && (
        <ToggleMoreContainer onClick={() => setShowAllEvents(!showAllEvents)}>
          <Text variant="bodyLargeBold" sx={{ color: baseColors.purple700 }}>
            {showAllEvents ? t('events.showLess') : t('events.showMore')}
          </Text>
        </ToggleMoreContainer>
      )}
    </OuterContainer>
  )
}
