import { User } from '@auth0/auth0-spa-js'
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { authActions } from 'store/auth/authSlice'
import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAll } from 'store/helpers/isClearAll'
import { isRejected } from 'store/helpers/isRejected'

import { accountApi } from './accountApi'
import { AccountState, Account, UserMetadata } from './types'

const clear = createAction('area/clear')

export const getAccounts = createAsyncThunk(
  'account/getAccounts',
  async (_, { dispatch, getState }) => {
    const accounts: any = await waitForResponse({
      callback: () => accountApi.getAccounts(getState),
    })
    const firstAccount = accounts[0]

    const firstAccountCompanyId = firstAccount?.companies?.[0]?.id
    dispatch(apiHeadersActions.setCompanyId(firstAccountCompanyId))

    const firstAccountId = accounts[0]?.id
    if (firstAccountId) {
      dispatch(apiHeadersActions.setAccountId(firstAccountId))
    }

    dispatch(
      apiHeadersActions.setSessionId(
        firstAccount?.companies?.[0]?.sessions?.[0]?.id || 0
      )
    )

    return accounts
  }
)

export const checkRole = createAsyncThunk(
  'account/checkRole',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => accountApi.checkRole(getState),
      withJSON: false,
    })
)

export const createAccount = createAsyncThunk(
  'account/createAccount',
  async (account: Partial<Account>, { dispatch, getState }) => {
    const accountCreated = await waitForResponse({
      callback: () => accountApi.createAccount(getState, account),
    })
    if (accountCreated) {
      dispatch(apiHeadersActions.setAccountId(accountCreated.id))
    }
    return accountCreated
  }
)

export const getUsers = createAsyncThunk(
  'account/getUsers',
  async (_, { getState }) =>
    waitForResponse({
      callback: () => accountApi.getUsers(getState),
    })
)

export const addUser = createAsyncThunk(
  'account/addUser',
  async (
    { user, authRole }: { user: User; authRole: string },
    { getState }
  ) => {
    const result = await waitForResponse({
      callback: () => accountApi.addUser(getState, user, authRole),
    })

    return result
  }
)

export const removeUser = createAsyncThunk(
  'account/removeUser',
  async (userId: string, { getState }) => {
    await accountApi.removeUser(getState, userId)
    return userId
  }
)

export const sendEmailVerification = createAsyncThunk(
  'account/sendEmailVerification',
  async (userId: string, { getState }) =>
    waitForResponse({
      callback: () => accountApi.sendEmailVerification(getState, userId),
    })
)

export const requestChangePasswordEmail = createAsyncThunk(
  'account/requestChangePasswordEmail',
  async (userId: string, { getState }) =>
    waitForResponse({
      callback: () => accountApi.requestChangePasswordEmail(getState, userId),
    })
)

export const updateUserMetadata = createAsyncThunk(
  'account/updateMe',
  async (
    { user, metadata }: { user: Partial<User>; metadata: UserMetadata },
    { dispatch, getState }
  ) => {
    const userUpdated = await waitForResponse({
      callback: () => accountApi.updateUserMetadata(getState, user, metadata),
    })
    dispatch(authActions.setUser(userUpdated))

    return userUpdated
  }
)

export const updateOnboarding = createAsyncThunk(
  'account/updateOnboarding',
  async (
    {
      user,
      refreshUser = true,
    }: { user: Partial<User>; refreshUser?: boolean },
    { dispatch, getState }
  ) => {
    const userUpdated = await waitForResponse({
      callback: () => accountApi.updateOnboarding(getState, user),
    })
    if (refreshUser) {
      dispatch(authActions.setUser(userUpdated))
    }
    return userUpdated
  }
)

const initialState: Partial<AccountState> = { loading: false }

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.loading = false
        state.accounts = action.payload
      })
      .addCase(createAccount.pending, state => {
        state.loading = true
      })
      .addCase(createAccount.fulfilled, state => {
        state.loading = false
      })
      .addCase(addUser.pending, state => {
        state.loading = true
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false
        state.users = [...(state.users || []), action.payload]
      })
      .addCase(getUsers.pending, state => {
        state.loading = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false
        state.users = action.payload
      })
      .addCase(removeUser.pending, state => {
        state.loading = true
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.loading = false
        state.users =
          state.users && action.payload
            ? state.users.filter(user => user?.user_id !== action.payload)
            : state.users
      })
      .addCase(requestChangePasswordEmail.pending, state => {
        state.sending = true
        state.emailSent = false
      })
      .addCase(requestChangePasswordEmail.fulfilled, state => {
        state.sending = false
        state.emailSent = true
      })
      .addCase(updateUserMetadata.pending, state => {
        state.saved = false
      })
      .addCase(updateUserMetadata.fulfilled, state => {
        state.saved = true
      })
      .addCase(updateOnboarding.pending, state => {
        state.saved = false
      })
      .addCase(updateOnboarding.fulfilled, state => {
        state.saved = true
      })
      .addCase(checkRole.pending, state => {
        state.isCheckRolesLoading = true
      })
      .addCase(checkRole.fulfilled, state => {
        state.isCheckRolesLoading = false
        state.roleChecked = true
      })
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('account'), handleErrorState)
  },
})

export const { actions: accountActions, reducer: accountReducer } = accountSlice
