import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { ArrowWithBackground } from 'components/ArrowWithBackground'
import { StatusTagWithRoundIcon } from 'components/StatusTagWithRoundIcon'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import { AuditArea } from 'store/audit/types'
import { LevelCompletion } from 'store/reports/types'

import { useSubAreacompletion } from './hooks/useSubAreaCompletion'

const SubAreaBox = styled(WhiteBoxWithShadow)`
  gap: 40px;
  align-items: center;
  padding: 18px 36px 18px 24px;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 120px;
`

const CardBody = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:focus-visible::before {
    outline: 2px solid ${baseColors.purple};
    outline-offset: -2px;
    border-radius: 20px;
  }
`

interface SubAreaCardProps {
  subArea: AuditArea
  levelInfo: LevelCompletion
  firstChildArea: AuditArea | undefined
}

export const SubAreaCard: FunctionComponent<SubAreaCardProps> = ({
  subArea,
  levelInfo,
  firstChildArea,
}) => {
  const { t } = useTranslation()

  const { subAreaCompletion } = useSubAreacompletion(levelInfo, subArea.code)

  return (
    <SubAreaBox>
      <StatusWrapper>
        <Text
          fontWeight={700}
          size="lg"
          element="p"
          type="body"
          color={baseColors.purple800}
        >
          {subArea.title}
        </Text>
        {subAreaCompletion >= 0 && (
          <StatusTagWithRoundIcon
            percentage={subAreaCompletion}
            withCompleteLabels={false}
          />
        )}
      </StatusWrapper>
      <CardBody>
        <Text fontWeight={400} size="sm" element="p" type="body">
          {t(`${subArea.code}.description`)}
        </Text>
        {firstChildArea && (
          <StyledLink
            title={`Go to ${firstChildArea.title}`}
            to={`/reports/equality/${firstChildArea.code}`}
          >
            <ArrowWithBackground />
          </StyledLink>
        )}
      </CardBody>
    </SubAreaBox>
  )
}
