import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Tick: FunctionComponent<IconProps> = ({
  className,
  fill = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.40739 14.1242L17.3367 6.21574C18.0104 5.54379 19.1009 5.54379 19.7746 6.21574C20.4509 6.8902 20.4509 7.98561 19.7746 8.66007L9.40739 19L4.22539 13.8316C3.54915 13.1572 3.54915 12.0618 4.22539 11.3873C4.89911 10.7154 5.98956 10.7154 6.66328 11.3873L9.40739 14.1242Z"
      stroke={fill}
      fill={fill}
    />
  </svg>
)
