import React, { FunctionComponent } from 'react'

import i18next from 'i18next'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { space, baseColors, Calendar, Text } from '@fairhq/common'
import { toDate } from 'utils/dates'

const TimingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

const StyledCalendar = styled(Calendar)`
  margin-right: ${space[1]}px;
`

export interface InProgressTimingProps {
  frequency?: number
  nextNudgeOn: string
  nudgesToSend: number
  withIcon?: boolean
}

export const InProgressTiming: FunctionComponent<InProgressTimingProps> = ({
  frequency,
  nextNudgeOn,
  nudgesToSend,
  withIcon = true,
}) => {
  const relativeDate = toDate(i18next.language, nextNudgeOn)?.calendar(null, {
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    nextWeek: 'on dddd',
    sameElse: 'on DD/MM/YYYY',
  })

  const isLast = nudgesToSend <= 1

  return (
    <TimingContainer>
      {withIcon && <StyledCalendar outline={baseColors.grey300} />}
      <Text element="label" type="body" size="xs" fontWeight={400}>
        {isLast ? (
          <Trans
            i18nKey="campaigns.specificDatesRelativeFinal"
            values={{
              relativeDate,
            }}
          />
        ) : (
          <Trans
            i18nKey={
              frequency
                ? 'campaigns.specificDatesRelativeNextFrequency'
                : 'campaigns.specificDatesRelativeNext'
            }
            values={{
              relativeDate,
              frequency,
            }}
          />
        )}
      </Text>
    </TimingContainer>
  )
}
