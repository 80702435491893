import { baseColors } from '@fairhq/common'

export const useExperienceBar = ({
  total,
  detractors,
  passives,
  promoters,
}: {
  total: number
  detractors: number
  passives: number
  promoters: number
}) => {
  const totalBy100 = total / 100

  const data = {
    total,
    detractorsPercentage: detractors / totalBy100,
    passivesPercentage: passives / totalBy100,
    promotersPercentage: promoters / totalBy100,
  }

  const colors = {
    green: baseColors.fairGreen400,
    yellow: baseColors.yellow,
    red: baseColors.red400,
  }

  const sadSize =
    data.detractorsPercentage > data.promotersPercentage &&
    data.detractorsPercentage > data.passivesPercentage
      ? '24'
      : '12'

  const happySize =
    data.promotersPercentage > data.detractorsPercentage &&
    data.promotersPercentage > data.passivesPercentage
      ? '24'
      : '12'

  const backgroundImage = `linear-gradient(90deg, ${colors.red} ${
    data.detractorsPercentage
  }%, ${colors.yellow} ${data.detractorsPercentage}%, ${colors.yellow} ${
    data.detractorsPercentage + data.passivesPercentage
  }%, ${colors.green} ${data.detractorsPercentage + data.passivesPercentage}%)`

  return { sadSize, happySize, backgroundImage, colors }
}
