import queryString from 'query-string'

import { http } from 'utils/http'

import { AreasQueryParams } from './types'

export const areaApi = {
  getAreas(getState: () => unknown, query: AreasQueryParams) {
    return http.get(getState, `areas?${queryString.stringify(query as any)}`)
  },
  getStatusByArea(getState: () => unknown, code: string) {
    return http.get(getState, `areas/${code}/status`)
  },
  getDocumentTypesByArea(getState: () => unknown, code: string) {
    return http.get(getState, `areas/${code}/document-types`)
  },
  getAssessmentsByArea(getState: () => unknown, areaId: number) {
    return http.get(getState, `assessments/${areaId}`)
  },
  getQuestionsByArea(getState: () => unknown, code: string) {
    return http.get(getState, `areas/${code}/questions`)
  },
}
