import { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'

import { Colors } from '@fairhq/common'

import { decimalFormat } from 'utils/numbers'

type ColorGetterFn = (score: number, max?: number) => Colors

const getScoreColor: ColorGetterFn = score => {
  if (score >= 7.5) {
    return Colors.GREEN
  }

  if (score >= 5) {
    return Colors.YELLOW
  }

  return Colors.RED
}

interface ScoreProps {
  score: number
  max?: number
  colorGetter?: ColorGetterFn
}

export const Score: FunctionComponent<ScoreProps> = ({
  score,
  max = 10,
  colorGetter = getScoreColor,
}) => (
  <Text
    as="div"
    variant="bodyBold"
    sx={{
      lineHeight: '30px',
      whiteSpace: 'nowrap',
      width: 120,
      textAlign: 'right',
      color: 'grey300',
    }}
  >
    <Text sx={{ fontSize: 18, color: colorGetter(score) }}>
      {decimalFormat(score * 10)}
    </Text>{' '}
    of {max}
  </Text>
)
