import styled, { css } from 'styled-components'

export const Message = styled.p<{
  type?:
    | 'welcome'
    | 'error'
    | 'success'
    | 'indication'
    | 'text'
    | 'question'
    | 'consent'
    | 'privacy'
}>`
  margin-bottom: 16px;
  ${({ type, theme }) =>
    type === 'consent' &&
    css`
      color: ${theme.colors.grey400};
      font-size: 16px;
      line-height: 1.87;
    `}
  ${({ type }) =>
    type === 'text' &&
    css`
      margin-bottom: 0;
    `}
  ${({ type }) =>
    type === 'question' &&
    css`
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 0;
    `}
  ${({ type }) =>
    type === 'welcome' &&
    css`
      margin-bottom: 32px;
    `};
  ${({ type, theme }) =>
    type === 'error' &&
    css`
      color: ${theme.colors.red};
      display: flex;
    `}
  ${({ type, theme }) =>
    type === 'success' &&
    css`
      color: ${theme.colors.green};
      display: flex;
    `};
  ${({ type, theme }) =>
    type === 'indication' &&
    css`
      color: ${theme.colors.grey400};
      display: flex;
      font-size: 14px;
      line-height: 1.71;
    `}
  ${({ type, theme }) =>
    type === 'privacy' &&
    css`
      color: ${theme.colors.grey300};
      display: flex;
      font-size: 14px;
      line-height: 1.71;
      margin-bottom: 0;
      margin-top: 166px;

      a {
        color: inherit;
        font-weight: normal;
        text-decoration: underline;
        display: contents;
      }
    `}

  .rs-icon {
    margin-right: 8px;
  }
`

Message.defaultProps = { type: 'welcome' }
