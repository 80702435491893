import { FunctionComponent, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'
import { Uploader, UploaderProps } from 'rsuite'

import { ReactComponent as AddIcon } from '../../assets/add.svg'
import { LinkButton } from '../../styled'
import { MessageBox } from '../index'

interface FileUploaderProps extends UploaderProps {
  description: ReactNode
  icon?: string
  title?: string
}

const FileUploader: FunctionComponent<FileUploaderProps> = ({
  description,
  icon,
  title,
  draggable,
  ...restOfProps
}) => {
  const { t } = useTranslation()
  return (
    <Uploader draggable={draggable} {...restOfProps}>
      {draggable ? (
        <div>
          <MessageBox description={description} title={title} icon={icon} />
        </div>
      ) : (
        <LinkButton>
          <AddIcon /> <span>{t('upload.new')}</span>
        </LinkButton>
      )}
    </Uploader>
  )
}

export default FileUploader
