import { FunctionComponent } from 'react'

import { NavLink } from 'react-router-dom'
import { Container } from 'rsuite'
import styled from 'styled-components'

import { ReactComponent as BackIcon } from './assets/back.svg'

const TitleContainer = styled(Container)`
  a {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 13px;
    line-height: 1.38;
    color: ${({ theme }) => theme.colors.grey400};

    svg {
      margin-right: 12px;
    }
  }
`

const Title: FunctionComponent<{
  withBack?: { label: string; url: string }
  children?: React.ReactNode
}> = ({ children, withBack }) => (
  <TitleContainer>
    {withBack ? (
      <NavLink to={withBack.url}>
        <BackIcon /> <span>{withBack.label}</span>
      </NavLink>
    ) : undefined}
    <h2>{children}</h2>
  </TitleContainer>
)

export default Title
