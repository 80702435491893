import { ApiVersionOptions } from 'store/apiHeaders/types'
import { OldCompanyRecommendation } from 'store/oldRecommendations/types'
import { http } from 'utils/http'

import { Company } from './types'

export const companyApi = {
  updateCompany(
    getState: () => unknown,
    id: number,
    company: Partial<Company>
  ) {
    return http.patch(getState, `companies/${id}`, company)
  },
  getAPIVersion(getState: () => unknown) {
    return http.get(getState, `companies/version`, {
      'fairhq-version': ApiVersionOptions.V2,
    })
  },
  updateOldRecommendation(
    getState: () => unknown,
    id: number,
    { status }: Partial<OldCompanyRecommendation>
  ) {
    return http.put(getState, `old-recommendations/${id}`, { status })
  },
}
