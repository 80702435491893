import React, { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Text, baseColors } from '@fairhq/common'

import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'

import { SquaredProgressBar } from './SquaredProgressBar'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-basis: 50%;
`

const RightColumn = styled(Column)`
  gap: 16px;
`

const StyledButton = styled(Button)`
  align-self: flex-end;
  min-width: 180px;
`

const ProgressBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProgressText = styled(Text)`
  white-space: nowrap;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface PromptCardProps {
  title: string
  description?: string
  guidance?: ReactElement
  buttonText?: string
  onClick?: () => void
  progress?: { max: number; value: number }
  isLoading?: boolean
  error?: boolean
}

export const PromptCard: FunctionComponent<PromptCardProps> = ({
  title,
  description,
  guidance,
  buttonText,
  onClick,
  progress,
  isLoading,
  error,
}) => {
  const { t } = useTranslation()
  const { permissions } = useAuthPermissions()

  return (
    <>
      <Column>
        <Text element="h2" type="body" size="md" fontWeight={600}>
          {t(title)}
        </Text>
        {progress && (
          <ProgressBlock>
            <ProgressText
              element="p"
              type="body"
              size="md"
              fontWeight={400}
            >{`${progress.value} / ${progress.max}`}</ProgressText>
            <SquaredProgressBar value={progress.value} max={progress.max} />
          </ProgressBlock>
        )}
        {description && (
          <Text element="p" type="body" size="md" fontWeight={400}>
            {t(description)}
          </Text>
        )}
      </Column>
      <RightColumn>
        {guidance && (
          <Text element="p" type="body" size="sm" fontWeight={400}>
            {guidance}
          </Text>
        )}

        {permissions.includes(PermissionsEnum.CREATE_SESSION) ? (
          <>
            {buttonText && onClick && (
              <StyledButton loading={isLoading} onClick={onClick}>
                {t(buttonText)}
              </StyledButton>
            )}
          </>
        ) : null}

        {error && (
          <ErrorContainer>
            <Text
              element="div"
              type="body"
              fontWeight={600}
              size="xs"
              color={baseColors.red}
            >
              {t('error.TryAgain')}
            </Text>
          </ErrorContainer>
        )}
      </RightColumn>
    </>
  )
}
