import React, { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'

import { Label } from '../components/Label'
import { DEFAULT_BAR_HEIGHT } from '../constants'
import { VisualizationItemProps } from '../types'

import { ComparisonVisualizationItemValue } from './ComparisonVisualizationItemValue'

export const ComparisonVisualizationItem: FunctionComponent<{
  item: VisualizationItemProps
  isVertical?: boolean
  isScoreBy10?: boolean
}> = ({ item, isVertical, isScoreBy10 }) => (
  <Flex
    sx={{
      transition: 'all 0.15s ease-out',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: isVertical ? 'row' : 'column-reverse',
      zIndex: 3,
      flex: 1,
      borderRadius: '12px',
      padding: '0 6px',
      paddingBottom: '18px',
      bg: 'transparent',
      maxWidth: '150px',
      '&:hover': item.isTransparent
        ? undefined
        : {
            bg: 'white',
            boxShadow: '0px 18px 30px -6px rgba(10, 27, 64, 0.1)',
          },
    }}
  >
    <Label label={item.label} />
    <Flex
      sx={{
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'space-around',
        minHeight: `${DEFAULT_BAR_HEIGHT}px`,
        maxHeight: `${DEFAULT_BAR_HEIGHT}px`,
        width: '100%',
        marginBottom: '8px',
      }}
    >
      {item.values.map(itemValue => (
        <ComparisonVisualizationItemValue
          key={itemValue.key}
          item={itemValue}
          isScoreBy10={isScoreBy10}
        />
      ))}
    </Flex>
  </Flex>
)
