import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeTransition } from '@fairhq/common'

import { Tooltip } from '../components/Tooltip'
import { whiteColorBgChecker } from '../constants'
import { VisualizationItemValueProps, VisualizationTypeProps } from '../types'

export const StackVisualizationItemValue: FunctionComponent<
  VisualizationTypeProps<VisualizationItemValueProps>
> = ({ item, isScoreBy10 }) => {
  const { t } = useTranslation()
  const label = item.value ? (
    (isScoreBy10 && round(item.value / 10, 1)) || `${round(item.value)}%`
  ) : (
    <Box as="span" sx={{ opacity: 0 }}>
      {t('na')}
    </Box>
  )

  return (
    <Box
      sx={{
        width: '100%',
        height: `${item.value > 100 ? 100 : item.value}%`,
        padding: '0 6px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          flex: item.key === 'benchmark' ? 1 : undefined,
          borderRadius: '12px 12px 0 0',
          bg: item.color,
          transition: makeTransition('opacity'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          minHeight: `100%`,
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-12px',
            left: '0',
            right: '0',
            height: '12px',
            bg: item.value ? item.color : undefined,
          },
          '&:hover': { '.tooltip': { opacity: 1 } },
        }}
      >
        <Text
          as="div"
          variant="bodyLargeBold"
          sx={{
            color:
              item.value > 0 && item.key !== 'benchmark'
                ? (whiteColorBgChecker.includes(item.color) && 'white') ||
                  'grey400'
                : 'grey500',
            top:
              item.value === 0 || item.key === 'benchmark'
                ? '-34px'
                : undefined,
            position: 'absolute',
          }}
        >
          {label}
        </Text>
        <Tooltip tooltip={item.tooltip} />
      </Box>
    </Box>
  )
}
