import { FunctionComponent } from 'react'

import { alpha } from '@theme-ui/color'
import { Box, Flex, Grid, Text } from '@theme-ui/components'
import { map as _map, size as _size } from 'lodash'
import { ThemeUIStyleObject } from 'theme-ui'

import { Colors } from '@fairhq/common'
import Icon, { IconName } from 'ui-kit/Icon'

import { decimalFormat } from 'utils/numbers'

const getPillStyle: (score: ScoreMatrixScore) => ThemeUIStyleObject = ({
  color,
}) => {
  switch (color) {
    case Colors.RED:
      return {
        bg: Colors.RED,
        color: Colors.WHITE,
      }
    case Colors.GREEN:
      return {
        bg: Colors.GREEN,
        color: Colors.WHITE,
      }
    case Colors.YELLOW:
      return {
        bg: alpha(Colors.YELLOW, 0.15),
        color: Colors.YELLOW,
      }
    default:
      return {
        bg: Colors.GREY200,
        color: Colors.GREY400,
      }
  }
}

interface ScoreMatrixScore {
  value: number
  color: Colors
}

export interface Data {
  icon: IconName
  category: string
  scores: Record<string, ScoreMatrixScore>
}

interface ScoreMatrixRowProps {
  data: Data
}

export const ScoreMatrixRow: FunctionComponent<ScoreMatrixRowProps> = ({
  data,
}) => (
  <>
    <Box
      sx={{
        borderTop: '1px solid',
        borderColor: '#CFD6E2',
        position: 'sticky',
        left: 0,
        right: 0,
      }}
    />
    <Flex
      sx={{
        borderColor: '#CFD6E2',
        py: 3,
        width: 'fit-content',
        '&:first-of-type': {},
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flex: '0 0 240px',
          mr: 8,
          position: 'sticky',
          left: 0,
          backgroundColor: 'white',
        }}
      >
        {data.icon && <Icon name={data.icon} sx={{ mr: 2 }} />}
        <Text as="div" variant="bodyBold" sx={{ color: 'grey500' }}>
          {data.category}
        </Text>
      </Flex>
      <Grid columns={_size(data.scores)} gap={8} sx={{ flex: '1 1 auto' }}>
        {_map(data.scores, (score, key) => (
          <Text
            as="div"
            key={key}
            variant="bodyLargeBold"
            sx={{
              borderRadius: 100,
              py: 2,
              textAlign: 'center',
              width: '140px',
              ...getPillStyle(score),
            }}
          >
            {decimalFormat(score.value * 10)}
          </Text>
        ))}
      </Grid>
    </Flex>
  </>
)
