import React, { FunctionComponent, ReactNode } from 'react'

import { Box } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors } from '@fairhq/common'

import Button from '../../ui-kit/Button'
import WhiteBox from '../../ui-kit/WhiteBox'

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Confirm: FunctionComponent<{
  onCancel: () => void
  onSubmit: () => void
  visible: boolean
  cancelLabel?: string
  confirmLabel?: string
  danger?: boolean
  loading?: boolean
  onClose?: () => void
  title?: ReactNode
  error?: ReactNode
  errorLabel?: string
  children?: React.ReactNode
}> = ({
  children,
  cancelLabel,
  confirmLabel,
  danger,
  loading,
  onCancel,
  onClose,
  onSubmit,
  visible,
  title,
  error,
  errorLabel,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose || onCancel}
      shouldCloseOnOverlayClick
    >
      <WhiteBox
        sx={{
          maxWidth: '720px',
          width: '100%',
          textAlign: 'center',
          h3: { fontSize: '18px', mb: '24px' },
        }}
      >
        {title}
        {children}
        <Box>
          <Button
            disabled={loading}
            onClick={onSubmit}
            loading={loading}
            sx={{ bg: danger && 'red' }}
            mr="18px"
            size="large"
          >
            {confirmLabel || t('confirm.yes')}
          </Button>
          <Button variant="secondary" onClick={onCancel} size="large">
            {t(cancelLabel || 'confirm.cancel')}
          </Button>
        </Box>
        {error && (
          <ErrorContainer>
            <Text
              as="div"
              variant="label"
              sx={{
                color: baseColors.red,
                fontWeight: 'bold',
                mt: 4,
                width: '100%',
              }}
            >
              {t(errorLabel || 'error.somethingWrong.fallback')}
            </Text>
          </ErrorContainer>
        )}
      </WhiteBox>
    </Modal>
  )
}
