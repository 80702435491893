import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { SurveyWindow } from 'store/surveyWindow/types'

import { PromptCard } from './PromptCard'

interface SurveyWindowOpenProps {
  currentSurveyWindow: SurveyWindow
}

export const SurveyWindowOpen: FunctionComponent<SurveyWindowOpenProps> = ({
  currentSurveyWindow,
}) => {
  const { t } = useTranslation()

  return (
    <PromptCard
      title="surveys.open.title"
      progress={{
        max: currentSurveyWindow.totalInvited,
        value: currentSurveyWindow.totalCompleted,
      }}
      guidance={
        <Trans
          i18nKey="surveys.open.guidance"
          components={[
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t('surveys.open.guidanceLinkInvite')}
            >
              |
            </a>,
          ]}
        />
      }
    />
  )
}
