import { ReactNode, FunctionComponent } from 'react'

import { Box, Flex, Text, Link as ThemeLink } from '@theme-ui/components'
import { round } from 'lodash'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { DILevel as DILevelType } from 'store/score/types'
import Button from 'ui-kit/Button'
import { DonutChart } from 'ui-kit/DonutChart'
import ProgressBox from 'ui-kit/ProgressBox'

import { formatSpan } from 'utils/formatSpan'
import { SCORE_MAX, getLevel, getLevelColor } from 'utils/scores'

import { SpanProp } from './Dashbox'

interface DILevelProps {
  span: SpanProp
  percent: number
  completed: boolean
  processing: boolean
  dniLevel?: DILevelType
  sessionReportLink?: string
  scorable?: boolean
  isChangelog?: boolean
  requestReports?: () => void
}

export const DILevel: FunctionComponent<DILevelProps> = ({
  span,
  sessionReportLink,
  percent,
  completed,
  scorable,
  processing,
  dniLevel,
  requestReports,
  isChangelog,
}) => {
  const { t } = useTranslation()
  const getStep = () => {
    const steps: Record<
      string,
      { content: ReactNode; progressColor?: string }
    > = {
      start: {
        content: (
          <>
            <Text as="div" sx={{ mt: 5, mb: 6 }}>
              {t('dashboard.diLevel.findOutMore')}
            </Text>

            <Button icon="assessment" iconPosition="left" as={Link} to="/audit">
              {t('assessment.welcome.startAssessment')}
            </Button>
          </>
        ),
        progressColor: 'grey100',
      },
      continue: {
        content: (
          <>
            <Text as="div" variant="heading" sx={{ mt: 5, mb: 1 }}>
              {`${percent}%`}
            </Text>
            <Text as="div" sx={{ mb: 3 }}>
              {t('dashboard.diLevel.assessmentCompleted')}
            </Text>
            <Button icon="assessment" iconPosition="left" as={Link} to="/audit">
              {t('dashboard.diLevel.continueAssessment')}
            </Button>
          </>
        ),
        progressColor: 'grey100',
      },
      requestReports: {
        content: (
          <>
            <Text as="div" sx={{ mt: 2, mb: 5 }}>
              {t('dashboard.diLevel.thanksForCompleting')}
            </Text>
            <Button onClick={() => requestReports?.()}>
              {t('dashboard.diLevel.requestReports')}
            </Button>
          </>
        ),
        progressColor: 'primary',
      },
      processingReports: {
        content: (
          <Trans
            i18nKey="dashboard.diLevel.sitBackAndRelax"
            components={[
              <Text as="div" variant="subtitle" sx={{ mt: 1, mb: 2 }} />,
              <Text as="div" sx={{ mb: 4 }} />,
              <Text as="div" variant="bodyBold" />,
            ]}
          />
        ),
      },
      viewLevel: {
        content: (
          <>
            <Text
              as="div"
              variant="title"
              sx={{ mt: 1, mb: 2, fontSize: isChangelog ? '18px' : undefined }}
            >
              {t(`score.level.${getLevel(dniLevel?.score ?? 0)}`)}
            </Text>
            <Text as="div" sx={{ mb: 3 }}>
              <Trans
                i18nKey="dashboard.diLevel.learnMore"
                components={[
                  <ThemeLink href={t('meta.EDINotion')} target="_blank" />,
                ]}
              />
            </Text>
            {sessionReportLink ? (
              <Button
                as="a"
                href={sessionReportLink}
                variant="secondary"
                target="_blank"
              >
                {t('dashboard.diLevel.downloadReport')}
              </Button>
            ) : null}
          </>
        ),
        progressColor:
          (dniLevel?.score ?? 0) / (dniLevel?.max ?? 0) >= 0.75 // eslint-disable-line no-unsafe-optional-chaining
            ? 'green'
            : ((dniLevel?.score ?? 0) / (dniLevel?.max ?? 0) >= 0.5 && // eslint-disable-line no-unsafe-optional-chaining
                'yellow') ||
              'red',
      },
    }

    if (isChangelog) {
      return steps.viewLevel
    }
    if (percent === 0) {
      return steps.start
    }
    if (completed && !scorable && !processing) {
      return steps.requestReports
    }
    if (completed && !scorable && processing) {
      return steps.processingReports
    }
    if (percent > 0 && !scorable) {
      return steps.continue
    }
    return steps.viewLevel
  }

  const step = getStep()

  return (
    <ProgressBox
      sx={{
        gridColumn: formatSpan(span),
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'row',
      }}
      progressValue={Math.round(dniLevel?.score ?? 0)}
      progressMax={dniLevel?.max ?? SCORE_MAX}
      progressColor={step?.progressColor}
    >
      <Flex
        sx={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          py: 6,
          pl: 8,
          mr: 4,
        }}
      >
        <Box>
          <Text as="div" variant="caps">
            {t('dashboard.diLevel.title')}
          </Text>
          {step?.content}
        </Box>
      </Flex>
      <Box sx={{ flex: '0 0 auto', py: 7, pr: 7 }}>
        <DonutChart
          value={Math.round(dniLevel?.score ?? 0)}
          max={dniLevel?.max ?? SCORE_MAX}
          valueLabel={`${round(
            (Math.round(dniLevel?.score ?? 0) * 10) /
              (dniLevel?.max ?? SCORE_MAX) ?? 0,
            1
          )}`}
          maxLabel={`${Math.round(dniLevel?.score ?? 0)} ${t(`of`, {
            value: Math.round(dniLevel?.max ?? SCORE_MAX),
          })}`}
          diff={round(+(dniLevel?.difference ?? 0), 1)} // eslint-disable-line no-unsafe-optional-chaining
          color={getLevelColor(dniLevel?.score ?? 0)}
        />
      </Box>
    </ProgressBox>
  )
}
