import { baseColors } from '@fairhq/common'
import { TaskStatus } from 'store/tasks/types'

export const getTaskStatusColors = (status: string) => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
      return {
        backgroundColor: baseColors.purple,
        color: baseColors.white,
      }
    case TaskStatus.NOT_STARTED:
      return {
        backgroundColor: baseColors.white,
        color: baseColors.purple700,
      }
    case TaskStatus.COMPLETED:
    case TaskStatus.SKIPPED:
    default:
      return {
        backgroundColor: baseColors.grey200,
        color: baseColors.grey400,
      }
  }
}
