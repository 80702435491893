import { FunctionComponent } from 'react'

import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'
import { tCode } from 'utils/tCode'

import { useReportsParams } from '../hooks/useReportsParams'

import { DemGroupType, reportsKeyValue } from '../types'

import { DemGroupScoreBreakdown } from './DemGroupScoreBreakdown'
import { DiversityMakeUp } from './DiversityMakeUp'
import { DiversityRepresentationGroup } from './DiversityRepresentationGroup'
import { GroupScoreBreakdown } from './GroupScoreBreakdown'

export const GroupsSubLevelReports: FunctionComponent = () => {
  const { code: rawCode, type } = useReportsParams()
  const { t } = useTranslation()
  const code = customDecodeURIComponent(rawCode)

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: reports,
    isLoading,
    isError,
    isSuccess,
  } = useGetReportsQuery({ type, apiVersion, sessionId })

  const status = reports?.[
    `companyBy${startCase(type).replace(' ', '')}`
  ]?.find(({ [reportsKeyValue[type]]: group }: any) => group === code)

  const diversityMakeupGraphData = reports?.[
    `companyByDemGroupAnd${startCase(type).replace(' ', '')}`
  ]
    ?.filter(
      ({ dem_group_type, [reportsKeyValue[type]]: group }: any) =>
        (dem_group_type === DemGroupType.MINORITY ||
          dem_group_type === DemGroupType.ALL_MAJORITY) &&
        (!code || group === code)
    )
    .map(({ dem_group_code, dem_group_title, total }: any) => ({
      key: dem_group_code + Math.random(),
      label: tCode(t)(dem_group_code, '', dem_group_title),
      value: total,
    }))

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <DiversityRepresentationGroup key="DiversityRepresentationType" />
      <DiversityMakeUp
        key="DiversityMakeUp"
        status={status}
        graphData={diversityMakeupGraphData}
      />
      <GroupScoreBreakdown
        key="GroupScoreBreakdown_inclusion"
        area="inclusion"
      />
      <DemGroupScoreBreakdown
        key="DemGroupScoreBreakdown_inclusion"
        area="inclusion"
      />
      <GroupScoreBreakdown key="DemGroupScoreBreakdown" area="equality" />
      <DemGroupScoreBreakdown key="DemGroupScoreBreak" area="equality" />
    </StateHandler>
  )
}
