import { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'

import { baseColors, Button, Text } from '@fairhq/common'

const Container = styled.div`
  border-radius: 20px;
  width: 100%;
`

const Header = styled.div`
  background-color: ${baseColors.purple700};
  padding: 40px 60px 32px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`

const Content = styled.div`
  background-color: ${baseColors.white};
  padding: 32px 48px 48px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & button:not(:last-child) {
    margin-right: 16px;
  }
`

interface BaseConfirmMdalProps {
  onClose: () => void
  onSubmit?: () => void
  title: ReactElement
  body: ReactElement
  confirmCustomText: ReactElement
  cancelCustomText?: ReactElement
  withSingleButton?: boolean
}
export const BaseConfirmModal: FunctionComponent<BaseConfirmMdalProps> = ({
  onClose,
  onSubmit,
  title,
  body,
  withSingleButton,
  confirmCustomText,
  cancelCustomText,
}) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
      <Container>
        <Header>
          <Text
            type="body"
            element="h2"
            size="md"
            fontWeight={600}
            color={baseColors.white}
          >
            {title}
          </Text>
        </Header>
        <Content>
          <Text
            type="body"
            element="p"
            size="sm"
            fontWeight={500}
            color={baseColors.purple900}
          >
            {body}
          </Text>

          <ButtonsContainer>
            {withSingleButton ? (
              <Button fullWidth onClick={onClose}>
                {confirmCustomText}
              </Button>
            ) : (
              <>
                <Button fullWidth onClick={onSubmit}>
                  {confirmCustomText ?? t('confirm.yes')}
                </Button>
                <Button variant="tertiary" fullWidth onClick={onClose}>
                  {cancelCustomText ?? t('confirm.cancel')}
                </Button>
              </>
            )}
          </ButtonsContainer>
        </Content>
      </Container>
    </Modal>
  )
}
