import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { Response } from 'node-fetch'

import { config, waitForResponse } from '@fairhq/common'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { getSubscription } from '../customer/customerSlice'

import { paymentApi } from './paymentApi'
import { PaymentState, PaymentInfo, RetryInvoiceInfo } from './types'

const handlePayment = async (callback: () => Promise<Response>) => {
  const subscription = await waitForResponse({ callback })
  if (subscription.error) {
    throw subscription.error
  }
  return subscription
}

export const clear = createAction('payment/clear')

export const getTax = createAsyncThunk(
  'payment/getTax',
  async (_, { getState }) =>
    waitForResponse({ callback: () => paymentApi.getTax(getState) })
)
export const getCoupon = createAsyncThunk(
  'payment/getCoupon',
  async (coupon: string, { getState }) =>
    waitForResponse({
      callback: () => paymentApi.getCoupon(getState, coupon),
    })
)

export const processPayment = createAsyncThunk(
  'payment/processPayment',
  async (info: PaymentInfo, { getState }) =>
    handlePayment(() => paymentApi.processPayment(getState, info))
)
export const retryInvoice = createAsyncThunk(
  'payment/retryInvoice',
  async (info: RetryInvoiceInfo, { getState }) =>
    handlePayment(() => paymentApi.retryInvoice(getState, info))
)
export const getInvoices = createAsyncThunk(
  'payment/getInvoices',
  async (_, { getState }) =>
    waitForResponse({ callback: () => paymentApi.getInvoices(getState) })
)

const initialState: Partial<PaymentState> = { loading: false }

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getTax.fulfilled, (state, action) => {
        state.loadingTax = false
        state.tax = action.payload
      })
      .addCase(getTax.pending, state => {
        state.loadingTax = true
      })
      .addCase(getCoupon.pending, state => {
        state.loading = true
        state.coupon = undefined
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        state.loading = false
        state.coupon = action.payload
      })
      .addCase(processPayment.fulfilled, (state, action) => {
        const subscription = action.payload
        const { payment_intent, id } = subscription?.latest_invoice ?? {}
        const { status } = payment_intent ?? {}
        const isIncomplete =
          status === 'requires_payment_method' || status === 'requires_action'

        state.loading = false
        state.subscription = action.payload
        state.paymentIntent = payment_intent
        state.isIncomplete = isIncomplete

        if (isIncomplete) {
          localStorage.setItem(config.latestInvoiceId, id || '')
          localStorage.setItem(
            config.latestInvoicePaymentIntentStatus,
            status || ''
          )
        }
      })
      .addCase(retryInvoice.fulfilled, (state, action) => {
        const { payment_intent, id } = action.payload
        const { status } = payment_intent

        const isIncomplete =
          status === 'requires_payment_method' || status === 'requires_action'

        state.loading = false
        state.invoice = action.payload
        state.paymentIntent = payment_intent
        state.isIncomplete = isIncomplete

        if (isIncomplete) {
          localStorage.setItem(config.latestInvoiceId, id)
          localStorage.setItem(config.latestInvoicePaymentIntentStatus, status)
        }
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload
      })
      .addCase(getInvoices.pending, state => {
        state.loading = true
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false
        state.invoices = action.payload
      })
      .addMatcher(isPending('payment'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('payment'), handleErrorState)
  },
})

export const { reducer: paymentReducer } = paymentSlice
