import React, { FunctionComponent } from 'react'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { Round, Text, baseColors } from '@fairhq/common'

const Responses = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Tag = styled.div<{ backgroundColor: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 16px 4px 12px;
  border-radius: 9999px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: max-content;
`

const StyledRound = styled(Round)`
  flex-shrink: 0;
`

interface EngagementProps {
  invited: number
  completed: number
}

export const Engagement: FunctionComponent<EngagementProps> = ({
  invited,
  completed,
}) => (
  <Responses>
    <Text element="p" type="body" size="md" fontWeight={700}>
      <Trans
        i18nKey="diversity.overview.responses"
        values={{ value: completed }}
      />
    </Text>
    <Tag backgroundColor={baseColors.blue100}>
      <StyledRound
        width="14px"
        height="14px"
        outline={baseColors.blue400}
        stroke={baseColors.blue200}
      />
      <Text
        color={baseColors.blue600}
        type="body"
        element="p"
        size="xs"
        fontWeight={500}
      >
        <Trans
          i18nKey="diversity.overview.engagement"
          values={{ value: Math.round((completed / invited) * 100) }}
        />
      </Text>
    </Tag>
  </Responses>
)
