import React from 'react'

export const ApprovalStamp = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_19669)">
      <path
        d="M11.735 5.72234L12.5428 5.18372C12.9702 4.89884 13.4887 4.76099 13.9133 4.47212C14.0014 4.41222 14.0851 4.34323 14.1632 4.26514C14.8336 3.59449 14.8336 2.50714 14.1632 1.83649C13.4926 1.16583 12.4055 1.16583 11.735 1.83649C11.6569 1.91457 11.5879 1.9983 11.528 2.08637C11.2392 2.51106 11.1014 3.02972 10.8166 3.4571L10.278 4.26514M11.735 5.72234L10.278 4.26514M11.735 5.72234L12.8668 7.50136C13.1986 8.02283 13.1238 8.70483 12.6868 9.1419C12.4293 9.39943 12.0119 9.39943 11.7544 9.1419L6.85909 4.24568C6.60166 3.98817 6.60166 3.57066 6.85909 3.31314C7.29609 2.87606 7.97802 2.8012 8.49936 3.13305L10.278 4.26514"
        stroke="#3F379E"
      />
      <path
        d="M10.6673 11.263C10.0815 13.2314 8.25805 14.6668 6.09932 14.6668C3.4675 14.6668 1.33398 12.5333 1.33398 9.9015C1.33398 7.74276 2.76938 5.91932 4.7378 5.3335"
        stroke="#3F379E"
        strokeLinecap="round"
      />
      <path
        d="M4.66602 10.4443C4.66602 10.4443 5.08268 10.4443 5.49935 11.3332C5.49935 11.3332 6.82288 9.11097 7.99935 8.6665"
        stroke="#3F379E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_19669">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
