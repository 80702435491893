export const SCORE_MAX = 750
const SCORE_PERCENT_BEGINNER_MAX = 0.499
const SCORE_PERCENT_INTERMEDIATE_MAX = 0.749

enum Level {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
}

export const getLevel = (score: number): Level => {
  const scorePercent = score / SCORE_MAX
  if (scorePercent <= SCORE_PERCENT_BEGINNER_MAX) {
    return Level.BEGINNER
  }
  if (scorePercent <= SCORE_PERCENT_INTERMEDIATE_MAX) {
    return Level.INTERMEDIATE
  }
  return Level.ADVANCED
}

export const getLevelColor = (score: number) => {
  const scorePercent = score / SCORE_MAX
  if (scorePercent <= SCORE_PERCENT_BEGINNER_MAX) {
    return 'red'
  }
  if (scorePercent <= SCORE_PERCENT_INTERMEDIATE_MAX) {
    return 'yellow'
  }
  return 'green'
}
