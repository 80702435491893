import { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Arrow, Text } from '@fairhq/common'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const StyledArrow = styled(Arrow)`
  margin-left: 4px;
  margin-right: 4px;
`

interface ScoreImpactProps {
  current: number | null
  projected: number | null
}

export const ScoreImpact: FunctionComponent<ScoreImpactProps> = ({
  current,
  projected,
}) => (
  <Container>
    <Text
      type="body"
      element="span"
      size="xs"
      fontWeight={600}
      // Hardcoded on purpose until Dan can consolidate colours for accessibility
      color="#E84630"
    >
      {current}
    </Text>
    <StyledArrow />
    <Text
      type="body"
      element="span"
      size="xs"
      fontWeight={600}
      // Hardcoded on purpose until Dan can consolidate colours for accessibility
      color="#12A154"
    >
      {projected}
    </Text>
  </Container>
)
