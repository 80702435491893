import React from 'react'

import { keyframes } from '@emotion/react'
import { Box, Button as ButtonComponent } from '@theme-ui/components'

import { space } from '@fairhq/common'

import Icon, { IconName } from './Icon'

const rotate = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
})

export function Loading() {
  const circumference = 8 * 2 * Math.PI
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block' }}
    >
      <circle
        cx="12"
        cy="12"
        r="8"
        strokeWidth="3"
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={circumference * 0.5}
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  )
}

export type ButtonProps = {
  fullWidth?: boolean
  size?: 'large' | 'small'
  icon?: IconName
  iconPosition?: 'left' | 'right'
  iconSmall?: boolean
  loading?: boolean
  square?: boolean
} & any

function Button({
  fullWidth,
  size,
  as = 'button',
  variant = 'primary',
  sx,
  icon,
  iconPosition = 'right',
  iconSmall,
  children,
  loading,
  square,
  disabled,
  onClick,
  ...rest
}: ButtonProps) {
  const fullWidthStyles = !!fullWidth && {
    width: '100%',
  }

  const largeStyles = size === 'large' && {
    height: space[11],
  }

  const smallStyles = size === 'small' && {
    height: space[7],
    fontSize: 13,
    lineHeight: '18px',
    px: 4,
  }

  const iconStyles = !!icon && {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignText: 'center',
  }

  const squareStyle = !!square && {
    borderRadius: 8,
  }

  const buttonStyles = {
    ...fullWidthStyles,
    ...largeStyles,
    ...smallStyles,
    ...iconStyles,
    ...squareStyle,
    ...sx,
  }

  return (
    <ButtonComponent
      as={as}
      variant={`buttons.${variant}`}
      sx={buttonStyles}
      onClick={loading ? undefined : onClick}
      className={`${(loading && 'loading') || ''}`}
      disabled={disabled || loading}
      {...rest}
    >
      {!!icon && (
        <Icon
          name={icon}
          small={!!iconSmall}
          sx={{
            order: iconPosition === 'right' ? 2 : -1,
            ml: iconPosition === 'right' ? 2 : -2,
            mr: iconPosition === 'left' ? 2 : -2,
            opacity: 0.5,
            fill: 'currentColor',
          }}
        />
      )}
      <Box sx={{ position: 'relative', flex: 1 }}>
        <Box sx={{ opacity: loading ? 0 : 1 }}>{children}</Box>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -2,
              marginLeft: -2,
              animation: `${rotate} .75s linear infinite forwards`,
            }}
          >
            <Loading />
          </Box>
        )}
      </Box>
    </ButtonComponent>
  )
}

export default Button
