import React from 'react'

import {
  Box,
  Input as InputComponent,
  InputProps as TInputProps,
} from '@theme-ui/components'

import { space } from '@fairhq/common'

import Icon, { IconName } from './Icon'

type InputProps = {
  error?: string
  icon?: IconName
  boxSx?: any
} & Omit<TInputProps, 'ref'>

function Input(
  {
    name,
    id,
    value,
    placeholder,
    icon,
    sx,
    error,
    boxSx = {},
    ...rest
  }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const errorStyles = !!error && {
    borderColor: error ? 'red !important' : undefined,
  }
  const iconStyles = !!icon && {
    pl: `${space[7] - 1}px`,
  }
  const compSx = {
    ...errorStyles,
    ...iconStyles,
  }

  return (
    <Box sx={{ position: 'relative', ...boxSx }}>
      <InputComponent
        ref={ref}
        name={name}
        id={id || name}
        value={value}
        placeholder={placeholder}
        sx={{ ...compSx, ...sx }}
        {...rest}
      />
      {!!icon && (
        <Icon
          name={icon}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 2,
            mt: -2,
            pointerEvents: 'none',
          }}
        />
      )}
    </Box>
  )
}

export default React.forwardRef<HTMLInputElement, InputProps>(Input)
