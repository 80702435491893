import { FunctionComponent, useEffect, useState } from 'react'

import { Box } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import styled from 'styled-components'

import { Confirm } from 'components/confirm'
import { Fields } from 'components/form/Fields'
import { Form } from 'components/form/Form'
import { FieldsContainer } from 'components/form/styled'
import { DeactivateIllustration } from 'components/svgs/illustrations/DeactivateIllustration'
import { Employee } from 'features/employees/types'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { Message } from 'styled/message'
import WhiteBox from 'ui-kit/WhiteBox'

import { ReactComponent as ActivateIcon } from '../assets/activate.svg'
import { useUpdateEmployeeMutation } from '../emloyeesApi'
import { useEmployeeEditForm } from '../hooks/useEmployeeEditForm'

export const EmployeeFormContainer = styled(Box)`
  overflow: inherit;

  .rs-form {
    .rs-btn-toolbar {
      position: sticky;
      bottom: 0;
      z-index: 300;
    }
  }

  ${FieldsContainer} {
    padding: 0;
  }

  .rs-picker-select .rs-picker-toggle-value {
    font-size: 14px;
  }
`

interface EmployeeEditModalProps {
  employee: Partial<Employee>
  onClose: () => void
  visible?: boolean
}

export const EmployeeEditModal: FunctionComponent<EmployeeEditModalProps> = ({
  employee,
  onClose,
  visible,
}) => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { t } = useTranslation()
  const [updateEmployee, { isSuccess: isActivationSucess }] =
    useUpdateEmployeeMutation()

  const [activationVisible, setActivationVisible] = useState(false)
  const {
    model,
    form,
    fields,
    setForm,
    onSubmit,
    submitting,
    setSubmitting,
    isSuccess,
    isLoading,
  } = useEmployeeEditForm(employee)

  useEffect(() => {
    if (submitting && !isLoading) {
      setSubmitting(false)
    }
    if (isSuccess || isActivationSucess) {
      onClose()
      setActivationVisible(false)
    }
  }, [
    onClose,
    setSubmitting,
    submitting,
    isLoading,
    isSuccess,
    isActivationSucess,
  ])

  const { activated, firstName, lastName } = employee
  const activation = {
    deactivate: {
      icon: <DeactivateIllustration />,
      title: <h3>{t('employees.employee.doYouWantToDeactivate.title')}</h3>,
      message: t('employees.employee.doYouWantToDeactivate.description', {
        name: `${firstName} ${lastName}`.trim(),
      }),
      cancelLabel: t('employees.employee.deactivate.cancel'),
      confirmLabel: t('employees.employee.deactivate.confirm'),
      danger: true,
    },
    activate: {
      icon: <ActivateIcon />,
      title: <h3>{t('employees.employee.doYouWantToActivate.title')}</h3>,
      message: t('employees.employee.doYouWantToActivate.description', {
        name: `${firstName} ${lastName}`.trim(),
      }),
      cancelLabel: t('employees.employee.activate.cancel'),
      confirmLabel: t('employees.employee.activate.confirm'),
      danger: false,
    },
  }

  const { title, message, cancelLabel, confirmLabel, icon, danger } =
    activation[activated ? 'deactivate' : 'activate']

  return (
    <Modal
      isOpen={!!visible}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <WhiteBox
        sx={{
          padding: 0,
          maxWidth: '740px',
          width: '100%',
          position: 'relative',
          h3: { fontSize: '18px', textAlign: 'center' },
        }}
      >
        <Confirm
          title={title}
          danger={danger}
          cancelLabel={cancelLabel}
          confirmLabel={confirmLabel}
          onCancel={() => setActivationVisible(false)}
          onSubmit={() => {
            updateEmployee({
              employee: {
                ...employee,
                activated: !activated,
              },
              sessionId,
            })
          }}
          visible={!!activationVisible}
        >
          {icon}
          <Message>{message}</Message>
        </Confirm>
        <Box p={8}>
          <h3>{t('employees.employee.editInfo')}</h3>
        </Box>
        <EmployeeFormContainer
          sx={{
            '.rs-btn-toolbar': { padding: '24px 60px' },
            '.fhq-row': {
              padding: '24px 60px',
              margin: 0,
              '&:not(:last-of-type)': {
                borderBottom: theme => `1px solid ${theme.colors?.grey200}`,
              },
              h4: {
                fontSize: '16px',
                color: 'grey300',
                fontFamily: 'Poppins, sans-serif',
                paddingBottom: '12px',
              },
            },
          }}
        >
          <Form
            loading={submitting}
            disabled={submitting}
            model={model}
            formValue={form}
            onChange={(formValue: typeof form) => setForm(formValue)}
            onSubmit={onSubmit}
            onAction={() => setActivationVisible(true)}
            actionBtnLabel={
              activated
                ? t('employees.employee.deactivate')
                : t('employees.employee.activate')
            }
            submitBtnLabel={t('employees.employee.save')}
          >
            {visible ? <Fields data={fields} advanced /> : undefined}
          </Form>
        </EmployeeFormContainer>
      </WhiteBox>
    </Modal>
  )
}
