import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { NotionRenderer } from 'react-notion-x'

import { NotionPage } from 'store/notion/types'

import { NotionEmbedLinks } from './NotionEmebedLinks'

interface NotionEmbedProps {
  page: NotionPage
}

export const NotionEmbed: FunctionComponent<NotionEmbedProps> = ({ page }) => (
  <Box
    sx={{ bg: 'white', py: 11, px: 6, mb: 9, borderRadius: 20 }}
    css={`
      img {
        max-width: 100%;
      }
    `}
  >
    <div>
      <NotionRenderer
        recordMap={page}
        mapPageUrl={(pageUrl: string) =>
          `https://notion.so/${pageUrl?.replace(/-/g, '')}`
        }
        components={{
          Link: NotionEmbedLinks,
          PageLink: NotionEmbedLinks,
        }}
      />
    </div>
  </Box>
)
