import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { getProgressColor } from 'utils/getProgressColor'

// import progressBarLine from './progressBarLine.svg'

const StyledText = styled(Text)`
  line-height: 1.5rem;
  margin: 0 2px;
`

interface ProgressBarProps {
  value: number
  max?: number
}

export const SquaredProgressBar: FunctionComponent<ProgressBarProps> = ({
  value,
  max = 100,
}) => {
  const percentage = (value / max) * 100
  return (
    <Box
      sx={{
        position: 'relative',
        height: 24,
        bg: 'grey100',
        width: '100%',
        maxWidth: '240px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          bg: getProgressColor(percentage),
          width: `${Math.round(percentage)}%`,
          textAlign: 'right',
        }}
      >
        {percentage >= 20 && (
          <StyledText
            element="p"
            type="body"
            size="xs"
            fontWeight={600}
            color={baseColors.white}
          >
            {`${Math.round(percentage)}%`}
          </StyledText>
        )}
      </Box>
    </Box>
  )
}
