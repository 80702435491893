import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'

import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import { useGetBiggestGainsQuery } from 'store/surveyWindow/surveyWindowApi'

import { GapAndScore } from './GapAndScore'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const CategoryBlock = styled(WhiteBoxWithShadow)`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;

  & p {
    margin: 0;
  }
`

const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

interface BiggestGainsProps {
  latestSurveyWindowId: number
}

export const BiggestGains: FunctionComponent<BiggestGainsProps> = ({
  latestSurveyWindowId,
}) => {
  const { t } = useTranslation()

  const { data, isSuccess } = useGetBiggestGainsQuery({
    surveyWindowId: latestSurveyWindowId,
  })

  return (
    <Wrapper>
      <Container>
        <Text element="h2" type="body" size="xl" fontWeight={700}>
          {t('experience.gains.title')}
        </Text>
        <Text element="p" type="body" size="md" fontWeight={400}>
          {t('experience.gains.description')}
        </Text>
      </Container>
      <Container>
        {isSuccess &&
          data.length > 0 &&
          data.map(item => (
            <CategoryBlock key={item.category}>
              <Category>
                <Text
                  element="p"
                  type="body"
                  size="lg"
                  fontWeight={700}
                  color={baseColors.grey500}
                >
                  {t(`category.${item.subCategory}`)}
                </Text>
                <Text
                  element="p"
                  type="body"
                  size="xs"
                  fontWeight={500}
                  color={baseColors.purple600}
                >
                  {t(`category.${item.category}`)}
                </Text>
              </Category>

              <GapAndScore
                score={item.score}
                gap={item.gap}
                previousScore={item.previousScore}
                previousGap={item.previousGap}
                showDiffColumn={!!item}
              />
            </CategoryBlock>
          ))}
      </Container>
    </Wrapper>
  )
}
