import React, { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text } from '@fairhq/common'
import { BellCurve } from 'components/BellCurve'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'

import {
  useGetDemGroupsQuery,
  useGetJobLevelsQuery,
} from 'store/surveyWindow/surveyWindowApi'
import { SurveyWindow } from 'store/surveyWindow/types'

import { formatOrdinals } from 'utils/formatOrdinals'

import { Engagement } from './Engagement'
import { TradVsMinorities } from './TradVsMinorities'

const Container = styled(WhiteBoxWithShadow)`
  flex-direction: column;
  padding: 40px;
  gap: 12px;
`

const CapsText = styled(Text)`
  text-transform: uppercase;
`

const Top = styled.div`
  display: flex;
  gap: 40px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const PeerComparisonCurve = styled.div`
  min-width: 122px;
`

interface DiversityOverviewProps {
  latestSurveyWindow: SurveyWindow
  level?: string
}

export const DiversityOverview: FunctionComponent<DiversityOverviewProps> = ({
  latestSurveyWindow,
  level,
}) => {
  const { t } = useTranslation()

  const {
    data: demGroups,
    isSuccess,
    isFetching,
  } = useGetDemGroupsQuery({
    surveyWindowId: latestSurveyWindow.id,
    code: 'dem_minorities',
    level,
  })

  const { data: jobLevelsCount } = useGetJobLevelsQuery(
    {
      surveyWindowId: latestSurveyWindow?.id ?? 0,
    },
    { skip: !level }
  )

  const minorities = demGroups?.[0]
  const completedAndInvited = level
    ? jobLevelsCount?.find(jobLevelCount => jobLevelCount.level === level)
    : {
        completed: latestSurveyWindow.totalCompleted,
        invited: latestSurveyWindow.totalInvited,
      }

  return (
    <Container>
      <CapsText element="h2" type="body" size="sm" fontWeight={700}>
        {t('diversity.overview.title')}
      </CapsText>
      {!isFetching && (
        <Body>
          <Top>
            {completedAndInvited && (
              <Engagement
                completed={completedAndInvited?.completed}
                invited={completedAndInvited?.invited}
              />
            )}

            {isSuccess && minorities?.percentile && (
              <>
                <PeerComparisonCurve>
                  <BellCurve value={+minorities.percentile} />
                </PeerComparisonCurve>
                <Text element="p" type="body" size="md" fontWeight={400}>
                  <Trans
                    i18nKey="diversity.overview.peerComparison"
                    values={{
                      value: formatOrdinals(+minorities.percentile * 100),
                    }}
                  />
                </Text>
              </>
            )}
          </Top>

          <TradVsMinorities
            surveyWindowId={latestSurveyWindow.id}
            completed={completedAndInvited?.completed ?? 0}
            level={level}
          />
        </Body>
      )}
    </Container>
  )
}
