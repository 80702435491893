import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { DemGroupType } from '../types'

import { useReportsParams } from './useReportsParams'

export const useReports = () => {
  const { code, type } = useReportsParams()

  const { benchmark, companyBenchmark, surveysInfo, apiVersion, sessionId } =
    useAppSelector(
      (state: State) => ({
        benchmark:
          state.reportsReducer.benchmarks?.[code]?.find(
            ({ type: benchmarkType, benchmarkOption }: any) =>
              benchmarkType === 'industry' &&
              !benchmarkOption?.endsWith('_management') &&
              !benchmarkOption?.endsWith('_leadership')
          ) ||
          state.reportsReducer.benchmarks?.[code]?.find(
            ({ type: benchmarkType, benchmarkOption }: any) =>
              benchmarkType === 'city' &&
              !benchmarkOption?.endsWith('_management') &&
              !benchmarkOption?.endsWith('_leadership')
          ) ||
          state.reportsReducer.benchmarks?.[code]?.find(
            ({ type: benchmarkType, benchmarkOption }: any) =>
              benchmarkType === 'area' &&
              !benchmarkOption?.endsWith('_management') &&
              !benchmarkOption?.endsWith('_leadership')
          ),
        companyBenchmark: state.companyReducer.company?.companyBenchmark,
        surveysInfo: state.surveyReducer.status,
        apiVersion: state.apiHeadersReducer.apiVersion,
        sessionId: state.apiHeadersReducer.sessionId,
      }),
      shallowEqual
    )

  const filterByDemGroup = ({ dem_group_group }: any) =>
    dem_group_group === code || !code
  const filterForExistingCountry = ({ country }: any) => country !== null
  const filterForExistingDepartment = ({ department }: any) =>
    department !== null

  const {
    data: demGroupsData,
    isLoading: isDemGroupsLoading,
    isError: isDemGroupsError,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })
  const demGroups =
    demGroupsData?.companyByDemGroup?.filter(filterByDemGroup) ?? []
  const minority = demGroupsData?.companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code && dem_group_type === DemGroupType.MINORITY
  )
  const majority = demGroupsData?.companyByDemGroup?.find(
    ({ dem_group_group, dem_group_type }: any) =>
      dem_group_group === code &&
      (dem_group_type === DemGroupType.MAJORITY ||
        dem_group_type === DemGroupType.ALL_MAJORITY)
  )

  const {
    data: locationData,
    isLoading: isLocationLoading,
    isError: isLocationError,
  } = useGetReportsQuery({ type: 'location', apiVersion, sessionId })
  const location =
    type === 'dem_groups'
      ? locationData?.companyByDemGroupAndLocation
          ?.filter(filterForExistingCountry)
          .filter(filterByDemGroup)
      : locationData?.companyByLocation?.filter(filterForExistingCountry)

  const {
    data: departmentData,
    isLoading: isDepartmentLoading,
    isError: isDepartmentError,
  } = useGetReportsQuery({
    type: 'department',
    apiVersion,
    sessionId,
  })
  const department =
    type === 'dem_groups'
      ? departmentData?.companyByDemGroupAndDepartment
          ?.filter(filterForExistingDepartment)
          .filter(filterByDemGroup)
      : departmentData?.companyByDepartment.filter(filterForExistingDepartment)

  const {
    data: jobLevelData,
    isLoading: isJobLevelLoading,
    isError: isJobLevelError,
  } = useGetReportsQuery({
    type: 'job_level',
    apiVersion,
    sessionId,
  })
  const jobLevel =
    type === 'dem_groups'
      ? jobLevelData?.companyByDemGroupAndJobLevel?.filter(filterByDemGroup)
      : jobLevelData?.companyByJobLevel

  const total = {
    location: locationData?.companyByLocation?.filter(filterForExistingCountry),
    jobLevel: jobLevelData?.companyByJobLevel,
    department: departmentData?.companyByDepartment.filter(
      filterForExistingDepartment
    ),
  }

  const isLoading =
    isDemGroupsLoading ||
    isLocationLoading ||
    isDepartmentLoading ||
    isJobLevelLoading
  const isError =
    isDemGroupsError || isLocationError || isDepartmentError || isJobLevelError
  let queryStatus = QueryStatus.SUCCESS
  if (isError) {
    queryStatus = QueryStatus.ERROR
  }
  if (isLoading) {
    queryStatus = QueryStatus.LOADING
  }

  return {
    benchmark,
    companyBenchmark,
    jobLevel,
    minority,
    majority,
    location,
    department,
    demGroups,
    surveysInfo,
    total,
    queryStatus,
  }
}
