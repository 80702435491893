import { emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'

import { Event } from './types'

const eventsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getEvents: build.query<Event[], { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `/events`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const { useGetEventsQuery } = eventsApi
