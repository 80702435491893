import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { NumberInput } from '@fairhq/common'

import { Question } from 'store/questionTypes'
import { tCode } from 'utils/tCode'

import { Dropdown } from './Dropdown'
import { MultipleChoice } from './MultipleChoice'
import { Scale } from './Scale'
import { Semantic } from './Semantic'
import { SingleChoice } from './SingleChoice'

interface QuestionTypeCardProps {
  question: Question
  onNumberChange: (newValue: string) => void
  numberValue: string | undefined
  selectedOption: number | undefined
  setSelectedOption: (newValue: any) => void
  selectedOptions: number[]
  setSelectedOptions: (newValue: any) => void
  other: string | undefined
  setOther: (value: string) => void
}

export const QuestionTypeCard: FunctionComponent<QuestionTypeCardProps> = ({
  question,
  onNumberChange,
  numberValue,
  selectedOption,
  setSelectedOption,
  selectedOptions,
  setSelectedOptions,
  other,
  setOther,
}) => {
  const { t } = useTranslation()
  const tOption = tCode(t)
  const { type, code, title } = question

  if (type === 'dropdown') {
    return (
      <Dropdown
        question={question}
        onChange={setSelectedOption}
        selectedOption={selectedOption}
      />
    )
  }
  if (type === 'semantic') {
    return (
      <Semantic
        question={question}
        onChange={setSelectedOption}
        selectedOption={selectedOption}
      />
    )
  }
  if (type === 'textNumber') {
    return (
      <NumberInput
        value={numberValue}
        onChange={onNumberChange}
        placeholder={tOption(code, 'placeholder', title)}
      />
    )
  }

  if (type?.startsWith('scale')) {
    return (
      <Scale
        is10PointScale={type === 'scale10'}
        question={question}
        onChange={setSelectedOption}
        selectedOption={selectedOption}
      />
    )
  }

  if (type === 'single' || type === 'binary') {
    return (
      <SingleChoice
        question={question}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        other={other}
        setOther={setOther}
      />
    )
  }

  return (
    <MultipleChoice
      question={question}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      other={other}
      setOther={setOther}
    />
  )
}
