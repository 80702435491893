import { useEffect } from 'react'

import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getArea } from 'store/area/areaSlice'
import { AreaSource, AreasQueryParams } from 'store/area/types'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

export const useArea = (group?: AreaSource) => {
  const dispatch = useAppDispatch()
  const { code } = useParams<{ code: string }>()
  const { loading, areaCode } = useAppSelector(
    (state: State) => ({
      loading: state.areaReducer.loading,
      areaCode: state.areaReducer.area?.code,
    }),
    shallowEqual
  )
  const shouldLoad = (code !== areaCode || !areaCode) && code

  useEffect(() => {
    if (!loading && shouldLoad) {
      const query: AreasQueryParams = { code }
      if (group) {
        query.source = group
      }
      dispatch(getArea(query))
    }
  }, [code, dispatch, group, loading, shouldLoad])
}
