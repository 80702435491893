import { isArray } from 'lodash'

import { QuestionOption } from 'store/questionTypes'

export const buildOptions = (
  options: QuestionOption[],
  optionOrOptionsSelected: number | number[] | undefined
) =>
  options.filter(({ id }) =>
    isArray(optionOrOptionsSelected)
      ? optionOrOptionsSelected.includes(id)
      : optionOrOptionsSelected === id
  )
