import React from 'react'

import { alpha } from '@theme-ui/color'
import { Box, BoxProps } from '@theme-ui/components'
import { Link } from 'react-router-dom'

import { makeTransition } from '@fairhq/common'

type ProgressBoxProps = {
  progressValue?: number
  progressMax?: number
  progressColor?: string
  to?: string
  animated?: boolean
} & BoxProps

function ProgressBox({
  children,
  progressValue = 0,
  progressMax = 100,
  progressColor = 'yellow',
  sx,
  to,
  animated,
  ...rest
}: ProgressBoxProps) {
  const inner = (
    <Box
      sx={{
        bg: 'white',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        transition: `${makeTransition('box-shadow')}`,
        ...(to && {
          '&:hover': {
            boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
            '.fhqProgressBoxBackground': {
              background: th => alpha(th.rawColors?.[progressColor], 0.35),
            },
          },
        }),
        ...(animated && {
          transition: `${makeTransition('box-shadow')}, ${makeTransition(
            'transform',
            0.2
          )}`,
          svg: {
            transition: makeTransition('transform', 0.1),
          },
          '&:hover': {
            boxShadow: '0px 12px 16px rgba(10, 27, 64, 0.1)',
            '.fhqProgressBoxBackground': {
              background: th => alpha(th.rawColors?.[progressColor], 0.35),
            },
            transform: 'translateY(-3px)',
            svg: {
              transform: 'translateX(5px)',
            },
          },
        }),
        ...sx,
      }}
      {...rest}
    >
      <>
        <Box
          className="fhqProgressBoxBackground"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${(progressValue / progressMax) * 100}%`,
            height: '100%',
            background: th =>
              progressColor === 'grey100'
                ? th.rawColors?.grey100
                : alpha(th.rawColors?.[progressColor], 0.2), // hacky hacky, need light colors for this in DS
            zIndex: -1,
            transition: makeTransition('background'),
          }}
        />
        {children}
      </>
    </Box>
  )

  return to ? <Link to={to}>{inner}</Link> : inner
}

export default ProgressBox
