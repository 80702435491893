import { emptySplitApi } from 'emptySplitApi'

import { CategoryExperience } from './types'

const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getCategories: build.query<string[], void>({
      query: () => ({
        url: `categories`,
      }),
    }),
    getCategoriesExperience: build.query<
      CategoryExperience[],
      { category?: string }
    >({
      query: ({ category }) => ({
        url: `categories/${category}/experience`,
      }),
    }),
  }),
})

export const { useGetCategoriesQuery, useGetCategoriesExperienceQuery } =
  categoryApi
