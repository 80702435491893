import { Flex } from '@theme-ui/components'

import { orderBy, sortBy } from 'lodash'

import { shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router'

import { Text } from '@fairhq/common'

import { StateHandler } from 'components/StateHandler'
import { emptySplitApi } from 'emptySplitApi'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { PageTop } from 'layout/PageTop'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { Account } from 'store/account/types'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { clearAccount } from 'store/clear'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { companyActions } from 'store/company/companySlice'
import { Company } from 'store/company/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { clear as clearReports } from 'store/reports/reportsSlice'
import { State } from 'store/state'
import { Table } from 'ui-kit/Table'

import { Layout } from '../../layout/Layout'

import { AdminListItem } from './AdminListItem'

import { useAccounts } from './hooks/useAccounts'

export type AccountAndCompany = Account & { company: Partial<Company> }

export const AdminList = () => {
  const { apiVersion, jwtToken } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      jwtToken: state.authReducer.jwt,
    }),
    shallowEqual
  )
  const { isFHQAdmin } = useAuthRoles()
  const { accounts, isSuccess, isError, isLoading } = useAccounts(jwtToken)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { refetch: refetchAccount } = useGetAccountQuery({ apiVersion })
  const { refetch: refetchEntitlements } = useGetEntitlementsQuery()

  const onClick = (account: AccountAndCompany) => {
    dispatch(apiHeadersActions.setAccountId(account.id))
    dispatch(apiHeadersActions.setCompanyId(account.company.id))

    const accountSessions =
      orderBy(account.company.sessions, ['startDate'], ['desc']) ?? []
    dispatch(apiHeadersActions.setSessionId(accountSessions[0].id))
    dispatch(companyActions.setSessionCurrentlyViewing(accountSessions[0].id))

    dispatch(clearReports())
    dispatch(clearAccount())
    dispatch(emptySplitApi.util.resetApiState())
    refetchAccount()
    refetchEntitlements()
    navigate('/home')
  }

  const sortedAccounts: AccountAndCompany[] = sortBy(
    accounts?.map(account => {
      const company = account.companies[0]
      return {
        ...account,
        company,
      }
    }),
    [account => account.company.name?.toLowerCase()]
  )
  return (
    <Layout dark>
      <PageTop>
        <Text element="h1" type="heading" size="xl" fontWeight={500}>
          Select account
        </Text>
      </PageTop>
      <StateHandler
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
      >
        <Flex
          sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 6 }}
        >
          <Table
            sx={{
              bg: 'white',
              th: { bg: 'white', fontWeight: 700, color: 'grey400' },
            }}
            headers={['Company', 'Company ID', 'Account ID']}
            items={sortedAccounts}
            renderItem={account => (
              <AdminListItem
                key={account.company.id}
                account={account}
                onClick={onClick}
                showMetabaseLink={isFHQAdmin}
              />
            )}
          />
        </Flex>
      </StateHandler>
    </Layout>
  )
}
