import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { ApiVersion } from 'store/apiHeaders/types'
import { companyActions } from 'store/company/companySlice'

import { Account } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to accountApi

const accountApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getAccount: build.query<Account, { apiVersion: ApiVersion }>({
      query: ({ apiVersion }) => ({
        url: `/account`,
        headers: {
          'fairhq-version': apiVersion || '',
        },
      }),
      providesTags: [TagTypes.ACCOUNT],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: account } = await queryFulfilled
        if (account?.companies?.length > 0) {
          const company = account?.companies?.[0]
          dispatch(companyActions.setCompany(company))
          dispatch(apiHeadersActions.setCompanyId(company?.id))
        }

        const accountId = account?.id
        if (accountId) {
          dispatch(apiHeadersActions.setAccountId(accountId))
        }
      },
    }),
    getAccounts: build.query<Account[], void>({
      query: () => ({
        url: '/account/accounts',
      }),
      providesTags: [TagTypes.ACCOUNTS],
    }),
    getDVCAccounts: build.query<Account[], void>({
      query: () => ({
        url: '/account/dvc-accounts',
      }),
      providesTags: [TagTypes.ACCOUNTS],
    }),
    getAuthRoles: build.query<
      { id: string; name: string; description?: string }[],
      void
    >({
      query: () => ({
        url: '/account/roles',
      }),
    }),
  }),
})

export const {
  useGetAccountQuery,
  useGetAccountsQuery,
  useGetAuthRolesQuery,
  useGetDVCAccountsQuery,
} = accountApi
