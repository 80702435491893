import { Container } from 'rsuite'
import styled from 'styled-components'

export const StatusContainer = styled(Container)`
  align-items: center;
  flex-flow: wrap;
  justify-content: space-between;
  min-height: 126px;
  padding: 0 100px 0 80px;

  h2 {
    font-size: 24px;
  }

  .rs-row {
    text-align: right;
  }
`
export const TitleWithProgressContainer = styled(Container)<{
  status: 'completed' | 'reviewing' | 'pending'
}>`
  ${StatusContainer} h3 {
    color: ${({ status, theme }) =>
      status === 'completed'
        ? theme.colors.green
        : (status === 'reviewing' && theme.colors.primary) ||
          theme.colors.grey300};
  }
`
