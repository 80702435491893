import { FunctionComponent, useState } from 'react'

import { Flex, Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { useAuthRoles } from 'hooks/useAuthRoles'
import { Layout } from 'layout/Layout'
import Button from 'ui-kit/Button'

import { SettingsSidebar } from './SettingsSidebar'
import { Users } from './Users'

export const TeamSettings: FunctionComponent = () => {
  const { t } = useTranslation()
  const [showAddMember, setShowAddMember] = useState(false)
  const { isOwner } = useAuthRoles()

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <Flex
        sx={{ mb: 10, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Heading variant="subtitle">{t('settings.team')}</Heading>
        {isOwner && (
          <Button
            icon="addMember"
            iconPosition="left"
            onClick={() => setShowAddMember(true)}
            sx={{ my: -2 }}
          >
            Add member
          </Button>
        )}
      </Flex>
      <Text as="div" sx={{ maxWidth: 640, mb: 5 }}>
        {t('settings.team.description')}
      </Text>

      <Users
        setShowAddMember={setShowAddMember}
        showAddMember={showAddMember}
      />
    </Layout>
  )
}
