import { SessionId } from 'store/apiHeaders/types'
import { Assessment, AssessmentOption } from 'store/assessment/types'
import { Question } from 'store/questionTypes'

export const getAssessmentToSave = (
  question: Question,
  selectedOption: number | undefined,
  selectedOptions: number[],
  number: string | undefined,
  other: string | undefined,
  sessionId: SessionId,
  accountId: number | undefined,
  companyId: number | undefined
): Partial<Assessment> => {
  const { options, type, id: questionId, area } = question
  const assessmentOptions = options
    .filter(({ id }) =>
      type === 'multi' ? selectedOptions.includes(id) : selectedOption == id
    )
    .map(
      ({ id, score, analysis }) =>
        ({
          optionId: id,
          score,
          analysis,
        } as AssessmentOption)
    )

  const totalScore = assessmentOptions
    .map(({ score }) => score)
    .reduce((total, score) => total + score, 0)

  return {
    areaId: area.id,
    questionId,
    score: totalScore,
    assessmentOptions,
    sessionId,
    companyId,
    accountId,
    value: number,
    other,
    skipped: false,
  }
}
