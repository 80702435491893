import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { baseColors, space } from '@fairhq/common'

import { ScheduledCampaignStatus } from '../types'

export const StatusColor: {
  [key: string]: string
} = {
  [ScheduledCampaignStatus.PENDING]: baseColors.blue,
  [ScheduledCampaignStatus.LIVE]: baseColors.yellow,
  [ScheduledCampaignStatus.COMPLETED]: baseColors.green,
  [ScheduledCampaignStatus.CANCELLED]: baseColors.grey300,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  & div:not(:last-child) {
    margin-right: ${space[1]}px;
  }
`

const Step = styled.div<{ total: number; color: string }>`
  background-color: ${({ color }) => color};
  height: ${space[1]}px;
  border-radius: 100px;
  width: calc(100% / ${({ total }) => total});
  max-width: 33%;
`

export interface ProgressProps {
  status: ScheduledCampaignStatus
  sent: number
  total: number
  children?: React.ReactNode
}

export const Progress: FunctionComponent<ProgressProps> = ({
  status,
  sent,
  total,
  children,
}) => {
  const { t } = useTranslation()
  const steps = Array.from(Array(total).keys())
  const color = StatusColor[status]
  return (
    <Container>
      <Text as="div" variant="caps" color={color} sx={{ mb: 2 }}>
        {t(`campaigns.${status}.title`)}
      </Text>
      {children}
      <Steps>
        {steps.map(step => (
          <Step
            key={step}
            total={total}
            color={step < sent ? color : baseColors.grey200}
          />
        ))}
      </Steps>
    </Container>
  )
}
