import { FunctionComponent } from 'react'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Graph, space, Spinner } from '@fairhq/common'
import { QueryStatus } from 'store/types'
import { RoundButton } from 'styled'
import { DonutChart } from 'ui-kit/DonutChart'
import { getLevel, getLevelColor, SCORE_MAX } from 'utils/scores'

import { SmallDonut } from './Donut'
import { useScoresOverview } from './hooks/useScoresOverview'

const Container = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
  padding: ${space[10]}px;
`

const SpinnerContainer = styled(Container)`
  display: flex;
  justify-content: center;
`

const OverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${baseColors.grey200_2};
  padding-bottom: ${space[6]}px;
  margin-bottom: ${space[6]}px;
`

const ChartContainer = styled.div`
  align-items: center;
  margin-left: ${space[4]}px;
`

const AreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${space[4]}px;
`

const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledGraph = styled(Graph)`
  margin-right: ${space[3]}px;
`

export const ScoresOverview: FunctionComponent = () => {
  const { t } = useTranslation()
  const { scoreAreas, dniLevel, status } = useScoresOverview()
  const { score: dniScore, max: dniMaxScore } = dniLevel || {}
  const level = getLevel(dniScore ?? 0)

  if (status === QueryStatus.LOADING || status === QueryStatus.IDLE) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (!scoreAreas || status === QueryStatus.ERROR) {
    return null
  }

  return (
    <Container>
      <OverviewContainer>
        <div>
          <Text
            variant="caps"
            color={baseColors.grey400}
            sx={{ display: 'block' }}
          >
            {t(`reportsOverview.title`)}
          </Text>
          <Text
            variant="title"
            color={baseColors.grey500}
            sx={{ display: 'block', fontSize: 24 }}
          >
            {t(`score.level.${level}`)}
          </Text>
          <Text color={baseColors.grey500} sx={{ display: 'block' }}>
            {t(`reportsOverview.${level}.description`)}
          </Text>
        </div>
        <ChartContainer>
          <DonutChart
            value={Math.round(dniScore ?? 0)}
            max={dniMaxScore ?? SCORE_MAX}
            valueLabel={`${round(
              (Math.round(dniScore ?? 0) * 10) / (dniMaxScore ?? SCORE_MAX) ??
                0,
              1
            )}`}
            color={getLevelColor(dniScore ?? 0)}
            showDetail={false}
            diameter={180}
          />
        </ChartContainer>
      </OverviewContainer>

      {scoreAreas.map(({ name, score, max }) => {
        const normalisedScore = score / (max || 1) ?? 0
        const scoreOutOf10 = round(normalisedScore * 10, 1)
        const color =
          scoreOutOf10 >= 7.5
            ? baseColors.green
            : (scoreOutOf10 >= 5 && baseColors.yellow) || baseColors.red
        return (
          <AreaContainer key={name}>
            <Text
              variant="h2"
              color={baseColors.grey500}
              sx={{ display: 'block', textTransform: 'capitalize' }}
            >
              {name}
            </Text>
            <ScoreContainer>
              <Text variant="title" sx={{ color, mr: 3 }}>
                {scoreOutOf10}
              </Text>
              <SmallDonut color={color} percentage={normalisedScore * 100} />
            </ScoreContainer>
          </AreaContainer>
        )
      })}
      <ButtonContainer>
        <RoundButton appearance="ghost" componentClass={NavLink} to="/reports">
          <StyledGraph outline={baseColors.grey300} />
          {t(`reportsOverview.cta`)}
        </RoundButton>
      </ButtonContainer>
    </Container>
  )
}
