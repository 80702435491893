import { emptySplitApi } from 'emptySplitApi'

import { NotionPage } from './types'

const notionApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getNotionPage: build.query<NotionPage, { pageId: string }>({
      query: ({ pageId }) => ({
        url: `notion/${pageId}`,
      }),
    }),
  }),
})

export const { useGetNotionPageQuery } = notionApi
