import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Graph: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '20',
  height = '18',
}) => (
  <svg
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9.99976V15.9998H4V9.99976H2ZM1.5 7.99976C0.671573 7.99976 0 8.67133 0 9.49976V16.4998C0 17.3282 0.671573 17.9998 1.5 17.9998H4.5C5.32843 17.9998 6 17.3282 6 16.4998V9.49976C6 8.67133 5.32843 7.99976 4.5 7.99976H1.5Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.99976V15.9998H11V5.99976H9ZM8.5 3.99976C7.67157 3.99976 7 4.67133 7 5.49976V16.4998C7 17.3282 7.67157 17.9998 8.5 17.9998H11.5C12.3284 17.9998 13 17.3282 13 16.4998V5.49976C13 4.67133 12.3284 3.99976 11.5 3.99976H8.5Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.99976V15.9998H18V1.99976H16ZM15.5 -0.000244141C14.6716 -0.000244141 14 0.671328 14 1.49976V16.4998C14 17.3282 14.6716 17.9998 15.5 17.9998H18.5C19.3284 17.9998 20 17.3282 20 16.4998V1.49976C20 0.671329 19.3284 -0.000244141 18.5 -0.000244141H15.5Z"
      fill={outline}
    />
  </svg>
)
