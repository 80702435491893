import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Message } from '../../styled/message'

import DataListFooter from './data-list-footer'
import DataListHeader from './data-list-header'
import List from './list'
import { DataListContainer } from './styled'
import { ListRowFooter } from './styled/list'

export interface ActionMenu {
  icon?: React.ReactNode
  onClick?: (data: unknown) => void
  label?: React.ReactNode
  hidden?: boolean
}

export interface Header {
  key?: string
  label?: string
  size?: number
}

export interface Row {
  data: Record<string, any>
  actions?: ActionMenu[]
  faded?: boolean
}

interface DataListProps {
  headers: Header[]
  rows: Row[]
  action?: ActionMenu
  onLoadMore?: () => void
  loading?: boolean
  preview?: boolean
  total?: number
  title?: string
  onSearch?: (searchTerm: string) => void
}

const DataList: FunctionComponent<DataListProps> = ({
  action,
  rows,
  headers,
  loading,
  preview,
  total,
  onLoadMore,
  onSearch,
  title,
}) => {
  const { t } = useTranslation()
  const rowsVisible = preview ? rows?.slice(0, 5) : rows
  return (
    <DataListContainer preview={preview ? 1 : 0}>
      <DataListHeader
        title={title}
        total={total}
        onSearch={onSearch}
        action={action}
      />
      <List headers={headers} rows={rowsVisible} />
      <DataListFooter
        loading={loading}
        onLoadMore={onLoadMore}
        preview={preview}
        total={total || rows.length}
        data={rowsVisible}
      />
      {preview ? undefined : (
        <ListRowFooter>
          <Message type="indication">
            {t('data.showing', { count: rowsVisible.length, total })}
          </Message>
        </ListRowFooter>
      )}
    </DataListContainer>
  )
}

export default DataList
