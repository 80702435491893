import React, { FunctionComponent, ReactNode } from 'react'

import { Box, Text } from '@theme-ui/components'

import { makeTransition } from '@fairhq/common'

interface GraphTooltipProps {
  tooltip: ReactNode
  isHorizontal?: boolean
}

export const Tooltip: FunctionComponent<GraphTooltipProps> = ({
  tooltip,
  isHorizontal,
}) => {
  const verticalStyles = {
    bottom: '100%',
    left: '50%',
    transform: 'translate(-50%, 6px)',
  }
  const verticalAfterStyles = {
    top: '100%',
    left: '50%',
    ml: -1,
    borderWidth: '6px 6px 0 6px',
    borderTopColor: 'grey500',
  }

  const horizontalStyles = {
    bottom: '50%',
    left: '100%',
    ml: 3,
    transform: 'translate(-6px, 50%)',
  }
  const horizontalAfterStyles = {
    top: '50%',
    right: '100%',
    mt: -1,
    borderWidth: '6px 6px 6px 0',
    borderRightColor: 'grey500',
  }

  if (!tooltip) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      <Box
        className="tooltip"
        sx={{
          position: 'absolute',
          py: 1,
          px: 2,
          bg: 'grey500',
          opacity: 0,
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          borderRadius: 6,
          zIndex: 5,
          transition: [
            makeTransition('opacity'),
            makeTransition('transform'),
          ].join(),
          '&:after': {
            content: '""',
            position: 'absolute',
            borderStyle: 'solid',
            borderColor: 'transparent',
            ...(isHorizontal ? horizontalAfterStyles : verticalAfterStyles),
          },
          ...(isHorizontal ? horizontalStyles : verticalStyles),
        }}
      >
        <Text
          as="div"
          variant="label"
          sx={{
            textAlign: 'center',
            color: 'white',
          }}
        >
          {tooltip}
        </Text>
      </Box>
    </Box>
  )
}
