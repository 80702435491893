import React, { ReactNode, useState, FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { Colors } from '@fairhq/common'
import { CHANGELOG_MAX_SHOW } from 'const'
import Icon, { IconName } from 'ui-kit/Icon'
import { markedNumberFormat } from 'utils/numbers'

export type GapListItem = {
  key: string
  label: string
  value: string | number
  icon?: IconName
  delta?: number
}

interface GapListProps {
  title: ReactNode
  items: GapListItem[]
}

export const GapList: FunctionComponent<GapListProps> = ({ title, items }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)

  const itemsToDisplay =
    items?.length > CHANGELOG_MAX_SHOW
      ? (showMore && items) || items.slice(0, CHANGELOG_MAX_SHOW)
      : items

  return (
    <Box>
      <Flex
        sx={{
          alignItems: 'center',
          px: 5,
          pt: 5,
          pb: 4,
          borderRadius: '20px 20px 0 0',
          bg: 'white',
        }}
      >
        {title}
      </Flex>
      {itemsToDisplay.map(({ key, label, value, delta, icon }, i) => {
        const isPositive = !!delta && delta >= 0
        const isNegative = !!delta && delta < 0

        return (
          <Flex
            key={key || label}
            sx={{
              borderTop: '1px solid',
              borderColor: 'border',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius:
                items?.length <= CHANGELOG_MAX_SHOW &&
                i === itemsToDisplay.length - 1
                  ? '0 0 20px 20px'
                  : '',
              bg: 'white',
              px: 5,
              py: 2,
            }}
          >
            <Flex sx={{ alignItems: 'center' }}>
              {icon && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 48,
                    height: 48,
                    mr: 2,
                    bg: 'grey100',
                    borderRadius: '50%',
                  }}
                >
                  <Icon name={icon} color={Colors.GREY200} />
                </Flex>
              )}
              <Text as="div" sx={{ color: 'grey500' }}>
                {label}
              </Text>
            </Flex>

            <Flex>
              <Text
                as="div"
                variant="bodyLargeBold"
                sx={{
                  color: isPositive ? 'green' : isNegative ? 'red' : 'grey400',
                }}
              >
                {markedNumberFormat(delta)}
              </Text>
              <Text
                as="div"
                variant="subtitle"
                sx={{ minWidth: '72px', textAlign: 'right' }}
              >
                {value}
              </Text>
            </Flex>
          </Flex>
        )
      })}
      {items?.length > CHANGELOG_MAX_SHOW ? (
        <Flex
          as="button"
          sx={{
            width: '100%',
            display: 'inline-flex',
            height: 52,
            alignItems: 'center',
            padding: 0,
            borderTop: '1px solid',
            borderColor: 'border',
            justifyContent: 'center',
            borderRadius: '0 0 20px 20px',
            bg: 'white',
            px: 5,
            py: 2,
          }}
          onClick={() => setShowMore(!showMore)}
          role="button"
        >
          <Text as="div" variant="labelBold" sx={{ color: 'grey400' }}>
            {showMore ? t('showLess') : t('showMore')}
          </Text>
          <Icon
            name={showMore ? 'arrowUp' : 'arrowDown'}
            small
            sx={{ ml: 1 }}
          />
        </Flex>
      ) : undefined}
    </Box>
  )
}
