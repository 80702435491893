import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'

import { VisualizationLegendProps } from '../types'

export const Legend: FunctionComponent<VisualizationLegendProps> = ({
  legend,
  sx,
}) => (
  <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap', mb: -1, ...sx }}>
    {legend.map(item => (
      <Flex
        key={`legend_${Math.random()}`}
        sx={{ alignItems: 'center', mx: 2, mb: 1 }}
      >
        <Box
          sx={{
            width: 18,
            height: 18,
            bg: item.color,
            backgroundImage: ({ colors }) =>
              item.color === 'transparent'
                ? `linear-gradient(to left, ${colors?.border} 25%, transparent 25%, transparent 75%, ${colors?.border} 75%)`
                : undefined,
            backgroundSize:
              item.color === 'transparent' ? '18px 2px' : undefined,
            backgroundPosition:
              item.color === 'transparent' ? 'center' : undefined,
            backgroundRepeat:
              item.color === 'transparent' ? 'repeat-x' : undefined,
            mr: 1,
            borderRadius: 6,
            flex: '0 0 auto',
          }}
        />
        <Text as="div">{item.label}</Text>
      </Flex>
    ))}
  </Flex>
)
