import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Plus: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M12 4V20"
      stroke={outline}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12H20"
      stroke={outline}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
