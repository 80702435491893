import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Sparkles: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '20',
  height = '20',
}) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0.833313C5.46024 0.833313 5.83334 1.20641 5.83334 1.66665V2.49998C5.83334 3.16302 6.09673 3.79891 6.56557 4.26775C7.03441 4.73659 7.67029 4.99998 8.33333 4.99998C8.79357 4.99998 9.16667 5.37308 9.16667 5.83331C9.16667 6.29355 8.79357 6.66665 8.33333 6.66665C7.67029 6.66665 7.03441 6.93004 6.56557 7.39888C6.09673 7.86772 5.83334 8.5036 5.83334 9.16665V9.99998C5.83334 10.4602 5.46024 10.8333 5 10.8333C4.53976 10.8333 4.16667 10.4602 4.16667 9.99998V9.16665C4.16667 8.5036 3.90328 7.86772 3.43444 7.39888C2.9656 6.93004 2.32971 6.66665 1.66667 6.66665C1.20643 6.66665 0.833336 6.29355 0.833336 5.83331C0.833336 5.37308 1.20643 4.99998 1.66667 4.99998C2.32971 4.99998 2.9656 4.73659 3.43444 4.26775C3.90328 3.79891 4.16667 3.16302 4.16667 2.49998V1.66665C4.16667 1.20641 4.53976 0.833313 5 0.833313ZM4.1667 5.83331C4.32352 5.95093 4.47271 6.08013 4.61295 6.22037C4.75318 6.3606 4.88238 6.5098 5 6.66662C5.11762 6.5098 5.24682 6.3606 5.38706 6.22037C5.52729 6.08013 5.67649 5.95093 5.83331 5.83331C5.67649 5.71569 5.52729 5.58649 5.38706 5.44626C5.24682 5.30603 5.11762 5.15683 5 5.00001C4.88238 5.15683 4.75318 5.30603 4.61295 5.44626C4.47271 5.58649 4.32352 5.71569 4.1667 5.83331Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 3.33331V4.16665C13.3333 5.0507 12.9821 5.89855 12.357 6.52367C11.7319 7.14879 10.8841 7.49998 10 7.49998C9.53976 7.49998 9.16667 7.87308 9.16667 8.33331C9.16667 8.79355 9.53976 9.16665 10 9.16665C10.8841 9.16665 11.7319 9.51783 12.357 10.143C12.9821 10.7681 13.3333 11.6159 13.3333 12.5V13.3333C13.3333 13.7935 13.7064 14.1666 14.1667 14.1666C14.6269 14.1666 15 13.7935 15 13.3333V12.5C15 11.6159 15.3512 10.7681 15.9763 10.143C16.6014 9.51783 17.4493 9.16665 18.3333 9.16665C18.7936 9.16665 19.1667 8.79355 19.1667 8.33331C19.1667 7.87308 18.7936 7.49998 18.3333 7.49998C17.4493 7.49998 16.6014 7.14879 15.9763 6.52367C15.3512 5.89855 15 5.0507 15 4.16665V3.33331C15 2.87308 14.6269 2.49998 14.1667 2.49998C13.7064 2.49998 13.3333 2.87308 13.3333 3.33331ZM14.1667 6.93053C13.9839 7.20613 13.7729 7.46485 13.5355 7.70218C13.2982 7.93951 13.0395 8.15051 12.7639 8.33331C13.0395 8.51612 13.2982 8.72712 13.5355 8.96445C13.7729 9.20177 13.9839 9.4605 14.1667 9.73609C14.3495 9.4605 14.5605 9.20177 14.7978 8.96445C15.0351 8.72712 15.2939 8.51612 15.5694 8.33331C15.2939 8.15051 15.0351 7.93951 14.7978 7.70218C14.5605 7.46485 14.3495 7.20613 14.1667 6.93053Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 17.9166C7.5 17.2536 7.23661 16.6177 6.76777 16.1489C6.29893 15.68 5.66304 15.4166 5 15.4166C4.53976 15.4166 4.16667 15.0435 4.16667 14.5833C4.16667 14.1231 4.53976 13.75 5 13.75C5.66304 13.75 6.29893 13.4866 6.76777 13.0177C7.23661 12.5489 7.5 11.913 7.5 11.25V10.8333C7.5 10.3731 7.8731 9.99998 8.33333 9.99998C8.79357 9.99998 9.16667 10.3731 9.16667 10.8333V11.25C9.16667 11.913 9.43006 12.5489 9.8989 13.0177C10.3677 13.4866 11.0036 13.75 11.6667 13.75C12.1269 13.75 12.5 14.1231 12.5 14.5833C12.5 15.0435 12.1269 15.4166 11.6667 15.4166C11.0036 15.4166 10.3677 15.68 9.8989 16.1489C9.43006 16.6177 9.16667 17.2536 9.16667 17.9166V18.3333C9.16667 18.7935 8.79357 19.1666 8.33333 19.1666C7.8731 19.1666 7.5 18.7935 7.5 18.3333V17.9166ZM7.94628 14.1963C8.08651 14.056 8.21572 13.9068 8.33333 13.75C8.45095 13.9068 8.58016 14.056 8.72039 14.1963C8.86062 14.3365 9.00982 14.4657 9.16664 14.5833C9.00982 14.7009 8.86062 14.8301 8.72039 14.9704C8.58016 15.1106 8.45095 15.2598 8.33333 15.4166C8.21572 15.2598 8.08651 15.1106 7.94628 14.9704C7.80605 14.8301 7.65685 14.7009 7.50003 14.5833C7.65685 14.4657 7.80605 14.3365 7.94628 14.1963Z"
      fill={outline}
    />
  </svg>
)
