import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

export const SquareIconBackground = styled.div`
  background-color: ${baseColors.purple300};
  color: ${baseColors.purple};
  border-radius: 8px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`
