import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { BaseConfirmModal } from 'components/BaseConfirmModal'

interface ConfirmCloseModalProps {
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmCloseModal: FunctionComponent<ConfirmCloseModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <BaseConfirmModal
      onClose={onClose}
      onSubmit={onSubmit}
      title={t('surveys.close.confirmTitle')}
      body={t('surveys.close.confirmBody')}
      confirmCustomText={t('surveys.close.confirmSubmit')}
      cancelCustomText={t(`surveys.close.confirmCancel`)}
    />
  )
}
