import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'
import { Task } from 'store/tasks/types'

import { CompanyInitiative } from './types'

const initiativesApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getInitiatives: build.query<
      { companyInitiatives: CompanyInitiative[]; companyTasks: Task[] },
      { sessionId: SessionId }
    >({
      query: ({ sessionId }) => ({
        url: `initiatives`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.INITIATIVES],
    }),
    updateInitiative: build.mutation<
      CompanyInitiative,
      { strategyId: number | null; id: number }
    >({
      query: ({ id, strategyId }) => ({
        url: `initiatives/${id}`,
        method: 'PATCH',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
        body: { strategyId },
      }),
      invalidatesTags: [TagTypes.INITIATIVES],
    }),
  }),
})

export const { useGetInitiativesQuery, useUpdateInitiativeMutation } =
  initiativesApi
