import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { EmployeeState } from 'features/employees/types'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isRejected } from 'store/helpers/isRejected'
import { sendSurvey } from 'store/survey/surveySlice'

import { employeeApi } from './employeeApi'

const clear = createAction('employee/clear')

export const count = createAsyncThunk(
  'employee/count',
  async (_, { getState }) => {
    try {
      const [total, invited, level] = await employeeApi.count(getState)

      return [await total.json(), await invited.json(), await level.json()]
    } catch (error) {
      const typedError = error as NotifiableError
      Bugsnag.notify(typedError)
      throw error
    }
  }
)

const initialState: Partial<EmployeeState> = {
  loading: false,
  count: undefined,
  employeesFetched: false,
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(count.pending, state => {
        state.error = undefined
        state.loading = true
      })
      .addCase(count.fulfilled, (state, action) => {
        state.loading = false
        const [total, invited, level] = action.payload
        state.count = +total
        state.countNotInvited = +invited?.pending // eslint-disable-line no-unsafe-optional-chaining
        state.countInvited = +invited?.invited // eslint-disable-line no-unsafe-optional-chaining
        state.countByJobLevel = {}
        Object.keys(level).forEach(key => {
          if (state.countByJobLevel) {
            state.countByJobLevel[key] = +level[key]
          }
        })
      })
      .addCase(sendSurvey.fulfilled, state => {
        state.countNotInvited = 0
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('employee'), handleErrorState)
  },
})

export const { reducer: employeeReducer } = employeeSlice
