import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const keysSentToSentry = new Set()
export const translations: any[] = []
const interpolationSent = new Set()
const loadPath = `/translations/{{lng}}/{{ns}}.json`

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: { loadPath },
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    keySeparator: false,
    defaultNS: 'default',
    ns: ['default'],
    compatibilityJSON: 'v2',
    returnNull: false,
    supportedLngs: ['en', 'fr'],
    async missingInterpolationHandler(text: string) {
      if (!interpolationSent.has(text)) {
        interpolationSent.add(text)
      }
    },
    parseMissingKeyHandler(key) {
      const missingKey = {
        key,
        content: 'Missing content',
        note: `Location: ${window.location.pathname}`,
      }

      if (!keysSentToSentry.has(key)) {
        keysSentToSentry.add(key)
        translations.push(missingKey)
      }

      return key
    },
  })

export default i18n
