import { FunctionComponent, ReactNode } from 'react'

import { Container } from 'rsuite'
import styled from 'styled-components'

import { ReactComponent as CheckboxCheckedIcon } from './assets/checkbox-checked.svg'
import { ReactComponent as CheckboxEmptyIcon } from './assets/checkbox-empty.svg'
import { ReactComponent as RadioCheckedIcon } from './assets/radio-checked.svg'
import { ReactComponent as RadioEmptyIcon } from './assets/radio-empty.svg'

const LabelContainer = styled(Container)``

interface LabelProps {
  checked: boolean
  isCheckbox?: boolean
  label?: ReactNode
}

export const Label: FunctionComponent<LabelProps> = ({
  label,
  checked,
  isCheckbox,
}) => {
  const CheckedIcon = isCheckbox ? CheckboxCheckedIcon : RadioCheckedIcon
  const EmptyIcon = isCheckbox ? CheckboxEmptyIcon : RadioEmptyIcon

  return (
    <LabelContainer>
      {checked ? <CheckedIcon /> : <EmptyIcon />}
      {label ? <span>{label}</span> : undefined}
    </LabelContainer>
  )
}
