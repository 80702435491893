import { TFunction } from 'i18next'

import { Colors } from '@fairhq/common'

import { Area } from 'store/area/types'
import { IconName } from 'ui-kit/Icon'

export interface TaskOldRecommendation {
  code: string
  title: string
  intensity: string
  type: string[]
  notionLink: string
  image: string | null
}

export interface OldCompanyRecommendation {
  status: OldCompanyRecommendationStatus | string
  id: number
  areaId: number
  companyId: number
  oldRecommendationId: number
  sessionId: number
  area: Partial<Area & { score: { percent: number } }>
  oldRecommendation: Partial<OldRecommendation>
  copies: AppCopy[]
}

interface AppCopy {
  code: string
  deprecated: false
  description: string
  id: number
  score: string
  type: string
  updatedAt: string
}

export interface OldRecommendation {
  id: number
  code: string
  title: string
  intensity: OldRecommendationIntensity | string
  type: (OldRecommendationTypeStatus | string)[]
  notionLink: string
  image: string
  linkedReportRatingId: string[]
  linkedPracticeCriteriaId: string[]
  oldLinkedRecommendationsId: string[]
}

export enum OldRecommendationIntensity {
  MEDIUM = 'medium',
  LOW = 'low',
  INTENSIVE = 'intensive',
}

export enum OldCompanyRecommendationStatus {
  RECOMMENDED = 'recommended',
  WORKING_ON = 'working on',
  PLANNED = 'planned',
  IMPLEMENTED = 'implemented',
}

export const oldRecommendationStatusIcon: Record<
  OldCompanyRecommendationStatus | string,
  IconName
> = {
  [OldCompanyRecommendationStatus.RECOMMENDED]: 'improvements',
  [OldCompanyRecommendationStatus.PLANNED]: 'date',
  [OldCompanyRecommendationStatus.WORKING_ON]: 'pending',
  [OldCompanyRecommendationStatus.IMPLEMENTED]: 'successFull',
}
export const oldRecommendationStatusList: OldCompanyRecommendationStatus[] = [
  OldCompanyRecommendationStatus.RECOMMENDED,
  OldCompanyRecommendationStatus.PLANNED,
  OldCompanyRecommendationStatus.WORKING_ON,
  OldCompanyRecommendationStatus.IMPLEMENTED,
]

export enum OldRecommendationTypeStatus {
  PROCESS = 'process',
  TRAINING = 'training',
  TOOL = 'tool',
  DOCUMENT = 'document',
  STRATEGY = 'strategy',
  TIP = 'tip',
}

export const oldRecommendationStatusLabel = (t: TFunction) =>
  ({
    [OldCompanyRecommendationStatus.RECOMMENDED]: t(
      'oldRecommendation.status.recommended'
    ),
    [OldCompanyRecommendationStatus.PLANNED]: t(
      'oldRecommendation.status.planned'
    ),
    [OldCompanyRecommendationStatus.WORKING_ON]: t(
      'oldRecommendation.status.workingOn'
    ),
    [OldCompanyRecommendationStatus.IMPLEMENTED]: t(
      'oldRecommendation.status.completed'
    ),
  } as Record<OldCompanyRecommendationStatus | string, string>)

export const oldRecommendationStatusColor: Record<
  OldCompanyRecommendationStatus | string,
  Colors
> = {
  [OldCompanyRecommendationStatus.RECOMMENDED]: Colors.GREY300,
  [OldCompanyRecommendationStatus.PLANNED]: Colors.BLUE,
  [OldCompanyRecommendationStatus.WORKING_ON]: Colors.YELLOW,
  [OldCompanyRecommendationStatus.IMPLEMENTED]: Colors.GREEN,
}

export const iconMap: Record<string, IconName> = {
  [OldRecommendationTypeStatus.DOCUMENT]: 'documents',
  [OldRecommendationTypeStatus.PROCESS]: 'settings',
  [OldRecommendationTypeStatus.TOOL]: 'wrench',
  [OldRecommendationTypeStatus.TRAINING]: 'lightbulb',
  [OldRecommendationTypeStatus.TIP]: 'info',
  [OldRecommendationTypeStatus.STRATEGY]: 'strategy',
}

export const textMap: Record<string, string> = {
  [OldRecommendationTypeStatus.DOCUMENT]: 'Document',
  [OldRecommendationTypeStatus.PROCESS]: 'Process',
  [OldRecommendationTypeStatus.TOOL]: 'Tool',
  [OldRecommendationTypeStatus.TRAINING]: 'Training',
  [OldRecommendationTypeStatus.TIP]: 'Tip',
  [OldRecommendationTypeStatus.STRATEGY]: 'Strategy',
}
