import { FunctionComponent } from 'react'

import { orderBy } from 'lodash'
import styled from 'styled-components'

import { Link } from 'theme-ui'

import { baseColors, Button, Text } from '@fairhq/common'

import { SessionCamelCase } from 'store/company/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { TableCell, TableRow } from 'ui-kit/Table'

import { AccountAndCompany } from './Admin'

interface AdminListItemProps {
  account: AccountAndCompany
  onClick: (a: AccountAndCompany) => void
  showMetabaseLink: boolean
}

const StyledButton = styled(Button)`
  padding: 6px 12px;
`
export const AdminListItem: FunctionComponent<AdminListItemProps> = ({
  account,
  onClick,
  showMetabaseLink,
}) => {
  const { viewingCompanyId } = useAppSelector((state: State) => ({
    viewingCompanyId: state.apiHeadersReducer.companyId,
  }))

  const ViewButton = (
    <StyledButton variant="primary" onClick={() => onClick(account)}>
      <Text element="p" type="body" size="2xs" fontWeight={500}>
        view
      </Text>
    </StyledButton>
  )
  const ViewExternalLink = showMetabaseLink ? (
    <Link
      sx={{ hover: { color: 'red' } }}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://fairhq.metabaseapp.com/dashboard/166?company_id=${account.company.id}`}
    >
      view in Metabase &#x21AA;
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  )
  const latestSession: SessionCamelCase = orderBy(
    account.company.sessions,
    ['startDate'],
    ['desc']
  )[0]

  const rowCells = [
    `${account.company.name} (${latestSession.auditType})`,
    account.company.id,
    account.id,
    ViewButton,
    ViewExternalLink,
  ]

  return (
    <TableRow
      sx={{
        color: 'grey500',
        ':hover': { bg: 'grey100' },
        fontWeight: viewingCompanyId === account.company.id ? 'bold' : 'none',
        backgroundColor:
          viewingCompanyId === account.company.id
            ? baseColors.grey100
            : 'white',
      }}
    >
      {rowCells.map((children, index) => (
        <TableCell
          key={index}
          sx={{
            paddingTop: '2px',
            paddingBottom: '2px',
            height: '50px',
          }}
        >
          {children}
        </TableCell>
      ))}
    </TableRow>
  )
}
