import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export const useUser = (
  owner: string
): {
  nickname?: string
  picture?: string
} => {
  const { users } = useAppSelector(
    (state: State) => ({
      users: state.accountReducer.users ?? [],
    }),
    shallowEqual
  )

  const user = users?.find(user => user.user_id === owner)
  return {
    picture: user?.picture,
    nickname: user?.nickname,
  }
}
