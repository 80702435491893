import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import Icon, { IconName } from 'ui-kit/Icon'

interface SubNavItemProps {
  icon: IconName
  label: string
  onClick?: () => void
}

export const SubNavItem: FunctionComponent<SubNavItemProps> = ({
  icon,
  label,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Text
      as="div"
      variant="link"
      sx={{
        textAlign: 'right',
        color: 'grey400',
        '&:hover': {
          color: 'purple',
          '& > svg': { fill: 'purple' },
        },
      }}
      onClick={onClick}
    >
      {t(label)}
      <Icon name={icon} sx={{ ml: 2, verticalAlign: 'top' }} />
    </Text>
  )
}
