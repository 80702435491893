import React from 'react'

import { Box, BoxProps } from '@theme-ui/components'

import { makeTransition, Colors } from '@fairhq/common'

import { ReactComponent as ArrowCarret } from '../assets/icons/12/arrow-carret.svg'
import { ReactComponent as ArrowDownRight } from '../assets/icons/12/arrow-down-right.svg'
import { ReactComponent as ArrowDown } from '../assets/icons/12/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../assets/icons/12/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../assets/icons/12/arrow-right.svg'
import { ReactComponent as ArrowSortAsc } from '../assets/icons/12/arrow-sort-asc.svg'
import { ReactComponent as ArrowSortDesc } from '../assets/icons/12/arrow-sort-desc.svg'
import { ReactComponent as ArrowTopRight } from '../assets/icons/12/arrow-top-right.svg'
import { ReactComponent as ArrowUp } from '../assets/icons/12/arrow-up.svg'
import { ReactComponent as Close12 } from '../assets/icons/12/close.svg'
import { ReactComponent as AddMember } from '../assets/icons/24/add-member.svg'
import { ReactComponent as Add } from '../assets/icons/24/add.svg'
import { ReactComponent as leadership } from '../assets/icons/24/areas/diversity.leadership.svg'
import { ReactComponent as manager } from '../assets/icons/24/areas/diversity.manager.svg'
import { ReactComponent as peopleOfColor } from '../assets/icons/24/areas/diversity.peopleOfColor.svg'
import { ReactComponent as women } from '../assets/icons/24/areas/diversity.women.svg'
import { ReactComponent as complaints } from '../assets/icons/24/areas/equality.complaints.svg'
import { ReactComponent as employeeAttrition } from '../assets/icons/24/areas/equality.employeeAttrition.svg'
import { ReactComponent as hiring } from '../assets/icons/24/areas/equality.hiring.svg'
import { ReactComponent as keyPolicies } from '../assets/icons/24/areas/equality.keyPolicies.svg'
import { ReactComponent as learning } from '../assets/icons/24/areas/equality.learning.svg'
import { ReactComponent as onboarding } from '../assets/icons/24/areas/equality.onboarding.svg'
import { ReactComponent as payAndBonus } from '../assets/icons/24/areas/equality.payAndBonus.svg'
import { ReactComponent as performance } from '../assets/icons/24/areas/equality.performance.svg'
import { ReactComponent as promotion } from '../assets/icons/24/areas/equality.promotion.svg'
import { ReactComponent as belonging } from '../assets/icons/24/areas/inclusion.belonging.svg'
import { ReactComponent as fairness } from '../assets/icons/24/areas/inclusion.fairness.svg'
import { ReactComponent as openness } from '../assets/icons/24/areas/inclusion.openness.svg'
import { ReactComponent as psychologicalSafety } from '../assets/icons/24/areas/inclusion.psychologicalSafety.svg'
import { ReactComponent as voice } from '../assets/icons/24/areas/inclusion.voice.svg'
import { ReactComponent as ArrowLeftBack } from '../assets/icons/24/arrow-left-back.svg'
import { ReactComponent as Assessment } from '../assets/icons/24/assessment.svg'
import { ReactComponent as Branches } from '../assets/icons/24/branches.svg'
import { ReactComponent as CheckboxCheckedRounded } from '../assets/icons/24/checkbox-checked-rounded.svg'
import { ReactComponent as CheckboxChecked } from '../assets/icons/24/checkbox-checked.svg'
import { ReactComponent as CheckboxUnavailable } from '../assets/icons/24/checkbox-unavailable.svg'
import { ReactComponent as Checkbox } from '../assets/icons/24/checkbox.svg'
import { ReactComponent as Checkmark } from '../assets/icons/24/checkmark.svg'
import { ReactComponent as Close } from '../assets/icons/24/close.svg'
import { ReactComponent as Company } from '../assets/icons/24/company.svg'
import { ReactComponent as Compass } from '../assets/icons/24/compass.svg'
import { ReactComponent as Customer } from '../assets/icons/24/customer.svg'
import { ReactComponent as Dashboard } from '../assets/icons/24/dashboard.svg'
import { ReactComponent as DateLate } from '../assets/icons/24/date-late.svg'
import { ReactComponent as Date } from '../assets/icons/24/date.svg'
import { ReactComponent as Document } from '../assets/icons/24/document.svg'
import { ReactComponent as Documents } from '../assets/icons/24/documents.svg'
import { ReactComponent as Edit } from '../assets/icons/24/edit.svg'
import { ReactComponent as Error } from '../assets/icons/24/error.svg'
import { ReactComponent as Fail } from '../assets/icons/24/fail.svg'
import { ReactComponent as Flag } from '../assets/icons/24/flag.svg'
import { ReactComponent as Help } from '../assets/icons/24/help.svg'
import { ReactComponent as Home } from '../assets/icons/24/home.svg'
import { ReactComponent as Improvements } from '../assets/icons/24/improvements.svg'
import { ReactComponent as Info } from '../assets/icons/24/info.svg'
import { ReactComponent as Lightbulb } from '../assets/icons/24/lightbulb.svg'
import { ReactComponent as LogOut } from '../assets/icons/24/log-out.svg'
import { ReactComponent as MenuKebab } from '../assets/icons/24/menu-kebab.svg'
import { ReactComponent as Menu } from '../assets/icons/24/menu.svg'
import { ReactComponent as Paperclip } from '../assets/icons/24/paperclip.svg'
import { ReactComponent as PaymentMethodFail } from '../assets/icons/24/payment-method-fail.svg'
import { ReactComponent as PaymentMethod } from '../assets/icons/24/payment-method.svg'
import { ReactComponent as Pending } from '../assets/icons/24/pending.svg'
import { ReactComponent as Profile } from '../assets/icons/24/profile.svg'
import { ReactComponent as Resend } from '../assets/icons/24/resend.svg'
import { ReactComponent as Scales } from '../assets/icons/24/scales.svg'
import { ReactComponent as Search } from '../assets/icons/24/search.svg'
import { ReactComponent as Send } from '../assets/icons/24/send.svg'
import { ReactComponent as Settings } from '../assets/icons/24/settings.svg'
import { ReactComponent as Strategy } from '../assets/icons/24/strategy.svg'
import { ReactComponent as SuccessFull } from '../assets/icons/24/success-full.svg'
import { ReactComponent as Success } from '../assets/icons/24/success.svg'
import { ReactComponent as Survey } from '../assets/icons/24/survey.svg'
import { ReactComponent as Team } from '../assets/icons/24/team.svg'
import { ReactComponent as Unavailable } from '../assets/icons/24/unavailable.svg'
import { ReactComponent as Visible } from '../assets/icons/24/visible.svg'
import { ReactComponent as Wallet } from '../assets/icons/24/wallet.svg'
import { ReactComponent as Wrench } from '../assets/icons/24/wrench.svg'

import { ReactComponent as ReportInfo } from '../assets/icons/30/report-info.svg'
import { ReactComponent as ReportNegative } from '../assets/icons/30/report-negative.svg'
import { ReactComponent as ReportPositive } from '../assets/icons/30/report-positive.svg'

export type IconName =
  | 'addMember'
  | 'add'
  | 'arrowLeftBack'
  | 'assessment'
  | 'checkmark'
  | 'close'
  | 'company'
  | 'customer'
  | 'dashboard'
  | 'dateLate'
  | 'date'
  | 'documents'
  | 'edit'
  | 'error'
  | 'fail'
  | 'help'
  | 'home'
  | 'improvements'
  | 'info'
  | 'lightbulb'
  | 'logOut'
  | 'menuKebab'
  | 'menu'
  | 'paymentMethodFail'
  | 'paymentMethod'
  | 'pending'
  | 'profile'
  | 'resend'
  | 'search'
  | 'send'
  | 'settings'
  | 'strategy'
  | 'success'
  | 'successFull'
  | 'survey'
  | 'team'
  | 'visible'
  | 'wallet'
  | 'wrench'
  | 'arrowCarret'
  | 'arrowDownRight'
  | 'arrowDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowSortAsc'
  | 'arrowSortDesc'
  | 'arrowTopRight'
  | 'arrowUp'
  | 'closeSmall'
  | 'reportPositive'
  | 'reportNegative'
  | 'reportInfo'
  | 'scales'
  | 'flag'
  | 'compass'
  | 'paperclip'
  | 'checkbox'
  | 'checkboxChecked'
  | 'checkboxCheckedRounded'
  | 'checkboxUnavailable'
  | 'branches'
  | 'unavailable'
  | 'document'
  | 'diversity.leadership'
  | 'diversity.manager'
  | 'diversity.peopleOfColor'
  | 'diversity.women'
  | 'equality.complaints'
  | 'equality.employeeAttrition'
  | 'equality.hiring'
  | 'equality.keyPolicies'
  | 'equality.learning'
  | 'equality.onboarding'
  | 'equality.payAndBonus'
  | 'equality.performance'
  | 'equality.promotion'
  | 'inclusion.belonging'
  | 'inclusion.fairness'
  | 'inclusion.openness'
  | 'inclusion.psychologicalSafety'
  | 'inclusion.voice'

export const icons = {
  addMember: AddMember,
  add: Add,
  arrowLeftBack: ArrowLeftBack,
  assessment: Assessment,
  checkmark: Checkmark,
  close: Close,
  company: Company,
  customer: Customer,
  dashboard: Dashboard,
  dateLate: DateLate,
  date: Date,
  documents: Documents,
  edit: Edit,
  error: Error,
  fail: Fail,
  help: Help,
  home: Home,
  improvements: Improvements,
  info: Info,
  lightbulb: Lightbulb,
  logOut: LogOut,
  menuKebab: MenuKebab,
  menu: Menu,
  paymentMethodFail: PaymentMethodFail,
  paymentMethod: PaymentMethod,
  pending: Pending,
  profile: Profile,
  resend: Resend,
  search: Search,
  send: Send,
  settings: Settings,
  strategy: Strategy,
  success: Success,
  successFull: SuccessFull,
  survey: Survey,
  team: Team,
  visible: Visible,
  wallet: Wallet,
  wrench: Wrench,

  arrowCarret: ArrowCarret,
  arrowDownRight: ArrowDownRight,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowSortAsc: ArrowSortAsc,
  arrowSortDesc: ArrowSortDesc,
  arrowTopRight: ArrowTopRight,
  arrowUp: ArrowUp,
  closeSmall: Close12,

  reportPositive: ReportPositive,
  reportNegative: ReportNegative,
  reportInfo: ReportInfo,

  scales: Scales,
  flag: Flag,
  compass: Compass,
  paperclip: Paperclip,
  checkbox: Checkbox,
  checkboxChecked: CheckboxChecked,
  checkboxCheckedRounded: CheckboxCheckedRounded,
  checkboxUnavailable: CheckboxUnavailable,
  branches: Branches,
  unavailable: Unavailable,
  document: Document,

  'diversity.leadership': leadership,
  'diversity.manager': manager,
  'diversity.peopleOfColor': peopleOfColor,
  'diversity.women': women,
  'equality.complaints': complaints,
  'equality.employeeAttrition': employeeAttrition,
  'equality.hiring': hiring,
  'equality.keyPolicies': keyPolicies,
  'equality.learning': learning,
  'equality.onboarding': onboarding,
  'equality.payAndBonus': payAndBonus,
  'equality.performance': performance,
  'equality.promotion': promotion,
  'inclusion.belonging': belonging,
  'inclusion.fairness': fairness,
  'inclusion.openness': openness,
  'inclusion.psychologicalSafety': psychologicalSafety,
  'inclusion.voice': voice,
}

export type IconProps = {
  name: IconName
  small?: boolean
  color?: Colors | 'currentColor'
} & BoxProps

export default function Icon({ name, small, color, sx, ...rest }: IconProps) {
  return (
    <Box
      as={icons[name]}
      sx={{
        width: small ? 12 : 24,
        height: small ? 12 : 24,
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: name?.includes('.') ? undefined : color || 'icon',
        'path[fill-rule="evenodd"]': {
          fill: name?.includes('.') ? color || 'icon' : undefined,
        },
        'path[stroke="white"]': {
          stroke: name?.includes('.') ? color || 'icon' : undefined,
        },
        transition: makeTransition('fill'),
        ...sx,
      }}
      {...rest}
    />
  )
}
