import React from 'react'

import { groupBy, orderBy } from 'lodash'

import { Trans } from 'react-i18next'

import { Colors } from '@fairhq/common'

import { REPORTS_BLUE } from 'features/reportsAreasCore/utils/representation/getDemGroupRepresentationByTab'

import { COLOR_RULES_DEFAULT } from '../constants'
import {
  ColorRule,
  VisualizationBenchmarkProps,
  VisualizationItemProps,
  VisualizationItemValueProps,
} from '../types'

const convertItem = (
  item: any,
  colorRules: ColorRule[],
  type: 'Bar' | 'Comparison' | 'Row' | 'Stack' | 'RowStack',
  isMinMajSorted: boolean
) => {
  if (item?.values) {
    let formattedItemValues = item.values.map((itemValue: any) => ({
      ...itemValue,
      color:
        itemValue.color ||
        colorRules?.find(({ max }) => itemValue.value < max)?.color,
    }))
    if (type !== 'Stack' && type !== 'RowStack' && !isMinMajSorted) {
      formattedItemValues = orderBy(formattedItemValues, ['value'])
    } else {
      const itemValuesGroupedByColors = groupBy(formattedItemValues, 'color')
      formattedItemValues = [
        ...(itemValuesGroupedByColors[Colors.GREY300] ?? []),
        ...(itemValuesGroupedByColors[Colors.GREY200] ?? []),
        ...(itemValuesGroupedByColors[Colors.GREY100] ?? []),
        ...(itemValuesGroupedByColors[REPORTS_BLUE] ?? []),
        ...(itemValuesGroupedByColors[Colors.PURPLE300] ?? []),
        ...(itemValuesGroupedByColors[Colors.PURPLE400] ?? []),
        ...(itemValuesGroupedByColors[Colors.PURPLE500] ?? []),
        ...(itemValuesGroupedByColors[Colors.PURPLE] ?? []),
      ].reverse()
    }

    return { ...item, values: formattedItemValues }
  }
  const { key, value, label, color, tooltip, total, max } = item
  const tooltipComponent =
    tooltip ||
    (value && max && total ? (
      <Trans
        i18nKey="dashboard.diversity.makeup.tooltip"
        components={[<strong />]}
        values={{ completed: max, count: value }}
      />
    ) : undefined)

  const comparedValue = (total && total * 100) || value

  return {
    label,
    values: [
      {
        key,
        value: comparedValue,
        color:
          color || colorRules?.find(({ max }) => comparedValue < max)?.color,
        label,
        tooltip: tooltipComponent,
      },
    ],
  } as VisualizationItemProps
}

export function convertIntoVisualizationItem(
  items: any[],
  type: 'Bar' | 'Comparison' | 'Row' | 'Stack' | 'RowStack',
  benchmark?: VisualizationBenchmarkProps,
  colorRules: ColorRule[] = COLOR_RULES_DEFAULT,
  isMinMajSorted = false
): VisualizationItemProps[] {
  const visualizationItems = []

  const companyScore = items?.find(({ key }) => key === 'companyScore')

  if (companyScore) {
    visualizationItems.push(
      convertItem(companyScore, colorRules, type, isMinMajSorted)
    )
  }

  const formattedItems =
    items
      ?.filter(({ key }) => key !== 'companyScore')
      .map(item => convertItem(item, colorRules, type, isMinMajSorted)) ?? []
  visualizationItems.push(
    ...(type === 'Stack' ||
    type === 'RowStack' ||
    formattedItems?.[0]?.values?.length > 1
      ? formattedItems
      : orderBy(formattedItems, item => item.values?.[0].value, ['asc']))
  )

  return [
    ...(benchmark
      ? [
          {
            label: benchmark.label,
            isTransparent: true,
            values: [
              {
                key: 'benchmark',
                label: benchmark.label,
                value: benchmark.value * 100,
                color: 'transparent',
              } as VisualizationItemValueProps,
            ],
          },
        ]
      : []),
    ...visualizationItems,
  ]
}
