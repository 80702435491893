import React, { FunctionComponent } from 'react'

import { sortBy } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { baseColors, Colors, Text } from '@fairhq/common'
import { Visualization } from 'components/visualization/Visualization'
import { useGetDemGroupsQuery } from 'store/surveyWindow/surveyWindowApi'
import { SurveyWindow } from 'store/surveyWindow/types'

const Container = styled.div`
  border-radius: 20px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 3%), 0 2px 6px rgba(0, 0, 0, 4%);
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 20px 20px 0 0;
  padding: 32px 24px;
  background-color: ${baseColors.white};
  overflow: hidden;
`

const Body = styled.div`
  background-color: ${baseColors.grey100};
  border-radius: 0 0 20px 20px;
  padding: 32px 80px;
`

interface CompanyMakeUpProps {
  latestSurveyWindow: SurveyWindow
  level?: string
}

export const CompanyMakeUp: FunctionComponent<CompanyMakeUpProps> = ({
  latestSurveyWindow,
  level,
}) => {
  const { t } = useTranslation()

  const { data, isFetching } = useGetDemGroupsQuery({
    surveyWindowId: latestSurveyWindow.id,
    level,
  })

  if (!isFetching && data && data.length > 0) {
    return (
      <Container>
        <Header>
          <Text element="h3" type="body" size="xl" fontWeight={700}>
            {t('dashboard.diversity.companyMakeUp')}
          </Text>
          <Text element="p" type="body" size="xs" fontWeight={400}>
            {t('diversity.companyMakeup.info')}
          </Text>
        </Header>
        <Body>
          <Visualization
            type="Row"
            items={
              sortBy(
                data?.map(({ demGroupCode, count, rate }) => ({
                  key: demGroupCode + Math.random(),
                  label: t(demGroupCode),
                  color: Colors.PURPLE,
                  value: Math.round(+rate * 100),
                  tooltip: (
                    <Trans
                      i18nKey="dashboard.diversity.makeup.tooltip"
                      components={[<strong />]}
                      values={{
                        completed: latestSurveyWindow.totalCompleted,
                        count,
                      }}
                    />
                  ),
                })),
                ['value']
              ) as any
            }
          />
        </Body>
      </Container>
    )
  }
  return null
}
