import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'

import { NotionPage } from 'store/notion/types'

import { OldCompanyRecommendation, TaskOldRecommendation } from './types'

const oldRecommendationsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getOldRecommendation: build.query<
      TaskOldRecommendation,
      { code: string; sessionId: SessionId }
    >({
      query: ({ code, sessionId }) => ({
        url: `old-recommendations/${code}`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getOldRecommendations: build.query<
      OldCompanyRecommendation[],
      { sessionId: SessionId }
    >({
      query: ({ sessionId }) => ({
        url: `old-recommendations`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.OLD_RECOMMENDATIONS],
    }),
    getOldRecommendationNotionPage: build.query<
      NotionPage,
      { pageId: string; sessionId: SessionId }
    >({
      query: ({ pageId, sessionId }) => ({
        url: `notion/${pageId}`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const {
  useGetOldRecommendationQuery,
  useGetOldRecommendationNotionPageQuery,
  useGetOldRecommendationsQuery,
} = oldRecommendationsApi
