import { FunctionComponent } from 'react'

import { shallowEqual } from 'react-redux'

import { Navigate } from 'react-router'

import { Spinner } from '@fairhq/common'
import { EmptySidebar } from 'components/EmptySidebar'
import { NoAccess } from 'components/noAccess/NoAccess'
import Assessments from 'features/assessments/Assessments'
import { Layout } from 'layout/Layout'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export const Audit: FunctionComponent = () => {
  const { companyId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )

  const {
    data: companyEntitlements,
    isLoading,
    isSuccess,
  } = useGetEntitlementsQuery(undefined, {
    skip: !companyId,
  })

  const getComponent = (entitlements: EntitlementsEnum[]) => {
    if (entitlements.includes(EntitlementsEnum.ADVANCED_ASSESSMENT_AUDIT)) {
      return <Assessments />
    }
    if (entitlements.includes(EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT)) {
      return <Navigate to="/survey" />
    }

    if (entitlements.includes(EntitlementsEnum.EMPLOYEES)) {
      return <Navigate to="/employees" />
    }

    return <NoAccess />
  }
  return (
    <>
      {isLoading && (
        <Layout
          sidebar={<EmptySidebar />}
          dark
          contentSx={{
            paddingTop: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Layout>
      )}
      {isSuccess && getComponent(companyEntitlements.entitlements)}
    </>
  )
}
