import { round } from 'lodash'

export const decimalFormat = (number: number | string | null | undefined) => {
  if (number === undefined || number === null) {
    return ''
  }
  const parsedNumber = typeof number === 'string' ? +number : number

  return round(parsedNumber / 10, 1)
}

export const markedNumberFormat = (number?: number | null) => {
  if (number === null || number === undefined || number === 0) {
    return '-'
  }

  const symbol = number > 0 ? '+' : '-'

  return `${symbol}${Math.abs(number)}`
}
