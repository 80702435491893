import { forwardRef } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import { Box, Input as BaseInput } from '@theme-ui/components'

import styled from 'styled-components'

import { baseColors } from '../../primitives/colors'
import { space } from '../../primitives/space'
import { ArrowCaret } from '../icons/ArrowCaret'

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  margin-top: -12px;
  pointer-events: none;
  color: ${baseColors.grey300};
`

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 16px;
  pointer-events: none;
  color: ${baseColors.grey300};
`

export interface InputProps {
  onClick?: () => void
  value?: string | number | readonly string[]
  placeholder?: string
  name?: string
  icon?: JSX.Element
  showArrow?: boolean
  fieldId: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onClick,
      value,
      placeholder,
      name,
      icon,
      showArrow,
      fieldId,
      onChange,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => (
    <Box
      sx={{
        position: 'relative',
        borderColor: baseColors.grey200,
      }}
      onClick={onClick}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      <BaseInput
        ref={ref}
        name={name}
        value={value}
        placeholder={placeholder}
        sx={{
          pl: icon && `${space[7] - 1}px`,
          '&:focus': {
            borderColor: baseColors.purple,
          },
        }}
        id={fieldId}
        onChange={onChange}
      />
      {showArrow && (
        <ArrowContainer>
          <ArrowCaret />
        </ArrowContainer>
      )}
    </Box>
  )
)
