import { FunctionComponent } from 'react'

import { EmptySidebar } from 'components/EmptySidebar'

import { QueryStatus } from 'store/types'

import { useReportsSidebarGenAndSub } from './hooks/useReportsSidebarGenAndSub'
import { MenuGroups } from './MenuGroups'

export const ReportsSidebarGeneralSubAndGroups: FunctionComponent = () => {
  const { sidebarItems, status } = useReportsSidebarGenAndSub()

  if (status === QueryStatus.SUCCESS) {
    return <MenuGroups items={sidebarItems} />
  }

  return <EmptySidebar />
}
