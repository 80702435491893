import { FunctionComponent } from 'react'

import { shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { baseColors, space, Spinner } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import i18n from 'i18n'
import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import { diffWithNow } from 'utils/dates'

import { MIN_SURVEY_COMPLETION } from 'utils/minSurveyCompletion'

import { AuditInProgress } from './AuditInProgress'
import { ReadyForNewSurvey } from './ReadyForNewSurvey'
import { StartAudit } from './StartAudit'
import { SurveyCompleted } from './SurveyCompleted'
import { SurveyInProgress } from './SurveyInProgress'

const Container = styled.div`
  background-color: ${baseColors.white};
  border-radius: 20px;
  padding: ${space[10]}px;
  height: fit-content;
`

const SpinnerContainer = styled(Container)`
  display: flex;
  justify-content: center;
`

export const StatusOverview: FunctionComponent = () => {
  const { latestSession, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      latestSession: state.companyReducer.latestSession,
      apiVersion: state.companyReducer.latestSession?.apiVersion,
      sessionId: state.companyReducer.latestSession?.id,
    }),
    shallowEqual
  )

  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  const { data: account } = useGetAccountQuery({ apiVersion })

  const daysBeforeNewSession = diffWithNow(
    i18n.language,
    latestSession?.endDate
  )

  const reportsUnlocked = account && isScorable
  const showStatusOverview = !reportsUnlocked || daysBeforeNewSession <= 30

  const { auditQueryStatus, assessmentPercentage, surveyPercentage } =
    useAuditBy(latestSession?.id, latestSession?.apiVersion)

  const getContent = () => {
    if (daysBeforeNewSession <= 30) {
      return <ReadyForNewSurvey />
    }
    if (assessmentPercentage === 0) {
      return surveyPercentage === 0 ? <StartAudit /> : <AuditInProgress />
    }
    if (assessmentPercentage > 0 && assessmentPercentage < 100) {
      return <AuditInProgress />
    }
    if (assessmentPercentage === 100) {
      return surveyPercentage >= MIN_SURVEY_COMPLETION ? (
        <SurveyCompleted />
      ) : (
        <SurveyInProgress />
      )
    }
    return null
  }
  const content = getContent()

  if (!showStatusOverview) {
    return null
  }

  if (auditQueryStatus === QueryStatus.SUCCESS) {
    return <Container>{content}</Container>
  }

  if (
    auditQueryStatus === QueryStatus.LOADING ||
    auditQueryStatus === QueryStatus.IDLE
  ) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  return null
}
