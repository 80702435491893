import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Assessment: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M3 0.5C2.33696 0.5 1.70107 0.763392 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3V11C0.5 11.663 0.763392 12.2989 1.23223 12.7678C1.70107 13.2366 2.33696 13.5 3 13.5H5C5.27614 13.5 5.5 13.2761 5.5 13C5.5 12.7239 5.27614 12.5 5 12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H10.3333C10.7312 1.5 11.1127 1.65804 11.394 1.93934C11.6753 2.22064 11.8333 2.60218 11.8333 3V5.66667C11.8333 5.94281 12.0572 6.16667 12.3333 6.16667C12.6095 6.16667 12.8333 5.94281 12.8333 5.66667V3C12.8333 2.33696 12.5699 1.70107 12.1011 1.23223C11.6323 0.763392 10.9964 0.5 10.3333 0.5H3Z"
      fill={outline}
    />
    <path
      d="M3.16667 4.33333C3.16667 4.05719 3.39052 3.83333 3.66667 3.83333H9.66667C9.94281 3.83333 10.1667 4.05719 10.1667 4.33333C10.1667 4.60948 9.94281 4.83333 9.66667 4.83333H3.66667C3.39052 4.83333 3.16667 4.60948 3.16667 4.33333Z"
      fill={outline}
    />
    <path
      d="M3.16667 7C3.16667 6.72386 3.39052 6.5 3.66667 6.5H7C7.27614 6.5 7.5 6.72386 7.5 7C7.5 7.27614 7.27614 7.5 7 7.5H3.66667C3.39052 7.5 3.16667 7.27614 3.16667 7Z"
      fill={outline}
    />
    <path
      d="M3.66667 9.16667C3.39052 9.16667 3.16667 9.39052 3.16667 9.66667C3.16667 9.94281 3.39052 10.1667 3.66667 10.1667H5C5.27614 10.1667 5.5 9.94281 5.5 9.66667C5.5 9.39052 5.27614 9.16667 5 9.16667H3.66667Z"
      fill={outline}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.36433 7.49535C9.63029 7.28258 9.96075 7.16667 10.3013 7.16667C10.6419 7.16667 10.9724 7.28258 11.2384 7.49535L12.937 8.85422C13.1127 8.99475 13.2545 9.17299 13.3519 9.37575C13.4494 9.57851 13.5 9.8006 13.5 10.0256V12.3333C13.5 12.6427 13.3771 12.9395 13.1583 13.1583C12.9395 13.3771 12.6428 13.5 12.3333 13.5H8.33333C8.02392 13.5 7.72717 13.3771 7.50838 13.1583C7.28958 12.9395 7.16667 12.6427 7.16667 12.3333V9.97435C7.16667 9.74939 7.21727 9.5273 7.31473 9.32454C7.41218 9.12179 7.55399 8.94355 7.72966 8.80303L9.36433 7.49535ZM10.3013 8.16667C10.1878 8.16667 10.0777 8.20531 9.98901 8.27623L8.35434 9.58391C8.29578 9.63075 8.24851 9.69017 8.21602 9.75776C8.18354 9.82534 8.16667 9.89936 8.16667 9.97435V12.3333C8.16667 12.3775 8.18423 12.4199 8.21548 12.4512C8.24674 12.4824 8.28913 12.5 8.33333 12.5H12.3333C12.3775 12.5 12.4199 12.4824 12.4512 12.4512C12.4824 12.4199 12.5 12.3775 12.5 12.3333V10.0256C12.5 9.95057 12.4831 9.87654 12.4506 9.80895C12.4182 9.74137 12.3709 9.68195 12.3123 9.63511L10.6137 8.27623C10.525 8.20531 10.4149 8.16667 10.3013 8.16667Z"
      fill={outline}
    />
  </svg>
)
