import { FunctionComponent, useState } from 'react'

import { alpha } from '@theme-ui/color'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import { makeTransition } from '@fairhq/common'
import { useDropdown } from 'hooks/useDropdown'

import { updateOldRecommendation } from 'store/company/companySlice'
import { useAppDispatch } from 'store/hooks'
import {
  OldCompanyRecommendationStatus,
  oldRecommendationStatusColor,
  oldRecommendationStatusIcon,
  oldRecommendationStatusLabel,
  oldRecommendationStatusList,
} from 'store/oldRecommendations/types'
import Icon from 'ui-kit/Icon'

const buttonStyles: Record<
  OldCompanyRecommendationStatus | string,
  ThemeUIStyleObject
> = {
  [OldCompanyRecommendationStatus.RECOMMENDED]: {
    bg: alpha(
      oldRecommendationStatusColor[OldCompanyRecommendationStatus.RECOMMENDED],
      0.15
    ),
    color:
      oldRecommendationStatusColor[OldCompanyRecommendationStatus.RECOMMENDED],
  },
  [OldCompanyRecommendationStatus.PLANNED]: {
    bg: alpha('blue', 0.15),
    color: 'blue',
  },
  [OldCompanyRecommendationStatus.WORKING_ON]: {
    bg: alpha('yellow', 0.15),
    color: 'yellow',
  },
  [OldCompanyRecommendationStatus.IMPLEMENTED]: {
    bg: alpha('green', 0.15),
    color: 'green',
  },
}

interface StatusPickerProps {
  data: OldCompanyRecommendationStatus | string
  sx?: ThemeUIStyleObject
  oldRecommendationId: number
}

export const StatusPicker: FunctionComponent<StatusPickerProps> = ({
  data,
  sx,
  oldRecommendationId,
}) => {
  const { t } = useTranslation()
  const { ref, opened, toggleDropdown, closeDropdown } = useDropdown()
  const dispatch = useAppDispatch()
  const [selectedStatus, setSelectedStatus] = useState<
    OldCompanyRecommendationStatus | string
  >(data)

  return (
    <Flex
      ref={ref}
      sx={{
        position: 'relative',
        height: 48,
        px: 2,
        minWidth: 210,
        alignItems: 'center',
        borderRadius: 8,
        userSelect: 'none',
        cursor: 'pointer',
        ...buttonStyles[selectedStatus],
        ...sx,
      }}
      onClick={toggleDropdown}
    >
      <Icon
        name={oldRecommendationStatusIcon[selectedStatus]}
        sx={{ mr: 1 }}
        color="currentColor"
      />
      <Text as="div" variant="caps" sx={{ flex: '1 1 auto' }}>
        {oldRecommendationStatusLabel(t)[selectedStatus]}
      </Text>
      <Icon name="arrowCarret" sx={{ ml: 2 }} color="currentColor" small />

      {opened && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1000,
            top: '100%',
            left: 0,
            width: '100%',
            mt: 1,
            boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
            bg: 'white',
            color: 'grey500',
            borderRadius: 8,
            overflow: 'hidden',
          }}
        >
          {oldRecommendationStatusList.map(status => (
            <Flex
              key={status}
              sx={{
                px: 2,
                py: 1,
                transition: makeTransition('background'),
                '&:hover': { bg: 'grey100' },
              }}
              onClick={() => {
                dispatch(
                  updateOldRecommendation({
                    id: oldRecommendationId,
                    oldRecommendation: { status },
                  })
                )
                setSelectedStatus(status)
                closeDropdown()
              }}
            >
              <Text
                as="div"
                sx={{ fontWeight: status === selectedStatus ? '700' : '' }}
              >
                {oldRecommendationStatusLabel(t)[status]}
              </Text>
            </Flex>
          ))}
        </Box>
      )}
    </Flex>
  )
}
