import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import { ThemeUIStyleObject } from 'theme-ui'

import { space, makeTransition } from '@fairhq/common'

import { useAuthRoles } from 'hooks/useAuthRoles'

import useSignOut from '../../hooks/use-sign-out'
import Sidebar from '../../layout/Sidebar'
import Icon, { IconName } from '../../ui-kit/Icon'
import RouterLink from '../../ui-kit/RouterLink'

const Separator: FunctionComponent<{ sx?: ThemeUIStyleObject }> = ({ sx }) => (
  <Box sx={{ mt: 1, py: 1, pl: 7, pr: 4, ...sx }}>
    <Box sx={{ bg: 'grey200', height: 1 }} />
  </Box>
)

const MenuItem: FunctionComponent<{
  text: string
  url: string
  icon?: IconName
}> = ({ text, url, icon }) => {
  const { pathname } = useLocation()
  const isActive = pathname.includes(url)

  return (
    <RouterLink to={url}>
      <Flex
        sx={{
          alignItems: 'center',
          minHeight: 48,
          pl: `${space[7] - 3}px`,
          pr: 4,
          py: 1,
          color: isActive ? 'purple' : 'grey500',
          borderLeft: '3px solid',
          bg: isActive ? 'grey100' : 'transparent',
          borderColor: isActive ? 'purple' : 'transparent',
          transition: [
            makeTransition('background'),
            makeTransition('border'),
            makeTransition('color'),
          ].join(),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        {icon ? <Icon name={icon} sx={{ mr: 2 }} /> : null}
        <Text as="div" variant="label">
          {text}
        </Text>
      </Flex>
    </RouterLink>
  )
}

type TNavItem = { key: string; label: string; icon: IconName; path: string }

const navItems: TNavItem[] = [
  {
    key: 'account',
    label: 'nav.settings.account',
    icon: 'customer',
    path: '/settings/account',
  },
  {
    key: 'team',
    label: 'nav.settings.team',
    icon: 'team',
    path: '/settings/team',
  },
  {
    key: 'company',
    label: 'nav.settings.company',
    icon: 'company',
    path: '/settings/company',
  },
]
const ownerOrAdminOnlyItems: TNavItem[] = [
  {
    key: 'billing',
    label: 'nav.settings.billing',
    icon: 'paymentMethod',
    path: '/settings/billing',
  },
  {
    key: 'assessment',
    label: 'nav.settings.assessment',
    icon: 'assessment',
    path: '/settings/assessment',
  },
]

export const SettingsSidebar: FunctionComponent = () => {
  const { t } = useTranslation()
  const signOut = useSignOut()

  const { isOwner, isAdmin } = useAuthRoles()

  let displayedItems = navItems.slice()

  if (isOwner || isAdmin) {
    displayedItems = displayedItems.concat(ownerOrAdminOnlyItems)
  }

  return (
    <Sidebar sx={{ py: 6 }}>
      {displayedItems.map(item => (
        <MenuItem
          key={item.key}
          text={t(item.label)}
          url={item.path}
          icon={item.icon}
        />
      ))}
      <Separator />
      <Flex
        sx={{
          alignItems: 'center',
          minHeight: 48,
          pl: 7,
          pr: 4,
          py: 1,
          color: 'grey500',
          transition: makeTransition('color'),
          cursor: 'pointer',
          '&:hover': {
            color: 'purple',
          },
        }}
        onClick={signOut}
      >
        <Icon name="logOut" sx={{ mr: 2 }} />
        <Text as="div" variant="label">
          {t('nav.signOut')}
        </Text>
      </Flex>
    </Sidebar>
  )
}
