import { FunctionComponent } from 'react'

import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import { Text } from 'theme-ui'

import { Employee } from 'features/employees/types'
import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import Button from 'ui-kit/Button'
import { TableCell, TableRow } from 'ui-kit/Table'
import { toDate } from 'utils/dates'

interface EmployeeRowProps {
  employee: Employee
  setSelectedEmployees: (e: Employee[]) => void
  selectedEmployees: Employee[]
  setEmployeeToEdit: (e: Employee) => void
  showSurveyColumn?: boolean
}

export const EmployeeRow: FunctionComponent<EmployeeRowProps> = ({
  employee,
  setSelectedEmployees,
  selectedEmployees,
  setEmployeeToEdit,
  showSurveyColumn = false,
}) => {
  const { t } = useTranslation()

  const { data: sessions } = useGetSessionsQuery()
  const latestSession = sessions?.[0]

  const onClick = () => {
    if (selectedEmployees.find(item => item.id === employee.id)) {
      setSelectedEmployees(
        selectedEmployees.filter(item => item.id !== employee.id)
      )
    } else {
      setSelectedEmployees([...selectedEmployees, employee])
    }
  }

  const getTag = ({ activated, createdAt, invited }: Employee) => {
    const isInactive = !activated
    const isNew =
      !invited &&
      latestSession &&
      createdAt &&
      createdAt >= latestSession?.start_date

    if (isInactive || isNew) {
      return (
        <Text
          variant="caps"
          sx={{
            display: 'inline-block',
            ml: 1,
            color: isNew ? 'purple' : 'grey300',
          }}
        >
          {isNew ? t('new') : t('inactive')}
        </Text>
      )
    }

    return null
  }

  return (
    <TableRow
      key={employee.id}
      sx={{
        color: employee.activated ? 'grey500' : 'grey300',
        cursor: 'pointer',
        ':hover': { bg: 'grey100' },
      }}
      onClick={onClick}
    >
      <TableCell sx={{ paddingRight: 0 }}>
        <Button
          icon={
            selectedEmployees.find(item => item.id === employee.id)
              ? 'checkboxChecked'
              : 'checkbox'
          }
          variant="link"
          aria-label={t('survey.selectEmployee')}
          readOnly
          sx={{
            cursor: 'pointer',
            svg: {
              opacity: 1,
              margin: 0,
              fill: !selectedEmployees.find(item => item.id === employee.id)
                ? 'none'
                : '',
            },
          }}
        />
      </TableCell>
      <TableCell sx={{ fontWeight: 700 }}>
        {[employee.lastName, employee.firstName].join(', ')}
        {getTag(employee)}
      </TableCell>
      <TableCell>{employee.email}</TableCell>
      <TableCell>{employee.level}</TableCell>
      {showSurveyColumn && (
        <TableCell>
          {employee.invited
            ? toDate(i18next.language, employee.invited)?.format('ll')
            : undefined}
        </TableCell>
      )}
      <TableCell>
        <Button
          variant="link"
          icon="edit"
          aria-label={t('employees.sendToSelected')}
          onClick={(e: any) => {
            e.stopPropagation()
            setEmployeeToEdit(employee)
          }}
          sx={{ position: 'relative' }}
        />
      </TableCell>
    </TableRow>
  )
}
