import { FunctionComponent } from 'react'

import { Survey } from 'features/survey/Survey'
import { Layout } from 'layout/Layout'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementsEnum } from 'store/company/types'

import { AssessmentSidebar } from '../assessments/AssessmentSidebar'

import { SurveyWindow } from './SurveyWindow'

export const Surveys: FunctionComponent = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  return (
    <Layout dark maxContentWidth={1080} sidebar={<AssessmentSidebar />}>
      {companyEntitlements?.entitlements.includes(
        EntitlementsEnum.EMPLOYEE_SURVEY_AUDIT
      ) && <Survey />}

      {companyEntitlements?.entitlements.includes(EntitlementsEnum.SURVEYS) && (
        <SurveyWindow />
      )}
    </Layout>
  )
}
