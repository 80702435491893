import React, { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { Box, Flex, Text as ThemeUIText } from 'theme-ui'

import { Chevron, baseColors, makeTransition } from '@fairhq/common'

import { useDropdown } from 'hooks/useDropdown'
import { useGetStrategyQuery } from 'store/strategy/strategyApi'
import {
  useEditTaskMutation,
  useGetTaskStatusOptionsQuery,
} from 'store/tasks/tasksApi'
import { Task, TaskStatus } from 'store/tasks/types'
import { getTaskStatusColors } from 'utils/getTaskStatusColor'

const StyledChevron = styled(Chevron)`
  margin-left: 16px;
`

interface StatusDropdownProps {
  status: TaskStatus
  task: Task
  setSkipTask: () => void
  setCompleteTask: () => void
  setCurrentstatus: (s: TaskStatus) => void
  parentId?: number
}

export const StatusDropdown: FunctionComponent<StatusDropdownProps> = ({
  status,
  task,
  setSkipTask,
  setCompleteTask,
  setCurrentstatus,
  parentId = 0,
}) => {
  const { t } = useTranslation()

  const { data: statusOptions, isSuccess } = useGetTaskStatusOptionsQuery()
  const { data: strategy, refetch: refetchStrategy } = useGetStrategyQuery()
  const [editTask, { isSuccess: isEditTaskSuccess }] = useEditTaskMutation()

  const { id: taskId } = task

  const {
    ref,
    opened: isDropdownOpen,
    toggleDropdown,
    closeDropdown,
    openDropdown,
  } = useDropdown()

  useEffect(() => {
    if (isDropdownOpen) {
      const element = document.getElementById(`options_list_0`)

      if (element) {
        element.focus({ preventScroll: true })
      }
    }
  }, [isDropdownOpen])

  useEffect(() => {
    if (isEditTaskSuccess) {
      if (status === TaskStatus.SKIPPED) {
        setSkipTask()
      } else if (status === TaskStatus.COMPLETED && task.linkedQuestionCode) {
        setCompleteTask()
      } else {
        refetchStrategy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditTaskSuccess, status, refetchStrategy])

  const handleClick = async ({
    option,
    taskId,
  }: {
    option: TaskStatus
    taskId: number
  }) => {
    setCurrentstatus(option)
    await editTask({
      id: taskId,
      status: option,
      sessionId: strategy?.sessionId,
    })
    handleCloseDropdown()
  }

  const handleKeyDownSelect = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      openDropdown()
    }

    if (isDropdownOpen && e.key === 'Escape') {
      handleCloseDropdown()
    }
  }
  const handleKeyDownOption = (
    e: React.KeyboardEvent<HTMLDivElement>,
    option: TaskStatus,
    index: number
  ) => {
    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault()
      const sibling = document.getElementById(`options_list_${index + 1}`)
      if (sibling) {
        sibling.focus()
      }
    }

    if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault()
      const sibling = document.getElementById(`options_list_${index - 1}`)
      if (sibling) {
        sibling.focus()
      }
    }

    if (e.key === 'Enter') {
      handleClick({ option, taskId })
    }
  }

  const handleCloseDropdown = () => {
    closeDropdown()
    const element = document.getElementById(
      `status_dropdown_${parentId}_${taskId}`
    )
    if (element) {
      element.focus()
    }
  }

  return (
    <>
      {isSuccess && statusOptions.length > 0 && (
        <Flex
          tabIndex={0}
          ref={ref}
          id={`status_dropdown_${parentId}_${taskId}`}
          sx={{
            position: 'relative',
            height: 48,
            px: '16px',
            alignItems: 'center',
            borderRadius: 12,
            userSelect: 'none',
            cursor: 'pointer',
            svg: { mr: 1 },
            ...getTaskStatusColors(status),
            flexShrink: 0,
            ':focus-visible': {
              outline: `2px solid ${baseColors.purple}`,
              outlineOffset: '2px',
              borderRadius: '8px',
            },
          }}
          onClick={toggleDropdown}
          onKeyDown={handleKeyDownSelect}
          role="button"
          aria-haspopup="true"
          aria-expanded={isDropdownOpen}
        >
          <ThemeUIText as="p" variant="caps" sx={{ width: '90px' }}>
            {t(`tasks.status.${status}`)}
          </ThemeUIText>
          <StyledChevron />
          {isDropdownOpen && (
            <Box
              tabIndex={0}
              as="ul"
              id={`options_list_${parentId}_${taskId}`}
              sx={{
                position: 'absolute',
                zIndex: 1000,
                top: '100%',
                left: 0,
                width: '100%',
                mt: 1,
                boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
                bg: 'white',
                borderRadius: 8,
                overflow: 'hidden',
                p: 0,
              }}
              role="listbox"
              aria-multiselectable="false"
              aria-label="task status options list"
            >
              {statusOptions?.map((option, index) => (
                <Flex
                  as="li"
                  tabIndex={0}
                  id={`options_list_${index}`}
                  className={option}
                  key={option}
                  sx={{
                    px: 2,
                    py: 1,
                    transition: makeTransition('background'),
                    '&:hover': { bg: 'grey100' },
                    color: baseColors.grey500,
                    border: `2px solid transparent`,
                    ':focus-visible': {
                      border: `2px solid ${baseColors.purple}`,
                      borderRadius: '8px',
                      outline: 'none',
                    },
                  }}
                  onKeyDown={e => {
                    handleKeyDownOption(e, option, index)
                  }}
                  onClick={() => handleClick({ option, taskId })}
                  role="option"
                  aria-selected={status === option}
                >
                  <ThemeUIText
                    as="div"
                    sx={{ fontWeight: option === status ? '700' : '' }}
                  >
                    {t(`tasks.status.${option}`)}
                  </ThemeUIText>
                </Flex>
              ))}
            </Box>
          )}
        </Flex>
      )}
    </>
  )
}
