import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { startOfTomorrow, isWeekend } from 'date-fns'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'

import {
  space,
  baseColors,
  DatePicker,
  TimePicker,
  Spinner,
} from '@fairhq/common'

import { useGetCompanySlackChannelsQuery } from 'features/campaigns/campaignsApi'
import { SlackChannel } from 'features/campaigns/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import Button from 'ui-kit/Button'
import WhiteBox from 'ui-kit/WhiteBox'

import { ChannelPicker } from '../Campaign.ChannelPicker'
import { GenericTiming } from '../Campaign.GenericTiming'

dayjs.extend(utc)
dayjs.extend(isBetween)

const InputsContainer = styled.div`
  display: flex;
  margin-bottom: ${space[6]}px;

  > * {
    width: 50%;
    flex-grow: 1;

    &:first-child {
      margin-right: ${space[4]}px;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const todayWithHour = (hour: number) =>
  dayjs.utc().date(dayjs().date()).hour(hour).minute(0).second(0)

const filterWorkingHoursUtc = (time: Date) => {
  // We are only comparing times, so we're setting the day to today for all dates
  const earliestAllowedTime = todayWithHour(8)
  const latestAllowedTime = todayWithHour(17)
  return dayjs(time)
    .date(dayjs().date())
    .isBetween(earliestAllowedTime, latestAllowedTime, 'hour', '[]')
}

export interface DateModalProps {
  modalTitleKey: string
  titleKey: string
  descriptionKey: string
  cancelKey: string
  confirmKey: string
  errorKey: string
  onConfirm: () => void
  isLoading: boolean
  isError: boolean
  onClose: () => void
  startDate: Date
  onStartDateChange: (date: Date | null) => void
  publishTime: Date
  onPublishTimeChange: (date: Date | null) => void
  totalNudges: number | undefined
  frequency: number | undefined
  channel: SlackChannel | undefined
  onChannelChange: (channel: SlackChannel) => void
}

export const DateModal: FunctionComponent<DateModalProps> = ({
  modalTitleKey,
  titleKey,
  descriptionKey,
  cancelKey,
  confirmKey,
  errorKey,
  onConfirm,
  isLoading,
  isError,
  onClose,
  startDate,
  onStartDateChange,
  publishTime,
  onPublishTimeChange,
  totalNudges,
  frequency,
  channel,
  onChannelChange,
}) => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const {
    data: slackChannels,
    isLoading: isLoadingChannels,
    isError: isErrorChannels,
    isSuccess: isSuccessChannels,
  } = useGetCompanySlackChannelsQuery({ sessionId })

  return (
    <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
      <WhiteBox
        sx={{
          width: '100%',
          padding: '30px 60px 48px',
        }}
      >
        <Text as="div" variant="subtitle" sx={{ textAlign: 'center', mb: 8 }}>
          {t(modalTitleKey)}
        </Text>
        <Text as="div" variant="h3" sx={{ mb: 1 }}>
          {t(titleKey)}
        </Text>
        <Text as="div" sx={{ mb: 2 }}>
          {t(descriptionKey)}
        </Text>
        {totalNudges !== undefined && frequency !== undefined && (
          <GenericTiming totalNudges={totalNudges} frequency={frequency} />
        )}
        {isLoadingChannels && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {isErrorChannels && (
          <ErrorContainer>
            <Text
              as="div"
              variant="label"
              sx={{ color: baseColors.red, fontWeight: 'bold', mt: 4 }}
            >
              {t('campaigns.edit.error')}
            </Text>
          </ErrorContainer>
        )}

        {isSuccessChannels && (
          <>
            <ChannelPicker
              channel={channel}
              onChannelChange={onChannelChange}
              slackChannels={slackChannels}
            />

            <InputsContainer>
              <div>
                <DatePicker
                  value={startDate}
                  onChange={onStartDateChange}
                  minDate={startOfTomorrow()}
                  filterDate={date => !isWeekend(date)}
                  id="date"
                  label={t('campaigns.schedule.startDate')}
                />
                <Text
                  as="div"
                  variant="label"
                  sx={{
                    mb: 1,
                    mt: 1,
                    fontSize: 13,
                    color: baseColors.grey400,
                  }}
                >
                  {t('campaigns.schedule.startDateDescription')}
                </Text>
              </div>
              <div>
                <TimePicker
                  value={publishTime}
                  onChange={onPublishTimeChange}
                  timeIntervalsMinutes={60}
                  filterTime={filterWorkingHoursUtc}
                  id="time"
                  label={t('campaigns.schedule.startTime')}
                />
                <Text
                  as="div"
                  variant="label"
                  sx={{
                    mb: 1,
                    mt: 1,
                    fontSize: 13,
                    color: baseColors.grey400,
                  }}
                >
                  {t('campaigns.schedule.startTimeDescription')}
                </Text>
              </div>
            </InputsContainer>
            <ButtonsContainer>
              <Button variant="secondary" sx={{ mr: 4 }} onClick={onClose}>
                {t(cancelKey)}
              </Button>
              <Button onClick={onConfirm} loading={isLoading}>
                {t(confirmKey)}
              </Button>
            </ButtonsContainer>
            {isError && (
              <ErrorContainer>
                <Text
                  as="div"
                  variant="label"
                  sx={{ color: baseColors.red, fontWeight: 'bold', mt: 4 }}
                >
                  {t(errorKey)}
                </Text>
              </ErrorContainer>
            )}
          </>
        )}
      </WhiteBox>
    </Modal>
  )
}
