import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { chain, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { Tabs } from 'components/Tabs'
import { COLOR_RULES_GAPS } from 'components/visualization/constants'
import { GraphItem } from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import BorderBox from 'ui-kit/BorderBox'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'

import { useReportsParams } from '../hooks/useReportsParams'
import { getScore } from '../utils/getScore'

interface GroupScoreBreakdownProps {
  area: string
}

export const GroupScoreBreakdown: FunctionComponent<
  GroupScoreBreakdownProps
> = ({ area }) => {
  const { t } = useTranslation()
  const { code: rawCode, type } = useReportsParams()
  const code = customDecodeURIComponent(rawCode)

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: typeReports,
    isLoading: isTypeReportsLoading,
    isError: isTypesReportsError,
    isSuccess: isTypeReportsSuccess,
  } = useGetReportsQuery({ type, apiVersion, sessionId })
  const groups =
    typeReports?.experience?.filter(({ _group }: any) => _group === code) ?? []
  const {
    data: demGroupsReports,
    isLoading: isDemGroupsLoading,
    isError: isDemGroupsError,
    isSuccess: isDemGroupsSuccess,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })
  const general = demGroupsReports?.experienceGeneral ?? []

  const generalByArea = chain(general).keyBy('parent_area_code').value()

  const items: Record<string, GraphItem[]> = {
    score:
      Object.keys(generalByArea)
        ?.filter(parentAreaCode => parentAreaCode?.includes(area))
        .map(subAreaCode => ({
          key: subAreaCode,
          value: +getScore(groups, subAreaCode),
          label: t(
            `${subAreaCode}.title`,
            generalByArea?.[subAreaCode]?.parent_area_title
          ),
        })) ?? [],
    comparison:
      Object.keys(generalByArea)
        ?.filter(parentAreaCode => parentAreaCode?.includes(area))
        .map(subAreaCode => ({
          key: subAreaCode,
          value:
            +getScore(groups, subAreaCode) - +getScore(general, subAreaCode),
          label: t(
            `${subAreaCode}.title`,
            generalByArea?.[subAreaCode]?.parent_area_title
          ),
        })) ?? [],
  }

  const [activeTab, setActiveTab] = React.useState('score')
  return (
    <StateHandler
      isError={isTypesReportsError || isDemGroupsError}
      isSuccess={isTypeReportsSuccess && isDemGroupsSuccess}
      isLoading={isTypeReportsLoading || isDemGroupsLoading}
      errorMessage={t('promoteCampaigns.error.title')}
    >
      <BorderBox mb={9} sx={{ py: 5 }}>
        <Flex
          sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
        >
          <Text as="div" variant="bodyLargeBold">
            {t(`reports.${area}.group.experience.title`)}
          </Text>
          <Tabs
            tabs={
              !code
                ? [
                    {
                      key: 'score',
                      label: t('reports.group.score'),
                      length: items?.score?.length ?? 0,
                    },
                  ]
                : []
            }
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Flex>
        <Visualization
          type={
            items?.[activeTab].length < 4 || items?.[activeTab].length > 10
              ? 'Row'
              : 'Bar'
          }
          items={sortBy(items?.[activeTab] || [], ['value']) as any}
          isScoreBy10={activeTab !== 'comparison'}
          colorRules={activeTab === 'comparison' ? COLOR_RULES_GAPS : undefined}
        />
      </BorderBox>
    </StateHandler>
  )
}
