import { Container } from 'rsuite'
import styled from 'styled-components'

export const MessageBoxContainer = styled(Container)`
  max-width: 800px;
  margin: auto;
  padding-top: 60px;
  flex-grow: 1;
  align-self: center;
  width: 100%;

  h3 {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    margin: 30px 0;
  }

  .rs-btn-toolbar {
    display: flex;
    margin-bottom: 36px;
    justify-content: space-between;

    .rs-btn {
      margin: 0;
    }
  }

  p {
    justify-content: center;
    text-align: center;
  }
`
