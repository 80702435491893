import { useEffect } from 'react'

import { useAppSelector } from 'store/hooks'
import { useCheckGoogleUserHasBeenInvitedQuery } from 'store/signUp/signUpApi'
import { State } from 'store/state'

export const useCheckGoogleUserIsInvited = () => {
  const isGoogleAuth = useAppSelector(
    (state: State) =>
      state.authReducer.user?.sub?.includes('google') ||
      state.authReducer.user?.user_id?.includes('google')
  )
  const { data: isInvitedGoogleUser } = useCheckGoogleUserHasBeenInvitedQuery()

  useEffect(() => {
    if (isInvitedGoogleUser && isGoogleAuth) {
      window.location.reload()
    }
  }, [isGoogleAuth, isInvitedGoogleUser])

  return isInvitedGoogleUser
}
