import { Colors } from '@fairhq/common'

import { ColorGetterFn } from '../types'

export const colorGetter: (
  great?: number,
  medium?: number,
  gaps?: boolean
) => ColorGetterFn =
  (great = 75, medium = 50, gaps = false) =>
  ({ value, max }) => {
    if (value === undefined || value === null) {
      return null
    }

    const newValue = max ? (value * 100) / max : value

    if (gaps) {
      if (newValue < medium) {
        return Colors.RED
      }
      if (newValue < great) {
        return Colors.YELLOW
      }
      return Colors.GREEN
    }

    if (newValue >= great) {
      return Colors.GREEN
    }
    if (newValue >= medium) {
      return Colors.YELLOW
    }
    return Colors.RED
  }
