import React, { FunctionComponent } from 'react'

import { Box, Grid } from '@theme-ui/components'
import { chain } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { Practices } from './Practices'
import { ScoreHeader } from './ScoreHeader'

interface ReportsPracticeCriteriaProps {
  code: string
}

export const ReportsPracticeCriteria: FunctionComponent<
  ReportsPracticeCriteriaProps
> = ({ code }) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: generalData,
    isLoading,
    isError,
    isSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'general',
    apiVersion,
    sessionId,
  })

  const practiceCriteria = generalData?.practiceCriteria
  const processAndDocuments = generalData?.processAndDocuments

  const { process } = chain(processAndDocuments).keyBy('_type').value()
  // eslint-disable-next-line no-unsafe-optional-chaining
  const practiceScore = (process?.score ?? 0) / process?.area_max

  const critical =
    practiceCriteria?.filter?.(({ critical: isCritical }: any) => isCritical) ??
    []
  const good =
    practiceCriteria?.filter?.(
      ({ critical: isCritical }: any) => !isCritical
    ) ?? []
  const score = +(practiceScore * 10)

  const getRaw = ({ title, code: key, checked }: any) => ({
    key,
    checked,
    content: tCode(t)(code, '', title),
  })

  if (practiceCriteria?.length === 0) {
    return null
  }

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <ScoreHeader
        title={t('reports.practice.title')}
        score={score}
        icon="settings"
      />
      <Grid columns={2} gap={4} mb={9}>
        <Box sx={{ borderRadius: 20, bg: 'white', px: 6, py: 5 }}>
          <Practices
            data={critical.map(getRaw)}
            title={t('reports.practice.criticalFoundation')}
          />
        </Box>

        <Box sx={{ borderRadius: 20, bg: 'white', px: 6, py: 5 }}>
          <Practices
            data={good.map(getRaw)}
            title={t('reports.practice.goodPractice')}
          />
        </Box>
      </Grid>
    </StateHandler>
  )
}
