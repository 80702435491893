import { shallowEqual } from 'react-redux'

import { useAuthRoles } from 'hooks/useAuthRoles'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export const useCanOverrideMaintenance = () => {
  const { isAdmin } = useAuthRoles()

  const { apiVersion } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )
  const { data: account } = useGetAccountQuery({ apiVersion })
  const accountIds = account?.companies?.map(({ accountId }) => accountId) ?? []

  const rawAllowedCompanies = process.env.REACT_APP_MAINTENANCE_ALLOWED_ACCOUNTS

  const allowedCompanies: number[] = rawAllowedCompanies
    ? rawAllowedCompanies
        .split(',')
        .map(stringCompanyId => parseInt(stringCompanyId, 10))
    : []

  const isAllowedAccount = !!allowedCompanies.find(companyId =>
    accountIds.includes(companyId)
  )
  return isAdmin || isAllowedAccount
}
