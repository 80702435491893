import { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { BaseConfirmModal } from 'components/BaseConfirmModal'

import { Employee } from '../types'

interface ConfirmSendSelectedModalProps {
  onClose: () => void
  selectedEmployees: Employee[]
  onSubmit: () => void
}
export const ConfirmSendSelectedModal: FunctionComponent<
  ConfirmSendSelectedModalProps
> = ({ onClose, selectedEmployees, onSubmit }) => {
  const { t } = useTranslation()
  const hasEmployeesSelected = selectedEmployees.length > 0

  return (
    <BaseConfirmModal
      onClose={onClose}
      onSubmit={onSubmit}
      title={
        hasEmployeesSelected
          ? t('employees.allSelected.title')
          : t('employees.noneSelected.title')
      }
      body={
        hasEmployeesSelected ? (
          <Trans
            i18nKey="employees.allSelected.body"
            values={{
              name: selectedEmployees
                .map(employee => employee.email)
                .join(', '),
            }}
          />
        ) : (
          t('employees.noneSelected.body')
        )
      }
      withSingleButton={!hasEmployeesSelected}
      confirmCustomText={
        hasEmployeesSelected
          ? t('employees.allSelected.confirm')
          : t('employees.noneSelected.confirm')
      }
    />
  )
}
