import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

import { CompanyInsightWithoutAreas } from 'features/insights/types'
import { Insight } from 'features/strategy/Insight'

import headerImageBlue from '../strategy/svgs/strategyHeaderBlue.svg'

const Container = styled.section`
  background-color: ${baseColors.purple800};
  border-radius: 20px 20px 0 0;
  padding: 32px 40px;
  color: ${baseColors.white};
  background-image: url('${headerImageBlue}');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  gap: 60px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
`

const LeftBox = styled.div`
  min-width: 30%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

const ChallengeContainer = styled.div`
  border-radius: 0 0 20px 20px;
  padding: 32px 40px;
  display: flex;
  gap: 60px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 12%), 0 2px 8px rgba(0, 0, 0, 6%);
  background-color: ${baseColors.grey100};
  margin-bottom: 24px;
`

const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

interface EditStrategyIntroProps {
  companyInsights: CompanyInsightWithoutAreas[]
}
export const EditStrategyIntro: FunctionComponent<EditStrategyIntroProps> = ({
  companyInsights,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <Container>
        <LeftBox>
          <StyledText element="p" fontWeight={700} type="body" size="xs">
            {t('strategy.header.label')}
          </StyledText>
          <Text element="h1" fontWeight={500} type="heading" size="xl">
            {t('strategy.header.title')}
          </Text>
        </LeftBox>
        <Text element="p" fontWeight={400} type="body" size="sm">
          {t('strategy.header.description')}
        </Text>
      </Container>
      <ChallengeContainer>
        <LeftBox>
          <StyledText element="p" fontWeight={700} type="body" size="xs">
            {t('strategy.draft.stepTwoIntroLabel')}
          </StyledText>
          <Text element="h1" fontWeight={500} type="heading" size="xl">
            {t('strategy.draft.stepTwoIntroTitle')}
          </Text>
        </LeftBox>
        <List>
          {companyInsights.map(companyInsight => (
            <Insight
              key={companyInsight.id}
              title={companyInsight.insight.description}
            />
          ))}
        </List>
      </ChallengeContainer>
    </div>
  )
}
