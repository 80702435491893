import { AxiosRequestConfig } from 'axios'

import { http } from 'utils/http'

export const documentApi = {
  getAllDocumentType(getState: () => unknown) {
    return http.get(getState, 'document-types')
  },
  getDocuments(getState: () => unknown) {
    return http.get(getState, 'documents')
  },
  reviewDocuments(
    getState: () => unknown,
    body: { sessionId: number; areaId?: number }
  ) {
    return http.post(getState, `documents/review`, body)
  },
  submitDocument(
    getState: () => unknown,
    data: FormData,
    config: AxiosRequestConfig = {}
  ) {
    return http.postWithAxios(getState, 'documents', data, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      } as any,
    })
  },
  updateDocument(
    getState: () => unknown,
    id: number,
    data: FormData,
    config: AxiosRequestConfig = {}
  ) {
    return http.putWithAxios(getState, `documents/${id}`, data, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      } as any,
    })
  },
  deleteDocument(getState: () => unknown, id = '') {
    return http.delete(getState, `documents/${id}`)
  },
  getStatus(getState: () => unknown, code: string) {
    return http.get(getState, `documents/status/${code}`)
  },
  removeUpload(getState: () => unknown, documentId: number, id: number) {
    return http.delete(getState, `documents/${documentId}/links/${id}`)
  },
}
