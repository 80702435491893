import { useGetExperienceQuestionsQuery } from 'store/surveyWindow/surveyWindowApi'
import { ExperienceQuestion } from 'store/surveyWindow/types'

function findClosestToZero(
  surveyWindowQuations: ExperienceQuestion[],
  count = 3
) {
  return surveyWindowQuations
    .filter(
      item =>
        item.companyScore >= 0.6 && Math.abs(item.minorityMajorityGap) <= 0.02
    )
    .sort(
      (a, b) =>
        Math.abs(a.minorityMajorityGap) - Math.abs(b.minorityMajorityGap)
    ) // Sort by absolute value
    .slice(0, count)
}

export const useFairExperience = (latestSurveyWindowId: number) => {
  const { data, isSuccess } = useGetExperienceQuestionsQuery({
    surveyWindowId: latestSurveyWindowId,
  })

  if (data && data.length > 0) {
    return { data: findClosestToZero(data), isSuccess }
  }
  return { data: [], isSuccess }
}
