import { flattenDeep } from 'lodash'

import { Assessment } from '../../assessment/types'

export const getAnsweredOptionsCode = (assessments: Assessment[] = []) =>
  flattenDeep(
    assessments?.map(({ assessmentOptions }) =>
      assessmentOptions
        .filter(({ option }) => option?.code)
        .map(({ option }) => option?.code)
    )
  )
