import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import ProgressBox from 'ui-kit/ProgressBox'

import { tCode } from 'utils/tCode'

import { Dashbox, SpanProp } from './Dashbox'
import { DashboxHeading } from './DashboxHeading'

interface AreasProps {
  scoreAreas: any[]
  span: SpanProp
  title: string
}

export const Areas: FunctionComponent<AreasProps> = ({
  scoreAreas,
  span,
  title,
}) => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  return (
    <Dashbox span={span}>
      <DashboxHeading heading={title} />

      {scoreAreas.map(
        ({ score, max, area_code: code, title: area_title }, i) => {
          const scoreBy10 = (score * 10) / max ?? 0
          const color =
            scoreBy10 >= 7.5 ? 'green' : (scoreBy10 >= 5 && 'yellow') || 'red'
          return (
            <ProgressBox
              key={code}
              sx={{
                borderTop: th => `1px solid ${th.colors?.grey200}`,
                borderRadius:
                  i === scoreAreas.length - 1 ? '0 0 19px 19px' : '',
              }}
              progressValue={Math.round(score)}
              progressMax={max}
              progressColor={color}
            >
              <Flex sx={{ alignItems: 'center', py: 1, px: 8, minHeight: 78 }}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    bg: color,
                    mr: 2,
                    borderRadius: '50%',
                  }}
                />
                <Text as="div" sx={{ flex: 1, color: 'grey500' }}>
                  {tArea(code, 'title', area_title ?? code)}
                </Text>
                <Flex>
                  <Text
                    as="div"
                    variant="bodyLargeBold"
                    sx={{ color: 'grey500', fontSize: '18px' }}
                  >
                    {Math.round(score)}
                  </Text>
                  <Text
                    as="div"
                    variant="bodyLargeBold"
                    sx={{ color: 'grey300' }}
                  >
                    &nbsp;/ {Math.round(max)}
                  </Text>
                </Flex>
              </Flex>
            </ProgressBox>
          )
        }
      )}
    </Dashbox>
  )
}
