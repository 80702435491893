import { FunctionComponent } from 'react'

interface CloseProps {
  className?: string
  fill?: string
  width?: string
  height?: string
}

export const Close: FunctionComponent<CloseProps> = ({
  className,
  fill = 'currentColor',
  width = '24px',
  height = '24px',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={fill}
  >
    <path d="M20.8479 19.4379C20.9426 19.5318 20.9958 19.6596 20.9958 19.7929C20.9958 19.9262 20.9426 20.054 20.8479 20.1479L20.1479 20.8479C20.054 20.9426 19.9262 20.9958 19.7929 20.9958C19.6596 20.9958 19.5318 20.9426 19.4379 20.8479L11.9979 13.4079L4.5579 20.8479C4.46402 20.9426 4.33622 20.9958 4.2029 20.9958C4.06958 20.9958 3.94178 20.9426 3.8479 20.8479L3.1479 20.1479C3.05324 20.054 3 19.9262 3 19.7929C3 19.6596 3.05324 19.5318 3.1479 19.4379L10.5879 11.9979L3.1479 4.5579C3.05324 4.46402 3 4.33622 3 4.2029C3 4.06958 3.05324 3.94178 3.1479 3.8479L3.8479 3.1479C3.94178 3.05324 4.06958 3 4.2029 3C4.33622 3 4.46402 3.05324 4.5579 3.1479L11.9979 10.5879L19.4379 3.1479C19.5318 3.05324 19.6596 3 19.7929 3C19.9262 3 20.054 3.05324 20.1479 3.1479L20.8479 3.8479C20.9426 3.94178 20.9958 4.06958 20.9958 4.2029C20.9958 4.33622 20.9426 4.46402 20.8479 4.5579L13.4079 11.9979L20.8479 19.4379Z" />
  </svg>
)
