import { useEffect } from 'react'

import { shallowEqual } from 'react-redux'

import { useParams } from 'react-router-dom'

import { getQuestionsByArea } from 'store/area/areaSlice'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

export const useQuestions = () => {
  const dispatch = useAppDispatch()
  const { code = '' } = useParams<{ code: string }>()
  const { questions, loading, areaCode } = useAppSelector(
    (state: State) => ({
      questions: state.areaReducer.questions?.[code] ?? [],
      loading: state.areaReducer.questionsLoading,
      areaCode: state.areaReducer.area?.code,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (code === areaCode && !loading && questions?.length === 0) {
      dispatch(getQuestionsByArea(code))
    } else if (questions.length > 0) {
      dispatch(questionSessionActions.setQuestionsAfterGet(questions))
    }
  }, [code, dispatch, loading, questions.length, areaCode, questions])
}
