import { Container } from 'rsuite'
import styled from 'styled-components'

export const InfoBoxContent = styled.div<{ small?: boolean }>`
  flex: 1;
  max-width: ${({ small }) => (small ? '600px' : '800px')};

  h3 {
    margin-bottom: 10px;
    font-size: 18px !important;
    line-height: 1.67 !important;
  }

  p {
    margin-bottom: 0;
    max-width: 600px;
  }
`

export const InfoBoxHeaderContainer = styled(Container)`
  flex-flow: row;

  svg {
    min-width: 120px;
    margin-right: 36px;
  }
`

export const InfoBoxContainer = styled(Container)`
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 62px;

  .rs-btn-toolbar {
    display: flex;
    flex-flow: column;

    .rs-btn {
      margin-bottom: 22px;
    }

    .rs-btn.rs-btn-link {
      padding: 0;
      margin-bottom: 0;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      color: ${({ theme }) => theme.colors.grey400};
    }

    p {
      justify-content: flex-end;

      &[type='welcome'] {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
      }

      &[type='indication'] {
        margin: 0;
      }
    }

    svg {
      margin-left: 12px;
    }
  }
`
