import React, { FunctionComponent, useState } from 'react'

import { Trans } from 'react-i18next'

import {
  useCloseSurveyWindowMutation,
  useGetSurveyWindowQuery,
} from 'store/surveyWindow/surveyWindowApi'

import { SurveyWindow } from 'store/surveyWindow/types'

import { ConfirmCloseModal } from './ConfirmCloseModal'
import { PromptCard } from './PromptCard'

interface SurveyWindowCloseProps {
  currentSurveyWindow: SurveyWindow
}

export const SurveyWindowClose: FunctionComponent<SurveyWindowCloseProps> = ({
  currentSurveyWindow,
}) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const { isFetching: isFetchingCurrent } = useGetSurveyWindowQuery()

  const onClick = () => {
    setShowConfirm(true)
  }

  const [
    closeSurveyWindow,
    { isLoading: isLoadingClose, isError: isErrorClose },
  ] = useCloseSurveyWindowMutation()

  const onSubmit = () => {
    closeSurveyWindow({ id: currentSurveyWindow.id })
    setShowConfirm(false)
  }

  const onClose = () => {
    setShowConfirm(false)
  }

  return (
    <>
      <PromptCard
        title="surveys.close.title"
        progress={{
          max: currentSurveyWindow.totalInvited,
          value: currentSurveyWindow.totalCompleted,
        }}
        description="surveys.close.description"
        buttonText="surveys.close.button"
        isLoading={isFetchingCurrent || isLoadingClose}
        onClick={onClick}
        guidance={<Trans i18nKey="surveys.close.guidance" />}
        error={isErrorClose}
      />
      {showConfirm && (
        <ConfirmCloseModal onSubmit={onSubmit} onClose={onClose} />
      )}
    </>
  )
}
