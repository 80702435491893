import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { Link } from 'react-router-dom'

import { makeTransition, Colors } from '@fairhq/common'
import Icon, { IconName } from 'ui-kit/Icon'

interface StrategyMenuItemProps {
  text: string
  icon: IconName
  url: string
  active?: boolean
  onClick?: () => void
}

export const OldStrategyMenuItem: FunctionComponent<StrategyMenuItemProps> = ({
  text,
  icon,
  url,
  active,
  onClick,
}) => (
  <Link to={active ? '' : url} onClick={() => onClick?.()}>
    <Flex
      sx={{
        position: 'relative',
        px: 7,
        height: 54,
        alignItems: 'center',
        color: active ? 'purple' : 'grey500',
        transition: makeTransition('color'),
        bg: active ? 'grey100' : 'white',
        '&:hover': { color: 'purple' },
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          width: 3,
          height: '100%',
          bg: 'purple',
          display: active ? 'block' : 'none',
        },
      }}
    >
      <Icon
        name={icon}
        sx={{ mr: 2 }}
        color={active ? Colors.PURPLE : undefined}
      />
      <Text as="div" sx={{ fontWeight: 400 }}>
        {text}
      </Text>
    </Flex>
  </Link>
)
