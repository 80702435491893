import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import progressBarLine from './progressBarLine.svg'

const getLineAtPercentageStyles = (
  lineAtPercentage: number | undefined
): any | undefined => {
  if (!lineAtPercentage) {
    return {}
  }
  return {
    '&:after': {
      content: "''",
      position: 'absolute',
      top: -1,
      right: `${100 - lineAtPercentage}%`,
      width: 2,
      height: 24,
      backgroundImage: `url('${progressBarLine}')`,
    },
  }
}

interface ProgressBarProps {
  value: number
  max?: number
  color?: string
  className?: string
  lineAtPercentage?: number
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  value,
  max = 100,
  color = 'green',
  className,
  lineAtPercentage,
}) => (
  <Box
    sx={{
      position: 'relative',
      height: 12,
      borderRadius: 6,
      bg: 'grey100',
      ...getLineAtPercentageStyles(lineAtPercentage),
    }}
    className={className}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        bg: color,
        width: `${Math.min(1, value / max) * 100}%`,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      }}
    />
  </Box>
)
