import { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'

import { baseColors, Megaphone } from '@fairhq/common'

export const ScheduledCampaignIcon: FunctionComponent = () => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px',
      minWidth: '24px',
      padding: '4px',
      background: baseColors.purple200,
      borderRadius: '8px',
      marginRight: '12px',
    }}
  >
    <Megaphone width="16px" outline={baseColors.purple} />
  </Flex>
)
