export enum ScheduledCampaignStatus {
  PENDING = 'pending',
  LIVE = 'live',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export interface Campaign {
  id: number
  code: string
  theme: {
    code: string
    color: string
    image: string
  }
  frequency: number
  totalNudges: number
}

export interface ScheduledCampaign {
  scheduledCampaignId: number
  campaign: Campaign
  status: ScheduledCampaignStatus
  createdAt: string
  startDate: string
  owner: string
  publishTime: string
  channel: SlackChannel
  nudgesSent: number
  nudgesToSend: number
  nextNudgeOn: string
}

export interface SlackChannel {
  id: string
  name: string
  isPrivate: boolean
}

export enum EditCampaignActions {
  EDIT_DATE = 'EDIT_DATE',
  CANCEL = 'CANCEL',
}
