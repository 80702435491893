import React, { FunctionComponent } from 'react'

import { Arrow } from '@fairhq/common'

import { SquareIconBackground } from './SquareIconBackground'

export const ArrowWithBackground: FunctionComponent = () => (
  <SquareIconBackground>
    <Arrow />
  </SquareIconBackground>
)
