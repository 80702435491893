import { FunctionComponent, useEffect, useState } from 'react'

import { Flex, Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

import { Layout } from 'layout/Layout'
import { requestChangePasswordEmail } from 'store/account/accountSlice'
import { State } from 'store/state'
import Field from 'ui-kit/Field'
import { Separator } from 'ui-kit/Separator'
import { rolesOptions } from 'utils/rolesOptions'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { SettingsSidebar } from './SettingsSidebar'

export const AccountSettings: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showEmailSent, setShowEmailSent] = useState<boolean>(false)

  const { user, loading, emailSent } = useAppSelector(
    (state: State) => ({
      user: state.authReducer.user,
      loading: state.accountReducer.sending,
      emailSent: state.accountReducer.emailSent,
    }),
    shallowEqual
  )

  const role = rolesOptions.find(r => r.value === user?.user_metadata?.role)

  useEffect(() => {
    if (!loading && emailSent) {
      setShowEmailSent(true)
      setTimeout(() => {
        setShowEmailSent(false)
      }, 10000)
    }
  }, [emailSent, loading])

  return (
    <Layout sidebar={<SettingsSidebar />} dark maxContentWidth={660}>
      <Heading variant="subtitle" sx={{ mb: 10 }}>
        {t('settings.account')}
      </Heading>

      <Separator />

      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
      >
        <Heading as="h3" variant="h3">
          {t('settings.personal')}
        </Heading>
        <Link to="/settings/account/personal">
          <Text as="div" variant="capsLink">
            {t('settings.changeDetails')}
          </Text>
        </Link>
      </Flex>
      {!!user && (
        <>
          <Field
            label={t('form.fullName.label')}
            value={user.name}
            sx={{ mb: 3 }}
            inline
            textValue
          />
          <Field
            label={t('form.email.label')}
            value={user.email}
            sx={{ mb: 3 }}
            inline
            textValue
          />
          <Field
            label={t('form.role.label')}
            value={
              role?.value === 'other'
                ? user?.user_metadata?.role_other
                : (t(role ? role.label : '') as string)
            }
            sx={{ mb: 3 }}
            inline
            textValue
          />
        </>
      )}

      <Separator />

      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
      >
        <Heading as="h3" variant="h3">
          {t('settings.password')}
        </Heading>
        {!!user && user.email && (
          <Text
            as="div"
            variant="capsLink"
            onClick={() => {
              if (!loading && user?.sub) {
                dispatch(requestChangePasswordEmail(user.sub))
              }
            }}
          >
            {t('settings.resetPassword')}
          </Text>
        )}
      </Flex>

      <Field
        label={t('form.currentPassword.label')}
        value="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
        inline
        textValue
      />
      {showEmailSent && emailSent && (
        <Text as="div" sx={{ mt: 4, color: 'green' }}>
          {t('forgotPassword.emailSent')}
        </Text>
      )}

      <Separator />
    </Layout>
  )
}
