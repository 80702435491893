import { FunctionComponent } from 'react'

import { Container } from '../components/Container'
import { VisualizationComponentProps } from '../types'
import { getMaximumGap } from '../utils/getMaximumGap'

import { BarVisualizationItem } from './BarVisualizationItem'

export const Bar: FunctionComponent<VisualizationComponentProps> = ({
  items,
  benchmark,
  isLegendVisible,
  isScoreBy10,
  isGaps,
  legend,
}) => (
  <Container
    benchmark={benchmark}
    isLegendVisible={isLegendVisible}
    legend={legend}
    items={items}
  >
    {items.map(item => (
      <BarVisualizationItem
        key={item.label}
        item={item}
        isScoreBy10={isScoreBy10}
        isGaps={isGaps}
        maxGap={isGaps ? getMaximumGap(items) : undefined}
      />
    ))}
  </Container>
)
