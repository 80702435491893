import React, { FunctionComponent } from 'react'

import { format, isSameDay, isSameMonth } from 'date-fns'

import { useTranslation } from 'react-i18next'

import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Link, Text } from 'theme-ui'

import { baseColors, Colors } from '@fairhq/common'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementsEnum } from 'store/company/types'

import { Event as EventType } from './types'

const CardEventContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  width: 100%;
  flex-grow: 1;
`
const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DateContainer = styled.div`
  background-color: ${baseColors.purple100};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`

const ImageContainer = styled.div`
  align-self: center;
`

const Image = styled.img`
  width: 90px;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`
interface EventProps {
  event: EventType
}

export const Event: FunctionComponent<EventProps> = ({ event }) => {
  const { t } = useTranslation()

  const {
    code,
    startDate,
    endDate,
    isEntireMonth,
    image,
    articleUrl,
    campaignId,
  } = event

  const { data: companyEntitlements } = useGetEntitlementsQuery()

  const getDate = (startDate: Date, endDate: Date, isEntireMonth: boolean) => {
    if (isEntireMonth) {
      return format(startDate, 'MMMM')
    }
    if (isSameDay(startDate, endDate)) {
      return format(startDate, 'MMMM do')
    }
    const formateedEndDate = isSameMonth(startDate, endDate)
      ? format(endDate, 'do')
      : format(endDate, 'MMMM do')
    return `${format(startDate, 'MMMM do')} - ${formateedEndDate}`
  }

  const hasLinks = !!articleUrl || !!campaignId

  return (
    <CardEventContainer>
      <ImageContainer>
        <Image src={image} alt="Abstract image representing the event" />
      </ImageContainer>
      <ContentContainer>
        <DateContainer>
          <Text
            variant="body"
            sx={{ fontWeight: 600, color: Colors.PURPLE800 }}
          >
            {getDate(new Date(startDate), new Date(endDate), isEntireMonth)}
          </Text>
        </DateContainer>
        <Text
          variant="bodyLarge"
          sx={{
            lineHeight: '24px',
            fontWeight: 'bold',
            color: Colors.GREY500,
          }}
        >
          {t(`events.${code}.title`)}
        </Text>
        {hasLinks && (
          <LinksContainer>
            {!!articleUrl && (
              <Link href={articleUrl}>{t('events.links.article')}</Link>
            )}
            {campaignId &&
              companyEntitlements?.entitlements.includes(
                EntitlementsEnum.CAMPAIGNS
              ) && (
                <RouterLink
                  to={{
                    pathname: '/campaigns',
                    search: `selected=campaign-${campaignId}`,
                  }}
                >
                  {t('events.links.campaign')}
                </RouterLink>
              )}
          </LinksContainer>
        )}
      </ContentContainer>
    </CardEventContainer>
  )
}
