import { FunctionComponent } from 'react'

import { Box, Grid, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ThemeUIStyleObject } from 'theme-ui'

import { ReactComponent as Illustration } from 'assets/illustrations/employee-survey2.svg'
import Button from 'ui-kit/Button'
import Icon from 'ui-kit/Icon'
import WhiteBox from 'ui-kit/WhiteBox'

type SurveyIntroProps = {
  sx?: ThemeUIStyleObject
}

export const SurveyIntro: FunctionComponent<SurveyIntroProps> = ({ sx }) => {
  const { t } = useTranslation()

  const infoItems = [
    {
      title: t('survey.benchmark.title'),
      text: t('survey.benchmark.description'),
    },
    {
      title: t('survey.inclusionExperience.title'),
      text: t('survey.inclusionExperience.description'),
    },
    {
      title: t('survey.feedback.title'),
      text: t('survey.feedback.description'),
    },
  ]

  return (
    <WhiteBox sx={sx}>
      <Grid columns={2} gap={4}>
        <Box>
          <Text as="div" variant="subtitle" sx={{ mb: 4 }}>
            {t('survey.understandYourEmployees')}
          </Text>

          <Box as={Illustration} sx={{ mb: 2, ml: 8 }} />

          <Box>
            <Link to="/employees/add">
              <Button size="large" variant="primary" sx={{ mr: 2 }}>
                {t('survey.addEmployees')}
              </Button>
            </Link>
            {/* TODO <Button size="large" variant="secondary"> */}
            {/*  {t('survey.previewSurvey')} */}
            {/* </Button> */}
          </Box>
        </Box>

        <Box sx={{ bg: 'grey100', p: 8, borderRadius: 20 }}>
          {infoItems.map((item, i) => (
            <Box
              key={i}
              sx={{ position: 'relative', pl: 6, '& + &': { mt: 4 } }}
            >
              <Icon
                name="addMember"
                sx={{ position: 'absolute', top: 0, left: 0, fill: 'green' }}
              />
              <Text as="div" variant="bodyBold" sx={{ color: 'green', mb: 1 }}>
                {item.title}
              </Text>
              <Text as="div">{item.text}</Text>
            </Box>
          ))}
        </Box>
      </Grid>
    </WhiteBox>
  )
}
