import { FunctionComponent } from 'react'

import { Box, Flex } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Text } from 'theme-ui'

import { baseColors, Megaphone } from '@fairhq/common'
import { StateHandler } from 'components/StateHandler'
import { useGetSlackInstallStatusQuery } from 'features/campaigns/campaignsApi'
import { useScheduledCampaigns } from 'features/campaigns/hooks/useScheduledCampaigns'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { SlackbotInfo } from './SlackbotInfo'

export const PromoteCampaigns: FunctionComponent = () => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { isSuccess, isLoading, isError } = useGetSlackInstallStatusQuery({
    sessionId,
  })
  const scheduledCampaigns = useScheduledCampaigns()

  return (
    <Box
      sx={{ padding: '60px', backgroundColor: 'white', borderRadius: '20px' }}
    >
      <StateHandler
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
        errorMessage={t('promoteCampaigns.error.title')}
      >
        <Flex sx={{ marginBottom: '8px' }}>
          <Megaphone outline={baseColors.purple} />
          <Text
            variant="caps"
            sx={{
              color: baseColors.purple,
              fontSize: 14,
              marginLeft: '8px',
            }}
          >
            {t(`promoteCampaigns.title`)}
          </Text>
        </Flex>
        <SlackbotInfo scheduledCampaigns={scheduledCampaigns} />
      </StateHandler>
    </Box>
  )
}
