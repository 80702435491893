import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { Square } from 'components/Square'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import { useGetDemGroupsQuery } from 'store/surveyWindow/surveyWindowApi'
import { SurveyWindow } from 'store/surveyWindow/types'

import { KeyDemographic } from './KeyDemographic'

const Container = styled(WhiteBoxWithShadow)`
  flex-direction: column;
  padding: 40px;
  gap: 36px;
`

const CapsText = styled(Text)`
  text-transform: uppercase;
`

const Caption = styled.div`
  display: flex;
  gap: 8px;
`

const Captions = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`

interface KeyIndustryDemographicsProps {
  latestSurveyWindow: SurveyWindow
  previousSurveyWindow?: SurveyWindow
  level?: string
}
export const KeyIndustryDemographics: FunctionComponent<
  KeyIndustryDemographicsProps
> = ({ latestSurveyWindow, previousSurveyWindow, level }) => {
  const { t } = useTranslation()

  const {
    data: latestDemGroups,
    isSuccess: isLatestDemGroupsSuccess,
    isFetching,
  } = useGetDemGroupsQuery({
    surveyWindowId: latestSurveyWindow.id,
    level,
  })

  const { data: previousDemGroups } = useGetDemGroupsQuery(
    {
      surveyWindowId: previousSurveyWindow?.id,
      level,
    },
    { skip: !previousSurveyWindow?.id }
  )

  const keyDemographics = [
    { demGroupCode: 'dem_gender_womenandminorities', benchmark: 50 },
    { demGroupCode: 'dem_ethnicity_poc', benchmark: 30 },
    { demGroupCode: 'dem_SEB_low', benchmark: 60 },
  ]

  if (isLatestDemGroupsSuccess && !isFetching) {
    return (
      <Container>
        <CapsText element="h2" type="body" size="sm" fontWeight={700}>
          {t('diversity.keyDem.title')}
        </CapsText>
        {keyDemographics.map(({ demGroupCode, benchmark }) => (
          <KeyDemographic
            key={demGroupCode}
            demCode={demGroupCode}
            latestSurveyWindow={latestSurveyWindow}
            previousSurveyWindow={previousSurveyWindow}
            latestDem={latestDemGroups?.find(
              demGroups => demGroups.demGroupCode === demGroupCode
            )}
            previousDem={previousDemGroups?.find(
              demGroups => demGroups.demGroupCode === demGroupCode
            )}
            benchmark={benchmark}
          />
        ))}
        <Captions>
          {previousDemGroups && (
            <Caption>
              <Square
                backgroundColor={baseColors.grey300}
                borderColor={baseColors.grey400}
              />
              <Text element="p" type="body" size="sm" fontWeight={500}>
                {t(`diversity.keyDem.previousLabel`)}
              </Text>
            </Caption>
          )}

          <Caption>
            <Square
              backgroundColor={baseColors.purple800}
              borderColor={baseColors.purple800}
            />
            <Text element="p" type="body" size="sm" fontWeight={500}>
              {t(`diversity.keyDem.latestLabel`)}
            </Text>
          </Caption>
        </Captions>
      </Container>
    )
  }
  return null
}
