import { FunctionComponent } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { useTranslation, Trans } from 'react-i18next'

import styled from 'styled-components'

import { space } from '@fairhq/common'
import { SignUpLayout } from 'layout/SignUpLayout'

const Padding = styled.span`
  padding-bottom: ${space[5]}px;
  display: block;
`

export const Maintenance: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <SignUpLayout>
      <Heading variant="h1" pb={4} color="grey500">
        {t('maintenance.title')}
      </Heading>
      <Text as="div" variant="bodyLarge">
        <Trans i18nKey="maintenance.description" components={[<Padding />]} />
      </Text>
    </SignUpLayout>
  )
}
