import { FunctionComponent } from 'react'

import { Input, InputGroup } from 'rsuite'

export const InputAddon: FunctionComponent<any> = ({
  addon,
  ...restOfProps
}) => (
  <InputGroup>
    <InputGroup.Addon style={{ backgroundColor: 'transparent' }}>
      {addon}
    </InputGroup.Addon>
    <Input {...restOfProps} />
  </InputGroup>
)
