import { FunctionComponent } from 'react'

interface RoundTickProps {
  className?: string
  fill?: string
  width?: string
  height?: string
}

export const RoundTick: FunctionComponent<RoundTickProps> = ({
  className,
  fill = 'currentColor',
  width = '24px',
  height = '24px',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM10.73 15.35L16.35 9.73C16.5341 9.53685 16.5341 9.23315 16.35 9.04L15.82 8.51C15.6256 8.31943 15.3144 8.31943 15.12 8.51L10.38 13.25L8.88 11.76C8.78927 11.6633 8.66259 11.6085 8.53 11.6085C8.39741 11.6085 8.27073 11.6633 8.18 11.76L7.65 12.29C7.55534 12.3839 7.5021 12.5117 7.5021 12.645C7.5021 12.7783 7.55534 12.9061 7.65 13L10.03 15.35C10.1207 15.4467 10.2474 15.5015 10.38 15.5015C10.5126 15.5015 10.6393 15.4467 10.73 15.35Z"
    />
  </svg>
)
