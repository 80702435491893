import { FunctionComponent, useState } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { baseColors } from '@fairhq/common'
import { Tabs } from 'components/Tabs'
import { getTabs } from 'features/reportsAreasCore/utils/getTabs'
import { SurveyCompletion as ISurveyCompletion } from 'hooks/useCompletion'
import { ProgressBar } from 'ui-kit/ProgressBar'
import ProgressBox from 'ui-kit/ProgressBox'

import { getProgressColor } from 'utils/getProgressColor'

const Footer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: 16px;
`

interface SurveyCompletionProps {
  data: ISurveyCompletion
  opened: boolean
}

export const SurveyCompletion: FunctionComponent<SurveyCompletionProps> = ({
  data,
  opened,
}) => {
  const { t } = useTranslation()
  const graphItems = {
    byJobLevel: data?.byJobLevel,
    byLocation: data?.byLocation,
    byDepartment: data?.byDepartment,
  } as any
  const tabs = getTabs(t, graphItems)?.filter(
    ({ key }) =>
      key === 'byJobLevel' || key === 'byLocation' || key === 'byDepartment'
  )
  const [activeTab, setActiveTab] = useState('byJobLevel')
  const progressColor = getProgressColor(data.total)

  return (
    <Box sx={{ flex: '0 1 482px' }}>
      <Text
        as="div"
        variant="labelBold"
        sx={{ textAlign: 'right', color: 'grey300', mb: 3, mt: '2px' }}
      >
        {data.completed} of {data.max} employees
      </Text>

      <ProgressBar
        value={data.total}
        color={progressColor}
        lineAtPercentage={75}
      />

      {opened && (
        <Box sx={{ mt: 6 }}>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabSx={{ flex: `1 1 ${100 / tabs.length}%`, mb: 2 }}
          />

          {graphItems?.[activeTab]?.map(({ label, value }: any) => (
            <ProgressBox
              key={label}
              progressValue={value}
              progressColor={getProgressColor(value)}
              sx={{
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 12,
                '& + &': { mt: 1 },
              }}
            >
              <Flex
                sx={{
                  px: 5,
                  height: 60,
                  alignItems: 'center',
                }}
              >
                <Text
                  as="div"
                  variant="labelBold"
                  sx={{ flex: 1, color: 'grey500' }}
                >
                  {label}
                </Text>
                <Text
                  as="div"
                  variant="bodyBold"
                  sx={{ color: 'grey500' }}
                >{`${Math.round(value)}%`}</Text>
              </Flex>
            </ProgressBox>
          ))}
          {graphItems?.[activeTab]?.length > 0 && (
            <Footer>
              <Text variant="body" sx={{ color: baseColors.grey500 }}>
                {t(`reports.completionMax.${activeTab}`)}
              </Text>
            </Footer>
          )}
        </Box>
      )}
    </Box>
  )
}
