import { useState } from 'react'

export const useOpenable = (defaultOpen = false) => {
  const [opened, setOpened] = useState(defaultOpen)

  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const toggle = () => setOpened(!opened)

  return { opened, open, close, toggle }
}
