import { FunctionComponent } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { SignUpLayout } from 'layout/SignUpLayout'
import { RoundButton } from 'styled'
import { Message } from 'styled/message'

const Container = styled.div`
  max-width: 480px;
  width: 100%;
`

const Header = styled.h2`
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.4;
  white-space: pre;
`

export const NoActiveSubscription: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <SignUpLayout
      sources="
    https://files.fairhq.co/images/small/small_Not_found4x_7efb4420ac.png  300w,
    https://files.fairhq.co/images/medium/medium_Not_found4x_7efb4420ac.png  600w,
    https://files.fairhq.co/images/large/large_Not_found4x_7efb4420ac.png  1200w,
    https://files.fairhq.co/images/large/Not_found4x_7efb4420ac.png 2000w"
      sourcesWebp="https://files.fairhq.co/images/small/small_Not_found4x_7efb4420ac.webp  300w,
    https://files.fairhq.co/images/medium/medium_Not_found4x_7efb4420ac.webp  600w,
    https://files.fairhq.co/images/large/large_Not_found4x_7efb4420ac.webp  1200w,
    https://files.fairhq.co/images/large/Not_found4x_7efb4420ac.webp 2000w"
      picture="https://files.fairhq.co/images/origin/Not_found4x_7efb4420ac.png"
      notFound
    >
      <Container>
        <Header>
          <Trans i18nKey="setup.error.title" components={[<br />]} />
        </Header>
        <Message>{t('setup.error.description')}</Message>
        <RoundButton
          appearance="ghost"
          componentClass={NavLink}
          to="/account-setup"
        >
          {t('setup.error.button')}
        </RoundButton>
      </Container>
    </SignUpLayout>
  )
}
