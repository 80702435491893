import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'
import { Assessment } from 'store/assessment/types'
import { Question } from 'store/questionTypes'

import { LinkedQuestion, Task, TaskStatus, TaskWithResources } from './types'

const tasksApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getTasks: build.query<Task[], { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `/tasks`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getTaskStatusOptions: build.query<TaskStatus[], void>({
      query: () => ({
        url: `/tasks/status`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
    }),
    editTask: build.mutation<
      void,
      { id: number; status: string; sessionId: SessionId }
    >({
      query: ({ id, status, sessionId }) => ({
        url: `tasks/${id}`,
        method: 'PATCH',
        body: {
          status,
        },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      invalidatesTags: [TagTypes.TASK],
    }),
    getTasksFollowUpQuestions: build.query<
      Question[],
      { sessionId: SessionId }
    >({
      query: ({ sessionId }) => ({
        url: `/tasks/questions`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    postTaskAnswer: build.mutation<
      void,
      {
        questionId: number
        taskId: number
        optionId: number
        otherInput?: string
        sessionId: SessionId
      }
    >({
      query: ({ questionId, taskId, optionId, otherInput, sessionId }) => ({
        url: '/tasks/answer',
        method: 'POST',
        body: {
          questionId,
          taskId,
          other: otherInput,
          assessmentOptions: [
            {
              analysis: 0,
              score: -1,
              optionId,
            },
          ],
        },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getLinkedQuestion: build.query<
      LinkedQuestion,
      { code: string; sessionId: SessionId }
    >({
      query: ({ code, sessionId }) => ({
        url: `/assessments/${code}/group`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    saveAssessment: build.mutation<
      Assessment,
      { assessment: Partial<Assessment>; sessionId: SessionId }
    >({
      query: ({ assessment, sessionId }) => ({
        url: '/assessments',
        method: 'POST',
        body: { ...assessment },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    updateAssessment: build.mutation<
      Assessment,
      { id: string; assessment: Partial<Assessment>; sessionId: SessionId }
    >({
      query: ({ id, assessment, sessionId }) => ({
        url: `/assessments/${id}`,
        method: 'PATCH',
        body: { ...assessment },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getCompanyTask: build.query<TaskWithResources, { code: string }>({
      query: ({ code }) => ({
        url: `tasks/${code}`,
      }),
      providesTags: [TagTypes.TASK],
    }),
    isTaskLinkedToStrategy: build.query<boolean, { code: string }>({
      query: ({ code }) => ({
        url: `tasks/${code}/strategy`,
      }),
    }),
  }),
})

export const {
  useGetTasksQuery,
  useGetTaskStatusOptionsQuery,
  useEditTaskMutation,
  useGetTasksFollowUpQuestionsQuery,
  usePostTaskAnswerMutation,
  useGetLinkedQuestionQuery,
  useSaveAssessmentMutation,
  useUpdateAssessmentMutation,
  useGetCompanyTaskQuery,
  useIsTaskLinkedToStrategyQuery,
} = tasksApi
