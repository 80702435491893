import React, { FunctionComponent } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { space } from '@fairhq/common'

import { Campaign } from './compound/Campaign'
import { usePastCampaigns } from './hooks/usePastCampaigns'

const Container = styled.div`
  margin-bottom: ${space[10]}px;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: ${space[5]}px;
`

export const PastCampaigns: FunctionComponent = () => {
  const { t } = useTranslation()
  const pastCampaigns = usePastCampaigns()
  return pastCampaigns?.length > 0 ? (
    <Container>
      <HeadingContainer>
        <Heading sx={{ mr: 4 }}>{t('campaigns.past.title')} </Heading>
        <Text as="div" variant="label">
          {t('campaigns.past.description')}
        </Text>
      </HeadingContainer>
      {pastCampaigns.map(
        ({
          scheduledCampaignId,
          campaign: {
            code: campaignCode,
            theme: { code: themeCode, color },
            frequency,
            totalNudges,
          },
          owner,
          startDate,
          nudgesSent,
          status,
        }) => (
          <Campaign key={scheduledCampaignId}>
            <Campaign.LeftColumn>
              <Campaign.Content>
                <Campaign.Theme themeCode={themeCode} color={color} />
                <Campaign.Title campaignCode={campaignCode} />
                <Campaign.Footer>
                  <Campaign.User owner={owner} />
                  <Campaign.SpecificTiming
                    startDate={startDate}
                    frequency={frequency}
                  />
                </Campaign.Footer>
              </Campaign.Content>
            </Campaign.LeftColumn>
            <Campaign.Progress
              sent={nudgesSent}
              total={totalNudges}
              status={status}
            >
              <Campaign.Sent
                sent={nudgesSent}
                total={totalNudges}
                status={status}
              />
            </Campaign.Progress>
          </Campaign>
        )
      )}
    </Container>
  ) : null
}
