import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Navigate as Redirect } from 'react-router-dom'

import { Area } from 'store/area/types'
import { tCode } from 'utils/tCode'

import { MessageBox } from '../../components'

import areaIcons from '../areas/utils/icons'

export const Welcome: FunctionComponent<{
  completed: boolean
  onStart?: () => void
  area?: Partial<Area>
}> = ({ area, completed, onStart }) => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState('')
  const tArea = tCode(t)
  const { parent } = area ?? {}
  const tOptions = {
    name: tArea(parent?.code, 'title', parent?.title),
  }

  const messageBoxProps = {
    btnLabel: completed
      ? t('assessment.end.gotToAreaPage')
      : t('assessment.welcome.startAssessment'),
    description: completed
      ? tArea(
          area?.code,
          'description.completed',
          t('assessment.goodJobOnFinishingTheAssessment'),
          tOptions
        )
      : tArea(
          area?.code,
          'description.not.completed',
          t('assessment.welcome.description'),
          tOptions
        ),

    icon: area?.parent ? areaIcons[area?.parent?.code] : undefined,
    onClick: completed
      ? () => {
          setRedirect(`/audit/${parent?.code}`)
        }
      : onStart,
    title: completed
      ? tArea(
          area?.code,
          'title.completed',
          t('assessment.end.thanksForCompletingAssessment')
        )
      : tArea(area?.code, 'title.not.completed', t('assessment.welcome.title')),
    success: completed,
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return <MessageBox {...messageBoxProps} />
}
