import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const Round: FunctionComponent<IconProps> = ({
  className,
  outline = 'currentColor',
  stroke = '#d9d9d9',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
  >
    <circle
      cx="6"
      cy="6"
      r="4.5"
      fill={outline}
      stroke={stroke}
      strokeWidth="3"
    />
  </svg>
)
