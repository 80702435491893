import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useIntercom } from 'react-use-intercom'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

export const useDocumentProgress = () => {
  const { t } = useTranslation()
  const { update: updateIntercom } = useIntercom()
  const { documents, auditQueryStatus } = useAppSelector(
    (state: State) => ({
      documents: state.auditReducer.status?.documents ?? {
        completed: 0,
        missing: 0,
        pending: 0,
        percent: 0,
        rejected: 0,
        reviewed: 0,
        skipPercent: 0,
        submitted: 0,
        total: 0,
      },
      auditQueryStatus: state.auditReducer.auditQueryStatus,
    }),
    shallowEqual
  )
  const status = {
    title: `${Math.round(documents.percent)}%`,
    message: t('document.progress.completed', {
      total: documents.total,
      reviewed: documents.reviewed,
      uploaded: documents.completed - documents.missing,
      missing: documents.missing,
    }),
    type:
      documents.reviewed === documents.total
        ? 'completed'
        : (documents.submitted === documents.total && 'reviewing') || 'pending',
  }

  updateIntercom({
    customAttributes: {
      fairhq_documents_submitted: documents.submitted,
      fairhq_documents_reviewed: documents.reviewed,
      fairhq_documents_skipped: documents.missing,
      fairhq_documents_uploaded: documents.total - documents.missing,
    },
  })

  return { ...documents, status, queryStatus: auditQueryStatus }
}
