import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'

import { QueryStatus } from 'store/types'
import { tCode } from 'utils/tCode'

import { useReportsGroupsMenu } from './useReportsGroupsMenu'
import { useReportsMenu } from './useReportsMenu'

export interface ReportsSidebarSubMenuItem {
  text: string
  url: string
  code: string
  enabled?: boolean
}

export interface ReportsSidebarMenuGroup {
  text: string
  url: string
  baseUrl?: string
  enabled?: boolean
  items?: ReportsSidebarSubMenuItem[]
  code: string
}

export const useReportsSidebarGenAndSub = (): {
  sidebarItems: ReportsSidebarMenuGroup[]
  status: QueryStatus
} => {
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { menu, status } = useReportsMenu()
  const { groupsMenu, isLoading, isError } = useReportsGroupsMenu()

  if (
    isLoading ||
    isError ||
    status === QueryStatus.ERROR ||
    status === QueryStatus.LOADING
  ) {
    return {
      sidebarItems: [],
      status: isError ? QueryStatus.ERROR : QueryStatus.LOADING,
    }
  }

  const sidebarItems: ReportsSidebarMenuGroup[] = [...menu, ...groupsMenu]?.map(
    ({ code, items, url, baseUrl, sorted, enabled }: any) => ({
      code,
      enabled: enabled === undefined ? true : enabled,
      text: tArea(code, 'title', code),
      url: url || `/reports/${code}`,
      baseUrl,
      items: orderBy(
        items.map(({ code: subCode, title, text }: any) => ({
          code: subCode,
          text: text || tArea(subCode, 'title', title),
          url: `/reports/${code}/${encodeURIComponent(subCode)}`,
        })),
        sorted ? ['text'] : []
      ),
    })
  )

  return { sidebarItems, status: QueryStatus.SUCCESS }
}
