import React from 'react'

import Bugsnag from '@bugsnag/js'

import { config } from '@fairhq/common'
import 'styles.css'
import 'i18n'

export const ErrorBoundary =
  config.env !== 'local' && process.env.NODE_ENV === 'production'
    ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment
    : React.Fragment
