export const getScore = (data: any[], parentAreaCode: string) => {
  const dataFiltered =
    data?.filter(
      ({ parent_area_code }: any) => parent_area_code === parentAreaCode
    ) ?? []
  const reduce = (acc: number, score: number) => acc + score

  return (
    ((dataFiltered
      .map(({ score_calculation }: any) => score_calculation)
      .reduce(reduce, 0) ?? 0) *
      100) /
      (dataFiltered.map(({ area_max }: any) => area_max).reduce(reduce, 0) ??
        0 ??
        1) || 0
  )
}
