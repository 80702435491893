import { FunctionComponent } from 'react'

import { IconProps } from './types'

export const ArrowSquare: FunctionComponent<IconProps> = ({
  className,
  fill = 'currentColor',
  stroke = 'currentColor',
  width = '16',
  height = '16',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1078_13556)">
      <path
        d="M7 2.91016C4.9145 2.91391 4.30852 2.91023 3.6106 3.60815C2.85742 4.36132 2.85742 5.57355 2.85742 7.99796C2.85742 10.4224 2.85742 11.6346 3.6106 12.3878C4.36377 13.141 5.57599 13.141 8.00045 13.141C10.4249 13.141 11.6371 13.141 12.3903 12.3878C13.0882 11.6899 12.9662 11.0855 12.97 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30313 2.15637C8.88891 2.15723 8.55382 2.49371 8.55468 2.90792C8.55554 3.32214 8.89203 3.65723 9.30624 3.65637L11.1506 3.64839L7.35461 7.44434C7.06172 7.73724 7.06172 8.21211 7.35461 8.505C7.64751 8.7979 8.12238 8.7979 8.41527 8.505L12.2309 4.68937L12.2229 6.57298C12.222 6.98719 12.5571 7.32368 12.9713 7.32453C13.3855 7.32539 13.722 6.9903 13.7228 6.57609L13.7346 2.89614C13.735 2.69669 13.656 2.50529 13.515 2.36425C13.3739 2.22322 13.1825 2.14417 12.9831 2.14458L9.30313 2.15637Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1078_13556">
        <rect width="12" height="12" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)
