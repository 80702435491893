import React, { FunctionComponent } from 'react'

import { Text } from '@theme-ui/components'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

import { ScheduledCampaignStatus } from '../types'

import { StatusColor } from './Campaign.Progress'

const Span = styled.span`
  color: ${({ color }) => color};
`

export interface SentProps {
  status: ScheduledCampaignStatus
  sent: number
  total: number
}

export const Sent: FunctionComponent<SentProps> = ({ status, sent, total }) => (
  <Text as="div" variant="subtitle" sx={{ fontSize: 24, mb: 2 }}>
    <Trans
      i18nKey="campaigns.sent"
      values={{
        sent,
        total,
      }}
      components={[
        <Span color={StatusColor[status]} />,
        <Span color={baseColors.grey200} />,
        <Span color={baseColors.grey300} />,
      ]}
    />
  </Text>
)
