import { ReactNode, FunctionComponent } from 'react'

import { Loader } from 'rsuite'
import styled, { css } from 'styled-components'

import { baseColors } from '../primitives/colors'

const StyledLoader = styled(Loader)`
  .rs-loader {
    .rs-loader-spin {
      &:before {
        border-color: transparent;
      }
      &:after {
        ${({ color }) =>
          css`
            border-color: ${color} ${color} ${color} transparent;
          `}
      }
    }
    .rs-loader-content {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.71;
      color: @grey300;
    }
  }

  .rs-loader-vertical .rs-loader .rs-loader-content {
    margin-top: 36px;
  }
`

export interface SpinnerProps {
  backdrop?: boolean
  center?: boolean
  content?: ReactNode
  size?: 'lg' | 'md' | 'sm' | 'xs'
  vertical?: boolean
  color?: string
}

export const Spinner: FunctionComponent<SpinnerProps> = ({
  backdrop,
  center,
  content,
  size,
  vertical,
  color = baseColors.purple,
}) => (
  <StyledLoader
    center={center}
    content={content}
    backdrop={backdrop}
    vertical={vertical}
    size={size || (backdrop && 'lg') || 'md'}
    speed="slow"
    aria-label="loading spinner"
    color={color}
  />
)
