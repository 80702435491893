import { emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions } from 'store/apiHeaders/types'

const integrationsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    createEmployees: build.query<any, { accountToken: string }>({
      query: ({ accountToken }) => ({
        url: `integrations/employees`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-integration-token': accountToken,
        },
        method: 'POST',
      }),
    }),
    createLinkToken: build.query<
      { link_token: string; integration_name: string },
      void
    >({
      query: () => ({ url: `integrations/token`, method: 'POST' }),
    }),
    getAccountToken: build.query<any, { publicToken: string }>({
      query: ({ publicToken }) => ({
        url: `integrations/token/${publicToken}`,
      }),
      transformResponse: (response: { body: { account_token: string } }) =>
        response.body,
    }),
  }),
})

export const {
  useCreateEmployeesQuery,
  useCreateLinkTokenQuery,
  useGetAccountTokenQuery,
} = integrationsApi
