import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useGetScheduledCampaignsQuery } from '../campaignsApi'
import { ScheduledCampaignStatus, ScheduledCampaign } from '../types'

export const usePastCampaigns = (): ScheduledCampaign[] => {
  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )
  const { pastCampaigns } = useGetScheduledCampaignsQuery(
    { sessionId },
    {
      selectFromResult: ({ data }) => ({
        pastCampaigns: data?.filter(
          campaign =>
            campaign.status === ScheduledCampaignStatus.COMPLETED ||
            campaign.status === ScheduledCampaignStatus.CANCELLED
        ),
      }),
    }
  )
  return pastCampaigns || []
}
