import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'

const StyledText = styled(Text)<{ percentage: string }>`
  line-height: 1.5rem;
  padding-left: ${({ percentage }) => percentage};
  margin-left: 8px;
`

interface ComparisonSquareBarProps {
  percentage: number
  color?: string
}

export const ComparisonSquareBar: FunctionComponent<
  ComparisonSquareBarProps
> = ({ percentage, color }) => {
  const percentageString = `${Math.round(percentage)}%`

  return (
    <Box
      sx={{
        position: 'relative',
        height: 24,
        bg: 'grey100',
        width: '100%',
        maxWidth: '240px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          bg: color ?? baseColors.purple800,
          width: percentageString,
          textAlign: 'right',
        }}
      />
      <StyledText
        element="p"
        type="body"
        size="sm"
        fontWeight={600}
        color={color ? baseColors.grey400 : baseColors.purple800}
        percentage={percentageString}
      >
        {percentageString}
      </StyledText>
    </Box>
  )
}
