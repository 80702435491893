const sectors = [
  { value: 'technology', label: 'company.sector.Technology' },
  {
    value: 'venture_capital',
    label: 'company.sector.VentureCapital',
  },
  {
    value: 'aerospace_and_defence',
    label: 'company.sector.AerospaceAndDefence',
  },
  {
    value: 'asset_and_wealth_management',
    label: 'company.sector.AssetAndWealthManagement',
  },
  { value: 'automotive', label: 'company.sector.Automotive' },
  {
    value: 'banking_and_capital_markets',
    label: 'company.sector.BankingAndCapitalMarkets',
  },
  { value: 'business_services', label: 'company.sector.BusinessServices' },
  {
    value: 'capital_projects_and_infrastructure',
    label: 'company.sector.CapitalProjectsAndInfrastructure',
  },
  { value: 'charities', label: 'company.sector.Charities' },
  { value: 'chemicals', label: 'company.sector.Chemicals' },
  { value: 'education', label: 'company.sector.Education' },
  {
    value: 'engineering_and_construction',
    label: 'company.sector.EngineeringAndConstruction',
  },
  { value: 'financial_services', label: 'company.sector.FinancialServices' },
  {
    value: 'forest_paper_and_packaging',
    label: 'company.sector.ForestPaperAndPackaging',
  },
  {
    value: 'government_and_public_services',
    label: 'company.sector.GovernmentAndPublicServices',
  },
  { value: 'healthcare', label: 'company.sector.Healthcare' },
  {
    value: 'hospitality_and_leisure',
    label: 'company.sector.HospitalityAndLeisure',
  },
  { value: 'insurance', label: 'company.sector.Insurance' },
  { value: 'manufacturing', label: 'company.sector.Manufacturing' },
  {
    value: 'media_and_entertainment',
    label: 'company.sector.MediaAndEntertainment',
  },
  { value: 'mining_and_metals', label: 'company.sector.MiningAndMetals' },
  { value: 'oil_and_gas', label: 'company.sector.OilAndGas' },
  {
    value: 'pharmaceutical_and_life_sciences',
    label: 'company.sector.PharmaceuticalAndLifeSciences',
  },
  { value: 'power_and_utilities', label: 'company.sector.PowerAndUtilities' },
  { value: 'private_equity', label: 'company.sector.PrivateEquity' },
  { value: 'real_estate', label: 'company.sector.RealEstate' },
  { value: 'retail_and_consumer', label: 'company.sector.RetailAndConsumer' },
  {
    value: 'sovereign_investment_funds',
    label: 'company.sector.SovereignInvestmentFunds',
  },
  { value: 'telecommunications', label: 'company.sector.Telecommunications' },
  {
    value: 'transport_and_logistic',
    label: 'company.sector.TransportAndLogistic',
  },
]

export default sectors
