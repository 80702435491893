import React, { FunctionComponent } from 'react'

import i18next from 'i18next'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { space, baseColors, Calendar, Text } from '@fairhq/common'
import { toDate } from 'utils/dates'

const TimingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

const StyledCalendar = styled(Calendar)`
  margin-right: ${space[1]}px;
`

export interface SpecificTimingProps {
  startDate: string
  frequency: number
  withIcon?: boolean
}

export const SpecificTiming: FunctionComponent<SpecificTimingProps> = ({
  startDate,
  frequency,
  withIcon = true,
}) => (
  <TimingContainer>
    {withIcon && <StyledCalendar outline={baseColors.grey300} />}
    <Text element="label" type="body" size="xs" fontWeight={400}>
      <Trans
        i18nKey="campaigns.specificDates"
        values={{
          startDate: toDate(i18next.language, startDate)?.format('DD/MM/YYYY'),
          frequency,
        }}
      />
    </Text>
  </TimingContainer>
)
