import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { Colors } from '@fairhq/common'

import { StateHandler } from 'components/StateHandler'
import { GraphItem } from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'
import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'
import BorderBox from 'ui-kit/BorderBox'
import { DonutChart } from 'ui-kit/DonutChart'

import { useAreaDetail } from '../hooks/useAreaDetail'

const qualityColorMap: Record<string, Colors> = {
  low: Colors.RED,
  okay: Colors.YELLOW,
  great: Colors.GREEN,
}

interface ReportsOverviewProps {
  code: string
  graphItems?: GraphItem[]
}

export const ReportsOverview: FunctionComponent<ReportsOverviewProps> = ({
  code,
  graphItems,
}) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: reportByAreaData,
    isLoading,
    isError,
    isSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'general',
    apiVersion,
    sessionId,
  })

  const { value, max, quality, header, blurb, queryStatus } =
    useAreaDetail(code)
  return (
    <StateHandler
      isError={isError || queryStatus === QueryStatus.ERROR}
      isSuccess={isSuccess && queryStatus === QueryStatus.SUCCESS}
      isLoading={isLoading || queryStatus === QueryStatus.LOADING}
      errorMessage={t('error.TryAgain')}
    >
      <BorderBox mb={5}>
        <Flex sx={{ justifyContent: 'space-between', mb: 6 }}>
          <Box sx={{ maxWidth: 600 }}>
            <Text as="div" variant="capsLarge" sx={{ mb: 2 }}>
              {t('overview')}
            </Text>
            <Text
              as="div"
              variant="title"
              color={qualityColorMap[quality]}
              sx={{ mb: 2 }}
            >
              {header}
            </Text>

            <Text as="div">{blurb}</Text>
          </Box>

          <Box sx={{ ml: 10, flex: '0 0 auto' }}>
            <DonutChart
              value={value}
              max={max}
              valueLabel={round(reportByAreaData?.scoring?.score10 ?? 0, 1)}
              maxLabel={`${Math.round(value)} ${t(`of`, {
                value: Math.round(max),
              })}`}
              color={qualityColorMap[quality]}
            />
          </Box>
        </Flex>

        {graphItems && graphItems?.length > 0 ? (
          <>
            <Text as="div" variant="capsLarge" sx={{ mb: 4 }}>
              {t('scoreBreakdown')}
            </Text>
            <Visualization type="Bar" items={graphItems as any} isScoreBy10 />
          </>
        ) : undefined}
      </BorderBox>
    </StateHandler>
  )
}
