import { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Slack, Lock, Hash, Text } from '@fairhq/common'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const StyledSlack = styled(Slack)`
  margin-right: 6px;
  min-width: ${({ width }) => width}px;
`

const StyledLock = styled(Lock)`
  flex-shrink: 0;
`

const StyledHash = styled(Hash)`
  flex-shrink: 0;
`

export interface ChannelProps {
  channelName: string
  isPrivate: boolean
  iconSize?: string
}

export const Channel: FunctionComponent<ChannelProps> = ({
  channelName,
  isPrivate,
  iconSize = '24',
}) => (
  <Container>
    <StyledSlack width={iconSize} height={iconSize} />
    <Container>
      {isPrivate ? (
        <StyledLock height="1em" width="1em" />
      ) : (
        <StyledHash height="1em" width="1em" />
      )}
      <Text element="label" type="body" size="xs" fontWeight={400}>
        {channelName}
      </Text>
    </Container>
  </Container>
)
