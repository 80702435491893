import React, { FunctionComponent, useEffect } from 'react'

import { Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { Spinner } from '@fairhq/common'
import { Layout } from 'layout/Layout'
import { getUsers } from 'store/account/accountSlice'

import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { AvailableCampaigns } from './AvailableCampaigns'
import {
  useGetCompanySlackChannelsQuery,
  useGetSlackInstallStatusQuery,
} from './campaignsApi'
import { CampaignsBlankSlate } from './CampaignsBlankSlate'
import { PastCampaigns } from './PastCampaigns'
import { ScheduledCampaigns } from './ScheduledCampaigns'

export const Campaigns: FunctionComponent = () => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const {
    data: isSlackbotInstalled,
    isSuccess,
    isLoading,
    isError,
  } = useGetSlackInstallStatusQuery({ sessionId })

  // This query is only here because it takes a while to load
  // The data is then ready to be used later on when it is needed
  useGetCompanySlackChannelsQuery({ sessionId }, { skip: !isSlackbotInstalled })

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  return (
    <>
      {isError && (
        <Layout
          contentSx={{
            paddingTop: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text as="div" variant="h3">
            {t(`campaigns.loadingError.title`)}
          </Text>
        </Layout>
      )}
      {isLoading && (
        <Layout
          contentSx={{
            paddingTop: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Layout>
      )}
      {isSuccess && isSlackbotInstalled === false && <CampaignsBlankSlate />}
      {isSuccess && isSlackbotInstalled === true && (
        <Layout dark>
          <ScheduledCampaigns />
          <AvailableCampaigns />
          <PastCampaigns />
        </Layout>
      )}
    </>
  )
}
