import React, { useEffect } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { useLocation, useNavigate, useParams } from 'react-router'

import styled from 'styled-components'

import { Spinner, Text, baseColors } from '@fairhq/common'
import { usePracticeCriteria } from 'features/reportsAreasDVC/hooks/usePracticeCriteria'
import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'

import { AreaType } from 'store/area/types'
import { useGetAuditAreasQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { getLevelNumber } from 'utils/getLevelNumber'

import { LevelCard } from './LevelCard'

const LevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const ReportsAreasDVC = () => {
  const { t } = useTranslation()
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const { sessionId } = useAppSelector((state: State) => ({
    sessionId: state.apiHeadersReducer.sessionId,
  }))

  const { data: areas } = useGetAuditAreasQuery(
    {
      sessionId,
      query: { code, type: AreaType.LOCAL },
    },
    { skip: !code }
  )

  const area = areas?.find(({ code: areaCode }) => code === areaCode)

  const { queryStatus, practicesGroupedByLevel, firstIncompleteLevel } =
    usePracticeCriteria(code)

  useEffect(() => {
    if (location.pathname === '/reports/equality') {
      navigate('/reports/equality/equality.operations.keyPolicies')
    }
  }, [location, navigate])

  return (
    <Layout sidebar={<ReportsModularSidebar />} dark>
      {area?.title && (
        <PageTop
          sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}
        >
          <Text type="heading" element="h1" size="2xl" fontWeight={500}>
            {area?.title}
          </Text>
          <Text type="body" element="p" size="sm" fontWeight={400}>
            <Trans i18nKey={`${code}.description`} />
          </Text>
        </PageTop>
      )}

      {queryStatus === QueryStatus.LOADING && <Spinner center />}
      {queryStatus === QueryStatus.ERROR && (
        <Text
          element="p"
          type="body"
          size="md"
          fontWeight={600}
          color={baseColors.red}
        >
          {t('error.TryAgain')}
        </Text>
      )}
      {queryStatus === QueryStatus.SUCCESS &&
        practicesGroupedByLevel &&
        code && (
          <LevelsContainer>
            {Object.keys(practicesGroupedByLevel).map(levelKey => (
              <LevelCard
                key={levelKey}
                practices={practicesGroupedByLevel[levelKey]}
                levelNumber={getLevelNumber(levelKey)}
                isShowAllOpenInitialSate={firstIncompleteLevel === levelKey}
                areaCode={code}
                levelKey={levelKey}
              />
            ))}
          </LevelsContainer>
        )}
    </Layout>
  )
}
