import { FunctionComponent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { Employee } from 'features/employees/types'

import Button from 'ui-kit/Button'
import { Table } from 'ui-kit/Table'

import { useGetEmployeesQuery } from './emloyeesApi'
import { EmployeeRow } from './EmployeeRow'
import { useIsIntersection } from './hooks/useIsIntersection'
import { EmployeeEditModal } from './modals/EmployeeEditModal'
import { LIMIT, removeDupes, sortEmployees } from './utils'

const StyledWrapper = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
`

interface EmployeesListProps {
  searchTerm: string
  selectedEmployees: Employee[]
  setSelectedEmployees: (selectedEmployees: Employee[]) => void
  showSurveyColumn?: boolean
}

export const EmployeesList: FunctionComponent<EmployeesListProps> = ({
  searchTerm,
  selectedEmployees,
  setSelectedEmployees,
  showSurveyColumn = false,
}) => {
  const [employeeToEdit, setEmployeeToEdit] = useState<Partial<Employee>>()
  const [allEmployees, setAllEmployees] = useState<Employee[]>([])
  const [lastEmployeeOfPreviousBatch, setLastEmployeeOfPreviousBatch] =
    useState<Employee | undefined>(undefined)

  const { t } = useTranslation()
  const { intersectionRef, isIntersection } = useIsIntersection()

  const {
    data: employees,
    isLoading,
    isFetching,
  } = useGetEmployeesQuery({
    limit: LIMIT,
    ...(searchTerm ? { terms: searchTerm } : {}),
    ...(lastEmployeeOfPreviousBatch
      ? {
          lastName: lastEmployeeOfPreviousBatch.lastName,
          firstName: lastEmployeeOfPreviousBatch.firstName,
          employeeId: lastEmployeeOfPreviousBatch.id,
        }
      : {}),
  })

  useEffect(() => {
    if (isIntersection && employees && employees.length) {
      setLastEmployeeOfPreviousBatch(employees[employees.length - 1])
    }
  }, [isIntersection, employees])

  useEffect(() => {
    setAllEmployees(allEmployees =>
      sortEmployees(removeDupes({ allEmployees, employees }))
    )
  }, [employees])

  useEffect(() => {
    setAllEmployees([])
    setLastEmployeeOfPreviousBatch(undefined)
  }, [searchTerm])

  return (
    <>
      {employeeToEdit ? (
        <EmployeeEditModal
          visible={!!employeeToEdit}
          employee={employeeToEdit}
          onClose={() => {
            setEmployeeToEdit(undefined)
          }}
        />
      ) : null}
      {isLoading && (
        <StyledWrapper>
          <Spinner />
        </StyledWrapper>
      )}
      {!isLoading && allEmployees.length ? (
        <>
          <Table
            items={allEmployees}
            headers={[
              <Button
                icon={
                  selectedEmployees.length === allEmployees.length &&
                  selectedEmployees.length > 0
                    ? 'checkboxChecked'
                    : (selectedEmployees.length > 0 && 'checkboxUnavailable') ||
                      'checkbox'
                }
                variant="link"
                aria-label={
                  selectedEmployees.length === allEmployees.length
                    ? t('survey.deselectAll')
                    : t('survey.selectAll')
                }
                onClick={() => {
                  if (selectedEmployees.length === allEmployees.length) {
                    setSelectedEmployees([])
                  } else {
                    setSelectedEmployees(allEmployees)
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  svg: {
                    opacity: 1,
                    margin: 0,
                    fill: selectedEmployees.length === 0 ? 'none' : '',
                  },
                }}
              />,
              t('employeesList.header.name'),
              t('employeesList.header.email'),
              t('employeesList.header.jobLevel'),
              showSurveyColumn
                ? [t('employeesList.header.surveySentOn'), '']
                : '',
            ]}
            renderItem={employee => (
              <EmployeeRow
                key={employee.id}
                employee={employee}
                setSelectedEmployees={setSelectedEmployees}
                selectedEmployees={selectedEmployees}
                setEmployeeToEdit={setEmployeeToEdit}
                showSurveyColumn={showSurveyColumn}
              />
            )}
            sx={{
              bg: 'white',
              th: { bg: 'white', fontWeight: 700, color: 'grey400' },
            }}
          />
          {allEmployees.length >= LIMIT && !isFetching && (
            <div ref={intersectionRef} />
          )}
        </>
      ) : null}
    </>
  )
}
