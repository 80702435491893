import { useTranslation } from 'react-i18next'

import { useGetCategoriesQuery } from 'store/category/categoryApi'

import { useGetSurveyWindowArchiveComparisonQuery } from 'store/surveyWindow/surveyWindowApi'

export const useSidebarExperience = () => {
  const { t } = useTranslation()

  const { data: archive, isSuccess: isArchiveSuccess } =
    useGetSurveyWindowArchiveComparisonQuery()

  const { data: categories, isSuccess: isSuccessCategories } =
    useGetCategoriesQuery()

  const latestArchiveSurveyWindow = archive?.[0]
  const EXPERIENCE_CODE = 'experience'

  if (isArchiveSuccess) {
    return {
      sidebarItems: {
        code: EXPERIENCE_CODE,
        enabled: !!latestArchiveSurveyWindow,
        text: t(`${EXPERIENCE_CODE}.title`),
        url: `/reports/${EXPERIENCE_CODE}`,
        items:
          isSuccessCategories && categories.length > 0
            ? categories.map(item => ({
                code: item,
                text: t(`category.${item}`),
                url: `/reports/${EXPERIENCE_CODE}/${item}`,
                enabled: true,
              }))
            : undefined,
      },
    }
  }
  return { sidebarItems: undefined }
}
