import React, { useState, FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ThemeUIStyleObject } from 'theme-ui'

import { Tabs } from 'components/Tabs'

import { COLOR_RULES_GAPS } from 'components/visualization/constants'
import { GraphItem } from 'components/visualization/types'
import { Visualization } from 'components/visualization/Visualization'

import { getTabs } from '../utils/getTabs'

import { Breakdown } from './Breakdown'

interface AnswerBreakdownProps {
  answer: string
  score: number
  items?: Record<string, GraphItem[]>
  max?: number
  sx?: ThemeUIStyleObject
}

export const AnswerBreakdown: FunctionComponent<AnswerBreakdownProps> = ({
  items,
  answer,
  score,
  max,
  sx,
}) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('overview')
  const currentItems = sortBy(
    items?.[activeTab] || items?.byEmployeeGroups || [],
    ['value']
  )

  return (
    <Breakdown title={answer} score={score} max={max} sx={sx}>
      <Box
        sx={{ borderTop: '1px solid', borderColor: 'grey200', py: 4, px: 8 }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Text as="div" variant="caps">
            {t('reports.answersDistribution')}
          </Text>{' '}
          <Tabs
            tabs={getTabs(t, items)}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Flex>
        <Visualization
          type={
            currentItems.length < 4 || currentItems.length > 10 ? 'Row' : 'Bar'
          }
          items={currentItems as any}
          isScoreBy10={activeTab !== 'byGaps'}
          isGaps={activeTab === 'byGaps'}
          colorRules={activeTab === 'byGaps' ? COLOR_RULES_GAPS : undefined}
        />
      </Box>
    </Breakdown>
  )
}
