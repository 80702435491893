import { FunctionComponent } from 'react'

import styled from 'styled-components'

import { baseColors } from '@fairhq/common'

interface EmptyCircleProps {
  dimension: number
}

const EmptyCircle = styled.div<EmptyCircleProps>`
  background-color: ${baseColors.grey100};
  width: ${({ dimension }) => dimension}px;
  height: ${({ dimension }) => dimension}px;
  border-radius: 50%;
  position: relative;
`

const Circle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`

interface InnerWhiteCircleProps {
  dimension: number
  strokeWidth: number
}

const InnerWhiteCircle = styled.div<InnerWhiteCircleProps>`
  background-color: ${baseColors.white};
  width: ${({ dimension, strokeWidth }) => dimension - strokeWidth * 2}px;
  height: ${({ dimension, strokeWidth }) => dimension - strokeWidth * 2}px;
  border-radius: 50%;
  position: absolute;
  top: ${({ strokeWidth }) => strokeWidth}px;
  left: ${({ strokeWidth }) => strokeWidth}px;
  box-shadow: 0 8px 12px rgba(10, 27, 64, 10%);
`

interface SmallDonutProps {
  percentage: number
  color: string
  dimension?: number
  strokeWidth?: number
  className?: string
}

export const SmallDonut: FunctionComponent<SmallDonutProps> = ({
  percentage,
  color,
  dimension = 52,
  strokeWidth = 11,
  className,
}) => {
  const radius = dimension / 2 - strokeWidth / 2
  const circumference = radius * 2 * Math.PI
  const strokeDashoffset = circumference - (percentage / 100) * circumference

  return (
    <EmptyCircle dimension={dimension}>
      <svg height={dimension} width={dimension} className={className}>
        <Circle
          stroke={color}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          r={radius}
          cx={dimension / 2}
          cy={dimension / 2}
        />
      </svg>
      <InnerWhiteCircle dimension={dimension} strokeWidth={strokeWidth} />
    </EmptyCircle>
  )
}
