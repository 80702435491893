import React, { FunctionComponent } from 'react'

import { Heading } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

export interface TitleProps {
  campaignCode: string
}

export const Title: FunctionComponent<TitleProps> = ({ campaignCode }) => {
  const { t } = useTranslation()
  return (
    <Heading sx={{ mb: 1, fontSize: 21 }}>
      {t(`campaigns.${campaignCode}.title`)}
    </Heading>
  )
}
