import React, { useCallback, useEffect, useState } from 'react'

import { useMergeLink } from '@mergeapi/react-merge-link'

import { useTranslation } from 'react-i18next'

import { Spinner } from '@fairhq/common'

import Button from '../../ui-kit/Button'

import { useGetAccountTokenQuery } from './integrationsApi'

export const LinkAccount: React.FunctionComponent<{
  linkToken: string
  next: () => void
  setAccountToken: (token: string) => void
}> = ({ linkToken, next, setAccountToken }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [publicToken, setPublicToken] = useState('')
  const { data, isError, refetch } = useGetAccountTokenQuery(
    { publicToken },
    { skip: !publicToken }
  )
  const { account_token: accountToken } = data ?? {}
  const onSuccess = useCallback((token: string) => {
    setPublicToken(token)
  }, [])
  const setLoadingFalse = useCallback(() => {
    setLoading(false)
  }, [])
  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
    onExit: setLoadingFalse,
    onValidationError: setLoadingFalse,
    tenantConfig: { apiBaseURL: 'https://api-eu.merge.dev' },
  })
  const loadMergeLink = useCallback(() => {
    setLoading(true)
    if (publicToken) {
      refetch?.()
    } else {
      open()
    }
  }, [open, publicToken, refetch])

  useEffect(() => {
    if (accountToken) {
      setLoading(false)
      setAccountToken(accountToken)
      next()
    }
  }, [accountToken, next, setAccountToken])

  useEffect(() => {
    if (isError) {
      setLoading(false)
    }
  }, [isError])

  if (!linkToken) {
    return <Spinner backdrop vertical />
  }

  return (
    <Button disabled={!isReady} onClick={loadMergeLink} loading={loading}>
      {t('employees.add.linkAccount')}
    </Button>
  )
}
