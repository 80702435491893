import React, { FunctionComponent } from 'react'

import { round } from 'lodash'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'

import { DifferenceTag } from 'components/DifferenceTag'
import { getScoreColor } from 'utils/getScoreColor'

import { GapTag } from './GapTag'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & p {
    margin: 0;
  }
`

const TagContainer = styled.div`
  min-width: 77.5px;
`

interface GapAndScoreProps {
  score: number
  gap: number
  previousScore?: number
  previousGap?: number
  showDiffColumn: boolean
}

export const GapAndScore: FunctionComponent<GapAndScoreProps> = ({
  score,
  gap,
  previousScore,
  previousGap,
  showDiffColumn = false,
}) => {
  const scoreDiffPercentage = previousScore
    ? Math.round(((score - previousScore) / previousScore) * 100)
    : undefined

  const gapDiff = previousGap ? round((gap - previousGap) * 10, 1) : undefined

  return (
    <Wrapper>
      <Container>
        {gap != 0 ? (
          <>
            <Text
              element="p"
              type="body"
              size="xs"
              fontWeight={600}
              color={baseColors.coolGrey500}
            >
              GAP
            </Text>
            <Text element="p" type="body" size="2xl" fontWeight={600}>
              {round(gap * 10, 1).toFixed(1)}
            </Text>
          </>
        ) : (
          <Text
            element="p"
            type="body"
            size="xs"
            fontWeight={600}
            color={baseColors.coolGrey500}
          >
            NO GAP
          </Text>
        )}
        {showDiffColumn && (
          <TagContainer>
            {gapDiff ? (
              <GapTag
                value={gapDiff}
                displayValue={Math.abs(gapDiff).toFixed(1)}
              />
            ) : null}
          </TagContainer>
        )}
      </Container>
      <Container>
        <Text
          element="p"
          type="body"
          size="xs"
          fontWeight={600}
          color={baseColors.coolGrey500}
        >
          SCORE
        </Text>
        <Text
          element="p"
          type="body"
          size="2xl"
          fontWeight={600}
          color={getScoreColor(score * 10)}
        >
          {round(score * 10, 1).toFixed(1)}
        </Text>
        {showDiffColumn && (
          <TagContainer>
            {scoreDiffPercentage ? (
              <DifferenceTag
                value={scoreDiffPercentage}
                displayValue={`${Math.abs(scoreDiffPercentage)}%`}
              />
            ) : null}
          </TagContainer>
        )}
      </Container>
    </Wrapper>
  )
}
