import React from 'react'

import { User } from '@auth0/auth0-react'
import { darken } from '@theme-ui/color'
import { Box, Flex, Text } from '@theme-ui/components'
import { SxProp, Avatar as UIAvatar } from 'theme-ui'

import { Colors } from '@fairhq/common'

import { ReactComponent as BlankAvatar } from '../assets/blank-avatar.svg'

const avatarColors = [
  Colors.YELLOW,
  Colors.PINK,
  darken('blue', 0.1),
  darken('green', 0.15),
]

type Props = {
  user?: User
  size?: number
  grayscale?: boolean
  sx?: SxProp
  picture?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

function Avatar({ user, size = 48, grayscale, picture, onClick, sx }: Props) {
  if (picture && user) {
    return (
      <Box
        sx={{ flex: '0 0 auto', width: size, height: size, ...sx }}
        onClick={onClick}
      >
        <UIAvatar src={picture} alt={user?.name} />
      </Box>
    )
  }

  if (!user) {
    return (
      <Box
        as={BlankAvatar}
        sx={{ flex: '0 0 auto', width: size, height: size, ...sx }}
        onClick={onClick}
      />
    )
  }

  return (
    <Flex
      sx={{
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        borderRadius: '50%',
        userSelect: 'none',
        cursor: 'default',
        bg: grayscale
          ? Colors.GREY200
          : avatarColors[parseInt(user.id, 10) % avatarColors.length],
        ...sx,
      }}
      onClick={onClick}
    >
      <Text as="div" variant="caps" color="white">
        {[user?.given_name?.[0], user?.family_name?.[0]].join('')}
      </Text>
    </Flex>
  )
}

export default Avatar
