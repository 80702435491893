import { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { StateHandler } from 'components/StateHandler'
import { QueryStatus } from 'store/types'

import { useGroupScoreBreakdown } from '../hooks/useGroupScoreBreakdown'

import { Breakdown } from './Breakdown'
import { ScoreMatrix } from './ScoreMatrix'

interface DemGroupScoreBreakdownProps {
  area: string
}

export const DemGroupScoreBreakdown: FunctionComponent<
  DemGroupScoreBreakdownProps
> = ({ area }) => {
  const { t } = useTranslation()
  const { items, headers, queryStatus } = useGroupScoreBreakdown(area)

  return (
    <StateHandler
      isError={queryStatus === QueryStatus.ERROR}
      isSuccess={queryStatus === QueryStatus.SUCCESS}
      isLoading={queryStatus === QueryStatus.LOADING}
      errorMessage={t('error.TryAgain')}
    >
      <Breakdown
        title={
          <Text as="div" variant="bodyLargeBold" sx={{ color: 'grey400' }}>
            {t(`reports.${area}.score.breakdown.title`)}
          </Text>
        }
        sx={{ mb: 5 }}
      >
        <Box sx={{ borderRadius: 20, bg: 'white', px: 8, py: 5, mb: 5 }}>
          <ScoreMatrix data={items as any} headers={headers} />
        </Box>
      </Breakdown>
    </StateHandler>
  )
}
