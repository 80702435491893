import { Colors } from '@fairhq/common'

import { ColorRule } from './types'

export const DEFAULT_BAR_HEIGHT = 200
export const DEFAULT_LABEL_WIDTH = 320

export const COLOR_RULES_DEFAULT: ColorRule[] = [
  { max: 50, color: Colors.RED },
  { max: 75, color: Colors.YELLOW },
  { max: 1000, color: Colors.GREEN },
]

export const COLOR_RULES_GAPS: ColorRule[] = [
  { max: -15, color: Colors.RED },
  { max: -7, color: Colors.YELLOW },
  { max: 1000, color: Colors.GREEN },
]

export const COLOR_RULES_POC: ColorRule[] = [
  { max: 15.5, color: Colors.RED },
  { max: 23.3, color: Colors.YELLOW },
  { max: 1000, color: Colors.GREEN },
]

export const COLOR_RULES_WOMEN: ColorRule[] = [
  { max: 24.5, color: Colors.RED },
  { max: 37.5, color: Colors.YELLOW },
  { max: 62.5, color: Colors.GREEN },
  { max: 1000, color: Colors.YELLOW },
]

export const whiteColorBgChecker: any[] = [
  Colors.PURPLE,
  Colors.RED,
  Colors.GREEN,
  Colors.YELLOW,
]
