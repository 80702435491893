import { FunctionComponent, useEffect, useState } from 'react'

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { baseColors, Button, space, Text } from '@fairhq/common'
import { QuestionTypeCard } from 'components/questions/QuestionTypeCard'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import {
  useGetLinkedQuestionQuery,
  useSaveAssessmentMutation,
  useUpdateAssessmentMutation,
} from 'store/tasks/tasksApi'

import { Task } from 'store/tasks/types'

import { getAssessmentToSave } from './getAssessmentToSave'
import { ScoreImpact } from './ScoreImpact'

const Container = styled(motion.div)`
  border-radius: 20px;
  width: 100%;
`

const Header = styled.div`
  background-color: ${baseColors.purple700};
  padding: 40px 60px 32px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`

const SubHeader = styled.div`
  background-color: ${baseColors.purple200};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubHeaderText = styled(Text)`
  margin-right: 8px;
`

const Content = styled.div`
  background-color: ${baseColors.white};
  padding: 32px 48px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`

const ErrorContainer = styled.div`
  margin-top: 24px;
  background-color: #fdf3f1;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & button:not(:last-child) {
    margin-right: 16px;
  }
`

const Body = styled(Text)`
  text-align: center;
  padding-bottom: ${space[4]}px;
`

interface TaskCompletedModalProps {
  onClose: () => void
  task: Task
}

export const TaskCompletedModal: FunctionComponent<TaskCompletedModalProps> = ({
  onClose,
  task: { scoreImpact, linkedQuestionCode },
}) => {
  const { t } = useTranslation()

  const { sessionId, accountId, companyId } = useAppSelector(
    (state: State) => ({
      sessionId: state.apiHeadersReducer.sessionId,
      accountId: state.apiHeadersReducer.accountId,
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )

  const [selectedOption, setSelectedOption] = useState<number | undefined>()
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])
  const [number, setNumber] = useState<string | undefined>()
  const [other, setOther] = useState<string | undefined>()
  const [error, setError] = useState<boolean>(false)

  const [
    saveAssessment,
    {
      isError: isSaveError,
      isSuccess: isSaveSuccess,
      isLoading: isSaveLoading,
    },
  ] = useSaveAssessmentMutation()
  const [
    updateAssessment,
    {
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
    },
  ] = useUpdateAssessmentMutation()

  const { areaCode, current, projected } = scoreImpact || {}

  const { data: linkedQuestion } = useGetLinkedQuestionQuery({
    code: linkedQuestionCode,
    sessionId,
  })

  useEffect(() => {
    if (isSaveError || isUpdateError) {
      setError(true)
    } else if (isSaveSuccess || isUpdateSuccess) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveError, isUpdateError, isSaveSuccess, isUpdateSuccess, setError])

  if (!linkedQuestion) {
    return null
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <Header>
        <Text
          type="body"
          element="h2"
          size="lg"
          fontWeight={600}
          color={baseColors.white}
        >
          {t(
            linkedQuestion.question
              ? 'taskCompleted.title'
              : 'tasks.questionNotAvailable.title'
          )}
        </Text>
      </Header>
      {current && projected && (
        <SubHeader>
          <SubHeaderText
            type="body"
            element="p"
            size="sm"
            fontWeight={500}
            color={baseColors.purple900}
          >
            Potential impact on your {t(`${areaCode}.title`)} score
          </SubHeaderText>
          <ScoreImpact current={current} projected={projected} />
        </SubHeader>
      )}
      {linkedQuestion.question ? (
        <Content>
          <Body
            type="body"
            element="h3"
            size="lg"
            fontWeight={500}
            color={baseColors.purple900}
          >
            {linkedQuestion.question.title}
          </Body>
          <QuestionTypeCard
            question={linkedQuestion.question}
            onNumberChange={setNumber}
            numberValue={number}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            other={other}
            setOther={setOther}
          />
          {error && (
            <ErrorContainer>
              <Text
                element="p"
                type="body"
                size="sm"
                fontWeight={500}
                color="#D32E17"
              >
                {t('taskCompleted.saveError')}
              </Text>
            </ErrorContainer>
          )}
          <ButtonsContainer>
            <Button onClick={onClose} variant="tertiary" fullWidth>
              {t('taskCompleted.cancelButton')}
            </Button>
            <Button
              onClick={() => {
                const { assessment: existingAssessment } = linkedQuestion
                if (linkedQuestion.question) {
                  const assessmentToSave = getAssessmentToSave(
                    linkedQuestion.question,
                    selectedOption,
                    selectedOptions,
                    number,
                    other,
                    sessionId,
                    accountId,
                    companyId
                  )
                  if (existingAssessment?.id) {
                    updateAssessment({
                      id: existingAssessment.id,
                      assessment: assessmentToSave,
                      sessionId,
                    })
                  } else {
                    saveAssessment({
                      assessment: assessmentToSave,
                      sessionId,
                    })
                  }
                }
              }}
              fullWidth
              loading={
                isSaveLoading ||
                isUpdateLoading ||
                isSaveSuccess ||
                isUpdateSuccess
              }
            >
              {t('taskCompleted.saveButton')}
            </Button>
          </ButtonsContainer>
        </Content>
      ) : (
        <Content>
          <Body
            type="body"
            element="p"
            size="sm"
            fontWeight={500}
            color={baseColors.purple900}
          >
            {t('tasks.questionNotAvailable.body')}
          </Body>
          <Button fullWidth onClick={onClose}>
            {t('tasks.questionNotAvailable.button')}
          </Button>
        </Content>
      )}
    </Container>
  )
}
