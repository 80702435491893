import { chain } from 'lodash'
import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { useAuditBy } from 'hooks/useAuditBy'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { useGetReportsQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'

export const useReportsGroupsMenu = () => {
  const { t } = useTranslation()
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { isSurveyCompleted } = useAuditBy(sessionId, apiVersion)
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  const groupsMenu = []

  const {
    data: demGroupsRawData,
    isLoading: isDemGroupsRawDataLoading,
    isError: isDemGroupsRawDataError,
  } = useGetReportsQuery({ type: 'dem_groups', apiVersion, sessionId })
  const demGroups = Object.keys(
    chain(demGroupsRawData?.companyByDemGroup ?? [])
      .keyBy('dem_group_group')
      .value()
  )?.filter(group => group !== 'dem_traditional_majority')
  if (demGroups?.length > 0 && (isScorable || isSurveyCompleted)) {
    groupsMenu.push({
      code: 'group/dem_groups',
      url: demGroups[0] ? `/reports/group/dem_groups` : '#',
      baseUrl: '/reports/group/dem_groups',
      items: demGroups.map(group => ({ code: group, title: group })),
      sorted: true,
    })
  }

  const {
    data: jobLevelRawData,
    isLoading: isJobLevelRawDataLoading,
    isError: isJobLevelRawDataError,
  } = useGetReportsQuery({ type: 'job_level', apiVersion, sessionId })
  const jobLevels = jobLevelRawData?.companyByJobLevel ?? []
  if (jobLevels?.length > 0 && (isScorable || isSurveyCompleted)) {
    groupsMenu.push({
      code: 'group/job_level',
      url: '/reports/group/job_level',
      baseUrl: '/reports/group/job_level',
      items: jobLevels.map(({ level }: any) => ({
        code: level,
        title: level,
      })),
      sorted: true,
    })
  }

  const {
    data: departmentRawData,
    isLoading: isDepartmentRawDataLoading,
    isError: isDepartmentRawDataError,
  } = useGetReportsQuery({ type: 'department', apiVersion, sessionId })
  const departments = departmentRawData?.companyByDepartment?.filter(
    ({ department }: any) => department !== null
  )
  if (departments?.length > 0 && (isScorable || isSurveyCompleted)) {
    groupsMenu.push({
      code: 'group/department',
      url: '/reports/group/department',
      baseUrl: '/reports/group/department',
      items: departments.map(({ department }: any) => ({
        code: department,
        title: department,
        text: t(department),
      })),
      sorted: true,
    })
  }

  const {
    data: locationRawData,
    isLoading: isLocationRawDataLoading,
    isError: isLocationRawDataError,
  } = useGetReportsQuery({ type: 'location', apiVersion, sessionId })
  const locations = locationRawData?.companyByLocation?.filter(
    ({ country }: any) => country !== null
  )

  if (locations?.length > 0 && (isScorable || isSurveyCompleted)) {
    groupsMenu.push({
      code: 'group/location',
      url: '/reports/group/location',
      baseUrl: '/reports/group/location',
      items: locations.map(({ country }: any) => ({
        code: country,
        title: country,
        text: t(`country.${country}`),
      })),
      sorted: true,
    })
  }

  const isLoading =
    isDemGroupsRawDataLoading ||
    isJobLevelRawDataLoading ||
    isDepartmentRawDataLoading ||
    isLocationRawDataLoading

  const isError =
    isDemGroupsRawDataError ||
    isJobLevelRawDataError ||
    isDepartmentRawDataError ||
    isLocationRawDataError

  return { groupsMenu, isLoading, isError }
}
