import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'

import { useAuth0, User } from '@auth0/auth0-react'
import Modal from 'react-modal'
import { BrowserRouter as Router } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ThemeProvider as ThemeUIThemeProvider } from 'theme-ui'

import { theme, themeUI, Spinner, config } from '@fairhq/common'
import { useCacheBuster } from 'hooks'
import { useGetApiHeaderData } from 'hooks/useGetApiHeaderData'
import LoggedInApp from 'LoggedInApp'
import { authActions } from 'store/auth/authSlice'
import AuthContext from 'utils/auth'
import { convertToIntercomUser } from 'utils/convertToIntercomUser'
import { checkTokenStatus } from 'utils/token'

import { useAppDispatch } from './store/hooks'

Modal.setAppElement('#root')

const GlobalStyle = createGlobalStyle`
  body {
    font-feature-settings: "liga", "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Druk Wide Web', sans-serif;
  }

  a {
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }

  .ReactModal__Overlay {
    background: rgba(21, 28, 40, 80%) !important;
    padding: 80px 40px !important;
    top: 74px !important;
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow: auto !important;
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  .ReactModal__Content {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    position: relative !important;
    max-width: 740px !important;
    width: 100% !important;
    overflow: initial !important;
    margin: 0;
    inset: 0 !important;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
  }
  .ReactModal__Content--before-close {
    opacity: 0;
  }
`

const getShowSpinner = (
  loggedIn: boolean,
  hasData: boolean,
  user: User | undefined
): boolean => {
  // Users that just signed up won't have the api header data yet so no point blocking
  // the app from loading until we can fetch that data
  if (user?.email_verified === false) {
    return false
  }

  // Users that aren't finished with onboarding won't have api header data yet
  // so no point blocking the app from loading until we can fetch that data
  const isPastOnboarding =
    user?.['https://fairhq.co/user_metadata']?.onboarding?.hidden

  if (!isPastOnboarding) {
    return false
  }

  return loggedIn === true && hasData === false
}

const App: FunctionComponent = () => {
  useCacheBuster()
  const dispatch = useAppDispatch()
  const { update: updateIntercom } = useIntercom()
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
    getIdTokenClaims,
  } = useAuth0()
  const [loggedIn, setLoggedIn] = useState(false)
  const hasData = useGetApiHeaderData()
  const showSpinner = getShowSpinner(loggedIn, hasData, user)

  useEffect(() => {
    if (
      (!localStorage.getItem(config.tokenKey) || !isAuthenticated) &&
      !isLoading
    ) {
      getAccessTokenSilently().then(token => {
        if (token) {
          dispatch(authActions.setJWT(token))
        }
      })
    } else if (localStorage.getItem(config.tokenKey)) {
      getIdTokenClaims().then(idToken => {
        const tokenWithStatus = checkTokenStatus(idToken)
        if (tokenWithStatus.status !== 'valid') {
          getAccessTokenSilently().then(token => {
            if (token) {
              dispatch(authActions.setJWT(token))
            }
          })
        }
      })
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
  ])

  useEffect(() => {
    if (user) {
      dispatch(authActions.setUser(user))
      updateIntercom(convertToIntercomUser(user))
    }
  }, [dispatch, user, updateIntercom])

  useEffect(() => {
    setLoggedIn(isAuthenticated)
  }, [isAuthenticated])

  return (
    <Suspense fallback={<Spinner backdrop vertical />}>
      <Router>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
          <ThemeUIThemeProvider theme={themeUI}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              {showSpinner ? (
                <Spinner center backdrop vertical />
              ) : (
                <LoggedInApp />
              )}
            </ThemeProvider>
          </ThemeUIThemeProvider>
        </AuthContext.Provider>
      </Router>
    </Suspense>
  )
}

export default React.memo(App)
