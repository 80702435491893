import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Arrow, Text, baseColors } from '@fairhq/common'
import { ArrowDirection } from '@fairhq/common/lib/components/atoms/icons/Arrow'

const Tag = styled.div<{ backgroundColor: string; color: string }>`
  display: inline-flex;
  gap: 2px;
  align-items: center;
  padding: 2px 8px;
  border-radius: 9999px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  white-space: nowrap;
  color: ${({ color }) => color};
  width: max-content;
  align-self: center;
`

interface DifferenceTagProps {
  value: number
  displayValue?: string
}

export const DifferenceTag: FunctionComponent<DifferenceTagProps> = ({
  value,
  displayValue,
}) => {
  if (value === 0) {
    return null
  }

  return (
    <Tag
      backgroundColor={value > 0 ? baseColors.fairGreen100 : baseColors.red100}
      color={value > 0 ? baseColors.fairGreen600 : baseColors.red600}
    >
      <Arrow direction={value > 0 ? ArrowDirection.UP : ArrowDirection.DOWN} />
      <Text type="body" element="span" size="sm" fontWeight={600}>
        {displayValue ?? Math.round(value)}
      </Text>
    </Tag>
  )
}
