import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

export const RightColumn: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => <Container>{children}</Container>
