import { FunctionComponent } from 'react'

interface RocketProps {
  className?: string
  outline?: string
  width?: string
  height?: string
}

export const Rocket: FunctionComponent<RocketProps> = ({
  className,
  outline = 'currentColor',
  width = '24',
  height = '24',
}) => (
  <svg
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
    width={width}
    height={height}
  >
    <path
      fill={outline}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2659 3.82495L20.6956 4.25465C20.9335 4.4848 21.042 4.81786 20.9854 5.14402L19.9861 10.6402C19.7969 11.5695 19.2744 12.3976 18.5172 12.9685L15.7692 15.127C16.223 16.3934 15.7776 17.8069 14.68 18.5846L10.6829 21.4026C10.4588 21.5571 10.1522 21.5038 9.99341 21.2827L9.70363 20.8729C9.24735 20.2211 9.0689 19.4146 9.2076 18.6311C9.3463 17.8477 9.79077 17.1515 10.4431 16.6959L11.3324 16.0663L8.42456 13.1584L7.79503 14.0478C7.33947 14.7001 6.64329 15.1446 5.85983 15.2833C5.07636 15.422 4.2699 15.2435 3.61809 14.7872L3.20839 14.4974C2.98725 14.3386 2.93393 14.032 3.08848 13.8079L5.90641 9.81074C6.68399 8.71304 8.09754 8.26772 9.36388 8.7215L11.5223 5.94346C12.1107 5.20241 12.9492 4.70143 13.8806 4.53445L19.3765 3.53516C19.7027 3.47848 20.0357 3.587 20.2659 3.82495ZM12.8913 7.05268L9.31391 11.5995L12.9213 15.2069L17.4779 11.6195C17.8758 11.2917 18.1477 10.8361 18.2474 10.3304L18.5272 8.80145L15.7192 5.99342L14.1903 6.27323C13.6799 6.37415 13.2205 6.64977 12.8913 7.05268ZM13.4309 8.97133C12.8806 9.56193 12.8968 10.4823 13.4676 11.0531C14.0384 11.6239 14.9588 11.6402 15.5493 11.0898C16.0997 10.4992 16.0834 9.57888 15.5126 9.00806C14.9418 8.43724 14.0215 8.421 13.4309 8.97133ZM6.76078 16.5581C6.89401 16.5581 7.02171 16.6113 7.11552 16.7059L7.81501 17.4054C7.9096 17.4992 7.9628 17.6269 7.9628 17.7601C7.9628 17.8934 7.9096 18.0211 7.81501 18.1149L4.5574 21.3726C4.46359 21.4672 4.33588 21.5204 4.20266 21.5204C4.06944 21.5204 3.94174 21.4672 3.84792 21.3726L3.14843 20.6731C3.05385 20.5793 3.00064 20.4516 3.00064 20.3183C3.00064 20.1851 3.05385 20.0574 3.14843 19.9636L6.40605 16.7059C6.49986 16.6113 6.62756 16.5581 6.76078 16.5581Z"
    />
  </svg>
)
