import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Bin, Plus, baseColors } from '@fairhq/common'

const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  max-width: 64px;
  padding: 0 8px;
  &:focus-visible {
    outline: none;
  }
  &:focus-visible::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 24px;
  }
`
const AddButton = styled(ButtonBase)`
  border-radius: 0 20px 20px 0;
  background-color: ${baseColors.blue000};
  color: ${baseColors.blue400};
  &:hover {
    background-color: ${baseColors.blue100};
    color: ${baseColors.blue500};
  }
  &:active {
    background-color: ${baseColors.blue200};
    color: ${baseColors.blue600};
  }
`

const RemoveButton = styled(ButtonBase)`
  border-radius: 0 20px 20px 0;
  background-color: ${baseColors.red100};
  color: ${baseColors.red500};
  &:hover {
    background-color: ${baseColors.red200};
    color: ${baseColors.red600};
  }
  &:active {
    background-color: ${baseColors.red300};
    color: ${baseColors.red700};
  }
`

interface AddOrRemoveButtonProps {
  title: string
  name?: string
  onClick: () => void
  variant: 'add' | 'remove'
}
export const AddOrRemoveButton: FunctionComponent<AddOrRemoveButtonProps> = ({
  title,
  name,
  onClick,
  variant,
}) =>
  variant == 'add' ? (
    <AddButton
      type="button"
      name={name ?? title}
      title={title}
      onClick={onClick}
    >
      <Plus aria-hidden="true" />
    </AddButton>
  ) : (
    <RemoveButton
      type="button"
      name={name ?? title}
      title={title}
      onClick={onClick}
    >
      <Bin aria-hidden="true" />
    </RemoveButton>
  )
