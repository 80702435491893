import React, { FunctionComponent } from 'react'

import { Flex, Grid, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { StateHandler } from 'components/StateHandler'
import { COLOR_RULES_WOMEN } from 'components/visualization/constants'
import { getLegend } from 'components/visualization/utils/getLegend'
import { Visualization } from 'components/visualization/Visualization'
import { ReportGroup } from 'store/reports/types'
import { QueryStatus } from 'store/types'
import BorderBox from 'ui-kit/BorderBox'
import { customDecodeURIComponent } from 'utils/customDecodeURIComponent'

import { useDiversityRepresentation } from '../hooks/useDiversityRepresentation'

interface DiversityRepresentationGroupProps {
  area?: string
  group?: ReportGroup
}

export const DiversityRepresentationGroup: FunctionComponent<
  DiversityRepresentationGroupProps
> = ({ area, group }) => {
  const { t } = useTranslation()
  const { code: rawCode = '', type = '' } = useParams<{
    type: string
    code: string
  }>()
  const code = customDecodeURIComponent(rawCode)
  const { representationByGroup: representation, queryStatus } =
    useDiversityRepresentation(area, group)

  if (
    !(
      (type === 'location' && code) ||
      (type === 'department' && code) ||
      (!type && code)
    )
  ) {
    return null
  }

  return (
    <StateHandler
      isError={queryStatus === QueryStatus.ERROR}
      isSuccess={queryStatus === QueryStatus.SUCCESS}
      isLoading={queryStatus === QueryStatus.LOADING}
      errorMessage={t('error.TryAgain')}
    >
      <Grid columns={2} gap={4} mb={9}>
        {representation.map(({ key, title, items, benchmark }) => (
          <BorderBox key={key}>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 5,
              }}
            >
              <Text as="div" variant="bodyLargeBold">
                {title}
              </Text>
            </Flex>
            <Visualization
              type="Bar"
              items={items as any}
              benchmark={benchmark as any}
              colorRules={COLOR_RULES_WOMEN}
              isLegendVisible
              legend={getLegend(t, key)}
            />
          </BorderBox>
        ))}
      </Grid>
    </StateHandler>
  )
}
