import { useTranslation } from 'react-i18next'

import { ReportsSidebarMenuGroup } from 'features/reports/hooks/useReportsSidebarGenAndSub'
import { useJobLevels } from 'hooks/useJobLevels'
import {
  useGetJobLevelsQuery,
  useGetSurveyWindowArchiveComparisonQuery,
} from 'store/surveyWindow/surveyWindowApi'
import { findJobLevel } from 'utils/findJobLevel'

export const useSidebarDiversity = (): {
  sidebarItems: ReportsSidebarMenuGroup | undefined
} => {
  const { t } = useTranslation()
  const jobLevels = useJobLevels()

  const { data: archive, isSuccess: isArchiveSuccess } =
    useGetSurveyWindowArchiveComparisonQuery()

  const latestArchiveSurveyWindow = archive?.[0]

  const { data: jobLevelsCount } = useGetJobLevelsQuery(
    {
      surveyWindowId: latestArchiveSurveyWindow?.id ?? 0,
    },
    { skip: !latestArchiveSurveyWindow }
  )

  const DIVERSITY_CODE = 'diversity'
  if (isArchiveSuccess) {
    return {
      sidebarItems: {
        code: DIVERSITY_CODE,
        enabled: !!latestArchiveSurveyWindow,
        text: t(`${DIVERSITY_CODE}.title`),
        url: `/reports/${DIVERSITY_CODE}`,
        items:
          jobLevelsCount && jobLevelsCount?.length > 0
            ? jobLevelsCount.map(({ level, completed }) => {
                const currentLevel = findJobLevel(jobLevels, level)

                return {
                  code: currentLevel?.code ?? '',
                  text: t(`jobLevel.${currentLevel?.level.toLowerCase()}`),
                  url: `/reports/${DIVERSITY_CODE}/${level}`,
                  enabled: completed != 0,
                }
              })
            : undefined,
      },
    }
  }
  return { sidebarItems: undefined }
}
