import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  margin-right: 30px;
  align-self: center;
`

const Image = styled.img`
  width: 120px;
`

export interface VisualProps {
  image: string
}

export const Visual: FunctionComponent<VisualProps> = ({ image }) => (
  <Container>
    <Image src={image} alt="Abstract image representing campaign theme" />
  </Container>
)
