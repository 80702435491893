import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, Text } from 'theme-ui'

import { Graph, baseColors, makeTransition, space } from '@fairhq/common'
import { Documents } from 'components/svgs/icons/Documents'
import { Home } from 'components/svgs/icons/Home'
import { Megaphone } from 'components/svgs/icons/Megaphone'
import { Strategy } from 'components/svgs/icons/Strategy'
import { PermissionsEnum, useAuthPermissions } from 'hooks/useAuthPermissions'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementScopesEnum } from 'store/company/types'
import RouterLink from 'ui-kit/RouterLink'

const IconContainer = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? baseColors.purple : baseColors.grey300};
  margin-right: ${space[2]}px;
  display: flex;
  align-items: center;
`

interface NavItem {
  icon?: FunctionComponent
  key: string
  label: string
  to: string
}

export const NavItems: FunctionComponent = () => {
  const { data } = useGetEntitlementsQuery()
  const { permissions } = useAuthPermissions()

  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { isAdmin } = useAuthRoles()

  const lookupNavItems: Record<
    string,
    {
      icon: FunctionComponent
      to?: string
      scopes: EntitlementScopesEnum[]
    }
  > = {
    dashboard: {
      scopes: [
        EntitlementScopesEnum.TASKS,
        EntitlementScopesEnum.EVENTS,
        EntitlementScopesEnum.CAMPAIGNS,
        EntitlementScopesEnum.SURVEY,
        EntitlementScopesEnum.REPORTS,
      ],
      icon: Home,
      to: '/home',
    },
    ...(permissions?.includes(PermissionsEnum.READ_AUDIT)
      ? {
          [EntitlementScopesEnum.AUDIT]: {
            scopes: [
              EntitlementScopesEnum.EMPLOYEES,
              EntitlementScopesEnum.SURVEY,
              EntitlementScopesEnum.AUDIT,
            ],
            icon: Documents,
          },
        }
      : {}),
    [EntitlementScopesEnum.REPORTS]: {
      scopes: [EntitlementScopesEnum.REPORTS],
      icon: Graph,
    },
    [EntitlementScopesEnum.STRATEGY]: {
      scopes: [EntitlementScopesEnum.STRATEGY],
      icon: Strategy,
    },
    [EntitlementScopesEnum.CAMPAIGNS]: {
      scopes: [EntitlementScopesEnum.CAMPAIGNS],
      icon: Megaphone,
    },
  }

  const getNavItems = (data: string[]): NavItem[] => {
    const allowedScopes: string[] = Object.keys(lookupNavItems).filter(key =>
      lookupNavItems[key].scopes.find((item: string) => data.includes(item))
    )

    const navItems: NavItem[] = allowedScopes.map(key => ({
      key,
      label: t(`nav.${key}`),
      to: lookupNavItems[key].to ?? `/${key}`,
      icon: lookupNavItems[key].icon,
    }))

    if (isAdmin) {
      navItems?.unshift({
        label: t('nav.admin'),
        key: 'admin',
        to: '/admin',
      })
    }

    return navItems
  }

  return (
    <>
      {data &&
        getNavItems(data?.scopes).map(({ to, icon: Icon, label, key }) => {
          const isActive = pathname.startsWith(to)
          return (
            <RouterLink to={to} key={key}>
              <Flex
                sx={{
                  alignItems: 'center',
                  px: 2,
                  mx: 2,
                  height: '100%',
                  borderBottom: '3px solid',
                  borderColor: isActive ? 'purple' : 'transparent',
                  color: isActive ? 'purple' : 'grey500',
                  '&:hover': {
                    color: 'purple',
                  },
                }}
              >
                {Icon ? (
                  <IconContainer isActive={isActive}>
                    <Icon />
                  </IconContainer>
                ) : null}
                <Text
                  as="div"
                  variant="labelBold"
                  sx={{
                    color: 'inherit',
                    transition: makeTransition('color'),
                  }}
                >
                  {label}
                </Text>
              </Flex>
            </RouterLink>
          )
        })}
    </>
  )
}
