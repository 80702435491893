import { Campaign } from 'features/campaigns/types'
import { Assessment } from 'store/assessment/types'
import { Question } from 'store/questionTypes'

export enum TaskStatus {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  SKIPPED = 'skipped',
  COMPLETED = 'completed',
}

export interface Task {
  id: number
  code: string
  priority: number
  status: TaskStatus
  oldRecommendation: {
    code: string | null
  }
  scoreImpact: {
    areaCode: string
    current: number | null
    projected: number | null
  } | null
  linkedQuestionCode: string
  notionURL: string
  description: string
}

export interface TaskWithResources extends Task {
  resources?: Resource[]
}

export interface Resource {
  code: string
  description: string
  externalURL?: string
  linkedCampaignCode?: string[]
  linkedEventCode: string[]
  notionURL?: string
  type: string
  campaign: Campaign
}

export interface LinkedQuestion {
  question: Question
  assessment: Assessment | null
}
