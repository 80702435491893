import { FunctionComponent } from 'react'

import { Box } from 'theme-ui'

import { Grid } from '../components/Grid'
import { Legend } from '../components/Legend'
import { VisualizationComponentProps } from '../types'
import { convertIntoLegend } from '../utils/convertIntoLegend'

import { ComparisonVisualizationItem } from './ComparisonVisualizationItem'

export const Comparison: FunctionComponent<VisualizationComponentProps> = ({
  items,
  benchmark,
  isLegendVisible,
  isScoreBy10,
  legend,
}) => (
  <Box>
    <Grid benchmarkValue={benchmark?.value ?? undefined}>
      {items.map(item => (
        <ComparisonVisualizationItem
          key={item.label}
          item={item}
          isScoreBy10={isScoreBy10}
        />
      ))}
    </Grid>
    {isLegendVisible ? (
      <Legend legend={legend || convertIntoLegend(items)} />
    ) : undefined}
  </Box>
)
