import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeTransition } from '@fairhq/common'

import { Tooltip } from '../components/Tooltip'
import { whiteColorBgChecker } from '../constants'
import { VisualizationItemValueProps, VisualizationTypeProps } from '../types'

export const RowStackVisualizationItemValue: FunctionComponent<
  VisualizationTypeProps<VisualizationItemValueProps>
> = ({ item, isScoreBy10 }) => {
  const { t } = useTranslation()
  const label = item.value ? (
    (isScoreBy10 && round(item.value / 10, 1)) || `${round(item.value)}%`
  ) : (
    <Box as="span" sx={{ opacity: 0 }}>
      {t('na')}
    </Box>
  )
  return (
    <Box
      sx={{
        flex: 1,
        borderRadius: '0 12px 12px 0',
        bg: item.color,
        transition: makeTransition('opacity'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '42px',
        width: '100%',
        maxWidth: `${item.value > 100 ? 100 : item.value}%`,
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '-12px',
          bottom: '0',
          top: '0',
          width: '12px',
          bg: item.value ? item.color : undefined,
          zIndex: -1,
        },
        '&:hover': { '.tooltip': { opacity: 1 } },
      }}
    >
      <Text
        as="div"
        variant="bodyLargeBold"
        sx={{
          color:
            item.value > 0 && item.key !== 'benchmark'
              ? (whiteColorBgChecker.includes(item.color) && 'white') ||
                'grey400'
              : 'grey500',
          right:
            item.value === 0 || item.key === 'benchmark' ? '-48px' : undefined,
          position: 'absolute',
        }}
      >
        {label}
      </Text>
      <Tooltip tooltip={item.tooltip} isHorizontal />
    </Box>
  )
}
