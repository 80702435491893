import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Heading, Text } from 'theme-ui'

import { StateHandler } from 'components/StateHandler'
import { Layout } from 'layout/Layout'

import { Separator } from 'ui-kit/Separator'

import { AssessmentChange } from './AssessmentChange'
import { useGetAssessmentChangesQuery } from './settingsApi'
import { SettingsSidebar } from './SettingsSidebar'

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`

export const AssessmentChanges: FunctionComponent = () => {
  const { t } = useTranslation()

  const {
    data: assessmentChanges,
    isLoading,
    isError,
    isSuccess,
  } = useGetAssessmentChangesQuery()

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <StateHandler
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        errorMessage={t('error.somethingWrong.fallback')}
      >
        <Heading variant="subtitle" sx={{ mb: 8 }}>
          {t('settings.assessment.title')}
        </Heading>
        <Text as="div" sx={{ maxWidth: 640, mb: 5 }}>
          {t('settings.assessment.description')}
        </Text>
        {assessmentChanges && assessmentChanges.length === 0 && (
          <Text as="div">{t('settings.assessments.empty')}</Text>
        )}
        {assessmentChanges && assessmentChanges.length > 0 && (
          <List>
            {assessmentChanges.map((change, index) => (
              <AssessmentChange key={index} change={change} />
            ))}
          </List>
        )}
        <Separator />
        <Text as="div" sx={{ maxWidth: 640, mb: 5 }}>
          {t('settings.assessment.footnote')}
        </Text>
      </StateHandler>
    </Layout>
  )
}
