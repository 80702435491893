import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Round, Text, baseColors } from '@fairhq/common'

const CompletionTag = styled.div<{ backgroundColor: string; color: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 16px 4px 12px;
  border-radius: 9999px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  white-space: nowrap;
`

const StyledRound = styled(Round)`
  flex-shrink: 0;
`

interface StatusTagWithRoundIconProps {
  percentage: number
  withCompleteLabels?: boolean
}

export const StatusTagWithRoundIcon: FunctionComponent<
  StatusTagWithRoundIconProps
> = ({ percentage, withCompleteLabels = true }) => {
  const { t } = useTranslation()

  if (percentage === 0) {
    return (
      <CompletionTag
        backgroundColor={baseColors.coolGrey300}
        color={baseColors.purple900}
      >
        <Text type="body" element="p" size="xs" fontWeight={500}>
          {t('reports.notStarted')}
        </Text>
      </CompletionTag>
    )
  }

  if (percentage > 0 && percentage < 100) {
    return (
      <CompletionTag
        backgroundColor={baseColors.purple200}
        color={baseColors.purple700}
      >
        <StyledRound
          width="14px"
          height="14px"
          outline={baseColors.purple500}
          stroke={baseColors.purple400}
        />
        <Text type="body" element="p" size="xs" fontWeight={500}>
          {withCompleteLabels
            ? t('reports.complete', { percent: Math.round(percentage) })
            : `${Math.round(percentage)}%`}
        </Text>
      </CompletionTag>
    )
  }

  if (percentage === 100) {
    return (
      <CompletionTag
        backgroundColor={baseColors.fairGreen100}
        color={baseColors.fairGreen600}
      >
        <StyledRound
          width="14px"
          height="14px"
          outline={baseColors.fairGreen400}
          stroke={baseColors.fairGreen200}
        />
        <Text type="body" element="p" size="xs" fontWeight={500}>
          {withCompleteLabels ? t('reports.completed') : t(`reports.ready`)}
        </Text>
      </CompletionTag>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
