import { FunctionComponent } from 'react'

import { round } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Text } from 'theme-ui'

import { baseColors, Graph, space, ClipboardArrow } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { RoundButton } from 'styled'

import { useRequestReportsBy } from '../hooks/useRequestReportsBy'

import { SurveyProgressBar } from './SurveyProgressBar'

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space[1]}px;
  svg {
    margin-right: ${space[1]}px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledGraph = styled(Graph)`
  margin-right: ${space[2]}px;
`

export const SurveyCompleted: FunctionComponent = () => {
  const { t } = useTranslation()
  const { latestSession } = useAppSelector((state: State) => ({
    latestSession: state.companyReducer.latestSession,
  }))

  const { audit } = useAuditBy(latestSession?.id, latestSession?.apiVersion)
  const { processing: reportsRequested, requestReports } = useRequestReportsBy({
    sessionId: latestSession?.id,
    apiVersion: latestSession?.apiVersion,
  })
  const surveyPercentage = round(audit?.survey?.percent ?? 0, 0)

  return (
    <>
      <LabelContainer>
        <ClipboardArrow outline={baseColors.purple} />
        <Text variant="caps" sx={{ color: baseColors.purple, fontSize: 14 }}>
          {t(`statusOverview.employeeSurvey`)}
        </Text>
      </LabelContainer>
      <Text variant="title" sx={{ mb: 2, fontSize: 24, display: 'block' }}>
        <Trans
          i18nKey="statusOverview.percentageCompleted"
          values={{
            percentageCompleted: surveyPercentage,
          }}
        />
      </Text>
      <SurveyProgressBar
        surveyPercentage={surveyPercentage}
        completedSurveys={audit?.survey.completedSurveys}
        totalSurveys={audit?.survey.surveys}
      />
      <Text
        sx={{
          mt: 2,
          mb: 4,
          color: baseColors.grey400,
          fontSize: 16,
          display: 'block',
        }}
      >
        {t(`statusOverview.requestReports.description`)}
      </Text>
      <ButtonContainer>
        {reportsRequested ? (
          <Text sx={{ color: baseColors.grey400, fontSize: 16 }}>
            {t(`statusOverview.reportsRequested.description`)}
          </Text>
        ) : (
          <RoundButton onClick={() => requestReports?.()}>
            <StyledGraph outline={baseColors.grey300} />
            {t(`statusOverview.requestReports.cta`)}
          </RoundButton>
        )}
      </ButtonContainer>
    </>
  )
}
