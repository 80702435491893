import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'

import { ScheduledCampaign, Campaign, SlackChannel } from './types'

const campaignsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getSlackInstallStatus: build.query<boolean, { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `companies/slack`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      // Without this, the data isn't being re-fetched when we're logged
      // in as the admin user and change account.
      // TODO: remove this line when the account has been moved to use RTK Query
      // too and we can use tags instead. This goes for all the endpoints in this file.
      keepUnusedDataFor: 0,
      transformResponse: (response: { isInstalled: boolean }) =>
        response.isInstalled,
    }),
    getSlackInstallLink: build.query<string, { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `campaigns/slack/install`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: { url: string }) => response.url,
    }),
    getScheduledCampaigns: build.query<
      ScheduledCampaign[],
      { sessionId: SessionId }
    >({
      query: ({ sessionId }) => ({
        url: `campaigns/scheduled`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: [TagTypes.CAMPAIGNS],
    }),
    getCompanySlackChannels: build.query<
      SlackChannel[],
      { sessionId: SessionId }
    >({
      query: ({ sessionId }) => ({
        url: `campaigns/slack/channels`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAvailableCampaigns: build.query<Campaign[], { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `campaigns`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    editCampaign: build.mutation<
      void,
      {
        scheduledCampaignId: number
        startDate: string
        publishTime: string
        sessionId: SessionId
        channel: SlackChannel
      }
    >({
      query: ({
        scheduledCampaignId,
        startDate,
        publishTime,
        sessionId,
        channel,
      }) => ({
        url: `campaigns/scheduled/${scheduledCampaignId}`,
        method: 'PATCH',
        body: {
          startDate,
          publishTime,
          channel,
        },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
        keepUnusedDataFor: 0,
      }),
      invalidatesTags: [TagTypes.CAMPAIGNS],
    }),
    scheduleCampaign: build.mutation<
      void,
      {
        campaignId: number
        startDate: string
        publishTime: string
        sessionId: SessionId
        channel: SlackChannel
      }
    >({
      query: ({ campaignId, startDate, publishTime, sessionId, channel }) => ({
        url: `campaigns/schedule`,
        method: 'POST',
        body: {
          campaignId,
          startDate,
          publishTime,
          channel,
        },
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
        keepUnusedDataFor: 0,
      }),
      invalidatesTags: [TagTypes.CAMPAIGNS],
    }),
    cancelScheduledCampaign: build.mutation<
      void,
      {
        scheduledCampaignId: number
        sessionId: SessionId
      }
    >({
      query: ({ scheduledCampaignId, sessionId }) => ({
        url: `campaigns/scheduled/${encodeURIComponent(scheduledCampaignId)}`,
        method: 'DELETE',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
        keepUnusedDataFor: 0,
      }),
      invalidatesTags: [TagTypes.CAMPAIGNS],
    }),
  }),
})

export const {
  useGetSlackInstallStatusQuery,
  useGetSlackInstallLinkQuery,
  useGetScheduledCampaignsQuery,
  useGetAvailableCampaignsQuery,
  useEditCampaignMutation,
  useScheduleCampaignMutation,
  useCancelScheduledCampaignMutation,
  useGetCompanySlackChannelsQuery,
} = campaignsApi
