import { http } from 'utils/http'

import { Survey } from './types'

export const surveyApi = {
  count(getState: () => unknown) {
    return http.get(getState, `surveys/count`)
  },
  save(getState: () => unknown, survey: Partial<Survey>) {
    return http.post(getState, 'surveys', survey)
  },
  update(getState: () => unknown, survey: Partial<Survey>) {
    return http.patch(getState, `surveys`, survey)
  },
  sendSurvey(getState: () => unknown, ids?: number[]) {
    return http.post(getState, 'surveys/send', { ids })
  },
  getInvitation(getState: () => unknown) {
    return http.get(getState, 'surveys/invitation')
  },
  getAssessments(getState: () => unknown) {
    return http.get(getState, `surveys/assessments`)
  },
  getCompletion(getState: () => unknown) {
    return http.get(getState, `surveys/completion`)
  },
}
