import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

interface DifficultyCircleProps {
  active: boolean
  sx?: ThemeUIStyleObject
}

export const DifficultyCircle: FunctionComponent<DifficultyCircleProps> = ({
  active,
  sx,
}) => (
  <Box
    sx={{
      flex: '0 0 auto',
      width: 8,
      height: 8,
      border: '1px solid',
      borderColor: active ? 'purple' : 'border',
      bg: active ? 'purple' : 'transparent',
      borderRadius: '100%',
      ...sx,
    }}
  />
)
