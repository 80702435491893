import React, { ReactNode } from 'react'

import { Box } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

type TableProps = {
  headers?: ReactNode[]
  items: any[]
  renderItem: (item: any) => React.ReactNode
  sx?: ThemeUIStyleObject
}

export const Table = ({ headers, items, renderItem, sx }: TableProps) => (
  <Box
    as="table"
    sx={{
      border: '1px solid',
      borderColor: 'border',
      borderCollapse: 'separate',
      borderRadius: '10px',
      overflow: 'hidden',
      width: '100%',
      variant: 'text.body',
      ...sx,
    }}
  >
    {!!headers && (
      <Box as="thead">
        <TableRow>
          {headers.map((header, index) => (
            <TableHeaderCell key={Math.random() + index}>
              {header}
            </TableHeaderCell>
          ))}
        </TableRow>
      </Box>
    )}
    <Box as="tbody">{items.map(renderItem)}</Box>
  </Box>
)

export function TableRow({
  sx,
  children,
  onClick,
}: {
  sx?: ThemeUIStyleObject
  children: any
  onClick?: (...args: any[]) => void
}) {
  return (
    <Box as="tr" sx={{ ...sx }} onClick={onClick}>
      {children}
    </Box>
  )
}

export function TableCell({
  as = 'td',
  sx,
  children,
}: {
  as?: React.ElementType
  sx?: ThemeUIStyleObject
  children: any
}) {
  return (
    <Box
      as={as}
      sx={{
        borderTop: '1px solid',
        borderColor: 'border',
        py: 2,
        px: 4,
        height: 62,
        '&:first-of-type': { pl: 6 },
        '&:last-of-type': { pr: 6 },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export function TableHeaderCell({
  children,
  sx,
}: {
  children: any
  sx?: ThemeUIStyleObject
}) {
  return (
    <TableCell
      as="th"
      sx={{
        variant: 'text.label',
        textAlign: 'left',
        height: 50,
        bg: 'grey100',
        borderTop: 0,
        ...sx,
      }}
    >
      {children}
    </TableCell>
  )
}
