import React, { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { useGetInsightsQuery } from 'features/insights/insightsApi'
import { StrategyDueBar } from 'features/strategy/StrategyDueBar'
import { StrategyIntro } from 'features/strategy/StrategyIntro'

import {
  useCheckIsNewStrategyDueQuery,
  useUpdateStrategyMutation,
} from 'store/strategy/strategyApi'
import { Strategy } from 'store/strategy/types'

import { scrollTop } from 'utils/scrollTop'

import { ActionBar } from './ActionBar'
import { InsightEditable } from './InsightEditable'
import { Instructions } from './Instructions'

const Main = styled.main`
  display: flex;
  margin: 24px 0;
  gap: 40px;
  color: ${baseColors.purple900};

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0;
  }
`

const SectionContainer = styled.section`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`

const ListContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
`

interface EditStrategyStep1Props {
  strategy: Strategy
}

export const EditStrategyStep1: FunctionComponent<EditStrategyStep1Props> = ({
  strategy,
}) => {
  const { t } = useTranslation()

  const { data: companyInsights, isSuccess } = useGetInsightsQuery({
    sessionId: strategy.sessionId,
  })

  const { data: isNewStrategyDue } = useCheckIsNewStrategyDueQuery()

  const [
    updateStrategy,
    {
      isLoading: isUpdateStrategyLoading,
      isSuccess: isUpdateStrategySuccess,
      isError: isUpdateStrategyError,
    },
  ] = useUpdateStrategyMutation()

  useEffect(() => {
    if (isUpdateStrategySuccess) {
      scrollTop()
    }
  })

  return (
    <>
      <StrategyIntro />
      <Main>
        <Instructions
          title="strategy.draft.stepOneTitle"
          description="strategy.draft.stepOneDescription"
        />

        {isSuccess && companyInsights.length > 0 && (
          <SectionContainer>
            <Text element="h3" type="body" size="xl" fontWeight={600}>
              {t('strategy.draft.stepOneListTitle')}
            </Text>
            <ListContainer>
              {companyInsights?.map(companyInsight => (
                <InsightEditable
                  key={companyInsight.id}
                  companyInsight={companyInsight}
                />
              ))}
            </ListContainer>
          </SectionContainer>
        )}
      </Main>
      {isNewStrategyDue ? (
        <StrategyDueBar />
      ) : (
        <ActionBar
          nextLabel="strategy.draft.stepOneNextLabel"
          isLoading={isUpdateStrategyLoading}
          isError={isUpdateStrategyError}
          onNextClick={() =>
            updateStrategy({
              id: strategy.id,
              draftStep: 2,
              regenerateInitiatives: true,
            })
          }
        />
      )}
    </>
  )
}
