import React, { ReactNode, FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'
import {
  LineChart as LineChartComponent,
  ResponsiveContainer,
  Line,
  YAxis,
  Tooltip,
  CartesianGrid,
  XAxis,
} from 'recharts'
import { ThemeUIStyleObject } from 'theme-ui'

import { baseColors } from '@fairhq/common'

import { LineChartTooltip } from './LineChartTooltip'

export enum DataKeys {
  TO = 'to',
  FROM = 'from',
}

type LineChartItem = {
  key: string
  label: ReactNode
} & Record<string, any>

interface LineChartProps {
  items: LineChartItem[]
  min?: number
  max?: number
  sx?: ThemeUIStyleObject
}

export const LineChart: FunctionComponent<LineChartProps> = ({
  items,
  min = 0,
  max = 10,
  sx,
}) => {
  const lineRef = React.useRef<any>(null)
  const [tooltipPoint, setTooltipPoint] = React.useState<any>({})

  const onMouseMove = (chart: any) => {
    if (chart.isTooltipActive && !!lineRef.current) {
      const { x, y } =
        lineRef.current.props.points?.[chart.activeTooltipIndex] || {}
      setTooltipPoint({ x, y })
    }
  }

  const onMouseLeave = () => {
    setTooltipPoint({})
  }

  return (
    <Box sx={{ ...sx }}>
      <ResponsiveContainer width="100%" height={200}>
        <LineChartComponent
          data={items}
          height={200}
          width={500}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
        >
          <Line
            key={DataKeys.TO}
            dataKey={DataKeys.TO}
            stroke={baseColors.grey200}
            strokeWidth={4}
            dot={{ r: 4 }}
            activeDot={false}
            isAnimationActive={false}
          />
          <Line
            ref={lineRef}
            key={DataKeys.FROM}
            dataKey={DataKeys.FROM}
            stroke={baseColors.purple}
            strokeWidth={4}
            dot={{ r: 3, fill: baseColors.purple }}
            activeDot={{
              r: 6,
              stroke: baseColors.purple,
              strokeWidth: 4,
              fill: baseColors.purple,
            }}
            isAnimationActive={false}
          />
          <XAxis
            interval={0}
            dataKey="label"
            padding={{ left: 50, right: 50 }}
            tickLine={false}
            tick={{ fontSize: '13px', fill: baseColors.grey500 }}
            tickMargin={10}
          />
          <YAxis
            domain={[min, max]}
            tickCount={3}
            width={20}
            axisLine={false}
            tickLine={false}
            tickMargin={5}
            interval={0}
            tick={{ fontSize: 13, fontWeight: 700, fill: baseColors.grey300 }}
          />
          <Tooltip
            content={<LineChartTooltip />}
            isAnimationActive={false}
            cursor={false}
            position={tooltipPoint}
          />
          <CartesianGrid vertical={false} />
        </LineChartComponent>
      </ResponsiveContainer>
    </Box>
  )
}
