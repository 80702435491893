import { FunctionComponent } from 'react'

import { EmptySidebar } from 'components/EmptySidebar'

import { ReportsSidebarMenuGroup } from 'features/reports/hooks/useReportsSidebarGenAndSub'
import { MenuGroups } from 'features/reports/MenuGroups'
import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'
import { EntitlementsEnum } from 'store/company/types'

import { useSidebarDiversity } from './hooks/useSidebarDiversity'
import { useSidebarEquality } from './hooks/useSidebarEquality'
import { useSidebarExperience } from './hooks/useSidebarExperience'

export const ReportsModularSidebar: FunctionComponent = () => {
  const { data: companyEntitlements } = useGetEntitlementsQuery()

  const { sidebarItems: sidebarEqualityItems } = useSidebarEquality()
  const { sidebarItems: sidebarDiversityItems } = useSidebarDiversity()
  const { sidebarItems: sidebarExperienceItems } = useSidebarExperience()

  const allSidebarItems: ReportsSidebarMenuGroup[] = []

  if (
    sidebarEqualityItems &&
    (companyEntitlements?.entitlements.includes(
      EntitlementsEnum.PRIVEQ_CERTIFICATION_REPORTS
    ) ||
      companyEntitlements?.entitlements.includes(
        EntitlementsEnum.DIVERSITYVC_CERTIFICATION_REPORTS
      ))
  ) {
    allSidebarItems.push(sidebarEqualityItems)
  }

  if (
    sidebarDiversityItems &&
    companyEntitlements?.entitlements.includes(EntitlementsEnum.DEM_REPORT)
  ) {
    allSidebarItems.push(sidebarDiversityItems)
  }

  if (
    sidebarExperienceItems &&
    companyEntitlements?.entitlements.includes(EntitlementsEnum.IS)
  ) {
    allSidebarItems.push(sidebarExperienceItems)
  }

  return allSidebarItems.length > 0 ? (
    <MenuGroups items={allSidebarItems} />
  ) : (
    <EmptySidebar />
  )
}
