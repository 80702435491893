import { FunctionComponent } from 'react'

import styled from 'styled-components'

import { FormContainer } from 'styled'

import { StepsIcon } from './StepsIcon'

const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  h2 {
    margin: 0 0 18px;
  }
`

export const SignUpSteps: FunctionComponent<{
  activeStep: number
  totalSteps: number
  title?: string
  children?: React.ReactNode
}> = ({ activeStep, children, title, totalSteps }) => (
  <FormContainer>
    <Title>
      <h2>{title}</h2>
      <StepsIcon activeStep={activeStep} totalSteps={totalSteps} />
    </Title>
    {children}
  </FormContainer>
)
