export const defaultImage = {
  webp: `https://files.fairhq.co/images/small/small_Signup_image_0874ec873b.webp  300w,
    https://files.fairhq.co/images/medium/medium_Signup_image_0874ec873b.webp  600w,
    https://files.fairhq.co/images/large/large_Signup_image_0874ec873b.webp  1200w,
    https://files.fairhq.co/images/large/Signup_image_0874ec873b.webp 2000w`,
  png: `
    https://files.fairhq.co/images/small/small_Signup_image_0874ec873b.png  300w,
    https://files.fairhq.co/images/medium/medium_Signup_image_0874ec873b.png  600w,
    https://files.fairhq.co/images/large/large_Signup_image_0874ec873b.png  1200w,
    https://files.fairhq.co/images/origin/Signup_image_0874ec873b.png 2000w`,
  src: 'https://files.fairhq.co/images/thumbnail/thumbnail_Signup_image_0874ec873b.png',
}
