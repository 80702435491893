import React, { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { Spinner, Text } from '@fairhq/common'
import { NoAccess } from 'components/noAccess/NoAccess'
import { Layout } from 'layout/Layout'

import { useGetEntitlementsQuery } from 'store/company/companyApiWithQuery'

import { EntitlementScopesEnum, EntitlementsEnum } from 'store/company/types'

interface EntitlementsGuardProps {
  entitlement?: EntitlementsEnum
  entitlementScope?: EntitlementScopesEnum
  component: ReactElement
}

export const EntitlementsGuard: FunctionComponent<EntitlementsGuardProps> = ({
  entitlement,
  entitlementScope,
  component,
}) => {
  const { t } = useTranslation()

  const {
    data: companyEntitlements,
    isError,
    isSuccess,
    isLoading,
  } = useGetEntitlementsQuery()

  const hasEntitlement =
    entitlement && companyEntitlements?.entitlements.includes(entitlement)
  const hasScope =
    entitlementScope && companyEntitlements?.scopes.includes(entitlementScope)

  const showRoute = hasEntitlement || hasScope

  return (
    <>
      {isError && (
        <Layout
          dark
          contentSx={{
            paddingTop: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text element="h3" type="heading" size="md" fontWeight={500}>
            {t(`error.somethingWrong.fallback`)}
          </Text>
        </Layout>
      )}
      {isLoading && (
        <Layout
          dark
          contentSx={{
            paddingTop: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Layout>
      )}
      {isSuccess && showRoute ? component : <NoAccess />}
    </>
  )
}
